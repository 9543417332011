import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import popupImg from "../../../assets/img/popup-img.png";
import searchIcon from "../../../assets/img/searchIcon.png";
import { AuthContext } from "../../Auth/AuthContext";
import LoadModal from "../../RecoModule/LoadModal";

function SelectModel() {
  const {darkMode, accessToken} = useContext(AuthContext)
  const { state } = useLocation();
  const navigate = useNavigate();
  const agentForm = state || {
    name: "",
    project_id: 1,
    user_id: 1,
    description: "",
    goal: [],
    instruction: [],
    agent_workflow: "Goal Based Workflow",
    constraints: [],
    toolkits: [],
    tools: [38],
    exit: "",
    iteration_interval: "500",
    model: "",
    max_iterations: 5,
    permission_type: "",
    LTM_DB: "",
    user_timezone: "",
    knowledge: null,
    agent_type: "Generative",
    agent_model: "1"
  };
  const [agentFormValues, setAgentFormValues] = useState(agentForm);
  const [selectedModel, setSelectedModel] = useState();
  const [selectedRow, setSelectedRow] = useState(0);
  const agentType = localStorage.getItem("Agent");
  const selectedIndex = agentType == "ADRE" ? 0 : 1;
  console.log(agentFormValues);
  const [models, setModels] = useState([]);
  const [prevSelectedRowId, setPrevSelecteRowId] = useState(undefined);

  const [createdby, setcreatedby] = useState({
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman"
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/finetuned_models`, {
        headers: {
          Authorization: accessToken
        }
      })
      .then(response => {
        setModels(response.data.data.slice().reverse());
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const content = `

  Analysing the tasks, Alchemist recommends full Supervised Fine-Tuning (SFT), Reward Modeling, and Proximal Policy Optimization (PPO) for Adverse Drug Relations (ADR) events. This combined approach could lead to a 15-30% reduction in ADR-related hospitalizations, significantly impacting both patient well-being and healthcare costs.
<center><img src="${popupImg}" style="width:25rem;"/></center>
<strong>Supervised Fine-Tuning (SFT) Precision in Prediction:</strong> SFT leads to higher precision in predicting potential adverse reactions, as the model becomes more attuned to the nuances of drug interactions and patient-specific factors.
<strong>Benefit:</strong> Improved accuracy in ADR prediction can lead to a reduction in patient morbidity and mortality. For instance, a 10% increase in prediction accuracy could potentially translate to thousands of prevented ADR incidents in large patient populations.Reward Modeling

<strong>Customized Learning Objectives:</strong> Reward modeling allows for the creation of custom reward functions that can prioritize the most clinically significant ADRs. This means the model can focus on detecting ADRs that are rare but severe, rather than common but mild.
<strong>Benefit:</strong> By focusing on severe ADRs, healthcare systems can significantly reduce the incidence of life-threatening drug reactions. For example, early detection of a rare but severe ADR in just 1% of cases could save lives and reduce healthcare costs associated with treating these reactions. Proximal Policy Optimization (PPO)

<strong>Efficient Exploration:</strong> PPO, a reinforcement learning algorithm, is useful for exploring different strategies in ADR prediction and management. It can efficiently balance the exploration of new strategies and the exploitation of known strategies, leading to more robust ADR prediction models.
Quantifiable Benefit: Enhanced exploration exploitation balance can lead to a more rapid convergence to optimal strategies, potentially reducing the time to identify critical ADRs by a significant margin. For instance, a 20% reduction in convergence time can accelerate the deployment of effective ADR prediction models in clinical settings.`;

  function selectModelFunction(event, param, id) {
    if (selectedRow === id) {
      // setSelectedRow(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow(id); // Select the clicked row
      localStorage.setItem("basemodelidForAgent", JSON.stringify(param.base_model_id));
      console.log(id + 1);
    }
    console.log(param);
    agentFormValues.model = "gpt-4";
    
  }

  function createAgentAPICall() {
    console.log(agentFormValues);
    agentFormValues['agent_type'] = 'Generative'
    const headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${accessToken}`
    };
    const payload = JSON.stringify(agentFormValues);
    console.log(payload);

    axios
      .post(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`, payload, { headers })
      .then(response => {
        console.log(response);
        navigate("/agentfactory/createAgent/testAgent/" + response.data.id);
        // accesstoken = response.data.access_token;
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  }

  const handleBack = () => {
    console.log('hi')
    console.log(agentFormValues)
    navigate("/agentfactory/createAgent", { state: agentFormValues })
  }

  return (
    <>
      <LoadModal
        showModal={showModal}
        closeModal={closeModal}
        content={content}
        submitModal={createAgentAPICall}
      />
      <div className="container-fluid px-4 pt-3 imgBackground">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid px-0">
            <span
              className="navbar-brand "
              style={darkMode ? { color: "white" } : { color: "#2a3647" }}
            >
              SELECT MODEL
            </span>
            <span style={{ color: "#D5DAE4" }}>|</span>
            <span
              className="navbar-brand "
              style={darkMode ? { color: "white", marginLeft: "5px" } : { color: "#2a3647", marginLeft: "5px" }}
            >
              Agent Name :
              {agentFormValues.name}
            </span>

            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav ms-auto">
                <Link
                  to="/agentfactory"
                  class="btn btn-primary cancelBtnDark me-3"
                  role="button"
                >
                  Cancel
                </Link>
                <span
                  className="verticalLine"
                  style={{ borderLeft: "1px solid #D5DAE4" }}
                ></span>
                <a
                  class="btn btn-primary cancelBtnDark me-3"
                  role="button"
                  onClick={handleBack}
                >
                  Back
                </a>

                <a
                  class="btn btn-primary submitBtnDark"
                  role="button"
                  onClick={createAgentAPICall}
                >
                  Save & Proceed
                </a>

                
              </div>
            </div>
          </div>
        </nav>

        <Card
          className={"mt-2 " + (darkMode ? 'darkBackground' : 'whiteBg')}
          style={{
            border: darkMode ? 'none' : '1px solid #DFE1E5',
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0"
          }}
        >
          <Navbar expand="lg" className="py-3">
            <Container fluid className="px-3">
              <Navbar.Brand
                className=" baseFont"
                style={{ textTransform: "inherit", fontWeight: "bold", color: darkMode ? 'white' : '' }}
              >
                CONFIGURED MODEL
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="ms-auto my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <span className="my-auto pe-2" style={{ color: darkMode ? '#E5EAF1' : '' }}>Base Model :</span>
                  <NavDropdown
                    className={darkMode ? 'btn text-capitalize  modelSelectDropdownDark ' : ' btn text-capitalize  modelSelectDropdown'}
                    style={darkMode ? { height: '2rem' } : {}}
                    title="All"
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <InputGroup className={darkMode ? "searchWidth alchemSearchGroupDark" : "searchWidth alchemSearchGroup"}>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    className="searchBar"
                  />
                  <Button
                    variant="outline-secondary"
                    id="searchButton"
                    className="searchGroupBtn"
                  >
                    <img
                      className="img-fluid"
                      loading="lazy"
                      src={searchIcon}
                      alt=""
                    />
                  </Button>
                </InputGroup>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Card>

        <Card className={darkMode ? 'tableCardDark' : 'tableCard'}>
          <Card.Body>
            <table className={darkMode ? 'table alchemTableDark selectModelTable ' : 'table alchemTable selectModelTable'}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Model Name</th>
                  <th>Description</th>
                  <th>Base Model</th>
                  <th>Type</th>
                  <th>Date Created</th>
                  <th>Last Modified</th>
                </tr>
              </thead>
              <tbody id="accordion">
                {models.map((info, i) => {
                  const isSelected = selectedRow === i;
                  console.log(info.
                    base_model_id)
                  return [
                    <>
                      <tr key={info.
                    id}>
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={isSelected}
                            onClick={e => selectModelFunction(e, info, i)}
                          />
                        </td>
                        <td
                          data-bs-toggle="collapse"
                          data-bs-parent="#accordion"
                          data-bs-target={"#rowId" + i}
                          className="accordion-toggle"
                          aria-expanded={i === selectedIndex ? "true" : "false"}
                          aria-controls={"rowId" + i}
                        >
                        </td>
                        <td>{info.name}</td>
                        <td>{info.description}</td>
                        <td>
                          {(() => {
                            if (info.base_model_id == 1) {
                              return <p>falcon-7b</p>;
                            } else if (info.base_model_id == 2) {
                              return <p>bloom-3b</p>;
                            } else if (info.base_model_id == 3) {
                              return <p>flan-t5-base</p>;
                            } else if (info.base_model_id == 4) {
                              return <p>bloom-3b</p>;
                            }
                          })()}
                        </td>
                        <td>{info.name.includes("Wealth") ? "Predictive" : "Generative"}</td>
                        <td>
                          {" "}
                          {new Date(info.created_at).toLocaleDateString(
                            "en-GB"
                          )}{" "}
                        </td>
                        <td>
                          {new Date(info.updated_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="9" className="hiddenRow border-bottom">
                          <div
                            id={"rowId" + i}
                            className={i === selectedIndex ? "" : "collapse"}
                            aria-labelledby={"rowId" + i}
                            data-bs-parent="#accordion"
                          >
                            <div className="border-bottom container-fluid px-5 subTable_outer">
                              <table className="table alchemTable alchemSubTable subTable mb-0">
                                <thead>
                                  <tr className="info">
                                    <th>Purpose</th>
                                    <th>Status</th>
                                    <th>Updated by</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{info.purpose}</td>
                                    <td>{info.status}</td>
                                    <td>{createdby[info.updated_by]}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ];
                })}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default SelectModel;
