import React from "react";
import LogoIcon from "../asset/TCSlogo.svg";
import settingIcon from "../asset/setting.svg";
import "./navbar.css";

export default function navBar() {
  return (
    <div className="header">
      <img src={LogoIcon} alt="tcs logo" />

      <img src={settingIcon} alt="setting icon" />
    </div>
  );
}
