import React, { useContext, useEffect, useState } from 'react'
import accordionPlus from '../../../assets/img/accordion-plus.svg'
import accordionMinus from '../../../assets/img/accordion-minus.svg'

export default function HPAccordion({ head, data }) {
    const [active, setActive] = useState(false)

    return (
        <>
            <div className='d-flex my-1 flex-column' style={{ background: `${active ? '#313557 0% 0% no-repeat padding-box' : '#313557 0% 0% no-repeat padding-box'}`, border: '1px solid #313557' }}>
                {active ?
                    <div className='d-flex flex-row justify-content-start px-2 py-2' >
                        <div className=' d-flex my-auto mr-2 flex-row'><div className='ps-2 my-auto' onClick={() => { setActive(false) }}><img src={accordionMinus}></img></div></div>
                        <div className='d-flex my-auto flex-column'>
                            <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{head || 'NOT SPECIFIED'}</div>
                        </div>
                    </div>
                    :
                    <div className='d-flex flex-row justify-content-start px-2 py-2' >
                        <div className='d-flex mr-2 my-auto flex-row'><div className='ps-2 my-auto' onClick={() => { setActive(true) }}><img src={accordionPlus}></img></div></div>
                        <div className='d-flex my-auto flex-column'>
                            <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{head || 'NOT SPECIFIED'}</div>
                        </div>
                    </div>
                }
                {
                    active ?
                        <div className='d-flex flex-column ps-2 w-100' style={{ background: '#212542 0% 0% no-repeat padding-box' }}>
                            {
                                Object.keys(data).map((k, idx) => (
                                    <div className='d-flex flex-row px-1 py-1 justify-content-between'>
                                        <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>{`${k}`}</div>
                                        <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data[k] === '' ? 'EMPTY' : `${data[k]}`}</div>
                                    </div>
                                ))
                            }
                        </div> :
                        <></>
                }
            </div>
        </>
    )
}