import React from 'react';
import { Table, Form } from 'react-bootstrap';

// Define the CustomTable component
const CustomTableNew = ({ TableColumn, data, darkMode, onDataChange }) => {
  // Handler to update input values
  const handleInputChange = (rowIndex, key, value) => {
    const updatedData = [...data];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [key]: {
        ...updatedData[rowIndex][key],
        value
      }
    };
    // Notify parent component of the data change
    onDataChange(updatedData);
  };

  return (
    <div
      className="custom-table-wrapper"
      style={{
        background: darkMode ? "#42466C" : "",
        border: darkMode ? "none" : ""
      }}
    >
      <Table className={darkMode ? 'custom-tableDark' : 'custom-table'}>
        <thead>
          <tr>
            {TableColumn.map((e, index) => (
              <th key={index} scope="col">{e}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item["Parameter Name"].value}</td>
              <td>
                {item.Input.type === "number" && (
                  <input
                    type="number"
                    value={item.Input.value}
                    min={item.Input.min}
                    max={item.Input.max}
                    className='form-control'
                    style={{ background: '#34385a', color: 'rgb(186, 201, 241)', border: '1px solid #2F499E' }}
                    onChange={(e) => handleInputChange(index, 'Input', e.target.value)}
                  />
                )}
                {item.Input.type === "text" && (
                  <input
                    type="text"
                    value={item.Input.value}
                    className='form-control'
                    style={{ background: '#34385a', color: 'rgb(186, 201, 241)', border: '1px solid #2F499E' }}
                    onChange={(e) => handleInputChange(index, 'Input', e.target.value)}
                  />
                )}
                {item.Input.type === "radio" && (
                  item.Input.options.map((option, optIndex) => (
                    <label key={optIndex}>
                      <input
                        type="radio"
                        name={`radio-group-${index}`}
                        value={option.value}
                        checked={item.Input.value === option.value}
                        className='mx-2'
                        onChange={(e) => handleInputChange(index, 'Input', e.target.value)}
                      />
                      {option.label}
                    </label>
                  ))
                )}
                {item.Input.type === "select" && (
                  <Form.Select
                    className="form-select"
                    style={{
                      background: darkMode ? "#34385A" : "",
                      color: darkMode ? "#BAC9F1" : "",
                      border: "1px solid #2F499E"
                    }}
                    value={item.Input.value || ""}
                    onChange={(e) => handleInputChange(index, 'Input', e.target.value)}
                    aria-label="Default select example"
                    size="sm"
                  >
                    <option value="">Select an option</option>
                    {item.Input.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CustomTableNew;
