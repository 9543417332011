import React, { useContext, useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import styles from "./NavBar.module.css"
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoutLogo from "../../../assets/img/onBtn.svg";
import LogoWhite from "../../../assets/img/logoWhite.svg";
import logodark from "../../../assets/img/logoWhite.svg";
import { Link, useNavigate } from "react-router-dom";
import UserThumbnail from "../../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../../assets/img/dropdown-arrow.svg";
import { FaGear } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../../Auth/AuthContext";
import PropTypes from 'prop-types'
import { DockContext } from "../../DockWrapper/DockContext";




export default function NavBar({ links, profile, handleToggle, nodeIndex, setNodeIndex, handleProceed, proceedDisable, isSummary = true }) {

    const [username, setUsername] = useState();
    const [IDtoken, setIDtoken] = useState();
    const [access_token, setAccess_token] = useState();
    const [step, setStep] = useState("SEND FOR MODEL TRAINING")
    const {
        accessToken,
        darkMode,
        IDToken,
    } = useContext(AuthContext);

    const { chat_history } = useContext(DockContext);

    useEffect(() => {
        setIDtoken(IDToken);
        setAccess_token(accessToken);
        if (IDtoken) {
            const decoded = jwtDecode(IDtoken);
            setUsername(decoded.name);
        }
    }, [IDtoken, setIDtoken]);



    const navigate = useNavigate();
    const handleHome = () => {
        return navigate("/");
    };



    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className={
                darkMode
                    ? `navWithBgDark competencydetails p-0`
                    : `navWithBg competencydetails p-0 ${styles["fixed-height-15"]}`
            }
        >
            <Container fluid style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                <Navbar.Brand href="" onClick={handleHome}>
                    <img
                        className="img-fluid logoSize"
                        loading="lazy"
                        src={darkMode ? logodark : LogoWhite}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {links && (
                        <Nav
                            className="me-auto alchemNavLinks"
                            defaultActiveKey={"competencies"}
                        >
                            {links.map((link, index) => (
                                <Nav.Link
                                    to={link.route}
                                    className={`justify-content-center align-items-center d-flex  ${darkMode ? "text-dark" : "text-white"
                                        }`}
                                    style={{
                                        display: "inline-block",
                                        borderBottom: link.isActive ? '3px solid #3366FF' : 'none',

                                    }}
                                    as={Link}
                                    key={index}
                                >
                                    <div className={styles.line}></div>
                                    {link.name}
                                </Nav.Link>
                            ))}
                        </Nav>
                    )}

                    <div
                        className="collapse navbar-collapse"
                        id="navbarNavAltMarkup"
                    >
                        <div
                            className="navbar-nav ms-auto w-100 d-flex justify-content-end align-items-center"

                        >


                            {
                                chat_history.currentStage <= 3 && <>

                                    <div className="d-flex align-items-center">
                                        {
                                            isSummary && <>
                                                <Link
                                                    to={"/summary"}
                                                    className={`${styles["link-text"]} mr-2`}

                                                >
                                                    Summary
                                                </Link>
                                                <div className="line me-3" />
                                            </>
                                        }
                                        {/* <Link
                                            className={`cancelBtnDark m-2`}
                                            to={"/activityList"}
                                        >
                                            close
                                        </Link> */}
                                    </div>
                                    {/* <Link to={"/activityList"} className={"btn btn-primary submitBtnDark px-5 "}>
                                        Save
                                    </Link> */}
                                    <div className="line me-3"></div>

                                    {/*TODO: for now, wireframe doesn't support dynamic btn according to page.  */}

                                    {/* <button
                                        className="btn btn-primary submitBtnDark"
                                        onClick={() => setNodeIndex(1)}
                                    >
                                        {step}
                                    </button> */}

                                </>}
                            <NavComp profile={true} />

                        </div>
                    </div>

                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}


// -------------------------------------------------------------
// ---------------------user nav comp





export function NavComp({ profile }) {
    const {
        setAuthenticated,
        accessToken,
        darkMode,
        IDToken,
        setIDToken
    } = useContext(AuthContext);


    const [username, setUsername] = useState();
    const [idtoken, setIdtoken] = useState();
    const [access_token, setAccess_token] = useState();


    useEffect(() => {
        // Retrieve the JWT token from local storage
        setIdtoken(IDToken);
        setAccess_token(accessToken);
        if (idtoken) {
            // Decode the JWT token
            const decoded = jwtDecode(idtoken);
            setUsername(decoded.name);
            // setUsername(decoded.)
        }
    }, [idtoken, setIdtoken]);

    function removeCookie(name) {
        document.cookie =
            name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const handleLogout = () => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");
        // Construct logout URL with headers
        removeCookie("accessToken");
        removeCookie("refreshToken");
        removeCookie("IDToken");
        setAuthenticated(false);
    };



    return (
        <>
            {profile ? (
                <Nav>
                    {/* <Nav.Link href="#deets" className="navWhite">
              Mayur Jadhav
            </Nav.Link> */}
                    {/* <Toggle darkMode={darkMode} handleToggle={handleToggle} /> */}
                    <NavDropdown
                        title={
                            <>
                                <img src={UserThumbnail} />
                                <span
                                    className="user-name"
                                    style={darkMode ? { color: "#E5EAF1" } : {}}
                                >
                                    {username}
                                </span>
                                <img src={DropDownArrow} />
                            </>
                        }
                        id="navbarScrollingDropdown "
                        className={
                            "btn user-profile m-0 " + darkMode ? " user-drop-down" : ""
                        }
                        style={
                            darkMode
                                ? { backgroundColor: "#212542", border: "none", zIndex: "99999999999999999999999999" }
                                : { zIndex: "99999999999999999999999999" }
                        }
                    >
                        <NavDropdown.Item href="#action3">
                            User Profile
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => handleLogout()}>
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            ) : (
                <div className="d-flex justify-center align-items-center">
                    <FaGear className="text-white" style={{ fontSize: "18px" }} />
                </div>
            )}
        </>
    )
}

NavComp.propTypes = {
    profile: PropTypes.bool.isRequired
}


