import React, { useContext, useEffect, useState } from 'react'
import styles from "./Summary.module.css"
import PropTypes from 'prop-types'
import NavBar from '../navBar/NavBar'
import { Nav } from 'react-bootstrap'
import { DockContext } from '../../DockWrapper/DockContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const dummy_data = [
    {
        activitychain: {
            title: "wealt analysis",
            inteligence: "diagnostic",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque"
        },

        agents: {
            title: "agent name",
            inteligence: "diagnostic",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque"
        },
        recommendedmodels: {
            title: "EDA",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque",
            isComplete: false
        }


    },
    {
        activitychain: {
            title: "wealt analysis 2",
            inteligence: "diagnostic",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque"
        },

        agents: {
            title: "agent name",
            inteligence: "diagnostic",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque"
        },
        recommendedmodels: {
            title: "EDA",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque",
            isComplete: false
        }


    },
    {
        activitychain: {
            title: "wealt analysis 3",
            inteligence: "diagnostic",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque"
        },

        agents: {
            title: "agent name",
            inteligence: "diagnostic",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque"
        },
        recommendedmodels: {
            title: "EDA",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elit. Pellentesque",
            isComplete: true,

        }


    }
]


export default function Summary() {

    const {
        activity_master_json,
        setActivity_master_json,
        selectedKnowledgeWork
    } = useContext(DockContext)

    const [paramsData, setParamsData] = useState([])

    useEffect(() => {
        const knowledge_work_id = selectedKnowledgeWork.id ? selectedKnowledgeWork.id : 1
        console.log(knowledge_work_id)
        setActivity_master_json({})
        axios
            .get(
                `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${knowledge_work_id}`
            )
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data)
                    if (res.data.is_master_json) {
                        setActivity_master_json(res.data.master_json)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }, [])


    const HandleParamsPopulation = (data) => {
        setParamsData(data)
    }

    const navigate = useNavigate()

    const HandleActivityChainRedirection = () => {

        navigate("/agentMapping")
    }


    const cellDetails = (props) => {

        console.log(props)
        return (
            <div className={`p-1 d-flex justify-content-start flex-column`} >

                <div className={`d-flex align-items-center`}>
                    <i className="fa-solid fa-note-sticky m-1  fs-5" style={{ color: "#8f8fb9" }} ></i>
                    <h4 className={`m-0 text-white font-weight-medium`}>{props["name"]}</h4>
                </div>

                <p style={{color:"cyan"}}>
                    Intelligence: {props["finetuned_model_type"]}
                </p>

                <p className="m-0" style={{ color: "#c5dfc4" }}>
                    {props["description"]}
                </p>

            </div>
        )


    }

    const modelDetails = (item) => {
        if (item) {

            return (
                <div className={`p-1 d-flex justify-content-start flex-column`} onClick={() => HandleParamsPopulation(item["hyper_parameters"])}>

                    <div className={`d-flex align-items-center`}>
                        <i className="fa-solid fa-note-sticky m-1  fs-5" style={{ color: "#8f8fb9" }} ></i>
                        <h4 className={`m-0 text-white font-weight-medium`}>{item["model_name"]}</h4>
                    </div>

                    <p>
                        Base Model: {item["base_model"]}
                    </p>

                    <p className="m-0" style={{ color: "#c5dfc4" }}>
                        Weights: {item["model_weight"]}
                    </p>

                </div >
            )
        }

    }


    const populateTable = () => {
        console.log(activity_master_json?.knowledge_work?.activities)
        return activity_master_json?.knowledge_work?.activities.map((data, i) => {
            console.log(data)
            return <tr key={i} >
                <td className='w-50' style={{ height: "100px", background: "#454d7b" }} >
                    {cellDetails(data)}
                </td>
                <td className='w-50' style={{ height: "100px", background: "#454d7b" }} >

                    {/* model population logic changed */}
                    {modelDetails(data[`${data["finetuned_model_type"]}_model`])}

                </td>
            </tr>

        })


    }

    useEffect(() => {
        console.log(selectedKnowledgeWork)
    }, [selectedKnowledgeWork])

    let subtitle = `Knowledge Work: ${selectedKnowledgeWork.name}`;
    let title = "Create Knowledge Work";
    let steps = ["Context Gathering", "Context Formalizing into Activities", "Knowledge Activity Validation", "Agent Chain Mapping", "Agent Chain Test"];
    const aChainToCahtPopFun = (props) => {

        console.log("aChainToCahtPopFun- 123", props);
    }


    const navSummary = [{ name: "CREATE KNOWLEDGE WORK" }, { name: `Knowledge Work: ${selectedKnowledgeWork.name}` }]

    return (<>
        <div className={`${styles["max-size-100"]} h-100 `}>
            <NavBar links={navSummary} isSummary={false} profile={true} />
            <SubHeading heading='summary screen' />
            <div className={`${styles["table-container"]}`}>
                <table className={`table table-hover  mb-0 table-bordered border-secondary `} style={{ width: "65%" }}>
                    <thead >
                        <tr>
                            <th scope="col" className={`text-uppercase text-light`} style={{ background: "#222b52" }} >Activity Chain</th>
                            <th scope="col" className={`text-uppercase text-light`} style={{ background: "#222b52" }} >Recommended models</th>
                        </tr>
                    </thead>
                    <tbody className={`${styles["table-bg-light-cyan"]}`}>
                        {populateTable()}
                    </tbody>
                </table>
                <div className={` d-table ${styles["bg-light-grey"]}`} style={{ width: "35%" }} >
                    <table className={`table table-hover  mb-0 border-top `}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" className={`text-uppercase`} style={{ background: "#222b52" }} >Recommended models</th>

                            </tr>
                        </thead>
                        <tbody className={`${styles["table-bg-light-cyan"]}`}  >

                            <tr style={{ height: "600px" }}>
                                <HyperparameterRecommended data={paramsData} />
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div >
        <></>
    </>
    )
}


Summary.propTypes = {}

// --------------------------------components of use in this screen


export function SubHeading({ heading, modelTraining }) {

    return (
        <div className={`w-100  d-flex justify-content-between align-items-center ${styles["bg-dark-blue"]} p-2 `} style={{backgroundColor:"#454d7b"}}>
            <h4 className={`text-uppercase fs-5 `}>
                {heading}
            </h4>

            {/* for now the ability is not in the ui decision yet to be taken */}

            {/* <Nav.Link className="icon-link icon-link-hover text-white mr-2">
                Activity Chain Visualization
                <i className="fa-solid fa-binoculars"></i>
            </Nav.Link> */}

        </div>
    )
}

SubHeading.propTypes = {
    heading: PropTypes.string.isRequired,
    modelTraining: PropTypes.object.isRequired
}



export function HyperparameterRecommended({ data }) {

    console.log(data)

    const parameter = (params) => {
        return Object.keys(params).map((data, i) => {
            console.log(data, params[data])
            return <div key={i} className={`d-flex gap-2 p-2 align-items-center justify-content-between ${styles["bg-dark-blue"]}`}>
                <p className='text-light'>{data}</p>
                <p className='text-light'>{params[data]}</p>
            </div>
        })
    }
    return (
        <div className={"d-grid m-2 border p-0 h-100"} style={{ overflow: "scroll", maxHeight: "100%", minHeight: "600px" }}>
            <div className='d-inline-flex  align-items-center justify-content-between px-4 py-1 border-bottom bg-light-subtle' style={{ maxHeight: "10%" }} >
                <h4>Parameter</h4>
                <h4>Input</h4>
            </div>
            {
                Object.keys(data).map((item, i) => {

                    return (<>
                        <div key={i} className='d-flex gap-2 p-1 align-items-center justify-content-between border-bottom'>
                            <span>{item}</span>
                        </div>

                        {parameter(data[item])}
                    </>
                    )
                })
            }


        </div>
    )
}

HyperparameterRecommended.propTypes = {}

