import React, { useEffect, useRef, useContext, useState } from "react";
import axios from "axios";
import sendchat from "../../../assets/img/sendchat.svg";
import { DockContext } from "../../DockWrapper/DockContext";
import { AuthContext } from "../../Auth/AuthContext";
import { MarkerType } from "reactflow";
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from "react-icons/bs";
import PropTypes from 'prop-types'
import llmLogo from '../../../assets/img/coach-llm-logo.png'
import Login from "../../AgentModule/Login/Login";
import { useNavigate } from "react-router-dom";
import PinkBtn from '../../../assets/img/coach-llm-logo.png'
import AudioRecorder from "../../AudioRec/AudioRec";
import userBubble from '../../../assets/img/user_bubble.svg'
import botBubble from '../../../assets/img/bot_bubble.svg'
import { Button, Modal, Spinner } from "react-bootstrap";
import Styles from "./ChatInterface.module.css"

export default function ChatInterface(props) {

    const [message, setMessage] = React.useState('')
    const [sendDisabled, setSendDisabled] = React.useState(false)
    const {
        setisFinish,
        isFinish,
        setIsDraggable,
        isDraggable,
        isAchainDocked,
        setisAchainDocked,
        achaindockSize, setAchainContentSize,
        chat_history, setchat_history,
        setmindmapLoader,
        setmind_map_history, setactivityReactFlowHis,
        activity_master_json, setActivity_master_json,
        selectedKnowledgeWork, setSelectedKnowledgeWork,
        dockSize,

    } = useContext(DockContext);
    const fileRef = React.useRef();
    const [files, setFiles] = useState([]);
    const { subID, setToken } = useContext(AuthContext)
    const [voiceToText, setVoiceToText] = useState("")


    const appendHistory = (message = []) => {
        try {
            let data = { ...chat_history }
            console.log(data)

            let updatedStage = { ...chat_history[chat_history.stageConfig[chat_history.currentStage]] }
            console.log(updatedStage)
            let currentHistory = updatedStage.steps[updatedStage.currentStep].history
            let updatedSteps = { ...updatedStage.steps[updatedStage.currentStep], history: currentHistory.concat(message) }
            console.log(updatedSteps)

            updatedStage.steps[updatedStage.currentStep] = updatedSteps
            data[data.stageConfig[data.currentStage]] = updatedStage
            console.log(data)

            setchat_history(data)
        } catch (e) {
            console.log(e, "Error on appending history", "\nmessage given to the func\n", message)
        }


    }
    const handleChatHistory = (message = []) => {
        // for setting history from  the master json 
        try {
            let data = { ...chat_history }
            console.log(data)

            let updatedStage = { ...chat_history[chat_history.stageConfig[chat_history.currentStage]] }
            console.log(updatedStage)

            let updatedSteps = { ...updatedStage.steps[updatedStage.currentStep], history: message }
            console.log(updatedSteps)

            updatedStage.steps[updatedStage.currentStep] = updatedSteps
            data[data.stageConfig[data.currentStage]] = updatedStage
            console.log(data)

            setchat_history(data)

        } catch (e) {
            console.log(e, "Error on retriving chat history", "\nparams given to the handleChatHistory func\n", message)
        }

    }

    const [qllm, setQllm] = useState(null);

    const HoverComponent = () => {
        const [isHovered, setIsHovered] = useState(false);
        const style = {
            backgroundColor: 'white',
            color: isHovered ? 'red' : 'black',
            position: 'relative',
            padding: '10px',
        };

        const hoverStyle = {
            position: 'absolute',
            padding: '10px',

            width: "150%",
            right: "100px",
            backgroundColor: 'white',
            color: 'black',
        };
        // console.log(qllm)
        return (
            <div
                style={{ 'color': "#243063" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                sllm
                {isHovered && (

                    <pre style={hoverStyle}>{qllm}</pre>
                )}
            </div>
        );
    };

    const fetchTaskConfig = async (userId) => {
        const url = `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot_config/id?user_id=${selectedKnowledgeWork.id}`;
        // TODO:commented for now
        // try {
        //     const response = await axios.get(url, {
        //         headers: {
        //             'accept': 'application/json'
        //         }
        //     });
        //     setQllm(response.data.data.qllm)
        // } catch (error) {
        //     console.error(`Error fetching task config: ${error}`);

        // }
    };

    React.useEffect(() => {
        fetchTaskConfig(subID)
        setmind_map_history({
            nodes: [],
            edges: []
        })
        setactivityReactFlowHis({
            nodes: [],
            edges: []
        })
        setActivity_master_json({})
    }, [])




    // const [chains, setChains] = React.useState({})
    const chatContainerRef = useRef(null);
    const ForViewSake = useRef(null);

    const handleChange = (e) => {
        e.target.style.height = "50px"
        if (parseInt(e.target.style.height.replace("px", "")) < 80 && parseInt(e.target.style.height.replace("px", "")) > 50) {
            e.target.style.height = (e.target.scrollHeight) + "px"
        }
        setMessage(e.target.value)
    }


    var HOST = `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/input/`

    var BEFORE_HOST = `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/input_before/`




    const [data_test, setData_test] = useState("")

    const ReWriteHistory = (chathistory_chain_payload) => {

        const SignalRegexPattern = /^\W{3,}(END\sOF\sCONVERSATION)\W{3,}/g
        const chat_history = []

        chathistory_chain_payload?.map((item, index) => {

            if (SignalRegexPattern.test(item.content) && index != chathistory_chain_payload.length - 1) {
                console.log(item, "regex omitted ")
            } else {
                console.log(item, "convo added")
                chat_history.push(item)
            }

        })

        console.log(chat_history)
        setHistory(chat_history)

    }
    const handlesend_coachllm = async (reflection, userInput) => {
        setSendDisabled(true)
        setmindmapLoader(true)
        setHistory([...history, { role: "user", content: userInput }, { role: 'reflection', content: reflection }, { role: 'assistant', content: 'Thinking...' }])
        await axios.post(HOST, {
            "message":
                userInput // Changed reflection to userInput 
        })
            .then(responses => {
                console.log(responses.data)
                // setData_test("agent: " + responses.data.message)
                axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/chat_history`)
                    .then(async res => {

                        if (Object.keys(res.data).includes("chathistory_chain_payload")) {
                            console.log(res.data)
                            ReWriteHistory(res.data?.chathistory_chain_payload)
                            if (res.data?.activity_chain_payload.nodes.length != 0 && res.data?.chathistory_chain_payload?.length > 2) {
                                console.log("updating mind_map")
                                setmind_map_history(res.data?.mindmap_chain_payload)
                                setactivityReactFlowHis(res.data?.activity_chain_payload)
                            }
                            setActivity_master_json(res.data?.master_json)
                            // handleChatScroll()

                        } else {

                            handleChatHistory([])
                            setmind_map_history({})
                            setactivityReactFlowHis({})

                        }

                    }
                    ).catch((error) => {
                        console.log(error)
                    })
                setSendDisabled(false)
            })
            .catch(error => {
                console.log(error)
                setSendDisabled(false);
                appendHistory([{ role: 'user', content: userInput }, { role: 'assistant', content: 'Failed to response please try again' }])
            })


        setMessage("");
    };
    const handleSend_reflection = (start) => {
        console.log(start, "handle send");
        var input_before = ""
        setSendDisabled(true)
        setmindmapLoader(true)
        setHistory([...history, { role: 'user', content: message + start }, { role: 'assistant', content: 'Reflecting...' }])
        axios.post(BEFORE_HOST, {
            "message":
                message + start
        })
            .then(responses => {
                console.log("refleciton output: ", responses.data.data)
                input_before = responses.data.data
                setData_test(responses.data.data)
                axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/chat_history`)
                    .then(async res => {

                        if (Object.keys(res.data).includes("chathistory_chain_payload")) {
                            console.log(res.data)
                            handlesend_coachllm(input_before, message)

                        } else {

                            handleChatHistory([])
                            setmind_map_history({})
                            setactivityReactFlowHis({})

                        }

                    }
                    ).catch((error) => {
                        console.log(error)
                    })
                setSendDisabled(false)
            })
            .catch(error => {
                console.log(error)
                setSendDisabled(false);
                appendHistory([{ role: 'user', content: message }, { role: 'assistant', content: 'Failed to response please try again' }])
            })


        setMessage("");
        console.log("sharing", data_test)
    };

    const [history, setHistory] = useState([])

    useEffect(() => {
        console.log(process.env.REACT_APP_COACH_SERVER)
        axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/chat_history`)
            .then(res => {

                if (Object.keys(res.data).includes("chathistory_chain_payload")) {
                    console.log(res.data?.activity_chain_payload)
                    setcleareChatMOdal(res.data?.chathistory_chain_payload.length >= 2 ? true : false)
                    ReWriteHistory(res.data?.chathistory_chain_payload)
                    if (res.data?.activity_chain_payload.nodes.length != 0 && res.data?.chathistory_chain_payload?.length > 2) {
                        console.log("updating mind_map")
                        setmind_map_history(res.data?.mindmap_chain_payload)
                        setactivityReactFlowHis(res.data?.activity_chain_payload)
                    }
                    setActivity_master_json(res.data?.master_json)
                    // handleChatScroll()

                } else {

                    handleChatHistory([])
                    setmind_map_history({})
                    setactivityReactFlowHis({})

                }
            })
            .catch(res => console.log(res))


    }, []);

    const navigate = useNavigate()
    useEffect(() => {
        if (!subID) {
            navigate("/activityList")
        }

    }, [subID])








    const handleFinish = () => {

        props.setActivityComplete(true)
        props.openValidateModal()
        axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/finish?kw_id=${selectedKnowledgeWork.id}`)
            .then((res) => {
                console.log(res)
                props.openValidateModal()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleChatScroll = () => {
        chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight + 100,
            behavior: 'smooth',
        });
    };



    useEffect(() => {
        if (chatContainerRef) {
            chatContainerRef.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
        if (chatContainerRef) {
            chatContainerRef.current.addEventListener('DOMSubtreeModified', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [history])

    useEffect(() => {
        if (!selectedKnowledgeWork) {
            navigate("/activityList")
        }
    }, [])



    useEffect(() => {
        if (voiceToText) {
            setMessage(voiceToText)
        }
    }, [voiceToText])



    // -------------------------------clear chat popup --------------------------

    const [cleareChatMOdal, setcleareChatMOdal] = useState(false)

    // -------------------------------clear chat popup ends--------------------------


    const clearChat = () => {

        axios
            .post(
                `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/chat_history`
            )
            .then((res) => {
                console.log(res.data)
                setisFinish(false)
                setmind_map_history({
                    nodes: [],
                    edges: []
                })
                setactivityReactFlowHis({
                    nodes: [],
                    edges: []
                })

            })
            .catch((error) => {
                console.log(error)
            })

    }
    const restartConvo = () => {
        setSendDisabled(true)
        clearChat()
        console.log(history)
        setHistory([{ "role": "assistant", "content": "Thinking...!" }])
        axios.post(HOST, {
            "message": "START"
        })
            .then(responses => {
                console.log(responses.data)
                axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/chat_history`)
                    .then(async res => {

                        if (Object.keys(res.data).includes("chathistory_chain_payload")) {
                            console.log(res.data?.activity_chain_payload)
                            handleChatHistory(res.data.chathistory_chain_payload)
                            ReWriteHistory(res.data?.chathistory_chain_payload)
                            if (res.data?.activity_chain_payload?.nodes?.length >= 1) {
                                setmind_map_history(res.data?.mindmap_chain_payload)
                            } else {
                                setmind_map_history({ nodes: [], edges: [] })
                            }
                            setactivityReactFlowHis(res.data?.activity_chain_payload)
                            setActivity_master_json(res.data?.master_json)
                            // handleChatScroll()

                        } else {

                            handleChatHistory([])
                            setmind_map_history({})
                            setactivityReactFlowHis({})

                        }

                    }
                    ).catch((error) => {
                        console.log(error)
                    })
                setSendDisabled(false)
            })
            .catch(error => {
                console.log(error)
                setSendDisabled(false);
                appendHistory([{ role: 'user', content: message }, { role: 'assistant', content: 'Failed to response please try again' }])
            })


        setcleareChatMOdal(false)



    }



    return (
        <>

            {cleareChatMOdal &&
                <div className="clear-chat-modal-bg">
                    <div className="d-flex flex-column justify-content-around gap-2 chat-interface-blur m-3 p-4 rounded" style={{ backgroundColor: "#212542", minHeight: "200px", maxHeight: "250px" }}>

                        <h4 className="text-white p1">Would you like to continue the chat ?</h4>
                        <div className="d-flex justify-content-evenly p-1">

                            <button className="btn btn-primary m-1 text-capitalize" style={{ minWidth: "100px" }} onClick={() => { setcleareChatMOdal(false) }}>continue</button>
                            <button className="btn btn-secondary m-1 text-capitalize" onClick={restartConvo}> Reset Chat Conversation </button>

                        </div>

                    </div>
                </div>
            }

            <div className="d-flex flex-column justify-content-between" style={{ width: '60%' }}>
                <div className="chat-content disable-scrollbars overflow-scroll" ref={chatContainerRef} style={{ maxHeight: '70vh', flexGrow: '0' }}>
                    {
                        <DynamicMapper currentHistory={history} parentRef={chatContainerRef} />
                    }

                    {
                        isFinish &&
                        <div className="d-flex m-1 justify-content-center align-items-center">
                            <button className="btn btn-primary" onClick={handleFinish}> PROCEED FOR ACTIVITY VALIDATION</button>
                        </div>
                    }


                </div >

                {/*---------- Footer --------------------*/}

                <div className="h-25 w-100 chat-achain-dock">


                    <div className="chat-input" >

                        {/* <img src={PinkBtn} style={{ maxWidth: "80px", maxHeight: "70px", marginRight: "10px" }} onClick={toggleDock} /> */}

                        <div className="chat-input-container" style={{ display: 'flex', flexDirection: 'row', alignItems: "center", backgroundColor: "#fff" }}>
                            <textarea
                                rows="1"
                                id="chatLLMInput"
                                type="text"
                                placeholder={sendDisabled ? "Please wait for the bot response" : "Type your message..."}
                                className="chat-input-box mr-4"
                                value={message}
                                onChange={e => handleChange(e)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSend_reflection("")
                                    }
                                }}
                                disabled={sendDisabled} style={{ overflowY: 'scroll', width: "85%", border: "none", background: "transparent", backgroundColor: "none", boxShadow: "none", maxHeight: '60px', height: "50px", padding: "1%", borderRadius: "6px" }} />


                            <div className=" chatbot-text-overlay-pos ">

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: "10px" }} >
                                    <AudioRecorder setResponse={setMessage} voiceToText={voiceToText} />
                                </div>


                                {!sendDisabled ?
                                    <img className="fa fa-paper-plane chat-send-icon w-50" aria-hidden="true" alt='send' src={sendchat} onClick={() => { handleSend_reflection("") }}></img>
                                    :
                                    <img className="fa fa-paper-plane chat-send-icon" aria-hidden="true" src={sendchat} alt='test'></img>
                                }

                            </div>


                        </div>


                    </div>

                </div>
            </div>
            {/*---------- Footer --------------------*/}
        </>
    );


}



// -------------------------------------------------------------




// ---------------------------------Dynamic div mapping for json/text
export function DynamicMapper({ currentHistory, parentRef }) {
    const {
        chat_history,
        setisFinish,
        isFinish
    } = useContext(DockContext);

    const [history, setHistory] = useState(currentHistory)

    const SignalRegexPattern = /^\W{3,}(END\sOF\sCONVERSATION)\W{3,}/g

    useEffect(() => {
        if (isFinish) {
            setHistory(history.slice(0, -1))
            console.log("slicing")
        }
    }, [isFinish])

    const handleContent = (content, isReflection) => {

        if (typeof (content) == typeof ("string")) {

            if (SignalRegexPattern.test(content)) {
                console.log(history.length - 1)

                if (history[history.length - 1]["role"] === "assistant" && history[history.length - 1]["content"] === content) {
                    setisFinish(true)
                } else {
                    setisFinish(false)
                }
            }
            content = content.replaceAll("**", "").replaceAll("*", "").replaceAll("***", "")
            return <TextBasedComp content={content} isReflection={isReflection} />
        } else if (typeof (content) === typeof ({})) {
            console.log("json", content);
            return <JsonBasedcomp content={content} />
        }

    }

    const handleHistory = () => {
        let stages = Object.values(chat_history.stageConfig)
        const his_array = []

        stages.map((stage) => {
            let currentStage = chat_history[stage]
            console.log(currentStage);
            if (Object.keys(currentStage).length > 1) {

                let steps = Object.keys(currentStage.steps)
                console.log(steps);

                steps.map((step) => {
                    let currentStep = currentStage.steps[step]
                    console.log(currentStep);
                    his_array.push(...currentStep.history)
                    console.log(his_array);
                    return ""
                })

            }
        })

        setHistory(his_array)

    }

    useEffect(() => {
        handleHistory()
        console.log(currentHistory)
        console.log(history)
    }, [chat_history])

    useEffect(() => {
        setHistory(currentHistory)
    }, [currentHistory])

    useEffect(() => {
        console.log('ZXAB', parentRef)
        if (parentRef?.current) {
            parentRef.current.scrollTop = parentRef.current.scrollHeight
        }
    }, [history])

    const ContentDropDownConfig = (index) => {
        console.log(history[index + 1], index, history.length)
        if (history.length > index + 1) {
            return history[index + 1]["role"] === "reflection"
        } else {
            return false
        }
    }
    const handleDropDownCase = (message, index) => {
        const content = message.content.replaceAll("**", "").replaceAll("*", "").replaceAll("***", "")
        return (
            <>
                <button className="btn align-self-end d-flex justify-content-center align-items-center flex-row border-0" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="false" aria-controls={`collapse-${index}`}>

                    <i className={`fa-solid fa-angle-down ${Styles["tooltip"]}`} style={{ color: "#212542" }} >
                        <span className={`${Styles["tooltiptext"]} overflow-hidden fw-light`}>Show Reflection</span>
                    </i>
                    <pre className={`text-dark m-0`} style={{ font: "normal normal normal 14px/18px Calibri" }}> {`${content}`}</pre>
                </button>
                <div className="collapse" id={`collapse-${index}`}>
                    <div className="border-top pt-1 border-secondary" style={{ backgroundColor: "transparent", color: "white" }}>
                        <h4 className="text-dark text-decoration-underline">Reflection:-</h4>
                        <pre className={`text-dark m-0`} style={{ font: "normal normal normal 14px/18px Calibri" }}> {`${history[index + 1].content.replaceAll("**", "").replaceAll("*", "").replaceAll("***", "")}`}</pre>
                    </div>
                </div>
            </>
        )
    }

    const handleMessage = () => {
        return history.map((message, index) => {
            console.log(message, "dynamic mapper")
            const isUser = message.role === "user";
            const isBot = message.role === "assistant";
            const isReflection = message.role === "reflection";
            console.log("is user:", isUser)
            console.log("is bot:", isBot)
            if (!isReflection) {
                return (
                    <div
                        key={`${index}_${message.role}`}
                        className={
                            `${isUser
                                ? "user-message-chatLLM flex-row"
                                : "api-response flex-row"
                            }
                             `
                        }
                    >
                        {isBot && (
                            <img src={botBubble} className={message.content.includes("\n") ? "align-self-start" : "align-self-center"} alt="bot" />
                        )}
                        <div className={`chat-bubble align-items-start ${isUser ? "justify-content-end mr-2" : "justify-content-start ml-2 "} ${isReflection ? "text-white" : "text-dark"} flex-column`} style={isReflection ? { backgroundColor: "#7682db", color: "white" } : isUser ? { color: "black", width: "93%" } : { color: "black" }} >
                            {ContentDropDownConfig(index) ?
                                handleDropDownCase(message, index) :
                                handleContent(message.content, isReflection)
                            }
                        </div>
                        {isUser ? (
                            <img src={userBubble} className={message.content.includes("\n") ? "align-self-end" : "align-self-center"} alt="user" />
                        ) : null}
                    </div>
                )
            }
        })
    }
    return (
        <>
            {
                handleMessage()
            }
        </>

    )
}

DynamicMapper.propTypes = {
}



export function TextBasedComp({ content, isReflection }) {
    return (
        <pre className={`${isReflection ? "text-white" : "text-dark"} m-0`} style={{ font: "normal normal normal 14px/18px Calibri" }}> {`${content}`}</pre>
    )
}


TextBasedComp.propTypes = {
    content: PropTypes.string.isRequired,
}


export function JsonBasedcomp({ content }) {

    const handleContent = () => {

        return Object.keys(content).map((key, index) => {
            let keyValue = content[key]
            if (typeof (keyValue) == typeof ({})) {

                let keysOfKey = Object.keys(keyValue)
                console.log(keyValue, keysOfKey);

                return <div key={index} style={{ minWidth: "400px" }} >
                    <h4 className="m-0 mb-3 text-dark text-uppercase font-weight-bold" style={{ fontSize: "12px" }} > {`${key}`}</h4>
                    <div className="d-flex flex-column ">
                        {
                            keysOfKey.map((entitie, i) => {
                                return <div
                                    key={i}
                                    className={
                                        "text-dark rounded chatinterface-card"
                                    }
                                >
                                    <div>

                                        <p style={{ font: "normal normal bold 12px/14px Calibri" }}>
                                            {entitie}
                                        </p>
                                        <pre style={{ font: "normal normal bold 12px/14px Calibri" }}>
                                            {keyValue[entitie]}
                                        </pre>

                                    </div>

                                    <i className="fas fa-chevron-circle-up"></i>

                                </div>
                            })
                        }

                    </div >
                </div>


            } else if (typeof (keyValue) == typeof ("")) {
                return <div className="mb-3" style={{ font: "normal normal normal 14px/18px Calibri", color: "#111621" }}> {keyValue} </div>
            }


        })

    }

    return (
        <div className="api-response">
            <div className="w-100 d-flex flex-column align-items-stretch pt-2">
                {
                    handleContent()

                }
            </div>

        </div>

    )
}


JsonBasedcomp.propTypes = {
    content: PropTypes.object.isRequired
}



