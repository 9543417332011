// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.grey .MuiLinearProgress-root{
    height: 8px !important;
    border-radius: 50px;
}

.grey .MuiLinearProgress-colorPrimary{
    background-color: #DFE1E5 !important;
    
}
.grey .MuiLinearProgress-bar1Determinate{
    border-radius: 50px;
    background: #A0A0A0 0% 0% no-repeat padding-box;

}
.grey{
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/graph/ProgressBarGrey/ProgressBarGrey.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;;AAExC;AACA;IACI,mBAAmB;IACnB,+CAA+C;;AAEnD;AACA;IACI,WAAW;AACf","sourcesContent":["\n.grey .MuiLinearProgress-root{\n    height: 8px !important;\n    border-radius: 50px;\n}\n\n.grey .MuiLinearProgress-colorPrimary{\n    background-color: #DFE1E5 !important;\n    \n}\n.grey .MuiLinearProgress-bar1Determinate{\n    border-radius: 50px;\n    background: #A0A0A0 0% 0% no-repeat padding-box;\n\n}\n.grey{\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
