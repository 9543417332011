import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import AlchemTableNav from "../../SharedComponents/AlchemTableNav/AlchemTableNav";
import PropTypes from "prop-types";
import { AuthContext } from "../../Auth/AuthContext";

const SelectModelCompetancy = (props) => {
  const {accessToken, setAccessToken, setRefreshToken} = useContext(AuthContext)
  // variables
  const { state } = useLocation();
  const agentForm = state;
  const createdby = {
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman",
  };

  const baseModelMapping = {
    1: "falcon",
    2: "bloom",
    3: "flan t5",
    4: "Llama 2",
  };

  const taskList = {
    1: "Summarization",
    2: "QA",
    3: "Text Generation",
  };
  const filters = [
    {
      label: "Base Model",
      options: ["Banking", "Life Science", "Insurance"],
      selectedOption: "All",
    },
  ];
  // useStates
  let agentFormValues = agentForm;
  console.log(agentFormValues);

  // ------------------compare model code---------------

  //useStates
  const [dummyData, setdummyData] = useState([]);
  const [selectedRow1, setSelectedRow1] = useState(null);
  const [selectedRow2, setSelectedRow2] = useState(null);
  const [selectedModelName, setselectedModelName] = useState("");
  const [modelType, setmodelType] = useState(false); // false if base model true if finetune model

  //functions

  // this function will select the row and set the data to localstorage
  const handleRowSelection = (index, name) => {
    if (selectedRow1 === index) {
      setSelectedRow1(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow1(index); // Select the clicked row
      setselectedModelName(name);
      localStorage.setItem("basemodelid", JSON.stringify(index + 1));
      localStorage.setItem("basemodelname", name);
      console.log(index + 1);
    }
  };

  // this function will select the row and set the data to localstorage
  const handleRowSelection2 = (index, name) => {
    if (selectedRow2 === index) {
      setSelectedRow2(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow2(index); // Select the clicked row
      setselectedModelName(name);
      localStorage.setItem("basemodelid", JSON.stringify(index + 1));
      localStorage.setItem("basemodelname", name);
      console.log(index + 1);
    }
  };

  //useEffects
  //this useEffect will fetch the base models & finetuned models and push it to tempData
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };
  useEffect(() => {
    let tempData = [];
    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/basemodels`, {
        headers:{
          Authorization: accessToken
        }
      })
      .then((response) => {
        tempData.push(...response.data.data);
        console.log(
          "Fetch agents success : ",
          response.data.data,
          "----------"
        );
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401)
        axios
        .post(
          `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`,{"refresh_token": getCookie("refreshToken")},
          { headers }
        )
        .then(refreshResponse => {
          // Check if the refresh request was successful
          if (
            refreshResponse.status === 200 &&
            refreshResponse.data.access_token &&
            refreshResponse.data.refresh_token
          ) {
            setAccessToken(refreshResponse.data.access_token);
            setRefreshToken(refreshResponse.data.refresh_token);
            document.cookie = `accessToken=${refreshResponse.data.access_token}`;
            document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
            console.log("Token refreshed");
            return true; // User is authenticated after successful token refresh
          } else {
            console.log("Error refreshing token:", refreshResponse);
            return false; // Authentication failed if token refresh fails
          }
        })
        .catch(error => {
          if(error.response && error.response.status === 401)
          window.location.reload()
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.error === "invalid_grant"
          ) {
            console.error("Refresh token expired or invalid");
          }

          return false; // Authentication failed if token refresh fails
        });
      });

    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/finetuned_models`, {
        headers:{
          Authorization: `${accessToken}`
        }
      })
      .then((response) => {
        setmodelType(true);
        tempData.push(...response.data.data);
        console.log(
          "Fetch agents success : ",
          response.data.data,
          "----------"
        );
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401)
          window.location.reload();
      });
    setdummyData(tempData);
  }, []);

  console.log(dummyData);

  //this function is displaying the data from dummyData
  const DisplayData = dummyData.map((info, i) => {
    const isSelected = selectedRow1 === i;
    const jsSelected = selectedRow2 === i;
    if (modelType) {
      return (
        <>
          <tr key={i}>
            <td>
              <input
                type="radio"
                className="checkbox"
                checked={isSelected}
                onChange={() => {}} // Disable checkbox change
                onClick={() => handleRowSelection(i, info.name)}
              />
            </td>
            <td
              data-toggle="collapse"
              data-target={"#rowId" + i}
              className="accordion-toggle"
              aria-expanded="false"
              aria-controls={"#rowId" + i}
              style={{ cursor: "pointer" }}
            ></td>
            <td>{info.name}</td>
            <td>{info.description}</td>
            <td>
              {baseModelMapping[info.base_model_id]
                ? `${baseModelMapping[info.base_model_id]}`
                : "NA"}
            </td>
            <td>
              {taskList[info.task_id] ? `${taskList[info.task_id]}` : "NA"}
            </td>
            <td>{info.usecase ? `${info.usecase}` : "NA"} </td>
            <td>
              {info.status ? (
                <div className="statusContainer">
                  <div
                    className="circle"
                    style={{
                      background:
                        info.status == "Just created"
                          ? "#1FEB00"
                          : info.status == "Finished"
                          ? "#3366FF"
                          : "#FEA92B",
                    }}
                  ></div>{" "}
                  `${info.status}`
                </div>
              ) : (
                "NA"
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="10" className="hiddenRow border-bottom">
              <div
                className="collapse subTable_outer multi-collapse"
                id={"rowId" + i}
              >
                <table className="table subTable">
                  <thead>
                    <tr className="info">
                      <th>Purpose</th>
                      <th>Status</th>
                      <th>Updated by</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{info.purpose}</td>
                      <td>{info.status}</td>
                      <td>{createdby[info.updated_by]}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </>
      );
    } else {
      return (
        <tr key={i}>
          <td>
            <input
              type="radio"
              className="checkbox"
              checked={jsSelected}
              onChange={() => {}} // Disable checkbox change
              onClick={() => handleRowSelection2(i, info.name)}
            />
          </td>

          <td>{info.name}</td>
          <td>{info.description}</td>
          <td>{info.version}</td>
        </tr>
      );
    }
  });

  return (
    <div className="">
      <div className="container-fluid px-4 pageHeadBg">
        <nav className="navbar navbar-expand-lg pt-3">
          <div className="container-fluid px-0 ">
            <div className="d-flex text-center justify-content-center align-content-center align-item-center p-1">
              <span
                className={`taskList ${
                  props.darkMode ? "" : "text-dark"
                } border-end pe-3 ps-0`}
              >
                Model Name : {localStorage.getItem("name")}
              </span>
              <span
                className={`taskList ${
                  props.darkMode ? "text-white" : "text-dark"
                } ps-3`}
              >
                Selected Model : {selectedModelName}{" "}
              </span>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ms-auto">
                <Link
                  to={{
                    pathname: "/agentcreation",
                  }}
                >
                  <button className="btn btn-primary cancelBtnDark">
                    Cancel
                  </button>
                </Link>

                {selectedRow1 == null && selectedRow2 == null ? (
                  <Link to={""}>
                    <button className="btn btn-primary submitBtnDark ms-3">
                      Save & Proceed
                    </button>
                  </Link>
                ) : (
                  <Link>
                    <button
                      className="btn btn-primary submitBtnDark ms-3"
                      onClick={() => props.parentFunction()}
                    >
                      Save & Proceed
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>

        <AlchemTableNav
          filters={filters}
          showFilters={true}
          showSearchbar={true}
          navbarBrand=""
        />
        <Card className={props.darkMode ? "tableCardDark" : "tableCard"}>
          <Card.Body className="pb-0">
            <table
              className={
                props.darkMode
                  ? "table alchemTableDark allModelTable mb-0"
                  : "table alchemTable allModelTable mb-0"
              }
            >
              <thead>
                {modelType ? (
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Base Model</th>
                    <th>Task</th>
                    <th>Domain</th>
                    <th>Status</th>
                  </tr>
                ) : (
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>version</th>
                  </tr>
                )}
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
SelectModelCompetancy.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  parentFunction: PropTypes.func.isRequired,
};

export default SelectModelCompetancy;
