import React, { useContext } from 'react'
import { DockContext } from '../../../DockWrapper/DockContext'
import checkMarkCircle from '../../../../assets/img/check_mark_circle.svg'

export default function AgentSideBar({ data, title, setData, selectedPos, setSelectedPos, finished }) {


    const handleSelectData = (item) => {
        console.log("work in progress")
        let updatedData = data.map((datum) => {
            let UpdatedDatum = { ...datum }
            UpdatedDatum.isActive = false
            if (datum.name == item.name) {
                UpdatedDatum.isActive = true
            }
            return UpdatedDatum
        })
        console.log(updatedData)
        setData(updatedData)
    }


    return (
        <>
            {
                data?.map((item, idx, arr) => {
                    return (
                        <>
                            <div className='w-100 d-flex flex-row pt-4 ps-4 pe-4 pb-4' style={{ font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#E5EAF1', background: `${idx === selectedPos ? '#212542 0% 0% no-repeat padding-box' : ''}` }} onClick={() => { handleSelectData(item); setSelectedPos(idx) }}>
                                <div className='me-2 rounded-circle text-center' style={{ background: `${idx === selectedPos ? '#83A0F7 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'}`, width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{idx + 1}</div>
                                {item.name}
                                {/* {
                                    true ?
                                        <div className='ms-auto rounded-circle text-center' ><img src={checkMarkCircle}></img></div>
                                        : <></>
                                } */}
                            </div>
                            {idx !== arr.length - 1 && idx !== selectedPos && idx !== selectedPos - 1 ? <div className='mx-auto' style={{ border: '2px solid #212542', width: '80%' }}></div> : <></>}
                        </>
                    )


                })
            }

        </>
    )
}
