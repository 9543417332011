import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from 'react-icons/bs'
import { DockContext } from '../../../DockWrapper/DockContext'
import { AuthContext } from '../../../Auth/AuthContext'
import Collapse from '../../../utilities/Collapse'

export default function ListModel({ setTitle }) {

    const {
        nodeIndex,
        setIsAgentDocked,
        agentdockSize,
        setAgentContentSize,
        isAgentDocked,
        setIsDraggable,
        isDraggable
    } = useContext(DockContext)
    const { darkMode, IDToken, accessToken } = useContext(AuthContext)

    const toggleDock = () => {
        setIsAgentDocked(!isAgentDocked);
        setIsDraggable(!isDraggable);
        if (isAgentDocked) {
            setAgentContentSize(100)
        }
        else {
            setAgentContentSize(100 - agentdockSize * 100)
        }
    };

    const element = {
        title:
            <div className='w-100 text-black d-flex flex-row justify-content-between align-items-center bg-white p-2'>
                <div className='d-flex flex-row justify-content-between gap-3'>

                    <div className='d-flex flex-column'>
                        <span className='fw-bold'>
                            Model name
                        </span>
                        <span>
                            EDA
                        </span>

                    </div>

                    <div className='d-flex flex-column'>
                        <span className='fw-bold'>
                            Base Model
                        </span>
                        <span>
                            Version 3.0
                        </span>

                    </div>

                    <div className='d-flex flex-column'>
                        <span className='fw-bold'>
                            Task
                        </span>
                        <span>
                            Summarization
                        </span>

                    </div>


                </div>

                <div className='d-flex flex-row gap-2' >
                    <button className='btn btn-primary'>
                        Select
                    </button>
                    <i className="fa-solid fa-angle-down m-auto"></i>
                </div>
            </div>
        ,

        description: <div className='bg-white d-flex text-dark flex-column justify-content-between gap-2' >
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                    <span className='fw-bold'>
                        Date Updated
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>

                <div className='d-flex flex-column'>

                    <span className='fw-bold'>
                        Domain
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>

                <div className='d-flex flex-column'>

                    <span className='fw-bold'>
                        Sub Domain
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>

            </div>

            <div>
                <span className='fw-bold'>Model Description</span>
                <p>
                    fndkfosihafodsjfjsdjifsdjfosdijofsajfosjfdsahn
                </p>

            </div>
        </div>
    }

    const HandleSwapComp = () => {
        // swapping list model comp with model selection
        setTitle("ModelSelection")
    }

    return (
        <div className="h-100 px-0 bg-white text-dark" style={{ overflow: "-moz-hidden-unscrollable" }}>

            <div className="d-flex flex-column  align-items-start mx-0" style={{ overflow: "hidden" }}>
                <Col className="border-top border-bottom w-100 p-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className='p-2  border-end'>

                            All Models (02)
                        </span>

                        <span className='d-flex  justify-content-between gap-1  border-end p-2' onClick={HandleSwapComp}>

                            Back
                        </span>

                        <button className="btn d-inline-flex" onClick={toggleDock}>
                            {isAgentDocked ? (
                                <BsBoxArrowDownLeft style={{ color: "black" }} />
                            ) : (
                                <BsBoxArrowUpRight style={{ color: "black" }} />
                            )}
                        </button>

                    </div>
                </Col>

                <Col className="border-top border-bottom w-100 p-0">


                    <div className='d-flex flex-row gap-3 p-2 justify-content-center'>

                        <div className='d-flex align-items-center'>
                            <i className="fa-solid fa-filter m-auto text-dark"></i>

                        </div>

                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Domain
                            </button>

                            <ul className="dropdown-menu">
                                <li>action</li>
                                <li>action 2</li>
                                <li>action 3</li>
                            </ul>
                        </div>

                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Sub Domain
                            </button>

                            <ul className="dropdown-menu">
                                <li>action</li>
                                <li>action 2</li>
                                <li>action 3</li>
                            </ul>
                        </div>


                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Task
                            </button>

                            <ul className="dropdown-menu">
                                <li>action</li>
                                <li>action 2</li>
                                <li>action 3</li>
                            </ul>
                        </div>

                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Base Model
                            </button>

                            <ul className="dropdown-menu">
                                <li>action</li>
                                <li>action 2</li>
                                <li>action 3</li>
                            </ul>
                        </div>

                    </div>

                </Col>


                <Col>

                    <Collapse title={element.title} description={element.description} id={"modelSelection"} />
                    <Collapse title={element.title} description={element.description} id={"modelSelection1"} />
                    <Collapse title={element.title} description={element.description} id={"modelSelection2"} />
                    <Collapse title={element.title} description={element.description} id={"modelSelection3"} />

                </Col>




            </div>

        </div>
    )
}
