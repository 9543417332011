// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  height: 5%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  width: 100%;
  height: 62px;
  background-color: #ffffff;
  /* background-image: url("../asset/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}
`, "",{"version":3,"sources":["webpack://./src/components/AgentChainModule/ModelCompetancy/components/navbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB;;;2BAGyB;AAC3B","sourcesContent":[".header {\n  display: flex;\n  height: 5%;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 50px;\n  width: 100%;\n  height: 62px;\n  background-color: #ffffff;\n  /* background-image: url(\"../asset/bg.png\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
