import axios from "axios";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";
import "../../AgentModule/CreateAgent/TestAgent.css";
import { AuthContext } from "../../Auth/AuthContext";

function Playground() {
  const { darkMode, accessToken } = useContext(AuthContext)
  const [chainExecutionData, setChainExecutionData] = useState({});
  const [response, setResponse] = useState("");
  const [feed, setFeed] = useState({});
  const [agentName, setAgentName] = useState();
  const [keys, setkeys] = useState([]);
  let { id } = useParams();
  console.log(id);
  // const [agentData, setAgentData] = useState({});

  const [runInput, setRunInput] = useState("");
  const [agent, setAgent] = useState(null);
  const [agentHistory, setAgentHistory] = useState(null);
  const [agentStatus, setagentStatus] = useState(false); // false for running and true for completed
  const [keysIndex, setkeysIndex] = useState(0);
  const [agentNameApi, setAgentNameApi] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [nextAgent, setnextAgent] = useState(1);

  const [ws, setWs] = useState(null);
  const [data, setData] = useState(null);
  // const [pkValue, setPkValue] = useState('');

  const graph = pkValue => {
    const socket = new WebSocket("ws://74.235.164.4:8000/ws/graph/");
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connected");
      const message = {
        pk: pkValue,
        user_id: "123",
        date: "your_date_value",
        method_action: "start_sending_data"
      };
      socket.send(JSON.stringify(message));
    };

    socket.onmessage = event => {
      const message = JSON.parse(event.data);
      console.log("Received message from server:", message);
      setData(message);
    };

    socket.onerror = error => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  };
  const handleClick = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/agentchainexecution/agent-chain-executions/`
      );
      setChainExecutionData(res.data);
      console.log(res.data);

      let arr = Object.keys(
        res.data.agent_chain_executions[0].agent_chain_execution_feed
      );
      console.log(arr);

      setkeys(arr);

      setAgentName(arr[0]);
      setnextAgent(1);
      setagentStatus(
        res.data.agent_chain_executions[0].agent_chain_execution_feed[
          "Status"
        ].toLowerCase() == "Completed".toLowerCase()
      );
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    // handleClick()
    const interval = setInterval(handleClick, 3000);

    // setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[18].feed)

    return () => {
      clearInterval(interval); // Clear interval on unmount
    };
  }, []);

  useEffect(() => {
    if (Object.keys(chainExecutionData).length != 0) {
      const keys = Object.keys(
        chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed
      );
      // setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[keys[keysIndex]]?.feed)
      // setResponse(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[keys[keysIndex]]?.response)

      console.log(
        "obj: ",
        Object.keys(
          chainExecutionData.agent_chain_executions[0]
            .agent_chain_execution_feed
        )
      );
    }
  }, [chainExecutionData]);
  const agentNames = () => {
    axios
      .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`, {
        headers: {

          authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => {
        setAgentNameApi(response.data);
        console.log("Fetch agents success : ");
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleResponse = id => {
    const keys = Object.keys(
      chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed
    );
    setkeysIndex(keys.indexOf(id));
    console.log(keys);
    setResponse(
      chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
        id
      ]?.response
    );
    setFeed(
      chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
        id
      ]?.feed
    );
    console.log(
      chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
        id
      ]?.feed
    );
  };

  useEffect(() => {
    // handleClick()
    const interval = setInterval(handleClick, 3000);

    // setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[18].feed)

    return () => {
      clearInterval(interval); // Clear interval on unmount
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(chainExecutionData).length != 0 &&
      agentNameApi.length != 0
    ) {
      const keys = Object.keys(
        chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed
      );
      setFeed(
        chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
          keys[keysIndex]
        ]?.feed
      );
      setResponse(
        chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
          keys[keysIndex]
        ]?.response
      );

      console.log(
        "obj: ",
        Object.keys(
          chainExecutionData.agent_chain_executions[0]
            .agent_chain_execution_feed
        )
      );
    }
  }, [chainExecutionData]);

  const handleNext = () => {
    console.log(keys);
    if (keys.length - 1 != nextAgent) {
      setAgentName(keys[nextAgent]);
      setnextAgent(nextAgent + 1);
    }
  };

  const handleRun = () => {
    const headers = { "Content-Type": "application/json" };
    const payload = {
      input_data: runInput
    };
    const agentId = chainExecutionData.agent_chain_executions[0].agent_chain_id;
    console.log(agentId);
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/agentchains/run/${id}?user_id=1006`, (payload),
        {
          headers: {

            authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(response => {
        console.log(response.data);
        graph(response.data.id);
        setInterval(agentNames(), 500);

        if (ws) {
          const message = {
            pk: response.data.id,
            user_id: "123",
            date: "your_date_value"
          };
          ws.send(JSON.stringify(message));
        }
        setShow(false);
        setRunInput("")
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleChange = e => {
    const value = e.target.value;
    setRunInput(value);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/149/`, {
        headers: {

          authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => {
        setAgent(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/get_history/149/`, {
        headers: {

          authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => {
        // setAgentHistory(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const runHistory = [
    {
      name: "Run Name",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
    },
    {
      name: "Run Name",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
    },
    {
      name: "Run Name",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
    }
  ];

  return (
    <Container fluid className="playground">
      <div className="px-3">
        <nav className="navbar navbar-expand-lg pb-0">
          <div className="container-fluid pt-3 px-0">
            <span className="" style={{ color: darkMode ? '#BAC9F1' : '' }}>PLAYGROUND</span>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ms-auto">
                <Link
                  className="btn btn-primary cancelBtnDark me-3"
                  to={"/"}
                >
                  Cancel
                </Link>
                <Link
                  className="btn btn-primary submitBtnDark me-3"
                  href="#"
                >
                  Save
                </Link>
                <div className="line me-3"></div>
                <Link
                  className="btn btn-primary cancelBtnDark me-3"
                  to={"../"}
                >
                  Back
                </Link>

                <Link
                  className="btn btn-primary submitBtnDark"
                  to={"/agentfactory"}
                >
                  Create
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Row className="container-fluid mx-0 px-0">
        <Col xs={3} className="col">
          <Container className="container-fluid p-4 agent-playground" style={{ background: darkMode ? '#474F73' : '#f2f3f3', height: "34rem", border: 'none' }}>
            <Row>
              <Col>
                <h6 style={{ color: darkMode ? '#BAC9F1' : '' }}>Run History</h6>
              </Col>
              <Col>
                {/* <Button
                    variant="primary"
                    className="run-btn"
                    onClick={handleShow}
                  >
                    New Run
                  </Button> */}
              </Col>
            </Row>
            {agentHistory &&
              agentHistory.slice(0, 10).map(rh => {
                return (
                  <>
                    <Card className="mt-3 run-history-col" style={{ background: darkMode ? '#212543' : '#f2f3f3', border: 'none' }}>
                      <Card.Body>
                        <h6 style={{ color: darkMode ? '#BAC9F1' : '' }}>{rh.name}</h6>
                        <p style={{ color: darkMode ? '#fff' : '' }}>{rh.status}</p>
                        <Row>
                          <Col>{rh.num_of_calls} CALLS</Col>
                          {rh.time_difference.hours == 0 && (
                            <Col>{rh.time_difference.minutes} MINS AGO</Col>
                          )}
                          {rh.time_difference.hours > 0 && (
                            <Col>{rh.time_difference.hours} HRS AGO</Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </>
                );
              })}
          </Container>
        </Col>

        <Col xs={6} className="run-name col">
          <Container className="container-fluid p-4 agent-playground" style={{ background: darkMode ? '#474F73' : '#f2f3f3', border: 'none' }}>
            {/* {data && data.value.feeds.map((feed, index) => {return (
              <Card className="p-0 mb-1">
                <Card.Body>
                  <div key={index} className={`feed feed-${feed.role}`}>
                  <pre>6</pre>
                  <p className="run-name-time">Updated at: {new Date(feed.updated_at).toLocaleString()}</p>
                  </div>
                </Card.Body>
              </Card>
              )})} */}
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" style={{ background: darkMode ? '#212543' : '' }}>
                {response?.length > 0 && (
                  <h2 class="accordion-header" id="headingOne" >
                    <h2
                      class="accordion-button"
                      // type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {response?.length > 0 && String(response)}
                    </h2>
                  </h2>
                )}
                {feed?.length > 0 &&
                  feed.map(field => {
                    console.log(field);
                    return (
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body" style={{ color: darkMode ? '#BAC9F1' : '', border: darkMode ? 'none' : '' }}>
                          {String(field.feed)}

                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Container>

          <Row className="mt-2 justify-content-center align-items-center">
            {/* <Col xs={8} className="ps-0 input-form"> */}
            <InputGroup className={`${darkMode ? 'inputDark' : ''} mt-3 `} >
              <Form.Control
                style={{
                  borderRadius: "4px",
                  marginRight: "20px"
                }}
                placeholder="Enter your prompt and press Enter"
                value={runInput}
                onChange={handleChange}
              />
              <Button
                type="submit"
                className="submitBtnDark"
                id="button-addon2"
                onClick={handleRun}
              >
                Run
              </Button>
            </InputGroup>
            {/* </Col> */}
          </Row>
        </Col>

        <Col xs={3} className="details col">
          <Container className="container-fluid p-4 agent-playground" style={{ background: darkMode ? '#474F73' : '#f2f3f3', border: 'none' }}>
            {/* <Card className="px-0 mx-0 details-desc" style={{ background: "#f4f4f4", cursor: "pointer" }}>
                                <Card.Body className="px-0" onClick={() => { handleResponse(); handleNext() }}>

                                    <h6 className="text-center">{String(agentName)}</h6>
                                    <h6 className="text-center">{response}</h6>
                                    
                                    </Card.Body>
                                </Card> */}

            {agentNameApi.length != 0 &&
              keys.map((current, index) => {
                return index !== keys.length - 1 ? (
                  <>
                    <Card
                      className="px-0 mx-0 details-desc mb-3"
                      // style={{ background: "#f4f4f4", cursor: "pointer" }}
                      style={{ background: darkMode ? '#212543' : '#f4f4f4', border: 'none', cursor: "pointer" }}
                    >
                      <Card.Body
                        className="px-0"
                        onClick={() => {
                          handleResponse(current);
                          handleNext();
                        }}
                      >
                        {agentNameApi.map(agents => {
                          return agents.agent.id === parseInt(current) ? (
                            <h6 className="text-center p-2" style={{ color: darkMode ? '#BAC9F1' : '' }}>
                              {String(agents.agent.agent_name)}
                            </h6>
                          ) : (
                            ""
                          );
                        })}

                        <h6 className="text-center" style={{ color: darkMode ? '#fff' : '' }}>
                          {/* {agent.agent.agent_name} */}
                        </h6>
                        <h6 className="text-center" style={{ color: darkMode ? '#fff' : '' }}>
                          {/* {response} */}
                        </h6>
                      </Card.Body>
                    </Card>{" "}
                  </>
                ) : (
                  ""
                );
              })}
          </Container>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Run Agent Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="input">
              <Form.Label>Input</Form.Label>
              <Form.Control
                type="input"
                value={runInput}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancelBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button variant="primary" className="submitBtn" onClick={handleRun}>
            Run
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <p className="copyright">© TCS 2024</p> */}
    </Container>
  );
}
export default Playground;
