import Nav from "react-bootstrap/Nav";
import "../AgentChainModule/CreateAgentChain.css";
// import CreateAgentChainNav from "../AgentChainModule/CreateAgentChainNav";
import { Link } from "react-router-dom";

function GraphNav() {
  return (
    <div className="container-fluid pt-4 ps-4">
      <div className="row mx-0 mb-3">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-2"> Model Orchestrator </h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav
            variant="pills"
            defaultActiveKey="#"
            className="agentWizard"
          >
            <Nav.Item className="">
              <Nav.Link
                as={Link}
                to="#"
                eventKey="#"
                className="linkPill mx-auto mb-1"
              >
                1
              </Nav.Link>
              <span className="pilltext"> Select Model</span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className="linkPill mx-auto mb-1"
              >
                2
              </Nav.Link>
              <span className="pilltext">Fine-tuning </span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className="linkPill mx-auto mb-1"
              >
                3
              </Nav.Link>
              <span className="pilltext">Reward & PPO </span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className="linkPill mx-auto mb-1"
              >
                4
              </Nav.Link>
              <span className="pilltext">Preview Model </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default GraphNav;
