import Card from "react-bootstrap/Card";
import ellipsisIcon from "../../../assets/img/ellipsis.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import InputGroup from "react-bootstrap/InputGroup";
import searchIcon from "../../../assets/img/searchIcon.png";
import React, { useState, useEffect } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import axios from "axios";
import avtar2 from "../../../assets/img/agentAvatar.svg";
import { Row, Col } from "react-bootstrap";
import AlchemNav from "../../../components/AgentModule/AlchemNav/AlchemNav";
import CompetencyNav from "../CompetencyNav";
import downArrow from "./asset/down_arrow.svg";
import CompetencyDetailsNav from "./CompetencyDetailsNav";
import starSolid from "../../../assets/img/star-solid.svg";
import starReg from "../../../assets/img/star-regular.svg";
import UploadDragZone from "./UploadDragZone";

const ListCompetancy = props => {
  const [agentchain, setagentchain] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/competencies`)
      .then(response => {
        setagentchain(response.data.competencies);
        console.log("Fetch agentchains success : ");
        console.log(response.data.competencies);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const navigate = useNavigate();

  const handleClick = (e, id) => {
    let path = "details/:id";
    // navigate(path, {id});
    navigate(generatePath("details/:id", { id }));
    console.log(id);
  };

  const handleConfig = e => {
    e.preventDefault();
    navigate("configure", {
      state: {
        competency: {
          competency_name: "",
          agent_chain_mapping: "",
          user_id: 1,
          feedback: "A new feedback",
          tag_line: "",
          competency_type: "",
          industry_domain: "",
          industry_sub_domain: "",
          id: "",
          uploaded_files: [""]
        },
        isUpdate: false
      }
    });
  };

  return (
    <div>
      {/* <AlchemNav /> */}
      {/* <CompetencyNav/> */}
      <CompetencyDetailsNav />
      <div className="competencyBg">
        <Navbar expand="lg" className="bg-body-tertiary subNav pb-3 pt-4">
          <Container fluid className="px-4">
            <Navbar.Brand href="#">
              <h2 className="competenciesTitle">
                Knowledge Work ({agentchain.length})
              </h2>
            </Navbar.Brand>
            <Link
              onClick={handleConfig}
              className="submitBtn"
              style={{ padding: "8px 18px" }}
            >
              {" "}
              + Configure
            </Link>
          </Container>
        </Navbar>
        <Navbar expand="lg" className="bg-body-tertiary subNav pt-1 pb-0">
          <Container fluid className="px-4">
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScrolls" className="nav-2">
              <Nav
                className="m-auto competency-reactflow-header"
                // style={{ maxHeight: "100px" }}
                defaultActiveKey="deployed"
                navbarScroll
              >
                <Nav.Link
                  eventKey="deployed"
                  className="my-auto pe-2 ml-4 deployedTab"
                >
                  <div>Deployed</div>
                </Nav.Link>
                <Nav.Link
                  eventKey="draft"
                  className="my-auto pe-2 draftTab deployedTab"
                >
                  <div className="draftTabText">Draft</div>
                </Nav.Link>
                <div style={{ paddingLeft: "465px" }}></div>
                <span className="my-auto pe-2 text-uppercase">
                  Knowledge Work
                </span>
                <NavDropdown
                  className="btn modelSelectDropdown my-auto pe-2"
                  title={
                    <div className="d-flex justify-content-around">
                      All
                      <img src={downArrow} />
                    </div>
                  }
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
                <div class="line me-3 mt-3"></div>
                <span className="my-auto pe-2">INDUSTRY</span>
                <NavDropdown
                  className="btn modelSelectDropdown my-auto pe-2"
                  title={
                    <div className="d-flex justify-content-around">
                      All
                      <img src={downArrow} />
                    </div>
                  }
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
                <div class="line me-3 mt-3"></div>
                <span className="my-auto pe-2">INDUSTRY SUB DOMAIN</span>
                <NavDropdown
                  className="btn modelSelectDropdown my-auto pe-2"
                  title={
                    <div className="d-flex justify-content-around">
                      All
                      <img src={downArrow} />
                    </div>
                  }
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="wrapper-comp">
          <div className=" compCardHolder d-flex flex-wrap justify-content-start mb-0 border-top-0">
            {agentchain.map(variant => (
              <>
                <div className=" col-md-3 mb-4">
                  <Card
                    bg="Light"
                    onClick={e => handleClick(e, variant.id)}
                    style={{ height: "100%", cursor: "pointer" }}
                  >
                    <Card.Header className="agentDate agentCardHead">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <img
                            className="img-fluid"
                            loading="lazy"
                            src={avtar2}
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>{variant.competency_name}</h6>
                          <p className="mb-0">Your Friendly Assistant</p>
                        </div>
                        <div className="col-1 px-0">
                          <img
                            className="img-fluid"
                            loading="lazy"
                            src={ellipsisIcon}
                            style={{ paddingLeft: "10px", paddingTop: "10px" }}
                            alt=""
                          />
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {/* <Card.Title className="cardSubTitle mb-0 mt-2 pt-3">
                    Created By Name
                  </Card.Title>
                  <Card.Title className="cardName">
                    {variant.competency_name}
                  </Card.Title>
                  <Card.Text className="cardText">
                    {variant.chain_name}
                  </Card.Text> */}
                      <Row>
                        <Col xs={6}>
                          <Card.Title className="competencyCardTitle">
                            Industry
                          </Card.Title>
                          <Card.Text>{variant.industry_domain}</Card.Text>
                          <Card.Title className="competencyCardTitle">
                            Knowledge Work
                          </Card.Title>
                          <Card.Text>{variant.competency_name}</Card.Text>
                          <div
                            style={{ width: "115px", height: "27px" }}
                            className="bubble "
                          >
                            {" "}
                            <img className="" src={starSolid} />{" "}
                            <img src={starSolid} /> <img src={starSolid} />{" "}
                            <img src={starSolid} /> <img src={starReg} />{" "}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <Card.Title
                            className="competencyCardTitle"
                            style={{ paddingTop: "2rem" }}
                          >
                            Industry sub domain
                          </Card.Title>
                          <Card.Text>{variant.industry_sub_domain}</Card.Text>
                          <Card.Title className="competencyCardTitle">
                            Configured On
                          </Card.Title>
                          <Card.Text>
                            {new Date(variant.created_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card.Body>
                    {/* <Card.Footer className="agentCardFooter agentDate">
                  Updated On :{" "}
                  {new Date(variant.updated_at).toLocaleDateString("en-GB")}
                </Card.Footer> */}
                  </Card>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCompetancy;
