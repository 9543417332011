import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaRegStar, FaStar } from "react-icons/fa";
import up_arrow from "../../assets/img/angle-up-solid.svg";
import avatar from "../../assets/img/avtar.png"; // Import the AgentWidget component
import searchIcon from "../../assets/img/searchIcon.png";
import { AuthContext } from '../Auth/AuthContext';
import AgentWidget from './agentWidget';
import './agentchain.css';



const AgentList = () => {
  const { darkMode, accessToken } = useContext(AuthContext)
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null); // New state to track the selected agent
  const [widgetVisible, setWidgetVisible] = useState(false);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`,
          {
            headers: {
              "Content-Type": "application/json",
              "authorization": `Bearer ${accessToken}`
            },
          }
        );
        setAgents(response.data); // Assuming response.data is an array of agents
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, []);

  const onDragStart = (event, nodeType, id) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('id', id);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleAgentClick = (agent) => {
    setSelectedAgent(agent);
    setWidgetVisible(true);
  };
  const handleWidgetClose = () => {
    setSelectedAgent(null);
    setWidgetVisible(false);
  };
  return (
    <div>
      {/* Conditionally render the AgentWidget based on the selectedAgent state */}
      {selectedAgent && <AgentWidget
        agent={selectedAgent}
        isVisible={widgetVisible}
        onClose={handleWidgetClose}
      />}
      <div className="h-auto mt-2 w-auto " >
        <aside className=" p-0 d-flex justify-content-start flex-column  h-100" style={{ backgroundColor: darkMode ? "#212542" : "" }}>
          <div className="w-100" style={{ height: '37rem' }}>
            <div className=" d-flex justify-content-around flex-column w-100" style={{ borderBottom: darkMode ? "#aac7ff 1px solid" : "1px solid #d5d5d5" }}>
              <div className={"cat-rat-outer " + (darkMode ? "darkBackground" : "")}>
                <div className="cat-rat-inner">
                  Agents
                </div>
                <div className="cat-rat-inner">
                  <InputGroup
                    className={darkMode ? 'searchWidth alchemSearchGroupDark' : 'searchWidth alchemSearchGroup'}
                    style={{ paddingRight: "20px" }}>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      className="searchBar"
                    />
                    <Button
                      variant="outline-secondary"
                      id="searchButton"
                      className="searchGroupBtn"
                    >
                      <img
                        className="img-fluid"
                        loading="lazy"
                        src={searchIcon}
                        alt=""
                      />
                    </Button>
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="container-fluid h-100 d-inline-block agent-chain-list-n-rating " style={{ backgroundColor: darkMode ? "#212542" : "" }}>
              <div className="row h-100 g-0" style={{ backgroundColor: darkMode ? "#212542" : "" }}>
                <div className="col-md-6 pr-4 " style={darkMode ? { backgroundColor: ("#212542"), borderRight: ("1px solid #aac7ff") } : { borderRight: (" 1px solid #d5d5d5") }}>
                  <div className="d-flex agent-chain-list" >
                    {agents.map(agent => (
                      <div
                        key={agent.id}
                        className={"dndnode " + (darkMode ? "px-2" : "")}
                        style={darkMode ? { backgroundColor: "#34385A", border: 'none' } : {}}
                        onDragStart={(event) => onDragStart(event, agent.agent.agent_name, agent.agent.id)}
                        draggable
                        onClick={() => handleAgentClick(agent)} // Add click handler
                      >
                        <img width="24px" src={avatar} alt="star regular" />
                        <p className='dndnodeText' style={darkMode ? { color: "#BAC9F1" } : {}}>{agent.agent.agent_name}</p>
                        <div style={{ borderRadius: "50%", background: (!darkMode ? "transparent linear-gradient(180deg, #FFFFFF 0%, #AAC7FF 100%) 0% 0% no-repeat padding-box" : "#aac7ff"), width: "20px", height: "20px", marginLeft: "3px", fontWeight: "bold", color: "darkblue" }}>i</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={"col-md-6 bg-white p-0 m-0 " + (darkMode ? "darkBackground" : "")}>
                  <div className="cat-rat-inner-three border-bottom mt-2">
                    <a className="cat-rat-text  w-100 d-flex justify-content-between" data-toggle="collapse" href="#hel" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <p className="pl-3" style={darkMode ? { color: "#aac7ff" } : {}}>Categories</p>
                      <img className="cat-rat-star mr-4" src={up_arrow} alt="star regular" />
                    </a>
                    <br />
                    <div id='hel'>
                      <input className="form-check-input " type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <label for="vehicle1" style={darkMode ? { color: "#E5EAF1" } : {}}>Banking</label><br />
                      <input className="form-check-input " type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                      <label for="vehicle2" style={darkMode ? { color: "#E5EAF1" } : {}}>Finance</label><br />
                      <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
                      <label for="vehicle2" style={darkMode ? { color: "#E5EAF1" } : {}}>Health</label><br />
                      <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
                      <label for="vehicle2" style={darkMode ? { color: "#E5EAF1" } : {}}>Retail</label><br />
                      <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
                      <label for="vehicle2" style={darkMode ? { color: "#E5EAF1" } : {}}>Privacy</label><br />

                    </div>
                  </div>
                  <div className="cat-rat-inner-three mt-2 p-0">
                    <div className="cat-rat-text w-100 d-flex justify-content-between" data-toggle="collapse" href="#hel2" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <p className="pl-3" style={darkMode ? { color: "#aac7ff" } : {}}>Rating</p>
                      <img className="cat-rat-star mr-4" src={up_arrow} alt="star regular" />
                    </div>
                    <br />
                    <div id='hel2'>
                      <div className="feedback-wrappern  justify-content-center aline-item-center ">

                        <input className="form-check-input " type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                        <div className="feedback">

                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />



                        </div>
                      </div>
                      <div className="feedback-wrappern justify-content-center aline-item-center ">
                        <input className="form-check-input " type="checkbox" id="vehicle2" name="vehicle2" value="Car" />

                        <div className="feedback">
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                        </div>
                      </div>
                      <div className="feedback-wrappern  justify-content-center aline-item-center ">
                        <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />

                        <div className="feedback">
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                        </div>
                      </div>
                      <div className="feedback-wrappern  justify-content-center aline-item-center ">
                        <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />


                        <div className="feedback">
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                        </div>
                      </div>
                      <div className="feedback-wrappern justify-content-center aline-item-center ">
                        <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />

                        <div className="feedback">
                          <FaStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                          <FaRegStar style={{ color: darkMode ? '#BAC9F1' : '' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>

      </div>
    </div>

  );
};

export default AgentList;
