// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EvaluateAndGraphouter{
    /* width: 100%; */
    height: 2rem;
    width: 100%;
    /* background-color: rebeccapurple; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    flex-direction: row;
    margin-bottom: 10px;
}

  
`, "",{"version":3,"sources":["webpack://./src/components/ModelOrchestrator/FineTunining/EvaluateAndGraph.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,qCAAqC;IACrC,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".EvaluateAndGraphouter{\n    /* width: 100%; */\n    height: 2rem;\n    width: 100%;\n    /* background-color: rebeccapurple; */\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    box-sizing: border-box;\n    padding-left: 10px;\n    flex-direction: row;\n    margin-bottom: 10px;\n}\n\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
