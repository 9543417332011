import Card from "react-bootstrap/Card";
import avtar from "../../../assets/img/agentAvatar.svg";
import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate, generatePath , Link} from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import axios from "axios";
import { AuthContext } from "../../Auth/AuthContext";

const AgentChains = () => {
  const [agentchain, setAgentchain] = useState([]);
  const { darkMode } = useContext(AuthContext);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/agentchains/chains/`)
      .then(response => {
        setAgentchain(response.data.chains);
        console.log("Fetch agentchains success : ");
        console.log(response.data.chains);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const navigate = useNavigate();

  const handleClick = (e, id) => {
    navigate(generatePath("playground/:id", { id }));
    console.log(id);
  };

  return (
    <div>
      <div className="container-fluid px-0">
        <div className="row mx-0">
          {agentchain.map(variant => (
            <div 
            key={variant.id}
            className="col-md-3 mb-4"
            >
              <Card
                
                className={
                  darkMode
                    ? "mb-2 agentCardBodyTopPadDark agentCardDark "
                    : "mb-2 whiteBg agentCard"
                }
                onClick={e => handleClick(e, variant.id)}
              >
                <Card.Header
                  className={
                    darkMode
                      ? "agentDate agentCardHeadDark"
                      : "agentDate agentCardHead"
                  }
                >
                  <div className="row mx-0">
                    <div className="col-3 px-0">
                      <img
                        className="img-fluid avtarImg"
                        loading="lazy"
                        src={avtar}
                        alt=""
                      />
                    </div>
                    <div className="col-8">
                      <h6>{variant.chain_name}</h6>
                      <p className="mb-0">Your Friendly Assistant</p>
                    </div>
                    <div className="col-1 px-0">
                      <HiDotsVertical
                        style={{ color: darkMode ? "white" : "inherit" }}
                      />
                    </div>
                  </div>
                </Card.Header>
                
                <Link to={"/playground/" + variant.id}>
                  <Card.Body className="agentCardBodyTopPad">
                    <Card.Title
                      className="competencyCardTitle mb-0"
                      style={{ color: darkMode ? "#BAC9F1" : "" }}
                    >
                      Created By
                    </Card.Title>
                    <Card.Title
                      className="cardName"
                      style={{ color: darkMode ? "#BAC9F1" : "" }}
                    >
                      
                    </Card.Title>
                    <Card.Title
                      className="competencyCardTitle mb-0"
                      style={{ color: darkMode ? "#BAC9F1" : "" }}
                    >
                      Purpose
                    </Card.Title>
                    <Card.Text style={{ color: darkMode ? "#fff" : "" }}>
                    </Card.Text>

                    <Row>
                      <Col xs={6}>
                        <Card.Title
                          className="competencyCardTitle"
                          style={{ color: darkMode ? "#BAC9F1" : "" }}
                        >
                          Created On
                        </Card.Title>
                        <Card.Text style={{ color: darkMode ? "#fff" : "" }}>
                          {new Date(variant.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </Card.Text>
                      </Col>
                      <Col xs={6}>
                        <Card.Title
                          className="competencyCardTitle"
                          style={{ color: darkMode ? "#BAC9F1" : "" }}
                        >
                          Updated On
                        </Card.Title>
                        <Card.Text style={{ color: darkMode ? "#fff" : "" }}>
                          {new Date(variant.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Link>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentChains;
