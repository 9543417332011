import axios from "axios";
import e from "cors";
import { jwtDecode } from "jwt-decode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import blank from "../../../../assets/img/Mask Group 3@2x.png";
import AgentAvatar1 from "../../../../assets/img/agentAvatar1.png";
import uploadIcon from "../../../../assets/img/upload-agent.png";
import "../CreateAgent.css";
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from "react-icons/bs";
import DynamicInput from "../DynamicInput";
import { AuthContext } from "../../../Auth/AuthContext";
import { DockContext } from "../../../DockWrapper/DockContext";
import LoadModal from "../../../RecoModule/LoadModal";

function AgentDetails({ setTitle, data, setData, isSave, setSelectedPos, finished, setFinished, setIsSave }) {
  const {
    activity_master_json,
    selectedKnowledgeWork,
    isAgentDetailsChanged, setIsAgentDetailsChanged
  } = useContext(DockContext)
  const { darkMode, IDToken, accessToken } = useContext(AuthContext)
  let user = jwtDecode(IDToken)
  const navigate = useNavigate();
  const { state } = useLocation();
  const [agents, setAgents] = useState([]);
  const [showAgentIcon, setShowAgentIcon] = useState(false);
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null)
  console.log(state)
  const handleClose = () => {
    setShowAgentIcon(false);
    setSelectedFile(null);
  };
  const handleShow = () => setShowAgentIcon(true);


  const [agentForm, setAgentForm] = useState({
    goal: [],
    instructions: [],
    tools: [],
  });

  const [content, setContent] = useState("");

  const handleChange = e => {
    const { name, value } = e.target;
    setIsAgentDetailsChanged(true)
    setDetails(prev => {
      return { ...prev, [name]: value };
    });
  };

  function agentValues() {
    console.log("Form Submitted", agentForm);
    goalFormValues.forEach(element => {
      agentForm.goal.push(element.value);
    });

    instructionsFormValues.forEach(element => {
      agentForm.instructions.push(element.value);
    });
    console.log("Post updating values", agentForm);
    navigate("/agentfactory/createAgent/selectModel", { state: agentForm });
  }



  // add and delete Goals Dynamically
  const [goalFormValues, setGoalFormValues] = useState([
    {
      label: "goal",
      type: "text",
      value: ""
    }
  ]);

  const handleGoalChange = (e, index) => {
    const values = [...goalFormValues];
    values[index].value = e.target.value;
    setGoalFormValues(values);
  };

  const handleGoalDeleteField = (e, index) => {
    const values = [...goalFormValues];
    values.splice(index, 1);
    setGoalFormValues(values);
  };

  const handleGoalAddField = e => {
    e.preventDefault();
    const values = [...goalFormValues];
    values.push({
      label: "goal",
      type: "text",
      value: ""
    });
    setGoalFormValues(values);
  };

  // add and delete Tools Dynamically
  const [toolsFormValues, setToolsFormValues] = useState([
    {
      label: "tools",
      type: "text",
      value: ""
    }
  ]);

  const handleToolsChange = (e, index) => {
    const values = [...toolsFormValues];
    values[index].value = e.target.value;
    setToolsFormValues(values);
  };

  const handleToolsDeleteField = (e, index) => {
    const values = [...toolsFormValues];
    values.splice(index, 1);
    setToolsFormValues(values);
  };

  const handleToolsAddField = e => {
    e.preventDefault();
    const values = [...toolsFormValues];
    values.push({
      label: "tools",
      type: "text",
      value: ""
    });
    setToolsFormValues(values);
  };

  // add and delete Instructions Dynamically
  const [instructionsFormValues, setInstructionsFormValues] = useState([
    {
      label: "goal",
      type: "text",
      value: ""
    }
  ]);

  const handleInstructionsChange = (e, index) => {
    const values = [...instructionsFormValues];
    values[index].value = e.target.value;
    setInstructionsFormValues(values);
  };

  const handleInstructionsDeleteField = (e, index) => {
    const values = [...instructionsFormValues];
    values.splice(index, 1);
    setInstructionsFormValues(values);
  };

  const handleInstructionsAddField = () => {
    console.log("called")
    const values = [...instructionsFormValues];
    values.push({
      label: "goal",
      type: "text",
      value: ""
    });
    setInstructionsFormValues(values);
  };

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };




  /** File Handling  */
  const [file, setFile] = useState(blank);
  const [prevIcon, setPrevIcon] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const agentIcons = [AgentAvatar1];
  const do_uploadIcon = () => {
    setPrevIcon(file);
    setShowAgentIcon(true);
  };
  const handleFileChange = event => {
    setSelectedFile(URL.createObjectURL(new Blob(event.target.files)));
  };
  const handleDrop = event => {
    event.preventDefault();
    setSelectedFile(URL.createObjectURL(new Blob(event.target.files)));
  };
  const updateIcon = img => {
    setSelectedFile(img);
  };
  const updateAgentIcon = () => {
    setFile(selectedFile);
    handleClose();
  };


  // ----------------------------------------------------------------------------------------

  const [tabLinks, setTabLinks] = useState([
    { name: "goal", route: "", isActive: true },
    { name: "instructions", route: "", isActive: false },
    { name: "tools", route: "", isActive: false },
  ])

  const updateAgentForm = () => {
    let data = { ...agentForm }
    let updated_fields = instructionsFormValues.filter((item) => item.value ? true : false).map((item) => item.value)
    console.log(updated_fields)
    let arr = data["goal"].concat(updated_fields)
    data["goal"] = updated_fields
    console.log(updated_fields, arr, data["goal"])
    setAgentForm(data)
    console.log(data)


  }

  const handleTabChange = (index) => {
    setTabLinks(prevLinks =>
      prevLinks.map((link, i) => {

        if (link.isActive) {
          let data = { ...agentForm }
          let key = link.name
          console.log(data[key], key, data)
          console.log(instructionsFormValues)
          let updated_fields = instructionsFormValues.filter((item) => item.value ? true : false).map((item) => item.value)
          console.log(updated_fields)
          let arr = data[key].concat(updated_fields)
          data[key] = updated_fields
          console.log(updated_fields, arr, data[key])
          setAgentForm(data)
          console.log(data)
        }

        if (i === index) {
          if (typeof (agentForm[link.name]) === typeof ([]) && agentForm[link.name]?.length >= 1) {
            console.log(agentForm[link.name])
            let arr = agentForm[link.name]
            console.log(arr)
            let updated_data = []
            arr.map((item, i) => {

              let obj = {
                label: link.name,
                type: "text",
                value: item
              }

              updated_data.push(obj)

            })

            setInstructionsFormValues(updated_data)
            console.log(updated_data)
          } else {
            setInstructionsFormValues([{
              label: link.name,
              type: "text",
              value: ""
            }])
          }
        }

        return { ...link, isActive: i === index }
      }
      )
    )
  }

  const [allDone, setAllDone] = useState(false)
  const [details, setDetails] = useState(
    {
      "name": "",
      "description": "",
      "role": "",
      "goal": "",
      "human_input_required": true,
      "status": true,
      "finetuned_model_type": "",
      "predictive_model_id": 0,
      "generative_model_id": 0,
      "kpi": "",
      "id": 0,
      "tasks": [
        {
          "description": "",
          "expected_output": "",
          "id": 0
        }
      ]
    }
  )

  const [allAgentUpdateDone, setAllAgentUpdateDone] = useState(false)

  const MoveTONextModel = () => {

    setAllAgentUpdateDone(false)
    let updatedData = []
    let next_index = undefined

    if (data.length === 1 || allAgentUpdateDone) {
      setAllAgentUpdateDone(true)
      return ""
    }

    data.map((item, index) => {

      let obj = { ...item }
      if (obj.isActive) {
        obj.isActive = false
        obj.isCompleted = true
        next_index = index + 1
        console.log(index, "made false")
      }
      if (index === next_index) {
        console.log(index, next_index, "before made true and undefied")
        obj.isActive = true
        finished.push(next_index - 1)
        setFinished([...finished])
        setSelectedPos(next_index)
        next_index = undefined
        if (index == data.length - 1) {
          setAllAgentUpdateDone(true)
          next_index = 0
        }

        console.log(index, next_index, "after made true and undefied")
        console.log(index, "made true")
      }


      console.log(index, "all")

      updatedData.push(obj)
    })
    // setAllDone(!updatedData.find((item) => (item.isCompleted === false)))
    setData(updatedData)

  }

  const populateInstructionField = (goals) => {
    const updatedFormField = []
    goals.map((goal) => {
      let templateObj = {
        label: "goal",
        type: "text",
        value: goal
      }
      updatedFormField.push(templateObj)
    })

    console.log(updatedFormField)
    setInstructionsFormValues(updatedFormField)
  }


  const populateDetails = () => {
    console.log(data)
    data?.map((item, index) => {

      if (item.isActive) {
        activity_master_json?.activities.map((activity) => {
          if (item.name == activity.name) {
            console.log(activity)
            setDetails(activity)
            console.log(activity.goal)
            setAgentForm(
              {
                goal: activity?.goal.split("/"),
                instructions: [],
                tools: []
              })
            populateInstructionField(activity?.goal.split("/"))
          }

        })
      }
    })
  }


  useEffect(() => {
    populateDetails()
  }, [])



  useEffect(() => {
    populateDetails()
  }, [activity_master_json, data])


  const [tasks, setTasks] = useState(details.tasks)


  useEffect(() => {
    setTasks(details.tasks)
  }, [details])

  const handleTaskChange = (e, id) => {
    setIsAgentDetailsChanged(true)
    let updatedItem = {}
    let oldTask = tasks
    let updatedTask = []

    oldTask.map((item) => {
      updatedItem = { ...item }
      if (item.id == id) {
        const { name, value } = e.target
        updatedItem[name] = value
      }
      updatedTask.push(updatedItem)
    })

    setTasks(updatedTask)

    setDetails({
      ...details, tasks: updatedTask
    })

  }

  const HandleTestCompRedirection = () => {
    console.log("clicked")
    if (!selectedKnowledgeWork.id || !details.id) {
      alert("Something went wrong, redirecting to knowledge work list")
      console.log('ZABC', selectedKnowledgeWork, details)
      // navigate("/activityList")
    }
    console.log('ZABC', selectedKnowledgeWork, details)
    navigate(`/testagent/${selectedKnowledgeWork?.id}/${details?.id}`)
  }

  const [taskErr, setTaskErr] = useState("")


  const addTask = () => {
    setTaskErr("")
    let status = false
    const payload = {
      "description": "",
      "expected_output": ""
    }

    axios
      .post(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/tasks/`, payload)
      .then((res) => {
        console.log(res.data)
        const updatedTask = tasks.concat(res.data)
        console.log(updatedTask)
        setTasks(updatedTask)
        status = true
        console.log(status)
      })
      .catch((error) => {
        console.log(error)
        status = false
        console.log(status)
      })
    console.log(status)
    return status
  }
  const [isTaskPopulated, setIsTaskPopulated] = useState(true)
  const [isActivityPopulated, setIsActivityPopulated] = useState(true)

  const taskPopulation = async () => {


  }


  const activitiesPopulation = async (data) => {



  }

  const handleMoveNextModelAfterUpdate = () => {
    MoveTONextModel()
  }

  const handleSubmit = async () => {

    try {
      setShow(true)
      setAlertMessage(null)
      console.log("inside func");

      let taskStatus = await taskPopulation();
      setTaskErr("");

      // Map over tasks and return an array of promises  
      const taskPromises = tasks.map((task) => {
        const payload = {
          "description": task.description,
          "expected_output": task.expected_output
        };
        console.log(payload);

        return axios
          .patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/tasks/${task.id}`, payload)
          .then((res) => {
            console.log(res.data);
            return true;
          })
          .catch((error) => {
            console.log(error);
            if (Object.keys(error).includes("response")) {
              setTaskErr(error.response.data);
            } else {
              setTaskErr(error);
            }
            return false;
          });
      });

      // Wait for all task promises to resolve  
      const taskResults = await Promise.all(taskPromises);

      // Check if any task failed  
      const isAnyTaskFailed = taskResults.some(result => !result);
      setIsTaskPopulated(!isAnyTaskFailed);

      if (isAnyTaskFailed) {
        console.log("Some tasks failed");
        return;
      }

      console.log("All tasks completed");

      // Prepare goal string  
      let goal = "";
      console.log(agentForm.goal)
      agentForm.goal.forEach((item) => {
        if (item) {
          goal += item + "/";
        }
      });

      let data = {
        "goal": goal,
        "description": details.description,
        "tasks": tasks
      };
      console.log(data);

      setTimeout(async () => {
        const activityResponse = await axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/activities/${details.id}`, data);

        if (activityResponse.status === 200) {
          console.log(activityResponse.data);
          setDetails(activityResponse.data);
          setIsActivityPopulated(true);
          setAlertMessage("Agent Details Updated")
        } else {
          setIsActivityPopulated(false);
          setAlertMessage("Something went wrong with activity update");
        }

      }, 150)
    } catch (error) {
      console.log(error);
      setIsTaskPopulated(false);
      setIsActivityPopulated(false);
      setAlertMessage("Something went wrong");
    }
  };


  const handleInputFieldAddition = () => {
    console.log("handleInputFieldAddition")
    const goalTab = tabLinks.find((item) => item.name.toLocaleLowerCase() == "goal".toLowerCase())
    const instructionTab = tabLinks.find((item) => item.name.toLocaleLowerCase() == "instructions".toLowerCase())
    console.log(goalTab, instructionTab)

    if (goalTab.isActive) {
      console.log("goal")
      handleInstructionsAddField()
      return ""
    }
    if (instructionTab.isActive) {
      // setNewTask(true)
      addTask()
      return ""
    }

  }


  useEffect(() => {

    if (isSave) {
      console.log("isSaved clicked")
      updateAgentForm()
      handleSubmit()
      setIsSave(false)

    }

  }, [isSave])

  useEffect(() => {
    if (instructionsFormValues.length > 1) {
      updateAgentForm()
    }
    setIsAgentDetailsChanged(true)
  }, [instructionsFormValues])

  const handleTaskDelete = async (taskID) => {
    let taskList = tasks.filter((task) => {
      return task.id != taskID
    })

    const payload = {
      "tasks": taskList
    }
    console.log(payload)
    await axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/activities/${details.id}`, payload)
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data)
          setTasks(res.data.tasks)
        }
      })
      .catch((error) => {
        console.log(error)
        setTaskErr(`${error}`)
      })
  }

  const styleConfig = {
    flexShrink: 0,
    width: "1em",
    height: "1em",
    marginTop: ".25em",
    verticalAlign: "top",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: "rgba(37, 97, 220, 0.81)"
  }

  return (
    <>
      <LoadModal
        showModal={showModal}
        closeModal={closeModal}
        content={content}
        submitModal={agentValues}
      />
      <AlertModal
        show={show}
        setShow={setShow}
        message={alertMessage}
      />
      <div className="container-fluid text-white" style={{ overflow: "scroll" }}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row ps-4 py-4" style={{ background: '#42466C 0% 0% no-repeat padding-box', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#FFFFFF', position: 'sticky', top: '0' }}>
            <div>Agent Details</div>
          </div>
          <div className="d-flex flex-column" style={{ background: '#212542 0% 0% no-repeat padding-box', font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#FFFFFF' }}>
            <div className="py-2 ps-2">Basic Details</div>
            <div className=" py-2 px-2">
              <label
                htmlFor="name"
                class="label-name pt-2 text-white"
              >
                Agent Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={details.name}
                onChange={handleChange}
                className="form-control input-name"
                style={{
                  background: darkMode ? "#212542" : "",
                  color: darkMode ? "#BAC9F1" : "",
                  border: darkMode ? "1px solid #2F499E" : ""
                }}
                readOnly
              />
            </div>
            <div className="px-2 py-2">
              <label
                htmlFor="purpose"
                className="label-purpose pt-3 text-white"
              >
                Agent Purpose
              </label>
              <textarea
                id="purpose"
                name="description"
                value={details.description}
                onChange={handleChange}
                className="form-control input-purpose"
                style={{
                  background: darkMode ? "#212542" : "",
                  color: darkMode ? "#BAC9F1" : "",
                  border: darkMode ? "1px solid #2F499E" : "",
                  minHeight: "25px",
                  maxHeight: "60px"
                }}
              />
            </div>
            <div className="px-2 py-2 d-flex justify-content-start align-items-center gap-2">

              <label className="form-check-label" for="humanInTheLoop">
                Human In The Loop
              </label>

              <input
                className={styleConfig}
                type="checkbox"
                id="humanInTheLoop"
                checked={details?.human_input_required}
                name="humanInTheLoop"
                onChange={(e) => setDetails({ ...details, human_input_required: e.target.checked })}
                style={{
                  background: "#212542",
                  color: "#BAC9F1",
                  border: "1px solid #2F499E",
                  width: "20px",
                  height: "20px",
                  fontSize: "1rem",
                  fontWeight: 400,
                  lineHeight: 1.5
                }}
              />
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                {tabLinks.map((link, index) => (
                  <div className="position-relative d-flex flex-column align-items-center ps-4 pb-2 pt-4"
                    onClick={() => handleTabChange(index)}>
                    <div style={{ textTransform: 'capitalize' }}>
                      {link.name}
                    </div>
                    {link.isActive ? <div style={{ position: 'absolute', bottom: '0px', height: '3px', background: 'rgb(51, 102, 255)', minWidth: '100%' }}></div> : <></>}
                  </div>
                ))}
              </div>
              <div className="d-flex flex-row pe-2 align-items-end">

                <button className="btn h-75 ms-1 mb-1" onClick={handleInputFieldAddition} style={{ background: '#D7E5FF 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#012060' }}>
                  Add
                </button>
              </div>
            </div>
            <div className="d-flex flex-column pt-2">
              {tabLinks?.find((item) => item.name === "goal")?.isActive &&
                < form className="px-2 disable-scrollbars" style={{ overflowY: "scroll" }} onSubmit={(e) => {
                  e.preventDefault()
                }}>
                  {instructionsFormValues?.map((obj, index) => (
                    <DynamicInput
                      key={index}
                      objValue={obj}
                      change={handleInstructionsChange}
                      index={index}
                      deleteField={handleInstructionsDeleteField}
                      darkMode={darkMode}
                    />
                  ))}
                </form>
              }
              {
                tabLinks?.find((item) => item.name === "instructions")?.isActive &&
                <>
                  <span className="text-danger" style={{ fontSize: "10px" }}>
                    {taskErr}
                  </span>
                  {
                    tasks.map((item, i) => {
                      return (
                        <div className="d-flex align-items-center border-bottom mb-4 p-1">

                          <div key={i} className="d-flex flex-column justify-content-between align-items-center flex-grow-1 m-auto p-2">
                            <div className="input-group input-group-sm mb-3">
                              <span className="input-group-text fw-bold text-capitalize text-wrap" id="inputGroup-sizing-sm" style={{ width: "120px" }} >description</span>
                              <input
                                type="text"
                                className="form-control"
                                name="description"
                                style={{
                                  background: darkMode ? "#212542" : "",
                                  color: darkMode ? "#BAC9F1" : "",
                                  border: darkMode ? "1px solid #2F499E" : ""
                                }}
                                aria-label="Sizing example input"
                                value={item.description}
                                onChange={(e) => { handleTaskChange(e, item.id) }}
                                aria-describedby="inputGroup-sizing-sm" />
                            </div>

                            <div className="input-group input-group-sm mb-3">
                              <span className="input-group-text fw-bold text-capitalize text-wrap" id="inputGroup-sizing-sm" style={{ width: "120px" }} >Expected Output</span>
                              <input
                                type="text"
                                className="form-control"
                                name="expected_output"
                                style={{
                                  background: darkMode ? "#212542" : "",
                                  color: darkMode ? "#BAC9F1" : "",
                                  border: darkMode ? "1px solid #2F499E" : ""
                                }}
                                aria-label="Sizing example input"
                                value={item.expected_output}
                                onChange={(e) => { handleTaskChange(e, item.id) }}
                                aria-describedby="inputGroup-sizing-sm" />
                            </div>



                          </div>


                          <i className="fa-solid fa-trash p-1 m-auto" style={{ fontSize: "1rem" }}></i>


                        </div>

                      )
                    })
                  }
                </>
              }
              {
                tabLinks?.find((item) => item.name === "tools")?.isActive &&
                <div className="px-2 pb-2 pt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="upload file"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={details.name}
                    style={{
                      background: darkMode ? "#212542" : "",
                      color: darkMode ? "#BAC9F1" : "",
                      border: darkMode ? "1px solid #2F499E" : ""
                    }}
                    readOnly={true}
                  />
                </div>
              }
            </div>
            <div className="d-flex flex-row justify-content-end pt-4 pb-2">
              <button className="btn me-2" onClick={HandleTestCompRedirection} style={{ background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF' }}>Test agent</button>
              {/* <button
                className="btn mx-2"
                onClick={handleMoveNextModelAfterUpdate}
                style={{ background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF' }}
              >
                Next activity
              </button> */}
            </div>
          </div>
        </div >
      </div >
    </>
  );
}
export default AgentDetails;




export function AlertModal({ message, show, setShow }) {


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate()

  const handleSummaryNavigate = () => {
    setTimeout(() => { navigate("/summary") }, 100)
  }

  return (

    <Modal
      size="lg"
      show={show} onHide={handleClose} key={false}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
      style={{
        zIndex: 9999999999999999
      }}
      dialogClassName={"modal-sm"}
    >
      <Modal.Header className="d-inline-flex text-dark" closeButton>
        <Modal.Title style={{ font: 'normal normal normal 20px/30px Calibri', letterSpacing: '0px', color: '#000000' }}>Updating Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-row my-4 text-dark">
        {!message ? <div className="d-flex flex-row w-100 justify-content-center"><div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div></div> : <div className="ms-2">{message}</div>}
      </Modal.Body>
      <Modal.Footer className="d-inline-flex">
        <button className="btn" onClick={handleSummaryNavigate} style={{ minWidth: "100px", background: '#D7E5FF 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#012060' }}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>

  );
}
