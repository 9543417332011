import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlchemLogo from "../../../assets/img/AlchemLogo.png";
import LogoutLogo from "../../../assets/img/logout.svg";
import LogoWhite from "../../../assets/img/logoWhite.svg";
import logodark from "../../../assets/img/logodark.svg";
import { Link, useNavigate } from "react-router-dom";
import UserThumbnail from "../../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../../assets/img/dropdown-arrow.svg";
import StartButton from "../../../assets/img/start-btn.svg";
import { AuthContext } from "../../Auth/AuthContext";
import { jwtDecode } from "jwt-decode";
import { useContext } from "react";
import Toggle from "../../Toggle/Toggle";
import { FiUser } from "react-icons/fi";

function AlchemNav({ module, handleToggle }) {
  const { IDToken, darkMode } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleHome = () => {
    return navigate("/");
  };
  var user = jwtDecode(IDToken);
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={darkMode ? "navWithBgDark pb-0" : "navWithBg pb-0"}
    >
      <Container fluid style= {{paddingLeft: "40px", paddingRight: "40px"}}>
        <Navbar.Brand
          onClick={handleHome}
          style={{
            cursor: "pointer",
            paddingBottom: "1px",
            paddingBottom: "8px",
            paddingTop: "0px"
          }}
        >
          <img
            className="img-fluid logoSize"
            loading="lazy"
            src={darkMode ? AlchemLogo : LogoWhite}
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className={
              (module.state == "wizard" ? "hidden" : "") +
              " " +
              "me-auto alchemNavLinks"
            }
          >
            <Nav.Link
              className={(module.name == "agentFactory" ? "active" : "") + " "}
              to="/rewardmodelHome"
              as={Link}
            >
              Model Orchestrator
            </Nav.Link>
            <Nav.Link
              // className="nav-pt"
              to="/agentFactory"
              as={Link}
              className={(module.name == "agent" ? "active" : "") + " "}
            >
              Agent Factory
            </Nav.Link>{" "}
          </Nav>

          <Nav>
            {/* <Nav.Link href="#deets" className="navWhite">
              Mayur Jadhav
            </Nav.Link>
            <Nav.Link eventKey={2} href="#" className="navWhite">
              <img
                className="img-fluid"
                loading="lazy"
                src={LogoutLogo}
                alt=""
              />
            </Nav.Link>{" "} */}
            {/* <div className="px-2 h-100 pt-2">
              <Toggle darkMode={darkMode} handleToggle={handleToggle} />
            </div> */}
            <NavDropdown
              title={
                <>
                <FiUser style={darkMode ? { color: "#E5EAF1" } : {}}/>
                  <span
                    className="user-name"
                    style={darkMode ? { color: "#E5EAF1" } : {}}
                  >
                    {user.name}
                  </span>
                  <img src={DropDownArrow} />
                </>
              }
              id="navbarScrollingDropdown"
              className="btn user-profile m-0 dropdownMb mr-3"
              style={
                darkMode ? { backgroundColor: "#34385A", border: "none" } : {}
              }
            >
              <NavDropdown.Item href="#action3">User Profile</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
              <NavDropdown.Item to="/">Logout</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link to="#" style={{ paddingLeft: "23px" }}>
              <img src={StartButton} />
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AlchemNav;
