import "./ModelHomeScreen.css";
import Button from "react-bootstrap/Button";
import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import Card from "react-bootstrap/Card";
import CompareModels from "./compareModels";
import JsonData from "./tableData.json";
import DotIcon from "./dotIcon.png";
// import StarIcon from "./starIcon.svg";
import axios from "axios";
import LoadModal from "./LoadModal";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../Auth/AuthContext";

const ModelHomeScreen = () => {
  const {accessToken} = useContext(AuthContext)
  // const [showModal, setShowModal] = useState(false);

  const [dummyData, setdummyData] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [createdby, setcreatedby] = useState({
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman"
  });

  const [baseModelMapping, setbaseModelMapping] = useState({
    1: "falcon",
    2: "bloom",
    3: "flan t5",
    4: "Llama 2"
  });

  const [taskList, settaskList] = useState({
    1: "Summarization",
    2: "QA",
    3: "Text Generation"
  });
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/basemodels`, {
        headers:{
          Authorization: accessToken
        }
      })
      .then(response => {
        setdummyData(response.data.data);
        console.log("Fetch agents success : ");
      })
      .catch(error => {
        console.error(error);
      });

    openModal();
  }, []);

  const handleRowSelection = index => {
    if (selectedRow === index) {
      setSelectedRow(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow(index); // Select the clicked row
      localStorage.setItem("basemodelid", JSON.stringify(index + 1));
      console.log(index + 1);
    }
  };

  console.log(dummyData);
  const DisplayData = dummyData.map((info, i) => {
    const isSelected = selectedRow === i;

    return (
      <>
        <tr
          key={i}
          // style={{ opacity: isSelected ? 1 : 0.5 }}
          // onClick={() => handleRowSelection(i)}
        >
          <td>
            <input
              type="checkbox"
              className="checkbox"
              checked={isSelected}
              onChange={() => {}} // Disable checkbox change
              onClick={() => handleRowSelection(i)}
            />
          </td>
          {/* <td>

          <Button className="addBtn" data-bs-toggle="collapse" 
          data-bs-target={"#rowId" + i}
          aria-expanded="false"
          role="button" onClick={() => setExpanded(!expanded)}>
            +
          </Button>
        </td> */}
          <td
            data-toggle="collapse"
            data-target={"#rowId" + i}
            className="accordion-toggle"
            aria-expanded="false"
            aria-controls={"#rowId" + i}
            style={{ cursor: "pointer" }}
          ></td>
          {/* <td>
        <button class="btn btn-primary" type="button" data-toggle="collapse" data-target={"#rowId" + i} aria-expanded="false" aria-controls={"#rowId" + i}>
          
        </button>
        </td> */}
          <td id="modelName">{info.name}</td>
          <td>{info.description}</td>
          <td>{baseModelMapping[info.id]}</td>
          {/* <td>{info.dataset_id}</td>
          <td>{info.task_id}</td>
          <td>{info.usecase}</td>
          <td>
            <div className="statusContainer">
              <div
                className="circle"
                style={{
                  background:
                    info.status == "Just created"
                      ? "#1FEB00"
                      : info.status == "Finished"
                      ? "#3366FF"
                      : "#FEA92B"
                }}
              ></div>{" "}
              {info.status}
            </div>
          </td> */}
          {/* {info.rating == "" || info.rating == null ? (
            <td>-</td>
          ) : (
            <td>
              <img className="img-fluid icon" src={StarIcon} alt="" />{" "}
              {info.rating}
            </td>
          )} */}

          {/* <td>
            <button>
              <img
                className="img-fluid icon"
                loading="lazy"
                src={DotIcon}
                alt=""
              />
            </button>
          </td> */}
        </tr>
        <tr>
          <td colspan="9" className="hiddenRow border-bottom">
            <div
              class="collapse subTable_outer multi-collapse"
              id={"rowId" + i}
            >
              <table className="table subTable">
                <thead>
                  <tr className="info">
                    <th>Purpose</th>
                    <th>Status</th>
                    <th>Updated by</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{info.purpose}</td>
                    <td>{info.status}</td>
                    <td>{createdby[info.updated_by]}</td>
                  </tr>
                  <tr>
                    <td colspan="3" className="px-0">
                      <Button className="saveButton p-0 mt-3">Test</Button>
                      <Button className="cancelButton p-0 ms-2 mt-3">
                        Refrain
                      </Button>
                      <Button className="cancelButton p-0 ms-2 mt-3">
                        Edit
                      </Button>
                      <Button className="cancelButton p-0 ms-2 mt-3">
                        Clone
                      </Button>
                      <Button className="deleteButton p-0 ms-3 mt-3">
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </>
    );
  });

  return (
    <div>
      <LoadModal showModal={showModal} closeModal={closeModal} />
      <AlchemNav module={{ name: "agentFactory", state: "nowizard" }} />
      <div className="px-4 pt-4 pageHeadBg">
        {/* <div className="box_container">
          {dummyData.map((data, i) => (
            <div className="box" key={i}>
              <p className="value">{data.value}</p>
              <p className="title">{data.title}</p>
            </div>
          ))}
        </div> */}
        <nav className="navbar navbar-expand-lg pt-0">
          <div className="container-fluid px-0 pb-2">
            <span className="navbar-brand">ALL MODELS</span>
            <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
              <div class="navbar-nav ms-auto px-0">
                {selectedRow == null ? (
                  <NavLink to={""}>
                    <button class="btn btn-primary submitBtn" role="button">
                      + Configure Model
                    </button>
                  </NavLink>
                ) : (
                  <NavLink to={"/finetuning"}>
                    <button class="btn btn-primary submitBtn" role="button">
                      + Proceed
                    </button>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </nav>
        <CompareModels />
        <Card className="mt-4">
          <Card.Body>
            <table class="table alchemTable compareModelTable mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Base Model</th>
                  {/* <th>Data Created</th>
                  <th>Task</th>
                  <th>Domain</th>
                  <th>Status</th> */}
                  {/* <th>Rating</th>
                  <th></th> */}
                </tr>
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ModelHomeScreen;
