import React from "react";
import { RiDeleteBin5Fill } from "react-icons/ri";

export default function DynamicInput({
  objValue,
  change,
  index,
  deleteField,
  darkMode,
}) {
  const { label, type, value } = objValue;
  return (
    <div className="d-flex flex-row align-items-start mx-0 mb-3 w-100">
      <div className="col-8 col-md-11 px-0">
        <input
          type={type || "text"}
          id={label}
          name={label}
          value={value || ""}
          className="form-control"
          onChange={e => change(e, index)}
          aria-describedby="inputGroup-sizing-sm"
          aria-label="Sizing example input"
          style={{ background: darkMode ? '#34385A' : '', color: darkMode ? '#BAC9F1' : '', border: darkMode ? '1px solid #2F499E' : '' }}
        />
      </div>
      <div className="col-4 col-md-1 my-auto" onClick={e => deleteField(e, index)}>
        <RiDeleteBin5Fill style={{ fontSize: '18px', color: darkMode ? '#BAC9F1' : '' }} />
      </div>
    </div>
  );
}
