import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logoWhite from "../../assets/img/logoWhite.svg";
import { AuthContext } from "../Auth/AuthContext";
import { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Nav, NavDropdown } from "react-bootstrap";
import { FaGear } from "react-icons/fa6";
import PropTypes from 'prop-types'
import UserThumbnail from "../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../assets/img/dropdown-arrow.svg";

function NavBar() {
  return (
    <Navbar style={{ height: "10%", paddingTop: ".55rem", paddingBottom: "0" }}>
      <Container fluid style={{ padding: "0 4rem 0 4rem" }}>
        <Navbar.Brand href="#home">
          <img className="img-fluid" loading="lazy" src={logoWhite} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;




export function NavComp({ profile }) {
  const {
    setAuthenticated,
    accessToken,
    darkMode,
    IDToken,
    setIDToken
  } = useContext(AuthContext);


  const [username, setUsername] = useState();
  const [idtoken, setIdtoken] = useState();
  const [access_token, setAccess_token] = useState();


  useEffect(() => {
    // Retrieve the JWT token from local storage
    setIdtoken(IDToken);
    setAccess_token(accessToken);
    if (idtoken) {
      // Decode the JWT token
      const decoded = jwtDecode(idtoken);
      setUsername(decoded.name);
      // setUsername(decoded.)
    }
  }, [idtoken, setIdtoken]);

  function removeCookie(name) {
    document.cookie =
      name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const handleLogout = () => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${access_token}`);
    headers.append("Content-Type", "application/json");
    // Construct logout URL with headers
    removeCookie("accessToken");
    removeCookie("refreshToken");
    removeCookie("IDToken");
    setAuthenticated(false);
  };



  return (
    <>
      {profile ? (
        <Nav>
          <NavDropdown
            title={
              <>
                <img src={UserThumbnail} />
                <span
                  className="user-name"
                  style={darkMode ? { color: "#E5EAF1" } : {}}
                >
                  {username}
                </span>
                <img src={DropDownArrow} />
              </>
            }
            id="navbarScrollingDropdown "
            className={
              "btn user-profile m-0 " + darkMode ? " user-drop-down" : ""
            }
            style={{ zIndex: "99999999999999999999999999" }}

          >
            <NavDropdown.Item href="#action3">
              User Profile
            </NavDropdown.Item>
            <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLogout()}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      ) : (
        <div className="d-flex justify-center align-items-center">
          <FaGear className="text-white" style={{ fontSize: "18px" }} />
        </div>
      )}
    </>
  )
}

NavComp.propTypes = {
  profile: PropTypes.bool.isRequired
}