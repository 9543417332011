import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import checkMarkCircle from '../../../assets/img/check_mark_circle.svg'
import NormalChat from '../../AgentModule/CreateAgent/chat/NormalChat';
import Accordion from './Accordion';
import { DockContext } from '../../DockWrapper/DockContext'
import dottedPatternBG from '../../../assets/img/doted-patternbg.png'

export default function SmeChainModelView({ agentData, setView }) {
    const navigate = useNavigate()
    const [selectedPos, setSelectedPos] = useState(0);
    const [finished, setFinished] = useState([])
    const [models, setModel] = useState([{
        'name': 'EDA', 'base_model': 'Version 1.0', 'date_updated': '01-01-0001', 'domain': 'Retail', 'task': 'Task', 'description': 'Description'
    }, {
        'name': 'EDA', 'base_model': 'Version 1.0', 'date_updated': '01-01-0001', 'domain': 'Retail', 'task': 'Task', 'description': 'Description'
    }])
    const UpdateActivityDetailsBtnRef = useRef(null)
    useEffect(() => {
        const sModels = []
        const model_type = agentData.master_json?.knowledge_work?.activities[selectedPos].finetuned_model_type
        const model_data = agentData.master_json?.knowledge_work?.activities[selectedPos][`${model_type}_model`]
        console.log(model_type, model_data)
        sModels.push({
            'name': agentData.master_json?.knowledge_work?.activities[selectedPos]?.name,
            'base_model': model_data?.base_model,
            'date_updated': new Date(agentData?.created_at).toString(),
            'domain': agentData?.domain,
            'task': agentData.master_json?.knowledge_work?.activities[selectedPos]?.tasks,
            'description': agentData.master_json?.knowledge_work?.activities[selectedPos]?.description,
        })
        setModel(sModels)
    }, [selectedPos])
    return (
        <>
            <div className='d-flex flex-row justify-content-between' style={{ background: 'transparent linear-gradient(90deg, #212542 0%, #222C53 49%, #212542 100%) 0% 0% no-repeat padding-box' }}>
                <div className='d-flex flex-column' style={{ marginLeft: '2.5%' }}>
                    <span className='mt-4 mb-1' style={{ font: 'normal normal bold 17px/21px Calibri', letterSpacing: '0.55px' }}>Create Knowledge Work</span>
                    <span className='mb-4 mt-1' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Knowledge Work: {agentData.name}</span>
                </div>

                <div className='d-flex flex-row mt-auto mb-2' style={{ marginRight: '2.5%' }}>
                    <a style={{
                        textDecoration: 'underline', font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#ADC2FF', lineHeight: '250%'
                    }} href='#'>Summary</a>
                    <span style={{ border: '1px solid #899DD4' }} className='mx-4'></span>
                    <button className='btn me-2' style={{ font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#012060', background: '#D7E5FF 0% 0% no-repeat padding-box', borderRadius: '4px' }} onClick={() => { navigate('/summary') }}>Close</button>
                    <button className='btn ms-2' style={{ background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF' }}>Save</button>
                </div>
            </div >
            <div className='container-fluid'>
                <div className='d-flex flex-row mx-2 mt-4 align-items-start'>
                    <div className='d-flex flex-column' style={{ background: '#42466C 0% 0% no-repeat padding-box', borderRadius: '6px', flexShrink: 0, minHeight: '68vh', maxHeight: '68vh', overflowY: 'auto', order: '1', width:'25%' }}>
                        <div className='w-100 d-flex flex-row mt-4 pt-4 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#E5EAF1', opacity: '1' }}>
                            Activity Chain
                        </div>
                        {[null, undefined, 0].includes(agentData.master_json?.knowledge_work?.activities?.length) ? <>No Models</> : agentData.master_json?.knowledge_work?.activities?.map((item, idx, arr) => (
                            <>
                                <div className='w-100 d-flex flex-row pt-4 ps-4 pe-4 pb-4' style={{ font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#E5EAF1', background: `${idx === selectedPos ? '#212542 0% 0% no-repeat padding-box' : ''}` }}>
                                    <div className='me-2 rounded-circle text-center' style={{ background: `${idx === selectedPos ? '#83A0F7 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box'}`, width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{idx + 1}</div>
                                    {item.name}
                                    {
                                        finished.includes(idx) ?
                                            <div className='ms-auto rounded-circle text-center' ><img src={checkMarkCircle}></img></div>
                                            : <></>
                                    }
                                </div>
                                {idx !== arr.length - 1 && idx !== selectedPos && idx !== selectedPos - 1 ? <div className='mx-auto' style={{ border: '2px solid #212542', width: '80%' }}></div> : <></>}
                            </>
                        ))}
                    </div>
                    <div ref={UpdateActivityDetailsBtnRef} className='d-flex flex-column justify-content-end' style={{ background: `url(${dottedPatternBG})`, borderRadius: '0px 0px 0px 0px', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundColor: "#E1E7F9", minHeight: '68vh', maxHeight: '68vh', overflowY: 'auto', order: '3', width:'30%' }}>
                        <NormalChat context={agentData?.activities}
                            UpdateActivityDetailsBtnRef={UpdateActivityDetailsBtnRef} />
                        {/* <RecommendedModelChat context={agentData?.activities} /> */}
                    </div>
                    <div className='d-flex flex-column' style={{ background: '#34385A 0% 0% no-repeat padding-box', borderRadius: '0px 0px 0px 0px', minHeight: '68vh', maxHeight: '68vh', overflowY: 'auto', Width: '35%', order: '2', width:'45%' }}>
                        <div className='p-2 d-flex flex-column'>
                            <div className='w-100 d-flex flex-row py-4 ps-4' style={{ font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1', background: '#42466C 0% 0% no-repeat padding-box' }}>
                                Recommended Models ({models.length})
                            </div>
                            <div class="accordion accordion-flush">
                                {models.map((item, idx, arr) => (
                                    <Accordion data={item} />
                                ))}
                            </div>

                            <div className='w-100 d-flex flex-row py-4 ps-4 justify-content-end' style={{ background: '#212542 0% 0% no-repeat padding-box' }}>
                                {
                                    selectedPos === (agentData.master_json?.knowledge_work?.activities?.length || 0) - 1 ?
                                        <button className='btn me-4' onClick={() => {
                                            if (setView) {
                                                navigate("/summary")
                                            }
                                        }} style={{ font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF', background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px' }}>Save And Go To Summary</button> :
                                        <button className='btn me-4' onClick={() => {
                                            if (selectedPos < agentData.master_json?.knowledge_work?.activities?.length) {
                                                finished.push(selectedPos)
                                                setFinished([...finished])
                                                setSelectedPos(selectedPos + 1)
                                            }
                                        }} style={{ font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF', background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px' }}>Save & Next</button>
                                }
                            </div>

                        </div>
                    </div>
                </ div>
            </div >
        </>
    )
}