import React from "react";
import NavBar from "./NavBar";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logoWhite from "../../assets/img/logoWhite.svg";
import settings from "../../assets/img/settings.png";
import dashboardImg from "../../assets/img/dashboardImg.png";

export default function Home() {
  const navigate = useNavigate();
  const handleHome = () => {
    return navigate("/");
  };
  return (
    <>
      <div className="home-image">
        <Navbar style={{ paddingTop: "64px", paddingBottom: "0" }}>
          <Container fluid style={{ padding: "0 4rem 0 4rem" }}>
            <Navbar.Brand href="#home" onClick={handleHome}>
              <img
                className="img-fluid"
                loading="lazy"
                src={logoWhite}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                {/* <img className="img-fluid" loading="lazy" src={settings} alt="" /> */}
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="row g-0 ">
          <div className="col-12 col-md-7 ">
            <div
              className="d-flex align-items-center px-6"
              style={{ paddingTop: "166px" }}
            >
              <div className="col-10 col-xl-12 p-0">
                <div>
                  <h2 className="h4 mb-2 home-title">An AI Driven Platform</h2>
                  <p className="home-text mb-4">
                    We make digital products that drive you to stand out. We
                    write words, take photos, make videos, and interact with
                    artificial intelligence. We make digital products that drive
                    you to stand out.
                  </p>
                  <div className="pt-4">
                    <Link to="/agentfactory">
                      {" "}
                      <Button className="homeBtn me-4" variant="">
                        Agents{" "}
                      </Button>{" "}
                    </Link>
                    <Button className="homeBtn me-4" variant="">
                      Agent Chains
                    </Button>{" "}
                    <Button className="homeBtn" variant="">
                      Playground
                    </Button>{" "}
                  </div>
                </div>
                {/* <hr className="border-primary-subtle mb-4"> </hr> */}
                {/* <h2 className="h1 mb-4">
                      We make digital products that drive you to stand out.
                    </h2>
                    <p className="lead m-0">
                      We write words, take photos, make videos, and interact
                      with artificial intelligence.
                    </p> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="card-body p-3 p-md-4 px-xl-5 pt-xl-0 pt-0">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <img
                      className="img-fluid rounded"
                      style={{ maxWidth: "98%" }}
                      loading="lazy"
                      src={dashboardImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="copyright copyright-home-screen">
          Copyright © TCS 2024. All Rights Reserved.
        </footer>
      </div>
    </>
  );
}
