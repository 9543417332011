import React, { useState, useEffect, useContext } from "react";
import { Dock } from "react-dock";
import Draggable from "react-draggable";
import ActivityPopup, { ChainPopup } from "../popup/ActivityPopup";
import { DockContext } from "../../DockWrapper/DockContext";
import styles from "./AchainDock.module.css"
import "../ChatPopup/ChatPopup.css";
import "../popup/ActivityPopup.css";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from "react-icons/bs";
import colorCode from "../../../data/activityNodeDB";



const AchainDock = (props) => {
    console.log(props, "from achain dock ");
    const {
        setIsDraggable,
        isDraggable,
        isAchainDocked,
        setisAchainDocked,
        achaindockSize,
        setAchainDockSize,
        achaincontentSize,
        setAchainContentSize,
        setPath,
        isFromAchain,
        setIsFromAchain,
        isActivityDeatilsDock,
        setIsActivityDeatilsDock
    } = useContext(DockContext);



    useEffect(() => {
        console.log(
            achaindockSize,
            isAchainDocked,
            achaincontentSize,
            isFromAchain,
        );
        setIsFromAchain(props.achain)
    }, [props])

    const toggleDock = () => {
        setisAchainDocked(!isAchainDocked);
        setIsDraggable(!isDraggable);
        if (isAchainDocked) {
            setAchainContentSize(100)
        }
        else {
            setAchainContentSize(100 - achaindockSize * 100)
        }
    };
    useEffect(() => setPath(window.location.pathname === '/AChain'))

    const handleSizeChange = (size) => {
        console.log(size)
        if (size < 0.46 && size > 0.30) {
            setAchainDockSize(size);
        }

    };






    useEffect(() => {
        if (isAchainDocked) {
            setAchainContentSize(100 - achaindockSize * 100)
        }
    },

        [achaindockSize])

    const dockStyle = { top: "11%", overflow: "hidden", height: "85%", left: "58.5% !important" };
    useEffect(() => {
        console.log(isActivityDeatilsDock)
    }, [isActivityDeatilsDock])

    return (
        <div className="d-flex flex-column px-2 py-2" style={{ width: '40%', height: '70vh' }}>
            <div>
                {props.children}
            </div>
            {isAchainDocked && isFromAchain ?
                (
                    <div isVisible={isAchainDocked} size={achaindockSize} onSizeChange={handleSizeChange} dimMode='none' dockStyle={dockStyle} style={{ width: '100%', height: '100%', overflow: "scroll" }}>

                        {
                            !isActivityDeatilsDock ?

                                props.ActivityComplete ? <ChainPopup /> : <ActivityPopup />
                                :

                                <ActivityDetails />
                        }

                    </div>
                ) : (
                    ""
                )}
        </div>
    );
};

export default AchainDock;



export function ActivityDetails() {

    const {
        setisFinish,
        dockSize,
        setIsDraggable,
        isDraggable,
        isAchainDocked,
        setisAchainDocked,
        setAchainContentSize,
        setIsActivityDeatilsDock,
        activityDetailsData, setActivityDetailsData,
        activity_master_json, setActivity_master_json
    } = useContext(DockContext);

    const toggleDock = () => {
        setisAchainDocked(!isAchainDocked);
        setisFinish(false)
        setIsDraggable(!isDraggable);
        if (isAchainDocked) {
            setAchainContentSize(100)
        }
        else {
            setAchainContentSize(100 - dockSize * 100)
        }
    };
    console.log("clicked activity detail", activityDetailsData)

    const [activityDetails, setActivityDetails] = useState({
        description: "lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor",
        goal: "lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor",
        role: "lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor",
        intelligenceType: {
            type: "generative",
            description: "lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor"
        },
        task: "lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor",
        outcome: "lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor"
    })

    useEffect(() => {
        console.log(activity_master_json)
        let selectedActivity = activity_master_json?.knowledge_work?.activities.find((item) => item.name === activityDetailsData.acticityName)
        console.log("selected activity", selectedActivity)
        setActivityDetails({
            description: selectedActivity.description,
            goal: selectedActivity.goal,
            role: selectedActivity.role,
            intelligenceType: {
                type: selectedActivity.finetuned_model_type,
                description: ""
            },
            tasks: selectedActivity.tasks,
        })
        console.log(colorCode, "color code", colorCode[`${selectedActivity.finetuned_model_type}`.toLowerCase()], "", `${selectedActivity.finetuned_model_type}`, selectedActivity.finetuned_model_type)
    }, [])


    return (
        <div className={"d-flex w-100 h-100 overflow-scroll"}>
            <div
                className={
                    isAchainDocked ? "chat-container-docked shadow" : "chat-container shadow"
                }
                style={{ backgroundColor: "red", height: "100%" }}
            >

                <div className="chat-header p-1" style={{ height: "10%" }}>
                    <div
                        className="chat-nav text-center"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "80%",
                        }}
                    >
                        <h4 className="text-capitalize m-1" style={{ fontSize: "14px" }}>Activity Details: {"wealth analysis"}</h4>
                        <Nav.Link
                            className="text-white"
                            onClick={() => setIsActivityDeatilsDock(false)}
                        >
                            Back
                        </Nav.Link>
                    </div>
                </div>
                <div className={`chat-content-activity p-1 `} style={{ overflow: "scroll", maxHeight: "90%" }} >
                    {
                        Object.keys(activityDetails).map((key, i) => {
                            if (key == "intelligenceType") {

                                return <div key={key + i} className="d-flex border-bottom flex-column p-3" >
                                    <div
                                        style={{ backgroundColor: `${activityDetails[key]["type"] ? colorCode[activityDetails[key]["type"].toLowerCase()] : "#ff033e"}` }}
                                        className={`p-2`} >
                                        <h5 className="m-0 text-capitalize fs-6">Intelligence Type: {activityDetails[key]["type"] ? activityDetails[key]["type"] : "yet to be updated"}</h5>
                                        <p className="m-0 text-white">{activityDetails[key]["description"]}</p>
                                    </div>
                                </div>

                            } else {

                                return <div key={key + i} className="d-flex border-bottom flex-column p-2">
                                    <h5 className="m-0 fs-6 text-capitalize fw-bold">{key}</h5>
                                    {
                                        key === "tasks" ?
                                            activityDetails[key].map((task) => {
                                                console.log(task)
                                                return (
                                                    <div className="d-flex border flex-column mb-1 p-2">
                                                        <h5 className="m-0 fs-6 fw-light"> <span className="fw-bold">Description:</span> {task.description}</h5>
                                                        <p className="m-0"> <span className="fw-bold">Out Come:</span> {task.expected_output}</p>
                                                    </div>
                                                )
                                            })
                                            :
                                            <p className="m-0">{activityDetails[key] ? activityDetails[key] : "yet to be updated"}</p>

                                    }
                                </div>
                            }
                        })
                    }

                </div>
            </div>
        </div>
    )
}

ActivityDetails.propTypes = {}