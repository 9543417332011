import React, { useEffect, useState } from "react";
import styles from "./Summary.module.css";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import MoHeader from "../MoHeader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// Sample data for demonstration; should be replaced with actual data source
import { recommendedModelsData } from "../Data/dummyData";
import CustomNavbar from "../../CustomNavbar/CustomNavbar";

export default function Summary({ darkMode, handleToggle }) {

  const TextToggle = ({ description = "" }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
      setIsExpanded(prevState => !prevState);
    };

    return (
      <p
        onClick={toggleText}
        style={{
          color: '#000',
          marginTop: '0.5rem',
          overflow: 'hidden',
          cursor: 'pointer'
        }}
        className="m-0"
      >
        {isExpanded || description.length <= 150
          ? description
          : description.slice(0, 150)}
        {description.length > 150 && !isExpanded && '...'}
      </p>
    );
  };



  const [mappedActivities, setMappedActivities] = useState([]);
  const [hyperparameters, setHyperparameters] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedHyperparameters, setSelectedHyperparameters] = useState({});
  const [knowledgeWorkName, setKnowledgeWorkName] = useState("");
  const [isPredictive, setIsPredictive] = useState(false)
  const [knowledgeWorkDetails, setKnowledgeWorkDetails] = useState({
    knowledge_work: {
      name: "",
      description: "",
      domain: "",
      sub_domain: "",
      business_user: "",
      activities: [],
    },
    selectedActivity: null,
    selectedRecommendedModel: null,
  });

  const [selectedItems, setSelectedItems] = useState({
    activities: [],
    recommendedModels: [],
  });

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      try {
        // Fetch activitychain data
        const response = await axios.get(
          `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${id}`
        );

        const master_json = response.data.master_json;
        console.log(master_json);

        // Extract data from master_json
        const { knowledge_work } = master_json;
        const activitiesFromSample = knowledge_work.activities;

        // Map activities
        const updatedActivities = activitiesFromSample.map(
          (activity, index) => {
            const baseModel = activity.model_name || "";
            setHyperparameters(
              activity.generative_model?.hyper_parameters || {}
            ); // Set hyperparameters from generative_model or empty object

            // Determine model details based on generative_model or predictive_model presence
            const modelDetails = activity.generative_model
              ? {
                model_name:
                  activity.generative_model.name ||
                  activity.generative_model.base_model ||
                  "No Model",
                description: activity.kpi || "",
                isComplete: activity.status || "unknown",
                hyperparameters:
                  activity.generative_model.hyper_parameters || {},
              }
              : activity.predictive_model
                ? {
                  model_name:
                    activity.predictive_model.name ||
                    activity.predictive_model.base_model ||
                    "No Model",
                  description: activity.kpi || "",
                  isComplete: activity.predictive_model.status || "unknown",
                  hyperparameters: {},
                }
                : {
                  model_name: "No Model",
                  description: "",
                  isComplete: "unknown",
                  hyperparameters: {},
                };

            return {
              activitychain: {
                id: index + 1,
                title: activity.name,
                intelligence: activity.finetuned_model_type || "unknown",
                description: activity.description,
              },

              recommendedmodels: {
                id: index + 1,
                ...modelDetails, // Spread the modelDetails into recommendedmodels
              },
            };
          }
        );

        // Set the complete knowledge work details including activities
        setKnowledgeWorkDetails({
          knowledge_work: {
            name: knowledge_work.name || "",
            description: knowledge_work.description || "",
            domain: knowledge_work.domain || "",
            sub_domain: knowledge_work.sub_domain || "",
            business_user: knowledge_work.business_user || "",
            activities: updatedActivities,
          },
          selectedActivity: null, // or some initial value
          selectedRecommendedModel: null, // or some initial value
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleCellClick = (category, item, categoryKey) => {
    setSelectedItems((prevState) => {
      const newSelectedItems = { ...prevState };

      if (!newSelectedItems[categoryKey]) {
        newSelectedItems[categoryKey] = [];
      }

      const index = newSelectedItems[categoryKey].findIndex(
        (i) => i.id === item.id
      );
      if (index === -1) {
        newSelectedItems[categoryKey].push(item);

        // Update selected item based on category
        if (category === "recommendedmodels") {
          setSelectedHyperparameters(item.hyperparameters);
          setIsDrawerOpen(true);
          setKnowledgeWorkDetails((prevDetails) => ({
            ...prevDetails,
            selectedRecommendedModel: item,
          }));
        } else if (category === "activitychain") {
          setKnowledgeWorkDetails((prevDetails) => ({
            ...prevDetails,
            selectedActivity: item,
          }));

          // Set isPredictive based on the activity's intelligence property
          setIsPredictive(item.intelligence === "predictive");
        }
      } else {
        newSelectedItems[categoryKey].splice(index, 1);

        // Update selected item based on category
        if (
          category === "recommendedmodels" &&
          !newSelectedItems[categoryKey].length
        ) {
          setSelectedHyperparameters({});
          setIsDrawerOpen(false);
          setKnowledgeWorkDetails((prevDetails) => ({
            ...prevDetails,
            selectedRecommendedModel: null,
          }));
        } else if (category === "activitychain") {
          setKnowledgeWorkDetails((prevDetails) => ({
            ...prevDetails,
            selectedActivity: null,
          }));

          // Reset isPredictive if there are no more selected activities with intelligence "predictive"
          const hasPredictive = newSelectedItems[categoryKey].some(
            (i) => i.intelligence === "predictive"
          );
          setIsPredictive(hasPredictive);
        }
      }

      return newSelectedItems;
    });
  };

  useEffect(() => {
    console.log("Updated knowledgeWorkDetails:", knowledgeWorkDetails);
  }, [knowledgeWorkDetails]);

  const cellDetails = (props) => {
    // Extract properties
    const { title, model_name, description, isComplete, onClick, index, selected } = props;

    return (
      <div style={{ marginBottom: '1rem', overflow: 'scroll' }}>
        <div className="d-flex align-items-start">
          {/* Circle with number */}
          <span
            className="circle-number"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',  // Adjust size as needed
              height: '32px', // Ensure equal width and height for a perfect circle
              borderRadius: '50%', // Creates a circle
              background: selected ? '#fff' : '#D7E5FF',
              color: '#000',
              fontWeight: 'bold',
              fontSize: '14px', // Adjust font size as needed
              textAlign: 'center',
              marginRight: '0.5rem', // Space between circle and text
              flexShrink: 0, // Prevent circle from shrinking
            }}
          >
            {index + 1} {/* Incremental number starting from 1 */}
          </span>
          <div style={{ overflow: 'scroll' }}

          >
            {/* Title or Model Name */}
            <p
              className="text-dark font-weight-bold"
              style={{
                margin: 0,
                cursor: 'pointer',
                // Prevent title from wrapping to a new line
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                borderRadius: '4px',
                textOverflow: 'ellipsis',
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event bubbling to parent elements
                onClick(); // Call the click handler for the title
              }}
            >
              {title || model_name} {/* Display title or model_name */}
            </p>
            {/* <p className="m-0" style={{ color: '#000', marginTop: '0.5rem',overflow:"scroll" }}>
            {description} 
            </p> */}
            <TextToggle description={description} />
          </div>
        </div>
      </div>
    );
  };





  const populateTable = (type) => {
    return knowledgeWorkDetails.knowledge_work.activities.map((activity, index) => {
      const isSelected = type === 'activitychain'
        ? selectedItems.activities?.some(item => item.id === activity.activitychain.id)
        : selectedItems.recommendedModels?.some(item => item.id === activity.recommendedmodels.id);

      return (
        <tr key={index} style={{ background: 'transparent' }}>
          {type === 'activitychain' && (
            <td
              style={{ border: 'none', background: 'transparent' }}
              className={`${isSelected ? 'selected' : ''
                }`}
            >
              <div
                style={{
                  background: isSelected ? '#C4D0F6' : '#fff',
                  margin: '20px',
                  boxShadow: '0px 0px 30px #647B9229',
                  borderRadius: '6px',
                  padding: '20px',
                  height: '120px'
                }}
              >
                {cellDetails({
                  title: activity.activitychain.title,
                  description: activity.activitychain.description,
                  isComplete: activity.activitychain.isComplete,
                  onClick: () => handleCellClick('activitychain', activity.activitychain, 'activities'),
                  index: index,
                  selected: isSelected
                })}
              </div>
            </td>
          )}
          {type === 'recommendedmodels' && (
            <td
              style={{ border: 'none', background: 'transparent' }}
              className={`${isSelected ? 'selected' : ''
                }`}
            >
              <div
                style={{
                  background: isSelected ? '#C4D0F6' : '#fff',
                  margin: '20px',
                  boxShadow: '0px 0px 30px #647B9229',
                  borderRadius: '6px',
                  padding: '20px',
                  height: '120px'
                }}
              >
                {cellDetails({
                  model_name: activity.recommendedmodels.model_name,
                  description: activity.recommendedmodels.description,
                  isComplete: activity.recommendedmodels.isComplete,
                  onClick: () => {
                    handleCellClick(
                      'recommendedmodels',
                      activity.recommendedmodels,
                      'recommendedModels'
                    );
                    setSelectedHyperparameters(activity.recommendedmodels.hyperparameters);
                  },
                  index: index,
                  selected: isSelected
                })}
              </div>
            </td>
          )}
        </tr>
      );
    });
  };






  const handleButtonClick = () => {
    // Check if any recommendedModels are selected
    const hasSelectedRecommendedModels =
      selectedItems.recommendedModels.length > 0;

    if (hasSelectedRecommendedModels && !isPredictive) {
      // Redirect to /training/{id}
      navigate(`/mo/summary/training/${id}`, {
        state: { knowledgeWorkDetails, selectedItems, hyperparameters },
      });
    } else if (isPredictive) {
      navigate(`/mo/summary/training/predictive/${id}`, {
        state: { knowledgeWorkDetails, selectedItems, hyperparameters },
      })
    }
    //   const url = "http://alchemist.eastus.cloudapp.azure.com:54321/flow/index.html";
    // const a = document.createElement('a');
    // a.href = url;
    // a.target = '_blank';
    // a.rel = 'noopener noreferrer'; // For security
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // }
    else {
      // Redirect to /models/{id}
      navigate(`/mo/models/${id}`, {
        state: { knowledgeWorkDetails, selectedItems, hyperparameters },
      });
    }
  };

  return (
    <>
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
      <div className="px-2 py-2 d-flex justify-content-between">

        <div className="w-50">
          <p className="text-light fw-bold">Model Training</p>
          <p style={{ color: "#ADC2FF", marginTop: '-10px' }}>
            Knowledge Work: {knowledgeWorkDetails.knowledge_work.name}
          </p>
        </div>
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "fit-content" }}
        >

          <div className="nav-btns">
            <button
              className="btn btn-sm mr-2"
              style={{ background: "#D7E5FF", color: "#012060" }}
            >
              Close
            </button>
            <button
              className="btn btn-sm border-none text-light mr-2"
              onClick={handleButtonClick}
              style={{ background: "#003BEB", color: "#fff" }}
              disabled={selectedItems.activities.length === 0}
            >
              {selectedItems.recommendedModels.length > 0
                ? "Start Model Training"
                : selectedItems.activities.length > 0
                  ? "Select Model"
                  : "Select Activity First"}
            </button>
          </div>
        </div>

      </div>

      <div style={{ marginLeft: '2.5%', font: 'normal normal normal 17px/21px Calibri', letterSpacing: '0.55px', color: '#E5EAF1' }}>
        Summary Screen
      </div>
      <div className="container-fluid p-4">
        <div className="row g-0">
          <div className="col-md-8">
            <div className="row g-0">
              <div className="col-md-6">

                <table className="table border-top-color:transparent" style={{ width: '100%', background: '#E7ECF9' }}>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-uppercase text-light"
                        style={{ background: '#42466C 0% 0% no-repeat padding-box', borderTop: '0px transparent' }}
                      >
                        <div>Activity Chain</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className={`${styles["table-bg-light-cyan"]}`} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}>
                    {populateTable('activitychain')}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table" style={{ width: '100%', background: '#E7ECF9' }}>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-uppercase text-light"
                        style={{ background: '#42466C 0% 0% no-repeat padding-box', borderLeft: '1px solid #899DD4', borderRight: '1px solid #899DD4', borderTop: '0px transparent' }}
                      >
                        Recommended Models
                      </th>
                    </tr>
                  </thead>
                  <tbody className={`${styles["table-bg-light-cyan"]}`} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}>
                    {populateTable('recommendedmodels')}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div className="col-md-4">
            <table className="table" style={{ background: '#E7ECF9' }}>
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="text-uppercase text-light"
                    style={{ background: '#42466C 0% 0% no-repeat padding-box', borderTop: '0px transparent', borderBottom: '0px transparent', overflow: 'scroll' }}
                  >
                    Recommended LLM Hyperparameters
                  </th>
                </tr>
              </thead>
              <tbody className="table-bg-light-cyan" style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', overflow: 'scroll', maxHeight: '100%' }}>
                <tr>
                  <td style={{ background: '#474f73', border: 'none' }}>
                    {hyperparameters ? (
                      <HyperparameterRecommended params={selectedHyperparameters} selectedModels={selectedItems.recommendedModels} />
                    ) : (
                      <p className="text-light">No hyperparameters.</p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}

Summary.propTypes = {};

// Helper component to display hyperparameters
const HyperparameterRecommended = ({ params, selectedModels }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (key) => {
    setExpandedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderObject = (obj, parentKey = "") => {
    return Object.keys(obj).map((key, i) => {
      const value = obj[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const isExpanded = !!expandedSections[fullKey];

      if (typeof value === "object" && value !== null) {
        return (
          <React.Fragment key={i}>
            <tr className={styles["bg-dark-blue"]}>
              <td colSpan="2" className="text-light" style={{ padding: '1rem 0rem' }}>
                <div
                  type="button"
                  className="p-2 m-0 d-flex align-items-center overflow-scroll"
                  style={{
                    background: "#34385a",
                    color: "#fff",
                    margin: 0,
                    padding: 0,
                    width: "100%",
                    maxHeight: "100vh"
                  }}
                  onClick={() => toggleSection(fullKey)}
                >
                  <span
                    className="mr-2 rounded"
                    style={{
                      textAlign: 'center',
                      background: '#484C6C',
                      width: '24px',
                      height: '24px',
                      lineHeight: '24px', // Center text vertically
                      display: 'inline-block', // Ensure proper alignment
                    }}
                  >
                    {isExpanded ? "−" : "+"}
                  </span>
                  <span>{key}</span>
                </div>
              </td>
            </tr>
            {isExpanded && renderObject(value, fullKey)}
          </React.Fragment>
        );
      } else {
        return (
          <tr key={i} className={styles["bg-dark-blue"]}>
            <td className="text-light">{key}</td>
            <td className="text-light">{String(value)}</td>
          </tr>
        );
      }
    });
  };

  const isParamsEmpty = Object.keys(params).length === 0;

  return (
    <div className="d-grid m-2 p-0 custom-scrollbar" style={{ maxHeight: "100vh" }}>
      {selectedModels.length === 0 ? (
        <p className="text-light">Select any model to see hyperparameters.</p>
      ) : (
        <table className={`custom-tableDark`}>
          <thead>
            <tr>
              <th style={{ background: '#212542', borderBottom: '1px solid #565970' }}>Parameter</th>
              <th style={{ background: '#212542', borderBottom: '1px solid #565970' }}>Input</th>
            </tr>
          </thead>
          <tbody style={{ background: 'transparent' }}>
            {isParamsEmpty ? (
              <tr>
                <td colSpan="2" className="text-light text-center">
                  No hyperparameters available
                </td>
              </tr>
            ) : (
              renderObject(params)
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

HyperparameterRecommended.propTypes = {
  params: PropTypes.object.isRequired,
};

export function SubHeading({ heading }) {
  return (
    <div
      className={`w-100 d-flex justify-content-between align-items-center ${styles["bg-dark-blue"]} p-2 border-top border-secondary-subtle`}
    >
      <h4 className={`text-uppercase fs-5 ml-4 pl-2`}>{heading}</h4>
      <Nav.Link className="icon-link icon-link-hover text-white mr-2">
        Activity Chain Visualization
        <i className="fa-solid fa-binoculars"></i>
      </Nav.Link>
    </div>
  );
}

SubHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};
