import { Handle, Position } from "reactflow";
import "./AChain.css";
import avatar from "../../assets/img/agentAvatar.svg";

function CreateAgentActivityNode({ data, isConnectable }) {
  let colorCode = {
    Recognition: "green",
    Predictive: "orange",
  };
  return (
    <div
      className="activityChainNode w-100"
      style={{
        height: "99%",
        borderRadius: "0.25rem 0.25rem 0.25rem 0.25rem",
        border: "1px solid " + colorCode[data.Activity_Classification],
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        style={{ backgroundColor: "white" }}
      />
      <div className="h-100 w-100 row g-0">
        <div className="col pt-3 pl-3 col-3">
          <img
            src={avatar}
            alt="avatar"
            style={{ height: "20px", width: "20px" }}
          ></img>
        </div>
        <div className="col col-9 pt-3">
          <p style={{ fontSize: "10px", marginBottom: "0px", color: "white" }}>
            Activity
          </p>
          <div className="row d-flex g-0 justify-content-between pr-4">
            <p
              style={{
                fontSize: "12px",
                marginBottom: "0px",
                color: "white",
                width: "80%",
              }}
            >
              {data.acticityName}
            </p>
            <div className="activityIButton">i</div>
          </div>
        </div>
      </div>
      {/* <div className='d-flex align-items-center justify-content-center w-100' style={{ height: '69%', marginTop: "-0.3%", backgroundColor: "#9CA3Af",borderRadius: " 0rem 0rem 0.25rem 0.25rem" }}>
                <div style={{ backgroundColor: 'white', width: '60%', fontSize: '12px', textAlign: 'center',borderRadius: "0.25rem 0.25rem 0.25rem 0.25rem" }} className='container'>Create Agent</div>
            </div> */}
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
        style={{ backgroundColor: "white" }}
      />
    </div>
  );
}

export default CreateAgentActivityNode;
