// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.competency-details .nav-tabs .nav-link.active {
  font: normal normal bold 16px/19px Calibri;
  letter-spacing: 0.67px;
  color: #012060;
  /* text-decoration: underline; */
  text-decoration-color: #3366ff;
  border: none;
  border-bottom: 5px solid #3366ff;
  font: normal normal bold 16px/19px Calibri;
  text-decoration-thickness: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AgentChainModule/ModelCompetancy/competencyDetails.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,sBAAsB;EACtB,cAAc;EACd,gCAAgC;EAChC,8BAA8B;EAC9B,YAAY;EACZ,gCAAgC;EAChC,0CAA0C;EAC1C,8BAA8B;AAChC","sourcesContent":[".competency-details .nav-tabs .nav-link.active {\n  font: normal normal bold 16px/19px Calibri;\n  letter-spacing: 0.67px;\n  color: #012060;\n  /* text-decoration: underline; */\n  text-decoration-color: #3366ff;\n  border: none;\n  border-bottom: 5px solid #3366ff;\n  font: normal normal bold 16px/19px Calibri;\n  text-decoration-thickness: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
