import Nav from "react-bootstrap/Nav";
import "./CreateAgent.css";
import { Link } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import checkimg from "../../../assets/img/check.png";
import darkCheckImg from "../../../assets/img/darkCheckImg.svg";

function ModelOrchestratorNav({ darkMode }) {
  return (
    <div className="container-fluid pt-3 px-40 ">
      <div className="row mx-0 mb-2">
        <div className="col-md-3 px-0 pb-3">
          <h5
            className="createAgentTitle pt-3"
            style={{ color: darkMode ? "#fff" : "" }}
          >
            Model Orchestrator
          </h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav
            variant="pills"
            defaultActiveKey="/agentfactory/createAgent"
            className={darkMode ? "agentWizard fineTuningDark" : "agentWizard"}
          >
            <Nav.Item className="">
              <Nav.Link
                as={Link}
                to=""
                eventKey="/comparemodels"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                <img
                  className="checkmarkimage"
                  src={darkMode ? darkCheckImg : checkimg}
                ></img>
              </Nav.Link>
              <span className="pilltext">Select Model</span>
            </Nav.Item>
            <hr
              className="horizontal negMarg"
              style={{
                color: "#3366FF",
                border: "1px solid",
                marginLeft: "-26px"
              }}
            />
            <Nav.Item className="negMarg" style={{ marginLeft: "-27px" }}>
              <Nav.Link
                as={Link}
                // to="/finetuning"
                eventKey="/agentfactory/createAgent/selectModel"
                // href="/agentfactory/agentDetails"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                2
              </Nav.Link>
              <span className="pilltext">&nbsp; Fine-tuning &nbsp; </span>
            </Nav.Item>
            <hr
              className="horizontal negMarg"
              style={{ marginLeft: "-27px" }}
            />
            <Nav.Item
              className="lastPillNegMarg"
              style={{ marginLeft: "-33px" }}
            >
              <Nav.Link
                as={Link}
                to=""
                eventKey=""
                // href="/agentfactory/createAgent/testAgent"
                className="linkPill mx-auto mb-1"
              >
                3
              </Nav.Link>
              <span className="pilltext" style={{ fontSize: "16px" }}>
                Preview Model{" "}
              </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default ModelOrchestratorNav;
