import Nav from "react-bootstrap/Nav";
import "../CreateAgentChain.css";
import { Link } from "react-router-dom";

function CreateAgentChainNav() {
  return (
    <div className="container-fluid pt-4 ps-4">
      <div className="row mx-0 mb-3">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-2"> Clone Chain </h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav
            variant="pills"
            defaultActiveKey="#"
            className="agentWizard"
          >
            <Nav.Item className="">
              <Nav.Link
                as={Link}
                to="#"
                eventKey="#"
                className="linkPill mx-auto mb-1"
              >
                1
              </Nav.Link>
              <span className="pilltext"> Agent Chain Details</span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className="linkPill mx-auto mb-1"
              >
                2
              </Nav.Link>
              <span className="pilltext">Test Agent Chain </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default CreateAgentChainNav;
