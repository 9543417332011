import React, { useEffect, useState } from "react";
import { Button, Card, InputGroup, Nav, NavLink } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import MoHeader from "./MoHeader";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import { CiSearch } from "react-icons/ci";
import Styles from "./Models.module.css"

const Models = ({ darkMode, handleToggle }) => {
    const [isPause, setIsPause] = useState(false)
    const [searchKey, setSearchKey] = useState("")


    const navigate = useNavigate();
    const location = useLocation();
    const { selectedItems, knowledgeWorkName, hyperparameters, knowledgeWorkDetails } = location.state || {};
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [displayedData, setDisplayedData] = useState([]);
    const [baseModelMapping, setBaseModelMapping] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const [knowledgeWorkLinks, setKnowledgeWorkLinks] = useState([
        { name: "Deployed", route: "", isActive: true },
        { name: "Draft", route: "", isActive: false },
    ]);

    const links = [
        { name: "Knowledge Work", route: "/activityList", isActive: false },
        { name: "Model Orchestrator", route: "/mo/knowledge-work", isActive: false },
        { name: "Models", route: "/mo/models", isActive: true },
    ];

    const filters = [
        { label: "Domain", options: ["Banking", "Life Science", "Insurance"], selectedOption: "All" },
        { label: "Sub-Domain", options: ["Wealth", "Drug", "Policy"], selectedOption: "All" },
        { label: "Task", options: ["Wealth", "Drug", "Policy"], selectedOption: "All" },
        { label: "Base Model", options: ["EY", "Astreagenica", "Cigna"], selectedOption: "All" },
    ];

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const response = await fetch("http://alchemist.eastus.cloudapp.azure.com:3005/models");
                const data = await response.json();
                setDisplayedData(data);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchBaseModels = async () => {
            try {
                const response = await fetch("http://alchemist.eastus.cloudapp.azure.com:3005/models/base_model");
                const baseModels = await response.json();
                const mapping = {};
                baseModels.forEach(model => {
                    mapping[model.model_id] = model.model_name;
                });
                setBaseModelMapping(mapping);
            } catch (error) {
                console.error("Failed to fetch base models:", error);
            }
        };

        fetchModels();
        fetchBaseModels();
    }, []);

    const handleKnowledgeWorkClick = (index) => {
        setKnowledgeWorkLinks(prevLinks =>
            prevLinks.map((link, i) => ({ ...link, isActive: i === index }))
        );
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [selectedRow, setSelectedRow] = useState([]);

    const handleRowSelection = (model) => {
        const isSelected = selectedRow.includes(model);

        if (isSelected) {
            setSelectedRow(prevSelected => prevSelected.filter(item => item !== model));
            selectedItems.recommendedModels = selectedItems.recommendedModels.filter(item => item !== model);
        } else {
            setSelectedRow(prevSelected => [...prevSelected, model]);
            selectedItems.recommendedModels.push(model);
        }

        console.log(selectedItems.recommendedModels);
    };

    const DisplayData = displayedData.map((info, i) => {
        const isSelected = selectedRow.includes(info);
        const baseModelName = baseModelMapping[info.base_model_id] || ''; // Use base_model_id to get base model name
        const isEmpty = (item) => {
            return item ? item : "Not Specified"
        }
        const styleConfig = {
            flexShrink: 0,
            width: "1em",
            height: "1em",
            marginTop: ".25em",
            verticalAlign: "top",
            appearance: "none",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundColor: "rgba(37, 97, 220, 0.81)"
        }
        return (
            <React.Fragment key={i}>
                <tr className={isSelected ? "selected-row" : ""}>
                    <td id="modelName" >
                        <div className="d-flex gap-1 justify-content-start align-items-center text-wrap overflow-scroll">

                            <input type="checkbox" name="model selection" style={styleConfig} onClick={() => handleRowSelection(info)} />
                            <span className="ml-2 p-2">
                                {isEmpty(info.model_name)}
                            </span>

                        </div>
                    </td>
                    <td>{isEmpty(info.model_description)}</td>
                    <td>{isEmpty(baseModelName)}</td>
                    <td>{isEmpty(info.model_type)}</td>
                </tr>
                <tr>
                    <td colSpan="9" className="hiddenRow border-bottom">
                        <div
                            className="collapse subTable_outer tableCardDark multi-collapse"
                            id={"rowId" + i}
                        >
                            <table className="alchemTableDark">
                                <thead>
                                    <tr className="info">
                                        <th>Purpose</th>
                                        <th>Status</th>
                                        <th>Updated by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{info.purpose}</td>
                                        <td>{info.status}</td>
                                        <td>{/* updated by */}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="px-0">
                                            <Button className="saveButton p-0 mt-3">Test</Button>
                                            <Button className="cancelButton p-0 ms-2 mt-3">Refrain</Button>
                                            <Button className="cancelButton p-0 ms-2 mt-3">Edit</Button>
                                            <Button className="cancelButton p-0 ms-2 mt-3">Clone</Button>
                                            <Button className="deleteButton p-0 ms-3 mt-3">Delete</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    });

    const handleButtonClick = () => {
        // Check if any recommendedModels are selected
        const hasSelectedRecommendedModels = selectedItems.recommendedModels.length > 0;

        if (hasSelectedRecommendedModels) {
            // Redirect to /training/{id}
            navigate(`/mo/summary/training/${id}`, { state: { selectedItems, knowledgeWorkName, hyperparameters, knowledgeWorkDetails } });
        } else {
            // Redirect to /models/{id}
            navigate(`/mo/models/${id}`, { state: { selectedItems, knowledgeWorkName, hyperparameters } });
        }
    };

    return (
        <div className="h-100">
            {id ? (
                <>
                    <MoHeader
                        darkMode={darkMode}
                        knowledgeWorkName={knowledgeWorkName}
                        handleModelTraining={handleButtonClick}
                    />
                    <div className="d-flex justify-content-end position-relative" style={{ bottom: '65px' }}>
                        <Nav>
                            <button className='btn btn-sm btn-outline-light mr-2'>Close</button>
                            <button className='btn btn-sm btn-light mr-2' onClick={handleButtonClick}>Start Model Training</button>
                        </Nav>
                    </div>
                </>

            ) : (
                <CustomNavbar links={links} profile={true} />
            )}

            <div style={{ padding: "40px" }} className="pt-4">
                <nav className="navbar navbar-expand-lg pb-3 pt-0">
                    <div className="container-fluid px-0 ">
                        <span className={darkMode ? "text-white page-title-dark " : "text-dark page-title-dark"} style={{ letterSpacing: '0px' }}>
                            Models ({displayedData.length})
                        </span>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav ms-auto px-0">
                                <InputGroup
                                    className={`mr-2 ${darkMode ? "ms-auto searchWidth alchemSearchGroupDark" : "ms-auto searchWidth alchemSearchGroup"}`}
                                >
                                    <input
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                        className="form-control border-0"
                                        value={searchKey}
                                        onKeyDown={(input) => {
                                            // input.key == "Enter" && SearchForKey()
                                        }}
                                        onChange={(event) => { setIsPause(true); setSearchKey(event.target.value); }}
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        id="searchButton"
                                        className="searchGroupBtn"
                                    //   onClick={SearchForKey}
                                    >
                                        <CiSearch style={{ fontSize: "18px", color: "#E5EAF1" }} />

                                    </Button>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </nav>



                <div className="mt-2">
                    <Nav className="me-auto mt-0" defaultActiveKey={"competencies"}>
                        {knowledgeWorkLinks.map((link, index) => (
                            <Nav.Link
                                to={link.route}
                                onClick={() => handleKnowledgeWorkClick(index)}
                                className={`justify-content-center align-items-center d-flex mt-0 ${link.isActive && "pb-0"}`}
                                style={{
                                    display: "inline-block",
                                    borderBottom: link.isActive ? "4px solid #3366FF" : "none",
                                    marginBottom: "-5px",
                                    marginRight: "6px",
                                    fontSize: "14px",
                                    color: darkMode ? "#fff" : "#000",
                                }}
                                as={Link}
                                key={index}
                            >
                                {link.name === "Deployed" ? link.name + ` (${displayedData.length})` : link.name + ` (${displayedData.length})`}
                            </Nav.Link>
                        ))}
                    </Nav>

                    <Card className="mt-4 tableCardDark" style={{ padding: "0.5rem" }}>
                        <Card.Body>
                            <table className={`table alchemTableDark allModelTable mb-0 ${Styles["modelTableMaxWidth"]}`}>
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                borderRight: "#2F499E22 solid 1px",
                                                paddingLeft: "16px",
                                                minWidth: '50px'
                                            }}
                                        >Name</th>
                                        <th
                                            style={{
                                                borderRight: "#2F499E22 solid 1px",
                                                paddingLeft: "16px",
                                                minWidth: '50px'
                                            }}
                                        >Description</th>
                                        <th
                                            style={{
                                                borderRight: "#2F499E22 solid 1px",
                                                paddingLeft: "16px",
                                            }}
                                        >Base Model</th>
                                        <th
                                            style={{
                                                borderRight: "#2F499E22 solid 1px",
                                                paddingLeft: "16px",
                                            }}
                                        >Intelligence Type</th>
                                    </tr>
                                </thead>
                                <tbody>{DisplayData}</tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

Models.propTypes = {
    darkMode: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
};

export default Models;
