import H2oNav from "./H2oNav";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import h2o from "../../assets/img/h20.png"


const H2oIframeComponent = () => {
    const iframeRef = useRef(null);
  
    useEffect(() => {
      const iframe = iframeRef.current;
  
      if (iframe) {
        iframe.width = '860';
        iframe.height = '484';
        iframe.src = 'http://172.208.104.191:54321/flow/index.html';
        iframe.title = 'H2O-3';
        iframe.sandbox = 'allow-same-origin allow-scripts allow-popups allow-forms';
        iframe.allow = 'clipboard-write';
      }
    }, []);
  
    return <iframe ref={iframeRef}></iframe>;
  };

const H2oScreen = () =>{
    return(
        <div>
            <H2oNav></H2oNav>
            <div>
            <img
            src={h2o}
            alt="H2o"
            style={{ width: '100%', height: 'auto' }}
            />
            </div>
        </div>
    )
}

export default H2oScreen