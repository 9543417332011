import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../Auth/AuthContext";
import Nav from "react-bootstrap/Nav";
import styles from "./CustomNavbar.module.css"
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlchemLogo from "../../assets/img/AlchemLogo.png";
import LogoutLogo from "../../assets/img/onBtn.svg";
import LogoWhite from "../../assets/img/logoWhite.svg";
import logodark from "../../assets/img/logoWhite.svg";
import { Link, useNavigate } from "react-router-dom";
import UserThumbnail from "../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../assets/img/dropdown-arrow.svg";
import StartButton from "../../assets/img/start-btn.svg";
import Toggle from "../Toggle/Toggle";
import { FiUser } from "react-icons/fi";
import { FaGear } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import settings from "../../assets/img/settings.png";

const CustomNavbar = ({ links, profile, handleToggle }) => {
  const [username, setUsername] = useState();
  const [IDtoken, setIDtoken] = useState();
  const [access_token, setAccess_token] = useState();
  const {
    token,
    setToken,
    userRole,
    setUserRole,
    authenticated,
    setAuthenticated,
    accessToken,
    setAccessToken,
    refreshToken,
    darkMode,
    setRefreshToken,
    IDToken,

    setIDToken
  } = useContext(AuthContext);

  useEffect(() => {
    // Retrieve the JWT token from local storage
    setIDtoken(IDToken);
    setAccess_token(accessToken);
    if (IDtoken) {
      // Decode the JWT token
      const decoded = jwtDecode(IDtoken);
      console.log(decoded);
      
      setUsername(decoded.name);
      // setUsername(decoded.)
    }
  }, [IDtoken, setIDtoken]);

  const headers = {
    Authorization: `Bearer ${access_token}`
  };
  function removeCookie(name) {
    document.cookie =
      name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  const handleLogout = () => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${access_token}`);
    headers.append("Content-Type", "application/json");
    // Construct logout URL with headers
    removeCookie("accessToken");
    removeCookie("refreshToken");
    removeCookie("IDToken");
    handleHome()
    setAuthenticated(false);
    // const logoutUrlWithHeaders = new URL('http://74.235.164.4:8080/realms/Alchemist/protocol/openid-connect/logout')
    // for (const [key, value] of headers.entries()) {
    //   logoutUrlWithHeaders.searchParams.append(key, value)
    // }

    // // Open the logout URL in a new tab/window
    // window.location.href = logoutUrlWithHeaders
  };

  const navigate = useNavigate();
  const handleHome = () => {
    return navigate("/");
  };
  return (
    <>

      <Navbar
        collapseOnSelect
        expand="lg"
        className={
          darkMode
            ? `navWithBgDark competencydetails p-0`
            : `navWithBg competencydetails p-0`
        }
        style={{ lineHeight: '2' }}
      >
        <Container fluid style={{ paddingLeft: "40px", paddingRight: "40px" }}>
          <Navbar.Brand href="" onClick={handleHome}>
            <img
              className="img-fluid logoSize"
              loading="lazy"
              src={darkMode ? logodark : LogoWhite}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {links && (
              <Nav
                className="me-auto alchemNavLinks"
                defaultActiveKey={"competencies"}
              >
                {links.map((link, index) => (
                  <Nav.Link
                    to={link.route}
                    className={`position-relative align-items-center d-flex mx-2  ${darkMode ? "text-white" : "text-dark"
                      }`}
                    activeClassName={"active"}
                    exact={true}
                    as={Link}
                    key={index}
                  >
                    <div>
                      {link.name}
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '0',
                        height: link.isActive ? '3px' : '0px',
                        background: '#3366FF',
                        backgroundColor: '#3366FF',
                        minWidth: '100%'
                      }}></div>
                  </Nav.Link>
                ))}
              </Nav>
            )}


            {profile ? (
              <Nav>
                <NavDropdown
                  title={
                    <>
                      <img src={UserThumbnail} />
                      <span
                        className="user-name"
                        style={darkMode ? { color: "#E5EAF1" } : {}}
                      >
                        {username}
                      </span>
                      <img src={DropDownArrow} />
                    </>
                  }
                  id="navbarScrollingDropdown "
                  className={
                    "btn user-profile m-0 " + darkMode ? " user-drop-down" : ""
                  }
                  style={
                    darkMode
                      ? { backgroundColor: "#212542", border: "none", zIndex: "99999999999999999999999999" }
                      : { zIndex: "99999999999999999999999999" }
                  }
                >
                  <NavDropdown.Item href="#action3">
                    User Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleLogout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>


              </Nav>
            ) : (
              ""
              // <div className="d-flex justify-center align-items-center">
              //   <FaGear className="text-white" style={{ fontSize: "18px" }} />
              // </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <nav>
      <div className="left-section">
        <div className="logo">Logo</div>
        {links && (
          <ul className="links">
            {links.map((link, index) => (
              <li key={index}>{link}</li>
            ))}
          </ul>
        )}
      </div>
      <div className="right-section">
        {profile ? (
          <div className="profile">
            Profile
            <div className="toggle">Dark Toggle</div>
          </div>
        ) : (
          <div className="settings-gear">Settings Gear Icon</div>
        )}
      </div>
    </nav> */}
    </>
  );
};

export default CustomNavbar;
