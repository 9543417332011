import axios from 'axios';
import trainerJson from './trainer_state.json';

// Function to generate a random color
const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#3366ff';
  // for (let i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  return color;
};

// Extract and prepare data from trainerJson
const prepareTrainerData = (trainerData) => {
  const logHistory = trainerData.log_history || [];

  const steps = logHistory.map(item => `Step ${item.step}`);
  const learningRates = logHistory.map(item => item.learning_rate || 0);
  const losses = logHistory.map(item => item.loss || 0);

  return {
    learningRates,
    losses,
    steps
  };
};

const { learningRates, losses, steps } = prepareTrainerData(trainerJson);

// Generate colors for each dataset
const learningRateColor = generateRandomColor();
const lossColor = generateRandomColor();

// Define chart data for learning rate
const learningRateChartData = {
  labels: steps,
  datasets: [
    {
      label: 'Learning Rate',
      data: learningRates,
      fill: false,
      backgroundColor: learningRateColor,
      borderColor: learningRateColor,
      borderWidth: 1
    }
  ]
};

// Define chart data for loss
const lossChartData = {
  labels: steps,
  datasets: [
    {
      label: 'Loss',
      data: losses,
      fill: false,
      backgroundColor: lossColor,
      borderColor: lossColor,
      borderWidth: 1
    }
  ]
};

// Sample data for the chart
const sampleData = {
  rouge: {
    rouge1: { precision: 0.5, recall: 0.4, fmeasure: 0.45 },
    rouge2: { precision: 0.6, recall: 0.5, fmeasure: 0.55 },
    rougeL: { precision: 0.55, recall: 0.45, fmeasure: 0.50 },
    rougeLsum: { precision: 0.65, recall: 0.55, fmeasure: 0.60 }
  },
  bleu: {
    precisions: [0.3, 0.4, 0.35, 0.25],
    bleu: 0.33
  },
  perplexity: {
    original: 120.5,
    generated: 95.3
  }
};

// Function to fetch sample chart data
const fetchChartData = async () => {
  try {
    // Use sample data instead of API response
    const data = sampleData;

    // Generate colors for each dataset
    const precisionColor = generateRandomColor();
    const recallColor = generateRandomColor();
    const fmeasureColor = generateRandomColor();
    const bleuColors = [generateRandomColor(), generateRandomColor(), generateRandomColor(), generateRandomColor()]; // For BLEU n-grams
    const perplexityColor = generateRandomColor();

    // Define chart data for Rouge
    const rougeData = {
      labels: ['ROUGE-1', 'ROUGE-2', 'ROUGE-L', 'ROUGE-Lsum'],
      datasets: [
        {
          label: 'Precision',
          data: [
            data.rouge.rouge1.precision,
            data.rouge.rouge2.precision,
            data.rouge.rougeL.precision,
            data.rouge.rougeLsum.precision,
          ],
          fill: false,
          backgroundColor: precisionColor,
          borderColor: precisionColor,
          borderWidth: 1
        },
        {
          label: 'Recall',
          data: [
            data.rouge.rouge1.recall,
            data.rouge.rouge2.recall,
            data.rouge.rougeL.recall,
            data.rouge.rougeLsum.recall,
          ],
          fill: false,
          backgroundColor: recallColor,
          borderColor: recallColor,
          borderWidth: 1
        },
        {
          label: 'F-Measure',
          data: [
            data.rouge.rouge1.fmeasure,
            data.rouge.rouge2.fmeasure,
            data.rouge.rougeL.fmeasure,
            data.rouge.rougeLsum.fmeasure,
          ],
          fill: false,
          backgroundColor: fmeasureColor,
          borderColor: fmeasureColor,
          borderWidth: 1
        },
      ],
    };

    // Define chart data for BLEU
    const bleuData = {
      labels: ['BLEU-1', 'BLEU-2', 'BLEU-3', 'BLEU-4'],
      datasets: [
        {
          label: 'BLEU-1 Precision',
          data: [data.bleu.precisions[0]],
          fill: false,
          backgroundColor: bleuColors[0],
          borderColor: bleuColors[0],
          borderWidth: 1
        },
        {
          label: 'BLEU-2 Precision',
          data: [data.bleu.precisions[1]],
          fill: false,
          backgroundColor: bleuColors[1],
          borderColor: bleuColors[1],
          borderWidth: 1
        },
        {
          label: 'BLEU-3 Precision',
          data: [data.bleu.precisions[2]],
          fill: false,
          backgroundColor: bleuColors[2],
          borderColor: bleuColors[2],
          borderWidth: 1
        },
        {
          label: 'BLEU-4 Precision',
          data: [data.bleu.precisions[3]],
          fill: false,
          backgroundColor: bleuColors[3],
          borderColor: bleuColors[3],
          borderWidth: 1
        },
      ],
    };

    // Define chart data for Perplexity
    const perplexityData = {
      labels: ['Original', 'Generated'],
      datasets: [
        {
          label: 'Perplexity',
          data: [data.perplexity.original, data.perplexity.generated],
          fill: false,
          backgroundColor: perplexityColor,
          borderColor: perplexityColor,
          borderWidth: 1
        },
      ],
    };

    // Define chart options
   // Define chart options
const options1 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        color: '#BAC9F1', // Set the color of the legend text
      },
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `Value: ${tooltipItem.raw}`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Epoch',
        color: '#BAC9F1', // Set the color of the x-axis title
      },
      ticks: {
        color: '#BAC9F1', // Set the color of the x-axis ticks
      },
    },
    y: {
      title: {
        display: true,
        text: 'Learning Rate',
        color: '#BAC9F1', // Set the color of the y-axis title
      },
      ticks: {
        color: '#BAC9F1', // Set the color of the y-axis ticks
      },
    },
  },
};
const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        color: '#BAC9F1', // Set the color of the legend text
      },
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `Value: ${tooltipItem.raw}`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Epoch',
        color: '#BAC9F1', // Set the color of the x-axis title
      },
      ticks: {
        color: '#BAC9F1', // Set the color of the x-axis ticks
      },
    },
    y: {
      title: {
        display: true,
        text: 'Loss',
        color: '#BAC9F1', // Set the color of the y-axis title
      },
      ticks: {
        color: '#BAC9F1', // Set the color of the y-axis ticks
      },
    },
  },
};


    return {
      rougeData,
      bleuData,
      perplexityData,
      options1,
      options2,
      bleuScore: data.bleu.bleu
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      rougeData: {},
      bleuData: {},
      perplexityData: {},
      options: {},
      bleuScore: null
    };
  }
};

// Export both chart data objects
export { fetchChartData, learningRateChartData, lossChartData };
