import React from 'react'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import LogoWhite from "../../assets/img/logoWhite.svg";
import logodark from "../../assets/img/logoWhite.svg";

const MoHeader = ({darkMode, knowledgeWorkName, handleNext, handleBack, modelId,handleModelTraining}) => {
    
    
    const navigate = useNavigate();
    const handleHome = () => {
      return navigate("/");
    };
    const handleProceed = () => {
        // Ensure modelId is available and handleNext is called with modelId
        if (modelId) {
          handleNext(modelId);
        } else {
          console.error('Model ID is not available.');
        }
      };
    return (
      <>
  
        <Navbar
          collapseOnSelect
          expand="lg"
          className={
            darkMode
              ? `navWithBgDark competencydetails p-2`
              : `navWithBg competencydetails p-0`
          }
        >
          <Container fluid style={{ paddingLeft: "40px", paddingRight: "40px" }}>
            <Navbar.Brand href="" onClick={handleHome}>
              <img
                className="img-fluid logoSize"
                loading="lazy"
                src={darkMode ? logodark : LogoWhite}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
           
                <Nav
                  className="me-auto alchemNavLinks"
                  defaultActiveKey={"competencies"}
                  >
                    
                </Nav> 
               
               

  
            
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  };

export default MoHeader
