import React, { useState, useContext, useEffect } from "react";
import "../ChatPopup/ChatPopup.css";
import "./ActivityPopup.css";
import { DockContext } from "../../DockWrapper/DockContext";
import ChatRobot from "../../../assets/img/coachllm-robo.svg";
import PropTypes from "prop-types";
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from "react-icons/bs";
import { Button, Dropdown, Nav, Spinner } from "react-bootstrap";
import ReactFlowContainer from "../ReactFlowContainer";
import MindMapReactFlow from "../../mindmap/MindMapReactFlow";
import axios from "axios";

function ActivityPopup() {
    const [isOpen, setIsOpen] = useState(false);

    const {
        isAchainDocked,
        selectedKnowledgeWork
    } = useContext(DockContext);

    console.log(selectedKnowledgeWork)


    const [activityPopLink, setActivityPopLink] = useState([])
    const [history, setHistory] = useState([])
    const [additionalHiddenPopLinks, setAdditionalHiddenPopLinks] = useState([])

    const removePopLink = (selectedLink, index) => {
        if (activityPopLink.length > 1) {
            selectedLink.isActive = false
            const modifiedPopLink = activityPopLink.filter((l) => l.name !== selectedLink.name)
            const modifiedAdditionalPopLink = additionalHiddenPopLinks.concat(selectedLink)
            console.log(modifiedPopLink)
            setActivityPopLink(modifiedPopLink)
            setAdditionalHiddenPopLinks(modifiedAdditionalPopLink)
        }

    }

    const addPopLink = (selectedLink) => {
        selectedLink.isActive = false
        const modifiedActivityPopLink = activityPopLink.concat(selectedLink)
        const modifiedAdditionalPopLink = additionalHiddenPopLinks.filter((l) => l.name !== selectedLink.name)
        console.log(modifiedAdditionalPopLink, modifiedActivityPopLink)
        setActivityPopLink(modifiedActivityPopLink)
        setAdditionalHiddenPopLinks(modifiedAdditionalPopLink)
    }



    useEffect(() => {

        setActivityPopLink([
            { name: "Activity Chain", route: "", isActive: true, id: 1 },
            { name: "Mindmap", route: "", isActive: false, id: 2 },
        ])
        setAdditionalHiddenPopLinks([
            { name: "Explainability", route: "", isActive: false, id: 1 },
        ])



    }, [])




    return (
        <div className={`${isOpen ? "chat-popup  open " : !isAchainDocked ? "d-none" : "h-100"} `}>

            <div
                className={
                    isAchainDocked ? "chat-container-docked shadow" : "chat-container shadow"
                }
                style={isAchainDocked ? { backgroundColor: "red", height: "100%" } : { backgroundColor: "red" }}
            >
                <div
                    className="chat-nav text-center d-flex flex-row p-2 pb-0 gap-4"
                    style={{ minHeight: '10%', maxHeight: "13%", background: '#34385A 0% 0% no-repeat padding-box', borderRadius: '4px 4px 0px 0px', font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0.59px', color: '#E5EAF1', borderBottom: ".6px solid #958b8b" }}
                >
                    {activityPopLink?.map((link, index) => (
                        <Nav.Link
                            className={`position-relative align-items-center d-flex mx-2 text-light `}
                            activeClassName={"active"}
                            exact={true}
                            key={index}
                        >
                            <div
                                onClick={() => {
                                    let arr = [...activityPopLink]
                                    arr = arr.map((element) => {
                                        console.log("activity popup", element,)
                                        if (element.name === link.name) {
                                            element.isActive = true
                                        } else {
                                            element.isActive = false
                                        }
                                        return element
                                    })
                                    console.log(arr)
                                    setActivityPopLink(arr)
                                }}>
                                {link.name}
                            </div>
                            {link.name !== "Activity Chain" &&
                                <div>
                                    <i className="ml-1 fa-solid fa-xmark text-light" onClick={() => removePopLink(link, index)}></i>
                                </div>
                            }
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    height: link.isActive ? '3px' : '0px',
                                    background: '#3366FF',
                                    backgroundColor: '#3366FF',
                                    minWidth: '100%'
                                }}></div>
                        </Nav.Link>
                    ))}
                    <Dropdown className="align-self-end">
                        <Dropdown.Toggle variant="info" id="dropdown-basic" className="d-flex justify-content-center align-items-center m-1 w-auto" style={{ height: "25px" }}>
                            Add Options <i className="fa-solid fa-plus ml-1"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {additionalHiddenPopLinks?.map((link) => {
                                return <Dropdown.Item onClick={() => addPopLink(link)}>{link.name}</Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>


                <div className="chat-content-activity" style={{ height: "90%", overflow: "hidden", background: '#212542 0% 0% no-repeat padding-box', borderRadius: '0px 0px 4px 4px' }}>



                    {activityPopLink?.find((item) => item.name === "Mindmap")?.isActive &&
                        <div style={{ width: "100%", height: "100%", background: '#212542 0% 0% no-repeat padding-box' }}>
                            <MindMapReactFlow />
                        </div>
                    }

                    {activityPopLink?.find((item) => item.name === "Activity Chain")?.isActive &&
                        <ReactFlowContainer />
                    }

                    {
                        activityPopLink?.find((item) => item.name === "Explainability")?.isActive &&
                        <Explainability />
                    }


                </div>

            </div >

        </div >
    )
}
ActivityPopup.propTypes = {};

export default ActivityPopup



// --------------------------------------------activityChain popup

export function Explainability() {

    const [summaryOfReflectionOnCoachllm, setSummaryOfReflectionOnCoachllm] = useState("")
    const [reflectionModalLoading, setReflectionModalLoading] = useState(false)
    const [history, setHistory] = useState([])


    const {
        selectedKnowledgeWork
    } = useContext(DockContext);


    const populateHistory = () => {
        axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork?.id}/chat_history`)
            .then(async res => {

                if (Object.keys(res.data).includes("chathistory_chain_payload")) {
                    console.log(res.data)
                    setHistory(res.data?.chathistory_chain_payload)
                }
            }
            ).catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        populateHistory()
    }, [])


    const reflectOnCoachllm = () => {
        setSummaryOfReflectionOnCoachllm("")

        axios.post(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork?.id}/random_input`, {
            "context": JSON.stringify(history),
            "stage": "coachllm_prompt",
            "conversation": []
        })
            .then(responses => {
                console.log(responses.data.data[0])
                if (responses.status == 200) {
                    const response = responses.data.data[0]["content"].replaceAll("**", "")
                    console.log(response)
                    setSummaryOfReflectionOnCoachllm(response)
                }
                setReflectionModalLoading(false)

            })
            .catch(error => {
                console.log(error)
                setSummaryOfReflectionOnCoachllm("Something went wrong, Please try to regenerate...!")
                setReflectionModalLoading(false)
            })
    }



    return (
        <>
            {

                reflectionModalLoading ?
                    <div className="d-flex justify-content-center align-items-center m-4 h-100">
                        <Spinner animation="grow" variant={"warning"} />
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center p-2  custom-scrollbar h-100 w-100">
                        <p className="text-capitalize fs-6 text-justify text-white p-1 py-2" style={{ fontFamily: "Calibri", whiteSpace: "pre-line", height: "100%", width: "100%" }}>
                            {`${summaryOfReflectionOnCoachllm?.replaceAll("**", "")}`}
                        </p>
                    </div>
            }
            <div className="d-flex gap-2 justify-content-center align-items-center m-1 p-1">

                <Button variant="primary" style={{ width: "fit-content" }} onClick={() => { setReflectionModalLoading(true); reflectOnCoachllm(); }}>
                    Generate
                </Button>
            </div>
        </>
    )
}

export function ChainPopup() {

    const {
        setisFinish,
        dockSize,
        setIsDraggable,
        isDraggable,
        mindmapLoader,
        isAchainDocked,
        setisAchainDocked,
        achaindockSize,
        setAchainDockSize,
        achaincontentSize,
        setAchainContentSize
    } = useContext(DockContext);
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [isChainValidated, setIsChainValidated] = useState(false)



    const toggleChat = () => {
        setIsOpen(!isOpen);

    };


    const toggleDock = () => {
        setisAchainDocked(!isAchainDocked);
        setisFinish(false)
        setIsDraggable(!isDraggable);
        if (isAchainDocked) {
            setAchainContentSize(100)
        }
        else {
            setAchainContentSize(100 - dockSize * 100)
        }
    };



    return (
        <>
            <div className={`${isOpen ? "chat-popup  open " : !isAchainDocked ? "d-none" : "h-100"} `}>




                <div
                    className={
                        isAchainDocked ? "chat-container-docked shadow" : "chat-container shadow"
                    }
                    style={isAchainDocked ? { backgroundColor: "red", height: "100%" } : { backgroundColor: "red" }}
                >

                    <div className="chat-header" style={{ height: "10%" }}>
                        <div
                            className="chat-nav text-center"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "85%",
                                height: "100%",
                                boxSizing: "border-box"
                            }}

                        >
                            <p className="align-middle text-uppercase fw-medium text-light" style={{ margin: "auto" }}>
                                Activity Chain
                            </p>

                            <div className="d-flex gap-2 m-auto" >
                                {
                                    <>
                                        <p className="m-0 text-light">Validation Complete</p>
                                        <i className="fa-solid fa-check" style={{ color: "#63E6BE", margin: "auto" }}></i>
                                    </>
                                }
                            </div>

                        </div>
                        <div className="d-flex justify-content-between ">

                            {/* <button className="btn" onClick={toggleDock}> */}
                            <button className="btn" >
                                {isAchainDocked ? (
                                    <BsBoxArrowDownLeft style={{ color: "white" }} />
                                ) : (
                                    <BsBoxArrowUpRight style={{ color: "white" }} />
                                )}
                            </button>

                        </div>
                    </div>


                    <div className="chat-content-activity" style={{ height: "90%", overflow: "hidden" }}>

                        <ReactFlowContainer />

                    </div>

                </div>

            </div>
            {!isAchainDocked &&
                <button
                    className={isOpen ? " chat-toggle-btn-open" : "chat-toggle-btn"}
                    onClick={toggleChat}
                >
                    <div className="chat-popup-robot-icon">
                        <img
                            src={ChatRobot}
                            alt=""
                            width={"20px"}
                            style={{ marginBottom: "5px" }}
                        />
                    </div>

                    {isOpen ? (
                        ""
                    ) : (
                        <p className="chat-popup-right-text mb-2 mt-1">Coach LLM</p>
                    )}
                </button>
            }
        </>
    )
}

ChainPopup.propTypes = {
    heading: PropTypes.string.isRequired,
    modelTraining: PropTypes.object.isRequired,
}



