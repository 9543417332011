import React, { useEffect, useState, useContext, useCallback } from "react";
import { DockContext } from "../DockWrapper/DockContext";
import "./AChain.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ActivityNode from "./ActivityNode";
import CreateAgentActivityNode from "./CreateAgentActivityNode";
import Mind from "../mindmap/Mind"
import ReactFlow, {
  Panel,
  Controls,
  MarkerType,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  MiniMap,
  Background,
} from "reactflow";
import "reactflow/dist/style.css";
import ActivityWithAgent from "./ActivityWithAgentNode";
import colorCode from "../../data/activityNodeDB";
import { AuthContext } from "../Auth/AuthContext";
import CustomNodeInputField from "./reactFlowCustomNode/CustomNodeAChain";
const rfStyle = {
  backgroundColor: "#f2f2f2",
};
const rfStyleDark = {
  backgroundColor: "#34385A",
  border: "none",
};


const nodeTypes = {
  activityNode: ActivityNode,
  createAgentNode: CreateAgentActivityNode,
  activityWithAgent: ActivityWithAgent,
  customNode: CustomNodeInputField
};


export default function ReactFlowContainer() {

  const { darkMode } = useContext(AuthContext)
  const {
    chains,
    isAchainDock,
    activityReactFlowHis,
    setactivityReactFlowHis

  } = useContext(DockContext);

  const [nodes, setNodes, onNodesChange] = useNodesState(activityReactFlowHis?.nodes ? activityReactFlowHis.nodes : []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(activityReactFlowHis?.edges ? activityReactFlowHis.edge : []);

  const location = useLocation()
  useEffect(() => {
    setNodes(activityReactFlowHis?.nodes)
    setEdges(activityReactFlowHis?.edges)
    console.log("changed", activityReactFlowHis)

  }, [activityReactFlowHis?.nodes, activityReactFlowHis?.edges])


  const onConnect = useCallback(
    (params) => {
      try {
        const existingEdge = edges.filter((edge) => edge.source == params.source);
        if (existingEdge.length == 0) {
          setEdges((eds) => {
            let tempEdges = addEdge(params, eds).map((e) => {
              e["type"] = "smoothstep";
              e["markerEnd"] = {
                type: MarkerType.ArrowClosed,
              };
              return e;
            });
            return tempEdges;
          });
        } else {
          alert("Node already connected to another node");
        }

      } catch (error) {
        console.error("something went wrong on creating edges and nodes")
      }

    },

    []
  );



  // -----------------------------------------------------------Right Click Feature -> node manipulation
  // preventing right click if node is not selected 
  const handleRightClick = (e) => {
    e.preventDefault()
    if (nodes.length <= 0) {
      alert("add nodes to use right click")
      e.preventDefault()
    }
  }


  // ---------------------------------------------------------------------

  // color selection for node in mindmap projection 
  function nodeColor(node) {
    switch (node.data.Activity_Classification) {
      case 'recognition':
        return '#50fc95'
      case 'predictive':
        return '#ffbc36'
      case 'generative':
        return '#ee92e7'
      default:
        return '#2a6f97';
    }
  }


  return (
    <ReactFlowProvider>

      <div
        className={`${isAchainDock ? "70%" : "100%"}`}
        style={
          darkMode
            ? {
              height: `${isAchainDock ? "70%" : "100%"}`,
              background:
                "transparent linear-gradient(116deg, #222E59 0%, #212542 100%) 0% 0% no-repeat padding-box",
              maxWidth: "100%",
              width: "100%",
              padding: `${isAchainDock && "5%"}`
            }
            : { height: "50%" }
        }
      >
        <ReactFlow
          onContextMenu={handleRightClick}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          connectOnClick={false}
          selectNodesOnDrag={false}
          onError={(e) => console.log(e)}
          fitView
          style={darkMode ? rfStyleDark : rfStyle}
          className="react-flow__edge"

        >
          {!nodes ? (
            <Panel>
              <div className="pannelText">
                <i>
                  Activity list will be generated from Genius Partner{" "}
                </i>
              </div>
            </Panel>
          ) : (
            <></>
          )}
          <Panel style={location.pathname.includes("testagent") ? { marginRight: `0px` } : {}} position={location.pathname.includes("testagent") ? "bottom-right" : "top-left"}>
            <div
              className="pannelText "
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: `${location.pathname.includes("testagent") ? "0px" : "1%"}`,
                height: "30px"
              }}

            >
              <div className="d-flex align-items-center mr-6">
                {Object.keys(colorCode).map(
                  (classification, index) => {
                    return (
                      <>
                        <div
                          className="pannel-legend-color "
                          style={{
                            backgroundColor:
                              colorCode[classification],
                          }}
                          key={index}
                        ></div>
                        <span key={1 + index} className="px-1 text-white text-capitalize">{classification}</span>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          </Panel>

          <Controls
            id="flow-cont"
            position={isAchainDock ? "bottom-right" : "top-right"}
            showZoom={true}
            showFitView={true}
            showInteractive={false}
            className={`${darkMode ? "zoomTransformDark mt-3" : "zoomTransform"
              }`}
            style={{
              height: "fit-content",
              width: "fit-content",
              display: "flex",
              backgroundColor: darkMode ? "#34385A" : "white",
              borderRadius: "0.4rem",

            }}
          ></Controls>
          <MiniMap className='mindMapReactFlow-miniMap' nodeStrokeWidth={3} nodeColor={nodeColor} pannable={true} style={{ backgroundColor: "#34385a" }} nodeStrokeColor={"yellow"} position={location.pathname.includes("testagent") ? "bottom-left" : "bottom-right"} />
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  )
}
