import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { DockContext } from "../../DockWrapper/DockContext";
import { AuthContext } from "../../Auth/AuthContext";
import axios from "axios";
import { faBedPulse } from "@fortawesome/free-solid-svg-icons";

const ActivityModal = ({ showModal, closeModal, setFirstTime }) => {

  const { darkMode } = useContext(AuthContext);
  const [name, setName] = useState("");
  const { setNodes, setNodeIndex } = useContext(DockContext);

  const { subID } = useContext(AuthContext)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isReadOnlyKwName, setisReadOnlyKwName] = useState(false)
  const [disableProceed, setDisableProceed] = useState(false)


  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setName(event.target.value);
  };


  useEffect(() => {
    localStorage.setItem("name", name);
  }, [name]);


  const clearState = () => {
    clearErr()
    setUploadLoader(false)
    setDisableProceed(false)
    setisReadOnlyKwName(false)
    setisDocumentAvailable(false)
    setInputFileEvent({})
    setName("")
  }

  const clearErr = () => {
    setIsError(false)
    setErrorMsg("")
  }


  const handleCancel = () => {
    clearState()
    closeModal()
  }


  const handleProceed = () => {

    const { status, message } = nameValidation()
    setUploadLoader(true)
    setisReadOnlyKwName(true)
    setDisableProceed(true)

    if (!status) {
      setUploadLoader(false)
      setIsError(true)
      setDisableProceed(false)
      setErrorMsg(JSON.stringify(message))
      setisReadOnlyKwName(false)
      return ""
    }

    if (!isDocumentAvailable) {
      axios
        .post(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/vector_all_no_docs?name=${name}`)
        .then((res) => {
          setisReadOnlyKwName(false)
          if (res.status == 200) {
            setUploadLoader(false)
            setDisableProceed(true)
            setIsError(false)
            console.log(name);
          }

        })
        .then(() => {
          setNodeIndex(0);
          setNodes([]);
          setName("")
          setisDocumentAvailable(false)
          closeModal();
        })

        .catch((error) => {
          console.log(error)
          setUploadLoader(false)
          setName("")
          setIsError(true)
          setDisableProceed(false)
          if (Object.keys(error).includes("response")) {
            setErrorMsg(JSON.stringify(error?.response?.data?.detail))
          } else {
            setErrorMsg("unable to create knowledge work, please try again later")

          }
          setisReadOnlyKwName(false)
          return ""
        })

    }

    if (isDocumentAvailable) {
      submitFile(inputFileEvent)
    }

  }

  const nameValidation = () => {
    if (!name) {
      return { status: false, message: "please provide valid name for knowledge work" }
    }

    const specialCharaterCheck = /\W+/g
    const alphaNumricCheck = /[a-zA-Z0-9]/g

    let nameWithOutHypen = name.replaceAll("-", "").replaceAll(" ", "")

    console.log(specialCharaterCheck.test(nameWithOutHypen), alphaNumricCheck.test(nameWithOutHypen), nameWithOutHypen)
    console.log(specialCharaterCheck.test(nameWithOutHypen) || !alphaNumricCheck.test(nameWithOutHypen), nameWithOutHypen)

    if (specialCharaterCheck.test(nameWithOutHypen)) {
      setName("")
      return { statue: false, message: "Knowledge Work name should not contain any special characters" }
    }

    if (!alphaNumricCheck.test(nameWithOutHypen)) {
      setName("")
      return { statue: false, message: "Knowledge Work name should not contain any special characters" }
    }

    return { status: true, message: "valid name" }


  }

  const [uploadLoader, setUploadLoader] = useState(false)
  const [inputFileEvent, setInputFileEvent] = useState({})

  const submitFile = async (event) => {
    clearErr()
    console.log(event)

    if (partitionConfig.partition < 512 || partitionConfig.qsPartition < 3) {
      setIsError(true)
      setErrorMsg("Min limit for partition: 512 and QsPartition 3")
      setUploadLoader(false)
      return ""
    }

    if (!event || Object.keys(event).length <= 0) {
      console.log("there is no event")
      setIsError(true)
      setErrorMsg("Please upload a valid document. If not please unselect the upload document switch.")
      setUploadLoader(false)
      return null
    }

    event.preventDefault();
    const { status, message } = nameValidation()



    if (!status) {
      setIsError(true)
      setErrorMsg(JSON.stringify(message))
      event.target.value = ""
      setisReadOnlyKwName(false)
      setDisableProceed(true)
      setUploadLoader(false)
      return ""
    }

    const formData = new FormData();
    setisReadOnlyKwName(true)
    event.preventDefault();
    console.log(event.target.files)
    setIsError(false)

    if (!event.target.files) {
      setIsError(true)
      setErrorMsg("Please upload a valid document. If not please unselect the upload document switch.")
      return ""
    }

    Array.from(event.target.files).forEach((file) => {
      formData.append("files", file);
    });

    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": `multipart/form-data`,
      },
    };

    setUploadLoader(true)
    axios.post(
      `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/vector_all?question_count=${partitionConfig.qsPartition}&chunk_size=${partitionConfig.partition}&name=${name}`,
      formData,
      config
    ).then((res) => {

      if (res.status == 200) {
        setisReadOnlyKwName(false)
        setName("")
        setDisableProceed(false)
        setIsError(false)
        console.log(name);
        setUploadLoader(false)
        setisDocumentAvailable(false)
        clearState()
        closeModal()
        if (setFirstTime) { setFirstTime('new-user') }
      }

    }).catch((error) => {
      console.log(error)
      setUploadLoader(false)
      setIsError(true)
      if (Object.keys(error).includes("response")) {
        setErrorMsg(JSON.stringify(error?.response?.data?.detail))
      } else {
        setErrorMsg("unable to create knowledge work, please try again later")

      }
      event.target.value = ""
      setisReadOnlyKwName(false)
      setDisableProceed(true)

    })


  };


  const [isDocumentAvailable, setisDocumentAvailable] = useState(false)
  const [partitionConfig, setPartitionConfig] = useState({
    partition: 1024,
    qsPartition: 6
  })


  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{}}
        className={darkMode ? "alchemModalDark" : "alchemModal"}
      >
        <Modal.Header
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginLeft: "16px",
            marginRight: "16px",
            borderBottom: "1px solid #101221",
          }}
        >
          <Modal.Title className="modalHead">Create Knowledge Work</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <form onSubmit={(e) => e.preventDefault()}>


            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Knowledge Work Name
              </label>
              <input
                type="text"
                className="form-control"
                id="knowledgeWorkNameInput"
                name="modelName"
                onChange={(event) => { clearErr(); handleOptionChange(event); }}
                value={name}
                required
                placeholder="Unique Knowledge Work Name"
                readOnly={isReadOnlyKwName}
                style={{
                  background: darkMode ? "transparent" : "",
                  border: darkMode ? "1px solid #9CB1E8" : "",
                  color: darkMode ? "#E6E9F3" : ""
                }}
              />
            </div>
            <div className="mb-3">
              <div className="d-flex">
                <label htmlFor="exampleInputPassword1" className={`form-label`}>
                  Do you have supporting documents?
                </label>

                <div className="form-check form-switch">
                  <input className="form-check-input" autoFocus type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => { setisDocumentAvailable(!isDocumentAvailable) }} />
                  <label className="form-check-label" for="flexSwitchCheckDefault">{isDocumentAvailable ? 'Yes' : 'No'}</label>
                </div>
              </div>
              {
                isDocumentAvailable &&
                <>
                  <div className="d-flex justify-content-between w-100 mt-2">
                    <label className="form-label" id="inputGroup-sizing-default">Document Upload<i className="ml-2 fa-solid fa-circle-question"></i></label>
                    <label className="form-label" id="inputGroup-sizing-default" style={{ fontSize: '12px' }}><i>File Supported .pdf</i></label>
                  </div>

                  <input className={`form-control my-2`} type="file" id="formFileMultiple" style={{ border: "1px solid #0d6efd" }} multiple required onChange={(e) => { clearErr(); setDisableProceed(false); setInputFileEvent(e); console.log(e) }} />


                </>
              }
              {isError &&
                <p className="text-danger m-0 text-capitalize">
                  {errorMsg}
                </p>
              }
              {uploadLoader &&
                <div className="d-flex justify-content-center align-items-center p-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
            </div>


          </form>



        </Modal.Body>

        <Modal.Footer
          id="footer"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <button
            className="btn btn-primary submitBtnDark me-0"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary submitBtnDark me-0"
            onClick={handleProceed}
            disabled={disableProceed}
          >
            Proceed
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ActivityModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ActivityModal;
