import React, { useContext, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import { IoIosClose } from "react-icons/io";
const LoadModal = ({ showModal, closeModal }) => {
  const {darkMode} = useContext(AuthContext)
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    radio: ""
  });
  const navigate = useNavigate();
  const [radio, setRadio] = useState("");

  const [formData, setFormData] = useState({
    rewardModel: "",
    version1: "",
    ppoModel: "",
    version2: ""
  });
  const handleDropdownChange = event => {
    const { name, value } = event.target;
    setSelectedOption(value);
    setShowDropdown(value === "yes");
    setRadio({
      ...radio,
      [name]: value
    });
  };

  const handleOptionChange = event => {
    // event.preventDefault();
    const { name, value } = event.target;

    // Show dropdown only if "Yes" is selected
    setFormData({
      ...formData,
      [name]: value
    });
    // console.log(formData);
  };
  const arr = [];
  if (selectedOption === "no") {
    arr.push(radio);
  } else {
    arr.push(radio, formData);
  }
  const handleSubmit = () => {
    console.log("form data: ", arr);
    if (selectedOption === "no") {
      window.location.href = "/comparemodels";
      return;
    }
    if (
      formData.rewardModel.trim() === "" ||
      formData.version1.trim() === "" ||
      formData.version2.trim() === "" ||
      formData.ppoModel === ""
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    console.log("form data: ", arr);
    localStorage.setItem(
      "rewardModelstatus",
      JSON.stringify(arr[0].radioButton)
    );
    localStorage.setItem("rewardModel", JSON.stringify(arr[1].rewardModel));
    localStorage.setItem("version1", JSON.stringify(arr[1].version1));
    localStorage.setItem("ppoModel", JSON.stringify(arr[1].ppoModel));
    localStorage.setItem("version2", JSON.stringify(arr[1].version2));
    navigate("/rewardModel");
    closeModal();
  };
  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={darkMode ? 'alchemModalDark' : 'alchemModal'}
        keyboard={false}
        
      >
        <Modal.Header
          
          className="border-bottom-0"
          style={{ paddingLeft: "32px", paddingRight: "32px" }}
        >
          <Modal.Title className="modalHead " style={{ lineHeight: "25px" }}>
            Do you want to Train the model with Reward and PPO Model?
          </Modal.Title>
            <button style={{background: 'transparent', color:'#fff', position:"relative", left:"20px", bottom:'10px'}}> <IoIosClose  className="fs-2"  onClick={closeModal}/></button>
        </Modal.Header>
        <Modal.Body>
          {/* <p
            style={{ fontSize: "20px", fontFamily: "calibri-bold" }}
            className="modalTitle"
          >
            Do you want to Train the model with Reward and PPO Model?
          </p> */}
          <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-row">

          <label className="switch custom-radio" >
            <input
              type="radio"
              value="yes"
              name="radioButton"
              checked={selectedOption === "yes"}
              onChange={handleDropdownChange}
            />
            <span className="slider round">
              {" "}
              Yes, Select Reward & PPO Model
            </span>
          </label>
          <label className="switch ms-4 custom-radio">
            <input
              type="radio"
              value="no"
              name="radioButton"
              checked={selectedOption === "no"}
              onChange={handleDropdownChange}
            />
            <span className="slider round"> No, Go to Preview Model</span>
          </label>
          </div>

          {showDropdown && (
            <div className="mt-4">
              <label className="form-label ">Reward Model</label>

              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
                name="rewardModel"
                onChange={handleOptionChange}
              >
                <option selected>Open this select menu</option>
                <option value="5">opt-350m</option>
                <option value="6">decapoda-research-llama-7b-hf</option>
              </select>

              <label className="form-label mt-3">Version</label>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
                name="version1"
                onChange={handleOptionChange}
              >
                <option selected>Open this select menu</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
              <label className="form-label mt-3">PPO Model</label>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
                name="ppoModel"
                onChange={handleOptionChange}
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <label className="form-label mt-3">Version</label>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
                name="version2"
                onChange={handleOptionChange}
              >
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          )}
          </div>
        </Modal.Body>

        <Modal.Footer id="footer" className="">
          <a
            className="btn btn-primary cancelBtnDark me-3"
            onClick={closeModal}
            role="button"
          >
            Cancel
          </a>
          <a
            className="btn btn-primary submitBtnDark me-3"
            onClick={handleSubmit}
            role="button"
          >
            Proceed
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoadModal;
