import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
// import { LinearProgress } from '@mui/material/LinearProgress';
import './ProgressBarBlue.css'
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//     root: {
//         height: 10,
//         borderRadius: 5,
//         backgroundImage: 'linear-gradient(to right, #ff8a65, #ffcd38)',
//     },
// });

const ProgressBarBlue = ({value}) => {
    console.log(typeof(value));
    // const classes = useStyles;
    return (
        <div className='blue'>

            <LinearProgress
                variant="determinate"
                value={value}
                className='rotate'
                // classes={{
                //     root: classes.root,
                // }}
            />
        </div>
    );
};

export default ProgressBarBlue;
