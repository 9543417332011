import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaToggleOn } from "react-icons/fa";
import { FaToggleOff } from "react-icons/fa6";
const ConfigureModal = ({ showModal, closeModal, darkMode }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    radio: "",
  });
  const [radio, setRadio] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    modelName: "",
    description: "",
    purpose: "",
    domain: "Digital",
    task: "",
  });
  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setSelectedOption(value);
    setShowDropdown(value === "yes");
    setRadio({
      ...radio,
      [name]: value,
    });
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const arr = [];

  arr.push(isChecked, formData);

  const handleSubmit = () => {
    if (
      arr[1].modelName.trim() === "" ||
      arr[1].description.trim() === "" ||
      arr[1].purpose.trim() === "" ||
      arr[1].task === ""
    ) {
      alert("Please fill in all required fields." + formData);
      return;
    }
    console.log("form data: ", arr[1]);
    localStorage.setItem("name", arr[1].modelName);
    localStorage.setItem("description", arr[1].description);
    localStorage.setItem("purpose", arr[1].purpose);
    localStorage.setItem("usecase", arr[1].domain);
    localStorage.setItem("task", arr[1].task);
    closeModal();
    navigate("/modelconfig");
  };
  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className={darkMode ? "alchemModalDark" : "alchemModal"}
      >
        <Modal.Header>
          <Modal.Title className="modalHead">Configure Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Model Name
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              name="modelName"
              onChange={handleOptionChange}
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Description
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              name="description"
              onChange={handleOptionChange}
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Purpose
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              name="purpose"
              onChange={handleOptionChange}
            />
          </div>
          <div class="mb-3">
            <label className="form-label">Domain</label>
            <select
              className="form-select form-select-sm"
              aria-label=".form-select-sm example"
              name="domain"
              onChange={handleOptionChange}
            >
              <option selected>Open this select menu</option>
              <option value="Life Sciences">Life Sciences</option>
              <option value="Retail_CPG_eCommerce">
                Retail CPG & e-commerce
              </option>
              <option value="Banking_Financial_Services_Insurance">
                Banking, Financial Services & Insurance
              </option>
              <option value="Manufacturing_including_Automotive">
                Manufacturing including Automotive
              </option>
              <option value="Utilities">Utilities</option>
              <option value="Hitech">Hitech</option>
              <option value="Travel_Transport_Hospitality">
                Travel, Transport & Hospitality
              </option>
              <option value="Construction_Engineering">
                Construction & Engineering
              </option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="Media_Entertainment">Media & Entertainment</option>
              <option value="Energy_Mining">Energy & Mining</option>
              <option value="Education">Education</option>
              <option value="Consulting_Professional_Services">
                Consulting & Professional Services
              </option>
              <option value="Govt_Public_Sector">Govt & Public Sector</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Task
            </label>
            <select
              class="form-control form-select-sm"
              aria-label=".form-select-sm example"
              name="task"
              onChange={handleOptionChange}
            >
              <option selected>Open this select menu</option>
              <option value="1">Summarization</option>
              <option value="2">QA</option>
              <option value="3">Text Generation</option>
            </select>
          </div>
          <div class="mb-3 display-flex">
            <label for="exampleInputPassword1" class="form-label">
              Train with Reward and PPO Model?
              <input
                type="checkbox"
                class="form-check-input"
                name="toggle"
                id="exampleInputPassword1"
                checked={isChecked}
                onChange={handleToggle}
                style={{ display: "none" }}
              />
              <span
                className={`toggle-button ${isChecked ? "active" : ""} ms-2`}
                onClick={handleToggle}
              >
                {isChecked ? (
                  <FaToggleOn style={{ fontSize: "30px", color: "grey" }} />
                ) : (
                  <FaToggleOff style={{ fontSize: "30px", color: "grey" }} />
                )}
              </span>
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer id="footer">
          <a
            class="btn btn-primary cancelBtnDark me-3"
            onClick={closeModal}
            role="button"
          >
            Cancel
          </a>
          <a class="submitBtnDark me-3" onClick={handleSubmit} role="button">
            Proceed
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfigureModal;
