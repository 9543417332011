import Container from "react-bootstrap/Container";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import { Button, Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import "../AgentModule/CreateAgent/TestAgent.css";
import axios from "axios";
import { DockContext } from "../DockWrapper/DockContext";
import { AuthContext } from "../Auth/AuthContext";

const demoData = {
  id: "-200",
  name: "demo",
  user_id: "testing",
  chain_payload: {
    nodes: [],
    edges: [],
    name: "",
    purpose: "",
    agent: {},
  },
};
function TestAgentAchain({ agentIdTest, darkMode, activityChainID }) {
  const [ind, setIndex] = useState(1);
  const { accessToken, setAccessToken, setRefreshToken } =
    useContext(AuthContext);
  const { nodeIndex, setNodeIndex } = useContext(DockContext);
  let chains_data = JSON.parse(localStorage.getItem("chains"));

  let passdata = chains_data
    ? { ...chains_data, chain_payload: JSON.parse(chains_data.chain_payload) }
    : demoData;
  const [saveBtn, setSaveBtn] = useState(false);
  const [testAgentBtn, setTestAgentBtn] = useState(false);
  const [saveProceedBtn, setSaveProceedBtn] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [JSONData, setJSONData] = useState(passdata);
  console.log(activityChainID);
  const handleBackStep = () => {

    setNodeIndex(nodeIndex + 1);
    console.log(nodeIndex);
    navigate(`/agentcreation`);

    if (ind > 1) {
      setIndex(ind - 1);
    }
    

    setSaveBtn(false);
    setSaveProceedBtn(false);
    setTestAgentBtn(false);

    navigate(-1)

  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const [agentId, setAgentId] = useState(0);
  const [agentResponse, setAgentResponse] = useState();
  console.log(JSONData.id);
  const test_agent = () => {
    setIndex(ind + 1);
    console.log(agentId);
    setTestAgentBtn(true);
  };
  const createAgent = () => {
    console.log("agent cannot be created");
  };
  const [save, setSave] = useState(false);
  const do_proceed = () => {
    setIndex(ind + 1);
    setSaveBtn(true);
    setSaveProceedBtn(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log("hii");
  };

  const { id } = useParams();
  console.log(id);

  var { state } = useLocation();
  // state=18
  const [runInput, setRunInput] = useState("");
  const [agent, setAgent] = useState(null);
  const [agentHistory, setAgentHistory] = useState(null);

  const [show, setShow] = useState(false);

  const [ws, setWs] = useState(null);
  const [data, setData] = useState(null);
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };
  function getCookie(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }
  useEffect(() => {
    console.log("Inside use Effect");

    axios
      .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/${agentIdTest}/`, {
        headers,
      })
      .then((response) => {
        console.log(response.data);
        setAgent(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`,
              { refresh_token: getCookie("refreshToken") },
              { headers }
            )
            .then((refreshResponse) => {
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true;
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false;
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401)
                window.location.reload();
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false;
            });
      });

    axios
      .get(
        `${process.env.REACT_APP_AGENT_SERVER}/v1/agent/get_history/${agentIdTest}/`,
        { headers }
      )
      .then((response) => {
        console.log(response.data);
        setAgentHistory(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`,
              { refresh_token: getCookie("refreshToken") },
              { headers }
            )
            .then((refreshResponse) => {
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true;
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false;
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401)
                window.location.reload();
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false;
            });
      });
  }, []);

  const graph = (pkValue) => {
    const socket = new WebSocket(
      `ws://alchemist.eastus.cloudapp.azure.com:8000/ws/graph/`
    );
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connected");
      const message = {
        pk: pkValue,
        user_id: "123",
        date: "your_date_value",
        method_action: "start_sending_data",
      };
      socket.send(JSON.stringify(message));
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Received message from server:", message);
      setData(message);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  };

  const handleRun = () => {
    const headers = { "Content-Type": "application/json" };
    const payload = {
      goal: [runInput],
    };
    axios
      .post(
        `${process.env.REACT_APP_AGENT_SERVER}/v1/agent/run/${agentIdTest}/`,
        JSON.stringify(payload),
        { headers }
      )
      .then((response) => {
        console.log(response.data.id);
        graph(response.data.id);
        if (ws) {
          const message = {
            pk: response.data.id,
            user_id: "123",
            date: "your_date_value",
          };
          ws.send(JSON.stringify(message));
        }
        setShow(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`,
              { refresh_token: getCookie("refreshToken") },
              { headers }
            )
            .then((refreshResponse) => {
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true;
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false;
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401)
                window.location.reload();
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false;
            });
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setRunInput(value);
  };

  return (
    <>
      <Row
        style={{
          paddingTop: "10px",
          paddingBottom: "0",
          height: "40px",
        }}
      >
        <Col sm={6} className="d-flex">
          <span
            className="title-agent-details"
            style={{ padding: "12px" }}
          ></span>
        </Col>

        <Col
          sm={6}
          style={{ padding: 0, paddingRight: "2rem" }}
          className="text-right"
        >
          <button
            class="btn btn-primary cancelBtnDark me-3 cancel-new-agent"
            style={{ margin: 0 }}
            onClick={handleBackStep}
          >
            Back
          </button>

          {agentResponse && (
            <Link
              to="./knowledgework"
              class="btn btn-primary submitBtnDark me-3"
              role="button"
              onClick={test_agent}
              style={{ width: "118px", marginTop: "2px" }}
            >
              Test Activity
            </Link>
          )}
          {saveProceedBtn && (
            <a
              class="btn btn-primary submitBtnDark ms-3"
              href="#"
              role="button"
              onClick={createAgent}
              style={{ marginTop: "2px" }}
            >
              Select & Proceed
            </a>
          )}
          {!saveProceedBtn && (
            <button
              class=""
              href="#"
              role="button"
              className={
                save
                  ? "submitBtnDark btn btn-primary me-3"
                  : "submitBtnDark btn btn-primary me-3"
              }
              disabled={save}
              onClick={do_proceed}
              style={{ width: "118px", marginTop: "2px" }}
            >
              Save & Proceed
            </button>
          )}
        </Col>
      </Row>
      <Container
        fluid
        className="playground container-fluid "
        style={{ paddingLeft: "24px", paddingRight: "24px" }}
      >
        <Row className="container-fluid px-0 mx-0">
          <Col xs={3} className="col ps-0" style={{ paddingRight: "12px" }}>
            <Container
              fluid
              className="container-fluid p-4 agent-playground"
              style={{
                background: darkMode ? "#474F73" : "#f2f3f3",
                height: "34rem",
                border: "none",
              }}
            >
              <Row>
                <Col>
                  <h6
                    className="mb-0 my-auto"
                    style={{ color: darkMode ? "#BAC9F1" : "" }}
                  >
                    Run History
                  </h6>
                </Col>
                <Col>
                  <Button variant="primary" className="submitBtnDark">
                    New Run
                  </Button>
                </Col>
              </Row>
              {agentHistory &&
                agentHistory.map((rh) => {
                  return rh.status === "COMPLETED" ? (
                    <>
                      <Card
                        className="mt-3 run-history-col"
                        style={{
                          background: darkMode ? "#212543" : "#f2f3f3",
                          border: "none",
                        }}
                      >
                        <Card.Body>
                          <h6 style={{ color: darkMode ? "#BAC9F1" : "" }}>
                            {rh.name}
                          </h6>
                          <p style={{ color: darkMode ? "#fff" : "" }}>
                            {rh.status}
                          </p>
                          <Row>
                            <Col>{rh.num_of_calls} CALLS</Col>
                            {rh.time_difference.hours == 0 && (
                              <Col>{rh.time_difference.minutes} MINS AGO</Col>
                            )}
                            {rh.time_difference.hours > 0 && (
                              <Col>{rh.time_difference.hours} HRS AGO</Col>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </>
                  ) : (
                    <></>
                  );
                })}
            </Container>
          </Col>

          <Col xs={6} className="run-name col">
            <Container
              fluid
              className="container-fluid p-4 agent-playground"
              style={{
                background: darkMode ? "#474F73" : "#f2f3f3",
                border: "none",
              }}
            >
              <h6 className="mb-3" style={{ color: darkMode ? "#BAC9F1" : "" }}>
                Output
              </h6>
              {console.log(data, "I'm in data")}
              {data &&
                data.value.feeds.map((feed, index) => {
                  return (
                    <Card
                      className="p-3 mb-1 border-0"
                      style={{ background: darkMode ? "#212543" : "" }}
                    >
                      <Card.Body className="px-0 pb-0">
                        <div key={index} className={`feed feed-${feed.role}`}>
                          <pre style={{ color: darkMode ? "#BAC9F1" : "" }}>
                            {feed.feed}
                          </pre>
                          <p
                            className="run-name-time"
                            style={{ color: darkMode ? "#BAC9F1" : "" }}
                          >
                            {"updated_at" in feed
                              ? "Updated at" +
                                new Date(feed.updated_at).toLocaleString()
                              : " "}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })}
            </Container>
            <Row className="mt-2 justify-content-center align-items-center">
              <InputGroup className={`${darkMode ? "inputDark" : ""} mt-3 `}>
                <Form.Control
                  style={{
                    borderRadius: "4px",
                    marginRight: "20px",
                  }}
                  placeholder="Enter your prompt and press Enter"
                  value={runInput}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  className="submitBtnDark"
                  id="button-addon2"
                  onClick={handleRun}
                >
                  Run
                </Button>
              </InputGroup>
            </Row>
          </Col>

          <Col xs={3} className="details col">
            <Card
              className="px-3 py-4 rounded-top border-bottom-0"
              style={{
                background: darkMode ? "#474F73" : "#f2f3f3",
                border: "none",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <h6 className="mb-0" style={{ color: darkMode ? "#BAC9F1" : "" }}>
                Agent Details
              </h6>
            </Card>
            <Container
              fluid
              className="container-fluid p-4 agent-playground tableCard"
              style={{
                background: darkMode ? "#474F73" : "#f2f3f3",
                border: "none",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <Card
                className="p-3 mb-3 details-desc"
                style={{
                  background: darkMode ? "#212543" : "",
                  border: "none",
                }}
              >
                <Card.Body className="p-0">
                  <h6 style={{ color: darkMode ? "#BAC9F1" : "" }}>
                    {(agent && agent.goal.length) || 0}{" "}
                    {(agent && agent.goal.length) == 1 ? "GOAL" : "GOALS"}
                  </h6>
                  {agent &&
                    agent.goal.map((g) => {
                      return (
                        <p style={{ color: darkMode ? "#fff" : "" }}>{g}</p>
                      );
                    })}
                </Card.Body>
              </Card>
              <Card
                className="p-3 mb-3 details-desc"
                style={{
                  background: darkMode ? "#212543" : "",
                  border: "none",
                }}
              >
                <Card.Body className="p-0">
                  <h6 style={{ color: darkMode ? "#BAC9F1" : "" }}>
                    {(agent && agent.instruction.length) || 0} INSTRUCTIONS
                  </h6>
                  {agent &&
                    agent.instruction.map((i) => {
                      return (
                        <pre style={{ color: darkMode ? "#fff" : "" }}>{i}</pre>
                      );
                    })}
                </Card.Body>
              </Card>
              <Card
                className="p-3 mb-3 details-desc"
                style={{
                  background: darkMode ? "#212543" : "",
                  border: "none",
                }}
              >
                <Card.Body className="p-0">
                  <h6 style={{ color: darkMode ? "#BAC9F1" : "" }}>
                    {(agent && agent.constraints.length) || 0} CONSTRAINTS
                  </h6>
                  {agent &&
                    agent.constraints.map((c) => {
                      return (
                        <pre style={{ color: darkMode ? "#fff" : "" }}>{c}</pre>
                      );
                    })}
                </Card.Body>
              </Card>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TestAgentAchain;
