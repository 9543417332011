import React from "react";
import ModelsNav from "../../ModelOrchestrator/FineTunining/ModelsNav";
import ModelCompetancyNav from "../ModelCompetancy/ModelCompetancyNav";
import Playground2 from "../Playground2/Playground2";

const TestCompetency = () => {
    return(
        <>
            <div className='inheritBg'>
                <ModelsNav/>
                <ModelCompetancyNav index = {3} />
                <Playground2/>
            </div>
        </>
    )
}

export default TestCompetency;