import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  recommendedModelsData,
  newData,
  completedData,
  inProgressData,
} from "./Data/dummyData";
import { fetchChartData } from"./Data/chartData";
import MoHeader from "./MoHeader";
import "./TrainingModel.css";
import { Button, Card, Nav, Table } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import Papa from "papaparse";
import { AuthContext } from "../Auth/AuthContext";
import FineTuningTemplate from "../../assets/templates/Medical_Data-Template.csv";
import RewardTemplate from "../../assets/templates/Reward_Model_data_Template.csv";
import CustomTable from "../Table/CustomTable";
import { TableData, TableColumn } from "../ModelOrchestrator/FineTunining/DB";
import {  Bar, Line } from "react-chartjs-2";
import DottedBackground from '../../assets/img/Mask Group 52@2x.png'
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import CustomTableNew from "./Data/CustomTable";
import DataSynthesizerSeed from "./DataSynthesizerSeed";
import { height } from "@mui/system";
import PerplexityChart from "./Charts/PerplixityChart";
import RougeScoresChart from "./Charts/RougeScoreChart";
import BleuScoreChart from "./Charts/BleuScoreChart";
import BleuDetailsChart from "./Charts/BleuDetailsChart";


const Training = ({ darkMode, handleToggle }) => {
  const backgroundImageUrl = 'url("../../assets/img/Mask Group 52@2x.png")'; 
  const { accessToken } = useContext(AuthContext);
  const [TableDataLocal, setTableDataLocal] = useState(TableData);
 
  const [index, setindex] = useState(1);
  const [TableColumnLocal, setTableColumnLocal] = useState(TableColumn);
 

  let templateSelector = {
    "/finetuning": FineTuningTemplate,
    "/rewardModel": RewardTemplate,
    "/rewardppo": RewardTemplate,
  };
  let location = useLocation();
  let path = location.pathname;
  let { id } = useParams();
  const {knowledgeWorkDetails, selectedItems,knowledgeWorkName,hyperparameters } = location.state || {};
  const knowledgeWorkNameNew = selectedItems.knowledgeWorkName;

  // Provide default values if selectedItems is undefined or does not contain the expected data
  const selectedModelIds = selectedItems.recommendedModels || [];
  const selectedAgent = (selectedItems?.agents || [])[0] || {}; // Extract first agent
  const selectedAgentTitle = selectedAgent.title || "";

  // Ensure `agentsData` and `recommendedModelsData` are defined and have data
  
  let summaryData = newData.find((item) => item.id === Number(id));

  const [currentSection, setCurrentSection] = useState(0);
  const [currentStep, setCurrentStep] = useState([]);
  const [currentModelId, setCurrentModelId] = useState(1);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [fileMessage, setFileMessage] = useState('');
  const [syntheticDataId, setSyntheticDataId] = useState(0);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const ws = useRef(null);
  const endOfMessagesRef = useRef(null);
  const[modelPath, setModelPath] = useState("");
  const [hyperparameterTableData, setHyperparameterTableData] = useState(TableDataLocal[1])
  const [peftTableData, setPeftTableData] = useState(TableDataLocal[3])
  const [hyperparameterObjectData, setHyperparameterObjectData] = useState({})
  const [peftObjectData, setPeftObjectData] = useState({})
  const [responseText, setResponseText] = useState('');
  const messageInputRef = useRef(null);

  const [seedMessages, setSeedMessages] = useState({
    "0": [
     
    ],
  });
  const [seedCurrentStep, setSeedCurrentStep] = useState(0);

  const sendSeedMessage = (message) => {
    // Add message to state and update messages
    const newMessage = { sender: 'ai', text: message };
    setSeedMessages(prevMessages => {
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[seedCurrentStep]) {
        updatedMessages[seedCurrentStep] = [];
      }
      updatedMessages[seedCurrentStep].push(newMessage);
      return updatedMessages;
    });
  };

  const [selectedModels, setSelectedModels] = useState(recommendedModelsData.filter((item) =>
    selectedModelIds.includes(item.id))); 
  const navigate = useNavigate();

  const handlehyperparameterTableDataChange = (transformedData) => {
    console.log("Updated Hyperparameters Table Data:", transformedData);
    const result = transformedData.reduce((acc, item) => {
      const parameterName = item["Parameter Name"].value;
      const inputValue = item["Input"].value;

      // Add the parameter name and input value to the result object
      acc[parameterName] = inputValue;
      return acc;
    }, {});
    setHyperparameterTableData(transformedData);
    setHyperparameterObjectData(result)
    // Optionally, send updatedData to API here
    // sendToApi(updatedData);
  };
  const handlePeftTableDataChange = (transformedData) => {

    console.log("Updated PEFT Table Data:", transformedData);
    const result = transformedData.reduce((acc, item) => {
      const parameterName = item["Parameter Name"].value;
      const inputValue = item["Input"].value;

      // Add the parameter name and input value to the result object
      acc[parameterName] = inputValue;
      return acc;
    }, {});
    
    // Log the result to check
    setPeftObjectData(result)
  console.log("Formatted Result Object:", result);
    setModelPath(transformedData.output_dir)
    setPeftTableData(transformedData);
    // Optionally, send updatedData to API here
    // sendToApi(updatedData);
  };
  useEffect(() => {
    console.log("TableData has changed:", TableData);
  }, [TableData]);
  useEffect(() => {
    // Initialize WebSocket connection
    ws.current = new WebSocket('ws://multimodel.eastus.cloudapp.azure.com:8000/ws/chat');
  
    // Handle incoming messages
    ws.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.message) {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [currentStep]: [
              ...(prevMessages[currentStep] || []),
              { text: data.message, sender: 'ai' }
            ]
          }));
        } else {
          console.error('Unexpected message format:', data);
        }
      } catch (error) {
        console.error('Error parsing message data:', error);
        setMessages((prevMessages) => ({
          ...prevMessages,
          [currentStep]: [
            ...(prevMessages[currentStep] || []),
            { text: event.data, sender: 'ai' } // Fallback to plain text
          ]
        }));
      }
    };
  
    // Handle WebSocket errors
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [currentStep]); // Re-run when the currentStep changes
  
  useEffect(() => {
    // Scroll to the bottom whenever messages change
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const stepToTemplateId = {
    1: 1,
    2: 2,
    // Add more mappings if needed
  };
  const sendMessage = (message) => {
    if (typeof message === 'string' && message.trim()) {
      // Determine the template_id based on the current step
      const template_id = stepToTemplateId[currentStep] || 1; // Default to 1 if currentStep is not found
  
      // Create the payload including the message and template_id
      const payload = {
        message,
        template_id
      };
  
      // Send the payload to the WebSocket server
      ws.current.send(JSON.stringify(payload));
  
      // Update local messages state
      setMessages((prevMessages) => ({
        ...prevMessages,
        [currentStep]: [
          ...(prevMessages[currentStep] || []),
          { text: message, sender: 'user' }
        ]
      }));
  
      // Clear the input field if it's a user-typed message
      if (messageInputRef.current) {
        setMessage('');
      }
    } else {
      console.warn("Message is not a valid string:", message);
    }
  };
  
  
  const renderStep0Chat = () => {
    const step0Suggestions = [
      "What is the general purpose of this chat?",
      "How can I get started?",
      "What are the main topics covered?",
      "Can you provide a summary of the available features?"
    ];
    const handleSuggestionClick = (suggestion) => {
      sendMessage(suggestion);
    };
  
    return (
      <div className="d-flex position-relative" style={{ minHeight: '75vh' }}>
        {/* Chat Box */}
        <div className="flex-grow-1 d-flex flex-column">
          <div className="d-flex flex-column-reverse flex-grow-1 overflow-auto p-4">
            <div className="d-flex flex-column w-100">
              {/* Messages will go here */}
            </div>
          </div>
        </div>
  
        {/* Suggestions Container for Step 0 */}
        <div className="position-absolute bottom-0 end-0 bg-light rounded shadow" style={{ width: '300px', maxHeight: '50vh', overflowY: 'auto', padding: '10px' }}>
          <p className="text-dark">Suggestions:</p>
          {step0Suggestions.map((suggestion, index) => (
            <p
              key={index}
              className="p-1 rounded text-light"
              style={{ background: '#003BEB', cursor: 'pointer' }}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </p>
          ))}
        </div>
      </div>
    );
  };
  
  

  const [hasDataset, setHasDataset] = useState(false); // State to track if user has dataset
 const renderChatStep0 = (stepIndex) => {
  const stepMessages = messages[stepIndex] || [];
  const suggestions = {
    "0": [
      "I have Dataset.",
      "I don't have Dataset.",
    ],
  };

  const stepSuggestions = suggestions[stepIndex] || [];

  const handleSuggestionClick = (suggestion) => {
    if (suggestion === "I have Dataset.") {
      setHasDataset(true); // Update state when user clicks "I have Dataset."
       // Send message from AI
    }
  };

  const handleUpload = (event) => {
    // Handle the file upload logic here
    console.log("File uploaded:", event.target.files[0]);
  };

  return (
    <div className="d-flex position-relative" style={{ minHeight: '75vh' }}>
      {/* Chat Box */}
      <div className="flex-grow-1 d-flex flex-column">
        <div className="d-flex flex-column-reverse flex-grow-1 overflow-auto p-4">
          <div className="d-flex flex-column w-100">
            {stepMessages.map((msg, index) => (
              <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                  <p className="m-0 text-dark">{msg.text}</p>
                </div>
              </div>
            ))}
            {/* Scroll to the bottom */}
            {/* <div ref={endOfMessagesRef} /> */}
          </div>
        </div>
      </div>

      {/* Suggestions Container */}
      {stepMessages.length === 0 && !hasDataset && (
        <div className="position-absolute bottom-0 end-0 bg-light-100 rounded shadow" style={{ width: '300px', maxHeight: '50vh', overflowY: 'auto', padding: '10px' ,opacity:'100',background:'white'}}>
          <p className="text-dark">Synthesize Data:</p>
          {stepSuggestions.map((suggestion, index) => (
            <p
              key={index}
              className="p-1 rounded text-light"
              style={{ background: '#003BEB', cursor: 'pointer' }}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </p>
          ))}
        </div>
      )}

      {/* Upload Button */}
      {hasDataset && (
        <div className="position-absolute bottom-0 end-0 bg-light rounded shadow" style={{ width: '300px', padding: '10px' }}>
          <p className="text-dark">Please upload your dataset:</p>
          <input type="file" onChange={handleUpload} />
        </div>
      )}
    </div>
  );
};
  const renderChat = (stepIndex) => {
    const stepMessages = messages[stepIndex] || [];
    const suggestions = {
      "1": [
        "What are hyperparameters?",
        "How do hyperparameters affect model performance?",
        "Can you explain different types of hyperparameters?",
        "What are some common hyperparameters used in machine learning?"
      ],
      "2": [
        "What is PEFT?",
        "How does PEFT affect hyperparameters?",
        "Can you give examples of PEFT hyperparameters?",
        "What are some tips for tuning PEFT hyperparameters?"
      ]
    };
  
    const stepSuggestions = suggestions[stepIndex] || [];
  
    const handleSuggestionClick = (suggestion) => {
      sendMessage(suggestion);
    };
  
    return (
      <div className="d-flex position-relative" style={{ minHeight: '75vh' }}>
        {/* Chat Box */}
        <div className="flex-grow-1 d-flex flex-column">
          <div className="d-flex flex-column-reverse flex-grow-1 overflow-auto p-4">
            <div className="d-flex flex-column w-100">
              {stepMessages.map((msg, index) => (
                <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                  <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                    <p className="m-0 text-dark">{msg.text}</p>
                  </div>
                </div>
              ))}
              {/* Scroll to the bottom */}
              {/* <div ref={endOfMessagesRef} /> */}
            </div>
          </div>
        </div>
  
        {/* Suggestions Container */}
        {stepMessages.length === 0 && (
          <div className="position-absolute bottom-0 end-0 bg-light rounded shadow" style={{ width: '300px', maxHeight: '50vh', overflowY: 'auto', padding: '10px' }}>
            <p className="text-dark">Suggestions:</p>
            {stepSuggestions.map((suggestion, index) => (
              <p
                key={index}
                className="p-1 rounded text-light "
                style={{background:'#003BEB', cursor:'pointer'}}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  

  
  
  const handleSyntheticFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const userId = 1; // Replace with the actual user ID
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(`http://localhost:8003/api/syntheticdata/available/upload_data?user_id=${userId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Success:', response.data);
        setSyntheticDataId(response.data["Data ID"])
        // Handle success, e.g., show a notification or update state
        
      } catch (error) {
        console.error('Error:', error);
        // Handle error, e.g., show an error message
      }
    }
  };

  const sendSyntheticMessage = () => {
    // Function to send a message
  };

  const handleSyntheticUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }
    const userId = 'yourUserId'; // Replace with actual user ID
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', userId);

    try {
      const response = await axios.post('http://localhost:8003/api/syntheticdata/available/upload_data', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Success:', response.data);
      // Handle success
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  
  }
  useEffect(() => {
    if (currentStep.length === 0) {
      setCurrentStep(new Array(selectedModelIds.length).fill(0));
    }
  }, [selectedModelIds]);


  const handleNextStep = (modelId) => {
    console.log("handleNextStep triggered with modelId:", modelId);
  
    setCurrentStep((prevSteps) => {
      console.log("prev steps: ", prevSteps);
      const newSteps = [...prevSteps];

       
      const currentSectionSteps = sections[currentSection].steps.length;
  
      const getStepName = (stepIndex) => {
        return sections[currentSection].steps[stepIndex] || "Unknown Step";
      };
      // Determine the data to send based on the current step
      const getDataForStep = (step) => {
        const stepName = getStepName(step);
        console.log("Getting data for step:", step);
        switch (step) {
          case 0: // Step 2 (index 1)
            return {
              knowledgeWorkDetails: {
                stepName,
                ...knowledgeWorkDetails,
                hyperparameters: {
  
                  ...hyperparameterTableData
                },
              },
            };
          case 1: // Step 3 (index 2)
            return {
              knowledgeWorkDetails: {
                stepName,
                ...knowledgeWorkDetails,
                peft: {
  
                  ...peftTableData
                },
              },
            };
          case 2: // Step 4 (index 3)
            return {
              knowledgeWorkDetails: {
                ...knowledgeWorkDetails,
                additionalInfo: 'Data specific to step 4',
              },
              hyperparameters: { /* Data specific to step 4 */ }
            };
          case 3: // Step 5 (index 4)
            return {
              knowledgeWorkDetails: {
                ...knowledgeWorkDetails,
                additionalInfo: 'Data specific to step 5',
              },
              hyperparameters: { /* Data specific to step 5 */ }
            };
          default:
            return {
              knowledgeWorkDetails,
              hyperparameters
            };
        }
      };
  
      // Function to make the API call
      const updateJson = async (data) => {
        console.log("Making API call with data:", data);
        try {
          const response = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log("API call successful:", response.data);
        } catch (error) {
          console.error("Error making API call:", error);
        }
      };
  
      // Check the current step and handle API calls
      if (newSteps[currentSection] === 0) { // User is in Step 2
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      } else if (newSteps[currentSection] === 1) { // User is in Step 3
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      } else if (newSteps[currentSection] === 2) { // User is in Step 4
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      } else if (newSteps[currentSection] === 3) { // User is in Step 5
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      }
  
      // Proceed with navigation and state updates
      if (newSteps[currentSection] === currentSectionSteps - 1) {
        setCurrentModelId(modelId);
        localStorage.setItem('modelID', modelId);
  
        const stateData = {
          knowledgeWorkDetails,
          selectedAgentTitle,
          selectedModelIds,
          selectedItems,
          knowledgeWorkName,
          hyperparameterObjectData,
          responseText,
          sftModelUrl
        };
  
        navigate(`/mo/summary/training/select-reward-ppo/${id}`, { state: stateData });
      } else if (newSteps[currentSection] < currentSectionSteps - 1) {
        // Move to the next step within the current section
        newSteps[currentSection] += 1;
      } else if (currentSection < sections.length - 1) {
        // Move to the next section if we are at the end of the current section
        setCurrentSection(currentSection + 1);
      }
  
      return newSteps;
    });
  };


  const handlePrevStep = () => {
    setCurrentStep((prevSteps) => {
      const newSteps = [...prevSteps];
      
      // Close the existing WebSocket connection
      // if (ws.current) {
      //   ws.current.close();
      //   setMessages([])
      // }
      
      // // Reinitialize the WebSocket connection
      // ws.current = new WebSocket('ws://localhost:8000/ws/chat');
      
      // // Handle incoming messages
      // ws.current.onmessage = (event) => {
      //   try {
      //     const data = JSON.parse(event.data);
      //     if (data.message) {
      //       setMessages((prevMessages) => [
      //         ...prevMessages,
      //         { text: data.message, sender: 'ai' }
      //       ]);
      //     } else {
      //       console.error('Unexpected message format:', data);
      //     }
      //   } catch (error) {
      //     console.error('Error parsing message data:', error);
      //     setMessages((prevMessages) => [
      //       ...prevMessages,
      //       { text: event.data, sender: 'ai' }
      //     ]);
      //   }
      // };
      
      // // Handle WebSocket errors
      // ws.current.onerror = (error) => {
      //   console.error('WebSocket error:', error);
      // };
  
      const currentStepIndex = newSteps[currentSection];
      const currentSectionSteps = sections[currentSection].steps.length;
  
      // Determine the step name based on the step index
      const getStepName = (stepIndex) => {
        return sections[currentSection].steps[stepIndex] || "Unknown Step";
      };
  
      // Determine the data to send based on the current step
      const getDataForStep = (stepIndex) => {
        const stepName = getStepName(stepIndex);
        console.log("Getting data for step:", stepIndex);
        switch (stepIndex) {
          case 2: // "Upload Data"
          return {
            knowledgeWorkDetails: {
              stepName,
              ...knowledgeWorkDetails,
              hyperparameters: {

                ...hyperparameterTableData
              },
              template_id:1
            },
          };
          case 3: // "Hyperparameters"
          return {
            knowledgeWorkDetails: {
              stepName,
              ...knowledgeWorkDetails,
              hyperparameters: {

                ...peftTableData
              },
            },
          };
          case 4: // "Peft Parameters"
            return {
              stepName,
              knowledgeWorkDetails: {
                ...knowledgeWorkDetails,
                additionalInfo: 'Data specific to step 4',
              },
              hyperparameters: { /* Data specific to step 4 */ }
            };
          case 5: // "Evaluate SFT"
            return {
              stepName,
              knowledgeWorkDetails: {
                ...knowledgeWorkDetails,
                additionalInfo: 'Data specific to step 5',
              },
              hyperparameters: { /* Data specific to step 5 */ }
            };
          default:
            return {
              stepName: "Unknown Step",
              knowledgeWorkDetails,
              hyperparameters
            };
        }
      };
  
      // Function to make the API call
      const updateJson = async (data) => {
        console.log("Making API call with data:", data);
        try {
          const response = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log("API call successful:", response.data);
        } catch (error) {
          console.error("Error making API call:", error);
        }
      };
  
      // Check the current step and handle API calls
      if (currentStepIndex >= 0) {
        const stepData = getDataForStep(currentStepIndex);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      }
  
      // Update the steps based on the current position
      if (currentStepIndex > 0) {
        newSteps[currentSection] -= 1; // Move to the previous step within the current section
      } else if (currentSection > 0) {
        // Move to the previous section if at the beginning of the current section
        newSteps[currentSection] = sections[currentSection - 1].steps.length - 1; // Set to last step of previous section
        setCurrentSection(currentSection - 1);
      }
  
      return newSteps;
    });
  };
  

  const sections = [
    {
      name: "Supervised Fine-tuning",
      steps: [
        "Upload Data",
        "SFT Hyperparameters",
        "Peft Parameters",
        "Evaluate SFT",
        "Inference",
        "Evaluate Model Generative Quality",
      ],
    },
    
  ];
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]); // State to store CSV data
  const [completed, setCompleted] = useState(false);
  const [logs,setLogs] = useState("");
  const [isFinetuningStarted, setIsFinetuningStarted] = useState(false)
  const [isTrainingStarted, setIsTrainingStarted] = useState(false)
  const [isTrainingCompleted, setIsTrainingCompleted] = useState(false)
  const [learningRateChartData, setLearningRateChartData] = useState();
  const [logErrors, setLogErrors] = useState("")
  const [metricsError, setMetricsError] = useState(true)
  const [context, setContext] = useState('');
  const [question, setQuestion] = useState('');
  const [lossChartData, setLossChartData] = useState();
  const[isInferanceDone, setIsInferanceDone] = useState(false)
  const [Infloading, setInfLoading] = useState(false);
  const [sftModelUrl, setSftModelUrl] = useState("");

  const [progress, setProgress] = useState(0);
  const [displayedData, setDisplayedData] = useState(newData);

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  // Handle changes in the question textarea
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file.name);
  };

  const handleUpload = () => {
    if (selectedFile) {
        setLoading(true);

        // Parse CSV file
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                console.log("Parsed CSV data: ", results.data);
                setCsvData(results.data.slice(0, 5)); // Update state with CSV data
            },
            error: (error) => {
                console.error("Error parsing CSV: ", error);
            },
            skipEmptyLines: true,
        });

        // Prepare FormData
        const formData = new FormData();
        formData.append("file", selectedFile);

        // API URL with filename and description
        const apiUrl = `http://alchemist.eastus.cloudapp.azure.com:3005/model/dataset?filename=${encodeURIComponent(selectedFile.name)}&description=${encodeURIComponent('File upload description here')}&data_type='sft'`;

        axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                console.log("File uploaded successfully:", response.data);
                localStorage.setItem("dataset_path", response.data.dataset_path);
                localStorage.setItem("dataset_id", response.data.dataset_id);
                setCompleted(true);
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        console.log("Please select a file to upload.");
    }
  }
  const [tableData, setTableData] = useState([
    // Initialize with default data or empty objects
  ]);
  const [updateDataJson, setUpdateDataJson] = useState({});
  const updateData = (dataIndex, column, type, value, key) => {
    console.log("Update function called with parameters:", { dataIndex, column, type, value, key });
    console.log("Current TableDataLocal:", TableDataLocal);
    console.log("Current updateDataJson:", updateDataJson);
  
    // Ensure dataIndex is within bounds
    if (dataIndex >= 0 && dataIndex < TableDataLocal.length) {
      const row = TableDataLocal[dataIndex];
  
      console.log("Row data at index:", row);
  
      // Ensure row is an array and not empty
      if (Array.isArray(row) && row.length > 0) {
        // Find the correct cell in the row
        const cellIndex = row.findIndex(cell => cell.hasOwnProperty(column));
  
        if (cellIndex !== -1) {
          const cell = row[cellIndex];
          // Make a copy of the table data and update
          const updatedTableData = [...TableDataLocal];
          const updatedRow = [...row];
  
          updatedRow[cellIndex] = {
            ...cell,
            [column]: { ...cell[column], value }
          };
  
          updatedTableData[dataIndex] = updatedRow;
  
          // Update state
          setTableDataLocal(updatedTableData);
          
          // Update JSON data
          const updatedJson = { ...updateDataJson };
          updatedJson[key] = value;
          setUpdateDataJson(updatedJson);
  
          console.log("Updated TableDataLocal:", updatedTableData);
          console.log("Updated updateDataJson:", updatedJson);
        } else {
          console.error("Column not found in row:", { column, row });
        }
      } else {
        console.error("Invalid row data:", row);
      }
    } else {
      console.error("Invalid dataIndex in TableDataLocal:", dataIndex);
    }
  };
  
 
  const [lastId, setLastId] = useState(17)
  const defaultHyperparameters = {
    model_name_or_path: "meta-llama/Meta-Llama-3-8B-Instruct",
    model_type: knowledgeWorkDetails.selectedActivity.intelligence,
    task: "",
    domain: " ",
    max_seq_length: 512,
    dtype: "torch.bfloat16",
    output_dir: hyperparameterObjectData.output_dir,
    do_train: true,
    do_eval: true,
    report_to: hyperparameterObjectData.report_to,
    learning_rate: hyperparameterObjectData.learning_rate,
    lr_scheduler_type: hyperparameterObjectData.lr_scheduler_type,
    num_train_epochs: parseInt(hyperparameterObjectData.num_train_epochs),
    per_device_train_batch_size: parseInt( hyperparameterObjectData.per_device_train_batch_size),
    per_device_eval_batch_size: parseInt( hyperparameterObjectData.per_device_eval_batch_size),
    gradient_accumulation_steps: parseInt( hyperparameterObjectData.gradient_accumulation_steps),
    optim: hyperparameterObjectData.optim,
    logging_steps: parseInt(hyperparameterObjectData.logging_steps),
    save_strategy: "steps",
    evaluation_strategy: hyperparameterObjectData.evaluation_strategy,
    max_grad_norm: hyperparameterObjectData.max_grad_norm,
    warmup_ratio: 0.03,
    bf16: hyperparameterObjectData.bf16,
    tf32: hyperparameterObjectData.tf32,
    torch_dtype: "bfloat16",
    fp16: false,
    gradient_checkpointing: hyperparameterObjectData.gradient_checkpointing,
    seed: 1,
    logging_dir: hyperparameterObjectData.logging_dir,
    load_in_4bit: false,
    load_in_8bit: false,
    // bnb_4bit_compute_dtype: "torch.bfloat16",
    bnb_4bit_quant_type: "nf4",
    use_bnb_nested_quant: true,
    // bnb_4bit_quant_storage: "torch.bfloat16",
    use_peft: true,
    lora_r: parseInt(peftObjectData.lora_r, 10),
    lora_alpha: parseInt(peftObjectData.lora_alpha),
    lora_dropout: parseFloat(peftObjectData.lora_dropout),
    lora_target_modules: ["q_proj", "k_proj", "v_proj", "o_proj", "gate_proj", "up_proj", "down_proj"],
    bias: 'none',
    task_type: peftObjectData.task_type,
    // inference_mode: peftObjectData.inference_mode,
    dataset_path: localStorage.getItem('dataset_path'),
    dataset_text_field: "text",
    dataset_num_proc: 2,
    // packing: false,
    // args: "None",
    // data_collator: "None",
    attn_implementation: null,
    max_steps:10,
    eval_steps:parseInt(hyperparameterObjectData.eval_steps,2),
    save_steps:2,
    save_total_limit:2,
    warmup_steps:2,
    preprocessing_remove_columns:['Question', 'context', 'response'],
    trainer_accelerator_state:"None",
    preprocessing_data_truncation:true,
    attn_implementation:"flash_attention_2"
 
 
 
    // num_epochs:4

};


    const createHyperparameterConfig = (hyperparameters) => {
        const config = defaultHyperparameters;

        // Update config based on tableData, if needed
        // Assuming tableData is available in scope
        const tableData = []; // Replace with actual data if available

        tableData.forEach(row => {
            const paramName = row["Parameter Name"]?.value;
            const inputData = row["Input"];

            if (paramName && inputData) {
                switch (inputData.type) {
                    case "number":
                        const numberValue = parseFloat(inputData.value);
                        config[paramName] = isNaN(numberValue) ? 0 : numberValue;
                        break;
                    case "text":
                    case "select":
                    case "radio":
                        config[paramName] = inputData.value || "string";
                        break;
                    case "boolean":
                        config[paramName] = inputData.value === "True";
                        break;
                    default:
                        break;
                }
            }
        });

        // Ensure fields that should be integers are integers
        const integerFields = [
            "logging_steps", "max_steps", "eval_steps", "save_steps", "save_total_limit",
            "per_device_train_batch_size", "gradient_accumulation_steps", "warmup_steps"
        ];

        integerFields.forEach(field => {
            if (config[field] === "None" || config[field] === undefined) {
                config[field] = 2;
            } else {
                config[field] = parseInt(config[field], 10);
            }
        });

        return {
          
            hyperparameter_config: config
        };
    };

    const handleStartFineTuning = async (hyperparameters = {}) => {
     
      try {
          // Create hyperparameter configuration
          const hyperparameterConfig = createHyperparameterConfig(hyperparameters);
  
          // Prepare the request payload
          const requestBody = {
              "service_name": "SFT",
              param_json: defaultHyperparameters,
              dataset_location: localStorage.getItem('dataset_path')
          };
  
          // Log to verify the request body
          console.log('Request Body:', requestBody);
  
          // Send the initial request
          const response = await axios.post('http://finetune.eastus.cloudapp.azure.com:8002/request_training/', requestBody, {
              headers: {
                  'Content-Type': 'application/json'
              }
          },
          setIsFinetuningStarted(true)
        );
         
  
          // Log response for debugging
          console.log('API Response:', response.data);
          localStorage.setItem('request_id', response.data["request_id"]);
          setIsFinetuningStarted(true);
  
          // Polling function to get container logs
          let trainingCompletedTime = null;
  
          const pollLogs = async () => {
            try {
                const requestBody = {
                    service_name: "SFT",
                    request_id: localStorage.getItem('request_id')
                };
        
                // Make API request
                const logResponse = await axios.post('http://finetune.eastus.cloudapp.azure.com:8002/container-logs/', requestBody, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
        
                const logs = logResponse.data.logs;
                const metrics = logResponse.data.metrics;
                const percentage = logResponse.data.percentage;
                const model_url = logResponse.data.model_url;
                setSftModelUrl(model_url)
        
                // Update state with the logs and progress
                setLogs(logs);
                setProgress(percentage);
                setIsTrainingStarted(true);
        
                const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); // Replace invalid characters for filename
                const filename = `SFT_logs_${timestamp}.log`;
                const blob = new Blob([logs], { type: 'text/plain' });
                const url = URL.createObjectURL(blob);
        
                if (model_url != "") {
                    if (!trainingCompletedTime) {
                        console.log('Training complete');
        
                        // Store the time when training completed
                        trainingCompletedTime = Date.now();
                        console.log('Training completed at:', trainingCompletedTime);
        
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
        
                        if (metrics && metrics.length > 0) {
                            const steps = metrics.map(log => log.step);
                            const learningRates = metrics.map(log => log.learning_rate);
                            const losses = metrics.map(log => log.loss);
        
                            // Prepare data for charts
                            const learningRateChartData = {
                                labels: steps,
                                datasets: [
                                    {
                                        label: 'Learning Rate',
                                        data: learningRates,
                                        borderColor: 'rgba(75,192,192,1)',
                                        backgroundColor: 'rgba(75,192,192,0.2)',
                                        fill: false
                                    }
                                ]
                            };
        
                            const lossChartData = {
                                labels: steps,
                                datasets: [
                                    {
                                        label: 'Loss',
                                        data: losses,
                                        borderColor: 'rgba(255,99,132,1)',
                                        backgroundColor: 'rgba(255,99,132,0.2)',
                                        fill: false
                                    }
                                ]
                            };
        
                            setLearningRateChartData(learningRateChartData);
                            setLossChartData(lossChartData);
                            setMetricsError(false); // Clear any previous errors
                        } else {
                            // If metrics are null or empty
                            setLearningRateChartData(null);
                            setLossChartData(null);
                            setMetricsError(true);
                        }
                    }
        
                    // Continue polling every 2 seconds for 10 seconds after completion
                    const now = Date.now();
                    const elapsedTime = now - trainingCompletedTime;
                    console.log('Elapsed Time:', elapsedTime);
        
                    if (elapsedTime <= 10000) { // 10 seconds
                        console.log('Continuing polling...');
                        setTimeout(pollLogs, 2000);
                        if (metrics && metrics.length > 0) {
                            const steps = metrics.map(log => log.step);
                            const learningRates = metrics.map(log => log.learning_rate);
                            const losses = metrics.map(log => log.loss);
        
                            // Prepare data for charts
                            const learningRateChartData = {
                                labels: steps,
                                datasets: [
                                    {
                                        label: 'Learning Rate',
                                        data: learningRates,
                                        borderColor: 'rgba(75,192,192,1)',
                                        backgroundColor: 'rgba(75,192,192,0.2)',
                                        fill: false
                                    }
                                ]
                            };
        
                            const lossChartData = {
                                labels: steps,
                                datasets: [
                                    {
                                        label: 'Loss',
                                        data: losses,
                                        borderColor: 'rgba(255,99,132,1)',
                                        backgroundColor: 'rgba(255,99,132,0.2)',
                                        fill: false
                                    }
                                ]
                            };
        
                            setLearningRateChartData(learningRateChartData);
                            setLossChartData(lossChartData);
                            setMetricsError(false); // Clear any previous errors
                            setIsTrainingCompleted(true);
                            setIsTrainingStarted(false)
                        } else {
                            // If metrics are null or empty
                            setLearningRateChartData(null);
                            setLossChartData(null);
                            setMetricsError(true);
                        }
        
                    } else {
                        console.log('Stopping polling after 10 seconds');
                        setIsFinetuningStarted(false);
                        setIsTrainingStarted(false);
                    }
        
                } else {
                    // Continue polling every 2 seconds
                    setTimeout(pollLogs, 2000);
                }
        
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    // Handle 500 error by retrying
                    console.error('Server Error 500, retrying...', error.message);
                    setTimeout(pollLogs, 2000); // Retry after 2 seconds
                } else {
                    // Handle other errors
                    console.error('Polling Error:', error.response ? error.response.data : error.message);
                    setIsTrainingStarted(false); // Stop any ongoing process
                    setIsFinetuningStarted(false);
                }
            }
        };
        
  
          // Start polling
          pollLogs();
        
      } catch (error) {
          // Handle errors from the initial request
          console.error('API Error:', error.response ? error.response.data : error.message);
      }
  };
  
  
  
  
  
  
  
  const handleInference = async () => {
    setInfLoading(true)
    try {
      const requestBody = {
      
       
            context: context,
            question: question,
       
        model_id: "meta-llama/Meta-Llama-3-8B-Instruct",
        model_url: sftModelUrl
      };

      // Make API request
      const logResponse = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8004/sft-inf/predict', requestBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      // Set the response and update the state
      setResponseText(logResponse.data.generated_text); // Adjust based on the actual response structure
      setIsInferanceDone(true);

    } catch (error) {
      console.error('Inference Error:', error.response ? error.response.data : error.message);
    }finally {
      setInfLoading(false); // End loading
    }
  }
  const [evalLoading, setEvalLoading] = useState(false)
  const [originalText, setOriginalText] = useState("")
  const [rouge1Sum, setRouge1Sum] = useState("")
  const [rouge2Sum, setRouge2Sum] = useState("")
  const [rougeLSum, setRougeLSum] = useState("")
  const [rougeLSumSum, setRougeLSumSum] = useState("")
  const [perplexitySum, setPerplexitySum] = useState("")
  const [bleuSum, setBleuSum] = useState("")
  const [PerplexityText,setPerplexityText] = useState("")
  const [blueText,setBlueText] = useState("")
  const [rouge1Text,setRouge1Text] = useState("")
  const [rouge2Text,setRouge2Text] = useState("")
  const [rougeLText,setRougeLText] = useState("")
  const [rougeLSumText,setRougeLSumText] = useState("")
  const [originalPerplexity, setOriginalPerplexity] = useState(null);
  const [generatedPerplexity, setGeneratedPerplexity] = useState(null);
  const [bleuData, setBleuData] = useState(null);
  const [rougeScores, setRougeScores] = useState({
    rouge1: [],
    rouge2: [],
    rougeL: [],
    rougeLsum: [],
  });

  const handleOriginalTextChange = (event) => {
    setOriginalText(event.target.value);
  };
  const handleSendEvaluate = async () => {
    setEvalLoading(true);
    try {
      const requestBody = {
        original_sentence: originalText,
        generated_sentence:responseText,
        // generated_sentence: "CUSTOMER OVERVIEW: Cisco Systems, Inc. is an American multinational technology conglomerate headquartered in San Jose, California, in the center of Silicon Valley. Cisco develops, manufactures and sells networking hardware, software, telecommunications equipment and other high-technology services and products. Through its numerous acquired subsidiaries, such as OpenDNS, WebEx, Jabber and Jasper, Cisco specializes into specific tech markets, such as Internet of Things (IoT), domain security and energy management. SOLUTION DELIVERED: The solution delivered by TCS was a predictive analytics model that used machine learning algorithms to analyze employee data and predict which employees were most likely to leave the organization within the next six months. The model also provided insights into the factors that contributed to employee attrition, such as job satisfaction, work-life balance, and compensation. BUSINESS CHALLENGE: The business challenge faced by Cisco was high employee attrition rates, which resulted in increased recruitment and training costs. The company needed a solution that could help them identify high-risk employees and take proactive steps to retain them. VALUE CREATED: The predictive analytics model developed by TCS helped Cisco identify high-risk employees and take proactive steps to retain them. The model also provided insights into the factors that contributed to employee attrition, allowing the company to address these issues and improve employee satisfaction. As a result, Cisco was able to reduce its employee attrition rates and save on recruitment and training costs.",
        model_id: "meta-llama/Meta-Llama-3-8B-Instruct",
        model_url: sftModelUrl
      };
  
      // Make API request
      const logResponse = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8005/8b', requestBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      // Set the response and update the state
      setEvalLoading(false); 
  
      // Correctly accessing data from the response
      const data = logResponse.data;
  
      // Setting Scores
      setRouge1Sum(data.Scores['ROUGE-1']);
      setRouge2Sum(data.Scores['ROUGE-2']);
      setRougeLSum(data.Scores['ROUGE-L']);
      setRougeLSumSum(data.Scores['ROUGE-Lsum']);
      setPerplexitySum(data.Scores['Perplexity']);
      setBleuSum(data.Scores['BLEU']);
  
      // Setting Perplexity Details
      setOriginalPerplexity(data['Perplexity Details']['Original Sentence Perplexity']);
      setGeneratedPerplexity(data['Perplexity Details']['Generated Sentence Perplexity']);
  
      // Setting Quality Explanations
      setPerplexityText(data['Quality Explanations']['Perplexity']);
      setBlueText(data['Quality Explanations']['BLEU']);
      setRouge1Text(data['Quality Explanations']['ROUGE-1']);
      setRouge2Text(data['Quality Explanations']['ROUGE-2']);
      setRougeLText(data['Quality Explanations']['ROUGE-L']);
      setRougeLSumText(data['Quality Explanations']['ROUGE-Lsum']);
  
      // Setting ROUGE Scores
      const rougeScoresData = data["ROUGE Scores"];
      console.log(rougeScoresData);
      
      setRougeScores({
        rouge1: rougeScoresData.rouge1,
        rouge2: rougeScoresData.rouge2,
        rougeL: rougeScoresData.rougeL,
        rougeLsum: rougeScoresData.rougeLsum,
      });
  
      // Setting BLEU Details
      const bleuDetails = data["BLEU Details"];
      setBleuData(bleuDetails);
  
    } catch (error) {
      console.error('Evaluation Error:', error.response ? error.response.data : error.message);
    } finally {
      setEvalLoading(false); // End loading
    }
  }
  
    // Define a click handler function
    const handleClick = () => {
        const paramsToUse = hyperparameters && Object.keys(hyperparameters).length > 0
            ? hyperparameters
            : defaultHyperparameters;

        handleStartFineTuning(paramsToUse);
    };
// Function to create hyperparameter configuration


  
const renderContent = () => {
  switch (activeTabIndex) {
    case 0:
      return(
        
        <div className="container-fluid">
          
          <p className="text-light mb-2" style={{borderBottom: '1px solid #2F499E'}}>Results</p>

          <div className="d-flex p-2 justify-cotnent-between align-items-cetner" style={{background: '#34385a', border:'1px solid #2F499E',borderRadius: '4px 4px 4px 4px'}}>
            <div className="d-flex p-2 flex-column" >
              <p className=" fs-6" style={{color:'#e5eaf1'}}>
                Perplexity
              <p className=" fs-3" style={{color:'#e5eaf1'}}>{perplexitySum}</p>
              </p>
            </div>
            <p className=" ml-2" style={{color:'#BAC9F1'}}>{PerplexityText}</p>
          </div>

          <div className=" mt-2 p-2 d-flex flex-column">
              
              <div className="">
                {/* <div className="d-flex align-items-center border-bottom">
                
                </div> */}
                <div className="p-2"  style={{border:'1px solid #2F499E'}}>
                {originalPerplexity !== null && generatedPerplexity !== null ? (
        <PerplexityChart
          originalPerplexity={originalPerplexity}
          generatedPerplexity={generatedPerplexity}
        />
      ) : (
        <p>Loading...</p>
      )}
                </div>
              </div>
              
            </div>
        </div>

      )
    case 1:
      return(
        <div className="container-fluid">
          
        <p className="text-light mb-2" style={{borderBottom: '1px solid #2F499E'}}>Results</p>

        <div className="d-flex p-2 justify-cotnent-between align-items-cetner rounded mt-2" style={{background: '#34385a', border:'1px solid #2F499E'}}>
          <div className="d-flex p-2 flex-column" >
            <p className=" fs-6" style={{color:'#e5eaf1'}}>
              Rouge 1
            <p className=" fs-3" style={{color:'#e5eaf1'}}>{rouge1Sum}</p>
            </p>
          </div>
          <p className=" ml-2" style={{color:'#BAC9F1'}}>{rouge1Text}</p>
        </div>
        <div className="d-flex p-2 justify-cotnent-between align-items-cetner rounded mt-2" style={{background: '#34385a', border:'1px solid #2F499E'}}>
          <div className="d-flex p-2 flex-column" >
            <p className=" fs-6" style={{color:'#e5eaf1'}}>
              Rouge 2
            <p className=" fs-3" style={{color:'#e5eaf1'}}>{rouge2Sum}</p>
            </p>
          </div>
          <p className=" ml-2" style={{color:'#BAC9F1'}}>{rouge2Text}</p>
        </div>
        <div className="d-flex p-2 justify-cotnent-between align-items-cetner rounded mt-2" style={{background: '#34385a', border:'1px solid #2F499E'}}>
          <div className="d-flex p-2 flex-column" >
            <p className=" fs-6" style={{color:'#e5eaf1'}}>
              Rouge L
            <p className=" fs-3" style={{color:'#e5eaf1'}}>{rougeLSum}</p>
            </p>
          </div>
          <p className=" ml-2" style={{color:'#BAC9F1'}}>{rougeLText}</p>
        </div>
        <div className="d-flex p-2 justify-cotnent-between align-items-cetner rounded mt-2" style={{background: '#34385a', border:'1px solid #2F499E'}}>
          <div className="d-flex p-2 flex-column" >
            <p className=" fs-6" style={{color:'#e5eaf1'}}>
              Rouge L Sum
            <p className=" fs-3" style={{color:'#e5eaf1'}}>{rougeLSumSum}</p>
            </p>
          </div>
          <p className=" ml-2" style={{color:'#BAC9F1'}}>{rougeLSumText}</p>
        </div>

        <div className=" mt-2 p-2 d-flex flex-column">
            <div className="p-2 d-flex align-items-center" style={{background: '#34385a', border:'1px solid #2F499E', borderRadius:'5px 5px 0px 0px'}}>
    

              <div class="form-check mr-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  Precision
                </label>
              </div>

              <div class="form-check mr-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  Recall Rate
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  F-1
                </label>
              </div>
            </div>
            <div className="">
              {/* <div className="d-flex align-items-center border-bottom">
              
              </div> */}
              <div className="p-2"  style={{border:'1px solid #2F499E'}}>
              {rougeScores.rouge1.length > 0 && rougeScores.rouge2.length > 0 && rougeScores.rougeL.length > 0 && rougeScores.rougeLsum.length > 0 ? (
        <RougeScoresChart rougeScores={rougeScores} />
      ) : ( 
        <p>Loading...</p>
      )}
              </div>
            </div>
            
          </div>
      </div>

      )
    case 2:
      return(
        <div className="container-fluid">
          
        <p className="text-light mb-2" style={{borderBottom: '1px solid #2F499E'}}>Results</p>

        <div className="d-flex p-2 justify-cotnent-between align-items-cetner rounded" style={{background: '#34385a', border:'1px solid #2F499E'}}>
          <div className="d-flex p-2 flex-column" >
            <p className=" fs-6" style={{color:'#e5eaf1'}}>
              BLEU
            <p className=" fs-3" style={{color:'#e5eaf1'}}>{bleuSum}</p>
            </p>
          </div>
          <p className=" ml-2" style={{color:'#BAC9F1'}}>{blueText}</p>
        </div>

        <div className=" mt-2 p-2 d-flex flex-column">
            <div className="p-2 d-flex align-items-center" style={{background: '#34385a', border:'1px solid #2F499E',borderRadius:'5px 5px 0px 0px'}}>
              
              <div class="form-check mr-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  Precision
                </label>
              </div>

              

              <div class="form-check mr-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  Recall Rate
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label class="form-check-label" for="gridCheck">
                  F-1
                </label>
              </div>
            </div>
            <div className="">
              {/* <div className="d-flex align-items-center border-bottom">
              
              </div> */}
              <div className="p-2"  style={{border:'1px solid #2F499E'}}>
              {bleuData ? (
        <>
          <BleuScoreChart
            bleuScore={bleuData.bleu} 
            brevityPenalty={bleuData.brevity_penalty} 
          />
          <BleuDetailsChart
            precisions={bleuData.precisions} 
            lengthRatio={bleuData.length_ratio} 
            translationLength={bleuData.translation_length} 
            referenceLength={bleuData.reference_length} 
          />
        </>
      ) : (
        <p>Loading...</p>
      )}
              </div>
            </div>
            
          </div>
      </div>

      )
    default:
      return <div>No data available</div>;
  }
};

  
  
  
  
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const knowledgeWorkLinks = [
    { name: "1. Perplexity", isActive: activeTabIndex === 0 },
    { name: "2. Rouge", isActive: activeTabIndex === 1 },
    { name: "3. BLEU", isActive: activeTabIndex === 2 },
  ];

  const handleKnowledgeWorkClick = (index) => {
    setActiveTabIndex(index);
  };

  const [data, setData] = useState(null);


  useEffect(() => {
    const loadChartData = async () => {
      const chartData = await fetchChartData();
      setData(chartData);
    };

    loadChartData();
  }, []);

  

  useEffect(() => {
    if (
      knowledgeWorkLinks.find((link) => link.isActive && link.name === "New")
    ) {
      setDisplayedData(newData);
    } else if (
      knowledgeWorkLinks.find(
        (link) => link.isActive && link.name === "In Progress"
      )
    ) {
      setDisplayedData(inProgressData); // replace with actual drafts data
    } else {
      setDisplayedData(completedData);
    }
  }, [completedData, knowledgeWorkLinks]);

  return (
    <div>
        
        <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
      <div className="container-fluid py-4 px-4">
        <p className="text-light fw-bold">Model Training</p>
        <p style={{color: '#ADC2FF', marginTop:'-10px'}}>Knowledge Work: {knowledgeWorkDetails.knowledge_work.name}</p>
      </div>
      
      <div className="d-flex justify-content-end align-items-center" style={{ background: '#34385A'}}>
  <Nav className="d-flex" style={{marginBottom:'7px'}}>
    <button className='btn btn-sm btn-link mr-2'>
      <Link to={`/mo/summary/new/${id}`} style={{ color: '#ADC2FF', textDecoration: 'underline'}}>Summary</Link>
    </button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Close</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }} onClick={handlePrevStep}>Back</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Save</button>
    <button className='btn btn-sm mr-2' style={{ background: '#003BEB', color: '#fff' }} onClick={() => handleNextStep(currentModelId)}>Proceed</button>
  </Nav>
</div>


      <div className="container-fluid "style={{ background: '#222E59', height: '100vh', display: 'flex',padding:'30px' }}>
        <div className="row flex-grow-1 m-0" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="col-md-2 p-2" style={{ background: "#42466C", overflowY: 'auto', height: '93vh',borderRadius: '4px 0px 0px 4px' }}>
            <h5 style={{ fontSize: "16px" }} className="text-light ml-4">
              Models ({selectedModelIds.length})
            </h5>
            <div className="accordion" id="accordionExample">
  {selectedModelIds.map((model, modelIndex) => (
    <div className="accordion-item" key={modelIndex} style={{ border: 'none' }}>
      <h2 className="accordion-header" id={`heading${model.id}`}>
        <button
          className={`accordion-button ${modelIndex === 0 ? 'show' : 'collapsed'}`}
          type="button"
          style={{
            background: '#313557',
            color: '#fff',
            fontSize: '14px',  // Adjusted font size
            padding: '8px 16px',
            
              // Adjusted padding for a smaller button
          }}
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${model.id}`}
          aria-expanded={modelIndex === 0 ? 'true' : 'false'}
          aria-controls={`collapse${model.id}`}
        >
          {model.model_name}
        </button>
      </h2>
      <div
        id={`collapse${model.id}`}
        className={`accordion-collapse collapse ${modelIndex === 0 ? 'show' : ''}`}
        aria-labelledby={`heading${model.id}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body" style={{ background: '#42466C', padding: '10px' }}>
          <div className="accordion" id={`accordionExample${model.id}`}>
            {sections.map((section, sectionIndex) => (
              <div className="accordion-item" content=""key={sectionIndex} style={{ border: 'none' }}>
                <h2 className="accordion-header" id={`heading${model.id}${sectionIndex}`}>
                  <button
                    className={`accordion-button rounded-0 ${sectionIndex === currentSection ? '' : 'collapsed'}`}
                    type="button"
                    style={{
                      background: sectionIndex === currentSection ? '#313557' : '#42466C',
                      color: '#fff',
                      fontSize: '14px',  // Adjusted font size
                      padding: '8px 16px'  // Adjusted padding for a smaller button
                    }}
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${model.id}${sectionIndex}`}
                    aria-expanded={sectionIndex === currentSection ? 'true' : 'false'}
                    aria-controls={`collapse${model.id}${sectionIndex}`}
                  >
                    {section.name}
                  </button>
                </h2>
                <div
                  id={`collapse${model.id}${sectionIndex}`}
                  className={`accordion-collapse collapse ${sectionIndex === currentSection ? 'show' : ''}`}
                  aria-labelledby={`heading${model.id}${sectionIndex}`}
                  data-bs-parent={`#accordionExample${model.id}`}
                >
                  <div className="accordion-body" style={{ background: '#42466C', padding: '10px' }}>
                    <div
                      className="progress"
                      style={{ height: '8px', marginBottom: '10px' }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${((currentStep[sectionIndex] + 1) / sections[sectionIndex].steps.length) * 100}%`,
                          background: '#0FC948',
                        }}
                        aria-valuenow={(currentStep[sectionIndex] + 1) * (100 / sections[sectionIndex].steps.length)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <ul className="list-group">
                      {section.steps.map((step, stepIndex) => {
                        const isCompleted = stepIndex < currentStep[sectionIndex];
                        const isCurrent = stepIndex === currentStep[sectionIndex];

                        return (
                          <li
                            key={stepIndex}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '4px',
                              fontSize: '14px', // Adjusted font size for better readability
                              padding: '8px',
                              borderRadius: '4px',
                              border: 'none',
                              backgroundColor: isCurrent ? '#313557' : 'transparent',
                              color: isCurrent || isCompleted ? '#fff' : '#ccc',
                            }}
                            className={`list-group-item ${isCurrent ? 'active' : ''}`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: isCompleted ? '#0FC948' : '#4D5284',
                                color: '#fff',
                                marginRight: '12px',
                                fontSize: '14px',
                                textAlign: 'center',
                                flexShrink:0
                              }}
                            >
                              {isCompleted ? '✓' : stepIndex + 1}
                            </div>
                            <div
                              style={{
                                flex: 1,
                                padding: '4px 8px',
                                borderRadius: '4px',
                              }}
                            >
                              {step}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ))}
</div>




          </div>
          <div className="col-md-6 chatBackgroundImage" style={{
            
            // Ensure the container has a minimum height
            background:'#E8EDFA', // Centers the image in the container
           
            height: '100%',
            opacity:'100',
            overflowY:'auto'
               }}>
                <div className="d-flex flex-column overflow-hidden" style={{minHeight:"90%",maxHeight:'90%'}}>
            <div
              className="flex-grow-1 d-flex flex-column overflow-scroll chatBackgroundImage" style={{
                backgroundSize: 'cover', // Ensures the image covers the container
                backgroundPosition: 'center', // Centers the image in the container
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                backgroundAttachment: 'fixed', // Keeps the background fixed during scrolling
                position: '', // Keeps the center column fixed
                top: '0',
               
              }}
              
             // Optional: Set background for visibility
            >
              {/* {currentStep[0] === 0 && (
                <div className="d-flex flex-column justify-content-center p-4">
                  <div
                    className="p-2 m-2 d-flex rounded"
                    style={{ background: "#FFFFFF" }}
                  >
                    <p className="text-dark">
                    No training data at hand? No problem! CoachLLM can generate the data you need according to your specifications
                    <br/>If you have training data ready for upload, use the option on the right side to get started. </p>            
                  </div>
                                   
                </div>
              )
              
            } */}
            {currentStep[0] === 0 &&(
                    <DataSynthesizerSeed
                    stepIndex={seedCurrentStep}
                    messages={seedMessages}
                    sendMessage={sendSeedMessage}
                  />
            
            )}
              {currentStep[0] === 1 && (
                      currentStep[0] === 1 && renderChat(1)
      //           <>
      //           {messages.length === 0 && (
      //             <p className="text-center text-dark mt-4">Ask Queries about hyperparameters</p>
      //           )}
      //         <div className="d-flex flex-column-reverse flex-grow-1 overflow-scroll p-4" style={{ minHeight: '75%' }}>
      //     <div className="d-flex flex-column w-100">
      //       {messages.map((msg, index) => (
      //         <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
      //           <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
      //             <p className="m-0 text-dark">{msg.text}</p>
      //           </div>
      //         </div>
      //       ))}
      //       {/* <div ref={endOfMessagesRef} /> */}
      //     </div>
      //   </div>
      // </>
              )}
             
              {currentStep[0] === 2 && (
                 currentStep[0] === 2 && renderChat(2)

              )}
              {currentStep[0] === 3 && (
                <div className="d-flex flex-column justify-content-center p-4">
                   <button  className={`m-2 btn btn-sm d-flex text-light align-items-center justify-content-center ${isFinetuningStarted ? 'animated-ellipsis' : ''}`} style={{background: '#003BEB'}} onClick={handleClick} disabled={isTrainingCompleted || isTrainingStarted || isFinetuningStarted}>
                    <span className="px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-bot"
                      >
                        <path d="M12 8V4H8" />
                        <rect width="16" height="12" x="4" y="8" rx="2" />
                        <path d="M2 14h2" />
                        <path d="M20 14h2" />
                        <path d="M15 13v2" />
                        <path d="M9 13v2" />
                      </svg>
                    </span>{" "}
                    {isFinetuningStarted ? "Finetuning" : !isTrainingCompleted ? "Start Finetuning":'Training Completed'}
                  </button>
                 
                {isTrainingStarted && (
            <div
                className="p-4 m-2 d-flex flex-column rounded"
                style={{ background: "#FFFFFF" }}
            >
                <p className="text-dark">
                    Supervised Finetuning Completion Status
                </p>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="progress" style={{ width: "100%" }}>
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progress}%`, background: "#42466C" }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {progress}%
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-primary"
                        style={{ marginLeft: "10px" }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )}
                  {isTrainingCompleted && (
                  <div
                    className="p-4 m-2 d-flex flex-column rounded"
                    style={{ background: "#FFFFFF" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#08C948"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-circle-check"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>

                        <h3 className="fs-4 ml-2" style={{ color: "#08C948" }}>
                          Supervised Finetuning Completed
                        </h3>
                      </div>
                      {/* <p className="text-dark" style={{ marginLeft: "10px" }}>
                        Time Taken: 12 Hours
                      </p> */}
                    </div>
                  </div>
                  
                )}

                </div>
              )}
              {currentStep[0]===4 && (
                <div>
                                
                                  <>
                                  
                                <div className="text-dark bg-light p-2 rounded">
                                <div
                                className="p-4 m-2 d-flex flex-column rounded"
                                style={{ background: "#ffffff" }}
                              >
                                <p className="text-center text-dark">Context </p>
                
                                <div class="">
                                  <div className="d-flex justify-content-between">
                                  <p className="text-dark">Sample test text</p>
                                  <p className="text-dark">Upload</p>
                                  </div>
                <textarea className="form-control border-0 text-dark"id="floatingTextarea2" style={{background: '#E8EDFA',height: "100px"}} value={context}
                          onChange={handleContextChange}></textarea>
                
                </div>
                              </div>
                               <div
                               className="p-4 m-2 d-flex flex-column rounded"
                               style={{ background: "#ffffff" }}
                             >
                               <p className="text-center text-dark">Question </p>
                
                               <div class="">
                                 <div className="d-flex justify-content-between">
                                 <p className="text-dark">Sample test text</p>
                                 <p className="text-dark">Upload</p>
                                 </div>
                <textarea className="form-control border-0 text-dark"id="floatingTextarea2" style={{background: '#E8EDFA',height: "100px"}} value={question}
                         onChange={handleQuestionChange} ></textarea>
                
                </div>
                <button className="btn btn-sm text-light mt-2" style={{background: '#003BEB'}} onClick={handleInference} > {Infloading ? 'Inferring...' : 'Inference'}</button>
                             </div>
                                </div>
                             </>
                
                                 
                                  <div className="text-dark bg-light p-2 rounded" dangerouslySetInnerHTML={{__html: responseText}}></div>
                               
                              
                                </div>
                
              )}
              {currentStep[0] === 5 && (
                <div className="d-flex flex-column justify-content-center p-4" >
                 
                 
                
                  <div
                    className="p-4 m-2 d-flex flex-column rounded"
                    style={{ background: "#ffffff" }}
                  >
                    <p className="text-center text-dark">METRIX CALCULATION</p>

                    <div class="">
                      <div className="d-flex justify-content-between">
                      <p className="text-dark">Sample test text</p>
                      <p className="text-dark">Upload</p>
                      </div>
  <textarea className="form-control border-0 text-dark"id="floatingTextarea2" style={{background: '#E8EDFA',height: "100px"}} value={originalText}
         onChange={handleOriginalTextChange}></textarea>

</div>
<button className="btn btn-sm text-light mt-2" style={{background: '#003BEB'}} onClick={handleSendEvaluate}>{evalLoading ? 'Evaluating...' : 'Evaluate'}</button>
                  </div>
                </div>
              )}
              </div>
      <div className="d-flex align-items-end justify-content-center">
                <div
                  className="p-2 m-2 d-flex align-items-center rounded"
                  style={{ background: "#ffffff" }}
                >
                <input
  type="text"
  className="form-control"
  style={{
    background: "transparent",
    color: "#003BEB",
    width: "30rem",
    height:'2rem',
    border: 'none',
    outline: 'none'
  }}
  placeholder="Type a message..."
  ref={messageInputRef}
  value={message}
  onChange={(e) => setMessage(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === 'Enter') {
      sendMessage(message); // Send the message typed by the user
    }
  }}
/>

                   <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleSyntheticFileChange}
          ref={fileInputRef}
        />
        <span className="px-2" onClick={() => fileInputRef.current.click()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="17.661" height="15.877" viewBox="0 0 17.661 15.877">
  <path id="Path_5908" data-name="Path 5908" d="M20.339,20.58a.6.6,0,0,1,0-.842l3.57-3.57a.6.6,0,0,1,.842,0l3.57,3.57a.6.6,0,1,1-.842.842l-2.554-2.555v6.3a.6.6,0,0,1-1.19,0v-6.3L21.181,20.58a.6.6,0,0,1-.842,0ZM32.661,24.92v4.76a1.19,1.19,0,0,1-1.19,1.19H17.19A1.19,1.19,0,0,1,16,29.68V24.92a1.19,1.19,0,0,1,1.19-1.19h5.058a.3.3,0,0,1,.3.3v.257a1.818,1.818,0,0,0,1.819,1.825,1.785,1.785,0,0,0,1.751-1.785v-.3a.3.3,0,0,1,.3-.3h5.058A1.19,1.19,0,0,1,32.661,24.92ZM29.686,27.3a.893.893,0,1,0-.893.893.893.893,0,0,0,.893-.893Z" transform="translate(-15.5 -15.494)" fill="#003beb" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</svg>
           
        </span>
                  <span className="px-2"  onClick={() => sendMessage(message)}>
                    
                  <svg id="Group_1737" data-name="Group 1737" xmlns="http://www.w3.org/2000/svg" width="15.822" height="13.573" viewBox="0 0 15.822 13.573">
  <g id="Group_1383" data-name="Group 1383">
    <path id="Path_53" data-name="Path 53" d="M0,.088A.089.089,0,0,1,.123.006C.46.145,10.89,4.607,15.768,6.7a.089.089,0,0,1,0,.163l-.015.006L.16,13.55l-.041.016A.089.089,0,0,1,0,13.482V7.639a.088.088,0,0,1,.078-.088c.9-.1,6.4-.5,8.88-.673a.088.088,0,0,0,0-.176C6.464,6.515.9,6.093.079,6.008A.088.088,0,0,1,0,5.92V.088" transform="translate(0 0.001)" fill="#003beb"/>
  </g>
</svg>

                  </span>
                </div>
              </div>

            </div>
          </div>

          
          
          {currentStep[0] === 0 && (
               <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'auto',height:'100vh' ,padding:'0'}}>
               <div className="d-flex align-items-center">
                 <p className="text-light pr-2 border-right">Training Data</p>
                 <p className="text-light" style={{ cursor: "pointer",display: "flex",alignContent:'center',justifyContent:'center',alignItems: 'center'  }}>
                   <span className="px-2">
                   <svg id="Group_676" data-name="Group 676" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
                      <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_790" data-name="Rectangle 790" width="12" height="12" fill="#e5eaf1"/>
                      </clipPath>
                    </defs>
                    <g id="Group_675" data-name="Group 675" clip-path="url(#clip-path)">
                      <path id="Path_198" data-name="Path 198" d="M12,125.257l-.043.1a.528.528,0,0,1-.446.353,1.806,1.806,0,0,1-.2.009H.686a1.247,1.247,0,0,1-.268-.019A.563.563,0,0,1,0,125.2a.828.828,0,0,1,0-.087q0-2.487,0-4.975a.564.564,0,0,1,.483-.6.544.544,0,0,1,.595.471,1.77,1.77,0,0,1,.012.236q0,2.07,0,4.14v.169h9.817v-.157q0-2.082,0-4.165a1.206,1.206,0,0,1,.026-.284.531.531,0,0,1,1.019-.063l.045.113Z" transform="translate(0 -113.719)" fill="#e5eaf1"/>
                      <path id="Path_199" data-name="Path 199" d="M66.179,7.7a.464.464,0,0,0-.058-.121Q65.381,6.785,64.638,6a.526.526,0,0,0-.894.134.579.579,0,0,0,.048.589,1.048,1.048,0,0,0,.116.14l2.428,2.584a.535.535,0,0,0,.879,0l2.444-2.6a.616.616,0,0,0,.143-.733.524.524,0,0,0-.821-.178,1.524,1.524,0,0,0-.111.111q-.717.762-1.433,1.526a.832.832,0,0,0-.075.11l-.041-.025V7.516q0-3.429,0-6.858a.615.615,0,0,0-.285-.585.539.539,0,0,0-.8.467c0,.058,0,.116,0,.174q0,3.4,0,6.8v.144l-.055.043" transform="translate(-60.777 0)" fill="#e5eaf1"/>
                    </g>
                    </svg>
                   </span>
                   <a
                     href={templateSelector[path]}
                     download="Template"
                     target="_blank"
                     style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                     className={
                       (darkMode ? "darg-n-drop-dark " : "darg-n-drop ") +
                       "p-0 justify-content-center fw-medium"
                     }
                   >
                     Download Template
                   </a>
                 </p>
               </div>
               <Card
                 className={darkMode ? "UploadCompDark p-3" : "UploadComp p-3"}style={{overflow:'scroll',height:'auto'}}
               >
                 <Card.Body
                   className={
                     darkMode
                       ? "uploadCompOuter d-flex align-items-center justify-content-center height:100%"
                       : "UploadComp d-flex align-items-center justify-content-center height:100%"
                   }
                   style={{ color: darkMode ? "" : "white" }}
                 >
                   {csvData.length <= 0 && (
                     <div className="text-center">
                       <div
                         className={
                           (darkMode ? "image-upload-dark" : "image-upload") +
                           " text-center d-flex flex-column align-items-center "
                         }
                       >
                         <input
                           type="file"
                           // id="fileUpload"
                           onChange={handleFileChange}
                           id="file-input"
                         />
                         <FiUpload
                           style={{
                             fontSize: "30px",
                             color: darkMode ? "#E5EAF1" : "#0056b3",
                           }}
                         />
                         {/* </label> */}
                         <div
                           style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                           className={
                             darkMode
                               ? "darg-n-drop-dark pb-4 pt-2"
                               : "darg-n-drop pb-5 fw-medium"
                           }
                         >
                           Drag and Drop
                         </div>
                         <div
                           className={
                             darkMode
                               ? "uploadCompOrDark mb-4"
                               : "uploadCompOr mb-5"
                           }
                         >
                           OR
                         </div>
                         <div
                           className="taskList"
                           style={{
                             fontSize: "14px",
                             color: darkMode ? "" : "rgb(0, 86, 179)",
                           }}
                         >
                           Choose from Repository
                         </div>
                         <label htmlFor="file-input" className="my-2">
                           <a className="cancelBtnDark  mx-0">
                             Choose Training Data
                           </a>
                         </label>
                         {selectedFile && (
                           <div
                             style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                           >
                             {" "}
                             {selectedFile.name}{" "}
                           </div>
                         )}
                       </div>
  
                       <Button
                         onClick={handleUpload}
                         disabled={loading}
                         // className="submitBtn mt-2"
                         className={`mt-2 mr-0 ${
                           selectedFile ? "" : "disabled"
                         } ${darkMode ? "submitBtnDark" : "submitBtnDark"}`}
                       >
                         {loading ? "Uploading..." : "Upload"}
                       </Button>
                       {loading && <div className="loader">Loading...</div>}
                     </div>
                   )}
                   {/* Display CSV Data */}
                   {csvData.length > 0 && (
                     <div className="csv-data ">
                       <Table
                         className={`${
                           darkMode ? "custom-tableDark" : "custom-table"
                         } mt-3`}
                       >
                         <thead className="table-secondary ">
                           <tr>
                             {Object.keys(csvData[0]).map((header, index) => (
                               <th key={index} style={{ fontWeight: "bold" }}>
                                 {header}
                               </th>
                             ))}
                           </tr>
                         </thead>
                         <tbody>
                           {csvData.map((row, index) => (
                             <tr key={index}>
                               {Object.values(row).map((cell, cellIndex) => (
                                 <td key={cellIndex}>{cell}</td>
                               ))}
                             </tr>
                           ))}
                         </tbody>
                       </Table>
                     </div>
                   )}
                 </Card.Body>
               </Card>
             </div>
          )}
          {currentStep[0] === 1 && (
            <div className="col-md-4 " style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light"> SFT Hyperparameters</p>
              </div>
              <div className="mt-2 p-2 d-flex flex-column" style={{backgroundColor:'transparent'}}>
                <CustomTableNew
                  // updateData={updateData}

                  data={hyperparameterTableData}
                  TableColumn={TableColumnLocal[index - 1]}
                  darkMode={darkMode}
                  onDataChange={handlehyperparameterTableDataChange}
                ></CustomTableNew>
              </div>
            </div>
          )}
         
          {currentStep[0] === 2 && (
            <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
  
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light">PEFT Hyperparameters</p>
              </div>
              <div className=" mt-2 p-2 d-flex flex-column" style={{backgroundColor:'transparent'}}>
              <CustomTableNew

                  data={peftTableData}
                  TableColumn={TableColumnLocal[index - 1]}
                  darkMode={darkMode}
                  onDataChange={handlePeftTableDataChange}
                ></CustomTableNew>
              </div>
            </div>
          )}
          {currentStep[0] === 3 && (
            <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
  
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light">Evaluate SFT</p>
              </div>
              
              <div className=" mt-2 p-2 d-flex flex-column">
               
              <div className="mt-2">
    {progress === 100 && metricsError && (
        <p className="text-align-center text-light">Fetching Metrics...</p>
    )}
    {progress === 100 && !metricsError && (
        <div className="p-2 mt-2">
            {learningRateChartData ? (
                <Line data={learningRateChartData} options={data.options1} />
            ) : (
                <p className="text-align-center text-light">No learning rate data available.</p>
            )}
            {lossChartData ? (
                <Line data={lossChartData} options={data.options2} />
            ) : (
                <p className="text-align-center text-light">No loss data available.</p>
            )}
        </div>
    )}
    {progress < 100 && (
        <div className="p-2 mt-2 rounded" style={{background: '#34385a', border:'1px solid #2F499E', maxHeight: '90vh', overflowY: 'scroll'}}>
            <p style={{color:'#BAC9F1'}}>{logs}</p>
        </div>
    )}
</div>

                
              </div>
            </div>
          )}
          {currentStep[0]===4 &&(
               
              <div className="col-md-4 p-2" style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
                <div className="d-flex align-items-center border-bottom">
                <p className="text-light">Inference Response</p>
              </div>
              <div className="p-2 mt-2 rounded" style={{background: '#34385a', border:'1px solid #2F499E', maxHeight: '90vh', overflowY: 'scroll'}}>
            <p style={{color:'#BAC9F1'}} dangerouslySetInnerHTML={{__html: responseText}}></p>
        </div>
            
                </div>

          )}
          {currentStep[0] === 5 && (
            <div className="col-md-4 p-2" style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
  
              <div className=" m-2 rounded" style={{background: '#212542'}}>
              <Nav className="mb-3 pt-2 rounded" style={{background: '#34385A'}}>
                {knowledgeWorkLinks.map((link, index) => (
                  <Nav.Link
                    key={index}
                    onClick={() => handleKnowledgeWorkClick(index)}
                    className={`justify-content-center align-items-center d-flex mt-0
              ${link.isActive && "pb-0"}`}
                    style={{
                      display: "inline-block",
                      borderBottom: link.isActive
                        ? "4px solid #3366FF"
                        : "none",
                      marginBottom: "-5px",
                      marginRight: "6px",
                      fontSize: "16px", // Fixed typo from '16ppx' to '16px'
                      color: darkMode ? "#fff" : "#000",
                      cursor: "pointer", // Add pointer cursor to indicate clickable
                    }}
                  >
                    {link.name}
                  </Nav.Link>
                ))}
              </Nav>
              {blueText != "" && (
                <div>{renderContent()}</div>
              )}
              {blueText === "" && (
                <div className="d-flex justify-content-center align-items-center">
                    <p className="text-light">Click on Evaluate to see the scores.</p>
                </div>
              )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  
};

export default Training;
