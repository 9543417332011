import React, { useEffect, useState } from "react";
import "./progressBar.css";

export default function ProgressBar({ file, progress, removeFile }) {
  const [isupload, setisupload] = useState(false);

  useEffect(() => {
    if (progress >= 100) {
      setisupload(true);
    } else {
      setisupload(false);
    }
  }, [progress]);

  return (
    <div className="progress-div">
      <h5 className="progress-file-txt">{file}</h5>
      <div className="d-flex justify-content-between align-items-center gap-5 ">
        {isupload ? (
          <h4 className="progress-txt"> File is uploaded </h4>
        ) : (
          <>
            <progress value={progress} max="100" />
          </>
        )}
        <i
          class="fa-solid fa-x ms-2"
          style={{ fontSize: "12px" }}
          onClick={() => {
            removeFile(file);
          }}
        ></i>
      </div>
    </div>
  );
}
