import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Card } from "react-bootstrap";
import "./ModelHomeScreen.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import searchIcon from "../../../assets/img/searchIcon.png";

const CompareModels = ({darkMode}) => {
  return (
    <div className=" w-auto">
      <Card
          className="mt-2"
          style={{
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0",
            border: darkMode ? "none" : "inherit"
          }}
        >
          <Navbar
            expand="lg"
            className={darkMode ? " ModelNavbarContainer darkBackground" : "ModelNavbarContainer whiteBg"}
          >
            <Container
              fluid
              className="py-0"
              style={{ border: (darkMode ? "none" : "1px solid #DFE1E5"), padding: "40px" }}
            >
              <Navbar.Brand
                className=" baseFont"
                style={{
                  color: darkMode ? "#E5EAF1" : "",
                  textTransform: "inherit",
                  fontWeight: "bold"
                }}
              >
                Select Model
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="ms-auto my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  {/* <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#action2">Link</Nav.Link> */}
                  <span className="my-auto pe-2 text-capitalize dropdown-label-dark "
              style={{ color: darkMode ? "#E5EAF1" : "" }}>
                    Base Model 
                  </span>
                  <NavDropdown
                    className={
                      darkMode
                        ? "btn text-capitalize form-select modelSelectDropdownDark dropdown-placeholder-dark dropdown-box-dark"
                        : " btn text-capitalize form-select modelSelectDropdown dropdown-placeholder-dark dropdown-box-dark"
                    }
                    title="All"
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item href="#action3">Mistral-7b</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                    Falcon
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link href="#" disabled>
              Link
            </Nav.Link> */}
                </Nav>
                <InputGroup
                  className={
                    darkMode
                      ? "searchWidth alchemSearchGroupDark"
                      : "searchWidth alchemSearchGroup"
                  }
                >
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    className="searchBar"
                  />
                  <Button
                    variant="outline-secondary"
                    id="searchButton"
                    className="searchGroupBtn"
                  >
                    <img
                      className="img-fluid"
                      loading="lazy"
                      src={searchIcon}
                      alt=""
                    />
                  </Button>
                </InputGroup>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Card>
     
    </div>
  );
};

export default CompareModels;
