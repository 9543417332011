import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Nav } from 'react-bootstrap'; // Ensure react-bootstrap is installed  
import './ChatBot.css'; // Custom stylesheet  
import ModelsNav from './ModelOrchestrator/FineTunining/ModelsNav';
import { useParams } from "react-router-dom";
import { AuthContext } from './Auth/AuthContext';
import { height, margin } from '@mui/system';

const ChatBot = ({ isAgentTest = false }) => {
    const { darkMode } = useContext(AuthContext);
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [executionId, setExecutionId] = useState(null);
    const [status, setStatus] = useState(null);
    const [logs, setLogs] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [final, setFinal] = useState("");
    const { knowledgeWorkId, activityId } = useParams()


    // Function to handle input change  
    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    // Function to trigger the first API  
    const startExecution = async () => {
        try {
            // dynamic endpoint based on the test page 
            const endPoint = activityId !== "0" ? `/execute_crew/${knowledgeWorkId}/${activityId}` : `/execute_crew/${knowledgeWorkId}`
            console.log(endPoint, knowledgeWorkId, activityId)
            console.log(`${process.env.REACT_APP_CREW_SERVER}${endPoint}`)

            const response = await axios.post(
                `${process.env.REACT_APP_CREW_SERVER}${endPoint}`,
                { input: userInput },
                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );
            setExecutionId(response.data.id);
            setMessages([...messages, { isUser: true, text: userInput }]);
            setUserInput('');
            pollForExecutionData(response.data.id);
        } catch (error) {
            console.error('Error starting execution:', error);
        }
    };

    // Function to poll for execution data  
    const pollForExecutionData = async (executionId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_CREW_SERVER}/get_results/${executionId}`,
                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );


            const executionData = response.data;
            console.log(executionData);
            setStatus(executionData.status);
            setLogs(executionData.logs);

            if (executionData.status === 'PENDING') {
                setTimeout(() => pollForExecutionData(executionId), 3000);
            } else if (executionData.status === 'WAITING_FOR_INPUT') {
                await getInput(executionId);
                // setMessages([...messages, { isUser: false, text: 'Waiting for user input...' }]); 
                return;
            } else {
                setMessages([...messages, { isUser: false, text: executionData.result.result.final_output }]);
                setFinal(executionData.result.result.final_output)
            }
        } catch (error) {
            console.error('Error polling execution data:', error);
        }
    };

    // Function to handle user input for WAITING_FOR_INPUT status  
    const provideInput = async (signal) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_CREW_SERVER}/provide_input/${executionId}`,
                { input: userInput, "signal": signal },
                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );
            setUserInput('');
            setFinal("")
            // setMessages([...messages, { isUser: true, text: userInput }]);  
            setStatus("PENDING")
            pollForExecutionData(executionId); // Resume polling after providing input  
        } catch (error) {
            console.error('Error providing input:', error);
        }
    };
    const getInput = async (executionId) => {
        console.log(executionId)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_CREW_SERVER}/provide_input/${executionId}`,

                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );
            setFinal(response.data.status)
            console.log(response.data.status)
        } catch (error) {
            console.error('Error providing input:', error);
        }
    };

    // Function to handle message submission  
    const handleSubmit = async () => {
        if (!userInput.trim()) return;
        setMessages([])
        if (status !== "WAITING_FOR_INPUT")
            await startExecution();

    };

    // Function to handle key click (if needed)  
    const handleKeyClick = (key) => {
        if (logs) {
            const data = logs[key];
            setSelectedData({ key, data: data ? JSON.stringify(data, null, 2) : 'No data for this key.' });
            setShowModal(true);
        }
    };

    const centerStyle = {
        textAlign: 'center', // Centers the text horizontally  
        display: 'flex',
        justifyContent: 'center', // Centers the content horizontally in the flex container  
        alignItems: 'center', // Centers the content vertically in the flex container  
        // Full height of the viewport  
        height: "10%",
        margin: "0"
    };

    return (
        <>
            <ModelsNav darkMode={darkMode} />
            <div style={{ height: "90%", overflow: "hidden" }}>

                <h2 style={centerStyle}>KNOWLEDGE WORK API</h2>
                <div className="chatbot-container" style={{ height: "90%", minHeight:"300px", maxHeight:"90%", minHeight:"400px" }}>
                    <div className="chat-window" style={darkMode ? { backgroundColor: "#E1E7F9", height: "90%", minHeight:"400px" } : {}}>
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={message.isUser ? 'user-message' : 'bot-message'}
                                style={{ color: 'black' }}
                            >
                                {message.text}
                            </div>
                        ))}

                        {status && <div style={{ color: "blue" }}>STATUS: {status}</div>}
                        <Nav className="keys-nav" style={{ color: 'black' }}>
                            <pre>{logs}</pre>
                            {final}
                        </Nav>
                    </div>
                    <div className="input-section" style={{ height: "10%" }}>
                        <input
                            type="text"
                            value={userInput}
                            onChange={handleInputChange}
                            placeholder="Type your message here..."
                            className="message-input"
                            style={{ borderRadius: "4px" }}
                        />
                        <button onClick={handleSubmit} className="btn btn-primary submitBtnDark ms-3">Send</button>
                    </div>
                </div>
            </div>

            {status == 'WAITING_FOR_INPUT' ? <div className="custom-component" style={{ position: 'absolute', right: '20px', top: '20px' }}>
                <h4 style={{ color: 'black' }}>yes- go ahead, no - ask feedback again</h4>
                <input
                    type="text"
                    placeholder="Enter text here"
                    value={userInput}
                    onChange={handleInputChange}
                    disabled={status !== 'WAITING_FOR_INPUT'}
                    style={{ marginBottom: '10px', padding: '5px', width: '200px', border: '1px solid #ccc', borderRadius: '4px' }}
                />
                <button
                    onClick={() => provideInput(1)}
                    disabled={status !== 'WAITING_FOR_INPUT'}
                    style={{ marginBottom: '5px', padding: '10px 20px', border: 'none', borderRadius: '4px', backgroundColor: status === 'WAITING_FOR_INPUT' ? '#007bff' : '#ccc', color: 'white', cursor: status === 'WAITING_FOR_INPUT' ? 'pointer' : 'not-allowed' }}
                >
                    Yes
                </button>
                <button
                    onClick={() => provideInput(0)}
                    disabled={status !== 'WAITING_FOR_INPUT'}
                    style={{ padding: '10px 20px', border: 'none', borderRadius: '4px', backgroundColor: status === 'WAITING_FOR_INPUT' ? '#007bff' : '#ccc', color: 'white', cursor: status === 'WAITING_FOR_INPUT' ? 'pointer' : 'not-allowed' }}
                >
                    No
                </button>
            </div> : null}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "black" }}>Data for {selectedData.key}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{selectedData.data && <>{selectedData.data}</>}</pre>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChatBot;

