import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    InputGroup,
    Modal,
    Nav,
    Row
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DockContext } from "../../../DockWrapper/DockContext";
import { AuthContext } from "../../../Auth/AuthContext";
import NavBar from "../navBar/NavBar";
import Login from "../../Login/Login";
import dottedPatternBG from '../../../../assets/img/doted-patternbg.png'
import sendchat from '../../../../assets/img/sendchat.svg'
import ReactFlowContainer from "../../../AChain/ReactFlowContainer";
import AchainDock from "../../../AChain/AchainDock/AchainDock"
import PinkBtnGif from '../../../../../src/assets/img/alchemist-ai-1.gif'
import { minWidth } from "@mui/system";


function TestAgent({ darkMode, handleToggle }) {
    const { nodeIndex, selectedKnowledgeWork, activity_master_json, setActivity_master_json, setactivityReactFlowHis } = useContext(DockContext)
    const [agentHistory, setagentHistory] = useState([])

    // ---------------wireframe update 

    const [tabLinks, setTabLinks] = useState([
        { name: "goal", route: "", isActive: true },
        { name: "instructions", route: "", isActive: false },
        { name: "tools", route: "", isActive: false },
    ])
    const [CrewtabLinks, setCrewTabLinks] = useState([
        { name: "Chain", route: "", isActive: true },
        { name: "Details", route: "", isActive: false },
    ])
    const links = [
        { name: "Knowledge Work", route: "/activityList", isActive: false },
    ];

    const handleCrewabChange = (index) => {
        setCrewTabLinks(prevLinks =>
            prevLinks.map((link, i) => {

                if (i == index) {
                    setdescription(agentConfig[link.name])
                }

                return { ...link, isActive: i === index }
            }
            )
        )
    }


    const [agentConfig, setagentConfig] = useState({
        goal: "Sed efficitur quam at massa semper, at maximus dui tristique. Duis ut magna in felis auctor faucibus. Donec pellentesque quis ligula in dignissim. Quisque tellus ipsum, efficitur sed suscipit consequat, consectetur sit amet nisi. Ut ac condimentum est. Mauris vulputate dictum ipsum eget ultricies.",
        instructions: "Mauris vestibulum tristique leo, et luctus nulla pharetra a. Nullam consectetur metus est, ac aliquam sapien rutrum quis. Phasellus non libero non diam porttitor aliquet. Nunc sit amet enim eget sem tempus facilisis non eget est. Praesent feugiat sapien ut orci scelerisque, vitae tempus felis sagittis.",
        tools: "Morbi sit amet magna lorem. Fusce placerat ligula sed ex auctor commodo. Vivamus efficitur vulputate tincidunt. Morbi viverra diam nec lectus molestie, ac aliquam velit posuere. Sed vehicula mi eu leo dapibus, ac dapibus libero egestas. Aliquam ac gravida nisl, sit amet ultricies nulla."
    })

    const [description, setdescription] = useState("")
    const handleTabChange = (index) => {
        setTabLinks(prevLinks =>
            prevLinks.map((link, i) => {

                if (i == index) {
                    setdescription(agentConfig[link.name])
                }

                return { ...link, isActive: i === index }
            }
            )
        )
    }


    const { knowledgeWorkId, activityId } = useParams()
    const navigate = useNavigate()
    const [activityDetails, setActivityDetails] = useState({
        "name": "Identifying Top Item Pairs Bought Together",
        "description": "This activity involves using the TCS Optumera solution to analyze customer purchase data and identify the top item pairs that are frequently bought together.",
        "role": "Data Analyst",
        "goal": "To identify patterns in customer purchases and optimize product placement and promotions.",
        "human_input_required": true,
        "status": null,
        "tool_type": null,
        "tool_id": null,
        "kpi": "Data Analysis Accuracy: 98%",
        "id": 3,
        "tasks": [
            {
                "description": "Analyze customer purchase data using TCS Optumera.",
                "expected_output": "A list of top item pairs frequently bought together.",
                "id": 3
            }
        ]
    })

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${knowledgeWorkId}`)
            .then((res) => {
                console.log(res.data)
                setActivity_master_json(res.data)
                setactivityReactFlowHis(res.data?.activity_chain_payload)
            })
            .catch((error) => {
                console.log(error)
            })


        axios.get(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/activities/${activityId}`)
            .then((res) => {
                setActivityDetails(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const [masterJsonActivity, setMasterJsonActivity] = useState(activity_master_json?.master_json?.knowledge_work?.activities?.find((item) => item.name === activityDetails.name))


    useEffect(() => {
        console.log(activity_master_json)
        const activityInMasterJson = activity_master_json?.master_json?.knowledge_work?.activities?.find((item) => {
            console.log(item)
            return item.name === activityDetails.name
        })
        console.log(activityInMasterJson)
        setMasterJsonActivity(activityInMasterJson)
        console.log(masterJsonActivity)

    }, [activityDetails, activity_master_json])


    return (
        <div className="d-flex flex-column" style={{ height: "100%", maxHeight: "100%", background: "transparent linear-gradient(118deg, #222E59 0%, #212542 100%) 0% 0% no-repeat padding-box", }}>
            <NavBar links={links} isSummary={false} profile={false} />

            <div className='d-flex flex-row justify-content-between pb-2' style={{ maxHeight: "10%", background: 'transparent linear-gradient(90deg, #212542 0%, #222C53 49%, #212542 100%) 0% 0% no-repeat padding-box' }}>
                <div className='d-flex flex-column' style={{ marginLeft: '2.5%' }}>
                    <span className='mt-4 mb-1' style={{ font: 'normal normal bold 17px/21px Calibri', letterSpacing: '0.55px' }}>Create Knowledge Work</span>
                    <div className="d-flex justify-content-center align-items-center gap-3" style={{ color: "#ADC2FF" }}>

                        {activityId != 0 &&
                            <>
                                <span className='m-auto' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Agent: {activityDetails.name}</span>
                                <div className="line me-3"></div>
                            </>
                        }
                        <span className='m-auto' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Knowledge Work: {activity_master_json?.name}</span>
                        {activityId != 0 &&
                            <>
                                <div className="line me-3"></div>
                                <span className='m-auto' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Model: {masterJsonActivity ? masterJsonActivity[`${masterJsonActivity?.finetuned_model_type}_model`]["base_model"] : ""} </span>
                            </>
                        }


                    </div>
                </div>
                <div className='d-flex flex-row mt-auto mb-2' style={{ marginRight: '2.5%' }}>
                    <span style={{ border: '1px solid #899DD4' }} className='mx-4'></span>
                    <button className='btn me-2 text-white' style={{ font: 'normal normal bold 14px/24px Calibri', background: "#003BEB 0% 0% no-repeat padding-box", letterSpacing: '0px', color: '#012060', borderRadius: '4px', minWidth: "100px" }} onClick={() => { activityId == 0 ? navigate('/activityList') : navigate('/smeAgentCreation') }}>Exit Test</button>
                </div>
            </div >

            <div
                className="d-flex flex-row p-4"
            >
                <div className="p-0 d-flex flex-column flex-grow-0" style={{
                    background: "#42466C",
                    width: "20%", minHeight: '68vh', maxHeight: "68vh", overflowY: 'scroll'
                }}>
                    <Container
                        className="p-0 border-0 h-100 overflow-hidden"
                    >
                        <Row
                            className="p-2 justify-content-between"
                            style={{ background: darkMode ? '#474F73' : '#f2f3f3', border: 'none' }}
                        >
                            <Col>
                                <h6 className="text-white m-auto" style={{ fontSize: "14px" }} >Run History</h6>
                            </Col>
                            <Col>
                                <Button
                                    variant="primary" disabled>

                                    New Run
                                </Button>
                            </Col>
                        </Row>
                        {agentHistory &&
                            agentHistory.map(rh => {
                                return (
                                    rh.status === 'COMPLETED' ?
                                        <>
                                            <Card className="mt-3 run-history-col"

                                                style={{ background: darkMode ? '#212543' : '', border: 'none' }}
                                            >
                                                <Card.Body>
                                                    <h6 style={{ color: darkMode ? '#BAC9F1' : '' }}>{rh.name}</h6>
                                                    <p style={{ color: darkMode ? '#fff' : '' }}>{rh.status}</p>
                                                    <Row>
                                                        <Col>{rh.num_of_calls} CALLS</Col>
                                                        {rh.time_difference.hours == 0 && (
                                                            <Col>{rh.time_difference.minutes} MINS AGO</Col>
                                                        )}
                                                        {rh.time_difference.hours > 0 && (
                                                            <Col>{rh.time_difference.hours} HRS AGO</Col>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </> : <></>
                                );
                            })}
                    </Container>
                </div>

                <div className="run-name p-0 d-flex flex-column flex-grow-2" style={{ width: '50%', minHeight: '68vh', maxHeight: "68vh", }}>
                    <div className='d-flex flex-column justify-content-end h-100'>

                        <div className='d-flex flex-column justify-content-end' style={{ background: `url(${dottedPatternBG})`, height: "100%", minHeight: "100%", maxHeight: "100%", backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundColor: "#E1E7F9" }}>
                            <TestAgentChat />
                        </div>


                    </div>

                </div>


                <div className="details d-flex flex-column flex-grow-0" style={{ background: "#34385A 0% 0% no-repeat padding-box", width: "30%", minHeight: '68vh', maxHeight: "68vh", overflowY: 'scroll' }} >
                    {
                        activityId == 0 ?
                            <div className="mt-0 h-100 p-2 d-flex flex-column" style={{ background: '#42466C 0% 0% no-repeat padding-box' }}>
                                <Nav
                                    className="flex-grow-1 mt-0 p-2 w-100"
                                    defaultActiveKey={"competencies"}
                                    style={{ height: "10%", background: '#34385A 0% 0% no-repeat padding-box', borderRadius: '4px 4px 0px 0px' }}
                                >
                                    {CrewtabLinks.map((link, index) => (
                                        <Nav.Link
                                            to={link.route}
                                            onClick={() => handleCrewabChange(index)}
                                            className={
                                                `justify-content-center align-items-center d-flex m-auto
                                            ${link.isActive && 'pb-0'}`
                                            }
                                            style={{
                                                display: "inline-block",
                                                borderBottom: link.isActive ? '3px solid #3366FF' : 'none',
                                                fontSize: "16ppx",
                                                color: `${link.isActive ? "#fff" : "#BAC9F1"}`,
                                                textTransform: "capitalize"
                                            }}
                                            as={Link}
                                            key={index}
                                        >
                                            {link.name}
                                        </Nav.Link>
                                    ))}

                                </Nav>

                                <div className="h-100 d-flex">
                                    {CrewtabLinks.find((i) => i.name == "Chain").isActive &&
                                        <ReactFlowContainer />
                                    }


                                    {CrewtabLinks.find((i) => i.name == "Details").isActive &&
                                        <Card className="p-3 mb-3 w-100 details-desc custom-scrollbar" style={{ backgroundColor: "#212542", color: "#BAC9F1" }}>
                                            <Card.Body className="p-0 d-flex flex-column">

                                                <h6 className="fw-light" style={{ color: "#BAC9F1" }}>
                                                    <span className="mr-1">Name:</span>
                                                    {activity_master_json.name}
                                                </h6>
                                                <div className="d-flex flex-row my-1 gap-2">
                                                    <h6 className="fw-light" style={{ color: "#BAC9F1" }}>
                                                        <span className="mr-1">Domain:</span>
                                                        {activity_master_json.domain || activity_master_json?.master_json?.knowledge_work?.domain}
                                                    </h6>
                                                    <h6 className={`fw-light text-capitalize  ${activity_master_json.status == "validate" ? "text-primary" : activity_master_json.status == "done" ? "text-success" : "text-warning"}`}  >
                                                        <span className="mr-1" style={{ color: "#BAC9F1" }}>Status:</span>
                                                        {activity_master_json.status}
                                                    </h6>

                                                </div>
                                                <h6 className="fw-light" style={{ color: "#BAC9F1" }}>
                                                    <span className="mr-1">Sub Domain:</span>
                                                    {activity_master_json.sub_domain || activity_master_json?.master_json?.knowledge_work?.sub_domain}
                                                </h6>
                                                <h6 className="fw-light" style={{ color: "#BAC9F1" }}>
                                                    <span className="mr-1">Description:</span>
                                                    {activity_master_json.description || activity_master_json?.master_json?.knowledge_work?.description}
                                                </h6>
                                                <h6 className="fw-light" style={{ color: "#BAC9F1" }}>
                                                    <span className="mr-1">Business User:</span>
                                                    {activity_master_json.business_user || activity_master_json?.master_json?.knowledge_work?.business_user}
                                                </h6>
                                            </Card.Body>
                                        </Card>
                                    }
                                </div>
                            </div>
                            :
                            <div className="m-3 h-100" >

                                <Card
                                    className="rounded-0 d-flex justify-content-start align-items-center flex-row p-2 ps-4"
                                    style={{
                                        background: "#42466C",
                                        height: "10%"

                                    }}
                                >
                                    <h6 className="m-0 text-white fw-bold" >Agent Details</h6>
                                </Card>
                                <Card
                                    className="rounded-0 d-flex justify-content-start align-items-center flex-row p-2 ps-4"
                                    style={{
                                        background: "#313557",
                                        height: "10%"

                                    }}
                                >
                                    <p className="m-0 text-white" >{masterJsonActivity ? masterJsonActivity[`${masterJsonActivity?.finetuned_model_type}_model`]["base_model"] : ""}</p>
                                </Card>
                                <Container
                                    className="p-0 tableCard"
                                    style={{
                                        border: 'none',
                                        borderTopLeftRadius: "0px",
                                        borderTopRightRadius: "0px",
                                        borderBottomLeftRadius: '0px',
                                        backgroundColor: "#212542",
                                        minHeight: '75%',
                                        maxHeight: '75%'
                                    }}
                                >

                                    <Nav
                                        className="mt-0 me-auto flex-grow-1 my-2"
                                        defaultActiveKey={"competencies"}
                                        style={{ height: "10%" }}
                                    >
                                        {tabLinks.map((link, index) => (
                                            <Nav.Link
                                                to={link.route}
                                                onClick={() => handleTabChange(index)}
                                                className={
                                                    `justify-content-center align-items-center d-flex mt-0
                                            ${link.isActive && 'pb-0'}`
                                                }
                                                style={{
                                                    display: "inline-block",
                                                    borderBottom: link.isActive ? '3px solid #3366FF' : 'none',
                                                    marginBottom: "-5px",
                                                    marginRight: '6px',
                                                    fontSize: "16ppx",
                                                    color: `${link.isActive ? "#fff" : "#BAC9F1"}`,
                                                    textTransform: "capitalize"
                                                }}
                                                as={Link}
                                                key={index}
                                            >
                                                {link.name}
                                            </Nav.Link>
                                        ))}

                                    </Nav>

                                    <Card className="p-3 mb-3 details-desc" style={{ backgroundColor: "#212542" }}>
                                        <Card.Body className="p-0">
                                            {
                                                tabLinks.find((i) => i.name == "goal").isActive &&
                                                activityDetails.goal.split("/").map((goal, i) =>
                                                    <h4 style={{ color: "#BAC9F1", fontSize: "14px" }}>
                                                        <span className="mr-2">{`${i + 1})`}</span>{goal}
                                                    </h4>
                                                )
                                            }

                                            {
                                                tabLinks.find((i) => i.name == "instructions").isActive &&
                                                <table className="p-2" style={{ backgroundColor: "#212542 !important", color: "#BAC9F1" }}>
                                                    <thead >
                                                        <tr>
                                                            <th scope="col" className="p-2" style={{ color: "#fff" }} >#</th>
                                                            <th scope="col" className="p-2" style={{ color: "#fff" }} >DESCRIPTION</th>
                                                            <th scope="col" className="p-2" style={{ color: "#fff" }} >EXPECTED OUTPUT</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            activityDetails.tasks.map((item, index) => {
                                                                return <tr key={index}>
                                                                    <td scope="row" className="text-white p-1"  >{index + 1}</td>
                                                                    <td className="p-2" style={{ color: "#BAC9F1", fontSize: "14px" }}>{item.description}</td>
                                                                    <td className="p-2" style={{ color: "#BAC9F1", fontSize: "14px" }}>{item.expected_output}</td>
                                                                </tr>

                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            }

                                            {
                                                tabLinks.find((i) => i.name == "tools").isActive &&
                                                <h4 style={{ color: "#BAC9F1", fontSize: "14px" }}>
                                                    {activityDetails.name}
                                                </h4>
                                            }
                                        </Card.Body>
                                    </Card>

                                </Container>

                            </div>

                    }


                </div>


            </div >
        </div>
    );
}

export default TestAgent;





export function TestAgentChat() {

    const [sendDisabled, setSendDisabled] = useState(false)
    const [history, setHistory] = useState([])
    const {
        selectedKnowledgeWork
    } = useContext(DockContext);


    const { darkMode } = useContext(AuthContext);
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [executionId, setExecutionId] = useState(null);
    const [status, setStatus] = useState(null);
    const [logs, setLogs] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [final, setFinal] = useState("");
    const { knowledgeWorkId, activityId } = useParams()
    console.log(knowledgeWorkId, activityId)

    let { pathname } = useLocation();

    // Function to handle input change  
    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    // Function to trigger the first API  
    const startExecution = async () => {
        try {
            // dynamic endpoint based on the test page 
            const endPoint = activityId !== "0" ? `/execute_crew/${knowledgeWorkId}/${activityId}` : `/execute_crew/${knowledgeWorkId}`
            console.log(endPoint, knowledgeWorkId, activityId)
            console.log(`${process.env.REACT_APP_CREW_SERVER}${endPoint}`)

            const response = await axios.post(
                `${process.env.REACT_APP_CREW_SERVER}${endPoint}`,
                { input: userInput },
                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );
            setExecutionId(response.data.id);
            setMessages([...messages, { isUser: true, text: userInput }]);
            setUserInput('');
            pollForExecutionData(response.data.id);
        } catch (error) {
            console.error('Error starting execution:', error);
        }
    };

    const [errorMessage, setErrorMessage] = useState("")

    // Function to poll for execution data  
    const pollForExecutionData = async (executionId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_CREW_SERVER}/get_results/${executionId}`,
                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );


            const executionData = response.data;
            console.log(executionData);
            // TODO: on reponse null auto restart
            // if (executionData === null && pathname?.includes("testagent")) {
            //     setMessages([])
            //     startExecution()
            // }
            setStatus(executionData.status);
            setLogs(executionData.logs);

            if (executionData.status === 'PENDING') {
                setTimeout(() => pollForExecutionData(executionId), 2000);
            } else if (executionData.status === 'WAITING_FOR_INPUT') {
                setUserInputModal(true)
                await getInput(executionId);
                // setMessages([...messages, { isUser: false, text: 'Waiting for user input...' }]); 
                return;
            }

            else {
                setMessages([...messages, { isUser: false, text: executionData.result.result.final_output }]);
                setFinal(executionData.result.result.final_output)
            }
        } catch (error) {
            setErrorMessage("Something went wrong, plese try aging in a while")
            console.error('Error polling execution data:', error);
        }
    };

    // Function to handle user input for WAITING_FOR_INPUT status  
    const provideInput = async (signal) => {
        setUserInputModal(false)
        try {
            await axios.post(
                `${process.env.REACT_APP_CREW_SERVER}/provide_input/${executionId}`,
                { input: userInput, "signal": signal },
                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );
            setUserInput('');
            setFinal("")
            // setMessages([...messages, { isUser: true, text: userInput }]);  
            setStatus("PENDING")

            pollForExecutionData(executionId); // Resume polling after providing input  
        } catch (error) {
            console.error('Error providing input:', error);
        }
    };

    const getInput = async (executionId) => {
        console.log(executionId)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_CREW_SERVER}/provide_input/${executionId}`,

                { headers: { 'Content-Type': 'application/json', 'accept': 'application/json' } }
            );
            setFinal(response.data.status)
            console.log(response.data.status)
        } catch (error) {
            console.error('Error providing input:', error);
        }
    };


    // Function to handle message submission  
    const handleSubmit = async () => {
        if (!userInput.trim()) return;
        setMessages([])
        if (status !== "WAITING_FOR_INPUT")
            await startExecution();

    };


    const chatContainerRef = useRef(null);
    const handleChatScroll = () => {
        chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight + 100,
            behavior: 'smooth',
        });
    };


    useEffect(() => {
        handleChatScroll();
    }, [history]);

    const appendHistory = (role, content) => {
        let data = [{ "role": role, "content": content }]
        console.log(history)
        const updatedHistory = history.concat(data)
        console.log(updatedHistory)
        setHistory(updatedHistory)
    }

    const [userInputModal, setUserInputModal] = useState(false)

    const clearState = () => {
        setMessages([]);
        setErrorMessage("");
        setFinal("");
        setUserInput("");
        setLogs("");
    }
    return (
        <>
            <div className="chat-content disable-scrollbars d-flex flex-column custom-scrollbar" ref={chatContainerRef}>
                {
                    <div className="chat-window  disable-scrollbars custom-scrollbar" style={darkMode ? { backgroundColor: "#e1e7f9", height: "100%", maxHeight: "100%" } : {}}>
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={message.isUser ? 'user-message' : 'bot-message'}
                                style={{ color: 'white', textAlign: `${message.isUse ? "right" : "left"}` }}
                            >
                                {message.text}
                            </div>
                        ))}

                        {status && <div style={{ color: "blue" }}>STATUS: {status}</div>}
                        <Nav className="keys-nav  disable-scrollbars custom-scrollbar" style={{ color: 'black', maxHeight: "500px" }}>
                            <pre className="fw-bold" style={{ fontSize: "14px", fontFamily: "Calibri" }}>{logs}</pre>
                        </Nav>

                        {errorMessage.length > 1 &&
                            <>
                                <h4 className="text-left fs-6 text-danger">
                                    {errorMessage}
                                </h4>
                                <button className="btn btn-primary" style={{ minWidth: "100px" }} onClick={clearState}>
                                    No worries, Retry
                                </button>
                            </>
                        }

                    </div>
                }
            </div >



            {/*---------- Footer --------------------*/}
            {

                <div className="h-25 w-100 chat-achain-dock">
                    <div className="chat-input" style={{ maxHeight: "70%" }}>

                        <div className="chat-input-container position-relative" style={{ display: 'flex', flexDirection: 'row', alignItems: "center", backgroundColor: "#fff" }}>
                            {/* <img src={PinkBtnGif} className="position-absolute" style={{ height: '77.01px', width: '77.01px', overflow: 'visible', left: 'calc(50% - 38px)', top: '-80%' }}></img> */}
                            <textarea
                                rows="1"
                                id="chatLLMInput"
                                type="text"
                                placeholder="Type your message..."
                                className="chat-input-box mr-4"
                                value={userInput}
                                onChange={e => handleInputChange(e)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit()
                                    }
                                }}
                                disabled={sendDisabled} style={{ overflowY: 'scroll', width: "85%", border: "none", background: "transparent", backgroundColor: "none", boxShadow: "none", maxHeight: '60px', height: "50px", padding: "1%", borderRadius: "6px" }} />


                            <div className=" chatbot-text-overlay-pos ">

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: "10px" }} className="w-50">

                                    <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#000', fontSize: '160%', marginLeft: '7px', marginTop: "11px" }}>
                                        <i className="fa-solid fa-microphone"></i>
                                    </label>
                                </div>

                                {!sendDisabled ?
                                    <img className="fa fa-paper-plane chat-send-icon w-50" aria-hidden="true" alt='send' src={sendchat} onClick={() => { handleSubmit() }}></img>
                                    :
                                    <img className="fa fa-paper-plane chat-send-icon" aria-hidden="true" src={sendchat} alt='test'></img>
                                }

                            </div>
                        </div>
                    </div>
                </div>



            }

            {/*---------- Footer --------------------*/}

            <UserInputModal show={userInputModal} setShow={setUserInputModal} provideInput={provideInput} handleInputChange={handleInputChange} status={status} userInput={userInput} final={final} />

        </>
    )


}



export function UserInputModal({ show, setShow, provideInput, handleInputChange, status, userInput, final = "" }) {
    const { darkMode } = useContext(AuthContext);
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            centered
            backdrop="static"
            keyboard={false}
            style={{ maxHeight: "600px", height: "600px", zIndex: "99999999999999999999", minWidth: "" }}
            className={darkMode ? "alchemModalDark" : "alchemModal"}
        >
            <Modal.Header className="border-bottom">
                <Modal.Title>Feedback On Last Response</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column justify-cotent-center align-items-center gap-1">

                    <pre className="custom-scrollbar" style={{ color: "#cfcfd0", width: "600px", maxHeight: "300px" }}>
                        {final?.replaceAll("**", "")}
                    </pre>
                    {/* <h4 className="text-capitalize">
                        yes- go ahead, no - ask feedback again
                    </h4> */}
                    <input
                        type="text"
                        placeholder="Enter Your Feed Back here"
                        value={userInput}
                        onChange={handleInputChange}
                        // disabled={status !== 'WAITING_FOR_INPUT'}
                        style={{ marginBottom: '10px', padding: '5px', width: '80%', minHeight: "50px", border: '1px solid #ccc', borderRadius: '4px' }}
                    />



                </div>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={() => provideInput(0)} disabled={status !== 'WAITING_FOR_INPUT'} style={{ minWidth: "100px" }} >
                    No
                </Button>
                <Button variant="primary" onClick={() => provideInput(1)} disabled={status !== 'WAITING_FOR_INPUT'} style={{ minWidth: "100px" }}>
                    Yes
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

