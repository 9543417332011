import React, { useContext, useState } from 'react'
import { Col } from 'react-bootstrap'
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from 'react-icons/bs'
import { DockContext } from '../../../DockWrapper/DockContext'
import { AuthContext } from '../../../Auth/AuthContext'
import Collapse from '../../../utilities/Collapse'
import { useNavigate } from 'react-router-dom'

export default function ModelSelection({ setTitle, data, setData }) {

    const {
        nodeIndex,
        setIsAgentDocked,
        agentdockSize,
        setAgentContentSize,
        isAgentDocked,
        setIsDraggable,
        isDraggable,
        activity_master_json
    } = useContext(DockContext)
    const { darkMode, IDToken, accessToken } = useContext(AuthContext)

    const toggleDock = () => {
        setIsAgentDocked(!isAgentDocked);
        setIsDraggable(!isDraggable);
        if (isAgentDocked) {
            setAgentContentSize(100)
        }
        else {
            setAgentContentSize(100 - agentdockSize * 100)
        }
    };
    const [allDone, setAllDone] = useState(false)

    const handleSaveAndMoveToNext = () => {
        let updatedData = []
        let item_index = 0

        data.map((item, index) => {
            let obj = { ...item }
            if (obj.isActive) {
                obj.isActive = false
                obj.isCompleted = true
                item_index = index
            }
            if (index === item_index + 1 && !obj.isCompleted) {
                obj.isActive = true
            }
            updatedData.push(obj)
        })

        setAllDone(!updatedData.find((item) => (item.isCompleted === false)))
        setData(updatedData)

    }

    const element = {
        title:
            <div className='w-100 text-black d-flex flex-row justify-content-between align-items-center bg-white p-2'>
                <div className='d-flex flex-column '>

                    <span className='m-auto'>
                        Model Name
                    </span>
                    <h4 className='m-0'>
                        EDA
                    </h4>

                </div>

                <div className='d-flex flex-row gap-2' >
                    <a className='text-danger'>
                        <i className="fa-regular fa-trash-can m-auto"></i>
                        Remove
                    </a>
                    <i className="fa-solid fa-angle-down m-auto"></i>
                </div>
            </div>
        ,
        description: <div className='bg-white d-flex text-dark flex-column justify-content-between gap-2' >
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                    <span className='fw-bold'>
                        Base Model
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>

                <div className='d-flex flex-column'>

                    <span className='fw-bold'>
                        Date Updated
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>

                <div className='d-flex flex-column'>

                    <span className='fw-bold'>
                        Domain
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>

                <div className='d-flex flex-column'>

                    <span className='fw-bold'>
                        Task
                    </span>
                    <span>
                        Version 1.0.1
                    </span>

                </div>
            </div>

            <div>
                <span className='fw-bold'>Model Description</span>
                <p>
                    fndkfosihafodsjfjsdjifsdjfosdijofsajfosjfdsahn
                </p>

            </div>
        </div>
    }
    const navigate = useNavigate()
    const handleRedirect = () => {
        navigate("/smesummary")
    }

    return (
        <div className="h-100 px-0 text-whte" style={{ overflow: "-moz-hidden-unscrollable" }}>

            <div className="d-flex flex-column  align-items-start mx-0" style={{ overflow: "hidden" }}>
                <Col className="border-top border-bottom w-100 p-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className='p-2  border-end'>

                            Recommended Models (02)
                        </span>


                        <button className="btn d-inline-flex border-start border-end" onClick={toggleDock}>
                            {isAgentDocked ? (
                                <BsBoxArrowDownLeft style={{ color: "fff" }} />
                            ) : (
                                <BsBoxArrowUpRight style={{ color: "fff" }} />
                            )}
                        </button>

                    </div>
                </Col>


                <Col>

                    <Collapse title={element.title} description={element.description} id={"modelSelection"} />
                    <Collapse title={element.title} description={element.description} id={"modelSelection1"} />
                    <Collapse title={element.title} description={element.description} id={"modelSelection2"} />
                    <Collapse title={element.title} description={element.description} id={"modelSelection3"} />

                </Col>




            </div >
            <div className='d-flex p-2'>
                {
                    !allDone ?
                        <button className='btn btn-primary align-self-end m-auto' onClick={handleSaveAndMoveToNext}>
                            SAVE AND GO TO NEXT ACTIVITY
                        </button>
                        :
                        <button className='btn btn-primary align-self-end m-auto' onClick={handleRedirect}>
                            SAVE AND GO TO SUMMARY
                        </button>

                }

            </div>
        </div >
    )
}
