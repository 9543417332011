import React, { useState , useEffect} from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaToggleOn } from "react-icons/fa";
import { FaToggleOff } from "react-icons/fa6";
import {} from "react-router-dom";
const ActivityModal = ({ showModal, closeModal, darkMode, HandleToggle }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    radio: ""
  });
  const [radio, setRadio] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [name, setName] = useState("")
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    modelName: "",
    description: "",
    purpose: "",
    domain: "Digital",
    task: ""
  });
  const handleDropdownChange = event => {
    const { name, value } = event.target;
    setSelectedOption(value);
    setShowDropdown(value === "yes");
    setRadio({
      ...radio,
      [name]: value
    });
  };

  const handleOptionChange = event => {
    setName(event.target.value);
  };
  const arr = [];

  arr.push(isChecked, formData);

  const handleSubmit = () => {
    // if (
    //   arr[1].modelName.trim() === "" ||
    //   arr[1].description.trim() === "" ||
    //   arr[1].purpose.trim() === "" ||
    //   arr[1].task === ""
    // ) {
    //   // Alert the user or handle the scenario where required fields are missing
    //   alert("Please fill in all required fields." + formData);
    //   return; // Stop further execution
    // }
    // console.log("form data: ", arr[1]);
    // localStorage.setItem("name", arr[1].modelName);
    // localStorage.setItem("description", arr[1].description);
    // localStorage.setItem("purpose", arr[1].purpose);
    // localStorage.setItem("usecase", arr[1].domain);
    // localStorage.setItem("task", arr[1].task);
    console.log(name)
    
    closeModal();
    navigate("/AChain")
  };
  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name])
  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className={darkMode ? 'alchemModalDark' : 'alchemModal'}
      >
        <Modal.Header>
          <Modal.Title className="modalHead">Synthesize Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p className="modalHead">Configure Model</p> */}
          {/* <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> */}
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Name
            </label>
            <input
              type="text"
              class="form-control border border-primary"
              id="exampleInputPassword1"
              name="modelName"
              onChange={handleOptionChange}
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Description
            </label>
            <textarea
             
              class="form-control border border-primary"
              id="exampleInputPassword1"
              name="modelName"
              rows='3'
              cols='10'
              onChange={handleOptionChange}
            ></textarea>
          </div>
          
          
          
          
         
        </Modal.Body>

        <Modal.Footer id="footer">
          <a
           className={darkMode ? 'btn btn-primary cancelBtnDark' : 'btn btn-primary cancelBtnDark'}
            onClick={closeModal}
            role="button"
          >
            Cancel
          </a>
          <a className={darkMode ? 'btn btn-primary submitBtnDark' : 'btn btn-primary submitBtnDark'} onClick={handleSubmit} role="button">
            Proceed
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActivityModal;
