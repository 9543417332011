import axios from "axios";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import logoBlue from "../../../assets/img/logoBlueLite.png";
import logoWhite from "../../../assets/img/logoWhite.svg";
import { AuthContext } from "../../Auth/AuthContext";
import "./Login.css";

export default function Login() {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const {
    setToken,
    accessToken,
    setAccessToken,
    setRefreshToken,
    setIDToken,
    darkMode
  } = useContext(AuthContext);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsError(false)
    const token = await loginUser({
      userName,
      password,
      setToken
    });
    console.log(token);
  };
  async function loginUser(credentials) {
    console.log(credentials);
    const headers = {
      "Content-Type": "application/json"
    };
    const payload = {
      username: credentials.userName, //Add credentials.email while working with actual data
      password: credentials.password //Add credentials.password while working with actual data
    };
    axios
      .post(`${process.env.REACT_APP_AUTHENTICATE_SERVER}/token`, payload, {
        headers
      })
      .then(response => {
        const { access_token, refresh_token, id_token } = response.data;
        setAccessToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        console.log(accessToken);
        if (response.data.access_token) {
          credentials.setToken(response.data.access_token);
          console.log(accessToken, "asdasd");
          document.cookie = `accessToken=${access_token}`;
          document.cookie = `refreshToken=${refresh_token}`;
          document.cookie = `IDToken=${id_token}`;
          setIDToken(response.data.id_token);
        }
      })
      .catch(error => {
        setIsError(true)
        if (error.response) {

          console.log(error.response);
          console.log("server responded");
          setErrorMessage(`${error.response.data}`)
          if (error.response.status == 401) {
            setErrorMessage("Email or Password is incorrect, please try agian")
          }
          credentials.setToken(false);
        } else if (error.request) {

          setErrorMessage("Network error, please try agian")
          console.log("network error");
        } else {
          setErrorMessage("something went wrong, please try agian")
          console.log(error);
        }
        setPassword("")
      });
    return "User Logged in";
  }

  return (
    <div className="w-100 h-100 overflow-hidden">
      <section className="p-0">
        <div className="container-fluid px-0">
          <div
            className="card"
            style={{ height: "auto", border: darkMode ? "none" : "" }}
          >
            <div className="row g-0">
              <div
                className={`col-12 col-md-6 text-bg-primary ${darkMode ? "login-image-dark" : "login-image"
                  }`}
              >

                <img
                  className="img-fluid app-logo"
                  loading="lazy"
                  src={logoWhite}
                  alt=""
                />
                <div className="d-flex align-items-center justify-content-center">
                  <div className="col-10 col-xl-8 py-3 text-center">
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-6"
                style={{
                  background: darkMode
                    ? "transparent radial-gradient(closest-side at 43% 71%, #253266 0%, #212542 100%) 0% 0% no-repeat"
                    : "",
                  border: darkMode ? "none" : ""
                }}
              >
                <div className="card-body p-3 p-md-4 p-xl-5 login-pad">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <h3
                          className=""
                          style={{
                            color: darkMode ? "#fff" : "",
                            fontSize: "36px !important",
                            fontFamily: "calibri-bold"
                          }}
                        >
                          Welcome
                        </h3>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="loginForm">
                    <div className="row gy-3 gy-md-4 overflow-hidden">
                      <div className="col-12">
                        <label
                          htmlFor="email"
                          className=""
                          style={{ color: darkMode ? "#fff" : "" }}
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className={`form-control ${darkMode ? "login-form-control-dark" : ""
                            }`}
                          name="email"
                          id="email"
                          onChange={(e) => { setIsError(false); setUserName(e.target.value) }}
                          style={{
                            background: darkMode ? "transparent" : "",
                            border: darkMode ? "1px solid #9CB1E8" : "",
                            color: darkMode ? "#E6E9F3" : ""
                          }}
                          placeholder="name@example.com"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label
                          htmlFor="password"
                          className=""
                          style={{ color: darkMode ? "#fff" : "" }}
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          className={`form-control ${darkMode ? "login-form-control-dark" : ""
                            }`}
                          name="password"
                          id="password"
                          onChange={(e) => { setIsError(false); setPassword(e.target.value) }}
                          value={password}
                          placeholder="Enter your password"
                          required
                          minLength={4}
                          style={{
                            background: darkMode ? "transparent" : "",
                            border: darkMode ? "1px solid #9CB1E8" : "",
                            color: darkMode ? "#E6E9F3" : ""
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="remember_me"
                            id="remember_me"
                            style={{ backgroundColor: "#2561dccf", border: "none" }}
                          />
                          <label
                            className={`form-check-label ${darkMode ? "text-white" : ""
                              }`}
                            htmlFor="remember_me"
                          >
                            Keep me logged in
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-grid">
                          {
                            isError &&
                            <p className="mb-1 text-danger text-capitalize">
                              *{errorMessage}
                            </p>
                          }
                          <button
                            className="btn bsb-btn-xl btn-primary submitBtnDark"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>


                <footer className="copyright copyright-login-screen">
                  Copyright © TCS 2024. All Rights Reserved
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
