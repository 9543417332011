import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls
} from "reactflow";
import "reactflow/dist/style.css";
import Sidebar from "./ChainList";
import "../index.css";
import CreateAgentChainNav from "./CloneChainNav";
import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import NavBar from "../ModelCompetancy/components/NavBar";
import ModelCompetancyNav from "../ModelCompetancy/ModelCompetancyNav";
import { useNavigate } from "react-router-dom";
import LoadModalCompetancy from "../ModelCompetancy/LoadModalCompetancy";
import LoadModal from "../../RecoModule/LoadModal";
import ModelsNav from "../../ModelOrchestrator/FineTunining/ModelsNav";
import Rflow from "../../rflow/Rflow";
import styles from "../Playground2/playground2.module.css";
const initialNodes = [];

// let id = 0;
// const getId = () => `dndnode_${id++}`;

const CloneCompetancy = () => {
  const location = useLocation();
  let final = JSON.parse(localStorage.getItem("competency", {}));

  console.log(final);
  const [competency_data, setcompetency_data] = useState({
    ...location.state.competency
  });
  const [isUpdate, setIsUpdate] = useState();
  const [chainId, setChainId] = useState(
    "2e249e32-17c6-410f-a64c-766225d05b65"
  );

  // New Items
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");

  // const [agents, setAgents] = useState([]);
  const navigate = useNavigate();
  const handleSave = e => {
    e.preventDefault();
    console.log(competency_data);
    axios
      .post(`${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/competencies/`, final)
      .then(res => {
        console.log(res.data.id);
        navigate("competency/testCompetency" + res.data.id);
        // alert(competency_data,res)
      })
      .catch(err => {
        console.error(err);
        // alert(competency_data,err)
      });
    // navigate("/agentfactory/playground/b76a5735-2f5c-4eaa-8e3e-60ad51d282ab");
    navigate("/competency/testCompetency");
  };

  const handleUpdate = e => {
    e.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/competencies/${competency_data.id}`,
        competency_data
      )
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (true) {
      axios
        .get(
          `${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/chains/` +
            "2e249e32-17c6-410f-a64c-766225d05b65"
        )
        .then(response => {
          // setchain(response.data.chain_payload);
          console.log(response.data.chain_payload);
          setNodes(response.data.chain_payload.nodes);
          setEdges(response.data.chain_payload.edges);
          setName(response.data.chain_payload.name);
          setPurpose(response.data.chain_payload.purpose);
        });
    }
    if (location.state.isUpdate) {
      openModal();
    }
  }, []);

  // useEffect(() => {
  //     const fetchAgents = async () => {
  //       try {
  //         const response = await fetch('http://74.235.164.4:8082/api/chains/');
  //         if (response.ok) {
  //           const data = await response.json();

  //           localStorage.setItem('test1', JSON.stringify( data.chains[0].chain_payload))
  //           setAgents(data.chains); // Assuming data is an array of agents
  //         } else {
  //           throw new Error('Failed to fetch data');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching agents:', error);
  //       }
  //     };

  //     fetchAgents();
  //   }, []);
  const onConnect = useCallback(
    params => {
      const existingEdge = edges.filter(edge => edge.source == params.source);
      if (existingEdge.length == 0) {
        setEdges(eds => addEdge(params, eds));
      } else {
        alert("Node already connected to another node");
      }
    },
    console.log("edges:", edges),

    []
  );

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    // console.log(event.dataTransfer)
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      const agent_id = event.dataTransfer.getData("id");
      if (competency_data.agent_chain_mapping == "") {
        setcompetency_data({
          ...competency_data,
          agent_chain_mapping: agent_id
        });
      }
      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });
      const newNode = {
        id: agent_id,
        type: "textupdater",
        position: { x: 200, y: 200 },
        data: { label: `${type}`, name: "ADRE" },
        style: {
          color: "#333", // Dark gray for text
          borderColor: "#666", // A slightly darker gray for borders
          background: "#f5f5f5", // Light gray for background
          width: 300
        }
      };
      setNodes(nds => nds.concat(newNode));
      console.log(reactFlowInstance);
    },
    [reactFlowInstance]
  );

  var flow = {
    nodes: nodes,
    edges: edges,
    name: name,
    purpose: purpose,
    id: chainId
  };
  const upload = () => {
    flow = JSON.parse(localStorage.getItem("test"));

    if (flow) {
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setName(flow.name || "");
      setPurpose(flow.purpose || "");
      setChainId(flow.setChainId || "");
    }
  };
  const isDeleting = "Yes" ? "Delete" : "";

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handlePurposeChange = event => {
    setPurpose(event.target.value);
  };

  // const saveChain = async () => {
  //   const connectedNodes = nodes.filter(node => edges.some(edge => edge.source === node.id.toString() || edge.target === node.id.toString()))
  //   if(connectedNodes.length < nodes.length){
  //     alert('please connect node')
  //   }else{

  //   // const flow = { name, purpose };
  //   const edges = flow.edges;
  //   const edgesList = [];

  //   // Iterate through edges and store source and target in the list
  //   edges.forEach((edge, index) => {
  //     const sourceNode = edge.source;
  //     const targetNode = edge.target;
  //     if (index === 0) {
  //       edgesList.push(sourceNode);
  //       // edgesList.push(targetNode)
  //     }
  //     edgesList.push(targetNode);

  //   });
  //   console.log(edgesList)
  //   flow['agent'] = edgesList
  //   localStorage.setItem('test', JSON.stringify(flow));

  //   fetch('http://74.235.164.4:8082/api/chains/?user_id=1', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // Add any other headers as needed
  //     },
  //     body: JSON.stringify(flow), // Convert data to JSON string
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Handle the response data
  //       console.log('Response:', data);
  //     })
  //     .catch((error) => {
  //       // Handle errors
  //       console.error('Error:', error);
  //     });
  //   alert('done chain');
  //   }
  // }
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  // useEffect(()=>{ openModal();},[])
  const closeModal = () => {
    setShowModal(false);
  };
  const content2 = `Alchemist automatically recommends Adverse Drug Relations and Event sub tasks in the form of purposive agent chains`;

  return (
    <>
      <div className="inheritBg ">
        {/* <AlchemNav/> */}
        <ModelsNav />
        <ModelCompetancyNav index={2} />
        {/* <LoadModalCompetancy
          // showModal={showModal}
          closeModal={closeModal}
          content={content2}
        /> */}

        {/* <LoadModal showModal={showModal} closeModal={closeModal} content={"modalContent"} navLink={"/agentfactory/createAgent"} /> */}
        <div className="mx-5 px-5">
          <div className={"container mt-3 px-0 " + styles.contNegMarg}></div>
          {/* <div className={"container-fluid "+styles.screenTitleNButton}
          >
            <span className={"navbar-brand "+styles.screenTitle}>Test Competency</span>
                        <div class="line me-3"></div>
                        <span className={"navbar-brand "+styles.screenTitle}>Competency Name</span>
            <div className="content-btns">
              <Link to="/competency">
                <button className="btn-secoundary close">Close</button>
              </Link>
              {isUpdate ? (
                <button className="btn-primary save" onClick={handleSave}>
                  Save & Proceed
                </button>
              ) : (
                <button className="btn-primary save" onClick={handleSave}>
                  Save & Proceed
                </button>
              )}
            </div>
          </div> */}
          <nav className={"navbar navbar-expand-lg p-0 " + styles.negMarg}>
            <div className={"container-fluid "}>
              <span className={"navbar-brand " + styles.screenTitle}>
                Add Agent Chain
              </span>
              <div class="line me-3"></div>
              <span className={"navbar-brand " + styles.screenTitle}>
                Knowledge Work Name
              </span>
              <div className={styles.i}>i</div>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="navbar-nav ms-auto w-100 d-flex justify-content-end">
                  <Link
                    class="btn btn-primary cancelBtn me-3"
                    role="button"
                    to={"/competency"}
                  >
                    Close
                  </Link>
                  <a
                    class="btn btn-primary submitBtn me-3"
                    href="#"
                    role="button"
                  >
                    Save
                  </a>
                  <div class="line me-3"></div>
                  <Link
                    class="btn btn-primary cancelBtn me-3"
                    to={"../"}
                    role="button"
                  >
                    Back
                  </Link>
                  <a
                    class="btn btn-primary submitBtn"
                    href="#"
                    role="button"
                    onClick={handleSave}
                  >
                    Proceed
                  </a>
                </div>
              </div>
            </div>
          </nav>

          <div className="row competencey-play">
            <div className="dndflow mb-4">
              <ReactFlowProvider>
                <div className="reactflow-wrapper pt-2">
                  <Rflow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    proOptions={{ hideAttribution: true }}
                    fitView
                    deleteKeyCode={isDeleting}
                    pannelText="(Drag and Drop the Agent Chains from the left panel)"
                  ></Rflow>
                  {/* <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    proOptions={{ hideAttribution: true }}
                    fitView
                    deleteKeyCode={isDeleting}
                    className="react-flow__edge animated"
                  >
                    <Controls />
                  </ReactFlow> */}
                </div>
                <Sidebar />
              </ReactFlowProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloneCompetancy;
