import React, { useCallback, useEffect, memo } from 'react';
import ReactFlow, { ReactFlowProvider, Controls, useReactFlow, ControlButton, useNodesState, useEdgesState, addEdge, Panel, MarkerType } from 'reactflow';
import { useMemo, useState } from 'react';

import 'reactflow/dist/style.css';
import Cnode from './Cnode';
import { height } from '@mui/system';
import PlusIcon from '../../assets/img/plusIcon.svg'
import MinusIcon from '../../assets/img/minusIcon.svg'
import { Col } from 'react-bootstrap';

// return <ReactFlow nodeTypes={nodeTypes} />;

function Rflow(props) {
  // const [edges, setEdges] = useState([])
  const initialEdges = [];
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isCol, setIsCol] = useState(true)

  function handleState(val) {
    // val = isCol;
    setIsCol(!isCol);
    console.log('hi' + val);
  }
  const onConnect = useCallback(
    params => {
      const existingEdge = edges.filter(edge => edge.source == params.source);
      if (existingEdge.length == 0) {
        setEdges(eds => {
          var tempEdges = addEdge(params, eds).map(e => {
            e['type'] = 'smoothstep';
            e['markerEnd'] = {
              type: MarkerType.ArrowClosed,
            }
            return e
          })
          return tempEdges;
        });

      } else {
        alert("Node already connected to another node");
      }
    },
    // console.log("edges:", edges),

    []
  );
  const initialNodes = [
    {
      id: '1', type: 'textUpdater', position: { x: 0, y: 0 }, change: "True", data: { label: '1', name: 'Chain 1', isChild: 'false', subnodes: { label: '1', name: 'Agent 1' }, }, style: {
        width: 300,
        // height: 100
      },
    },
    {
      id: '2', type: 'textUpdater', position: { x: 200, y: 400 }, change: "True", data: { label: '2', name: 'Chain 2', subnodes: { label: '2', name: 'Agent 2' }, }, style: {
        // width: 300,
        // height: 140
      },
    },
    {
      id: '3', type: 'textUpdater', position: { x: 200, y: 400 }, change: "True", data: { label: '3', name: 'Chain 2', subnodes: { label: '2', name: 'Agent 2' }, }, style: {
        // width: 300,
        // height: 140
      },
    },
    //   { id: '2', position: { x: 70, y: 70}, data: { label: '2' }, parentNode: '1', extent: 'parent', style: {}  },
  ];
 

  useEffect(() => {
    console.log(isCol, '*************************************');
  }, [isCol])
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  // const onDragOver = useCallback(event => {
  //     event.preventDefault();
  //     event.dataTransfer.dropEffect = "move";
  //     // console.log(event.dataTransfer)
  // }, []);
  const nodeTypes = useMemo(() => ({ textUpdater: Cnode }), []);
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
 
  var flow = {};
  var newNode = {}
  const onDrop = () => {
    flow = JSON.parse(localStorage.getItem("test"));
    if (flow) {
      console.log(flow[0].chain_name, '%%%%%%%%%%%%%%%%%%5')
      console.log(flow[0].chain_payload.nodes, '%%%%%%%%%%%%%%%%%%5')
      console.log(flow[0].chain_payload.edges, '%%%%%%%%%%%%%%%%%%5')
      newNode = {
        id: '156',
        type: 'textUpdater',
        position: { x: 100, y: 100 },
        data: { label: '3', name: flow[0].chain_name, isChild: 'false', subnodes: flow[0].chain_payload.nodes, subedges: flow[0].chain_payload.edges, nodeColapse: nodeColapse },
        style: { width: 400 }
      }

      setNodes([...nodes, newNode] || []);
      // setEdges(flow.edges || []);
    }

    var stepedges = edges?.map(e => {
      e['type'] = 'smoothstep';
      e['markerEnd'] = {
        type: MarkerType.ArrowClosed,
      }
      return e;
    })
    setEdges(stepedges)
    // onEdgesChange(() => setEdges(stepedges))
  }
  const { setViewport, zoomIn, zoomOut } = useReactFlow();
  const nodeColapse = (id, bool) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id && bool) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          node.style = {
            ...node.style,
            width: 250,
          };
        }
        else if(node.id === id){
          {
            // it's important that you create a new object here
            // in order to notify react flow about the change
            node.style = {
              ...node.style,
              width: 400,
            };
          }
        }

        return node;
      })
    );
  }
  return (
    <>
      {/* <div style={{ width: '67vw', height: '90vh' }}> */}
      {/* {isCol? '': ""} */}
      <ReactFlowProvider>
        {/* <div className="reactflow-wrapper"> */}
        <ReactFlow
          // onClick={handleState}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          draggable={true}
          onInit={props.onInit}
          onDrop={onDrop}
          onDragOver={props.onDragOver}
          // fitView
          className='react-flow__edge animated competency-flow'>
          <Panel><div className='pannelText'><i>{props.pannelText}</i></div></Panel>
          <Controls id='flow-cont' position='bottom-right'
            showZoom={true}
            showFitView={true}
            showInteractive={false}
            className='zoomTransform row'
          >
            {/* <Col className='p-0 m-0 flowControlPlus'>
              <ControlButton onClick={() => zoomIn({ duration: 8 })} className='col '>
                <img src={PlusIcon} onClick={() => zoomOut({ duration: 8 })}></img>
              </ControlButton>
            </Col>
            <Col className='p-0 m-0'>
              <ControlButton onClick={() => zoomOut({ duration: 8 })} className='col '>
                <img src={MinusIcon} onClick={() => {zoomOut({ duration: 8}); console.log('plus')}}></img>
              </ControlButton>
            </Col> */}
          </Controls>
        </ReactFlow>
        {/* </div> */}
        {/* <ControlButton onClick={() => console.log(nodes)}>
          <img src={MinusIcon}></img>
        </ControlButton> */}
      </ReactFlowProvider>
      {/* </div> */}
    </>
  );
}

export default (Rflow);