// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-bars {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 48%;
  min-width: 481px;
  min-height: 131px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  opacity: 1;
  padding: 2%;
  align-items: flex-start;
}
.Bar {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.Bar p {
  text-align: left;
  font: normal normal normal 14px/17px Calibri;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
}
.horizontal p {
  width: 135px;
  height: 19px;
  /* UI Properties */
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-family: Calibri;

  /* font: normal normal normal 16px/24px Calibri; */
  letter-spacing: 0.67px;
  color: #77768c;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/graph/HorizontalBars/HorizontalBars.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,+CAA+C;EAC/C,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,WAAW;EACX,SAAS;EACT,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,4CAA4C;EAC5C,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;AACA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;;EAEpB,kDAAkD;EAClD,sBAAsB;EACtB,cAAc;EACd,UAAU;AACZ","sourcesContent":[".horizontal-bars {\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n  width: 48%;\n  min-width: 481px;\n  min-height: 131px;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 1px solid #dfe1e5;\n  border-radius: 4px;\n  opacity: 1;\n  padding: 2%;\n  align-items: flex-start;\n}\n.Bar {\n  display: flex;\n  width: 100%;\n  gap: 1rem;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.Bar p {\n  text-align: left;\n  font: normal normal normal 14px/17px Calibri;\n  letter-spacing: 0px;\n  color: #888888;\n  opacity: 1;\n}\n.horizontal p {\n  width: 135px;\n  height: 19px;\n  /* UI Properties */\n  text-align: left;\n  font-size: 16px;\n  font-weight: normal;\n  font-family: Calibri;\n\n  /* font: normal normal normal 16px/24px Calibri; */\n  letter-spacing: 0.67px;\n  color: #77768c;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
