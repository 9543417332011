import React, { useContext, useEffect, useState } from "react";
import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import "./FineTuning.css";
import "./RewardAndPFO.css";
import { RewardSideBarNames, RewardTableData, TableColumn } from "./DB";
import CreateAgentNav from "../../AgentModule/CreateAgent/CreateAgentNav";
import ModelOrchestratorNav from "./ModelOrchestratorNav";
import StepsNavbar from "./StepsNavbar";
import { Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import UploadComp from "./UploadComp/UploadComp";
import CustomTable from "../../Table/CustomTable";
import ModelsNav from "./ModelsNav";
// import LoadModal1 from "./LoadModal";
import ModelRewardNav from "./ModelRewardNav";
import RewardModelProgress from "../RewardModelProgress";
import checkimg from "../../../assets/img/check.png";
import darkCheckImg from "../../../assets/img/darkCheckImg.svg";
import axios from "axios";
import download from "../../../assets/img/download.png";
import RewardTemplate from "../../../assets/templates/Reward_Model_data_Template.csv";
import { AuthContext } from "../../Auth/AuthContext";
import { MdOutlineFileDownload } from "react-icons/md";
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import AlchemProgressBar from "../../SharedComponents/AlchemProgressBar/AlchemProgressBar";
// import LoadModal from "../../RecoModule/LoadModal";
// import LoadModal from "../../RecoModule/LoadModal";

function RewardModel({ handleToggle }) {
  const { darkMode, accessToken } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [index, setindex] = useState(1);
  const [TableDataLocal, setTableDataLocal] = useState(RewardTableData);
  const [updateDataJson, setupdateDataJson] = useState({});
  const uploaddone = () => {
    proceed();
  };
  const navigate = useNavigate();
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const trainmodelfun = () => {};
  useEffect(() => {
    // openModal();
  }, []);

  const updateData = (data_index, column, type, value, key) => {
    if (
      type === "text" ||
      type === "number" ||
      type === "select" ||
      type === "radio"
    ) {
      let data = TableDataLocal;
      let tempupdateDate = updateDataJson;
      tempupdateDate[key] = value;
      setupdateDataJson(tempupdateDate);
      data[index - 1][data_index][column]["value"] = value;
      setTableDataLocal([...data]);
    }
  };

  useEffect(() => {
    if (index === 5) {
      navigate("/rewardppo");
      console.log("Index is 5 and navigate to rewardppo");
    } else if (index === 4) {
      console.log(updateDataJson);
    }
  }, [index]);

  const proceed = () => {
    if (index <= 4) setindex(index + 1);
    console.log(index);
  };

  const back = () => {
    if (index >= 2) setindex(index - 1);
    console.log(index);
  };
  const savehyper = () => {
    const headers = { 
      "Content-Type": "application/json",
      Authorization: accessToken
    };
    console.log(updateDataJson);
    axios
      .post(
        `${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/hyperparameters`,
        JSON.stringify({
          hyperparameter: {
            // [updateDataJson]
          }
        }),
        { headers }
      )
      .then(res => {
        localStorage.setItem(
          "rewardhyperparameter_id",
          JSON.stringify(res.data.id)
        );
        console.log(res.data.id);
      });
  };

  const FineTuningstart = () => {
    savehyper();
    const headers = { "Content-Type": "application/json" };
    console.log(updateDataJson);
    // axios
    //   .post(
    //     "http://172.208.104.191:5002/start_reward_training",
    //     JSON.stringify({
    //       basemodelid: 5,
    //       name: localStorage.getItem("name"),
    //       task: 1,
    //       reward_model_src:
    //         "/data/Model_Orchestrator_Datasets/20231124124712_reward_model_demo.csv",
    //       hyperparamsid: 4,
    //       dataset_id: 5
    //     }),
    //     { headers }
    //   )
    //   .then(res => {
    //     console.log(res.data);
    //     proceed();
    //   });

    proceed();
  };

  // const [showModal1, setShowModal1] = useState(true);
  // const openModal1 = () => {
  //   setShowModal(true);
  // };
  // const closeModal1 = () => {
  //   setShowModal(true);
  //   setShowModal1(false);
  // };
  // const content = `We recommend the the integration of advanced machine learning techniques like Parameter-Efficient Fine-Tuning (PEFT), Low-Rank Adaptation (LoRA), and Data and Model Parallelism to significantly enhance the detection and analysis of Adverse
  // Drug Reaction (ADR) events.
  // When combined, these techniques provide a robust framework for ADR event prediction and analysis:
  // PEFT ensures that even complex models are accessible and manageable in various healthcare settings. Reduced computational requirements mean that advanced ADR prediction models can be deployed more widely, even in resource-constrained healthcare settings.
  // LoRA allows for precise and efficient adaptation of these models to the specific requirements of ADR detection. Faster adaptation to specific ADR datasets means quicker deployment in clinical settings. This could result in a 15% reduction in the time required to update and deploy models in response to new drugs or emerging ADR patterns.
  // Data and Model Parallelism ensures that the computational demands of large datasets and models do not hinder performance, allowing for scalable and speedy analysis. By enabling the processing of larger datasets, these techniques can lead to more comprehensive and accurate ADR predictions. For instance, a 25% improvement in the ability to process large datasets could translate to a 10% increase in the accuracy of ADR predictions, potentially preventing thousands of adverse drug events annually.`;
  let title = 'Model Orchestrator'
  let subtitle = `Model Name : ${localStorage.getItem("name")}`
  let steps = ['Select Model','Fine-tuning','Reward & PPO', 'Preview Model']

  return (
    <>
      {/* <LoadModal
        showModal={showModal1}
        closeModal={closeModal1}
        content={content}
        submitModal={closeModal1}
      /> */}
      {/* <LoadModal1 showModal={showModal} closeModal={closeModal} /> */}
      {/* <ModelsNav darkMode={darkMode} handleToggle={handleToggle} /> */}
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
      <div className="finetuning-outer" style={{ gap: "0px" }}>
        <div className="finetuning-head mb-4">
        {/* <ModelRewardNav darkMode={darkMode} /> */}
          <AlchemProgressBar title = {title} subtitle = {subtitle} steps = {steps} index = {3}/>
        </div>
        <div className="finetuning-body pageHeadBg mt-3">
          {/* Left part of reward model shoing the steps - Hiding on training model part */}
          {index >= 4 ? (
            <> </>
          ) : (
            <div className="finetuning-body-left">
              <div
                className={
                  darkMode
                    ? "finetuning-navbar fineTuningDark"
                    : "finetuning-navbar"
                }
                style={{
                  background: darkMode ? "#42466C" : "",
                  border: darkMode ? "none" : ""
                }}
              >
                <div className="reward-navbar-header">
                  <span className="stepperheading">Reward Model</span>
                  <span>
                    Reward Model RLHF &nbsp;
                    <span style={{ paddingLeft: "5rem" }}>
                      <FaEdit className="text-primary" size={"1.1rem"} />
                    </span>
                  </span>
                </div>
                <div className="horizontalline"></div>

                <div className="finetuning-navbar-body">
                  <div class="stepper d-flex flex-column mt-3 ml-2">
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 1
                              ? "rounded-circle py-2 px-3 virticle-active virticle-active-green text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          {index >= 2 ? (
                            <img
                              className="checkmarkimage"
                              src={darkMode ? darkCheckImg : checkimg}
                            ></img>
                          ) : (
                            <>1</>
                          )}
                        </div>
                        <div
                          className={
                            index >= 2
                              ? "line virticle-active h-100"
                              : "line h-100"
                          }
                        ></div>
                      </div>
                      <div>
                        <p
                          class={`${
                            darkMode ? "leadDark" : "lead"
                          } active pb-3`}
                        >
                          Upload Data
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 2
                              ? "rounded-circle py-2 px-3 virticle-active text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          {index >= 3 ? (
                            <img
                              className="checkmarkimage"
                              src={darkMode ? darkCheckImg : checkimg}
                            ></img>
                          ) : (
                            <>2</>
                          )}
                        </div>
                        <div
                          className={
                            index >= 3
                              ? "line virticle-active h-100"
                              : "line h-100"
                          }
                        ></div>
                      </div>
                      <div>
                        <p
                          className={
                            index >= 2
                              ? darkMode
                                ? "leadDark  pb-3 active"
                                : "lead  pb-3 active"
                              : darkMode
                              ? "leadDark  pb-3 "
                              : "lead  pb-3 "
                          }
                        >
                          Model Parameters
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 3
                              ? "rounded-circle py-2 px-3 virticle-active text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          {index >= 4 ? (
                            <img
                              className="checkmarkimage"
                              src={darkMode ? darkCheckImg : checkimg}
                            ></img>
                          ) : (
                            <>3</>
                          )}
                        </div>
                        <div class="line h-100 virticle-active d-none"></div>
                      </div>
                      <div>
                        <p
                          className={
                            index >= 3
                              ? darkMode
                                ? "leadDark  pb-3 active"
                                : "lead  pb-3 active"
                              : darkMode
                              ? "leadDark  pb-3 "
                              : "lead  pb-3 "
                          }
                        >
                          PEFT
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="horizontalline"></div>
                <div className="reward-navbar-header">
                  <span className="stepperheading">PPO Model</span>
                  <span>
                    PPO Model: PPO &nbsp;
                    <span style={{ paddingLeft: "5rem" }}>
                      <FaEdit className="text-primary" size={"1.1rem"} />{" "}
                    </span>
                  </span>
                </div>
                <div className="horizontalline"></div>

                <div className="finetuning-navbar-body">
                  <div class="stepper d-flex flex-column mt-3 ml-2">
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-not-active text-white">
                          1
                        </div>
                        <div class="line h-100 "></div>
                      </div>
                      <div>
                        <p class={`${darkMode ? "leadDark" : "lead"} pb-3`}>
                          Upload Data
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-not-active text-white">
                          2
                        </div>
                        <div class="line h-100"></div>
                      </div>
                      <div>
                        <p class={`${darkMode ? "leadDark" : "lead"} pb-3`}>
                          Model Parameters
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-not-active text-white">
                          3
                        </div>
                        <div class="line h-100"></div>
                      </div>
                      <div>
                        <p class={`${darkMode ? "leadDark" : "lead"} pb-3`}>
                          PEFT Parameters
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-not-active text-white">
                          4
                        </div>
                        <div class="line h-100 virticle-active d-none"></div>
                      </div>
                      <div>
                        <p class={`${darkMode ? "leadDark" : "lead"} pb-3`}>
                          Evaluate Model
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {index === 1 ? (
            <div className="finetuning-body-right">
              <div className="CustomTable-Header">
                <div className="CustomTable-Header-Left">
                  <span
                    className="CustomTable-Header-Left-span text-uppercase border-end pe-3"
                    // style={{ paddingTop: "8px" }}
                  >
                    {RewardSideBarNames[index - 1]}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } border-end px-3`}
                  >
                    Model Name : {localStorage.getItem("name")}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } ps-3`}
                  >
                    SELECTED MODEL : {localStorage.getItem("basemodelname")}
                  </span>
                </div>
                <div className="CustomTable-Header-Right">
                  {index === 3 ? (
                    <button
                      className="submitBtnDark bs-3 ms-3"
                      onClick={FineTuningstart}
                    >
                      Train Model
                    </button>
                  ) : (
                    <button className="submitBtnDark ms-3" onClick={proceed}>
                      Proceed
                    </button>
                  )}
                  {index === 1 ? (
                    <>
                      <button
                        className="cancelBtnDark"
                        onClick={() => navigate("/rewardmodelhome")}
                      >
                        Back
                      </button>
                      {/* <a
                        href={RewardTemplate}
                        download="RewardTemplate"
                        target="_blank"
                        style={{
                          paddingTop: "6px",
                          paddingRight: "15px",
                          fontFamily: "calibri-bold",
                          fontSize: "16px",
                          color: darkMode ? "#BAC9F1" : ""
                        }}
                      >
                        <MdOutlineFileDownload style={{ fontSize: "18px" }} />{" "}
                        Download Template{" "}
                      </a> */}
                    </>
                  ) : (
                    <button className="cancelBtnDark" onClick={back}>
                      Back
                    </button>
                  )}
                </div>
              </div>
              <UploadComp
                uploaddone={uploaddone}
                darkMode={darkMode}
              ></UploadComp>
            </div>
          ) : index === 4 ? (
            <div className="finetuning-body-right w-100 ps-0 pe-0">
              <div className="CustomTable-Header">
                <div className="CustomTable-Header-Left">
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } ps-4 pe-3`}
                  >
                    Training Progress
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } border-end px-3`}
                  >
                    Model Name : {localStorage.getItem("name")}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } ps-3`}
                  >
                    SELECTED MODEL : {localStorage.getItem("basemodelname")}
                  </span>
                </div>
                <div className="CustomTable-Header-Right pe-4">
                  <button className="submitBtnDark ms-3" onClick={proceed}>
                    Proceed
                  </button>
                </div>
              </div>
              <>
                <RewardModelProgress darkMode={darkMode} />{" "}
              </>
            </div>
          ) : (
            <div className="finetuning-body-right">
              <div className="CustomTable-Header">
                <div className="CustomTable-Header-Left">
                  <span
                    className="CustomTable-Header-Left-span text-uppercase"
                    style={{ paddingTop: "8px" }}
                  >
                    {RewardSideBarNames[index - 1]}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } border-end px-3`}
                  >
                    Model Name : {localStorage.getItem("name")}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } ps-3`}
                  >
                    SELECTED MODEL : {localStorage.getItem("basemodelname")}{" "}
                    {/* {selectedModelName}{" "} */}
                  </span>
                </div>
                <div className="CustomTable-Header-Right">
                  {index === 3 ? (
                    <button
                      className="submitBtnDark bs-3 ms-3"
                      onClick={FineTuningstart}
                    >
                      Train Model
                    </button>
                  ) : (
                    <button className="submitBtnDark ms-3" onClick={proceed}>
                      Proceed
                    </button>
                  )}
                  {index === 1 ? (
                    <button
                      className="cancelBtnDark"
                      onClick={() => navigate("/rewardmodelhome")}
                    >
                      Back
                    </button>
                  ) : (
                    <button className="cancelBtnDark" onClick={back}>
                      Back
                    </button>
                  )}
                </div>
              </div>
              <CustomTable
                updateData={updateData}
                TableData={RewardTableData[index - 1]}
                TableColumn={TableColumn[index - 1]}
                darkMode={darkMode}
              ></CustomTable>
            </div>
          )}

          {/* </div> */}
        </div>
        <footer className="copyright copyright-footer">
          Copyright © TCS 2024. All Rights Reserved.
        </footer>
      </div>
    </>
  );
}

export default RewardModel;
