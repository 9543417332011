import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import AgentCard from "./AgentCard/AgentCard";
import AgentChains from "./AgentChains/AgentChains";

import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import NavDropdown from "react-bootstrap/NavDropdown";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";

function Agents({ darkMode, handleToggle }) {
  const [activeTab, setActiveTab] = useState("agent");
  const onTabChange = key => {
    setActiveTab(key);
  };
  return (
    <>
      <Container fluid className="pageHeadBg px-3" >
        <Row className="mx-0 pt-2">
          <Col style={{ padding: 0 }}>
            <div className="p-3">
              {activeTab === "agent" && <span className={darkMode ? "text-white page-title-dark" : "text-dark page-title-dark"}> Agents </span>}
              {activeTab === "agentChain" && <span className={darkMode ? "text-white page-title-dark" : "text-dark page-title-dark"}>Agent Chain</span>}
              {activeTab === "playGround" && <span className={darkMode ? "text-white page-title-dark" : "text-dark page-title-dark"}>Playground</span>}
            </div>
          </Col>
          <Col sm={2} className="my-auto py-3 text-right">
            {activeTab === "agent" && (
              <Link to="/agentFactory/createAgent" className="submitBtnDark ">
                + Add New Agent
              </Link>
            )}
            {activeTab === "agentChain" && (
              <Link
                to="/agentFactory/createAgentChain"
                className="submitBtnDark"
                style={{ padding: "8px 18px" }}
              >
                + Add New Agent Chain
              </Link>
            )}
          </Col>
        </Row>
        <Row className={darkMode ? "mx-3 bgwDark g-0 " : "mx-3 bgw g-0 "}>
          <Col className="py-0">
            <Row className="mx-0" style={{ border: darkMode ? 'none' : "1px solid #D5DAE4" }}>
              <Col sm={8} className="px-4">
                <div className="tabs" style={{ marginBottom: "5px" }}>
                  <ol className="tab-list mb-0 ">
                    <li
                      className={
                        activeTab === "agent"
                          ? darkMode ? 'tab-list-itemDark tab-list-activeDark' : "tab-list-item tab-list-active"
                          : darkMode ? 'tab-list-itemDark' : "tab-list-item"
                      }
                      onClick={() => onTabChange("agent")}
                    >
                      Agent
                    </li>
                    <li
                      className={
                        activeTab === "agentChain"
                          ? darkMode ? 'tab-list-itemDark tab-list-activeDark' : "tab-list-item tab-list-active"
                          : darkMode ? 'tab-list-itemDark' : "tab-list-item"
                      }
                      onClick={() => onTabChange("agentChain")}
                    >
                      Agent Chain
                    </li>
                    <li
                      className={
                        activeTab === "playGround"
                          ? darkMode ? 'tab-list-itemDark tab-list-activeDark' : "tab-list-item tab-list-active"
                          : darkMode ? 'tab-list-itemDark' : "tab-list-item"
                      }
                      onClick={() => onTabChange("playGround")}
                    >
                      Playground
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={2} className="my-auto">
                <InputGroup
                  className={darkMode ? 'searchWidth alchemSearchGroupDark' : 'searchWidth alchemSearchGroup'}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    className="searchBar"
                  />
                  <Button
                    variant="outline-secondary"
                    id="searchButton"
                    className="searchGroupBtn"
                  >
                    <CiSearch style={{ fontSize: '18px', textAlign: 'center', position: "relative", bottom: '2px', color: darkMode ? '#E5EAF1' : '' }} />
                  </Button>
                </InputGroup>
              </Col>
              <Col sm={2} className="my-auto">
                <div
                  style={{ borderLeft: "1px solid #BAC9F1" }}
                  className="ps-2 d-flex"
                >
                  <span className={darkMode ? "my-auto pe-2 agent-subnav-title-dark " : "my-auto pe-2 agent-subnav-title"}>
                    Created By
                  </span>
                  <NavDropdown
                    className={
                      darkMode
                        ? "btn text-capitalize form-select modelSelectDropdownDark dropdown-placeholder-dark dropdown-box-dark"
                        : "btn text-capitalize form-select modelSelectDropdown dropdown-placeholder-dark dropdown-box-dark"
                    }
                    title={<p style={{ color: darkMode ? '#BAC9F1' : '#000' }}>All</p>}

                  >

                    <NavDropdown.Item >
                      Leo Prem
                    </NavDropdown.Item>

                  </NavDropdown>
                </div>
              </Col>
            </Row>

            <Row
              className="mx-0 border-top-0"
              style={{ border: !darkMode ? "1px solid #D5DAE4" : "none", backgroundColor: darkMode ? '#454D7B' : "white" }}
            >
              <div className="tab-content  pt-4">
                {activeTab === "agent" && <AgentCard />}
                {activeTab === "agentChain" && <AgentChains />}
                {activeTab === "playGround" && (
                  <div className="container-fluid">
                    <Card className={darkMode ? "mb-2 agentCardBodyTopPadDark agentCardDark p-5 text-center" : "mb-2 whiteBg agentCard p-5 text-center "}>
                      <div style={{ color: darkMode ? 'white' : 'black' }}> Work in Progress </div>
                    </Card>
                  </div>
                )}
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Agents;