import { useState, createContext } from "react";

export const AuthContext = createContext()

export const AuthProvider = (props) => {
    const [token, setToken] = useState(null);
    const [userRole, setUserRole] = useState("agent");
    const [authenticated, setAuthenticated] = useState(false);
    // const [accessToken, setAccessToken] = useState("");
    // const [refreshToken, setRefreshToken] = useState("");
    // const [IDToken, setIDToken] = useState("");
    const [subID, setsubID] = useState("")
    const [reactFlowNodeDetailsAChain, setreactFlowNodeDetailsAChain] = useState({
        node: null,
        x: 0,
        y: 0,
        clicked: false,
        nodeID: 0,
        data: {}
    })
    const [accessToken, setAccessToken] = useState("eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIwSWhydExLNUpQMkY3QjJBTXJlODlUWVZwdC16eVhwM1gtSTFzdm1RVDF3In0.eyJleHAiOjE3MTI3NDUwNDUsImlhdCI6MTcxMjc0NDc0NSwianRpIjoiOTg0NTQ3MDUtOTIzMi00M2NhLWIyYzgtODJhNjkzN2NiMmMzIiwiaXNzIjoiaHR0cDovLzc0LjIzNS4xNjQuNDo4MDgwL3JlYWxtcy9BbGNoZW1pc3QiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiYTMzZjI5MGYtZmU2Mi00YmY2LTk1OTItN2JkZTk5MWE5MTczIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiQWxjaGVtaXN0VUkiLCJzZXNzaW9uX3N0YXRlIjoiN2E0Y2ZkYzMtZjI5Yi00ZTc4LTkwYjktMzc5ODk5ZDI0N2Y1IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJodHRwOi8vNzQuMjM1LjE2NC40OjMwMDMiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiZGVmYXVsdC1yb2xlcy1hbGNoZW1pc3QiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7IkFsY2hlbWlzdFVJIjp7InJvbGVzIjpbIlNNRSJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6IjdhNGNmZGMzLWYyOWItNGU3OC05MGI5LTM3OTg5OWQyNDdmNSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IkxlbyBQcmVtIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiMTgzNzg1NUB0Y3MuY29tIiwiZ2l2ZW5fbmFtZSI6IkxlbyIsImZhbWlseV9uYW1lIjoiUHJlbSIsImVtYWlsIjoiMTgzNzg1NUB0Y3MuY29tIn0.sQ1kRTlCq7Ntkyv235mW3I1FInfOpC_ANJrXnW0NOLaw3pw_M9RQb9v5780VXxbQ_hBE8AqpvfBbJaQPOHtpsof6oEm30pPeR_88ZWnp4z8jfFKV7O2bvRdh9I9JCJelnMgKEAbU7-dvjSfmndjgcYGWGYV2jwwJ_yLK4yTa47YF_mJAHV72dKo1C6s5ZCrnuB0ZfXWfzLKLVXUZ0L2AzkgDkrRi2xe7Z5rAbIt5nte32uUvwesQdfjo9Cb27O_kYScuOg_0GqxA2w9YU3-ZwZQayPod_ByoEJZSHq8kscnat49z2AVaWmjy6FmLg3mN5OzTLfOB3dsfFJOi-LbrKg")
    const [refreshToken, setRefreshToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJkN2ZmYWU0OC02MjhjLTRiODEtOTc4ZS1iYmE3YjQ0YWVmMWIifQ.eyJleHAiOjE3MTI3NDY1NDUsImlhdCI6MTcxMjc0NDc0NSwianRpIjoiYzY0Y2U5YWItMDFmMS00ZWZhLThlNGItMDhjOTU3NzY0MmY5IiwiaXNzIjoiaHR0cDovLzc0LjIzNS4xNjQuNDo4MDgwL3JlYWxtcy9BbGNoZW1pc3QiLCJhdWQiOiJodHRwOi8vNzQuMjM1LjE2NC40OjgwODAvcmVhbG1zL0FsY2hlbWlzdCIsInN1YiI6ImEzM2YyOTBmLWZlNjItNGJmNi05NTkyLTdiZGU5OTFhOTE3MyIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJBbGNoZW1pc3RVSSIsInNlc3Npb25fc3RhdGUiOiI3YTRjZmRjMy1mMjliLTRlNzgtOTBiOS0zNzk4OTlkMjQ3ZjUiLCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwic2lkIjoiN2E0Y2ZkYzMtZjI5Yi00ZTc4LTkwYjktMzc5ODk5ZDI0N2Y1In0.9NRQuh3ilSdDW82HUuK_-UgK7ISmG55cU-8uR6KJYYk")
    const [IDToken, setIDToken] = useState("eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIwSWhydExLNUpQMkY3QjJBTXJlODlUWVZwdC16eVhwM1gtSTFzdm1RVDF3In0.eyJleHAiOjE3MTI3NDUwNDUsImlhdCI6MTcxMjc0NDc0NSwiYXV0aF90aW1lIjowLCJqdGkiOiI0ZjcyY2Q1My1kNDQ1LTQxYTQtYTdiNi03NjMzNWY4Yjg2YTgiLCJpc3MiOiJodHRwOi8vNzQuMjM1LjE2NC40OjgwODAvcmVhbG1zL0FsY2hlbWlzdCIsImF1ZCI6IkFsY2hlbWlzdFVJIiwic3ViIjoiYTMzZjI5MGYtZmU2Mi00YmY2LTk1OTItN2JkZTk5MWE5MTczIiwidHlwIjoiSUQiLCJhenAiOiJBbGNoZW1pc3RVSSIsInNlc3Npb25fc3RhdGUiOiI3YTRjZmRjMy1mMjliLTRlNzgtOTBiOS0zNzk4OTlkMjQ3ZjUiLCJhdF9oYXNoIjoiLW5jcFd2Zjk2d3pFSGY0UGtKSnMyQSIsImFjciI6IjEiLCJzaWQiOiI3YTRjZmRjMy1mMjliLTRlNzgtOTBiOS0zNzk4OTlkMjQ3ZjUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJMZW8gUHJlbSIsInByZWZlcnJlZF91c2VybmFtZSI6IjE4Mzc4NTVAdGNzLmNvbSIsImdpdmVuX25hbWUiOiJMZW8iLCJmYW1pbHlfbmFtZSI6IlByZW0iLCJlbWFpbCI6IjE4Mzc4NTVAdGNzLmNvbSJ9.TzYnHT-RLZ3vH0yTLSkQ5F72DVcoDpp2LY9J0nWt7-IDOSYLlNQR3PFoFhPxbVAfnZPBvWpTOIopb3HMdc4aKePqiKaa3WJXRuyVoYscZE_8t5ozC6K_EPoBQspw3IMT1-j3r0KcJ_4GjIkdBay6rBBGLzIdBqmFvdoBuJAE0UC4Mt_5UAQg-tOm3VPAmo37OTDvH_XZ4NEu_q5JEGvzMD3YbK_hQpCtFEKcV9e1PqbqbKWpr4-diqCS1o22kExgDR_EVLwuo2Ei5ZoH3E6Yf8pjcxbO-hTDbJ2EA32UK_gMx0apT1zmC1_4g1SmlMzKRWTFFhhLcrdjh8bl_MeniQ")
    const [darkMode, setDarkMode] = useState(true);
    return (
        <AuthContext.Provider value={{ token, setToken, userRole, setUserRole, authenticated, setAuthenticated, accessToken, setAccessToken, refreshToken, setRefreshToken, IDToken, setIDToken, darkMode, setDarkMode, subID, setsubID, reactFlowNodeDetailsAChain, setreactFlowNodeDetailsAChain }}>
            {props.children}
        </AuthContext.Provider>
    )
}