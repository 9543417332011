import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import LoadModal from "./ModelHomeScreen/LoadModal";
import SelectModelCompetancyNav from "./selectModelCompetancyNav";
import AlchemTableNav from "../SharedComponents/AlchemTableNav/AlchemTableNav";
import AlchemProgressBar from "../SharedComponents/AlchemProgressBar/AlchemProgressBar";
import AlchemPagination from "../SharedComponents/AlchemPagination/AlchemPagination";
import { AuthContext } from "../Auth/AuthContext";

function SelectModelCompetancy({ darkMode }) {
  const {accessToken} = useContext(AuthContext)
  const { state } = useLocation();
  const agentForm = state;
  const agentFormValues = (agentForm);
  const createdby= ({
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman"
  });

  const [baseModelMapping, setbaseModelMapping] = useState({
    1: "falcon",
    2: "bloom",
    3: "flan t5",
    4: "Llama 2"
  });

  const [taskList, settaskList] = useState({
    1: "Summarization",
    2: "QA",
    3: "Text Generation"
  });

  console.log(agentFormValues);
  
  // ------------------compare model code---------------

  const [showModal, setShowModal] = useState(false);

  const [dummyData, setdummyData] = useState([]);

  const [selectedRow1, setSelectedRow1] = useState(null);
  const [selectedRow2, setSelectedRow2] = useState(null);
  const [selectedModelName, setselectedModelName] = useState("");
  const [modelType, setmodelType] = useState(false); // false if base model true if finetune model

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    let tempData = [];
    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/basemodels`, {
        headers:{
          Authorization: accessToken
        }
      })
      .then(response => {
        tempData.push(...response.data.data);
        console.log(
          "Fetch agents success : ",
          response.data.data,
          "----------"
        );
      })
      .catch(error => {
        console.error(error);
      });

    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/finetuned_models`,{
        headers:{
          Authorization: `${accessToken}`
        }
      })
      .then(response => {
        setmodelType(true);
        tempData.push(...response.data.data);
        console.log(
          "Fetch agents success : ",
          response.data.data,
          "----------"
        );
      })
      .catch(error => {
        console.error(error);
      });
    setdummyData(tempData);
    // openModal();
  }, []);

  const handleRowSelection = (index, name) => {
    if (selectedRow1 === index) {
      setSelectedRow1(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow1(index); // Select the clicked row
      setselectedModelName(name);
      localStorage.setItem("basemodelid", JSON.stringify(index + 1));
      localStorage.setItem("basemodelname", name);
      console.log(index + 1);
    }
  };
  const handleRowSelection2 = (index, name) => {
    if (selectedRow2 === index) {
      setSelectedRow2(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow2(index); // Select the clicked row
      setselectedModelName(name);
      localStorage.setItem("basemodelid", JSON.stringify(index + 1));
      localStorage.setItem("basemodelname", name);
      console.log(index + 1);
    }
  };

  const filters = [
    {
      label: "Base Model",
      options: ["Banking", "Life Science", "Insurance"],
      selectedOption: "All",
    },
    
  ];

     //pagination code
     const [currentPage, setCurrentPage] = useState(1);
     const recordsPerPage = 5;
     const totalPages = Math.ceil(dummyData.length / recordsPerPage);
   
     const handlePageChange = (pageNumber) => {
       setCurrentPage(pageNumber);
     };

      
   const startIndex = (currentPage - 1) * recordsPerPage;
   const endIndex = startIndex + recordsPerPage;
   const paginatedModels = dummyData.slice(startIndex, endIndex);
  console.log(dummyData);
  const DisplayData = paginatedModels.map((info, i) => {
    const isSelected = selectedRow1 === i;
    const jsSelected = selectedRow2 === i;
    if (modelType) {
      return (
        <>
          <tr
            key={i}
          >
            <td className="custom-radio">
              <input
                type="radio"
                className="checkbox "
                checked={isSelected}
                onChange={() => {}} // Disable checkbox change
                onClick={() => handleRowSelection(i, info.name)}
              />
            </td>
            <td
              data-toggle="collapse"
              data-target={"#rowId" + i}
              className="accordion-toggle"
              aria-expanded="false"
              aria-controls={"#rowId" + i}
              style={{ cursor: "pointer" }}
            ></td>
            <td id="modelName" style={{borderRight: '1px solid #2F499E'}}>{info.name}</td>
            <td style={{borderRight: '1px solid #2F499E'}}>{info.description}</td>
            <td style={{borderRight: '1px solid #2F499E'}}>
              {baseModelMapping[info.base_model_id]
                ? `${baseModelMapping[info.base_model_id]}`
                : "NA"}
            </td>
            <td style={{borderRight: '1px solid #2F499E'}}>
              {taskList[info.task_id] ? `${taskList[info.task_id]}` : "NA"}
            </td>
            <td style={{borderRight: '1px solid #2F499E'}}>{info.usecase ? `${info.usecase}` : "NA"} </td>

            <td >
              {info.status ? (
                <div className="statusContainer">
                  <div
                    className="circle"
                    style={{
                      background:
                        info.status == "Just created"
                          ? "#1FEB00"
                          : info.status == "Finished"
                          ? "#3366FF"
                          : "#FEA92B"
                    }}
                  ></div>{" "}
                  {info.status}
                </div>
              ) : (
                "NA"
              )}
            </td>
          </tr>
          <tr>
            <td colspan="10" className="hiddenRow border-bottom">
              <div
                class="collapse subTable_outer multi-collapse"
                id={"rowId" + i}
              >
                <table className="table subTable">
                  <thead>
                    <tr className="info">
                      <th>Purpose</th>
                      <th>Status</th>
                      <th>Updated by</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{info.purpose}</td>
                      <td>{info.status}</td>
                      <td>{createdby[info.updated_by]}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </>
      );
    } else {
      return (
          <tr
            key={i}
          >
            <td>
              <input
                type="radio"
                className="checkbox"
                checked={jsSelected}
                onChange={() => {}} // Disable checkbox change
                onClick={() => handleRowSelection2(i, info.name)}
              />
            </td>
            <td id="modelName">{info.name}</td>
            <td>{info.description}</td>
            <td>{info.version}</td>
          </tr>
        
      );
    }
  });
  let title = 'Model Orchestrator'
  let subtitle = `Model Name : ${localStorage.getItem("name")}`
  let steps = ['Select Model','Fine-tuning', 'Preview Model']

  return (

    <div className="">
      <LoadModal showModal={showModal} closeModal={closeModal} /> 
      <AlchemProgressBar title = {title} subtitle = {subtitle} steps = {steps} index = {1}/>
      <div className="container-fluid px-4 pageHeadBg">
        <nav className="navbar navbar-expand-lg pt-3">
          <div className="container-fluid px-0 ">
            <div className="d-flex text-center justify-content-center align-content-center align-item-center py-1">
              <span
                className={darkMode ? "text-white" : "text-dark"}
              >
                {selectedModelName ? `SELECTED MODEL : ${selectedModelName} ` : ""}
              </span>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav ms-auto">
                <Link to={"/"}>
                  <button
                    className={
                      darkMode
                        ? "btn btn-primary cancelBtnDark"
                        : "btn btn-primary cancelBtnDark"
                    }
                    role="button"
                  >
                    Cancel
                  </button>
                </Link>
                {selectedRow1 == null && selectedRow2 == null ? (
                  <Link to={""}>
                    <button
                      className={
                        darkMode
                          ? "btn btn-primary submitBtnDark me-0"
                          : "btn btn-primary submitBtnDark me-0"
                      }
                      role="button"
                    >
                      Save & Proceed
                    </button>
                  </Link>
                ) : (
                  <Link to={"/finetuning"}>
                    <button
                      className={
                        darkMode
                          ? "btn btn-primary submitBtnDark"
                          : "btn btn-primary submitBtnDark"
                      }
                      role="button"
                    >
                      Save & Proceed
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>

        <div className=" w-auto">
        <AlchemTableNav filters={filters} showFilters={true} showSearchbar={true} navbarBrand=''/>

        </div>
        <Card className={darkMode ? "tableCardDark" : "tableCard"}>
          <Card.Body>
            <table
              class={
                darkMode
                  ? "table alchemTableDark allModelTable mb-0"
                  : "table alchemTable allModelTable mb-0"
              }
            >
              <thead>
                {modelType ? (
                  <>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Base Model</th>
                      <th>Task</th>
                      <th>Domain</th>
                      <th>Status</th>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>version</th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </Card.Body>
        </Card>

        <AlchemPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
    </div>
  );
}

export default SelectModelCompetancy;
