import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import "./CustomTable.css";

function CustomTable({ TableColumn, TableData, updateData, darkMode }) {
  return (
    <div
      className="custom-table-wrapper"
      style={{
        background: darkMode ? "#42466C" : "",
        border: darkMode ? "none" : ""
      }}
    >
      <Table className={darkMode ? "custom-tableDark" : "custom-table"}>
        <colgroup>
          <col span="1" width="30px" />
          <col span="1" width="30px" />
          <col span="1" width="300px" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ borderTopLeftRadius: "4px" }}></th>
            {TableColumn.map((e, index) => (
              <th key={index} scope="col">{e}</th>
            ))}
            <th style={{ borderTopRightRadius: "4px" }}></th>
          </tr>
        </thead>
        <tbody>
          {TableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td style={{ borderBottom: "none" }}></td>
              {TableColumn.map((column, colIndex) => {
                const cellData = row[column];
                if (!cellData) {
                  return <td key={colIndex} style={{ borderBottom: "1px solid #2F499E" }}></td>;
                }
                // Render based on cellData.type
                switch (cellData.type) {
                  case "label":
                    return (
                      <td key={colIndex} style={{ borderBottom: "1px solid #2F499E" }}>
                        {cellData.value || ""}
                      </td>
                    );
                  case "radio":
                    return (
                      <td key={colIndex} style={{ borderBottom: "1px solid #2F499E" }}>
                        {cellData.options.map((option, optionIndex) => (
                          <React.Fragment key={optionIndex}>
                            <input
                              type="radio"
                              onChange={(e) => {
                                updateData(
                                  rowIndex,
                                  column,
                                  "radio",
                                  e.target.value,
                                  row["Parameter Name"]?.value
                                );
                              }}
                              checked={option.value === cellData.value}
                              id={`${option.label}_${optionIndex}`}
                              name={`${column}_${rowIndex}`}
                              value={option.value}
                            />
                            <label
                              className="mx-1 px-1"
                              style={{ verticalAlign: "text-bottom" }}
                              htmlFor={`${option.label}_${optionIndex}`}
                            >
                              {option.label}
                            </label>
                          </React.Fragment>
                        ))}
                      </td>
                    );
                  case "select":
                    return (
                      <td key={colIndex} style={{ borderBottom: "1px solid #2F499E" }}>
                        <Form.Select
                          className="form-select custom-table-select-text"
                          style={{
                            background: darkMode ? "#34385A" : "",
                            color: darkMode ? "#BAC9F1" : "",
                            border: "1px solid #2F499E"
                          }}
                          value={cellData.value || ""}
                          onChange={(e) => {
                            updateData(
                              rowIndex,
                              column,
                              "select",
                              e.target.value,
                              row["Parameter Name"]?.value
                            );
                          }}
                          aria-label="Default select example"
                          size="sm"
                        >
                          <option value="">Open this select menu</option>
                          {cellData.options.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    );
                  case "text":
                    return (
                      <td key={colIndex} style={{ borderBottom: "1px solid #2F499E" }}>
                        <input
                          className="form-control"
                          style={{
                            background: darkMode ? "#34385A" : "",
                            color: darkMode ? "#BAC9F1" : "",
                            border: "none"
                          }}
                          onChange={(e) => {
                            updateData(
                              rowIndex,
                              column,
                              "text",
                              e.target.value,
                              row["Parameter Name"]?.value
                            );
                          }}
                          type="text"
                          value={cellData.value || ""}
                        />
                      </td>
                    );
                  case "number":
                    return (
                      <td key={colIndex} style={{ borderBottom: "1px solid #2F499E" }}>
                        <input
                          className="form-control"
                          style={{
                            background: darkMode ? "#34385A" : "",
                            color: darkMode ? "#BAC9F1" : "",
                            border: "none"
                          }}
                          type="number"
                          min={cellData.min || undefined}
                          max={cellData.max || undefined}
                          onChange={(e) => {
                            updateData(
                              rowIndex,
                              column,
                              "number",
                              e.target.value,
                              row["Parameter Name"]?.value
                            );
                          }}
                          value={cellData.value || ""}
                        />
                      </td>
                    );
                  default:
                    return <td key={colIndex}>{cellData.value || ""}</td>;
                }
              })}
              <td style={{ borderBottom: "none" }}></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default CustomTable;
