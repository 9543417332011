// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomNavbar_line__q4EN5 {
    background-color: #c7d7f0;
    color: #c7d7f0;
    height: 2rem;
    width: 1.5px;
    margin: 1.05rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/CustomNavbar/CustomNavbar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".line {\n    background-color: #c7d7f0;\n    color: #c7d7f0;\n    height: 2rem;\n    width: 1.5px;\n    margin: 1.05rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `CustomNavbar_line__q4EN5`
};
export default ___CSS_LOADER_EXPORT___;
