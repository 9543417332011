import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import popupImg from "../../assets/img/popup-img.png";
import plusicon from "../../assets/img/plusIcon.svg";
import plusIconDark from "../../assets/img/plusIconDark.png";
import AlchemTableNav from "../SharedComponents/AlchemTableNav/AlchemTableNav";
import AlchemPagination from "../SharedComponents/AlchemPagination/AlchemPagination";
function SelectModelList({ darkMode }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const agentForm = state;
  const [agentFormValues, setAgentFormValues] = useState(agentForm);
  const [selectedModel, setSelectedModel] = useState();
  const [selectedRow, setSelectedRow] = useState(0);
  const agentType = localStorage.getItem("Agent");
  const selectedIndex = agentType == "ADRE" ? 0 : 1;

  const [models, setModels] = useState([]);
  const [prevSelectedRowId, setPrevSelecteRowId] = useState(undefined);

  const [createdby, setcreatedby] = useState({
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman",
  });

  const [baseModelMapping, setbaseModelMapping] = useState({
    1: "falcon",
    2: "bloom",
    3: "flan t5",
    4: "Llama 2",
    7: "GLM",
  });

  const [taskList, settaskList] = useState({
    1: "Summarization",
    2: "QA",
    3: "Text Generation",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MODALS_SERVER}/finetuned_models`)
      .then((response) => {
        setModels(response.data.data.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401)
          window.location.reload();
      });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const content = `

  Analysing the tasks, Alchemist recommends full Supervised Fine-Tuning (SFT), Reward Modeling, and Proximal Policy Optimization (PPO) for Adverse Drug Relations (ADR) events. This combined approach could lead to a 15-30% reduction in ADR-related hospitalizations, significantly impacting both patient well-being and healthcare costs.
<center><img src="${popupImg}" style="width:25rem;"/></center>
<strong>Supervised Fine-Tuning (SFT) Precision in Prediction:</strong> SFT leads to higher precision in predicting potential adverse reactions, as the model becomes more attuned to the nuances of drug interactions and patient-specific factors.
<strong>Benefit:</strong> Improved accuracy in ADR prediction can lead to a reduction in patient morbidity and mortality. For instance, a 10% increase in prediction accuracy could potentially translate to thousands of prevented ADR incidents in large patient populations.Reward Modeling

<strong>Customized Learning Objectives:</strong> Reward modeling allows for the creation of custom reward functions that can prioritize the most clinically significant ADRs. This means the model can focus on detecting ADRs that are rare but severe, rather than common but mild.
<strong>Benefit:</strong> By focusing on severe ADRs, healthcare systems can significantly reduce the incidence of life-threatening drug reactions. For example, early detection of a rare but severe ADR in just 1% of cases could save lives and reduce healthcare costs associated with treating these reactions. Proximal Policy Optimization (PPO)

<strong>Efficient Exploration:</strong> PPO, a reinforcement learning algorithm, is useful for exploring different strategies in ADR prediction and management. It can efficiently balance the exploration of new strategies and the exploitation of known strategies, leading to more robust ADR prediction models.
Quantifiable Benefit: Enhanced exploration exploitation balance can lead to a more rapid convergence to optimal strategies, potentially reducing the time to identify critical ADRs by a significant margin. For instance, a 20% reduction in convergence time can accelerate the deployment of effective ADR prediction models in clinical settings.`;

  function selectModelFunction(event, param, id) {
    console.log(param, id);

    console.log(event.target);

    setPrevSelecteRowId(id.i);
    setSelectedRow(id.i);

    console.log("Preve Selected Id " + prevSelectedRowId);
    console.log(prevSelectedRowId);
    if (prevSelectedRowId != undefined) {
      console.log("selectedRow" + prevSelectedRowId);
    }
  }

  function createAgentAPICall() {
    console.log(agentFormValues);

    const headers = {
      "Content-Type": "application/json",
    };
    const payload = JSON.stringify(agentFormValues);
    console.log(payload);

    axios
      .post(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`, payload, {
        headers,
      })
      .then((response) => {
        console.log(response);
        navigate("/agentfactory/createAgent/testAgent/" + response.data.id);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401)
          window.location.reload();
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  }

  const filters = [
    {
      label: "Domain",
      options: ["Banking", "Life Science", "Insurance"],
      selectedOption: "All",
    },
    {
      label: "Task",
      options: ["Wealth", "Drug", "Policy"],
      selectedOption: "All",
    },
    {
      label: "Base Model",
      options: ["EY", "Astreagenica", "Cigna"],
      selectedOption: "All",
    },
    {
      label: "Finetuned Model",
      options: ["EY", "Astreagenica", "Cigna"],
      selectedOption: "All",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const totalPages = Math.ceil(models.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const paginatedModels = models.slice(startIndex, endIndex);

  return (
    <>
      {/* <LoadModal
        showModal={showModal}
        closeModal={closeModal}
        content={content}
        submitModal={createAgentAPICall}
      /> */}
      <div className="container-fluid px-4">
        <AlchemTableNav
          filters={filters}
          showFilters={true}
          showSearchbar={true}
          navbarBrand=""
        />

        <Card className={darkMode ? "tableCardDark" : "tableCard"}>
          <Card.Body>
            <table
              class={
                darkMode
                  ? "table alchemTableDark allModelTable mb-0"
                  : "table alchemTable allModelTable mb-0"
              }
            >
              <thead
                className={
                  "custom-table-head-dark " + darkMode ? "dark-text" : ""
                }
              >
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Model Name</th>
                  <th>Description</th>
                  <th>Base Model</th>
                  <th>Model Type</th>
                  <th>Date Created</th>
                  <th>Last Modified</th>
                  <th></th>
                  {/* <th>Use Case</th> */}
                </tr>
              </thead>
              <tbody id="accordion" className={darkMode ? "dark-text" : ""}>
                {paginatedModels.map((info, i) => {
                  const isSelected = selectedRow === i;
                  return [
                    <>
                      <tr key={i}>
                        <td style={{ borderBottom: "none" }}></td>
                        <td
                          data-toggle="collapse"
                          data-target={"#rowId" + i}
                          className="accordion-toggle p-3 pt-4"
                          aria-expanded="false"
                          aria-controls={"#rowId" + i}
                          style={{ cursor: "pointer" }}
                        >
                          {darkMode ? (
                            <img src={plusIconDark} alt="plus" />
                          ) : (
                            <img src={plusicon} alt="plus" />
                          )}
                        </td>
                        <td>
                          <input
                            type="radio"
                            className="checkbox"
                            id="myRadio"
                            checked={isSelected}
                            onClick={(e) => selectModelFunction(e, info, { i })}
                            style={{
                              background: "none",
                              border: "1px solid #BAC9F1",
                            }}
                          />
                        </td>

                        <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                          {info.name}
                        </td>
                        <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                          {info.description}
                        </td>
                        <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                          {(() => {
                            if (info.name.includes("Risk")) {
                              return <p>GLM</p>;
                            } else if (info.base_model_id == 1) {
                              return <p>falcon-7b</p>;
                            } else if (info.base_model_id == 2) {
                              return <p>bloom-3b</p>;
                            } else if (info.base_model_id == 3) {
                              return <p>flan-t5-base</p>;
                            } else if (info.base_model_id == 4) {
                              return <p>bloom-3b</p>;
                            } else if (info.base_model_id == 8) {
                              return <p>mistral-3b</p>;
                            }
                          })()}
                        </td>
                        <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                          {info.name.includes("Risk")
                            ? "Predictive"
                            : "Generative"}
                        </td>
                        <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                          {" "}
                          {new Date(info.updated_at).toLocaleDateString(
                            "en-GB"
                          )}{" "}
                        </td>
                        <td>
                          {new Date(info.updated_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        {/* <td>{info.useCase}</td> */}

                        {/* <td className="text-center"> <Button
                            className={`btn cancelBtn ${
                              i === selectedIndex ? "hidden" : ""
                            }`}
                            role="button"
                            id={"selectedElement" + i}
                            onClick={e => {
                              selectModelFunction(e, info, { i });
                            }}
                          >
                            Select
                          </Button>
                          <img
                            className={`img-fluid mb-1 ${
                              i === selectedIndex ? "" : "hidden"
                            }`}
                            loading="lazy"
                            id={"selectedRow" + i}
                            src={greenTick}
                            alt=""
                          /> </td> */}
                      </tr>
                      <tr>
                        <td colspan="9" className="hiddenRow border-bottom">
                          <div
                            id={"rowId" + i}
                            className="collapse"
                            aria-labelledby={"rowId" + i}
                            data-bs-parent="#accordion"
                          >
                            <div
                              className=" container-fluid px-5 subTable_outer"
                              style={{
                                background: darkMode ? "#34385A" : "",
                                borderRadius: "4px",
                              }}
                            >
                              <table
                                className={`table alchemTable ${
                                  darkMode
                                    ? "alchemSubTableDark"
                                    : "alchemSubTable"
                                } subTable mb-0`}
                              >
                                <thead>
                                  <tr className="info">
                                    <th>Purpose</th>
                                    <th>Status</th>
                                    <th>Updated by</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{info.purpose}</td>
                                    <td>{info.status}</td>
                                    <td>{createdby[info.updated_by]}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>,
                  ];
                })}
              </tbody>
            </table>
          </Card.Body>
        </Card>
        <AlchemPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default SelectModelList;
