import React, { useState, useContext } from "react";
import "./ChatPopup.css";
import ChatLLM from "../chatLLM";
import { DockContext } from "../../DockWrapper/DockContext";
import ChatRobot from "../../../assets/img/coachllm-robo.svg";
import PropTypes from "prop-types";
import ChatLLMcqrs from "../ChatLLMcqrs";

function ChatPopup({
  type,
  setChains,
  setEdges,
  setNodes,
  setIsLoading,
  isFinish,
  isSynthe,
  setTableData,
  dataName,
  setDataName,
  aChainToCahtPopFun
}) {
  const [isOpen, setIsOpen] = useState(false);
  
  const { isDocked, enableCQRS } = useContext(DockContext);
  const toggleChat = () => {
    setIsOpen(!isOpen);
  };


  console.log(enableCQRS, "cqrs")
  return (
    <div>
      <div className={`chat-popup ${isOpen && !isDocked ? "open" : ""}`}>
        {enableCQRS === true ?


          <ChatLLMcqrs
            type={type}
            setChains={setChains}
            setEdges={setEdges}
            setNodes={setNodes}
            setIsLoading={setIsLoading}
            isFinish={!!isFinish}
            isSynthe={!!isSynthe}
            setTableData={setTableData}
            dataName={dataName}
            setDataName={setDataName}
            /> :
          <ChatLLM
            type={type}
            setChains={setChains}
            setEdges={setEdges}
            setNodes={setNodes}
            setIsLoading={setIsLoading}
            isFinish={!!isFinish}
            isSynthe={!!isSynthe}
            setTableData={setTableData}
            dataName={dataName}
            setDataName={setDataName}
          />
        }
      </div>
      <button
        className={isOpen ? " chat-toggle-btn-open" : "chat-toggle-btn"}
        onClick={toggleChat}
      >
        <div className="chat-popup-robot-icon">
          <img
            src={ChatRobot}
            alt=""
            width={"20px"}
            style={{ marginBottom: "5px" }}
          />
        </div>
        {isOpen ? (
          ""
        ) : (
          <p className="chat-popup-right-text mb-2 mt-1">Coach LLM</p>
        )}
      </button>
    </div>
  );
}
ChatPopup.propTypes = {
  type: PropTypes.string.isRequired,
  setChains: PropTypes.func.isRequired,
  setEdges: PropTypes.func.isRequired,
  setNodes: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isFinish: PropTypes.bool,
  isSynthe: PropTypes.bool,
  setTableData: PropTypes.func.isRequired,
  dataName: PropTypes.string.isRequired,
  setDataName: PropTypes.func.isRequired,
};

export default ChatPopup;
