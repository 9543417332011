import { Row, Col, Table, Tabs, Tab, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import AlchemNav from "../../../components/AgentModule/AlchemNav/AlchemNav";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls
} from "reactflow";
import "reactflow/dist/style.css";
import { useParams, useNavigate } from "react-router-dom";
import CompetencyNav from "../CompetencyNav";
import CompetencyDetailsNav from "./CompetencyDetailsNav";
import profileImg from "../../../assets/img/agentAvatar.svg";

import starSolid from "../../../assets/img/star-solid.svg";
import starReg from "../../../assets/img/star-regular.svg";
import "./competencyDetails.css";

const initialNodes = [];
const initialEdges = [];

function CompetencyDetails() {
  const [competencyDetails, setCompetencyDetails] = useState(null);
  const [chain, setchain] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [navState, setnavState] = useState("");

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/competencies/${id}/`)
      .then(response => {
        setCompetencyDetails(response.data);
        console.log(response.data);
        console.log("Fetch agentchains success : ");
        console.log(response.data.agent_chain_mapping);
        axios
          .get(
            `${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/chains/` +
              response.data.agent_chain_mapping
          )
          .then(response => {
            setchain(response.data.chain_payload);
            console.log(response.data.chain_payload);
            setNodes(response.data.chain_payload.nodes);
            setEdges(response.data.chain_payload.edges);
            setName(response.data.chain_payload.name);
            setPurpose(response.data.chain_payload.purpose);
          });
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const navigate = useNavigate();

  const handleClone = e => {
    e.preventDefault();
    navigate("/competency/configure", {
      state: { competency: competencyDetails, isUpdate: false }
    });
  };

  const handleEdit = e => {
    e.preventDefault();
    navigate("/competency/configure", {
      state: { competency: competencyDetails, isUpdate: true }
    });
  };
  const handleRemoveTag = i => {
    const updatedTags = competencyDetails.tags.filter(tag => tag !== i);
    setCompetencyDetails({ ...competencyDetails, tags: updatedTags });
  };

  return (
    <>
      {/* <AlchemNav /> */}
      {/* <CompetencyNav/> */}
      <CompetencyDetailsNav module={{ name: "competency", state: navState }} />

      <div className="p-2">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <span className="navbar-brand screenTitles">
              Knowledge Work Details
            </span>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav ms-auto">
                <Link
                  class="btn btn-primary backBtn me-3"
                  to="/competency"
                  role="button"
                >
                  Back
                </Link>
                <a
                  class="btn btn-primary clnEditBtn me-3"
                  // href="/competancy/configure"
                  onClick={handleClone}
                  role="button"
                >
                  Clone
                </a>
                <a
                  class="btn btn-primary clnEditBtn me-3"
                  onClick={handleEdit}
                  role="button"
                >
                  Edit
                </a>
                <a class="btn btn-primary submitBtn" role="button">
                  Test
                </a>
                {/* onClick={handleShow}  on hyperlink above removed as it was giving error*/}
              </div>
            </div>
          </div>
        </nav>
      </div>

      <Row className="container-fluid px-3 mx-0">
        <Col xs={8}>
          <Container className="container-fluid p-4 competency-details">
            {competencyDetails && (
              <>
                <Row className="container-fluid">
                  <Col xs={1}>
                    <button
                      type="button"
                      className="btn rounded-circle position-absolute p-4  d-flex justify-content-center align-items-center"
                      style={{
                        height: "40px",
                        width: "42px",
                        backgroundColor: "#F2F3F3"
                      }}
                    >
                      <img src={profileImg} style={{ width: "60px" }} />
                    </button>
                  </Col>
                  <Col xs={8} className="ps-3">
                    <h6 className="detailsTitle mb-1">Knowledge Work Name</h6>
                    <p className="competencyName">
                      {competencyDetails.competency_name}
                    </p>
                    <h6 className="detailsTitle mb-1">Tag Line</h6>
                    <p className="details">{competencyDetails.tag_line}</p>
                    <h6 className="detailsTitle mb-1">Description</h6>
                    <p className="details">{competencyDetails.description}</p>
                  </Col>
                  <Col xs={3}>
                    <div class="delpoyed mb-3">
                      <Button className="btn-secondary deployed-btn">
                        <span className="dot me-1"></span>
                        <span>Deployed</span>
                      </Button>
                    </div>
                    <h6 className="detailsTitle mb-1">Knowledge Work</h6>
                    <p className="details">
                      {competencyDetails.competency_type}
                    </p>
                    <h6 className="detailsTitle mb-1">Industry</h6>
                    <p className="details">
                      {competencyDetails.industry_domain}
                    </p>
                    <h6 className="detailsTitle mb-1">Industry Sub Domain</h6>
                    <p className="details">
                      {competencyDetails.industry_sub_domain}
                    </p>
                  </Col>
                  <Col xs={12} className="ps-5 ms-4">
                    <h6 className="detailsTitle">Tags</h6>
                    <div className="d-flex flex-wrap gap-1">
                      {competencyDetails.tags?.map((tag, index) => (
                        <span key={index} className="bubble">
                          {tag} {"hello"}
                          <span
                            className="removBubble"
                            onClick={() => handleRemoveTag(tag)}
                          >
                            x
                          </span>
                        </span>
                      ))}
                      <span key={1} className="bubble">
                        {"hello"}
                        <span
                          className="removBubble"
                          onClick={() => handleRemoveTag()}
                        >
                          x
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Col>

        <Col xs={4}>
          <Container className="container-fluid p-4 competency-details h-100">
            {competencyDetails && (
              <>
                <h5 className="files">Uploaded Files</h5>
                <Table className="uploadFiles">
                  <thead>
                    <tr>
                      <th className="fileNameHeader border-0">File Name</th>
                      <th className="fileUploadDateHeader border-0">
                        Date Uploaded
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {competencyDetails.uploaded_files.map(file => {
                      return (
                        <tr>
                          <td className="fileName">{file}</td>
                          <td className="fileUploadDate">
                            {new Date(
                              competencyDetails.updated_at
                            ).toDateString()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </Container>
        </Col>
      </Row>

      <Row className="container-fluid mx-0">
        <Col className="px-3">
          <div className=" p-4 competency-details">
            {competencyDetails && (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <span className="pe-2 detailsTitle">Rating</span>
                  <span className="pe-4 details">
                    <div style={{ padding: "5px 10px" }} className="bubble ">
                      {" "}
                      <img className="" src={starSolid} />{" "}
                      <img src={starSolid} /> <img src={starSolid} />{" "}
                      <img src={starSolid} /> <img src={starReg} />{" "}
                    </div>
                  </span>
                  <span className="pe-2 detailsTitle">Created on</span>
                  <span className="pe-4 details">
                    {new Date(competencyDetails.created_at).toDateString()}
                  </span>
                  <span className="pe-2 detailsTitle">Updated on</span>
                  <span className="pe-4 details">
                    {new Date(competencyDetails.updated_at).toDateString()}
                  </span>
                  <span className="pe-2 detailsTitle">Times Used</span>
                  <span className="pe-4 details">30</span>
                  <span className="pe-2 detailsTitle">Customers Using</span>
                  <span className="pe-4 detailss">10</span>
                </Container>
              </>
            )}
          </div>
        </Col>
      </Row>

      <Row className="container-fluid mx-0">
        <Col>
          <div className=" py-4 competency-details">
            <Tabs
              defaultActiveKey="agent-chains"
              id="agent-chain"
              className="mt-0 px-3 tabTitles"
            >
              <Tab eventKey="agent-chains" title="Agent Chains">
                <div style={{ width: "100%", height: "100vh" }}>
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    className="agent-chain-flow"
                  />
                </div>
              </Tab>
              {/* <Tab eventKey="end-points" title="API Endpoints">
                <div></div>
              </Tab> */}
            </Tabs>
          </div>
        </Col>
      </Row>
      {/* </Container> */}
    </>
  );
}

export default CompetencyDetails;
