import React, { useContext, useState } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Papa from "papaparse";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import UploadIcon from "../../../../assets/img/uploadIcon.png";
import "./UploadComp.css";
import { FiUpload } from "react-icons/fi";
import { dark } from "@mui/material/styles/createPalette";
import { Row, Col } from "react-bootstrap";
import FineTuningTemplate from "../../../../assets/templates/Medical_Data-Template.csv";
import RewardTemplate from "../../../../assets/templates/Reward_Model_data_Template.csv";
import { MdOutlineFileDownload } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../Auth/AuthContext";
export default function UploadComp(props) {
  const {accessToken} = useContext(AuthContext)
  let location = useLocation();
  let path = location.pathname;
  let templateSelector = {
    "/finetuning": FineTuningTemplate,
    "/rewardModel": RewardTemplate,
    "/rewardppo": RewardTemplate
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]); // State to store CSV data
  const [completed, setCompleted] = useState(false);
  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file.name);
  };

  const handleUpload = () => {
    if (selectedFile) {
      setLoading(true);

      // Parse CSV file
      Papa.parse(selectedFile, {
        header: true,
        complete: results => {
          console.log("Parsed CSV data: ", results.data);
          setCsvData(results.data.slice(0, 5)); // Update state with CSV data
        },
        error: error => {
          console.error("Error parsing CSV: ", error);
        },
        skipEmptyLines: true
      });
      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("task", parseInt(localStorage.getItem("task", 1)));
      formData.append("author", "Nimra");

      axios
        .post(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/datasets`, formData, {
          headers: {
            Authorization: accessToken
          }
        })
        .then(response => {
          console.log("File uploaded successfully:", response.data);
          localStorage.setItem("datasetsrc", response.data.datasetsrc);
          localStorage.setItem("datasetid", response.data.id);
          setCompleted(true);
        })
        .catch(error => {
          console.error("Error uploading file:", error);
        })
        .finally(() => {
          setLoading(false);
        });
      // Rest of your upload logic...
    } else {
      console.log("Please select a file to upload.");
    }
  };

  return (
    <Card className={props.darkMode ? "UploadCompDark p-3" : "UploadComp p-3"}>
      <Row
        className={
          "g-0 px-40 py-2 " +
          (props.darkMode ? "UploadCompDarkHeader " : "UploadCompHeader ")
        }
      >
        <Col
          style={{
            color: props.darkMode ? "#E5EAF1" : "#0056b3",
            textDecoration: "none"
          }}
          className={
            (props.darkMode ? "darg-n-drop-dark " : "darg-n-drop ") +
            "p-0 justify-content-center fw-medium"
          }
        >
          Training Data
        </Col>
        <Col className="p-0 align-items-end">
          {console.log(templateSelector[path])}
          <a
            href={templateSelector[path]}
            download="Template"
            target="_blank"
            style={{ color: props.darkMode ? "#E5EAF1" : "#0056b3" }}
            className={
              (props.darkMode ? "darg-n-drop-dark " : "darg-n-drop ") +
              "p-0 justify-content-center fw-medium"
            }
          >
            <MdOutlineFileDownload
              style={{ fontSize: "18px", marginRight: "8px" }}
            />
            Download Template{" "}
          </a>
        </Col>
      </Row>
      <Card.Body
        className={
          props.darkMode
            ? "uploadCompOuter d-flex align-items-center justify-content-center"
            : "UploadComp d-flex align-items-center justify-content-center"
        }
        style={{ color: props.darkMode ? "" : "white" }}
      >
        {/* {csvData.length} */}
        {csvData.length <= 0 && (
          <div className="text-center">
            <div
              className={
                (props.darkMode ? "image-upload-dark" : "image-upload") +
                " text-center d-flex flex-column align-items-center "
              }
            >
              <input
                type="file"
                // id="fileUpload"
                onChange={handleFileChange}
                id="file-input"
              />
              {/* <label for="file-input"> */}
              {/* <img
                  className="img-fluid "
                  loading="lazy"
                  src={UploadIcon}
                  alt=""
                /> */}
              <FiUpload
                style={{
                  fontSize: "30px",
                  color: props.darkMode ? "#E5EAF1" : "#0056b3"
                }}
              />
              {/* </label> */}
              <div
                style={{ color: props.darkMode ? "#E5EAF1" : "#0056b3" }}
                className={
                  props.darkMode
                    ? "darg-n-drop-dark pb-4 pt-2"
                    : "darg-n-drop pb-5 fw-medium"
                }
              >
                Drag and Drop
              </div>
              <div
                className={
                  props.darkMode ? "uploadCompOrDark mb-4" : "uploadCompOr mb-5"
                }
              >
                OR
              </div>
              <div
                className="taskList"
                style={{
                  fontSize: "14px",
                  color: props.darkMode ? "" : "rgb(0, 86, 179)"
                }}
              >
                Choose from Repository
              </div>
              <label htmlFor="file-input" className="my-2">
                <a className="cancelBtnDark  mx-0">Choose Training Data</a>
              </label>
              {selectedFile && (
                <div style={{ color: props.darkMode ? "#E5EAF1" : "#0056b3" }}>
                  {" "}
                  {selectedFile.name}{" "}
                </div>
              )}
            </div>

            <Button
              onClick={handleUpload}
              disabled={loading}
              // className="submitBtn mt-2"
              className={`mt-2 mr-0 ${selectedFile ? "" : "disabled"} ${
                props.darkMode ? "submitBtnDark" : "submitBtnDark"
              }`}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
            {loading && <div className="loader">Loading...</div>}
          </div>
        )}
        {/* Display CSV Data */}
        {csvData.length > 0 && (
          <div className="csv-data ">
            <Table
              className={`${
                props.darkMode ? "custom-tableDark" : "custom-table"
              } mt-3`}
            >
              <thead className="table-secondary ">
                <tr>
                  {Object.keys(csvData[0]).map((header, index) => (
                    <th key={index} style={{ fontWeight: "bold" }}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
