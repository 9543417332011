import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

export default function SelectModelCompetancyNav({darkMode}) {
  return (
    <div className="container-fluid pt-3 ps-4">
      <div className="row mx-0 mb-2">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-3" style={{color: darkMode ? '#fff' : ''}}>Model Orchestrator</h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav
            variant="pills"
            defaultActiveKey="/agentfactory/createAgent"
            className="agentWizard"
          >
            <Nav.Item className="">
              <Nav.Link
                as={Link}
                to="/competency/selectModel"
                eventKey="/comparemodels"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                1
              </Nav.Link>
              <span className="pilltext">Select Model</span>
            </Nav.Item>
            <hr
              className="horizontal negMarg"
              style={{ color: "#3366FF", border: "1px solid" }}
            />
            <Nav.Item className="negMarg">
              <Nav.Link
                as={Link}
                to="/finetuning"
                eventKey="/agentfactory/createAgent/selectModel"
                // href="/agentfactory/agentDetails"
                className="linkPill mx-auto mb-1 "
              >
                2
              </Nav.Link>
              <span className="pilltext">&nbsp; Fine-tuning &nbsp; </span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg ml-n26">
              <Nav.Link
                as={Link}
                to=""
                eventKey=""
                // href="/agentfactory/createAgent/testAgent"
                className="linkPill mx-auto mb-1"
              >
                3
              </Nav.Link>
              <span className="pilltext">Preview Model </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}
