import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DynamicInput from "../AgentModule/CreateAgent/DynamicInput";
import ellipsisIcon from "../../assets/img/ellipsis.png";
import "../AgentModule/CreateAgent/CreateAgent.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blank from "../../assets/img/Mask Group 3@2x.png";
import minus from "../../assets/img/minus.png";
import { Row, Col, Container } from "react-bootstrap";
import LoadModal from "../RecoModule/LoadModal";
import Modal from "react-bootstrap/Modal";
import uploadIcon from "../../assets/img/upload-agent.png";
import e from "cors";
import NavDropdown from "react-bootstrap/NavDropdown";
import ChatPopup from "./ChatPopup/ChatPopup";
import SelectModelList from "./SelectModelList";
import TestAgentAchain from "./testAgentAchain";

const AgentsListTest = ({ agentID, darkMode, activityChainID }) => {
  let chains_data = JSON.parse(localStorage.getItem("chains"));
  console.log(chains_data, activityChainID);

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [saveBtn, setSaveBtn] = useState(false);
  const [testAgentBtn, setTestAgentBtn] = useState(true);
  const [saveProceedBtn, setSaveProceedBtn] = useState(false);

  const navigate = useNavigate();

  const [showAgentIcon, setShowAgentIcon] = useState(false);

  const handleClose = () => {
    setShowAgentIcon(false);
    setSelectedFile(null);
  };

  const [agentForm, setAgentForm] = useState({
    name: "",
    project_id: 1,
    user_id: 1,
    description: "",
    goal: [],
    instruction: [],
    agent_workflow: "Goal Based Workflow",
    constraints: [],
    toolkits: [],
    tools: [38],
    exit: "",
    iteration_interval: "500",
    model: "",
    max_iterations: 5,
    permission_type: "",
    LTM_DB: "",
    user_timezone: "",
    knowledge: null,
  });

  const [content, setContent] = useState("");
  const payload = {
    name: agentForm.name,
    project_id: 1,
    description: agentForm.description,
    goal: ["INPUT: "],
    instruction: agentForm.instruction,
    agent_workflow: "Goal Based Workflow",
    constraints: [
      "If you are unsure how you previously did something or want to recall past events, thinking about similar events will help you remember.",
      "Ensure the tool and args are as per current plan and reasoning",
      'Exclusively use the tools listed under "TOOLS"',
      'REMEMBER to format your response as JSON, using double quotes ("") around keys and string values, and commas (,) to separate items in arrays and objects. IMPORTANTLY, to use a JSON object as a string in another JSON object, you need to escape the double quotes.',
    ],
    toolkits: [],
    tools: [12, 13, 17],
    exit: "No exit criterion",
    iteration_interval: 500,
    model: "test",
    max_iterations: 25,
    permission_type: "God Mode",
    LTM_DB: "Pinecone",
    user_timezone: "Asia/Calcutta",
    knowledge: null,
  };
  console.log(payload);

  console.log(agentID);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgentForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  function agentValues() {
    console.log("Form Submitted", agentForm);

    instructionsFormValues.forEach((element) => {
      agentForm.instruction.push(element.value);
    });
    console.log("Post updating values", agentForm);
    navigate("/agentfactory/createAgent/selectModel", { state: agentForm });
  }

  const [instructionsFormValues, setInstructionsFormValues] = useState([
    {
      label: "instruction",
      type: "text",
      value: "",
    },
  ]);

  const handleInstructionsChange = (e, index) => {
    const values = [...instructionsFormValues];
    values[index].value = e.target.value;
    setInstructionsFormValues(values);
  };

  const handleInstructionsDeleteField = (e, index) => {
    const values = [...instructionsFormValues];
    values.splice(index, 1);
    setInstructionsFormValues(values);
    if (agentForm.instruction.length > 0) {
      const valuesInstr = [...agentForm.instruction];
      valuesInstr.splice(index, 1);
      setAgentForm((prevState) => {
        let agentform = { ...prevState.agentForm };
        agentform.instruction = valuesInstr;
        return agentform;
      });
    }
  };

  const handleInstructionsAddField = (e) => {
    e.preventDefault();
    const values = [...instructionsFormValues];
    values.push({
      label: "instruction",
      type: "text",
      value: "",
    });
    setInstructionsFormValues(values);
  };

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  /** File Handling  */
  const [file, setFile] = useState(blank);
  const [prevIcon, setPrevIcon] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const agentIcons = [blank, ellipsisIcon, minus];
  const do_uploadIcon = () => {
    setPrevIcon(file);
    setShowAgentIcon(true);
  };
  const handleFileChange = (event) => {
    setSelectedFile(URL.createObjectURL(new Blob(event.target.files)));
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setSelectedFile(URL.createObjectURL(new Blob(event.target.files)));
  };
  const updateIcon = (img) => {
    setSelectedFile(img);
  };
  const updateAgentIcon = () => {
    setFile(selectedFile);
    handleClose();
  };

  return (
    <>
      <ToastContainer />
      <LoadModal
        showModal={showModal}
        closeModal={closeModal}
        content={content}
        submitModal={agentValues}
      />

      {/* Screen number 17 from use case wireframe By Mahadev */}

      <div className="container-fluid ">
        <div className="row mx-0">
          <div className="col-md-10 offset-md-1 px-0 pb-3">
            {/** Modal */}
            <Modal show={showAgentIcon} onHide={handleClose}>
              <Modal.Body className="modal-content-agent">
                <Container style={{ height: "100%" }}>
                  <Row style={{ marginBottom: "10px", height: "50px" }}>
                    <Col sm={11}>
                      <span
                        style={{
                          marginTop: "67px",
                          paddingTop: "20%",
                          fontSize: "20px",
                        }}
                      >
                        Agent Icon
                      </span>
                    </Col>
                    <Col sm={1}>
                      <button
                        onClick={handleClose}
                        style={{
                          height: "10px",
                          width: "10px",
                          color: "#012060",
                          background: "transparent",
                        }}
                      >
                        X
                      </button>
                    </Col>
                  </Row>
                  <Row
                    style={{ borderTop: "1px solid #D5DAE4", height: "321px" }}
                  >
                    <Col style={{ borderRight: "1px solid #D5DAE4" }} sm={6}>
                      <div
                        className="dragDropContainer"
                        onDrop={handleDrop}
                        onDragOver={(event) => event.preventDefault()}
                      >
                        <div className="upload-info">
                          <div
                            style={{
                              marginTop: "20px",
                              marginLeft: "34%",
                              height: "67px",
                              width: "67px",
                            }}
                          >
                            {selectedFile ? (
                              <img
                                src={selectedFile}
                                alt="selected file"
                                style={{ height: "67px", width: "67px" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>

                          <div style={{ marginTop: "5px", marginLeft: "46%" }}>
                            <img
                              src={uploadIcon}
                              alt="upload icon"
                              style={{ height: "17px", width: "17px" }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: "5px",
                              marginLeft: "33%",
                              color: "#3366FF",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Drag and Drop
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "11px",
                            marginLeft: "44%",
                            color: "#111621",
                            fontSize: "13px",
                          }}
                        >
                          <span> OR</span>
                        </div>
                        <input
                          type="file"
                          hidden
                          id="browse"
                          onChange={handleFileChange}
                          accept=".jpg,.jpeg,.png,.svg"
                        />
                        <label
                          htmlFor="browse"
                          className="browse-btn"
                          style={{ marginLeft: "25%" }}
                        >
                          <button
                            className="btn btn-primary cancelBtn me-3"
                            style={{ marginLeft: "20px", marginTop: "5px" }}
                            onClick={e.preventDefault}
                          >
                            Browse
                          </button>
                        </label>
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <span>Select from default Icons</span>
                        </Col>
                        <Col>
                          <NavDropdown
                            className="btn modelSelectDropdown"
                            title="All Domain"
                            id="navbarScrollingDropdown"
                          >
                            <NavDropdown.Item href="#action3">
                              Action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action4">
                              Another action
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action5">
                              Something else here
                            </NavDropdown.Item>
                          </NavDropdown>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <col></col>
                        {agentIcons.map((obj, index) => (
                          <Col sm={4} key={index}>
                            <button
                              onClick={() => updateIcon(obj)}
                              className="bg-transparent"
                            >
                              <img src={obj} alt="obj" height={67} width={67} />
                            </button>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: "20px", height: "50px", padding: 0 }}
                  >
                    <Col sm={8}></Col>
                    <Col sm={2} style={{ padding: 0 }}>
                      <button
                        className="btn btn-primary cancelBtnDark me-3"
                        style={{
                          height: "37px",
                          width: "76px",
                          marginLeft: "17px",
                          padding: "6px",
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col sm={2} style={{ padding: 0 }}>
                      <button
                        className="btn btn-primary submitBtnDark me-3"
                        onClick={updateAgentIcon}
                      >
                        Proceed
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
          </div>
        </div>

        {!saveBtn && !testAgentBtn && (
          <div className="row align-items-start mx-0">
            <div className="col-12 col-md-10 pe-3 offset-md-1">
              <Container
                className="bgw"
                style={{
                  border: "1px solid #dee2e6",
                  borderRadius: "4px",
                  marginBottom: "20px",
                }}
              >
                <Row
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    marginTop: "20px",
                    height: "41px",
                  }}
                >
                  <Col>
                    <span
                      className="subformTitle"
                      style={{
                        width: "110px",
                        marginTop: "11px",
                        marginLeft: "12px",
                      }}
                    >
                      {" "}
                      Basic Details{" "}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <form className="agentDetailsForm">
                    <Container>
                      <Row>
                        <Col sm={8} className="pt-4 pe-4">
                          <label htmlFor="name" className="label-name pt-2">
                            Agent Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={agentForm.name}
                            onChange={handleChange}
                            className="form-control input-name"
                          />
                          <label
                            htmlFor="description"
                            className="label-purpose pt-3"
                          >
                            Purpose
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            value={agentForm.description}
                            onChange={handleChange}
                            className="form-control input-purpose"
                            rows="4"
                          />
                        </Col>
                        <Col sm={4} style={{ borderLeft: "1px solid #dee2e6" }}>
                          <Row>
                            <Col>
                              <img
                                src={file}
                                className="agent-icon"
                                alt="file"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="creator-text">
                                Name of the Creator
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <a
                                to="/agentcreation"
                                class="btn btn-primary cancelBtnDark me-3 select-icon-btn"
                                href="#"
                                role="button"
                                onClick={do_uploadIcon}
                              >
                                Upload Agent Icon
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </Row>
              </Container>
              <Container
                className="bgw"
                style={{
                  border: "1px solid #dee2e6",
                  marginTop: "20px",
                  borderRadius: "4px",
                  marginBottom: "20px",
                }}
              >
                <Row style={{ marginTop: "40px" }}>
                  <Col sm={1} style={{ padding: 0 }}>
                    <hr
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        height: "4px",
                      }}
                    />
                  </Col>
                  <Col sm={2} style={{ padding: 0 }}>
                    <span
                      className="subformTitle"
                      style={{ marginLeft: "-56px" }}
                    >
                      {" "}
                      Instructions{" "}
                    </span>
                  </Col>
                  <Col sm={6} style={{ padding: 0 }}>
                    <hr
                      style={{
                        width: "133%",
                        marginTop: "10px",
                        marginLeft: "-105px",
                      }}
                    />
                  </Col>
                  <Col sm={2} style={{ padding: 0 }}>
                    <button
                      className="btn btn-primary cancelBtn me-4"
                      style={{ marginTop: "-6px", marginLeft: "39px" }}
                      onClick={handleInstructionsAddField}
                    >
                      + Add Instructions
                    </button>
                  </Col>
                  <Col sm={1} style={{ padding: 0 }}>
                    <hr
                      style={{
                        width: "78%",
                        marginTop: "10px",
                        marginLeft: "18px",
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "6px", marginBottom: "20px" }}>
                  <Col style={{ paddingLeft: "26px" }}>
                    {agentForm.instruction && (
                      <form>
                        {agentForm.instruction.map((obj, index) => {
                          let tempObj = {
                            label: "instruction",
                            type: "text",
                            value: obj,
                          };
                          return (
                            <DynamicInput
                              key={index}
                              objValue={tempObj}
                              onChange={handleInstructionsChange}
                              index={index}
                              deleteField={handleInstructionsDeleteField}
                            />
                          );
                        })}
                      </form>
                    )}
                    <form>
                      {instructionsFormValues.map((obj, index) => (
                        <DynamicInput
                          key={index}
                          objValue={obj}
                          onChange={handleInstructionsChange}
                          index={index}
                          deleteField={handleInstructionsDeleteField}
                        />
                      ))}
                    </form>
                  </Col>
                </Row>
              </Container>
            </div>
            <ChatPopup />
          </div>
        )}
      </div>

      {saveBtn && !testAgentBtn && <SelectModelList />}
      
      {testAgentBtn && (
        <TestAgentAchain
          agentIdTest={agentID}
          darkMode={darkMode}
          activityChainID={activityChainID}
        />
      )}
    </>
  );
};

export default AgentsListTest;
