import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
const ModalComp = () => {
    const [show, setShow] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        // Show dropdown only if "Yes" is selected
        setShowDropdown(value === 'yes');
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <button className="btn btn-primary submitBtn" onClick={handleShow}>Show</button>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <p className='text-center'>Model Details</p>

                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Model Name</label>
                        <input type="text" class="form-control border border-primary" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Description</label>
                        <input type="text" class="form-control border border-primary" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Purpose</label>
                        <input type="text" class="form-control border border-primary" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Domain</label>
                        <select class="form-control border border-primary form-select-sm" aria-label=".form-select-sm example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Task</label>
                        <select class="form-control border border-primary form-select-sm" aria-label=".form-select-sm example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Train with Reward and PPO Model?</label>
                        <input type="checkbox" class="form-control border border-primary" id="exampleInputPassword1" />
                    </div>




                </Modal.Body>

                <Modal.Footer id="footer">
                    <a class="btn btn-primary cancelBtn me-3" onClick={handleClose} role="button">
                        CANCEL
                    </a>
                    <a class="btn btn-primary submitBtn me-3" href="#" role="button">
                        PROCEED
                    </a>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default ModalComp
