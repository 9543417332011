import { height, style } from "@mui/system";
import { useMemo, useCallback, useState, memo, useContext, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  NodeResizeControl,
  Handle,
  Position,
  MarkerType
} from "reactflow";
import styl from "./Cnode.module.css";
import plus from "../../assets/img/plusIcon.png";
import { addEdge, useNodesState, useEdgesState, Controls } from "reactflow";
import { Row, Col, Container } from "react-bootstrap";
import avatar from "../../assets/img/agentAvatar.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import axios from "axios";
const handleStyle = { left: 10 };

function Cnode({ data, id }) {
  const onChange = useCallback(evt => {
    console.log(evt.target.value);
  }, []);
  const initialNodes = [
    {
      id: "1",
      type: "textUpdater",
      position: { x: 100, y: 50 },
      data: data.subnodes
    },
    {
      id: "2",
      type: "textUpdater",
      position: { x: 0, y: 0 },
      data: { label: "2", name: "Agent 1" }
    }
  ];
  const initialEdges = [{ id: "e1-2", source: "2", target: "1" }];
  const styles = {
    background: "red"
  };
  var subnodes = data.subnodes?.map(e => {
    e.type = "textUpdater";
    e.data = { ...e.data, name: e.data.label };
    console.log(e.data.label);
    return e;
  });
  const [nodes, setNodes, onNodesChange] = useNodesState(subnodes);
  console.log(data.subedges, "%%%%%%%%%%%%%%%%%%%%%%%%%");

  const [isColChild, setIsColChild] = useState(true);
  const nodeTypes = useMemo(() => ({ textUpdater: Cnode }), []);

  var subedges = data.subedges?.map(e => {
    e["type"] = "smoothstep";
    e["markerEnd"] = {
      type: MarkerType.ArrowClosed
    };
    return e;
  });

  const [edges, setEdges, onEdgesChange] = useEdgesState(subedges);
  const [bo, setBo] = useState(false);
  const [agents, setAgents] = useState([]);
  const { darkMode, accessToken } = useContext(AuthContext)


  const collapse = (bool, id) => {
    setIsColChild(bool);
    data.nodeColapse(id, !bool);
    // setNodes(nodes)
    setBo(true);
    setEdges(edges);
  };


  
  return (
    <div
      className={
        styl.wid + " "
        // + isColChild? styl.yes: styl.no
      }
      style={{
        backgroundColor: darkMode ? "#212542" : "",
        border: darkMode ? "1px solid orange" : ""
      }}
    >
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Right} id="a" />
      <div className={"d-flex align-items-center justify-content-evenly px-3 "}>
        <div className={styl.ninfo}>
          <div>
            {!data.subnodes || !isColChild ? <img src={avatar}></img> : ""}
          </div>
          <div>
            <h5 style={{ color: darkMode ? "#C7D2F2" : "" }}>{data.name}</h5>
          </div>

          {data.subnodes && isColChild ? <div className={styl.i}>i</div> : ""}
        </div>
        {/* <button className="btn-secondary close " style={{color: 'blue', border: '1px',}}>2</button> */}
        <div className={styl.colPlus}>
          {!data.subnodes || !isColChild ? <div className={styl.i}>i</div> : ""}
          <Link to={"/agentFactory/createAgent"}>
            <img src={plus}></img>
          </Link>
          {/* <div className={styl.c} onClick={() => {data.Coll.handleState(!data.Coll.isCol);console.log(data.Coll)}}>E</div> */}
          {data.subnodes ? (
            <div
              className={styl.c}
              onClick={() => {
                collapse(!isColChild, id);
              }}
            >
              E
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        style={
          data.subnodes && isColChild ? { height: "200px" } : { height: "auto" }
        }
      >
        {isColChild ? (
          <ReactFlowProvider>
            <NodeResizeControl />
            <ReactFlow
              className={styl.rflowst + " rtwsss"}
              nodes={nodes}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              edges={edges}
              nodesDraggable={false}
              // draggable = {data.subnodes? true: false}
              fitView
            >
              <Controls
                id="flow-cont"
                position="bottom-right"
                showZoom={false}
                showFitView={true}
                showInteractive={false}
                className="zoomTransform row"
              ></Controls>
            </ReactFlow>
            {/* <Controls/> */}
          </ReactFlowProvider>
        ) : (
          <ReactFlowProvider></ReactFlowProvider>
        )}
      </div>
      {/* <div>
        <label htmlFor="text">Text:</label>
        <input id="text" name="text" onChange={onChange} className="nodrag" />
      </div> */}
    </div>
  );
}

export default Cnode;



