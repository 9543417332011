import React, { useState } from "react";
import "./EvaluateAndGraph.css";
import EvaluateModel from "../EvaluateModel";
import { Nav } from "react-bootstrap";
import Content from "../../graph/Content";
function EvaluateAndGraph({isPPO, darkMode}) {
  const [buttontoggle, setbuttontoggle] = useState(true);

  
  return (
    <>
      <div className="px-0">
        {/* <div className="EvaluateAndGraphouter"> */}
        {/* <Nav variant="tabs" defaultActiveKey="playground">
            <Nav.Item>
              <Nav.Link
                eventKey="playground"
                onClick={() => setbuttontoggle(true)}
              >
                MODEL PLAYGROUND
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="metrics"
                onClick={() => setbuttontoggle(false)}
              >
                EVALUATION METRICS
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
        {/* </div> */}

        <div className="header pb-0 px-4" style={{background: darkMode ? '#34385A' : '', alignItems: "initial", border: 'none' }}>
          <div className="header-left px-0" >
            <button
              className={
                !buttontoggle ? "btn-header" : "btn-header btn-header-select"
              }
              style={{color: darkMode ? '#BAC9F1' : ''}}
              onClick={() => setbuttontoggle(true)}
            >
              Model Playground
            </button>
            <button
              className={
                buttontoggle ? "btn-header" : "btn-header btn-header-select"
              }
              style={{color: darkMode ? '#BAC9F1' : ''}}
              onClick={() => setbuttontoggle(false)}
            >
              Evaluation Metrics
            </button>
          </div>

          <div className="header-right my-auto">
            <div className="wrapper">
              <div className="color-area"></div>
              <p className="mb-0" style={{color: darkMode ? '#BAC9F1' : ''}}>Model (Pre Reward Training)</p>
            </div>
            <div className="wrapper">
              <div className="color-area color-area-selected"></div>
              <p className="mb-0" style={{color: darkMode ? '#BAC9F1' : ''}}>Model (Post Reward Training)</p>
            </div>
          </div>
        </div>
        {/* <button onClick={()=>setbuttontoggle(true)}>MODEL PLAYGROUND</button>
        <button onClick={()=>setbuttontoggle(false)}>EVALUATION METRICS</button> */}
      </div>

      {buttontoggle ? <EvaluateModel isPPO={isPPO} darkMode={darkMode} /> : <Content darkMode={darkMode} />}
    </>
  );
}

export default EvaluateAndGraph;
