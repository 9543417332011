import React, { useContext, useEffect, useRef, useState } from 'react'
import AgentSideBar from '../sideBar/AgentSideBar'
import styles from './AgentChainMapping.module.css'
import ChatInterface from '../../../AChain/FunctionalChatInterface/ChatInterface'
import AgentDock from '../dock/AgentDock'
import { DockContext } from '../../../DockWrapper/DockContext'
import NavBar from '../navBar/NavBar'
import { useNavigate } from 'react-router-dom'
import NormalChat from '../chat/NormalChat'
import dottedPatternBG from '../../../../assets/img/doted-patternbg.png'
import CustomNavbar from '../../../CustomNavbar/CustomNavbar'



export default function AgentChainMapping() {

    const {
        isAgentDocked, setIsAgentDocked, selectedKnowledgeWork,
        activity_master_json,
        isAgentDetailsChanged, setIsAgentDetailsChanged
    } = useContext(DockContext);

    const [data, setData] = useState()
    const [title, setTitle] = useState("AgentDetails")
    const [saveAgentDetails, setSaveAgentDetails] = useState(false)
    const [selectedPos, setSelectedPos] = useState(0)
    const [finished, setFinished] = useState([])

    const checkIsComplete = (item) => {
        let output = false
        activity_master_json?.activities?.map((data) => {

            if (data.name == item.name) {
                output = true
            }

        })

        return output
    }

    const populateDataForSideBar = () => {
        let items = []
        console.log(activity_master_json)
        activity_master_json?.activities?.map((item, i) => {

            console.log(item, activity_master_json?.activities.includes(item.name))

            items.push({
                name: item.name,
                isCompleted: checkIsComplete(item),
                isActive: i === 0
            })

        })
        console.log(items)
        setData(items)
    }

    useEffect(() => {

        populateDataForSideBar()

    }, [activity_master_json])

    useEffect(() => {
        setIsAgentDocked(true)
        populateDataForSideBar()
    }, [])
    const navigate = useNavigate()
    useEffect(() => {
        if (!selectedKnowledgeWork) {
            navigate("/activityList")
        }
    }, [selectedKnowledgeWork])

    // console.log(activity_master_json?.activities.find((item) => { data.find((datum) => { return datum.isActive }).name === item.name }))

    const navAgentCreation = [{ name: "CREATE KNOWLEDGE WORK" }, { name: `Knowledge Work: ${selectedKnowledgeWork.name}` }]

    const handleSummaryNavigation = () => {
        console.log(isAgentDetailsChanged, "agent details changed")
        if (isAgentDetailsChanged) {
            if (window.confirm("Modification are not saved, redirection might cause loss of unsaved data")) {

                navigate("/summary")
            }
        }

    }

    const UpdateActivityDetailsBtnRef = useRef(null)
    const [contextForChat, setContextForChat] = useState({})

    const contextGenerator = () => {

        const context = activity_master_json?.activities?.find((activity) => {
            let selectedActivity = data?.find((datum) => { return datum.isActive === true })
            console.log(selectedActivity)
            return activity?.name === selectedActivity?.name
        })
        console.log(context)
        setContextForChat(context)

    }

    useEffect(() => {

        contextGenerator()

    }, [data, activity_master_json])

    const [isLoadingForUpdateDataBtn, setIsLoadingForUpdateDataBtn] = useState(false)
    const [disableUpdateDataBtn, setDisableUpdateDataBtn] = useState(true)

    const links = [
        { name: "Knowledge Work", route: "/activityList", isActive: false },
    ];

    return (
        <div className='w-100 h-100'>
            <CustomNavbar links={links} profile={true}></CustomNavbar>
            <div className='d-flex flex-row justify-content-between' style={{ background: 'transparent linear-gradient(90deg, #212542 0%, #222C53 49%, #212542 100%) 0% 0% no-repeat padding-box' }}>
                <div className='d-flex flex-column' style={{ marginLeft: '2.5%' }}>
                    <span className='mt-4 mb-1' style={{ font: 'normal normal bold 17px/21px Calibri', letterSpacing: '0.55px' }}>Create Knowledge Work</span>
                    <span className='mb-4 mt-1' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Knowledge Work: {activity_master_json?.name}</span>
                </div>
                <div className='d-flex flex-row mt-auto mb-2' style={{ marginRight: '2.5%' }}>
                    <a style={{
                        textDecoration: 'underline', font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#ADC2FF', lineHeight: '250%'
                    }} href='#' onClick={handleSummaryNavigation}>Summary</a>
                    <span style={{ border: '1px solid #899DD4' }} className='mx-4'></span>
                    <button className='btn me-2' style={{ font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#012060', background: '#D7E5FF 0% 0% no-repeat padding-box', borderRadius: '4px' }} onClick={() => { navigate('/summary') }}>Close</button>
                    <button className='btn ms-2' style={{ background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF' }} onClick={() => setSaveAgentDetails(true)}>Save</button>
                </div>
            </div >
            <div className='container-fluid'>
                <div className='d-flex flex-row mx-2 mt-4 align-items-start'>
                    <div className='d-flex flex-column' style={{ background: '#42466C 0% 0% no-repeat padding-box', borderRadius: '4px 0px 0px 4px', minWidth: '20%', maxWidth: '20%', minHeight: '68vh', maxHeight: '68vh', overflowY: 'auto', order: '1' }}>
                        <div className='w-100 d-flex flex-row pt-4 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#E5EAF1', position: 'sticky', top: '0', background: '#42466C 0% 0% no-repeat padding-box' }}>
                            Agents
                        </div>
                        <AgentSideBar data={data} title={"Agents"} setData={setData} selectedPos={selectedPos} setSelectedPos={setSelectedPos} finished={finished} />
                    </div>
                    <div className='d-flex flex-column justify-content-end w-50' style={{ maxHeight: "68vh", minWidth: '30%', maxWidth: '30%', order: '3' }}>

                        <div className='d-flex flex-column justify-content-end' style={{ background: `url(${dottedPatternBG})`, borderRadius: '0px 0px 0px 0px', height: "100%", minHeight: "60.5vh", backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundColor: "#E1E7F9" }}>
                            <NormalChat

                                context={
                                    contextForChat ?
                                        JSON.stringify(contextForChat)
                                        :
                                        JSON.stringify({ "context": "there is no much context, please let the user know that there is no context and Ask the user to provide the context in detail" })}
                                UpdateActivityDetailsBtnRef={UpdateActivityDetailsBtnRef}
                                data={data}
                                setData={setData}
                                setDisableUpdateDataBtn={setDisableUpdateDataBtn}
                                setIsLoadingForUpdateDataBtn={setIsLoadingForUpdateDataBtn} />
                        </div>

                        <div className='d-flex align-items-center justify-content-end'>


                            <button
                                className='btn btn-primary m-2 btn-sm'
                                style={{ width: "100px", height: "30px" }}
                                disabled={disableUpdateDataBtn}
                                onClick={() => UpdateActivityDetailsBtnRef.current()}>
                                {
                                    isLoadingForUpdateDataBtn ?
                                        <div class="spinner-border text-info" style={{ height: "1rem", width: "1rem" }} role="status">
                                            <span class="visually-hidden" >Loading...</span>
                                        </div>

                                        :
                                        "Update Details"
                                }
                            </button>
                        </div>

                    </div>

                    <div className='d-flex flex-column w-50' style={{ background: '#34385A 0% 0% no-repeat padding-box', borderRadius: '0px 0px 4px 4px', minHeight: '68vh', maxHeight: '68vh', overflowY: 'scroll', order: '2', minWidth: '50%', maxWidth: '50%' }}>
                        <div className='p-2 d-flex flex-column'>
                            <AgentDock title={title} setTitle={setTitle} data={data} setData={setData} dynamicFuncForSave={saveAgentDetails} setSaveAgentDetails={setSaveAgentDetails} setSelectedPos={setSelectedPos} setFinished={setFinished} finished={finished} />
                        </div>
                    </div>
                </ div>
            </div >

        </div>

    )
}
