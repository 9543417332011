export let completedData = [];
for (let i = 1; i <= 7; i++) {
  completedData.push({
    id: i,
    name: `Completed ${i}`,
    description: `This is a description for Completed ${i}`,
    dateRequested: `${i} Jan 2022`,
    requestedBy: `${'John Doe'}`,
    Domain: "Life Science",
    SubDomain: "Health Care",
    Clients: `Client${i}`,
  });
}
export let newData = [];
for (let i = 1; i <= 10; i++) {
  newData.push({
    id: i,
      name: `New ${i}`,
    description: `This is a description for New ${i}`,
    dateRequested: `${i} Jan 2022`,
    requestedBy: `${'John Doe'}`,
    Domain: "Life Science",
    SubDomain: "Health Care",
    Clients: `Client${i}`,// this gives ratings from 1 to 5 in a cyclic manner
  });
}
export let inProgressData = [];
for (let i = 1; i <= 5; i++) {
  inProgressData.push({
    id: i,
    name: `In Progress ${i}`,
    description: `This is a description for In Progress ${i}`,
    dateRequested: `${i} Jan 2022`,
    requestedBy: `${'John Doe'}`,
    Domain: "Life Science",
    SubDomain: "Health Care",
    Clients: `Client${i}`,/// this gives ratings from 1 to 5 in a cyclic manner
  });
}

     
export let draftData = [];
for (let i = 1; i <= 10; i++) {
  draftData.push({
      name: `Draft${i}`,
    Description: `This is a description for Draft${i}`,
    DateCreated: `${i} Jan 2022`,
    Domain: "Life Science",
    SubDomain: "Health Care",
    Clients: `Client${i}`,
    Activities: `${i}`,
    Status: "Draft",
    Rating: `${(i % 5) + 1}`, // this gives ratings from 1 to 5 in a cyclic manner
  });
}
export let deployedData = [];
for (let i = 1; i <= 10; i++) {
  deployedData.push({
      name: `Deployed${i}`,
    Description: `This is a description for Draft${i}`,
    DateCreated: `${i} Jan 2022`,
    Domain: "Life Science",
    SubDomain: "Health Care",
    Clients: `Client${i}`,
    Activities: `${i}`,
    Status: "Draft",
    Rating: `${(i % 5) + 1}`, // this gives ratings from 1 to 5 in a cyclic manner
  });
}
export let hyperParameterData = [];
for (let i = 1; i <= 10; i++) {
    hyperParameterData.push({
        name: `Paramter Name${i}`,
      input: `Input ${i}`,
    });
  }
export let agentsData = [];
for (let i = 1; i <= 5; i++) {
    agentsData.push({
        id:i,
        name: `Agent ${i}`,
      intelligence: `Diagnostic`,
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, libero minima accusantium repudiandae magni quidem quia molestias aliquam dolore assumenda.`
    });
  }
export let activitesData = [];
for (let i = 1; i <= 5; i++) {
    activitesData.push({
        id:i,
        name: `Activity ${i}`,
      intelligence: `Diagnostic`,
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, libero minima accusantium repudiandae magni quidem quia molestias aliquam dolore assumenda.`
    });
  }

// for (let i = 1; i <= 5; i++) {
//     recommendedModelsData.push({
//         id:i,
//         name: `Model ${i}`,
//       description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, libero minima accusantium repudiandae magni quidem quia molestias aliquam dolore assumenda.`
//     });
//   }

  export let recommendedModelsData = [
    {
      "id": 1,
      "title": "meta-llama/Meta-Llama-3-8B-Instruct",
      "description": "Accuracy: 95%",
      "isComplete": true,
      "base_model": "Linear Regression",
      "hyperparameters":{
        "max_seq_length": 3072,
        "learning_rate": 0.000005,
        "num_train_epochs": 3,
        "per_device_train_batch_size": 1,
        "gradient_accumulation_steps": 2,
        "logging_steps": 10,
        "save_strategy": "epoch",
        "evaluation_strategy": "epoch",
        "bf16": true,
        "gradient_checkpointing": true
      }
    },
    {
      "id": 2,
      "title": "Model B",
      "description": "Precision: 90%",
      "isComplete":false,
      "base_model": "Random Forest",
      "hyperparameters": {
        "n_estimators": 100,
        "max_depth": 10
      }
    },
    {
      "id": 3,
      "title": "Model C",
      "description": "F1 Score: 92%",
      "isComplete": false,
      "base_model": "Support Vector Machine",
      "hyperparameters": {
        "C": 1.0,
        "kernel": "rbf"
      }
    },
  ]
  