// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vertical {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 20%;
  border-radius: 4px;
  opacity: 1;
  padding: 2%;
  transform: rotate(-90deg);
  align-items: stretch;
  height: 51%;
  width: 158%;
}
.VBar {
  width: 90%;
}
/* .VBar_left .MuiLinearProgress-bar1Determinate{
    background-color:#A0A0A0 !important;
} */

.Bar p {
  text-align: left;
  font: normal normal normal 14px/17px Calibri;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
}
.Vertical p {
  width: 135px;
  height: 19px;
  /* UI Properties */
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-family: Calibri;

  /* font: normal normal normal 16px/24px Calibri; */
  letter-spacing: 0.67px;
  color: #77768c;
  opacity: 1;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/graph/VerticalBars/VerticalBars.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,oBAAoB;EACpB,WAAW;EACX,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;;GAEG;;AAEH;EACE,gBAAgB;EAChB,4CAA4C;EAC5C,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;AACA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;;EAEpB,kDAAkD;EAClD,sBAAsB;EACtB,cAAc;EACd,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".Vertical {\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n  margin-bottom: 20%;\n  border-radius: 4px;\n  opacity: 1;\n  padding: 2%;\n  transform: rotate(-90deg);\n  align-items: stretch;\n  height: 51%;\n  width: 158%;\n}\n.VBar {\n  width: 90%;\n}\n/* .VBar_left .MuiLinearProgress-bar1Determinate{\n    background-color:#A0A0A0 !important;\n} */\n\n.Bar p {\n  text-align: left;\n  font: normal normal normal 14px/17px Calibri;\n  letter-spacing: 0px;\n  color: #888888;\n  opacity: 1;\n}\n.Vertical p {\n  width: 135px;\n  height: 19px;\n  /* UI Properties */\n  text-align: left;\n  font-size: 16px;\n  font-weight: normal;\n  font-family: Calibri;\n\n  /* font: normal normal normal 16px/24px Calibri; */\n  letter-spacing: 0.67px;\n  color: #77768c;\n  opacity: 1;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
