import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default function GraphShow(props) {
  const [metricsData, setMetricsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://172.208.104.191:5002/get_metrics', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "my_folder": "/data/llm_factory/Model_Orchestrator_finetuned_models/sft/runs"
          })
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data)
          setMetricsData(data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const interval = setInterval(fetchData, 3000);

    return () => {
      clearInterval(interval); // Clear interval on unmount
    };
  }, []);

  const renderChart = (metricKey, title, xAxisLabel, yAxisLabel) => {
    if (!metricsData || !metricsData[metricKey]) {
      return <p>Loading...</p>;
    }


    return (
      <div className="col-6">
        <div className="card mb-5">
          <div className="px-4 py-2">
            <span className="tabSub-head mb-2">{title}</span>
            <Line
              height={150}
              width={300}
              options={{
                scales: {
                  y: {
                    title: {
                      display: true,
                    
                      text: yAxisLabel
                    }
                  },
                  x: {
                    title: {
                      display: true,
                      text: xAxisLabel
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
              
              data={{
                labels: metricsData[metricKey].map(item => item[0].toString()),
                datasets: [
                  {
                    data: metricsData[metricKey].map(item => item[1]),
                    fill: true,
                    borderWidth: 2,
                    borderColor: 'rgba(51, 102, 255, 1)',
                    responsive: true,
                    pointRadius: 0,
                  },
                ],
              }}
              // data={{
              //   labels: ['January', 'February', 'March', 'April', 'May', 'June'],
              //   datasets: [{
              //     label: 'Demo data',
              //     data: [12, 19, 3, 5, 2, 3],
              //     fill: false,
              //     borderColor: 'rgb(75, 192, 192)',
              //     tension: 0.1
              //   }]
              // }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* {metricsData && ( */}
        <>
          <div className="row mx-0 mt-0 mb-3">
            {console.log("working",metricsData)}
          </div>
          <div className="row mx-0 mt-0 mb-1">
            {renderChart('train/learning_rate', 'Learning Rate', 'Steps', 'Rate')}
            {renderChart('train/loss', 'Loss Rate', 'Steps', 'Rate')}
            {/* Add more metrics using renderChart */}
          </div>
          <div className="row mx-0 mt-0 mb-1">
            {renderChart('train/train_samples_per_second', "train/train_samples_per_second", 'Steps', 'Rate')}
            {renderChart('train/train_steps_per_second', 'train/train_steps_per_second', 'Steps', 'Rate')}
            {/* Add more metrics using renderChart */}
          </div>
        </>
       {/* )} */}
      {/* {!metricsData && <p>Loading...</p>} */}
    </div>
  );
}
