import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  recommendedModelsData,
  newData,
  completedData,
  inProgressData,
} from "./Data/dummyData";
import { fetchChartData } from"./Data/chartData";
import MoHeader from "./MoHeader";
import "./TrainingModel.css";
import { Button, Card, Form, Nav, Table } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import Papa from "papaparse";
import { AuthContext } from "../Auth/AuthContext";
import FineTuningTemplate from "../../assets/templates/Medical_Data-Template.csv";
import RewardTemplate from "../../assets/templates/Reward_Model_data_Template.csv";
import CustomTable from "../Table/CustomTable";
import { TableData, TableColumn } from "../ModelOrchestrator/FineTunining/DB";
import {  Bar, Line } from "react-chartjs-2";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import CustomTableNew from "./Data/CustomTable";


const TrainingDPO = ({ darkMode, handleToggle }) => {
  const { accessToken } = useContext(AuthContext);
  const [TableDataLocal, setTableDataLocal] = useState(TableData);
 
  const [index, setindex] = useState(1);
  const [TableColumnLocal, setTableColumnLocal] = useState(TableColumn);
 

  let templateSelector = {
    "/finetuning": FineTuningTemplate,
    "/rewardModel": RewardTemplate,
    "/rewardppo": RewardTemplate,
  };
  let location = useLocation();
  let path = location.pathname;
  let { id } = useParams();
  const {knowledgeWorkDetails, selectedItems,knowledgeWorkName,hyperparameters,hyperparameterObjectData,responseText,sftModelUrl } = location.state || {};
  console.log(selectedItems, "hypos: ", hyperparameters);
  const knowledgeWorkNameNew = selectedItems.knowledgeWorkName;

  // Provide default values if selectedItems is undefined or does not contain the expected data
  const selectedModelIds = selectedItems.recommendedModels || [];
  const selectedAgent = (selectedItems?.agents || [])[0] || {}; // Extract first agent
  const selectedAgentTitle = selectedAgent.title || "";

  // Ensure `agentsData` and `recommendedModelsData` are defined and have data
  
  let summaryData = newData.find((item) => item.id === Number(id));
  const sections = [
    {
      name: "Supervised Fine-tuning",
      steps: [
        "Upload Data",
        "Basic Hyperparameters",
        "Advanced Hyperparameters",
        "Peft Parameters",
        "Evaluate SFT",
        "Evaluate Model Generative Quality",
        
      ],
    },
    {
      "name": "DPO",
      steps: [
        "Upload Data",
        "Model Paramters",
        "Evaluate Model",
      ]
    },
  ];
  const [modelHyperparameterTableData, setModelHyperparameterTableData] = useState(TableDataLocal[4])
  const [modelHyperparameterObjectData, setModelHyperparameterObjectData] = useState({})
  const [dpoModelUrl, setDPOModelUrl] = useState("")
  const handlehyperparameterTableDataChange = (transformedData) => {
    console.log("Updated Hyperparameters Table Data:", transformedData);
    const result = transformedData.reduce((acc, item) => {
      const parameterName = item["Parameter Name"].value;
      const inputValue = item["Input"].value;

      // Add the parameter name and input value to the result object
      acc[parameterName] = inputValue;
      return acc;
    }, {});
   
    setModelHyperparameterTableData(transformedData);
    setModelHyperparameterObjectData(result)
    // Optionally, send updatedData to API here
    // sendToApi(updatedData);
  };

  const defaultDPOHyperparameters = {
    "model_name_or_path": "meta-llama/Meta-Llama-3-8B-Instruct",
    "sft_model_id": 'meta-llama/Meta-Llama-3-8B-Instruct',
    "load_in_4bit": modelHyperparameterObjectData.load_in_4bit,
    load_in_8bit:false,
    train_dataset_path: "None",
    val_dataset_path: "None",
    // "torch_dtype": "bfloat16",
    // "bnb_4bit_use_double_quant": modelHyperparameterObjectData.bnb_4bit_use_double_quant,
    // "bnb_4bit_quant_type": modelHyperparameterObjectData.bnb_4bit_quant_type,
    "per_device_train_batch_size": parseInt(modelHyperparameterObjectData.per_device_train_batch_size),
    per_device_eval_batch_size:1,
    "gradient_accumulation_steps": parseInt( modelHyperparameterObjectData.gradient_accumulation_steps),
    "learning_rate": parseFloat(modelHyperparameterObjectData.learning_rate),
    "lr_scheduler_type": modelHyperparameterObjectData.lr_scheduler_type,
    num_train_epochs:5,
    // "max_steps": parseInt(modelHyperparameterObjectData.max_steps),
    "logging_steps": parseInt(modelHyperparameterObjectData.logging_steps),
    save_strategy:'epoch',
    evaluation_strategy:'epoch',
    max_grad_norm: 0.3,
    warmup_ratio:0.03,
    gradient_checkpointing: true,
    "output_dir": modelHyperparameterObjectData.output_dir,
    "optim": modelHyperparameterObjectData.optim,
    // "warmup_steps": parseInt(modelHyperparameterObjectData.warmup_steps),
    "fp16": modelHyperparameterObjectData.fp16,
    // "tf32": modelHyperparameterObjectData.tf32,
    "remove_unused_columns": modelHyperparameterObjectData.remove_unused_columns,
    "max_length":parseInt( modelHyperparameterObjectData.max_length),
    "max_prompt_length": parseInt(modelHyperparameterObjectData.max_prompt_length),
    // "trainer_accelerator_state": 'None',
    "beta": 0.1,
    // "model_adapter_name": modelHyperparameterObjectData.model_adapter_name,
    // "ref_adapter_name": modelHyperparameterObjectData.ref_adapter_name,
    "dataset_path":"dpo_dataset2.csv",
    use_peft: true,
    lora_r: 8,
    lora_target_modules : ["q_proj", "k_proj", "v_proj", "o_proj", "gate_proj", "up_proj", "down_proj"],
    lora_alpha: 32,   
    lora_dropout: 0.05,   
    bias: "none",   
    task_type: "CAUSAL_LM"
};


  const [currentSection, setCurrentSection] = useState(1);
  const [currentStep, setCurrentStep] = useState(sections.map(() => 0));
  const [currentModelId, setCurrentModelId] = useState(1);
  const [temperature, setTemperature] = useState(0);
  const [topP, setTopP] = useState(0.43);
  const [topK, setTopK] = useState(1);
  const [tokensToOutput, setTokensToOutput] = useState(256);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const ws = useRef(null);
  const endOfMessagesRef = useRef(null);
  const handleSliderChange = (sliderName, value) => {
    switch (sliderName) {
      case "temperature":
        setTemperature(value);
        break;
      case "topP":
        setTopP(value);
        break;
      case "topK":
        setTopK(value);
        break;
      case "tokensToOutput":
        setTokensToOutput(value);
        break;
      default:
        break;
    }
  };
  const [selectedModels, setSelectedModels] = useState(recommendedModelsData.filter((item) =>
    selectedModelIds.includes(item.id))); 
  const navigate = useNavigate();
  useEffect(() => {
    ws.current = new WebSocket('ws://multimodel.eastus.cloudapp.azure.com:8000/ws/chat');

    ws.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.message) {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [currentSection]: {
              ...prevMessages[currentSection],
              [currentStep[currentSection]]: [
                ...(prevMessages[currentSection]?.[currentStep[currentSection]] || []),
                { text: data.message, sender: 'ai' }
              ]
            }
          }));
        } else {
          console.error('Unexpected message format:', data);
        }
      } catch (error) {
        console.error('Error parsing message data:', error);
        setMessages((prevMessages) => ({
          ...prevMessages,
          [currentSection]: {
            ...prevMessages[currentSection],
            [currentStep[currentSection]]: [
              ...(prevMessages[currentSection]?.[currentStep[currentSection]] || []),
              { text: event.data, sender: 'ai' }
            ]
          }
        }));
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [currentSection, currentStep]);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const stepToTemplateId = {
    0: 5,
    1: 6,
    // Add more mappings if needed
  };
  const sendMessage = () => {
    if (message.trim()) {
      // Determine the template_id based on the current step
      const currentTemplateId = stepToTemplateId[currentStep[currentSection]] || 1; // Default to 1 if not found
  
      // Create the payload including the message and template_id
      const payload = {
        message,
        template_id: currentTemplateId
      };
  
      // Send the payload to the WebSocket server
      ws.current.send(JSON.stringify(payload));
  
      // Update local messages state
      setMessages((prevMessages) => ({
        ...prevMessages,
        [currentSection]: {
          ...prevMessages[currentSection],
          [currentStep[currentSection]]: [
            ...(prevMessages[currentSection]?.[currentStep[currentSection]] || []),
            { text: message, sender: 'user' }
          ]
        }
      }));
  
      // Clear the input field
      setMessage('');
    }
  };

  const renderChat = (sectionIndex, stepIndex) => {
    const stepMessages = messages[sectionIndex]?.[stepIndex] || [];

    return (
      <>
        {stepMessages.length === 0 && (
          <p className="text-center text-dark mt-4">
            {sections[sectionIndex].steps[stepIndex]}
          </p>
        )}
        <div className="d-flex flex-column-reverse flex-grow-1 overflow-scroll p-4" style={{ minHeight: '75%' }}>
          <div className="d-flex flex-column w-100">
            {stepMessages.map((msg, index) => (
              <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                  <p className="m-0 text-dark">{msg.text}</p>
                </div>
              </div>
            ))}
            {/* <div ref={endOfMessagesRef} /> */}
          </div>
        </div>
      </>
    );
  };
 
  useEffect(() => {
    if (currentStep.length === 0) {
      setCurrentStep(new Array(selectedModelIds.length).fill(0));
    }
  }, [selectedModelIds]);

  useEffect(() => {
    const stepName = sections[currentSection].steps[currentStep];
    
    // Define request body
    const requestBody = {
      knowledgeWorkDetails,
      currentStepName: stepName,
      // Optionally include hyperparameters or other details
    };

    // Make API call
    axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', requestBody, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        console.log('Success:', response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });

  }, [currentStep === 'Upload Data', currentSection, knowledgeWorkDetails]);

  const handleNextStep = async (modelId) => {
    setCurrentStep((prevSteps) => {
      const newSteps = [...prevSteps];
      const currentSectionSteps = sections[currentSection].steps.length;
  
      if (newSteps[currentSection] < currentSectionSteps - 1) {
        // Move to the next step within the current section
        newSteps[currentSection] += 1;
      } else if (currentSection < sections.length - 1) {
        // Move to the next section if at the end of the current section
        setCurrentSection(currentSection + 1);
        // Move to the first step of the next section
        newSteps[currentSection + 1] = 0;
      }
  
      // Perform API call with current step details
      const stepName = sections[currentSection].steps[newSteps[currentSection]];
  
      const requestBody = {
        knowledgeWorkDetails: {
          stepName,
          ...knowledgeWorkDetails,
          hyperparameters: {

            ...modelHyperparameterTableData
          },
        },
      
      };
  
      axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          console.log('Success:', response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  
      return newSteps;
    });
  };
  
  const handlePrevStep = async () => {
    setCurrentStep((prevSteps) => {
      const newSteps = [...prevSteps];
      
      if (newSteps[currentSection] > 0) {
        // Move to the previous step within the current section
        newSteps[currentSection] -= 1;
      } else if (currentSection > 0) {
        // Move to the previous section if at the start of the current section
        setCurrentSection(currentSection - 1);
        // Move to the last step of the previous section
        newSteps[currentSection - 1] = sections[currentSection - 1].steps.length - 1;
      }
  
      // Perform API call with current step details
      const stepName = sections[currentSection].steps[newSteps[currentSection]];
  
      const requestBody = {
        knowledgeWorkDetails: {
          stepName,
          // Include other necessary details here
          hyperparameters: {
            // Include model hyperparameters here
          },
        },
      };
  
      axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          console.log('Success:', response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  
      return newSteps;
    });
  };
  
  const isPrevButtonDisabled = () => {
    return currentSection === 1 && currentStep[currentSection] === 0;
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]); // State to store CSV data
  const [completed, setCompleted] = useState(false);
  const [logs,setLogs] = useState("");
  const [isFinetuningStarted, setIsFinetuningStarted] = useState(false)
  const [isTrainingStarted, setIsTrainingStarted] = useState(false)
  const [isTrainingCompleted, setIsTrainingCompleted] = useState(false)
  const [progress, setProgress] = useState(0);
  const [trainerMartics, setTrainerMatrics] = useState([])
  const [displayedData, setDisplayedData] = useState(newData);
  const [context, setContext] = useState('');
  const [question, setQuestion] = useState('');
  const [lossChartData, setLossChartData] = useState();
  const[isInferanceDone, setIsInferanceDone] = useState(false)
  const [Infloading, setInfLoading] = useState(false);
  const [responseTextDPO, setResponseTextDPO] = useState('');
  const [learningRateChartData, setLearningRateChartData] = useState();
  const [metricsError, setMetricsError] = useState(true)
  const cancelToken = useRef(null); // Ref for cancel token

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  // Handle changes in the question textarea
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file.name);
  };

  const handleUpload = () => {
    if (selectedFile) {
        setLoading(true);

        // Parse CSV file
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                console.log("Parsed CSV data: ", results.data);
                setCsvData(results.data.slice(0, 5)); // Update state with CSV data
            },
            error: (error) => {
                console.error("Error parsing CSV: ", error);
            },
            skipEmptyLines: true,
        });

        // Prepare FormData
        const formData = new FormData();
        formData.append("file", selectedFile);

        // API URL with filename and description
        const apiUrl = `http://alchemist.eastus.cloudapp.azure.com:3005/model/dataset?filename=${encodeURIComponent(selectedFile.name)}&description=${encodeURIComponent('File upload description here')}&data_type='dpo'`;

        axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                console.log("File uploaded successfully:", response.data);
                localStorage.setItem("dataset_path", response.data.dataset_path);
                localStorage.setItem("dataset_id", response.data.dataset_id);
                setCompleted(true);
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        console.log("Please select a file to upload.");
    }
  }
  const [tableData, setTableData] = useState([
    // Initialize with default data or empty objects
  ]);
  const [updateDataJson, setUpdateDataJson] = useState({});
  const updateData = (dataIndex, column, type, value, key) => {
    console.log("Update function called with parameters:", { dataIndex, column, type, value, key });
    console.log("Current TableDataLocal:", TableDataLocal);
    console.log("Current updateDataJson:", updateDataJson);
  
    // Ensure dataIndex is within bounds
    if (dataIndex >= 0 && dataIndex < TableDataLocal.length) {
      const row = TableDataLocal[dataIndex];
  
      console.log("Row data at index:", row);
  
      // Ensure row is an array and not empty
      if (Array.isArray(row) && row.length > 0) {
        // Find the correct cell in the row
        const cellIndex = row.findIndex(cell => cell.hasOwnProperty(column));
  
        if (cellIndex !== -1) {
          const cell = row[cellIndex];
          // Make a copy of the table data and update
          const updatedTableData = [...TableDataLocal];
          const updatedRow = [...row];
  
          updatedRow[cellIndex] = {
            ...cell,
            [column]: { ...cell[column], value }
          };
  
          updatedTableData[dataIndex] = updatedRow;
  
          // Update state
          setTableDataLocal(updatedTableData);
          
          // Update JSON data
          const updatedJson = { ...updateDataJson };
          updatedJson[key] = value;
          setUpdateDataJson(updatedJson);
  
          console.log("Updated TableDataLocal:", updatedTableData);
          console.log("Updated updateDataJson:", updatedJson);
        } else {
          console.error("Column not found in row:", { column, row });
        }
      } else {
        console.error("Invalid row data:", row);
      }
    } else {
      console.error("Invalid dataIndex in TableDataLocal:", dataIndex);
    }
  };
  
  
  useEffect(() => {
    console.log("TableData has changed:", TableData);
  }, [TableData]);
  const [lastId, setLastId] = useState(8);

  
  
  

  const [hyperparametersUpdated, setHyperparametersUpdated] = useState({});
  // const handleStartFineTuning = async () => {
  //   try {
  //     // Increment ID
  //     const newId = lastId + 1;
  
  //     // Create configuration with new ID
  //     const hyperparameterConfig = createHyperparameterConfig(TableDataLocal[1], newId);
  
  //     // Log to verify the config
  //     console.log('Hyperparameter Config:', hyperparameterConfig);
  
  //     const response = await axios.post('http://alchemist.eastus.cloudapp.azure.com:3005/model/hyperparameters/', hyperparameterConfig, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  
  //     // Log response for debugging
  //     console.log('API Response:', response.data);
  
  //     // Update state with the new ID
  //     setLastId(newId);
  
  //     // Handle successful response here, e.g., show a success message or navigate to another page
  //   } catch (error) {
  //     // Handle errors here
  //     console.error('API Error:', error.response ? error.response.data : error.message);
  //   }
  // };
  
  const handleStartFineTuning = async (hyperparameters = {}) => {
    try {
        // Create hyperparameter configuration

        // Prepare the request payload
        const requestBody = {
            "service_name": "DPO",
            param_json: defaultDPOHyperparameters,
            dataset_location: localStorage.getItem('dataset_path')
        };

        // Log to verify the request body
        console.log('Request Body:', requestBody);

        // Send the initial request
        const response = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8002/request_training/', requestBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        }, 
        setIsFinetuningStarted(true)
      );

        // Log response for debugging
        console.log('API Response:', response.data);
        localStorage.setItem('request_id', response.data["request_id"])

        // Polling function to get container logs
        let trainingCompletedTime = null;
        const pollLogs = async () => {
          try {
              const requestBody = {
                  service_name: "DPO",
                  request_id: localStorage.getItem('request_id')
              };

              // Make API request
              const logResponse = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8002/container-logs/', requestBody, {
                  headers: {
                      'Content-Type': 'application/json'
                  }
              });

              const logs = logResponse.data.logs;
              const metrics = logResponse.data.metrics;
              const percentage = logResponse.data.percentage;
              const model_url = logResponse.data.model_url;
              setDPOModelUrl(model_url)

              // Update state with the logs and progress
              setLogs(logs);
              setProgress(percentage);
              setIsTrainingStarted(true);

              const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); // Replace invalid characters for filename
              const filename = `DPO_logs_${timestamp}.log`;
              const blob = new Blob([logs], { type: 'text/plain' });
              const url = URL.createObjectURL(blob);

              if (model_url != "") {
                  if (!trainingCompletedTime) {
                      console.log('Training complete');
                      

                      // Store the time when training completed
                      trainingCompletedTime = Date.now(); 
                      console.log('Training completed at:', trainingCompletedTime);

                      const link = document.createElement('a');
                      link.href = url;
                      link.download = filename;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(url);

                      if (metrics && metrics.length > 0) {
                          const steps = metrics.map(log => log.step);
                          const learningRates = metrics.map(log => log.learning_rate);
                          const losses = metrics.map(log => log.loss);

                          // Prepare data for charts
                          const learningRateChartData = {
                              labels: steps,
                              datasets: [
                                  {
                                      label: 'Learning Rate',
                                      data: learningRates,
                                      borderColor: 'rgba(75,192,192,1)',
                                      backgroundColor: 'rgba(75,192,192,0.2)',
                                      fill: false
                                  }
                              ]
                          };

                          const lossChartData = {
                              labels: steps,
                              datasets: [
                                  {
                                      label: 'Loss',
                                      data: losses,
                                      borderColor: 'rgba(255,99,132,1)',
                                      backgroundColor: 'rgba(255,99,132,0.2)',
                                      fill: false
                                  }
                              ]
                          };

                          setLearningRateChartData(learningRateChartData);
                          setLossChartData(lossChartData);
                          setMetricsError(false); // Clear any previous errors
                      } else {
                          // If metrics are null or empty
                          setLearningRateChartData(null);
                          setLossChartData(null);
                          setMetricsError(true);
                      }
                  }

                  // Continue polling every 2 seconds for 10 seconds after completion
                  const now = Date.now();
                  const elapsedTime = now - trainingCompletedTime;
                  console.log('Elapsed Time:', elapsedTime);

                  if (elapsedTime <= 5000) { // 10 seconds
                      console.log('Continuing polling...');
                      setTimeout(pollLogs, 2000);
                      if (metrics && metrics.length > 0) {
                          const steps = metrics.map(log => log.step);
                          const learningRates = metrics.map(log => log.learning_rate);
                          const losses = metrics.map(log => log.loss);

                          // Prepare data for charts
                          const learningRateChartData = {
                              labels: steps,
                              datasets: [
                                  {
                                      label: 'Learning Rate',
                                      data: learningRates,
                                      borderColor: 'rgba(75,192,192,1)',
                                      backgroundColor: 'rgba(75,192,192,0.2)',
                                      fill: false
                                  }
                              ]
                          };

                          const lossChartData = {
                              labels: steps,
                              datasets: [
                                  {
                                      label: 'Loss',
                                      data: losses,
                                      borderColor: 'rgba(255,99,132,1)',
                                      backgroundColor: 'rgba(255,99,132,0.2)',
                                      fill: false
                                  }
                              ]
                          };

                          setLearningRateChartData(learningRateChartData);
                          setLossChartData(lossChartData);
                          setMetricsError(false); // Clear any previous errors
                          setIsTrainingCompleted(true);
                          setIsTrainingStarted(false)
                      } else {
                          // If metrics are null or empty
                          setLearningRateChartData(null);
                          setLossChartData(null);
                          setMetricsError(true);
                      }

                  } else {
                      console.log('Stopping polling after 10 seconds');
                      setIsFinetuningStarted(false);
                      setIsTrainingStarted(false);
                  }

              } else {
                  // Continue polling every 2 seconds
                  setTimeout(pollLogs, 2000);
              }

          
          } catch (error) {
              // Handle network or other unexpected errors
              console.error('Polling Error:', error.response ? error.response.data : error.message);
              setIsTrainingStarted(false); // Stop any ongoing process
              setIsFinetuningStarted(false);
          }
      };
      
  
      

        // Start polling
        pollLogs();

        // Update state with the new Id or handle other success logic

    } catch (error) {
        // Handle errors from the initial request
        console.error('API Error:', error.response ? error.response.data : error.message);
    }
};

const handleInference = async () => {
  setInfLoading(true)
  try {
    const requestBody = {
     
      context: context,
      question: question,
 
      model_name: "meta-llama/Meta-Llama-3-8B-Instruct",
  model_url: dpoModelUrl
    };

    // Make API request
    const logResponse = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8004/dpo-inf/generate', requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    // Set the response and update the state
    setResponseTextDPO(logResponse.data.generated_text); // Adjust based on the actual response structure
    setIsInferanceDone(true);

  } catch (error) {
    console.error('Inference Error:', error.response ? error.response.data : error.message);
  }finally {
    setInfLoading(false); // End loading
  }
}


  // Define a click handler function
  const handleClick = () => {
      const paramsToUse = hyperparameters && Object.keys(hyperparameters).length > 0
          ? hyperparameters
          : defaultDPOHyperparameters;

      handleStartFineTuning(defaultDPOHyperparameters);
  };

  
  
  
  
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const knowledgeWorkLinks = [
    { name: "1. Perplexity", isActive: activeTabIndex === 0 },
    { name: "2. Rouge", isActive: activeTabIndex === 1 },
    { name: "3. BLEU", isActive: activeTabIndex === 2 },
  ];

  const handleKnowledgeWorkClick = (index) => {
    setActiveTabIndex(index);
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    const loadChartData = async () => {
      const chartData = await fetchChartData();
      setData(chartData);
    };

    loadChartData();
  }, []);


  useEffect(() => {
    if (
      knowledgeWorkLinks.find((link) => link.isActive && link.name === "New")
    ) {
      setDisplayedData(newData);
    } else if (
      knowledgeWorkLinks.find(
        (link) => link.isActive && link.name === "In Progress"
      )
    ) {
      setDisplayedData(inProgressData); // replace with actual drafts data
    } else {
      setDisplayedData(completedData);
    }
  }, [completedData, knowledgeWorkLinks]);

  return (
    <div>
        
        <MoHeader darkMode={darkMode}/>
      <div className="container-fluid px-4 pt-4">
        <p className="text-light fw-bold">Model Training</p>
        <p style={{color: '#ADC2FF', marginTop:'-10px'}}>Knowledge Work: {knowledgeWorkDetails.knowledge_work.name}</p>
      </div>
      
      <div className="d-flex justify-content-end align-items-center" style={{ background: 'transparent'}}>
  <Nav className="d-flex" style={{marginBottom:'7px',position:'relative', bottom:'9.8rem'}}>
    <button className='btn btn-sm btn-link mr-2'>
      <Link to={`/mo/summary/new/${id}`} style={{ color: '#ADC2FF', textDecoration: 'underline'}}>Summary</Link>
    </button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Close</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }} onClick={handlePrevStep} disabled={isPrevButtonDisabled()}>Back</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Save</button>
    <button className='btn btn-sm mr-2' style={{ background: '#003BEB', color: '#fff' }} onClick={() => handleNextStep(currentModelId)}>Proceed</button>
  </Nav>
</div>

      <div className="container-fluid" style={{ background: '#222E59', height: '100vh', display: 'flex',padding:'30px'  }}>
        <div className="row flex-grow-1 m-0" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="col-md-2 p-2" style={{ background: "#42466C", overflowY: 'auto', height: '93vh' ,borderRadius: '4px 0px 0px 4px'}}>
            <h5 style={{ fontSize: "16px" }} className="text-light ml-4">
              Models ({selectedModelIds.length})
            </h5>
            <div className="accordion" id="accordionExample">
      {selectedModelIds.map((model, modelIndex) => (
        <div className="accordion-item" key={modelIndex} style={{ border: 'none' }}>
          <h2 className="accordion-header" id={`heading${model.id}`}>
            <button
              className={`accordion-button ${modelIndex === 0 ? 'show' : 'collapsed'}`}
              type="button"
              style={{
                background: '#313557',
                color: '#fff',
                fontSize: '14px',  // Adjusted font size
                padding: '8px 16px'  // Adjusted padding for a smaller button
              }}
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${model.id}`}
              aria-expanded={modelIndex === 1 ? 'true' : 'false'}
              aria-controls={`collapse${model.id}`}
            >
              {model.model_name}
            </button>
          </h2>
          <div
            id={`collapse${model.id}`}
            className={`accordion-collapse collapse ${modelIndex === 0 ? 'show' : ''}`}
            aria-labelledby={`heading${model.id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{ background: '#42466C', padding: '10px' }}>
              <div className="accordion" id={`accordionExample${model.id}`}>
                {sections.map((section, sectionIndex) => (
                  <div className="accordion-item" key={sectionIndex} style={{ border: 'none' }}>
                    <h2 className="accordion-header" id={`heading${model.id}${sectionIndex}`}>
                      <button
                        className={`accordion-button rounded-0 ${sectionIndex === currentSection ? '' : 'collapsed'}`}
                        type="button"
                        style={{
                          background: sectionIndex === currentSection ? '#313557' : '#42466C',
                          color: '#fff',
                          fontSize: '14px',  // Adjusted font size
                          padding: '8px 16px'  // Adjusted padding for a smaller button
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${model.id}${sectionIndex}`}
                        aria-expanded={sectionIndex === currentSection ? 'true' : 'false'}
                        aria-controls={`collapse${model.id}${sectionIndex}`}
                      >
                        {section.name}
                      </button>
                    </h2>
                    <div
                      id={`collapse${model.id}${sectionIndex}`}
                      className={`accordion-collapse collapse ${sectionIndex === currentSection ? 'show' : ''}`}
                      aria-labelledby={`heading${model.id}${sectionIndex}`}
                      data-bs-parent={`#accordionExample${model.id}`}
                    >
                      <div className="accordion-body" style={{ background: '#42466C', padding: '10px' }}>
                        <div
                          className="progress"
                          style={{ height: '8px', marginBottom: '10px' }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${((currentStep[sectionIndex] + 1) / sections[sectionIndex].steps.length) * 100}%`,
                              background: '#0FC948',
                            }}
                            aria-valuenow={(currentStep[sectionIndex] + 1) * (100 / sections[sectionIndex].steps.length)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <ul className="list-group">
  {section.steps.map((step, stepIndex) => {
    const isCompleted = stepIndex < currentStep[sectionIndex];
    const isCurrent = stepIndex === currentStep[sectionIndex];

    return (
      <li
        key={stepIndex}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '4px',
          fontSize: '14px', // Adjusted font size for better readability
          padding: '8px',
          borderRadius: '4px',
          border: 'none',
          backgroundColor: isCurrent ? '#313557' : 'transparent',
          color: isCurrent || isCompleted ? '#fff' : '#ccc',
        }}
        className={`list-group-item ${isCurrent ? 'active' : ''}`}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: isCompleted ? '#0FC948' : '#4D5284',
            color: '#fff',
            marginRight: '12px',
            fontSize: '14px',
          }}
        >
          {isCompleted ? '✓' : stepIndex + 1}
        </div>
        <div
          style={{
            flex: 1,
            padding: '4px 8px',
            borderRadius: '4px',
          }}
        >
          {step}
        </div>
      </li>
    );
  })}
</ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>



          </div>
          <div className="col-md-6"style={{
      // Ensure the container has a minimum height
      background:'#E8EDFA', // Centers the image in the container
           
            height: '100vh',
            opacity:'100',
            overflowY:'auto'
          }}>
          <div className="d-flex flex-column overflow-hidden" style={{ height: "90vh" }}>
      <div
        className="flex-grow-1 d-flex flex-column overflow-auto chatBackgroundImage" style={{
                backgroundSize: 'cover', // Ensures the image covers the container
                backgroundPosition: 'center', // Centers the image in the container
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                backgroundAttachment: 'fixed', // Keeps the background fixed during scrolling
                position: '', // Keeps the center column fixed
                top: '0',
               
              }}
       // Optional: Set background for visibility
      >
       
            {sections[currentSection] && currentStep[currentSection] === 0 && (
                renderChat(currentSection, currentStep[currentSection])
                )}
              {sections[currentSection] && currentStep[currentSection] === 1 && (
                
              <div className="d-flex flex-column justify-content-center p-4">
                
              <button className={`m-2 btn btn-sm d-flex text-light align-items-center justify-content-center ${isFinetuningStarted ? 'animated-ellipsis' : ''}`}  style={{background: '#003BEB'}} onClick={handleClick} disabled={isTrainingCompleted || isTrainingStarted || isFinetuningStarted}>
               <span className="px-2">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width="24"
                   height="24"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="currentColor"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="lucide lucide-bot"
                 >
                   <path d="M12 8V4H8" />
                   <rect width="16" height="12" x="4" y="8" rx="2" />
                   <path d="M2 14h2" />
                   <path d="M20 14h2" />
                   <path d="M15 13v2" />
                   <path d="M9 13v2" />
                 </svg>
               </span>{" "}
               {isFinetuningStarted ? "DPO Training" : !isTrainingCompleted ? "Start DPO":'DPO Completed'}
             </button>
           {isTrainingStarted && (
       <div
           className="p-4 m-2 d-flex flex-column rounded"
           style={{ background: "#FFFFFF" }}
       >
           <p className="text-dark">
               DPO Completion Status
           </p>
           <div className="d-flex align-items-center justify-content-center">
               <div className="progress" style={{ width: "100%" }}>
                   <div
                       className="progress-bar"
                       role="progressbar"
                       style={{ width: `${progress}%`, background: "#42466C" }}
                       aria-valuenow={progress}
                       aria-valuemin="0"
                       aria-valuemax="100"
                   >
                       {progress}%
                   </div>
               </div>
               <button
                   type="button"
                   className="btn btn-sm btn-outline-primary"
                   style={{ marginLeft: "10px" }}
               >
                   Cancel
               </button>
           </div>
          
       </div>
   )}
             {isTrainingCompleted && (
             <div
               className="p-4 m-2 d-flex flex-column rounded"
               style={{ background: "#FFFFFF" }}
             >
               <div className="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="32"
                     height="32"
                     viewBox="0 0 24 24"
                     fill="none"
                     stroke="#08C948"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     class="lucide lucide-circle-check"
                   >
                     <circle cx="12" cy="12" r="10" />
                     <path d="m9 12 2 2 4-4" />
                   </svg>

                   <h3 className="fs-4 ml-2" style={{ color: "#08C948" }}>
                     DPO Completed
                   </h3>
                 </div>
                 {/* <p className="text-dark" style={{ marginLeft: "10px" }}>
                   Time Taken: 12 Hours
                 </p> */}
               </div>
             </div>

             )}
             {/* {renderChat(currentSection, currentStep[currentSection])} */}
             {!isInferanceDone && isTrainingCompleted && (
                  <>
                <div className="text-dark bg-light p-2 rounded">
                <div
                className="p-4 m-2 d-flex flex-column rounded"
                style={{ background: "#ffffff" }}
              >
                <p className="text-center text-dark">Context </p>

                <div class="">
                  <div className="d-flex justify-content-between">
                  <p className="text-dark">Sample test text</p>
                  <p className="text-dark">Upload</p>
                  </div>
<textarea className="form-control border-0 text-dark"id="floatingTextarea2" style={{background: '#E8EDFA',height: "100px"}} value={context}
          onChange={handleContextChange}></textarea>

</div>
              </div>
               <div
               className="p-4 m-2 d-flex flex-column rounded"
               style={{ background: "#ffffff" }}
             >
               <p className="text-center text-dark">Question </p>

               <div class="">
                 <div className="d-flex justify-content-between">
                 <p className="text-dark">Sample test text</p>
                 <p className="text-dark">Upload</p>
                 </div>
<textarea className="form-control border-0 text-dark"id="floatingTextarea2" style={{background: '#E8EDFA',height: "100px"}} value={question}
         onChange={handleQuestionChange} ></textarea>

</div>
<button className="btn btn-sm text-light mt-2" style={{background: '#003BEB'}} onClick={handleInference} > {Infloading ? 'Inferring...' : 'Inference'}</button>
             </div>
                </div>
             </>

                )}
                {isInferanceDone && isTrainingCompleted && (
                 
                  <div className="text-dark bg-light p-2 rounded">{responseTextDPO}</div>
               
                )}
           </div>
              )}
              
              {sections[currentSection] && currentStep[currentSection] === 2 && (
          <div className="row p-2">
          <div className="col-md-5 mt-4 p-0 m-0 rounded" style={{ background: "#243063" }}>
            <div
              className="pt-2 rounded"
              style={{ background: "#243063", borderBottom: '1px solid #003beb' }}
            >
              <p className="text-light fw-bold pl-4">
              {selectedItems.recommendedModels[0].model_name} (Pre DPO Training)
              </p>
            </div>
            <div className="p-2" style={{ background: "#DDE3F6" }}>
              <p className="p-2 rounded text-dark bg-light">
                {responseText}
              </p>
             
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5 mt-4 p-0 m-0 rounded" style={{ background: "#243063" }}>
            <div
              className="pt-2 rounded"
              style={{ background: "#243063", borderBottom: '1px solid #003beb' }}
            >
              <p className="text-light fw-bold pl-4">
              {selectedItems.recommendedModels[0].model_name} (Post DPO Training)
              </p>
            </div>
            <div className="p-2" style={{ background: "#DDE3F6" }}>
              <p className="p-2 rounded text-dark bg-light">
                {responseTextDPO}
              </p>
             
            </div>
          </div>
        </div>
            
              )}
             
              
              </div>
              {sections[currentSection] && currentStep[currentSection] != 2 && (

              <div className="d-flex align-items-end justify-content-center">
                <div
                  className="p-2 m-2 d-flex align-items-center rounded"
                  style={{ background: "#ffffff" }}
                >
                  <input
                    type="text"
                    className="form-control "
                    style={{
                      background: "transparent",
                      color: "#003BEB",
                      width: "30rem",
                      height:'2rem',
                      border: 'none',
                      outline: 'none'
                    }}
                    placeholder="Type a message..."
                    value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
                  />
                  <span className="px-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.661" height="15.877" viewBox="0 0 17.661 15.877">
  <path id="Path_5908" data-name="Path 5908" d="M20.339,20.58a.6.6,0,0,1,0-.842l3.57-3.57a.6.6,0,0,1,.842,0l3.57,3.57a.6.6,0,1,1-.842.842l-2.554-2.555v6.3a.6.6,0,0,1-1.19,0v-6.3L21.181,20.58a.6.6,0,0,1-.842,0ZM32.661,24.92v4.76a1.19,1.19,0,0,1-1.19,1.19H17.19A1.19,1.19,0,0,1,16,29.68V24.92a1.19,1.19,0,0,1,1.19-1.19h5.058a.3.3,0,0,1,.3.3v.257a1.818,1.818,0,0,0,1.819,1.825,1.785,1.785,0,0,0,1.751-1.785v-.3a.3.3,0,0,1,.3-.3h5.058A1.19,1.19,0,0,1,32.661,24.92ZM29.686,27.3a.893.893,0,1,0-.893.893.893.893,0,0,0,.893-.893Z" transform="translate(-15.5 -15.494)" fill="#003beb" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</svg>
                  </span>
                  <span className="px-2" onClick={sendMessage}>
                  <svg id="Group_1737" data-name="Group 1737" xmlns="http://www.w3.org/2000/svg" width="15.822" height="13.573" viewBox="0 0 15.822 13.573">
  <g id="Group_1383" data-name="Group 1383">
    <path id="Path_53" data-name="Path 53" d="M0,.088A.089.089,0,0,1,.123.006C.46.145,10.89,4.607,15.768,6.7a.089.089,0,0,1,0,.163l-.015.006L.16,13.55l-.041.016A.089.089,0,0,1,0,13.482V7.639a.088.088,0,0,1,.078-.088c.9-.1,6.4-.5,8.88-.673a.088.088,0,0,0,0-.176C6.464,6.515.9,6.093.079,6.008A.088.088,0,0,1,0,5.92V.088" transform="translate(0 0.001)" fill="#003beb"/>
  </g>
</svg>                  </span>
                </div>
              </div>
              )}
            </div>
          </div>

          
          
        
          {sections[currentSection] && currentStep[currentSection] === 0 && (
             <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'auto',height:'100vh' ,padding:'0' }}>
             <div className="d-flex align-items-center">
               <p className="text-light pr-2 border-right">Training Data</p>
               <p className="text-light" style={{ cursor: "pointer",display: "flex",alignContent:'center',justifyContent:'center',alignItems: 'center'  }}>
                 <span className="px-2">
                 <svg id="Group_676" data-name="Group 676" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
                    <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_790" data-name="Rectangle 790" width="12" height="12" fill="#e5eaf1"/>
                    </clipPath>
                  </defs>
                  <g id="Group_675" data-name="Group 675" clip-path="url(#clip-path)">
                    <path id="Path_198" data-name="Path 198" d="M12,125.257l-.043.1a.528.528,0,0,1-.446.353,1.806,1.806,0,0,1-.2.009H.686a1.247,1.247,0,0,1-.268-.019A.563.563,0,0,1,0,125.2a.828.828,0,0,1,0-.087q0-2.487,0-4.975a.564.564,0,0,1,.483-.6.544.544,0,0,1,.595.471,1.77,1.77,0,0,1,.012.236q0,2.07,0,4.14v.169h9.817v-.157q0-2.082,0-4.165a1.206,1.206,0,0,1,.026-.284.531.531,0,0,1,1.019-.063l.045.113Z" transform="translate(0 -113.719)" fill="#e5eaf1"/>
                    <path id="Path_199" data-name="Path 199" d="M66.179,7.7a.464.464,0,0,0-.058-.121Q65.381,6.785,64.638,6a.526.526,0,0,0-.894.134.579.579,0,0,0,.048.589,1.048,1.048,0,0,0,.116.14l2.428,2.584a.535.535,0,0,0,.879,0l2.444-2.6a.616.616,0,0,0,.143-.733.524.524,0,0,0-.821-.178,1.524,1.524,0,0,0-.111.111q-.717.762-1.433,1.526a.832.832,0,0,0-.075.11l-.041-.025V7.516q0-3.429,0-6.858a.615.615,0,0,0-.285-.585.539.539,0,0,0-.8.467c0,.058,0,.116,0,.174q0,3.4,0,6.8v.144l-.055.043" transform="translate(-60.777 0)" fill="#e5eaf1"/>
                  </g>
                  </svg>
                 </span>
                 <a
                   href={templateSelector[path]}
                   download="Template"
                   target="_blank"
                   style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                   className={
                     (darkMode ? "darg-n-drop-dark " : "darg-n-drop ") +
                     "p-0 justify-content-center fw-medium"
                   }
                 >
                   Download Template
                 </a>
               </p>
             </div>
             <Card
               className={darkMode ? "UploadCompDark p-3" : "UploadComp p-3"}style={{height:'auto',overflow:'scroll'}}
             >
               <Card.Body
                 className={
                   darkMode
                     ? "uploadCompOuter d-flex align-items-center justify-content-center height:100%"
                     : "UploadComp d-flex align-items-center justify-content-center height:100%"
                 }
                 style={{ color: darkMode ? "" : "white" }}
               >
                 {csvData.length <= 0 && (
                   <div className="text-center">
                     <div
                       className={
                         (darkMode ? "image-upload-dark" : "image-upload") +
                         " text-center d-flex flex-column align-items-center "
                       }
                     >
                       <input
                         type="file"
                         // id="fileUpload"
                         onChange={handleFileChange}
                         id="file-input"
                       />
                       <FiUpload
                         style={{
                           fontSize: "30px",
                           color: darkMode ? "#E5EAF1" : "#0056b3",
                         }}
                       />
                       {/* </label> */}
                       <div
                         style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                         className={
                           darkMode
                             ? "darg-n-drop-dark pb-4 pt-2"
                             : "darg-n-drop pb-5 fw-medium"
                         }
                       >
                         Drag and Drop
                       </div>
                       <div
                         className={
                           darkMode
                             ? "uploadCompOrDark mb-4"
                             : "uploadCompOr mb-5"
                         }
                       >
                         OR
                       </div>
                       <div
                         className="taskList"
                         style={{
                           fontSize: "14px",
                           color: darkMode ? "" : "rgb(0, 86, 179)",
                         }}
                       >
                         Choose from Repository
                       </div>
                       <label htmlFor="file-input" className="my-2">
                         <a className="cancelBtnDark  mx-0">
                           Choose Training Data
                         </a>
                       </label>
                       {selectedFile && (
                         <div
                           style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                         >
                           {" "}
                           {selectedFile.name}{" "}
                         </div>
                       )}
                     </div>

                     <Button
                       onClick={handleUpload}
                       disabled={loading}
                       // className="submitBtn mt-2"
                       className={`mt-2 mr-0 ${
                         selectedFile ? "" : "disabled"
                       } ${darkMode ? "submitBtnDark" : "submitBtnDark"}`}
                     >
                       {loading ? "Uploading..." : "Upload"}
                     </Button>
                     {loading && <div className="loader">Loading...</div>}
                   </div>
                 )}
                 {/* Display CSV Data */}
                 {csvData.length > 0 && (
                   <div className="csv-data ">
                     <Table
                       className={`${
                         darkMode ? "custom-tableDark" : "custom-table"
                       } mt-3`}
                     >
                       <thead className="table-secondary ">
                         <tr>
                           {Object.keys(csvData[0]).map((header, index) => (
                             <th key={index} style={{ fontWeight: "bold" }}>
                               {header}
                             </th>
                           ))}
                         </tr>
                       </thead>
                       <tbody>
                         {csvData.map((row, index) => (
                           <tr key={index}>
                             {Object.values(row).map((cell, cellIndex) => (
                               <td key={cellIndex}>{cell}</td>
                             ))}
                           </tr>
                         ))}
                       </tbody>
                     </Table>
                   </div>
                 )}
               </Card.Body>
             </Card>
           </div>
          )}
          {sections[currentSection] && currentStep[currentSection] === 1 && (
            <div className="col-md-4 " style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
              <div className="d-flex align-items-center">
                <p className="text-light">Model Hyperparameters</p>
              </div>
              <div className="mt-2 p-2 d-flex flex-column" style={{background:'transparent'}}>
              <CustomTableNew
                  // updateData={updateData}
                  data={modelHyperparameterTableData}
                  TableColumn={TableColumnLocal[index - 1]}
                  darkMode={darkMode}
                  onDataChange={handlehyperparameterTableDataChange}
                ></CustomTableNew>
              </div>
            </div>
          )}
          {sections[currentSection] && currentStep[currentSection] === 2 && (
               <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
  
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light">Evaluate SFT</p>
              </div>
              
              <div className=" mt-2 p-2 d-flex flex-column">
               
              <div className="mt-2">
    {progress === 100 && metricsError && (
        <p className="text-align-center text-light">Fetching Metrics...</p>
    )}
    {progress === 100 && !metricsError && (
        <div className="p-2 mt-2">
            {learningRateChartData ? (
                <Line data={learningRateChartData} options={data.options1} />
            ) : (
                <p className="text-align-center text-light">No learning rate data available.</p>
            )}
            {lossChartData ? (
                <Line data={lossChartData} options={data.options2} />
            ) : (
                <p className="text-align-center text-light">No loss data available.</p>
            )}
        </div>
    )}
   
</div>

                
              </div>
            </div>
          )}
         
        </div>
      </div>
    </div>
  );

  
};

export default TrainingDPO;
