import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default function GraphShow(props) {
  const [metricsData, setMetricsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://172.208.104.191:5002/get_ppo_metrics', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "my_folder": "/data/llm_factory/API_Testing/metrics.txt"
          })
        });

        if (response.ok) {
          const data = await response.json();
          setMetricsData(null);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const interval = setInterval(fetchData, 3000);

    return () => {
      clearInterval(interval); // Clear interval on unmount
    };
  }, []); // Removed props.loadingPercentage from the dependency array to prevent unnecessary re-fetching

  return (
    <div>
      {metricsData && (
        <>
          <div className="row mx-0 mt-0 mb-3">
            {console.log("working")}
          </div>
          <div className="row mx-0 mt-0 mb-1">
            <div className="col-6">
              <div className="card mb-5">
                <div className="px-4 py-2">
                  <span className="tabSub-head mb-2">train/learning_rate</span>
                  <Line
                    height={150}
                    width={300}
                    options={{
                        plugins: {
                            legend: {
                              display: false
                            }
                          }
                      // Your options for the chart scales, legends, etc.
                    }}
                    data={{
                      labels: metricsData['train/learning_rate'].map(item => item[0].toString()),
                      datasets: [
                        {
                          data: metricsData['train/learning_rate'].map(item => item[1]),
                          fill: true,
                          borderWidth: 2,
                          borderColor: 'rgba(51, 102, 255, 1)',
                          responsive: true,
                          pointRadius: 0,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card mb-5">
                <div className="px-4 py-2">
                  <span className="tabSub-head mb-2">train/train_loss</span>
                  <Line
                    height={150}
                    width={300}
                    options={{
                        plugins: {
                            legend: {
                              display: false
                            }
                          }
                      // Your options for the chart scales, legends, etc.
                    }}
                    data={{
                      labels: metricsData['train/loss'].map(item => item[0].toString()),
                      datasets: [
                        {
                          data: metricsData['train/loss'].map(item => item[1]),
                          fill: true,
                          borderWidth: 2,
                          borderColor: 'rgba(51, 102, 255, 1)',
                          responsive: true,
                          pointRadius: 0,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Other chart components for different metrics */}
          </div>
          <div className="row mx-0 mt-0 mb-1">
            <div className="col-6">
              <div className="card mb-5">
                <div className="px-4 py-2">
                  <span className="tabSub-head mb-2">train/train_samples_per_second</span>
                  <Line
                    height={150}
                    width={300}
                    options={{
                        plugins: {
                            legend: {
                              display: false
                            }
                          }
                      // Your options for the chart scales, legends, etc.
                    }}
                    data={{
                      labels: metricsData['train/train_samples_per_second'].map(item => item[0].toString()),
                      datasets: [
                        {
                          data: metricsData['train/train_samples_per_second'].map(item => item[1]),
                          fill: true,
                          borderWidth: 2,
                          borderColor: 'rgba(51, 102, 255, 1)',
                          responsive: true,
                          pointRadius: 0,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card mb-5">
                <div className="px-4 py-2">
                  <span className="tabSub-head mb-2">train/train_steps_per_second</span>
                  <Line
                    height={150}
                    width={300}
                    options={{
                        plugins: {
                            legend: {
                              display: false
                            }
                          }
                      // Your options for the chart scales, legends, etc.
                    }}
                    data={{
                      labels: metricsData['train/train_steps_per_second'].map(item => item[0].toString()),
                      datasets: [
                        {
                          data: metricsData['train/train_steps_per_second'].map(item => item[1]),
                          fill: true,
                          borderWidth: 2,
                          borderColor: 'rgba(51, 102, 255, 1)',
                          responsive: true,
                          pointRadius: 0,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Other chart components for different metrics */}
          </div>
          
        </>
      )}
      {!metricsData && <p>Loading...</p>}
    </div>
  );
}
