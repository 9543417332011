// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
   
  .step.active {
    font-weight: bold;
    color: blue;
  }`, "",{"version":3,"sources":["webpack://./src/components/ModelOrchestrator/FineTunining/StepsNavbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb","sourcesContent":[".step {\n    cursor: pointer;\n    padding: 10px;\n    border-bottom: 1px solid #ddd;\n  }\n   \n  .step.active {\n    font-weight: bold;\n    color: blue;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
