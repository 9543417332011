import React, { useState, useEffect, useContext } from "react";
import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import "./FineTuning.css";
import "./RewardAndPFO.css";
import { PPOSideBarNames, PPOTableData, TableColumn } from "./DB";
import CreateAgentNav from "../../AgentModule/CreateAgent/CreateAgentNav";
import ModelOrchestratorNav from "./ModelOrchestratorNav";
import StepsNavbar from "./StepsNavbar";
import { Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import UploadComp from "./UploadComp/UploadComp";
import ModelsNav from "./ModelsNav";
import CustomTable from "../../Table/CustomTable";
import ModelRewardNav from "./ModelRewardNav";
import RewardModelPPOProgress from "../RewardModelPPOProgress";
import EvaluateAndGraph from "./EvaluateAndGraph";
import checkimg from "../../../assets/img/check.png";
import darkCheckImg from "../../../assets/img/darkCheckImg.svg";
import axios from "axios";
import download from "../../../assets/img/download.png";
import RewardTemplate from "../../../assets/templates/Reward_Model_data_Template.csv";
import { MdOutlineDownload, MdOutlineFileDownload } from "react-icons/md";
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import EditIconDark from '../../../assets/img/editIconDark.svg'
import { Position } from "reactflow";
import AlchemProgressBar from "../../SharedComponents/AlchemProgressBar/AlchemProgressBar";
import { DockContext } from "../../DockWrapper/DockContext";
import { AuthContext } from "../../Auth/AuthContext";

function RewardAndPPO({ darkMode, handleToggle }) {
  const {accessToken} = useContext(AuthContext)
  const [index, setindex] = useState(1);
  const [TableDataLocal, setTableDataLocal] = useState(PPOTableData);
  const [updateDataJson, setupdateDataJson] = useState({});
  const navigate = useNavigate();
  const {nodeIndex} = useContext(DockContext)
  useEffect(() => {
    if (index === 3) {
      console.log(updateDataJson);
    }
  }, [index]);

  const uploaddone = () => {
    proceed();
  };
  const proceed = () => {
    if (index <= 4) setindex(index + 1);
    console.log(index);
    if (index > 4) navigate("/rewardmodelhome");
  };

  const updateData = (data_index, column, type, value, key) => {
    if (
      type === "text" ||
      type === "number" ||
      type === "select" ||
      type === "radio"
    ) {
      let data = TableDataLocal;
      let tempupdateDate = updateDataJson;
      tempupdateDate[key] = value;
      setupdateDataJson(tempupdateDate);
      data[index - 1][data_index][column]["value"] = value;
      setTableDataLocal([...data]);
    }
  };
  const back = () => {
    if (index >= 2) setindex(index - 1);
    console.log(index);
  };
  const savehyper = () => {
    const headers = { 
      "Content-Type": "application/json",
      Authorization: accessToken
    };
    console.log(updateDataJson);
    axios
      .post(
        `${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/hyperparameters`,
        JSON.stringify({
          hyperparameter: {
            updateDataJson
          }
        }),
        { headers }
      )
      .then(res => {
        localStorage.setItem(
          "rewardppohyperparameter_id",
          JSON.stringify(res.data.id)
        );
        console.log(res.data.id);
      });
  };
  const FineTuningstart = async () => {
    await savehyper();
    const headers = { "Content-Type": "application/json" };
    console.log(updateDataJson);
    // axios
    //   .post(
    //     "http://172.208.104.191:5002/start_ppo_training",
    //     JSON.stringify({
    //       finetuned_model_id: 5,
    //       name: localStorage.getItem("name") + "_PPO",
    //       task: 1,
    //       ppo_model_src: "/data/llm_factory",
    //       hyperparamsid: 6,
    //       dataset_id: 6
    //     }),
    //     { headers }
    //   )
    //   .then(res => {
    //     console.log(res.data);
    //     proceed();
    //   });
    proceed();
  };
  const [progressIndex, setProgressIndex] = useState(nodeIndex<1 ? 2 : 3)
  let title = " Model Orchestrator"
let steps = ["Select Model", "Fine - Tuning", "Reward & PPO ", "Preview Model"]
  return (
    <>
      {/* <AlchemNav module={{ name: "agentFactory" }} /> */}
      {/* <ModelsNav darkMode={darkMode} handleToggle={handleToggle} /> */}
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle}/>
      <div className="finetuning-outer" style={{ gap: "0px" }}>
      
        <AlchemProgressBar title={title} steps={steps} subtitle="" index={progressIndex}/>
        <div className="finetuning-body pageHeadBg mt-3">
          {index == 4 ? (
            <> </>
          ) : (
            <div className="finetuning-body-left">
              <div
                className={
                  darkMode
                    ? "finetuning-navbar fineTuningDark"
                    : "finetuning-navbar"
                }
                style={{
                  background: darkMode ? "#42466C" : "",
                  border: darkMode ? "none" : ""
                }}
              >
                <div className="reward-navbar-header">
                  <span className="stepperheading">Reward Model</span>
                  <span>
                    Reward Model RLHF &nbsp;
                    <span style={{ paddingLeft: "5rem" }}>
                      {" "}
                      <img src={EditIconDark} style={{position: 'relative',bottom: '30px', left:'15px'}}/>{" "}
                    </span>
                  </span>
                </div>
                <div className="horizontalline"></div>

                <div className="finetuning-navbar-body">
                  <div class="stepper d-flex flex-column mt-1 ml-2 pt-3">
                    <div class="d-flex ">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-active text-white">
                          <img
                            className="checkmarkimage"
                            src={darkMode ? darkCheckImg : checkimg}
                          ></img>
                        </div>
                        <div class="line virticle-active h-100 "></div>
                      </div>
                      <div>
                        <p
                          class={`${
                            darkMode ? "leadDark" : "lead"
                          } active pb-3`}
                        >
                          Upload Data
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-active text-white">
                          <img
                            className="checkmarkimage"
                            src={darkMode ? darkCheckImg : checkimg}
                          ></img>
                        </div>
                        <div class="line virticle-active h-100"></div>
                      </div>
                      <div>
                        <p
                          class={`${
                            darkMode ? "leadDark" : "lead"
                          } active pb-3`}
                        >
                          Model Parameters
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div class="rounded-circle py-2 px-3 virticle-active text-white">
                          <img
                            className="checkmarkimage"
                            src={darkMode ? darkCheckImg : checkimg}
                          ></img>
                        </div>
                        <div class="line h-100 virticle-active d-none"></div>
                      </div>
                      <div>
                        <p
                          class={`${
                            darkMode ? "leadDark" : "lead"
                          } active pb-3`}
                        >
                          PEFT
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="horizontalline"></div>
                <div className="reward-navbar-header">
                  <span className="stepperheading">PPO Model</span>
                  <span>
                    PPO Model: PPO &nbsp;
                    <span style={{ paddingLeft: "5rem" }}>
                    <img src={EditIconDark} style={{position: 'relative',bottom: '30px',left: '30px'}}/>{" "}
                    </span>
                  </span>
                </div>
                <div className="horizontalline"></div>

                <div className="finetuning-navbar-body">
                  <div class="stepper d-flex flex-column mt-1 ml-2 pt-3">
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 1
                              ? "rounded-circle py-2 px-3 virticle-active text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          {index >= 2 ? (
                            <img
                              className="checkmarkimage"
                              src={darkMode ? darkCheckImg : checkimg}
                            ></img>
                          ) : (
                            <>1</>
                          )}
                        </div>
                        <div
                          className={
                            index >= 2
                              ? "line virticle-active h-100"
                              : "line h-100"
                          }
                        ></div>
                      </div>
                      <div>
                        <p
                          className={
                            index >= 1
                              ? darkMode
                                ? "leadDark  pb-3 active"
                                : "lead  pb-3 active"
                              : darkMode
                              ? "leadDark  pb-3 "
                              : "lead  pb-3 "
                          }
                        >
                          Upload Data
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 2
                              ? "rounded-circle py-2 px-3 virticle-active text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          {index >= 3 ? (
                            <img
                              className="checkmarkimage"
                              src={darkMode ? darkCheckImg : checkimg}
                            ></img>
                          ) : (
                            <>2</>
                          )}
                        </div>
                        <div
                          className={
                            index >= 3
                              ? "line virticle-active h-100"
                              : "line h-100"
                          }
                        ></div>
                      </div>
                      <div>
                        <p
                          className={
                            index >= 2
                              ? darkMode
                                ? "leadDark  pb-3 active"
                                : "lead  pb-3 active"
                              : darkMode
                              ? "leadDark  pb-3 "
                              : "lead  pb-3 "
                          }
                        >
                          Model Parameters
                        </p>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 3
                              ? "rounded-circle py-2 px-3 virticle-active text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          {index >= 4 ? (
                            <img
                              className="checkmarkimage"
                              src={darkMode ? darkCheckImg : checkimg}
                            ></img>
                          ) : (
                            <>3</>
                          )}
                        </div>
                        <div
                          className={
                            index >= 4
                              ? "line virticle-active h-100"
                              : "line h-100"
                          }
                        ></div>
                      </div>
                      <div>
                        <p
                          className={
                            index >= 3
                              ? darkMode
                                ? "leadDark  pb-3 active"
                                : "lead  pb-3 active"
                              : darkMode
                              ? "leadDark  pb-3 "
                              : "lead  pb-3 "
                          }
                        >
                          PEFT Parameters
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex flex-column pr-2 align-items-center">
                        <div
                          className={
                            index >= 4
                              ? "rounded-circle py-2 px-3 virticle-active text-white"
                              : "rounded-circle py-2 px-3 virticle-not-active text-white"
                          }
                        >
                          4
                        </div>
                        <div class="line h-100 virticle-active d-none"></div>
                      </div>
                      <div>
                        <p
                          className={
                            index >= 4
                              ? darkMode
                                ? "leadDark  pb-3 active"
                                : "lead  pb-3 active"
                              : darkMode
                              ? "leadDark  pb-3 "
                              : "lead  pb-3 "
                          }
                        >
                          Evaluate Model
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {index === 4 ? (
            <div className="container-fluid px-0">
              <div className="CustomTable-Header px-4">
                <div className="CustomTable-Header-Left">
                  {/* <span className="CustomTable-Header-Left-span text-uppercase border-end pe-3">
                     {PPOSideBarNames[index - 1]}
                  </span> */}
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } border-end pe-3`}
                  >
                    Model Name : {localStorage.getItem("name")}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } ps-3`}
                  >
                    SELECTED MODEL : {localStorage.getItem("basemodelname")}
                  </span>
                </div>
                <div className="CustomTable-Header-Right">
                  {index === 3 ? (
                    <button
                      className="submitBtnDark ms-3"
                      onClick={FineTuningstart}
                    >
                      Train Model
                    </button>
                  ) : (
                    <button className="submitBtnDark ms-3" onClick={proceed}>
                      Proceed
                    </button>
                  )}
                  {/* {index === 1 ? (
                    <button
                      className="FineTuning-cancleBtn"
                      onClick={() => navigate("/rewardmodel")}
                    >
                      Back
                    </button>
                  ) : (
                    <button className="FineTuning-cancleBtn" onClick={back}>
                      Back
                    </button>
                  )} */}
                </div>
              </div>
              <RewardModelPPOProgress darkMode={darkMode} />
            </div>
          ) : (
            <div className="finetuning-body-right pageHeadBg">
              <div className="CustomTable-Header">
                <div className="CustomTable-Header-Left">
                  {/* <span className="CustomTable-Header-Left-span text-uppercase border-end pe-3">
                    {PPOSideBarNames[index - 1]}
                  </span> */}
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } border-end pe-3`}
                  >
                    Model Name : {localStorage.getItem("name")}
                  </span>
                  <span
                    className={`taskList ${
                      darkMode ? "text-white" : "text-dark"
                    } ps-3`}
                  >
                    SELECTED MODEL : {localStorage.getItem("basemodelname")}
                  </span>
                </div>
                <div className="CustomTable-Header-Right">
                  {index === 3 ? (
                    <button
                      className="submitBtnDark ms-3"
                      onClick={FineTuningstart}
                    >
                      Train Model
                    </button>
                  ) : (
                    <button className="submitBtnDark ms-3" onClick={proceed}>
                      Proceed
                    </button>
                  )}
                  {index === 1 ? (
                    <>
                      <button
                        className="cancelBtnDark"
                        onClick={() => navigate("/rewardmodel")}
                      >
                        Back
                      </button>
                      {/* <a
                        href={RewardTemplate}
                        download="RewardTemplate"
                        target="_blank"
                        style={{
                          paddingTop: "6px",
                          paddingRight: "15px",
                          fontFamily: "calibri-bold",
                          fontSize: "16px",
                          color: darkMode ? "#BAC9F1" : ""
                        }}
                      >
                        <MdOutlineFileDownload style={{ fontSize: "18px" }} />{" "}
                        Download Template{" "}
                      </a> */}
                    </>
                  ) : (
                    <button className="cancelBtnDark" onClick={back}>
                      Back
                    </button>
                  )}
                </div>
              </div>
              {index === 1 ? (
                <UploadComp
                  uploaddone={uploaddone}
                  darkMode={darkMode}
                ></UploadComp>
              ) : // : index == 4 ? (
              //   <div className="w-100">
              //     <RewardModelPPOProgress />
              //   </div>
              // )
              index == 5 ? (
                <EvaluateAndGraph isPPO={true} darkMode={darkMode} />
              ) : (
                <CustomTable
                  updateData={updateData}
                  TableData={PPOTableData[index - 1]}
                  TableColumn={TableColumn[index - 1]}
                  darkMode={darkMode}
                ></CustomTable>
              )}
            </div>
          )}
        </div>
        <footer className="copyright copyright-footer">
          Copyright © TCS 2024. All Rights Reserved.
        </footer>
      </div>
    </>
  );
}

export default RewardAndPPO;
