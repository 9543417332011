import React, { useContext, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { DockProvider } from "./components/DockWrapper/DockContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./components/AgentModule/Home";
import Agentfactory from "./components/AgentModule/Agentfactory";
import Agents from "./components/AgentModule/Agents";
import Login from "./components/AgentModule/Login/Login";
import CreateAgent from "./components/AgentModule/CreateAgent/CreateAgent";
import AgentDetails from "./components/AgentModule/CreateAgent/dockComps/AgentDetails";
// import TestAgent from "./components/AgentModule/CreateAgent/TestAgent";
import SelectModel from "./components/AgentModule/CreateAgent/SelectModel";
import MainHomeScreen from "./components/MainHomeScreen";
import LandingPage from "./components/ModelOrchestrator/LandingPage";
import FlowView from "./components/AgentChainModule/FlowView";
import ModelCompetancy from "./components/AgentChainModule/ModelCompetancy/ModelCompetancy";
import AgentChains from "./components/AgentModule/AgentChains/AgentChains";
import Competency from "./components/AgentChainModule/ModelCompetancy/Competancy";
import ListCompetancy from "./components/AgentChainModule/ModelCompetancy/ListCompetancy";
import FineTuning from "./components/ModelOrchestrator/FineTunining/FineTuning";
import RewardModel from "./components/ModelOrchestrator/FineTunining/RewardModel";
import RewardAndPPO from "./components/ModelOrchestrator/FineTunining/RewardAndPPO";
import CompetencyDetails from "./components/AgentChainModule/ModelCompetancy/CompetencyDetails";
import Rflow from "./components/rflow/Rflow";
import ModalComp from "./components/AgentChainModule/CloneCompetancy/ModalComp";
import ModelHomeScreen from "./components/ModelOrchestrator/ModelHomeScreen/ModelHomeScreen";
import TestCompetency from "./components/AgentChainModule/TestCompetency/TestCompetency";
import Content from "./components/graph/Content";
import PreviewModel from "./components/ModelOrchestrator/FineTunining/PreviewModel";
import RewardModelPPOProgress from "./components/ModelOrchestrator/RewardModelPPOProgress";
import TrainingProgress from "./components/ModelOrchestrator/TrainingProgress";
import Playground from "./components/AgentChainModule/Playground/Playground";
import AChain from "./components/AChain/AChain";
import RewardModelHome from "./components/ModelOrchestrator/ModelHomeScreen/RewardModelHome";
import CloneCompetancy from "./components/AgentChainModule/CloneCompetancy/CloneCompetancy";
import GraphShow from "./components/ModelOrchestrator/Graphshow";
import SelectModelCompetancy from "./components/ModelOrchestrator/SelectModelCompetancy";
import ModelOrchestrator from "./components/ModelOrchestrator/ModelOrchestrator";
import ActivityList from "./components/AChain/ActivityList/ActivityList";
import AChainSaved from "./components/AChain/AChainSaved";
import H2oScreen from "./components/H2o/H2oScreen";
import AgentsList from "./components/AChain/AgentsList";
import { lightTheme, darkTheme } from "./theme";
import ModelConfiguration from "./components/AChain/ModelConfiguration";
import { AuthContext } from "./components/Auth/AuthContext";
import ChatBot from "./components/ChatBot";
import MenuShow from "./components/CustomNavbar/MenuShow";
import AlchemTableShow from "./components/SharedComponents/AlchemTable/AlchemTableShow";
import AlchemTableNavShow from "./components/SharedComponents/AlchemTableNav/AlchemTableNavShow";
import SyntheticTable from "./components/Synthetic/SyntheticTable/SyntheticTableList";
import SyntheticDataGenerator from "./components/Synthetic/SyntheticDataGenerator/SyntheticDataGenerator";
import AlchemProgressBar from "./components/SharedComponents/AlchemProgressBar/AlchemProgressBar";
import MindMapReactFlow from "./components/mindmap/MindMapReactFlow";
import Models from "./components/NewModelOrchestrator/Models";
import KnowledgeWork from "./components/NewModelOrchestrator/KnowledgeWork";
import Training from "./components/NewModelOrchestrator/TrainingModel";
import SelectRewardPPO from "./components/NewModelOrchestrator/SelectRewardPPO";
import SummaryScreen from "./components/NewModelOrchestrator/summary/Summary";

// axios.defaults.withCredentials = true; 
import AgentChainMapping from "./components/AgentModule/CreateAgent/agentChainMapping/AgentChainMapping";
import AgentModelMapping from "./components/AgentModule/CreateAgent/agentModelMapping/AgentModelMapping";
import Summary from "./components/AChain/summary/Summary";
import TestAgent from "./components/AgentModule/CreateAgent/testAgent/TestAgent";
import SmeSummary from "./components/AChain/SmeSummary/SmeSummary";
import TrainingDPO from "./components/NewModelOrchestrator/TrainingModelDPO";
import TraininRewardPPO from "./components/NewModelOrchestrator/TrainingModelRewardPPO";
import SmeSummaryModelView from "./components/AChain/SmeSummary/SmeSummaryModelView";
import TrainingModelPredictive from "./components/NewModelOrchestrator/TrainingModelPredictive";
import { minHeight } from "@mui/system";
import ReactFlowContainer from "./components/AChain/ReactFlowContainer";
import TestActivityChain from "./components/AChain/TestActivityChain";


function App() {
  const {
    token,
    setToken,
    setAuthenticated,
    accessToken,
    setAccessToken,
    setRefreshToken,
    setIDToken,
    darkMode,
    setDarkMode,
    setsubID,
    subID
  } = useContext(AuthContext);
  const theme = darkMode ? darkTheme : lightTheme;

  useEffect(() => {
    const body = document.body;
    body.style.backgroundColor = theme.body;
    body.style.color = theme.text;
  }, [theme]);


  const handleToggle = () => {
    setDarkMode(!darkMode);
  }

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }
  setAccessToken(getCookie('accessToken'));
  console.log(accessToken);

  const isAuthenticated = async () => {

    setIDToken(getCookie("IDToken"))
    const isTokenExpired = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTHENTICATE_SERVER}/introspect_token`, { "token": getCookie("accessToken") });
        console.log(response.data.active);
        if (response.data.active) {
          setsubID(response.data.sub)
        }

        return !response.data.active; // Return true if token is expired
      } catch (error) {
        console.error("Error checking token expiry:", error);
        return true; // Consider token expired if introspection fails
      }
    };

    const tokenExpired = await isTokenExpired();

    if (tokenExpired) {
      try {
        const headers = {
          "Content-Type": "application/json"
        };

        return axios
          .post(
            `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
            { headers }
          )
          .then(refreshResponse => {
            if (
              refreshResponse.status === 200 &&
              refreshResponse.data.access_token &&
              refreshResponse.data.refresh_token
            ) {
              setAccessToken(refreshResponse.data.access_token);
              setRefreshToken(refreshResponse.data.refresh_token);
              document.cookie = `accessToken=${refreshResponse.data.access_token}`;
              document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
              console.log("Token refreshed");
              return true;
            } else {
              console.log("Error refreshing token:", refreshResponse);
              return false;
            }
          })
          .catch(error => {
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data.error === "invalid_grant"
            ) {
              console.error("Refresh token expired or invalid");
            }

            return false; // Authentication failed if token refresh fails
          });
      } catch (error) {
        console.error("Error refreshing token:", error);
        return false; // Authentication failed if token refresh fails
      }
    }

    return true; // User is authenticated if token is not expired
  };
  useEffect(() => {
    console.log(process.env);
  }, [])
  useEffect(() => {
    const checkAuthentication = async () => {

      const isauthenticated = await isAuthenticated();
      setAuthenticated(isauthenticated);
      console.log(isauthenticated);
    };

    checkAuthentication();
  }, [token]);

  // if (!authenticated) {
  //   console.log(authenticated);
  //   return <Login setToken={setToken} />;

  // }
  if (!subID) {
    return <Login setToken={setToken} />;

  }
  if (!subID) {
    return <Login setToken={setToken} />;

  }






  const PrivateRoute = ({ children }) => {

    const isLoggedIn = isAuthenticated();
    console.log("Protecting and serving ", isLoggedIn)
    return isLoggedIn ? children : <Login setToken={setToken} />;

  };





  return (
    <DockProvider>
      <div className={`${darkMode ? 'pgHeaderBgDark' : 'pgHeaderBg'}`}>
        <div style={{ minHeight: '100vh', height: "200px" }} className="d-flex flex-column">
          <BrowserRouter >
            <Routes>
              <Route path="/" element={<MainHomeScreen />} />
              <Route path="/h2o" element={<H2oScreen />} />
              <Route path="/rflow" element={<Rflow />} />
              <Route
                path="/activityList"
                element={
                  <ActivityList
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                    firstTimeUser={'returning-user'}
                  />
                }
              />
              <Route
                path="/activityListFirstTime"
                element={
                  <ActivityList
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                    firstTimeUser={'first-time'}
                  />
                }
              />
              <Route path="/agentFactoryHome" element={<Home />} />
              <Route
                path="/agentfactory"
                element={
                  <Agentfactory
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              >
                <Route
                  path=""
                  element={
                    <Agents darkMode={darkMode} handleToggle={handleToggle} />
                  }
                />
                <Route path="createAgent" element={<CreateAgent />}>
                  <Route path="" element={<AgentDetails />} />
                  <Route path="selectModel" element={<SelectModel />} />
                  <Route
                    path="testAgent/:knowledgeWorkId/:activityId"
                    element={
                      <TestAgent
                        darkMode={darkMode}
                        handleToggle={handleToggle}
                      />
                    }
                  />
                </Route>
                <Route path="playground/:id" element={<Playground />} />
                <Route path="createAgentChain" element={<FlowView />} />
              </Route>
              <Route
                path="/modelOrchestratorHome"
                element={<LandingPage />}
              />
              <Route
                path="/modelOrchestrator"
                element={
                  <ModelOrchestrator
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              >
                <Route
                  path=""
                  element={
                    <SelectModelCompetancy
                      darkMode={darkMode}
                      handleToggle={handleToggle}
                    />
                  }
                />
              </Route>
              <Route path="/competency" element={<Competency />}>
                <Route path="" element={<ListCompetancy />} />
                <Route path="configure" element={<ModelCompetancy />} />
                <Route path="details/:id" element={<CompetencyDetails />} />
                <Route
                  path="createCompetencyChain"
                  element={<CloneCompetancy />}
                />
                <Route path="testCompetency" element={<TestCompetency />} />
              </Route>
              <Route path="/agentchains" element={<AgentChains />} />
              <Route
                path="/finetuning"
                element={
                  <FineTuning
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              />
              <Route
                path="/rewardmodel"
                element={<RewardModel handleToggle={handleToggle} />}
              />
              <Route path="/evalutemodel" element={<PreviewModel />} />
              <Route
                path="/rewardppo"
                element={
                  <RewardAndPPO
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              />
              <Route path="/comparemodels" element={<ModelHomeScreen />} />
              <Route
                path="/rewardmodelhome"
                element={
                  <RewardModelHome
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              />
              <Route path="/modal" element={<ModalComp />} />
              <Route path="/graph" element={<GraphShow />} />
              <Route
                path="/fgraph"
                element={
                  <Content darkMode={darkMode} handleToggle={handleToggle} />
                }
              />
              <Route path="/graph" element={<Content />} />
              <Route
                path="/rewardmodelprogress"
                element={<RewardModelPPOProgress />}
              />
              <Route
                path="/trainingprogress"
                element={<TrainingProgress />}
              />
              <Route
                path="/achain"
                element={
                  <AChain darkMode={darkMode} handleToggle={handleToggle} />
                }
              />
              <Route
                path="/achainsaved/:id"
                element={
                  <AChainSaved
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              />

              <Route
                path="/agentcreation"
                element={
                  <AgentsList
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              >
              </Route>
              <Route
                path="/knowledgework/playground/:id"
                element={<ChatBot />}
              />
              <Route
                path="/knowledgework/playground/:knowledgeWorkId/:activityId"
                element={<ChatBot isAgentTest={true} />}
              />
              <Route
                path="/modelconfig"
                element={
                  <ModelConfiguration
                    darkMode={darkMode}
                    handleToggle={handleToggle}
                  />
                }
              ></Route>
              <Route path="/customNav" element={<MenuShow />}></Route>
              <Route path="/customTable" element={<AlchemTableShow />} />
              <Route path="/customTableNav" element={<AlchemTableNavShow />} />
              <Route path="/synthetic" element={<SyntheticTable />}></Route>
              <Route path="/syntheticgenerator" element={<SyntheticDataGenerator />}></Route>
              <Route path="/progressbar" element={<AlchemProgressBar />}></Route>
              <Route path="/reactFlowTest" element={<MindMapReactFlow />}></Route>

              {/* newMo */}
              <Route path='/mo/models/:id?' element={<Models darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/knowledge-work' element={<KnowledgeWork darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/summary/:id' element={<Summary darkMode={darkMode}
                handleToggle={handleToggle} />} />

              <Route path='/mo/summary/new/:id' element={<SummaryScreen darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/summary/training/:id' element={<Training darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/summary/training/dpo/:id' element={<TrainingDPO darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/summary/training/reward-ppo/:id' element={<TraininRewardPPO darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/summary/training/select-reward-ppo/:id' element={<SelectRewardPPO darkMode={darkMode}
                handleToggle={handleToggle} />} />
              <Route path='/mo/summary/training/predictive/:id' element={<TrainingModelPredictive darkMode={darkMode}
                handleToggle={handleToggle} />} />

              <Route path="/smeAgentCreation" element={<AgentChainMapping />}></Route>
              <Route path="/agentMapping" element={<AgentModelMapping />}></Route>
              <Route path="/smesummary" element={<Summary />}></Route>
              <Route path="/summary" element={<SmeSummary />}></Route>
              <Route path="/summary-model" element={<SmeSummaryModelView />}></Route>
              <Route path="/testagent/:knowledgeWorkId/:activityId" element={
                <TestAgent
                  darkMode={darkMode}
                  handleToggle={handleToggle}
                />}></Route>
              <Route path="/login" element={<Login setToken={setToken} />}></Route>
              <Route path="/activitychain/:id" element={<TestActivityChain />}></Route>
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </DockProvider>

  );
}

export default App;
