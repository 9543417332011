import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
// import { Tooltip } from 'react-tooltip'
import { AuthContext } from '../Auth/AuthContext';
import "./LoadModal.css";


const LoadModal = ({ showModal, closeModal, content, recoContent, navLink, extra, cancelModal, submitModal, style, title }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { darkMode } = useContext(AuthContext)
  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };


  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    radio: ''
  });
  const [radio, setRadio] = useState('');
  const [isChecked, setIsChecked] = useState(false);


  const handleToggle = () => {
    setIsChecked(!isChecked);

  };
  const handleModalSubmit = (event) => {
    if (navLink == "") {
      closeModal()
    }
    console.log(navLink, extra)
    if (extra) {
      navigate(navLink, extra)
    } else {
      navigate(navLink)
    }

  }
  const [formData, setFormData] = useState({
    modelName: '',
    description: '',
    purpose: '',
    domain: 'Digital',
    task: '',
  })
  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setSelectedOption(value);
    setShowDropdown(value === 'yes');
    setRadio({
      ...radio,
      [name]: value,
    })
  }

  const handleOptionChange = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;

    // Show dropdown only if "Yes" is selected
    setFormData({
      ...formData,
      [name]: value,
    })
    // console.log(formData);
  };
  const arr = []

  arr.push(isChecked, formData)

  const handleSubmit = () => {
    console.log("calling")
    closeModal();
  }
  const tooltipStyle = {
    minWidth: '500px', // Set your desired width
  };
  return (
    <div  >
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className={darkMode ? 'alchemModalDark' : 'alchemModal'}

        dialogClassName={style != "" ? style : ""}
        style={{zIndex:"9999999999999999"}}
      >
        <Modal.Header
          closeButton
          className="border-bottom-0"
          style={{ paddingLeft: "32px", paddingRight: "32px" }}
        >
          <Modal.Title className="modalHead ">
            Coach LLM Recommendation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalText">
          {/* <h3 className="modalTitle">Genius Partner Recommendation</h3> */}
          <pre dangerouslySetInnerHTML={{ __html: content }}>
            {/* {content} */}
          </pre>
        </Modal.Body>

        <Modal.Footer id="footer" >
          <a className="btn btn-primary cancelBtnDark me-3" onClick={cancelModal ? cancelModal : closeModal} role="button">
            Ignore
          </a>
          <a className="btn btn-primary submitBtnDark me-3" onClick={submitModal ? submitModal : handleModalSubmit} role="button" style={{ "color": "white" }}>
            Proceed
          </a>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default LoadModal