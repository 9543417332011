import React from "react";
import ProgressBarBlue from "../ProgressBarBlue/ProgressBarBlue";
import ProgressBarGrey from "../ProgressBarGrey/ProgressBarGrey";
import "./VerticalBars.css";

const VerticalBars = ({data, darkMode}) => {
  console.log(data);
  return (
    <div className="vertical-wrapper" style={{background: darkMode ? '#212542' : '', border: 'none'}}>
      <div className="Vertical">
        <div className="VBar left">
          <ProgressBarGrey value={data.data1} ></ProgressBarGrey>
        </div>
        <div className="VBar right">
          <ProgressBarBlue value={data.data2} ></ProgressBarBlue>
        </div>
      </div>
      <p style={{color: darkMode ? '#BAC9F1' : ''}}>{data.data3}</p>
    </div>
  );
};

export default VerticalBars;
