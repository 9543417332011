import Nav from "react-bootstrap/Nav";
import checkimg from "../../../assets/img/checkDarkLarge.svg"
import { useContext, useState } from "react";
import styles from "./AlchemProgressBar.module.css"
import { DockContext } from "../../DockWrapper/DockContext";


const initialSteps = ["Knowledge Work", "    Select Model", "Model Configuration", "Test Agent"]
const initialTitle = `Pass "title" prop for this text`
const initialSubtitle = `Pass "subtitle" prop for this text`
const AlchemProgressBar = ({ title = initialTitle, subtitle = initialSubtitle, steps = initialSteps, index = (steps.length / 2) }) => {
  console.log(index);
  let { isDocked, path } = useContext(DockContext)
  console.log(isDocked)
  // document.body.classList.add("lightmode")
  return (
    <div className={`container-fluid  ${styles["alchem-progress-container"]}`}>
      <div className="row mx-0 position-relative ">
        {/* <div className="px-0   position-relative ">
          <h5 className={styles.title}>
            {title}
          </h5>

          <p className={styles.subtitle}>{subtitle} { } {subtitle.length > 0 ? (<div className={styles.i}>i</div>) : ''}</p>

        </div> */}
        <div className=" px-0   position-absolute w-100 d-flex align-items-center justify-content-center" style={{ zIndex: "1", margin: "-1.3rem", marginLeft: isDocked ? '8rem' : '' }}>
          <Nav variant="pills" defaultActiveKey="#" className=" d-flex flex-column" style={isDocked && path ? { width: "60%", marginLeft: "1rem" } : {}}>
            <div className="d-flex" style={isDocked && path ? { width: "90%" } : {}}>
              {steps.map((step, i) => {
                return (
                  <>
                    {i !== 0 && <div
                      className={(index >= (i + 1) ? styles.line : styles.line)}>
                    </div>}
                    <Nav.Item className="">
                      <p
                        to="/agentfactory/createAgentChain"
                        eventKey="/agentfactory/createAgentChain"
                        className={styles["alchem-progress-circle"] + "    " + (index > (i + 1) ? styles.completed : (index === i + 1 ? styles.active : " "))
                        }
                      >
                        {index > (i + 1) ? (
                          <img className="checkmarkimage" src={checkimg}></img>
                        ) : (
                          <div className={styles['circle-text']}>{i + 1}</div>
                        )}
                      </p>
                      <div className={`${styles["step-text"]} ${(i + 1) <= index ? styles["active-text"] : styles["inactive-text"]}   ${isDocked && path && styles["smalltxt"]}`} >
                        {step}
                      </div>
                    </Nav.Item>

                    {i < (steps.length - 1) && <div
                      className={(index >= (i + 1) ? styles.line : styles.line)}>
                    </div>}

                  </>)
              })}
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
}
export default AlchemProgressBar;
