import AlchemNav from "../AgentModule/AlchemNav/AlchemNav";
import { Link, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./modelOrchestrator.css";
import CustomNavbar from "../CustomNavbar/CustomNavbar";

export default function ModelOrchestrator({darkMode, handleToggle}) {
  const [navState, setNavState] = useState("wizard");
  return (
    <>
      {/* <Agents /> */}
      {/* <AlchemNav module={{ name: "agentFactory", state: navState }} darkMode={darkMode} handleToggle={handleToggle}/> */}
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle}/>
      {/* <CreateAgentNav /> */}
      <Outlet />
      <footer className="copyright copyright-footer">
        Copyright © TCS 2024. All Rights Reserved.
      </footer>
    </>
  );
}
