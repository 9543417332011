import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
// import { LinearProgress } from '@mnpm ui/material/LinearProgress';
import './ProgressBarGrey.css'

const ProgressBarGrey = ({value}) => {
    return (
        <div className='grey'>
            <LinearProgress
                variant="determinate"
                value={value}
            />
        </div>
    );
};

export default ProgressBarGrey;
