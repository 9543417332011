import React, { useEffect, useState } from "react";
import "./FineTuning.css";
import ModelOrchestratorNav from "./ModelOrchestratorNav";
import CustomTable from "../../Table/CustomTable";
import { TableData, TableColumn } from "./DB";
import UploadComp from "./UploadComp/UploadComp";

import { useNavigate } from "react-router-dom";
import TrainingProgress from "../TrainingProgress";


import checkimg from "../../../assets/img/check.png";
import darkCheckImg from "../../../assets/img/darkCheckImg.svg";

import axios from "axios";

import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import AlchemProgressBar from "../../SharedComponents/AlchemProgressBar/AlchemProgressBar";

function FineTuning({ darkMode, handleToggle }) {
  const [index, setindex] = useState(1);
  const [TableDataLocal, setTableDataLocal] = useState(TableData);
  const [TableColumnLocal, setTableColumnLocal] = useState(TableColumn);
  const [updateDataJson, setupdateDataJson] = useState({});

  const navigate = useNavigate();
  const proceed = () => {
    if (index <= 5) setindex(index + 1);
    console.log(index);
    setShowModal(false);
  };
  const uploaddone = () => {
    proceed();
  };

  useEffect(() => {
    if (index === 5) {
      console.log(TableDataLocal);
      console.log(updateDataJson);
      console.log("I'm fins9hed training");
    } else if (index === 6) {
      navigate("/rewardmodelhome");
      console.log("working  ");
    }
  }, [index]);
  const FineTuningstart = () => {
    const headers = { "Content-Type": "application/json" };
    console.log(updateDataJson);
    axios
      .post(
        "http://172.208.104.191:5002/start_training",
        JSON.stringify({
          basemodelid: localStorage.getItem("basemodelid"),
          name: localStorage.getItem("name"),
          description: localStorage.getItem("description"),
          usecase: localStorage.getItem("usecase"),
          task: localStorage.getItem("task"),
          userid: 1,
          purpose: localStorage.getItem("purpose"),
          finetuned_model_src: localStorage.getItem("datasetsrc"),
          hyperparamsid: 4,
          dataset_id: localStorage.getItem("datasetid")
        }),
        { headers }
      )
      .then(res => {
        console.log(res.data);

        proceed();
      });

    proceed();
    // navigate("/trainingprogress");
  };

  const updateData = (data_index, column, type, value, key) => {
    if (
      type === "text" ||
      type === "number" ||
      type === "select" ||
      type === "radio"
    ) {
      let data = TableDataLocal;
      let tempupdateDate = updateDataJson;
      tempupdateDate[key] = value;
      setupdateDataJson(tempupdateDate);
      data[index - 1][data_index][column]["value"] = value;
      setTableDataLocal([...data]);
    }
  };

  const back = () => {
    if (index >= 2) setindex(index - 1);
    console.log(index);
  };


  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    // navigate("/evalutemodel");
    setShowModal(false);
  };
  const [content, setContent] = useState(
    "Given the specificity of ADRE detection, we recommend hyperparameters that aims for lower training loss and higher accuracy"
  );

  const [recoContent, setRecContent] = useState();
  const newContent = `<strong>Resource Efficiency of PEFT</strong>
  Parameter-Efficient Fine-Tuning (PEFT) delivers resource efficiency in healthcare, particularly in ADRE prediction. PEFT allows for the fine-tuning of large-scale models on specific ADRE datasets without the need for comprehensive model overhauls. This approach significantly reduces computational demands, enabling the deployment of advanced ADRE prediction models in a broader range of healthcare settings. The potential increase in the deployment of these models in hospitals could be as high as 20%, substantially enhancing patient safety.

  <strong>Rapid Adaptability with LoRA</strong>
  Low-Rank Adaptation (LoRA) contributes significantly to model adaptability. By altering only a small segment of the model, such as neural network weights, LoRA enables rapid adaptation to specific ADRE tasks. This feature is vital for swiftly responding to new drugs or emerging ADRE patterns, potentially reducing the time required for model updates and deployment by 15%.

  <strong>Handling Large Datasets through Data and Model Parallelism</strong>
  Data and Model Parallelism addresses the challenge of processing large and complex datasets in ADRE studies. These techniques enable the efficient handling of extensive data on drug interactions and patient records, improving the accuracy of ADRE predictions. A 25% improvement in processing capabilities could translate to a 10% increase in prediction accuracy, significantly aiding in the prevention of adverse drug events.

  <strong>Combined Impact on ADRE Prediction</strong>
  When combined, these advanced techniques offer a comprehensive approach to ADRE prediction. They ensure models are resource-efficient, rapidly adaptable, and capable of effectively handling large datasets. This synergy could lead to a 20-30% reduction in ADRE-related hospitalizations, markedly improving patient outcomes and reducing healthcare costs associated with treating these reactions. The adoption of these advanced compute and learning optimization techniques is a critical step forward in enhancing patient care and safety in the pharmaceutical and healthcare industries.
</pre>`;

  const [isPeft, setisPeft] = useState(false);
  const doPeftReco = () => {
    setContent(
      `Advanced techniques like PEFT, LoRA, and Data/Model Parallelism enhance ADRE prediction in healthcare, reducing hospitalisations by 20-30% through improved efficiency, adaptability, and accuracy in processing large datasets.`
    );
    setShowModal(true);
    setisPeft(true);
    setRecContent(newContent);
  };
  let title = 'Model Orchestrator'
  let subtitle = `Model Name : ${localStorage.getItem("name")}`
  let steps = ['Select Model','Fine-tuning', 'Preview Model']

  return (
    <>
      {/* <LoadModal
        showModal={showModal}
        closeModal={closeModal}
        content={content}
        submitModal={proceed}
        style = {isPeft == true ? "my-modal": ""}
        recoContent={recoContent? recoContent: null}
      /> */}
      <div className="">
        <CustomNavbar links={[]} profile={true} handleToggle={handleToggle}/>
      
        <div className="finetuning-outer">
          <div className="finetuning-head">
          <AlchemProgressBar title = {title} subtitle = {subtitle} steps = {steps} index = {2}/>
          </div>
          <div className="finetuning-body pageHeadBg mt-3">
            {index >= 5 ? (
              <> </>
            ) : (
              <div className="finetuning-body-left pl-40 ">
                <div className="p-4"></div>
                <div
                  className={
                    darkMode
                      ? "finetuning-navbar fineTuningDark"
                      : "finetuning-navbar"
                  }
                  style={{
                    background: darkMode ? "#42466C" : "",
                    border: darkMode ? "none" : ""
                  }}
                > 
                  
                  <div
                    className="finetuning-navbar-header fw-bold text-start"
                    style={{ fontSize: "16px" }}
                  >
                    Supervised Fine-tuning
                  </div>
                  <div className="finetuning-navbar-body">
                    <div className="stepper d-flex flex-column mt-1 ml-2 pb-2">
                      <div className="d-flex">
                        <div className="d-flex flex-column pr-2 align-items-center">
                          <div
                            className={
                              index >= 1
                                ? "rounded-circle py-2 px-3 virticle-active text-white"
                                : "rounded-circle py-2 px-3 virticle-not-active text-white"
                            }
                          >
                            {index >= 2 ? (
                              <img
                                className="checkmarkimage"
                                src={darkMode ? darkCheckImg : checkimg}
                              ></img>
                            ) : (
                              <>1</>
                            )}
                          </div>
                          <div
                            className={
                              index >= 2
                                ? "line virticle-active h-100"
                                : "line h-100 bg-primary"
                            }
                          ></div>
                        </div>
                        <div>
                          <p
                            className={
                              index >= 1
                                ? darkMode
                                  ? "leadDark  pb-3 active"
                                  : "lead  pb-3 active"
                                : darkMode
                                ? "leadDark  pb-3 "
                                : "lead  pb-3 "
                            }
                          >
                            Upload Data
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex flex-column pr-2 align-items-center">
                          <div
                            className={
                              index >= 2
                                ? "rounded-circle py-2 px-3 virticle-active text-white"
                                : "rounded-circle py-2 px-3 virticle-not-active text-white"
                            }
                          >
                            {index >= 3 ? (
                              <img
                                className="checkmarkimage"
                                src={darkMode ? darkCheckImg : checkimg}
                              ></img>
                            ) : (
                              <>2</>
                            )}
                          </div>
                          <div
                            className={
                              index >= 3
                                ? "line virticle-active h-100"
                                : "line h-100"
                            }
                          ></div>
                        </div>
                        <div>
                          <p
                            className={
                              index >= 2
                                ? darkMode
                                  ? "leadDark  pb-3 active"
                                  : "lead  pb-3 active"
                                : darkMode
                                ? "leadDark  pb-3 "
                                : "lead  pb-3 "
                            }
                          >
                            Basic Hyperparameters
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex flex-column pr-2 align-items-center">
                          <div
                            className={
                              index >= 3
                                ? "rounded-circle py-2 px-3 virticle-active text-white"
                                : "rounded-circle py-2 px-3 virticle-not-active text-white"
                            }
                          >
                            {index >= 4 ? (
                              <img
                                className="checkmarkimage"
                                src={darkMode ? darkCheckImg : checkimg}
                              ></img>
                            ) : (
                              <>3</>
                            )}
                          </div>
                          <div
                            className={
                              index >= 4
                                ? "line virticle-active h-100"
                                : "line h-100"
                            }
                          ></div>
                        </div>
                        <div>
                          <p
                            className={
                              index >= 3
                                ? darkMode
                                  ? "leadDark  pb-3 active"
                                  : "lead  pb-3 active"
                                : darkMode
                                ? "leadDark  pb-3 "
                                : "lead  pb-3 "
                            }
                          >
                            Advanced Hyperparameters
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex flex-column pr-2 align-items-center">
                          <div
                            className={
                              index >= 4
                                ? "rounded-circle py-2 px-3 virticle-active text-white"
                                : "rounded-circle py-2 px-3 virticle-not-active text-white"
                            }
                          >
                            {" "}
                            {index >= 5 ? (
                              <img
                                className="checkmarkimage"
                                src={darkMode ? darkCheckImg : checkimg}
                              ></img>
                            ) : (
                              <>4</>
                            )}
                          </div>
                          <div
                            className={
                              index >= 5
                                ? "line virticle-active h-100"
                                : "line h-100"
                            }
                          ></div>
                        </div>
                        <div>
                          <p
                            className={
                              index >= 4
                                ? darkMode
                                  ? "leadDark  pb-3 active"
                                  : "lead  pb-3 active"
                                : darkMode
                                ? "leadDark  pb-3 "
                                : "lead  pb-3 "
                            }
                          >
                            PEFT Hyperparameters
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex flex-column pr-2 align-items-center">
                          <div
                            className={
                              index >= 5
                                ? "rounded-circle py-2 px-3 virticle-active text-white"
                                : "rounded-circle py-2 px-3 virticle-not-active text-white"
                            }
                          >
                            5
                          </div>
                          <div className="line h-100 d-none"></div>
                        </div>
                        <div>
                          <p
                            className={
                              index >= 5
                                ? darkMode
                                  ? "leadDark  pb-3 active"
                                  : "lead  pb-3 active"
                                : darkMode
                                ? "leadDark  pb-3 "
                                : "lead  pb-3 "
                            }
                          >
                            Evaluate Model
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {index >= 5 ? (
              <div className="w-100">
                <TrainingProgress darkMode={darkMode} />
              </div>
            ) : (
              <div className="finetuning-body-right pr-40">
                <div className="CustomTable-Header">
                  <div
                    className="CustomTable-Header-Left"
                    style={{ lineHeight: "31px" }}
                  >
                    {/* <span className="CustomTable-Header-Left-span text-uppercase border-end pe-3">
                      {SideBarNames[index - 1]}
                    </span> */}
                    <span
                      className={`taskList ${
                        darkMode ? "text-white" : "text-dark"
                      } border-end pe-3`}
                    >
                      Model Name : {localStorage.getItem("name")}
                    </span>
                    <span
                      className={`ps-3 ${
                        darkMode ? "text-white" : "text-dark"
                      } `}
                    >
                      SELECTED MODEL : {localStorage.getItem("basemodelname")}
                    </span>
                  </div>
                  <div className="CustomTable-Header-Right">
                    {index == 4 ? (
                      <button
                        className="submitBtnDark ms-3"
                        onClick={FineTuningstart}
                      >
                        Start Finetuning
                      </button>
                    ) : (
                      <button
                        className={
                          darkMode
                            ? "btn btn-primary submitBtnDark"
                            : "btn btn-primary submitBtnDark"
                        }
                        onClick={e => {
                          // index == 3 ? doPeftReco() : proceed();
                          proceed();
                        }}
                      >
                        Proceed
                      </button>
                    )}
                    {index === 1 ? (
                      <>
                        <button
                          className={
                            darkMode
                              ? "btn btn-primary cancelBtnDark"
                              : "btn btn-primary cancelBtnDark"
                          }
                          onClick={() => navigate("/modelOrchestrator")}
                        >
                          Back
                        </button>

                        {/* <a
                          href={FineTuningTemplate}
                          download="Template"
                          target="_blank"
                          style={{
                            paddingTop: "6px",
                            paddingRight: "15px",
                            fontFamily: "calibri-bold",
                            fontSize: "16px",
                            color: darkMode ? "#BAC9F1" : ""
                          }}
                        >
                          <MdOutlineFileDownload style={{ fontSize: "18px" }} />{" "}
                          Download Template{" "}
                        </a> */}
                      </>
                    ) : (
                      <button
                        className={
                          darkMode
                            ? "btn btn-primary cancelBtnDark"
                            : "btn btn-primary cancelBtnDark"
                        }
                        onClick={back}
                      >
                        Back
                      </button>
                    )}
                  </div>
                </div>
                {index === 1 ? (
                  <UploadComp
                    uploaddone={uploaddone}
                    darkMode={darkMode}
                  ></UploadComp>
                ) : index == 5 ? (
                  <>
                    <TrainingProgress />
                  </>
                ) : (
                  <CustomTable
                    updateData={updateData}
                    TableData={TableDataLocal[index - 1]}
                    TableColumn={TableColumnLocal[index - 1]}
                    darkMode={darkMode}
                  ></CustomTable>
                )}
              </div>
            )}
          </div>
        </div>
        <footer className="copyright copyright-footer">
          Copyright © TCS 2024. All Rights Reserved.
        </footer>
      </div>
    </>
  );
}

export default FineTuning;
