import { Handle, Position } from "reactflow";
import "../AChain/AChain.css";
import colorCode from "../../data/activityNodeDB";
import { Col, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { DockContext } from "../DockWrapper/DockContext";
import union from "../../assets/img/Union.svg";
import checkDark from "../../assets/img/darkCheckImg.svg";
import PropTypes from "prop-types";
import { AuthContext } from "../Auth/AuthContext";
import React from 'react'

export default function TitleNode({ data, isConnectable, id }) {

  console.log(data);
  return (
    <button
      className={` h-100 w-100 bg-transparent`}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{
          backgroundColor: "#ADC2FF",
          border: "none"
        }}
      />
      <div style={{color:"black"}}>
        {data.label}
      </div>

      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={{
          backgroundColor: "#ADC2FF",
          border: "none"
        }}
      />
    </button>
  )
}



TitleNode.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};
