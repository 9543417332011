import React, { useState, useEffect } from "react";
import Header from "./Header";
import Cards from "./Cards";
import "./content.css";
import HorizontalBars from "./HorizontalBars/HorizontalBars";
import VerticalBars from "./VerticalBars/VerticalBars";
import { LineChart, Line } from "@mui/x-charts";
import AlchemNav from "../AgentModule/AlchemNav/AlchemNav";
import GraphNav from "./GraphNav";
import NavBar from "../AgentModule/NavBar";

const uData = [0, 40, 50, 30, 80, 70, 60, 60, 80, 90];
const kData = [90, 80, 60, 60, 70, 80, 30, 50, 40, 0];

export default function Content({darkMode, handleToggle}) {
  const [kl, setKl] = useState(0);
  const [mean, setMean] = useState(0);
  const [advantageMean, setAdvantageMean] = useState(0);
  const [graphData, setgraphData] = useState({
    linegraph: {
      graph1: {
        data1: [0, 40, 50, 30, 80, 70, 60, 60, 80, 90],
        data2: [90, 80, 60, 60, 70, 80, 30, 50, 40, 120]
      },
      graph2: {
        data1: [0, 40, 50, 30, 80, 70, 60, 60, 80, 90],
        data2: [90, 80, 60, 60, 70, 80, 30, 50, 40, 20]
      }
    },
    verticalBars: {
      pair1: {
        data1: 30,
        data2: 60,
        data3: "ARC"
      },
      pair2: {
        data1: 40,
        data2: 50,
        data3: "Rouge"
      },
      pair3: {
        data1: 50,
        data2: 50,
        data3: "MMLU"
      },
      pair4: {
        data1: 50,
        data2: 50,
        data3: "HellaSWAG"
      },
      pair5: {
        data1: 50,
        data2: 50,
        data3: "Truthful QA"
      }
    },
    horizontalBars: {
      pair1: {
        data1: 30,
        data2: 60,
        data3: "Mean"
      },
      pair2: {
        data1: 90,
        data2: 79,
        data3: "Standard Deviation"
      }
    },
    cards: {
      mean: 0,
      improment: 23,
      kl: kl,
      returns: 23,
      policy: advantageMean
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // API CORS ISSUE UNCOMMENT AFTER FIXING THAT

        // const response = await fetch('http://172.208.104.191:5002/get_ppo_metrics', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   // Add your request payload here
        //   body: JSON.stringify({
        //     "my_folder": "/data/llm_factory/API_Testing/metrics.txt"
        //   }),
        // });

        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        //const result = await response.json();

        // temporary payload
        const result = [
          "objective/kl: 3.0650134086608887\n",
          "ppo/returns/mean: 1.091374158859253\n",
          "ppo/policy/advantages_mean: -0.015806524083018303\n"
        ];

        const formattedData = result.reduce((acc, item) => {
          const [key, value] = item.split(":");
          acc[key.trim()] = parseFloat(value.trim());
          return acc;
        }, {});

        console.log("ADASDASDASD", formattedData["objective/kl"]);
        setKl(formattedData["objective/kl"]);
        setMean(formattedData["ppo/returns/mean"]);
        setAdvantageMean(formattedData["ppo/policy/advantages_mean"]);
        console.log("dasdasd", graphData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content-container p-2" style={{background: darkMode ? '#474F72' : ''}}>
      {/* <AlchemNav/> */}
      {/* <GraphNav/> */}
      {/* <Header /> */}
      <div className="cards-container mt-2" >
        <Cards value={mean.toFixed(4)} name={"Mean improvement"} darkMode={darkMode}/>
        <Cards
          value={graphData.cards.improment}
          name={"Standard improvement"}
          darkMode={darkMode}
        />
        <Cards value={kl.toFixed(4)} name={"KL Divergence"} darkMode={darkMode}/>
        <Cards value={graphData.cards.returns} name={"PPO/Returns/Mean"} darkMode={darkMode}/>
        <Cards
          value={advantageMean.toFixed(4)}
          name={"PPO/Policy/ Advantages Mean"}
          darkMode={darkMode}
        />
      </div>
      <div className="horizontal-cards-container">
        <HorizontalBars data={graphData.horizontalBars.pair1} darkMode={darkMode}></HorizontalBars>
        <HorizontalBars data={graphData.horizontalBars.pair2} darkMode={darkMode}></HorizontalBars>
      </div>
      <div className="vertical-cards">
        <VerticalBars data={graphData.verticalBars.pair1} darkMode={darkMode}/>
        <VerticalBars data={graphData.verticalBars.pair2} darkMode={darkMode}/>
        <VerticalBars data={graphData.verticalBars.pair3} darkMode={darkMode}/>
        <VerticalBars data={graphData.verticalBars.pair4} darkMode={darkMode}/>
        <VerticalBars data={graphData.verticalBars.pair5} darkMode={darkMode}/>
      </div>

      <div className="cards-container mt-0" >
        <div className="chart-div" style={{background: darkMode ? '#212542' : '', border: 'none'}}>
          <div className="chart-div-wrapper">
            <h4 style={{color: darkMode ? '#BAC9F1' : ''}}>Diagnostic Chart</h4>
            <div className="icon-div-outer">
              <div className="icon-div-inner"></div>
            </div>
          </div>
          <LineChart
            sx={{
              //change left yAxis label styles
              "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                strokeWidth: "0.4",
                fill: darkMode ? '#BAC9F1' : "#888888"
              },
              // change all labels  shown on both xAxis and yAxis
              "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                fontFamily: "Roboto",
                fill: darkMode ? '#BAC9F1' : "#888888"
              },
              // change bottom label styles
              "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                strokeWidth: "0.5",
                stroke: darkMode ? '#BAC9F1' : "#888888"
              },
              // bottomAxis Line Styles
              "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                stroke: darkMode ? '#BAC9F1' : "#888888",
                strokeWidth: 0.4
              },
              // leftAxis Line Styles
              "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                stroke: darkMode ? '#BAC9F1' : "#888888",
                strokeWidth: 0.4
              },
              // area of the line
              "& .MuiAreaElement-root": {
                strokeDasharray: "10 5",
                strokeWidth: 4
                // fill: "url('#myGradient')",
              },
              "& .MuiAreaElement-series-uvId": {
                fill: "url('#myGradient')"
              },
              "& .MuiAreaElement-series-vId": {
                fill: "url('#myGrad')"
              },
              //  line
              "& .MuiLineElement-root": {
                strokeWidth: 5
                // fill: "url('#myGradient')",
              }
            }}
            // width={1000}
            height={400}
            series={[
              // { data: pData, label: 'pv', id: 'pvId' },
              {
                data: graphData.linegraph.graph1.data1,
                id: "vId",
                color: "#A0A0A0",
                textColor: "white",
                area: true,
                showMark: false
              },
              {
                data: graphData.linegraph.graph1.data2,
                id: "uvId",
                color: "#3366ff",
                textColor: "white",
                area: true,
                showMark: false
              }
            ]}
            slotProps={{
              legend: {
                labelStyle: {
                  fontSize: 25,
                  fill: darkMode ? '#BAC9F1' : "#a0a0a0"
                }
              }
            }}
            xAxis={[
              {
                label: "Time",
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                scaleType: "band",
                labelStyle: {
                  fill: darkMode?"#BAC9F1":"#A0A0A0"
                }
              }
            ]}
            yAxis={[
              {
                label: "Accuracy",
                labelStyle: {
                  fill: darkMode?"white":"#A0A0A0"
                }
              }
            ]}
          >
            <defs>
              <linearGradient id="myGrad" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#A0A0A0" />
                <stop offset="50%" stopColor="white" />
                {/* <stop offset="45%" stopColor="white" /> */}
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="myGradient" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#3366FF" />
                <stop offset="50%" stopColor="#88B2FF" />
                <stop offset="75%" stopColor="#fff" />
              </linearGradient>
            </defs>
          </LineChart>
        </div>
        <div className="chart-div" style={{background: darkMode ? '#212542' : '',border: 'none'}}>
          <div className="chart-div-wrapper">
            <h4 style={{color: darkMode ? '#BAC9F1' : ''}}>Diagnostic Chart</h4>

            <div className="icon-div-outer">
              <div className="icon-div-inner"></div>
            </div>
          </div>
          <LineChart
            sx={{
              //change left yAxis label styles
              "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                strokeWidth: "0.4",
                stroke: darkMode ? '#BAC9F1' : "#888888"
              },
              // change all labels fontFamily shown on both xAxis and yAxis
              "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                fontFamily: "Roboto",
                fill: darkMode ? '#BAC9F1' : "#888888"
              },
              // change bottom label styles
              "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                strokeWidth: "0.5",
                stroke: darkMode ? '#BAC9F1' : "#888888"
              },
              // bottomAxis Line Styles
              "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                stroke: darkMode ? '#BAC9F1' : "#888888",
                strokeWidth: 0.4
              },
              // leftAxis Line Styles
              "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                stroke: darkMode ? '#BAC9F1' : "#888888",
                strokeWidth: 0.4
              },
              // area of the line
              "& .MuiAreaElement-root": {
                strokeDasharray: "10 5",
                strokeWidth: 4
                // fill: "url('#myGradient')",
              },
              "& .MuiAreaElement-series-uvId": {
                fill: "url('#myGradient')"
              },
              "& .MuiAreaElement-series-vId": {
                fill: "url('#myGrad')"
              },
              //  line
              "& .MuiLineElement-root": {
                strokeWidth: 5
                // fill: "url('#myGradient')",
              }
            }}
            // width={1000}
            height={400}
            series={[
              // { data: pData, label: 'pv', id: 'pvId' },
              {
                data: graphData.linegraph.graph2.data1,
                id: "vId",
                color: "#A0A0A0",
                textColor: "white",
                area: true,
                showMark: false
              },
              {
                data: graphData.linegraph.graph2.data2,
                id: "uvId",
                color: "#3366FF",
                textColor: "white",
                area: true,
                showMark: false
              }
            ]}
            slotProps={{
              legend: {
                labelStyle: {
                  fontSize: 15,
                  fill: darkMode ? '#BAC9F1' : "#888888"
                }
              }
            }}
            xAxis={[
              {
                label: "Time",
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                scaleType: "band",
                labelStyle: {
                  fontSize: 15,
                  fill: darkMode ? '#BAC9F1' : "#888888"
                }

              }
            ]}
            yAxis={[
              {
                label: "Accuracsdfsy",
                labelStyle: {
                  
                },
                fill: "white"

              }
            ]}
          >
            <defs>
              <linearGradient id="myGrad" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#A0A0A0" />
                <stop offset="50%" stopColor="white" />
                {/* <stop offset="45%" stopColor="white" /> */}
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="myGradient" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#3366FF" />
                <stop offset="50%" stopColor="#88B2FF" />
                <stop offset="75%" stopColor="#fff" />
              </linearGradient>
            </defs>
          </LineChart>
        </div>
      </div>
    </div>
  );
}
