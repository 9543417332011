import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import NavBar from "./AgentModule/NavBar";
import Button from "react-bootstrap/Button";
import dashboardImg from "../assets/img/dashboardImg.png";
import { AuthContext } from "./Auth/AuthContext";
import { DockContext } from "./DockWrapper/DockContext";
export default function MainHomeScreen() {
  const { darkMode } = useContext(AuthContext);
  const {
    isDocked,
    path
  } = useContext(DockContext);

  return (
    <div className={darkMode ? "home-image-dark" : "home-image"}>
      <NavBar />
      <div style={{ height: "80%" }} className="row g-0">
        <div className="col-12 col-md-7 ">
          <div
            className="d-flex align-items-center px-6"
            style={{ paddingTop: "166px" }}
          >
            <div className="col-10 col-xl-12 p-0">
              <div>
                <h2 className="h4 mb2 home-title">
                  Transforming knowledge work
                </h2>
                <p className="home-text mb-4">
                  An AI-First platform to assure knowledge work transformation
                  and make it more efficient, effective, and of consistently
                  high quality
                </p>
                <div className={isDocked && path ? "" : "pt-4"} style={isDocked ? { display: "flex" } : { display: "block" }} >
                  <Link to="/activityListFirstTime" className="homeScreen">
                    <Button className="me-4 homeBtn" variant="">

                      Knowledge Work
                    </Button>
                  </Link>
                  <Link to="/mo/knowledge-work" className="homeScreen">
                    <Button className="me-4 homeBtn" variant="">

                      Model Orchestrator
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="card-body p-3 p-md-4 px-xl-5 pt-xl-0 pt-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  {darkMode ? (
                    ""
                  ) : (
                    <img
                      className="img-fluid rounded"
                      loading="lazy"
                      style={{ maxWidth: "98%" }}
                      src={dashboardImg}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer
        className="copyright copyright-home-screen"
        style={{ height: "10%" }}
      >
        Copyright © TCS 2024. All Rights Reserved.
      </footer>
    </div>
  );
}
