import React from 'react'
import './cards.css'

export default function Cards({value, name, darkMode}) {
  return (
    <div>
        {/* <h5 style={{color: darkMode ? '#BAC9F1' : '', textAlign: 'center'}}>{value}</h5>
        <p style={{color: darkMode ? '#fff' : '',  textAlign: 'center'}}>{name}</p> */}
        <div class="card p-2" style={{background: darkMode ? '#212542' : '', border:'none', color: darkMode ? '#BAC9F1' : '', textAlign: 'center'}}>
  <div class="card-body">
    <h5 class="card-title">{value}</h5>
    <h6 class=" mb-2" style={{color : darkMode ? '#fff'  :'' }}>{name}</h6>
  </div>
</div>
    </div>
  )
}
