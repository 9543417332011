// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    transform: translate3d(0%, 0, 0);
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
    padding: 15px 15px 80px;
}

.come-from-modal.right.fade .modal-dialog {
    right: 30%;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AChain/SmeSummary/sidebarModal.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,YAAY;IAIZ,gCAAgC;AACpC;;AAEA;;IAEI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IAIV,oDAAoD;AACxD;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".come-from-modal.left .modal-dialog,\n.come-from-modal.right .modal-dialog {\n    position: fixed;\n    margin: auto;\n    width: 320px;\n    height: 100%;\n    -webkit-transform: translate3d(0%, 0, 0);\n    -ms-transform: translate3d(0%, 0, 0);\n    -o-transform: translate3d(0%, 0, 0);\n    transform: translate3d(0%, 0, 0);\n}\n\n.come-from-modal.left .modal-content,\n.come-from-modal.right .modal-content {\n    height: 100%;\n    overflow-y: auto;\n    border-radius: 0px;\n}\n\n.come-from-modal.left .modal-body,\n.come-from-modal.right .modal-body {\n    padding: 15px 15px 80px;\n}\n\n.come-from-modal.right.fade .modal-dialog {\n    right: 30%;\n    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;\n    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;\n    -o-transition: opacity 0.3s linear, right 0.3s ease-out;\n    transition: opacity 0.3s linear, right 0.3s ease-out;\n}\n\n.come-from-modal.right.fade.in .modal-dialog {\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
