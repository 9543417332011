import React, { useState, useEffect } from "react";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import downarrow from "../../assets/img/down_arrow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import uparrow from "../../assets/img/up_arrow.svg";
import Graphshowppo from "./Graphshowppo";
function RewardModelPPOProgress({darkMode}) {
  const [rewardprogress, setRewardProgress] = useState([]);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [counter, setCounter] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [disp, setdisp] = useState("");
  const [startTime, setStartTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);

  const formatTime = totalSeconds => {
    console.log("rfsf", totalSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Padding each value with a leading zero if less than 10
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://172.208.104.191:5002/get_logs"
        );
        const logs = response.data.logs;
        const regexPattern = /(\d+)it \[(\d{2}):(\d{2}), (\d+\.\d+)s\/it\]/;

        if (rewardprogress === logs) {
          setCounter(prevCounter => prevCounter + 1);
        } else {
          setCounter(0);
        }
        setRewardProgress(logs);

        logs.forEach(log => {
          let percentage = 0;
          if (log.includes("COMPLETED")) {
            percentage = 99;
            setLoadingPercentage(percentage);
            setCompleted(true);
          }
          // Custom regex patterns for different log messages
          else if (log.includes("it")) {
            setdisp("TRAIN MODEL");
            const match = log.match(/(\d+)it/);
            if (match) {
              percentage = parseInt(match[0]);
            }
            console.log(match);
          }

          // Add more conditions for other log patterns if needed

          // Set the loading percentage based on the matched regex pattern

          setLoadingPercentage(percentage / 10);
        });

        console.log("Fetch Reward model progress success");
      } catch (error) {
        console.error(error);
      }
    };

    const interval = setInterval(() => {
      // Stop fetching data if progress reaches 100%
      if (counter === 3) {
        setLoadingPercentage(100);
        clearInterval(interval);
        setCompleted(true);
      } else {
        fetchData();
      }
      console.log(completed, counter);
    }, 3000); // Fetch data every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [loadingPercentage]);
  const [displayText, setDisplayText] = useState(true);
  const toggleText = () => {
    setDisplayText(displayText === true ? false : true);
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mx-0 mb-3">
        <div className="col-12 px-4" style={{color: darkMode ? '#BAC9F1' : ''}}>
          {completed ? (
            <>
              Congratulation training completed{" "}
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ marginLeft: "5px" }}
              />
            </>
          ) : (
            <div className="card mb-4 p-3" style={{background: darkMode ? '#34385A' : ''}}>
              <div className="mt-2 px-3">
                <span className="tabSub-head" style={{color: darkMode ? '#BAC9F1' : ''}}>Reward PPO Model: {disp} </span>
              </div>
              <div className="row mx-0 mt-2 px-3">
                <div className="col-md-10 px-0 py-2">
                  <ProgressBar
                    style={{ width: "100%" }}
                    now={loadingPercentage}
                    min="0"
                    max="100"
                  />
                </div>

                <div className="col-md-2 mb-2">
                  <a className="cancelBtnDark" href="#" role="button">
                    {" "}
                    Cancel
                  </a>
                </div>
              </div>

              <div className="row mb-3 mx-0 px-3">
                <div className="col-md-10 px-0">
                  <span className="baseFont " style={{color: darkMode ? '#BAC9F1' : ''}}>{loadingPercentage}%</span>
                </div>
                <div className="col-md-2 d-flex flex-start pe-0 ps-2">
                  <span className="baseFont float-right" style={{color: darkMode ? '#BAC9F1' : ''}}>
                    {formatTime(elapsedTime)}
                  </span>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
      <div
        className="row mx-0 d-flex align-items-center justify-content-center"
        style={{ backgroundColor: darkMode  ?'#474E71': "#D9E3F8", border:'none' }}
      >
        <button type="button" className="btn p-0">
          <a
            className="btn btn-primary consoleBtn align-items-center"
            href="#"
            role="button"
            onClick={toggleText}
          >
            Console
            <img
              className="img-fluid mb-1 ps-3"
              loading="lazy"
              src={downarrow}
              alt=""
              style={{ height: "20px", width: "25px" }}
            />
          </a>
        </button>
      </div>
      {!displayText ? (
        <Graphshowppo loadingPercentage={loadingPercentage} />
      ) : (
        <div className="row mb-3 mx-0">
          <div className="col-12 px-0">
            <div
              className="cards"
              style={{
                backgroundColor: "#031039"
                // overflow: "scroll",
                // marginBottom: "100px"
              }}
            >
              <div className="card-header border-white">
                <ul className="nav mt-0">
                  <li className="nav-item">
                    <a className="nav-link active text-white" href="#">
                      Console
                    </a>
                  </li>
                  <li className="nav-item disabled">
                    <a
                      className="nav-link disabled"
                      style={{ fontColor: "#C7D7F0" }}
                    >
                      Lorem ipsum dolor
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body text-white px-4">
                {rewardprogress.map((log, index) => (
                  <pre className="text-white" key={index}>
                    {log}
                  </pre>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RewardModelPPOProgress;
