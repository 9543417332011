import Nav from "react-bootstrap/Nav";
import "./CreateAgentChain.css";
import { Link } from "react-router-dom";
import { margin } from "@mui/system";
import { useContext } from "react";
import { AuthContext } from "../Auth/AuthContext";

function CreateAgentChainNav() {
  const {darkMode} = useContext(AuthContext)
  return (
    <div className="container-fluid pt-4 px-4 pb-0 ">
      <div className="row mx-0 pb-1">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-2" style={{color: darkMode?"white":""}}> Create Agent Chain </h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav variant="pills" defaultActiveKey="#" className="agentWizard">
            <Nav.Item className="">
              <Nav.Link
                as={Link}
                to="#"
                eventKey="#"
                className="linkPill mx-auto mb-1"
              >
                1
              </Nav.Link>
              <span className="pilltext"> Agent Chain Details</span>
            </Nav.Item>
            {/* <hr className="horizontal negMarg" /> */}
            <hr className="horizontal agentChainWiz agentChainHrWizNegMarg" style={{marginLeft: "-2.3rem", marginRight: "0.4rem"}}/>
            <Nav.Item className="lastPillNegMarg ml-30">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className="linkPill mx-auto mb-1"
              >
                2
              </Nav.Link>
              <span className="pilltext">Test Agent Chain </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default CreateAgentChainNav;
