// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivityList_cell-div__ZrCNO {
    min-width: 120px;
    padding: 5px;
    max-height: 120px;
}


.ActivityList_cell-div__ZrCNO::-webkit-scrollbar {
    width: 5px;
}

.ActivityList_cell-div__ZrCNO::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e72d;
    border: 1px solid #cacaca21;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.ActivityList_cell-div__ZrCNO::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #98c5e477;
}
`, "",{"version":3,"sources":["webpack://./src/components/AChain/ActivityList/ActivityList.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB;;;AAGA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,2BAA2B;IAC3B,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;AAC/B","sourcesContent":[".cell-div {\n    min-width: 120px;\n    padding: 5px;\n    max-height: 120px;\n}\n\n\n.cell-div::-webkit-scrollbar {\n    width: 5px;\n}\n\n.cell-div::-webkit-scrollbar-track {\n    border-radius: 8px;\n    background-color: #e7e7e72d;\n    border: 1px solid #cacaca21;\n    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);\n}\n\n.cell-div::-webkit-scrollbar-thumb {\n    border-radius: 8px;\n    background-color: #98c5e477;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell-div": `ActivityList_cell-div__ZrCNO`
};
export default ___CSS_LOADER_EXPORT___;
