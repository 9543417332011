import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Button, Row, Col, Card, Modal, Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import "../../AgentModule/CreateAgent/TestAgent.css";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
// import logoWhite from "../../assets/img/logoWhite.svg";
// import settings from "../../assets/img/settings.png";
import styles from "./playground2.module.css"
import { useEdgesState } from "reactflow";
import avatar from "../../../assets/img/avtar.png"
import { style } from "@mui/system";

function Playground2() {

    const [chainExecutionData, setChainExecutiondata] = useState({});
    const [response, setResponse] = useState("");
    const [feed, setFeed] = useState({});
    const [agentName, setAgentName] = useState();
    const [keys, setkeys] = useState([])
    let { id } = useParams();
    console.log(id);
    // const [agentData, setAgentData] = useState({});
    const [isTrace, setIsTrace] = useState(true);

    // useEffect(,[]);
    // const handlePrint = () => {
    //     console.log((chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed
    //     [18]));
    // }
    // for(let i=0; i< 1000000; i++);
    // handlePrint();
    const [runInput, setRunInput] = useState("");
    const [agent, setAgent] = useState(null);
    const [agentHistory, setAgentHistory] = useState(null);
    const [agentStatus, setagentStatus] = useState(false)  // false for running and true for completed
    const [keysIndex, setkeysIndex] = useState(0);
    const [agentNameApi, setAgentNameApi] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [nextAgent, setnextAgent] = useState(1)

    const [ws, setWs] = useState(null);
    const [data, setData] = useState(null);
    // const [pkValue, setPkValue] = useState('');

    const graph = (pkValue) => {
        const socket = new WebSocket('ws://74.235.164.4:8000/ws/graph/');
        setWs(socket);

        socket.onopen = () => {
            console.log('WebSocket connected');
            const message = {
                pk: pkValue,
                user_id: '123',
                date: 'your_date_value',
                method_action: 'start_sending_data',
            };
            socket.send(JSON.stringify(message));
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('Received message from server:', message);
            setData(message)
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        return () => {
            socket.close();
        };
    }
    const handleClick = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/agent-chain-executions/`);
            setChainExecutiondata(res.data)
            console.log(res.data)

            let arr = Object.keys(res.data.agent_chain_executions[0].agent_chain_execution_feed)
            console.log(arr);
            

            setkeys(arr)
            
            // console.log(typeof(res.data.agent_chain_executions[0].agent_chain_execution_feed[keys[0]]));
            setAgentName(arr[0]);
            setnextAgent(1)
            setagentStatus(res.data.agent_chain_executions[0].agent_chain_execution_feed["Status"].toLowerCase() == ("Completed").toLowerCase() ? true : false)

        } catch (err) {
            console.log("err: ", err)
        }
    }

    useEffect(() => {
        // handleClick()
        const interval = setInterval(handleClick, 3000);

        // setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[18].feed)

        return () => {
            clearInterval(interval); // Clear interval on unmount
        };

    }, []);



    useEffect(() => {
        if (Object.keys(chainExecutionData).length != 0) {
            const keys = Object.keys(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed)
            // setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[keys[keysIndex]]?.feed)
            // setResponse(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[keys[keysIndex]]?.response)

            console.log("obj: ", Object.keys(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed));
        }

    }, [chainExecutionData])
    const agentNames = () => {
        axios
        .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`)
            .then(response => {
                setAgentNameApi(response.data);
                console.log("Fetch agents success : ");
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }


    const handleResponse = (id) => {
        const keys = Object.keys(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed)
        setkeysIndex(keys.indexOf(id))
        console.log(keys);
        setResponse(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[id]?.response)
        setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[id]?.feed)
        console.log(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[id]?.feed)

    }

useEffect(() => {
    // handleClick()
    const interval = setInterval(handleClick, 3000);

    // setFeed(chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[18].feed)

    return () => {
        clearInterval(interval); // Clear interval on unmount
    };
}, []);

useEffect(() => {
    if (Object.keys(chainExecutionData).length != 0 && agentNameApi.length != 0) {
        const keys = Object.keys(
            chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed
        );
        setFeed(
            chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
                keys[keysIndex]
            ]?.feed
        );
        setResponse(
            chainExecutionData.agent_chain_executions[0].agent_chain_execution_feed[
                keys[keysIndex]
            ]?.response
        );

        console.log(
            "obj: ",
            Object.keys(
                chainExecutionData.agent_chain_executions[0]
                    .agent_chain_execution_feed
            )
        );
    }
}, [chainExecutionData]);



const handleNext = () => {
    console.log(keys);
    if (keys.length - 1 != nextAgent) {
        setAgentName(keys[nextAgent]);
        setnextAgent(nextAgent + 1);
    }
};

const handleRun = () => {
    const headers = { "Content-Type": "application/json" };
    const payload = {
        input_data: runInput,
    };
    const agentId = chainExecutionData.agent_chain_executions[0].agent_chain_id;
    console.log(agentId);
    axios
        .post(
            `${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/run/${agentId}?user_id=1006`,
            JSON.stringify(payload),
            { headers }
        )
        .then((response) => {
            console.log(response.data);
            graph(response.data.id);
            setInterval(agentNames(),500)
            
            if (ws) {
                const message = {
                    pk: response.data.id,
                    user_id: "123",
                    date: "your_date_value",
                };
                ws.send(JSON.stringify(message));
            }
            setShow(false);
        })
        .catch((error) => {
            console.log(error);
        });
};

const handleChange = (e) => {
    const value = e.target.value;
    setRunInput(value);
};

useEffect(() => {
    axios
        .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/18/`)
        .then((response) => {
            setAgent(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
}, []);

useEffect(() => {
    axios
        .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/get_history/18/`)
        .then((response) => {
            setAgentHistory(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
}, []);

const runHistory = [
    {
        name: "Run Name",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
    {
        name: "Run Name",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
    {
        name: "Run Name",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    },
];

const details = "An agent chain is a type of chain that involves an agent who can decide which tools to use based on the user input. An agent has access to a suite of tools, such as language models, parsers, translators, etc. Depending on the user input, the agent can then choose which, if any, of these tools to call."

return (
    <>
        <Container className={styles.playground+" container-fluid"}>
            <div className="">
                <nav className="navbar navbar-expand-lg">
                    <div className={"container-fluid "+styles.screenTitleNButton}>
                        <span className={"navbar-brand "+styles.screenTitle}>Test Competency</span>
                        <div class="line me-3"></div>
                        <span className={"navbar-brand "+styles.screenTitle}>Competency Name</span>
                        <div className={styles.i}>i</div>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav ms-auto">
                                <Link class="btn btn-primary cancelBtn me-3" role="button" to={'/competency'}>
                                    Cancel
                                </Link>
                                <a
                                    class="btn btn-primary submitBtn me-3"
                                    href="#"
                                    role="button"
                                >
                                    Save
                                </a>
                                <div class="line me-3"></div>
                                <Link
                                    class="btn btn-primary cancelBtn me-3"
                                    to={'../'}
                                    role="button"
                                >
                                    Back
                                </Link>
                                <a class="btn btn-primary submitBtn" href="#" role="button">
                                    Create
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <Row className="px-3">
                <Col xs={3} className="col">
                    <Container className={"container-fluid p-4 agent-playground "+ styles.ofu}>
                        <Row>
                            <Col>
                                <h6 className="pt-2">Run History</h6>
                            </Col>
                            <Col>
                                <Button
                    variant="primary"
                    className="run-btn"
                    onClick={handleShow}
                  >
                    New Run
                  </Button>
                            </Col>
                        </Row>
                        <div className={styles.runHistoryContainer + " " + styles.ofa}>

                        {agentHistory &&
                            agentHistory.slice(0, 10).map((rh) => {
                                return (
                                    <>
                                        <Card className={"mt-3 run-history-col "+ styles.runHistory}>
                                            <Card.Body>
                                                <h6>{rh.name}</h6>
                                                <p>{rh.status}</p>
                                                <Row>
                                                    <Col>{rh.num_of_calls} CALLS</Col>
                                                    {rh.time_difference.hours == 0 && (
                                                        <Col>{rh.time_difference.minutes} MINS AGO</Col>
                                                    )}
                                                    {rh.time_difference.hours > 0 && (
                                                        <Col>{rh.time_difference.hours} HRS AGO</Col>
                                                    )}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </>
                                );
                            })}
                        </div>
                    </Container>
                </Col>

                <Col xs={6} className="run-name col pr-0">
                    <Container className={"container-fluid p-4 agent-playground "+ styles.test_playground+ " " + styles.ofu}>
                        {/* {data && data.value.feeds.map((feed, index) => {return (
              <Card className="p-0 mb-1">
                <Card.Body>
                  <div key={index} className={`feed feed-${feed.role}`}>
                  <pre>6</pre>
                  <p className="run-name-time">Updated at: {new Date(feed.updated_at).toLocaleString()}</p>
                  </div>
                </Card.Body>
              </Card>
              )})} */}
                    <h2 className={styles.output}>Output</h2>
                    <div class={styles.ofa}>

                        <div class={"accordion "} id="accordionExample">
                            <div class="accordion-item">
                                {response?.length > 0 && (
                                    <h2 class="accordion-header" id="headingOne">
                                        <button
                                            class="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            {response?.length > 0 && String(response)}
                                        </button>
                                    </h2>
                                )}
                                {feed?.length > 0 &&
                                    feed.map((field) => {
                                        console.log(field)
                                        return (
                                            <div
                                                id="collapseOne"
                                                class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div class="accordion-body">{String(field.feed)}</div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    </Container>

                    
                </Col>

                <Col xs={3} className="details col pl-0">
                    <Container className={"container-fluid pt-4 agent-playground "+ styles.test_trace_details + " " + styles.ofu}>
                        {/* <Card className="px-0 mx-0 details-desc" style={{ background: "#f4f4f4", cursor: "pointer" }}>
                                <Card.Body className="px-0" onClick={() => { handleResponse(); handleNext() }}>

                                    <h6 className="text-center">{String(agentName)}</h6>
                                    <h6 className="text-center">{response}</h6>
                                    
                                    </Card.Body>
                                </Card> */}
                        

                    
                        <Row className={"px-3 py-0"+styles.tr}>
                            <Col
                            // className={isTrace?styles.td+" px-1 "+styles.act:styles.td}
                            className="d-flex align-items-center justify-content-center p-0"
                            onClick={() => setIsTrace(true)}
                            >
                                <Row
                                className={isTrace?styles.td+" px-1 "+styles.act:styles.td}>
                                    Agent Chain Trace
                                </Row>
                            </Col>
                            <Col 
                            // className={isTrace?styles.td:styles.td+" "+styles.act}
                            className="d-flex align-items-center justify-content-center p-0"
                            onClick={() => setIsTrace(false)}
                            >
                                <Row
                                    className={isTrace?styles.td:styles.td+" "+styles.act}
                                >

                                Details
                                </Row>
                            </Col>
                        </Row>
                        <Row className={styles.chains}>
                        {isTrace?
                        agentNameApi.length != 0 &&
                        keys.map((current,index)=>{
                            return index !== keys.length - 1 ? <>
                                 <Card className="px-0 mx-0 details-desc mb-3" style={{ background: "white", cursor: "pointer", height: "fit-content", width: "90%"}}>
                                    <Card.Body className="px-0" onClick={() => { handleResponse(current); handleNext() }}>
                                        {agentNameApi.map((agents)=>{
                                            
                                            return agents.agent.id === parseInt(current) ?
                                            <>
                                        
                                            <Row className={"w-100 "+ styles.nameRow}>
                                            <Col className={styles.avatarButton}>

                                            <img src={avatar} alt="avatar" style={{aspectRatio: "1/1", width: "90%" }}></img>
                                            </Col>
                                            <Col className={styles.name}>
                                            {/* <Row> */}

                                <h6 className="text-center p-2 ">{String(agents.agent.agent_name)}</h6>
                                            {/* </Row> */}
                                            </Col >
                                            <Col className={"d-flex align-items-end justify-content-center "+ styles.iButton}>
                                                <div className={styles.i}>i</div>
                                            </Col>
                                            </Row>
                                </>
                                            
                                            :""
                                                                                    })}
    
                                        {/* <h6 className="text-center">{agent.agent.agent_name}</h6> */}
                                        {/* <h6 className="text-center">{response}</h6> */}
    
                                    </Card.Body>
                                </Card> </> :""
    
      
                
                        }):
                        <Card style={{height: "fit-content"}} className="p-3">

                            {details}
                        </Card>
                        }
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className="d-flex p-3">
                        {/* <Col xs={8} className="ps-0 input-form"> */}
                    <Col xs={3} className="col"></Col>
                    <Col className={"pt-0 "+ styles.promptBox}>
                    <InputGroup className="mt-3">
                        <Form.Control
                            className={styles.promptInput}
                            style={{
                                border: "none",
                                marginRight: "20px",
                            }}
                            placeholder="Enter your prompt and press Enter"
                            value={runInput}
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            className="proceedButton p-0"
                            id="button-addon2"
                            onClick={handleRun}
                        >
                            Enter
                        </Button>
                    </InputGroup>
                    </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Run Agent Chain</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="input">
                            <Form.Label>Input</Form.Label>
                            <Form.Control type="input" value={runInput} onChange={handleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="cancelBtn" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="submitBtn" onClick={handleRun}>
                        Run
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <p className="copyright mt-5">© TCS 2024</p>
        </Container >
    </>
);

                    }
export default Playground2;
