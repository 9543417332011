import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../AgentModule/NavBar";
import Button from "react-bootstrap/Button";
import dashboardImg from "../../assets/img/dashboardImg.png";
import ChatPopup from "../AChain/ChatPopup/ChatPopup";

export default function LandingPage() {
  return (
    <>
      <div className="home-image">
        <div className="home-robo-image">
          <NavBar />
          <div className="row g-0 ">
            <div className="col-12 col-md-7">
              <div className="d-flex align-items-center px-6 h-100">
                <div className="col-10 col-xl-12 py-3 ps-0">
                  <div className="model-text-div">
                    <h2 className="h4 mb2 home-title">An AI Driven Platform</h2>
                    <p className="home-text mb-4">
                      We make digital products that drive you to stand out. We
                      write words, take photos, make videos, and interact with
                      artificial intelligence. We make digital products that
                      drive you to stand out.
                    </p>
                    <div className="model-buttons">
                      <Link to="/modelOrchestrator">
                        <Button className="me-1 homeBtn" variant="primary">
                          Model Details{" "}
                        </Button>
                      </Link>
                      {/* <Link to="/comparemodels"> */}
                      <Button className="homeBtn" variant="secondary" disabled>
                        Model selection
                      </Button>
                      {/* </Link> */}
                      {/* <Link to="/finetuning"> */}
                      <Button className="homeBtn" variant="success" disabled>
                        Supervised Fine-tuning
                      </Button>
                      {/* </Link> */}
                      {/* <Link to="/rewardmodel"> */}
                      <Button className="homeBtn" variant="success" disabled>
                        Reward PPO Training
                      </Button>
                      {/* </Link> */}
                      {/* <Link to="/evalutemodel"> */}
                      <Button className=" homeBtn" variant="success" disabled>
                        Preview Model
                      </Button>
                      {/* </Link> */}
                    </div>
                  </div>
                  {/* <hr className="border-primary-subtle mb-4"> </hr> */}
                  {/* <h2 className="h1 mb-4">
                      We make digital products that drive you to stand out.
                    </h2>
                    <p className="lead m-0">
                      We write words, take photos, make videos, and interact
                      with artificial intelligence.
                    </p> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-md-5">
            <div className="card-body p-3 p-md-4 px-xl-5 pt-xl-3 ">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <img
                      className="img-fluid rounded mb-4"
                      loading="lazy"
                      src={dashboardImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
