import axios from 'axios';
import React, { useState, useRef, useContext, useEffect } from 'react';
import WavEncoder from 'wav-encoder';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './AudioRec.module.css'
import { DockContext } from '../DockWrapper/DockContext';

function AudioRecorder({ voiceToText, setResponse = () => { } }) {
    const [isRecording, setIsRecording] = useState(false);
    // const [audioBlob, setAudioBlob] = useState(null);
    const [isRecordModal, setIsRecordModal] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);

    const startRecording = async () => {
        setIsLoader(false)
        try {
            console.log("recording started")
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };



            mediaRecorderRef.current.start();
            setIsRecording(true);
            console.log("started")
        } catch (error) {
            setIsRecording(false)
            console.error('Error accessing microphone:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            console.log("recording stopped")
            setIsLoader(true)
            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
                // setAudioBlob(blob);
                chunksRef.current = [];

                // Move sendAudioToServer call here  
                sendAudioToServer(blob);
            };

            mediaRecorderRef.current.stop();
        }
    };



    const sendAudioToServer = async (audioBlob) => {
        console.log(audioBlob, "audio blob")
        if (audioBlob) {
            // Upload the WAV file to server or handle it as needed
            setIsLoader(true)
            console.log(true, "changed loader state")
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.wav');
            formData.append('temperature', '0.0');
            formData.append('response_format', 'json');
            formData.append('temperature_inc', '0.2');

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": `multipart/form-data`,
                },
            };
            console.log(formData, "payload")

            // Example: sending the file to the server
            try {
                const response = await axios.post(`${process.env.REACT_APP_AUDIO_SERVER}/inference`, formData, config);

                if (response.status == 200) {
                    console.log('Audio file uploaded successfully');
                    console.log(response.data.text)

                    setResponse(response.data.text.replaceAll("\n", " "))
                } else {
                    console.error('Failed to upload audio file');
                }
                setIsLoader(false)
                setIsRecording(false)
                setIsRecordModal(false)
            } catch (error) {
                setIsLoader(false)
                setIsRecording(false)
                alert("couldn't process, please try again")
                console.log('Error uploading audio file', error);
            }
        }
    };

    const modalConfigurations = {
        show: isRecordModal,
        onHide: () => { isRecording ? alert("recording is on") : setIsRecordModal(false) },
        key: true,

    }

    return (
        <>
            <MyVerticallyCenteredModal configs={modalConfigurations} isRecording={isRecording} setIsRecording={setIsRecording} stopRecording={stopRecording} startRecording={startRecording} isLoader={isLoader} />

            <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#000', fontSize: '160%', marginLeft: '7px', marginTop: "11px" }} onClick={() => setIsRecordModal(!isRecordModal)} >
                {
                    !isRecording ?
                        <i className="fa-solid fa-microphone"></i>
                        :
                        <i class="fa-solid fa-microphone-slash"></i>

                }
            </label>
        </>
    );
}

export default AudioRecorder;





// modal for recording


function MyVerticallyCenteredModal({ configs = {}, setIsRecording, isRecording, stopRecording, startRecording, isLoader }) {
    const { darkMode } = useContext(DockContext)
    useEffect(() => {
        console.log(isLoader, "loader state")
    }, [isLoader])
    return (
        <Modal
            {...configs}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                zIndex: 9999999999999999
            }}
            className={"alchemModalDark"}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Recorder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className='d-flex flex-column align-items-center justify-content-center w-100 gap-2'>
                    <button className={`${styles["micIcon"]} rounded-circle py-4 px-5`} onClick={() => isRecording ? stopRecording() : startRecording()}>
                        {
                            isLoader ?
                                <div className={styles["three-body"]}>
                                    <div className={styles["three-body__dot"]}></div>
                                    <div className={styles["three-body__dot"]}></div>
                                    <div className={styles["three-body__dot"]}></div>
                                </div>
                                :
                                !isRecording ?
                                    <i className="fa-solid fa-microphone"></i>
                                    :
                                    <i class="fa-solid fa-microphone-slash"></i>
                        }
                    </button>
                    {isLoader && <p className='text-light fs-6 fw-light m-auto'>Procressing Voice, please wait....</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { configs.onHide(); stopRecording() }} disabled={isRecording | isLoader}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

