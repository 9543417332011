import React from "react";  
import ProgressBarBlue from '../ProgressBarBlue/ProgressBarBlue';
import ProgressBarGrey from '../ProgressBarGrey/ProgressBarGrey';
import './HorizontalBars.css'


const HorizontalBars = ({data, darkMode}) => {
    console.log(data);
    return(
        <div className="horizontal-bars" style={{background: darkMode ? '#212542' : '', border: 'none'}}>
            <div className="Bar">
                
                <ProgressBarGrey value={data.data1}></ProgressBarGrey>
                

                    <p style={{color: darkMode ? '#BAC9F1' : ''}}>30</p>
                
            </div>
            <div className="Bar">
                <ProgressBarBlue value={data.data2}></ProgressBarBlue>
                <p style={{color: darkMode ? '#BAC9F1' : ''}}>60</p>
            </div>
            <p style={{color: darkMode ? '#BAC9F1' : ''}}>{data.data3}</p>
        </div>
    )
}

export default HorizontalBars;