
import React, { useEffect, useRef, useContext, useState, useMemo } from "react";
import axios from "axios";
import sendchat from "../../../../assets/img/sendchat.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Auth/AuthContext";
import { DockContext } from "../../../DockWrapper/DockContext";
import AudioRecorder from "../../../AudioRec/AudioRec";
import PinkBtnGif from '../../../../../src/assets/img/alchemist-ai-1.gif'
import userBubble from '../../../../../src/assets/img/user_bubble.svg'
import botBubble from '../../../../../src/assets/img/bot_bubble.svg'

export default function NormalChat({ context, UpdateActivityDetailsBtnRef, data, setDisableUpdateDataBtn, setIsLoadingForUpdateDataBtn, setData }) {

    const [sendDisabled, setSendDisabled] = React.useState(false)
    const [history, setHistory] = React.useState([{ role: 'assistant', content: 'Welcome...!!!, Here you can edit/delete the agent details also you can test the agent by starting a conversation with it. For testing a agent click TEST AGENT button at bottom of Agent Details panel. For updating any of the information use the AGENT DETAILS panel. you can also ask suggestion or any queries about the agent to me.' }])
    const {
        selectedKnowledgeWork,
        activity_master_json,
        setActivity_master_json
    } = useContext(DockContext);

    const [message, setMessage] = useState("")
    const [activityDetails, setActivityDetails] = useState({})
    const navigate = useNavigate()

    const populateDetails = () => {
        console.log(data)
        data?.map((item) => {

            if (item.isActive) {
                activity_master_json?.activities.map((activity) => {
                    if (item.name == activity.name) {
                        console.log(activity)
                        setActivityDetails(activity)
                        console.log(activity)
                    }

                })
            }
        })
    }

    useEffect(() => {
        populateDetails()
    }, [])

    useEffect(() => {
        /* 
         check for knowledgework details if not for getting subID redirecting to activityList
         so that no state will be lost on reload
        */
        console.log(selectedKnowledgeWork)
        if (!selectedKnowledgeWork) {
            navigate("/activityList")
        }
    }, [selectedKnowledgeWork])

    const updateActivityAfterTaskData = (arrayOfUpdatedTasks) => {
        const activityPayload = { ...updatedActivityDeatilsFromBot.current, tasks: arrayOfUpdatedTasks }
        console.log(activityPayload)


        axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/activities/${updatedActivityDeatilsFromBot.current.id}`, activityPayload)
            .then((res) => {
                console.log(res.data)
                axios
                    .get(
                        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${selectedKnowledgeWork.id}`
                    )
                    .then((res) => {
                        if (res.status == 200) {
                            console.log(res.data)
                            if (res.data.is_master_json) {
                                setActivity_master_json(res.data)
                                setDisableUpdateDataBtn(true)
                            }
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const populateTask = async (task) => {

        try {
            console.log(activityDetails.tasks, activityDetails)
            const isTaskAlreadyPresent = activityDetails?.tasks?.find((oldTask) => oldTask.id === task.id)
            if (isTaskAlreadyPresent) {
                const updatedOldTaskPayload = {
                    "description": task.description,
                    "expected_output": task.expected_output
                }

                const response = await axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/tasks/${task.id}`, updatedOldTaskPayload)
                return response.data

            }

            const newTaskPayload = {
                "description": task.description,
                "expected_output": task.expected_output
            }
            const response = await axios.post(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/tasks/`, newTaskPayload)
            return response.data

        } catch (error) {
            console.log(error)
        }


    }

    const updateActivityData = async () => {

        const arrayOfUpdatedTasks = []

        await Promise.all(updatedActivityDeatilsFromBot.current?.tasks?.map(async (task) => {
            const updatedTask = await populateTask(task)
            console.log(updatedTask)
            arrayOfUpdatedTasks.push(updatedTask)
            console.log(arrayOfUpdatedTasks)

        }))
            .then(() => {
                console.log(arrayOfUpdatedTasks)
                updateActivityAfterTaskData(arrayOfUpdatedTasks)
            })
            .catch((error) => {
                console.log(error)
            })


    }

    useEffect(() => {
        UpdateActivityDetailsBtnRef.current = updateActivityData
    }, [UpdateActivityDetailsBtnRef.current])


    const handleSend = (start) => {
        console.log(start, "handle send");
        setSendDisabled(true)
        setHistory([...history, { role: 'user', content: message + start }, { role: 'assistant', content: 'Thinking...' }])
        console.log(history.slice(0, -1))

        axios.post(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/random_input`, {

            "context": context,
            "stage": "mother_prompt",
            "conversation": [...history, { role: 'user', content: message + start }]
        })
            .then(responses => {
                console.log(responses.data)
                console.log(history)
                setHistory(responses.data.data)
                setSendDisabled(false)
            })
            .catch(error => {
                console.log(error)
                setSendDisabled(false);
                setHistory([...history, { role: 'user', content: message }, { role: 'assistant', content: 'Failed to response please try again' }])
            })
        setMessage("")
    };

    const handleChange = (e) => {
        setMessage(e.target.value)
    }

    const chatContainerRef = useRef(null);
    const handleChatScroll = () => {
        chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight + 100,
            behavior: 'smooth',
        });
    };


    useEffect(() => {
        handleChatScroll();
    }, [history]);


    const [voiceToText, setVoiceToText] = useState("")
    const SignalRegexPattern = /^\W{3,}(UPDATE_JSON_SIGNAL)\W{3,}/g
    const updatedActivityDeatilsFromBot = useRef(activityDetails)
    const getJsonDataFromBot = (message) => {
        axios.post(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${selectedKnowledgeWork.id}/random_input`, {

            "context": context,
            "stage": "mother_prompt",
            "conversation": [...history, { role: 'user', content: message }]
        })
            .then(response => {
                console.log(response.data.data, response.data.data[response.data.data.length - 1]["content"])
                let updatedData = JSON.parse(response.data.data[response.data.data.length - 1]["content"])["updated_json"]
                console.log(updatedData)
                updatedActivityDeatilsFromBot.current = updatedData
                console.log(updatedActivityDeatilsFromBot.current)
                setHistory([...history, { role: "assistant", content: "Your Informations has been updated successfully. Let me know if you need anything else." }])
                setSendDisabled(false)
                setDisableUpdateDataBtn(false)
                setIsLoadingForUpdateDataBtn(false)
            })
            .catch(error => {
                console.log(error)
                setSendDisabled(false)
                setDisableUpdateDataBtn(false)
                setIsLoadingForUpdateDataBtn(false)
            })
    }

    const populateConversation = (message, index) => {
        console.log(message)
        if (SignalRegexPattern.test(message.content)) {
            if (history[history.length - 1]["content"] !== message.content) {
                return (

                    <div
                        key={index}
                        className={
                            "api-response flex-column gap-2"
                        }
                    >

                        <div className="d-inline-flex align-self-start">
                            <i className={"fas fa-robot text-primary fs-6"}></i>
                        </div>
                        <div className={`chat-bubble align-items-start ml-2`}>

                            <pre className="m-0 text-dark" > Please click on the update button at the bottom corner to update the data.</pre>

                        </div>

                    </div>
                )
            }
            console.log("regex captured")
            setDisableUpdateDataBtn(true)
            getJsonDataFromBot("Give me the UPDATED JSON")
            setIsLoadingForUpdateDataBtn(true)

            return (
                <div
                    key={index}
                    className={
                        "api-response flex-column gap-2"
                    }
                >

                    <div className="d-inline-flex align-self-start">
                        <i className={"fas fa-robot text-primary fs-6"}></i>
                    </div>
                    <div className={`chat-bubble align-items-start ml-2`}>

                        <pre className="m-0 text-dark" > Please click on the update button at the bottom corner to update the data.</pre>

                    </div>

                </div>
            )
        }


        return <div
            key={index}
            className={
                message.role === "user" ? "user-message-chatLLM flex-column-reverse gap-2" : "api-response flex-column gap-2"
            }
        >
            {message.role === "user" ? "" :
                <img src={botBubble} className="align-self-start"></img>}
            <div className={`chat-bubble align-items-start ${message.role === "user" ? "mr-2" : "ml-2"}`}>

                <pre className="m-0 text-dark" > {`${message.content}`}</pre>

            </div>
            {message.role !== "user" ? "" :
                <img src={userBubble} className="align-self-end"></img>}

        </div>

    }


    const historyCache = useMemo(() => history.map((message, index) => {

        return populateConversation(message, `${message.role}_${index}`)

    }), [history,])




    return (
        <>
            <div className="chat-content disable-scrollbars d-flex flex-column" ref={chatContainerRef} style={{ overflowY: 'scroll' }}>
                {


                    <>


                        {
                            historyCache
                        }
                    </>
                }
            </div >



            {/*---------- Footer --------------------*/}
            {

                <div className="h-25 w-100 chat-achain-dock" style={{ maxHeight: "25%" }}>
                    <div className="chat-input" style={{ maxHeight: "70%" }}>

                        <div className="chat-input-container position-relative" style={{ display: 'flex', flexDirection: 'row', alignItems: "center", backgroundColor: "#fff" }}>

                            <img src={PinkBtnGif} className="position-absolute" style={{ height: '77.01px', width: '77.01px', overflow: 'visible', left: 'calc(50% - 38px)', top: '-80%' }}></img>
                            <textarea
                                rows="1"
                                id="chatLLMInput"
                                type="text"
                                placeholder="Type your message..."
                                className="chat-input-box mr-4"
                                value={message}
                                onChange={e => handleChange(e)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSend("")
                                    }
                                }}
                                disabled={sendDisabled} style={{ overflowY: 'scroll', width: "85%", border: "none", background: "transparent", backgroundColor: "none", boxShadow: "none", maxHeight: '60px', height: "50px", padding: "1%", borderRadius: "6px" }} />


                            <div className=" chatbot-text-overlay-pos ">

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: "10px" }} >
                                    <AudioRecorder setResponse={setMessage} voiceToText={voiceToText} />
                                </div>

                                {!sendDisabled ?
                                    <img className="fa fa-paper-plane chat-send-icon w-50" aria-hidden="true" alt='send' src={sendchat} onClick={() => { handleSend("") }}></img>
                                    :
                                    <img className="fa fa-paper-plane chat-send-icon" aria-hidden="true" src={sendchat} alt='test'></img>
                                }

                            </div>


                        </div>
                    </div>
                </div>



            }

            {/*---------- Footer --------------------*/}
        </>
    )
}








