// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-content-activity {
    background-color: #ffffff;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: visible;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.a{
    background-color: #585757;
}

.chat-content-activity::-webkit-scrollbar {
    display: none;
    visibility: none;
    opacity: none;
}

.chat-content-activity::-webkit-scrollbar-thumb {
    display: none;
    visibility: none;
    opacity: none;
}

.chat-content-activity::-webkit-scrollbar-thumb:hover {
      display: none;
      visibility: none;
      opacity: none;
    }
    
    .chat-content-activity::-webkit-scrollbar-track {
      display: none;
      visibility: none;
      opacity: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AChain/popup/ActivityPopup.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,wCAAwC;IACxC,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;AACjB;;AAEA;MACM,aAAa;MACb,gBAAgB;MAChB,aAAa;IACf;;IAEA;MACE,aAAa;MACb,gBAAgB;MAChB,aAAa;EACjB","sourcesContent":[".chat-content-activity {\n    background-color: #ffffff;\n    border-radius: 4px 4px 0px 0px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    overflow: visible;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n.a{\n    background-color: #585757;\n}\n\n.chat-content-activity::-webkit-scrollbar {\n    display: none;\n    visibility: none;\n    opacity: none;\n}\n\n.chat-content-activity::-webkit-scrollbar-thumb {\n    display: none;\n    visibility: none;\n    opacity: none;\n}\n\n.chat-content-activity::-webkit-scrollbar-thumb:hover {\n      display: none;\n      visibility: none;\n      opacity: none;\n    }\n    \n    .chat-content-activity::-webkit-scrollbar-track {\n      display: none;\n      visibility: none;\n      opacity: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
