import React, { useContext, useEffect, useState } from 'react'
import styles from "./SmeSummary.module.css"
import PropTypes from 'prop-types'
import NavBar from '../navBar/NavBar'
import { Nav } from 'react-bootstrap'
import { DockContext } from '../../DockWrapper/DockContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import "./sidebarModal.css"
import SmeChainModelView from './SmeChainModelView'
import dottedPatternBG from '../../../assets/img/doted-patternbg.png'
import pencilIcon from '../../../assets/img/pencil_icon.svg'
import HPAccordion from './HPAccordion'
import CustomNavbar from '../../CustomNavbar/CustomNavbar'



export default function SmeSummary() {

    const [view, setView] = useState('models')
    const [showHP, setShowHP] = useState(false)

    const {
        activity_master_json,
        setActivity_master_json,
        selectedKnowledgeWork
    } = useContext(DockContext)

    const navigate = useNavigate()
    const [paramsData, setParamsData] = useState([])

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${selectedKnowledgeWork.id}`
            )
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data)
                    if (res.data.is_master_json) {
                        setActivity_master_json(res.data)

                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (!selectedKnowledgeWork) {
            navigate("/activityList")
        }
    }, [selectedKnowledgeWork])


    const HandleParamsPopulation = (data) => {
        console.log(data)
        if (data) {
            console.log(data)
            setParamsData(data)
        } else {
            setParamsData([])
        }
        setShowHP(true)
    }


    const handleRedirectToAgentEdit = () => {

        navigate("/smeAgentCreation")
    }

    const handleRedirectToModelSelection = () => {
        navigate("/agentMapping")
    }

    const populateTask = async (item) => {
        console.log(item)
        let output = {}

        try {
            const res = await axios.post(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/tasks/`,
                {
                    "description": item.description,
                    "expected_output": item.expected_output
                })
            console.log(res.data)
            output = res.data

        } catch (error) {
            console.log(error)
        }


        console.log(output)
        return output

    }


    const populateActivity = async (activity, tasksFromDB) => {

        console.log(activity, tasksFromDB)
        let output = {}
        try {
            const res = await axios.post(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/activities/`,
                {
                    "name": activity.name,
                    "description": activity.description,
                    "role": activity.role,
                    "goal": activity.goal,
                    "human_input_required": activity["human_input_required"],
                    "status": activity.status,
                    "finetuned_model_type": activity["finetuned_model_type"],
                    "predictive_model_id": 0,
                    "generative_model_id": 0,
                    "kpi": activity.kpi,
                    "tasks": tasksFromDB
                })

            console.log(res.data)
            output = res.data
        } catch (error) {
            console.log(error)
        }

        console.log(output)
        return output
    }


    const populateKnowledgeWork = async (item) => {
        console.log(item)
        let output = false
        try {

            const updatedActivities = activity_master_json.activities.concat(item)
            console.log(updatedActivities)

            const res = await axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${selectedKnowledgeWork.id}`,
                {
                    "activities": updatedActivities
                })
            console.log(res.status)
            if (res.status == 200) {
                output = true
                setActivity_master_json(res.data)
            } else {

                output = false
            }

        } catch (error) {
            console.log(error)
        }


        console.log(output)
        return output
    }


    const handlePopulateDB = async (activity) => {
        try {
            const activitiesFromDB = []
            // Loop through each activity  
            const tasksFromDB = []
            // Populate tasks and wait for all tasks to be populated before continuing  
            await Promise.all(activity.tasks.map(async (item) => {
                const task = await populateTask(item)
                tasksFromDB.push(task)
            })).then(async () => {
                console.log(tasksFromDB)

                // Populate activity with populated tasks  
                let updatedActivity = await populateActivity(activity, tasksFromDB)
                activitiesFromDB.push(updatedActivity)
            })

            // Populate knowledge work with populated activities
            console.log(activitiesFromDB)
            if (activitiesFromDB.length > 0) {
                const response = await populateKnowledgeWork(activitiesFromDB)
                return { status: response, message: response ? "successfully populated" : "Something went wrong" }
            }
        } catch (error) {
            return { status: false, message: `${error}` }
        }
    }

    const [agentCreationLoading, setAgentCreationLoading] = useState(false)

    const HandleAgentCreation = async (activity) => {

        console.log("agent creation started ")
        console.log(activity)

        setAgentCreationLoading(true)

        const isAgentCreated = await handlePopulateDB(activity)

        console.log(isAgentCreated)


        if (isAgentCreated.status === true) {
            setAgentCreationLoading(false)
        }

        if (isAgentCreated.status === false) {
            alert(`creation failed please retry, error:${isAgentCreated.messgae}`)
        }

    }

    const links = [
        { name: "Knowledge Work", route: "/activityList", isActive: false }
    ];


    const cellDetails = (props) => {

        console.log(props)
        return (
            <div className={`p-1 h-100 d-flex justify-content-start flex-row  align-items-start ms-2`} style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 0px 30px #647B9229', borderRadius: '6px' }}>
                <div className='flex flex-row'>
                    <div className='mt-2 ms-2 rounded-circle text-center' style={{ background: '#83A0F7 0% 0% no-repeat padding-box', width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{props?.idx}</div>
                </div>
                <div className='d-flex flex-column w-100'>
                    <div className='w-100 d-flex flex-row pt-2 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                        {props?.name}
                    </div>
                    <div className='w-100 d-flex flex-row ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                        Intelligence:&nbsp;<span style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>{props?.finetuned_model_type}</span>
                    </div>
                    <div className='w-100 d-flex flex-row ps-4 pe-4' style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>
                        {props?.description}
                    </div>
                </div>
            </div>
        )


    }

    const modelDetails = (item) => {
        const originalItem = item
        console.log(item)
        const activity = item
        const name = activity.finetuned_model_type + "_model"
        console.log(name)
        console.log(item[name], activity[name])
        item = activity[name]
        if (item && item.name !== '') {

            return (
                <div className={`p-1 d-flex h-100 justify-content-start flex-row  align-items-start ms-2`} style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 0px 30px #647B9229', borderRadius: '6px' }}>
                    <div className='flex flex-row'>
                        <div className='mt-2 ms-2 rounded-circle text-center' style={{ background: '#83A0F7 0% 0% no-repeat padding-box', width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{originalItem?.idx}</div>
                    </div>
                    <div className='d-flex flex-column w-100'>
                        <div className='w-100 d-flex flex-row pt-2 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                            {item?.base_model}
                        </div>
                        <div className='w-100 d-flex flex-row ps-4 pe-4 pb-4' style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>
                            {item?.name}
                        </div>
                    </div>
                    <div className='d-flex flex-column' type="button" onClick={() => HandleParamsPopulation(item?.hyper_parameters)}>
                        <div className='pe-2 pt-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#012060' }}>Hyperparameters</div>
                    </div>
                </div>
            )
        }

        return <div className='d-flex justify-content-center aligin'>
            No Recommended Model
        </div>

    }

    const agentDetails = (item, index, activityData) => {
        try {
            const data = item.find((activity) => activity.name === activityData.name)
            if (data) {
                return (
                    <div className={`p-1 h-100 d-flex justify-content-start flex-row  align-items-start`} style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 0px 30px #647B9229', borderRadius: '6px' }}>
                        <div className='flex flex-row'>
                            <div className='mt-2 ms-2 rounded-circle text-center' style={{ background: '#83A0F7 0% 0% no-repeat padding-box', width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{index + 1}</div>
                        </div>
                        <div className='d-flex flex-column w-100'>
                            <div className='w-100 d-flex flex-row pt-2 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                                {data?.name}
                            </div>
                            {/* <div className='w-100 d-flex flex-row ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                                    Intelligence:&nbsp;<span style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>{data?.finetuned_model_type}</span>
                                </div> */}
                            <div className='w-100 d-flex flex-row ps-4 pe-4 pb-4' style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>
                                {data?.description}
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='d-flex flex-row pr-2' onClick={handleRedirectToAgentEdit} type="button">
                                <div className='pe-1' style={{
                                    textDecoration: 'underline',
                                    font: 'normal normal normal 14px/17px Calibri',
                                    letterSpacing: '0px',
                                    color: '#003BEB'
                                }}>Test</div>
                                <img src={pencilIcon}></img>
                            </div>
                        </div>
                    </div>
                )
            }

        }
        catch (error) {
            console.log(error)
        }


        const isHasObject = item[index - 1] ? Object.keys(item[index - 1]).length : 0
        console.log('ZABC', activityData)
        const recommendedModelsData = activityData.finetuned_model_type ? activityData[activityData.finetuned_model_type + "_model"] : ""
        if ((index == 0 || isHasObject > 0) && activityData.finetuned_model_type !== "") {
            if (recommendedModelsData) {
                console.log(recommendedModelsData)
                if (recommendedModelsData?.base_model || recommendedModelsData?.base_model) {
                    console.log(recommendedModelsData?.base_model, recommendedModelsData)

                    return (
                        <div className='d-flex justify-content-end align-items-center h-100'>
                            {
                                agentCreationLoading ?
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    :
                                    <button className='btn' onClick={() => { HandleAgentCreation(activityData) }} style={{ background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF' }}>
                                        Create Agent
                                    </button>
                            }

                        </div>
                    )
                }
            }




        }

        return ""
    }


    const populateTable = () => {
        console.log(activity_master_json?.master_json?.knowledge_work?.activities)

        if (!activity_master_json || activity_master_json?.master_json?.knowledge_work?.activities?.length < 1 || !activity_master_json?.master_json?.knowledge_work?.activities) {
            console.log("there is a spinner")
            return (
                <div className='d-flex w-100 justify-content-center align-items-center p-4 m-4'>
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
        } else {
            return activity_master_json?.master_json?.knowledge_work?.activities.map((data, i) => {
                console.log(data)
                console.log(data[`${data["finetuned_model_type"]}"_model"`])
                data['idx'] = i + 1

                return <div key={i} className='d-flex flex-row justify-content-between px-2 py-2'>
                    <div className='h-100' style={{ width: "20%", minWidth: `${showHP ? '50%' : '33.33%'}`, maxWidth: `${showHP ? '50%' : '33.33%'}`, background: 'none' }} >
                        {cellDetails(data)}
                    </div>
                    <div className='h-100' style={{ width: "20%", minWidth: `${showHP ? '50%' : '33.33%'}`, maxWidth: `${showHP ? '50%' : '33.33%'}`, background: 'none' }} >

                        {/* model population logic changed */}
                        {
                            modelDetails(data)
                        }

                    </div>
                    {
                        !showHP ?
                            <div className='h-100' style={{ width: "20%", minWidth: "30%", maxWidth: "33.3%", background: 'none' }} >
                                {agentDetails(activity_master_json?.activities, i, data)}
                            </div> : <></>
                    }
                </div>

            })

        }



    }

    useEffect(() => {
        console.log(selectedKnowledgeWork)
    }, [selectedKnowledgeWork])

    let subtitle = `Knowledge Work: ${selectedKnowledgeWork.name}`;
    let title = "Create Knowledge Work";
    let steps = ["Context Gathering", "Context Formalizing into Activities", "Knowledge Activity Validation", "Agent Chain Mapping", "Agent Chain Test"];
    const aChainToCahtPopFun = (props) => {

        console.log("aChainToCahtPopFun- 123", props);
    }


    const navSummary = [{ name: "CREATE KNOWLEDGE WORK" }, { name: `Knowledge Work: ${selectedKnowledgeWork.name}` }]

    const renderConditional = (rview) => {
        console.log(rview)
        if (rview === 'models') {
            return <SmeChainModelView agentData={activity_master_json} setView={setView}></SmeChainModelView>
        } else {
            return <></>
        }
    }

    const handleDeployKnowledgeWork = () => {
        axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${selectedKnowledgeWork.id}`, { "status": "done" })
            .then((res) => {
                console.log(res)
                if (res.status == 200) {
                    navigate("/activityList")
                }
            })
            .catch((error) => {
                console.og(error)
            })
    }

    return (<>
        <div className={`${styles["max-size-100"]} h-100 `}>
            {/* side bar modal */}
            <HyperparameterRecommended data={paramsData} />

            <CustomNavbar links={links} profile={true}></CustomNavbar>
            <div className='d-flex flex-row justify-content-between' style={{ background: 'transparent linear-gradient(90deg, #212542 0%, #222C53 49%, #212542 100%) 0% 0% no-repeat padding-box' }}>
                <div className='d-flex flex-column' style={{ marginLeft: '2.5%' }}>
                    <span className='mt-4 mb-1' style={{ font: 'normal normal bold 17px/21px Calibri', letterSpacing: '0.55px' }}>Create Knowledge Work</span>
                    <span className='mb-4 mt-1' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Knowledge Work: {activity_master_json?.name}</span>
                </div>
                <div className='d-flex flex-row mt-auto mb-2' style={{ marginRight: '2.5%' }}>
                    <span style={{ border: '1px solid #899DD4' }} className='mx-4'></span>
                    <button className='btn me-2' style={{ font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#012060', background: '#D7E5FF 0% 0% no-repeat padding-box', borderRadius: '4px' }} onClick={() => { navigate('/activityList') }}>Close</button>
                    <button className='btn ms-2' style={{ background: '#003BEB 0% 0% no-repeat padding-box', borderRadius: '4px', font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#FFFFFF' }} onClick={handleDeployKnowledgeWork}>Deploy Knowledge Work</button>
                </div>
            </div >
            <div className='d-flex flex-column' style={{ background: 'transparent linear-gradient(119deg, #222E59 0%, #212542 100%) 0% 0% no-repeat padding-box' }}>
                <div className='d-flex flex-row justify-content-between pt-4 pb-2'>
                    <div style={{ marginLeft: '2.5%', font: 'normal normal normal 17px/21px Calibri', letterSpacing: '0.55px', color: '#E5EAF1' }}>
                        Summary Screen
                    </div>
                </div >
                <div className='d-flex flex-row' style={{ marginLeft: '2.5%', marginRight: '2.5%', backgroundImage: `url(${dottedPatternBG})`, backgroundSize: 'cover', backgroundColor: '#ffffff', backgroundAttachment: 'fixed' }}>
                    <div className='d-flex flex-column' style={{ width: `${showHP ? '66.66%' : '100%'}` }}>
                        <div className='d-flex flex-row w-100' style={{ background: '#42466C 0% 0% no-repeat padding-box', position: 'sticky', top: '0' }}>
                            <div
                                className='ps-4 py-2'
                                style={{ background: '#42466C 0% 0% no-repeat padding-box', width: `${showHP ? '50%' : '33.33%'}`, font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#FFFFFF', borderRight: '#D7E5FF', borderWidth: '2px' }} >
                                Activity Chain
                            </div>
                            <div
                                className='d-flex ps-5 py-2 flex-row justify-content-between'
                                style={{ background: '#42466C 0% 0% no-repeat padding-box', width: `${showHP ? '50%' : '33.33%'}`, font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#FFFFFF' }} >
                                <div>Recommended Models</div><div onClick={() => { navigate('/summary-model') }}>Edit<img className='ps-1' src={pencilIcon}></img></div>
                            </div>
                            {!showHP ? <div
                                className='ps-5 py-2'
                                style={{ background: '#42466C 0% 0% no-repeat padding-box', width: '33.33%', font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#FFFFFF' }} >
                                Agents
                            </div> : <></>}
                        </div>
                        <div className='d-flex flex-column' style={{ minHeight: "350px" }}>
                            {populateTable()}
                        </div>
                    </div>
                    {
                        showHP ?
                            <div className='d-flex flex-column h-100' style={{ minWidth: '33.33%', maxWidth: '33.33%', background: '#34385A 0% 0% no-repeat padding-box', borderRadius: '0px 0px 4px 4px', opacity: '1', backdropFilter: 'blur(50px)' }}>
                                <div
                                    className='d-flex pt-2 ps-2 pb-2 flex-row justify-content-between'
                                    style={{ background: '#42466C 0% 0% no-repeat padding-box', width: '100%', font: 'normal normal bold 16px/19px Calibri', letterSpacing: '0px', color: '#FFFFFF', position: 'sticky', top: '0' }} >
                                    <div>Recommended LLM Hyperparmeters</div><div className='pe-2' onClick={() => { setShowHP(false) }}>Close</div>
                                </div>
                                <div className='d-flex px-2 py-2 flex-column h-100' style={{ width: "20%", minWidth: "100%", maxWidth: "100%", background: '#212542 0% 0% no-repeat padding-box' }} >
                                    <div className='d-flex flex-row px-2 py-2' style={{ background: '#212542 0% 0% no-repeat padding-box', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#FFFFFF', textTransform: 'capitalize' }}>
                                        <div className='w-50'>Parameter Name</div>
                                        <div className='w-50'>Input</div>
                                    </div>
                                    {
                                        Object.keys(paramsData).length !== 0 ?
                                            <div>{
                                                Object.keys(paramsData).map((k, idx) => (
                                                    <HPAccordion head={k} data={paramsData[k]}></HPAccordion>
                                                ))
                                            }
                                            </div> : <div className='text-center my-1'> No Hyperparameters available</div>
                                    }
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
        </div >

        <></>
    </>
    )
}


SmeSummary.propTypes = {}

// --------------------------------components of use in this screen


export function SubHeading({ heading, modelTraining }) {
    const {
        selectedKnowledgeWork
    } = useContext(DockContext)
    const navigate = useNavigate()

    const redirectToTestCrew = () => {

        if (selectedKnowledgeWork) {
            navigate(`/testagent/${selectedKnowledgeWork.id}/0`)
            return ""
        }
        navigate("/activityList")

    }

    return (
        <div className={`w-100  d-flex justify-content-between align-items-center ${styles["bg-dark-blue"]} p-2 `} style={{ backgroundColor: "#454d7b" }}>
            <h4 className={`text-uppercase fs-5 `}>
                {heading}
            </h4>

            <Nav.Link className="icon-link icon-link-hover text-white mr-2" onClick={redirectToTestCrew} >
                Test Crew
                <i className="fa-solid fa-binoculars"></i>
            </Nav.Link>

        </div>
    )
}

SubHeading.propTypes = {
    heading: PropTypes.string.isRequired,
    modelTraining: PropTypes.object.isRequired
}



export function HyperparameterRecommended({ data }) {

    console.log(data)

    const populateParameters = () => {
        return (
            Object.keys(data).map((item, i) => {

                console.log(item, data[item])
                if (typeof (data[item]) === typeof ({})) {
                    return dynamicParameterWithSubParams(data[item], item)
                } else {
                    return paramsWithOutCategory(data, item)
                }
            })
        )
    }


    const paramsWithOutCategory = (data, item) => {
        console.log(data, item, "param without category")

        return (
            <div className={`d-flex gap-2 p-2 align-items-center justify-content-between ${styles["bg-dark-blue"]}`}>
                <p className='text-light'>{item}</p>
                <p className='text-light'>
                    {
                        `${data[item]}`
                    }
                </p>
            </div>
        )

    }

    const dynamicParameterWithSubParams = (data, key) => {

        console.log(data, key, "dynamic params")
        return (
            <div className='d-flex p-1 border-bottom flex-column'>

                <div className='d-flex border-bottom  align-items-center justify-content-between' style={{ maxHeight: "10%" }} >
                    <h4 className="modal-title fs-5 text-uppercase" id="side-bar-modal">{key}</h4>
                </div>

                {
                    Object.keys(data).length > 0 ?

                        Object.keys(data).map((item, i) => {
                            console.log(data, item)
                            return (
                                paramsWithOutCategory(data, item)
                            )
                        })
                        :

                        <h4 className='text-dark fs-6 text-center p-3'>
                            There are no Recommended Hyper Parameter for <span className='text-uppercase'> {key} </span>
                        </h4>
                }
            </div>
        )

    }



    return (
        <div className="modal fade come-from-modal right text-dark" id="side-bar-modal" tabindex="-1" aria-labelledby="side-bar-modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title fs-5" id="side-bar-modal">Hyperparameters</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='d-flex border-bottom  align-items-center justify-content-between' style={{ maxHeight: "10%" }} >
                            <h4 className="modal-title fs-5" id="side-bar-modal">Parameter Name</h4>
                            <h4 className="modal-title fs-5" id="side-bar-modal">Input</h4>
                        </div>
                        {
                            Object.keys(data).map((k, idx) => (
                                <HPAccordion head={k} data={data[k]}></HPAccordion>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

HyperparameterRecommended.propTypes = {}

