import React, { useEffect, useState } from "react";
import "../card.css";
import "./IndustryDetails.css";

function IndustryDetails({
  competency_data,
  setcompetency_data,
  setisedit,
  isedit,
}) {
  const [industryDetails_data, setindustryDetails_data] = useState({
    domain: competency_data.industry_domain,
    sub_domain: competency_data.industry_sub_domain,
    tags: [],
  });

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    console.log(inputValue);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();

      const tagValue = inputValue.trim();
      if (tagValue !== "") {
        setindustryDetails_data({
          ...industryDetails_data,
          tags: [...industryDetails_data.tags, tagValue],
        });
        setInputValue("");
      }

      var obj = {
        industry_domain: industryDetails_data.domain,
        industry_sub_domain: industryDetails_data.sub_domain,
      };
      var data = {
        ...competency_data,
        ...obj,

      }
      console.log(data,"industry");

      setcompetency_data({
        ...competency_data,
        ...obj,
      });
      var edit = [...isedit];
      edit[edit.length - 2] = true;
      setisedit([...isedit]);
    }
  };
  const handleRemoveTag = (i) => {
    const updatedTags = industryDetails_data.tags.filter((tag) => tag !== i);
    setindustryDetails_data({ ...industryDetails_data, tags: updatedTags });
  };

  useEffect(() => {
    var obj = {
      industry_domain: industryDetails_data.domain,
      industry_sub_domain: industryDetails_data.sub_domain,
    };

    setcompetency_data({
      ...competency_data,
      ...obj,
    });
    var edit = [...isedit];
    edit[edit.length - 2] = true;
    setisedit([...isedit]);
  }, [industryDetails_data]);

  return (
    <div className="cardOuter">
      <div className="cardInner">
        <div className="cardHeading">
          <h2 className="headingText">Industry Details</h2>
        </div>
        <label htmlFor="industryDomain">Industry Domain</label>
        <select
          id="industryDomain"
          name="industryDomain"
          onClick={(e) => {
            setindustryDetails_data({
              ...industryDetails_data,
              domain: e.target.value,
            });
          }}
        >
          <option value={industryDetails_data.domain || ""}>{industryDetails_data.domain}</option>
          <option value="Banking and Finance">Banking and Finance</option>
          <option value="Banking and Finance">Banking and Finance</option>
        </select>
        <label htmlFor="industrysubDomain">Industry Sub Domain</label>
        <select
          id="industrysubDomain"
          name="industrysubDomain"
          onClick={(e) => {
            setindustryDetails_data({
              ...industryDetails_data,
              sub_domain: e.target.value,
            });
          }}
        >
          <option value={industryDetails_data.sub_domain || ""}>{industryDetails_data.sub_domain}</option>
          <option value="Core Banking">Banking and Finance</option>
          <option value="Core Banking">Banking and Finance</option>
        </select>
        <label htmlFor="tagsInput">Tags</label>
        <input
          type="text"
          id="tagsInput"
          name="tagsInput"
          value={inputValue}
          onKeyDown={handleKeyPress}
          onChange={handleInputChange}
        />
        <div className="tagBubbles">
          {industryDetails_data.tags.map((tag, index) => (
            <span key={index} className="bubble">
              {tag}

              <span
                className="removBubble"
                onClick={() => handleRemoveTag(tag)}
              >
                x
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default IndustryDetails;
