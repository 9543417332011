// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tooltip container */
.ChatInterface_tooltip__-W40r {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.ChatInterface_tooltip__-W40r .ChatInterface_tooltiptext__ft003 {
    visibility: hidden;
    width: 150px;
    background-color: #212542;
    color: #bac9f1;
    text-align: center;
    padding: 5px;
    padding-bottom: 5px;
    border-radius: 6px;
    line-height: 14px;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: capitalize;
    text-wrap: wrap;
    overflow: scroll;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.ChatInterface_tooltip__-W40r .ChatInterface_tooltiptext__ft003::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.ChatInterface_tooltip__-W40r:hover .ChatInterface_tooltiptext__ft003 {
    visibility: visible;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/AChain/FunctionalChatInterface/ChatInterface.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,6EAA6E;IAC7E,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;;IAEhB,8BAA8B;IAC9B,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,SAAS;IACT,kBAAkB;;IAElB,oBAAoB;IACpB,UAAU;IACV,wBAAwB;AAC5B;;AAEA,kBAAkB;AAClB;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,sDAAsD;AAC1D;;AAEA,oEAAoE;AACpE;IACI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":["/* Tooltip container */\n.tooltip {\n    position: relative;\n    display: inline-block;\n}\n\n/* Tooltip text */\n.tooltip .tooltiptext {\n    visibility: hidden;\n    width: 150px;\n    background-color: #212542;\n    color: #bac9f1;\n    text-align: center;\n    padding: 5px;\n    padding-bottom: 5px;\n    border-radius: 6px;\n    line-height: 14px;\n    font-size: 14px;\n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n    text-transform: capitalize;\n    text-wrap: wrap;\n    overflow: scroll;\n\n    /* Position the tooltip text */\n    position: absolute;\n    z-index: 1;\n    bottom: 125%;\n    left: 50%;\n    margin-left: -60px;\n\n    /* Fade in tooltip */\n    opacity: 0;\n    transition: opacity 0.3s;\n}\n\n/* Tooltip arrow */\n.tooltip .tooltiptext::after {\n    content: \"\";\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    margin-left: -5px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: #555 transparent transparent transparent;\n}\n\n/* Show the tooltip text when you mouse over the tooltip container */\n.tooltip:hover .tooltiptext {\n    visibility: visible;\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `ChatInterface_tooltip__-W40r`,
	"tooltiptext": `ChatInterface_tooltiptext__ft003`
};
export default ___CSS_LOADER_EXPORT___;
