import React from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const AlchemPagination = ({ totalPages, currentPage, handlePageChange }) => {
  const pageNumbers = [];

  const handleClick = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  if (totalPages <= 7) {
    // Display all page numbers if total pages is less than or equal to 7
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Display ellipsis (...) before and after a range of page numbers
    if (currentPage <= 4) {
      for (let i = 1; i <= 5; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...");
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - 3) {
      pageNumbers.push(1);
      pageNumbers.push("...");
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push("...");
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }
  }

  return (
    <div className="pagination-container d-flex justify-content-center align-items-center my-4">
      <button
        className="pagination-previous-button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <IoChevronBack />
      </button>
      {pageNumbers.map((number, index) => (
        <button
          key={index}
          className={`pagination-button d-flex justify-content-center align-items-center ${currentPage === number ? 'active' : ''} ${typeof (number) !== typeof (1) && "bg-transparent text-white"}`}
          onClick={() => handlePageChange(number)}
          disabled={typeof (number) !== typeof (1)}
        >
          {number}
        </button>
      ))}
      <button
        className="pagination-next-button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <IoChevronForward />
      </button>
    </div>
  );

}

export default AlchemPagination;