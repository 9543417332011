// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .table-container::-webkit-scrollbar {
    width: 12px; /* Width of vertical scrollbar */
    height: 12px; /* Height of horizontal scrollbar */
  }
  
  .table-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
    border-radius: 6px; /* Rounded corners for the scrollbar */
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/NewModelOrchestrator/DataSynthesizerSeed.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,WAAW,EAAE,gCAAgC;IAC7C,YAAY,EAAE,mCAAmC;EACnD;;EAEA;IACE,mBAAmB,EAAE,gBAAgB;EACvC;;EAEA;IACE,gBAAgB,EAAE,iBAAiB;IACnC,kBAAkB,EAAE,sCAAsC;EAC5D;;EAEA;IACE,gBAAgB,EAAE,0BAA0B;EAC9C","sourcesContent":[".table-container {\n    max-height: 300px;\n    overflow-y: auto;\n    overflow-x: auto;\n  }\n  \n  .table-container::-webkit-scrollbar {\n    width: 12px; /* Width of vertical scrollbar */\n    height: 12px; /* Height of horizontal scrollbar */\n  }\n  \n  .table-container::-webkit-scrollbar-track {\n    background: #f1f1f1; /* Track color */\n  }\n  \n  .table-container::-webkit-scrollbar-thumb {\n    background: #888; /* Handle color */\n    border-radius: 6px; /* Rounded corners for the scrollbar */\n  }\n  \n  .table-container::-webkit-scrollbar-thumb:hover {\n    background: #555; /* Handle color on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
