import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import DynamicInput from "../AgentModule/CreateAgent/DynamicInput";
import "../AgentModule/CreateAgent/CreateAgent.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blank from "../../assets/img/Mask Group 3@2x.png";
import { IoMdClose } from "react-icons/io";
import AgentAvatar1 from "../../assets/img/agentAvatar1.png";
import AgentAvatar2 from "../../assets/img/AgentAvatar2.svg";
import AgentAvatar3 from "../../assets/img/AgentAvatar3.svg";
import { Row, Col, Container, ButtonGroup } from "react-bootstrap";
import ConfigureModal from "./ConfigureModal";
import Modal from "react-bootstrap/Modal";
import uploadIcon from "../../assets/img/upload-agent.png";
import e from "cors";
import NavDropdown from "react-bootstrap/NavDropdown";
import ChatPopup from "./ChatPopup/ChatPopup";
import SelectModelList from "./SelectModelList";
import AgentsListTest from "./AgentsListTest";
import colorCode from "../../data/activityNodeDB";
import { AuthContext } from "../Auth/AuthContext";
import { jwtDecode } from "jwt-decode";
import { FaRegUserCircle } from "react-icons/fa";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import { DockContext } from "../DockWrapper/DockContext";
import AlchemProgressBar from "../SharedComponents/AlchemProgressBar/AlchemProgressBar";
import PropTypes from "prop-types";

const demoData = {
  id: "-200",
  name: "demo",
  user_id: "testing",
  chain_payload: {
    nodes: [],
    edges: [],
    name: "",
    purpose: "",
    agent: {},
  },
};

const AgentsList = (props) => {
  const [ind, setIndex] = useState(1);
  const { darkMode, IDToken, accessToken, setAccessToken, setRefreshToken } =
    useContext(AuthContext);
  console.log(JSON.parse(localStorage.getItem("chains")));


  const [chains_data, setchains_data] = useState(JSON.parse(localStorage.getItem("chains")))
  // let chains_data = JSON.parse(localStorage.getItem("chains"));

  const [agent_id, setagent_id] = useState(chains_data
    ? JSON.parse(chains_data.chain_payload).nodes
    : demoData.chain_payload.nodes)

  // let agent_id = chains_data
  //   ? JSON.parse(chains_data.chain_payload).nodes
  //   : demoData.chain_payload.nodes;

  console.log(agent_id);
  agent_id.forEach((id) => {
    let activity_name = id.data.label;
    console.log(activity_name);
  });

  const [passdata, setpassdata] = useState(chains_data
    ? { ...chains_data, chain_payload: JSON.parse(chains_data.chain_payload) }
    : demoData)


  // let passdata = chains_data
  //   ? { ...chains_data, chain_payload: JSON.parse(chains_data.chain_payload) }
  //   : demoData;


  let user = jwtDecode(IDToken);
  const [saveBtn, setSaveBtn] = useState(false);
  const [testAgentBtn, setTestAgentBtn] = useState(false);
  const [saveProceedBtn, setSaveProceedBtn] = useState(false);
  const [classiication, setClassification] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showList, setShowList] = useState(false);
  const { nodeIndex, setNodeIndex } = useContext(DockContext);

  // --------------------localstorage change
const [suggestedAgent, setSuggestedAgent] = useState()

  // -----------------------------------------

  const handleMouseEnter = () => {
    setShowList(true);
  };

  const handleMouseLeave = () => {
    setShowList(false);
  };

  const [JSONData, setJSONData] = useState(passdata)
  // let JSONData = passdata;
  const [colorkey, setColorKey] = useState(1);

  const updateSuggestedAgent = (agent) => {
    console.log(agent.target.value.name);
    console.log(agent.target.value);
    setColorKey(agent.target.value);
    setNodeIndex(agent.target.value);
    console.log(
      JSONData.chain_payload.agent[agent.target.value]["Activity Name"]
    );
    setClassification(
      JSONData.chain_payload.agent[agent.target.value].Activity_Classification
    );
    const suggented_agent = JSONData.chain_payload.agent[agent.target.value];
    setSuggestedAgent(suggented_agent)
    navigate("/agentcreation"); // .....???

    // localStorage.setItem("currentActivityForAgentCreation", suggented_agent);

    setAgentForm((prevState) => {
      let agentform = { ...prevState.agentForm };
      agentform.name = suggented_agent?.["Activity Name"];
      agentform.description = suggented_agent["Description"] ? suggented_agent["Description"] : "";
      agentform.instruction = suggented_agent["Instructions"] ? suggented_agent["Instructions"] : [];
      console.log(agentform.instruction);
      return agentform;
    });

  };

  useEffect(() => {
    console.log(nodeIndex);
    const agent = { target: { value: nodeIndex } };
    updateSuggestedAgent(agent);
  }, []);

  const agentKeys = Object.keys(JSONData.chain_payload.agent);
  console.log(JSONData.chain_payload.agent);

  const navigate = useNavigate();

  const [agents, setAgents] = useState([]);
  const [showAgentIcon, setShowAgentIcon] = useState(false);
  const [agentlist, setAgentList] = useState([]);

  const handleClose = () => {
    setShowAgentIcon(false);
    setSelectedFile(null);
  };

  useEffect(() => {

    if (props.prevPath) {
      console.log(props.location.state);
      // updateSuggestedAgent(
      //   JSON.parse(localStorage.getItem("currentActivityForAgentCreation"))
      // );
      updateSuggestedAgent(suggestedAgent)
      setTestAgentBtn(true);
    }
    console.log(accessToken);
    axios
      .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setAgents(response.data);
        console.log("Fetch agents success : ");
        console.log(response.data);
        setAgentList(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
              { headers }
            )
            .then(refreshResponse => {
              // Check if the refresh request was successful
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true; // User is authenticated after successful token refresh
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false; // Authentication failed if token refresh fails
              }
            })
            .catch(error => {
              if (error.response && error.response.status === 401)
                window.location.reload()
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false; // Authentication failed if token refresh fails
            });
      });
  }, []);

  const [save, setSave] = useState(false);
  const [agentForm, setAgentForm] = useState({
    name: "",
    project_id: 1,
    user_id: 1,
    description: "",
    goal: [],
    instruction: [],
    agent_workflow: "Goal Based Workflow",
    constraints: [],
    toolkits: [],
    tools: [38],
    exit: "",
    iteration_interval: "500",
    model: "",
    max_iterations: 5,
    permission_type: "",
    LTM_DB: "",
    user_timezone: "",
    knowledge: null,
  });

  useEffect(() => {
    let agentExists = false;
    console.log(nodeIndex, nodeIndex);
    agent_id.forEach((id) => {
      console.log(id);
      if (id.id === `${nodeIndex}`) {
        console.log(id.agent);
        if (id.agent != null) {
          agentExists = true;
          console.log(agentExists);
        }
      }
    });

    setSave(agentExists);
  });

  const payload = {
    name: agentForm.name,
    project_id: 1,
    agent_type: "Generative",
    agent_model: "/path",
    instruction: agentForm.instruction,
    description: agentForm.description,
    goal: ["INPUT: "],
    agent_workflow: "Goal Based Workflow",
    constraints: [
      "If you are unsure how you previously did something or want to recall past events, thinking about similar events will help you remember.",
      "Ensure the tool and args are as per current plan and reasoning",
      'Exclusively use the tools listed under "TOOLS"',
      'REMEMBER to format your response as JSON, using double quotes ("") around keys and string values, and commas (,) to separate items in arrays and objects. IMPORTANTLY, to use a JSON object as a string in another JSON object, you need to escape the double quotes.',
    ],
    toolkits: [],
    tools: [12, 13, 17],
    exit: "No exit criterion",
    iteration_interval: 500,
    model: "test",
    max_iterations: 25,
    permission_type: "God Mode",
    LTM_DB: "Pinecone",
    user_timezone: "Asia/Calcutta",
    knowledge: null,
  };

  console.log(payload);

  useEffect(() => {
    console.log("chain agent form", agentForm)

  }, [agentForm])


  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };

  const [agentId, setAgentId] = useState(0);
  const [agentResponse, setAgentResponse] = useState();
  console.log(JSONData.id);

  const do_proceed = () => {
    setIndex(ind + 1);
    setSaveBtn(true);
    setSaveProceedBtn(true);
    handleInstFinish(true)
  };

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }



  const createAgent = () => {

    handleInstFinish(true)
    payload.instruction = payload.instruction.filter((ins) => {
      if (ins != "") {
        return true
      } else {
        return false
      }
    })
    axios
      .post(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`, payload, {
        headers,
      })
      .then((response) => {
        console.log(response);

        setAgentId(response.data.id);
        setAgentResponse(response.data);
        const test = JSONData;
        console.log(nodeIndex, response.data.id, test, "test");

        test.chain_payload.nodes.map((node) => {
          if (nodeIndex == node.id) {
            node["agent"] = response.data.id;
          }
        })

        test.chain_payload.agent[nodeIndex] = {
          ...test.chain_payload.agent[nodeIndex],
          "Activity Name": agentForm.name,
          "Description": agentForm.description,
          "Instructions": agentForm.instruction,
        }

        console.log(test);
        toast.success("Agent Creation in progress!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        axios
          .patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/items/${JSONData.id}`, {
            ...test,
            chain_payload: JSON.stringify(test.chain_payload),
          })
          .then((response) => {
            console.log(response);
            updateChainLoaclStorage(JSONData.id)

            toast.success("Agent Created!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log(error);
            }
          });

      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
          if (error.response && error.response.status === 401)
            axios
              .post(
                `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
                { headers }
              )
              .then(refreshResponse => {
                // Check if the refresh request was successful
                if (
                  refreshResponse.status === 200 &&
                  refreshResponse.data.access_token &&
                  refreshResponse.data.refresh_token
                ) {
                  setAccessToken(refreshResponse.data.access_token);
                  setRefreshToken(refreshResponse.data.refresh_token);
                  document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                  document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                  console.log("Token refreshed");
                  return true; // User is authenticated after successful token refresh
                } else {
                  console.log("Error refreshing token:", refreshResponse);
                  return false; // Authentication failed if token refresh fails
                }
              })
              .catch(error => {
                if (error.response && error.response.status === 401)
                  window.location.reload()
                if (
                  error.response &&
                  error.response.status === 400 &&
                  error.response.data.error === "invalid_grant"
                ) {
                  console.error("Refresh token expired or invalid");
                }

                return false; // Authentication failed if token refresh fails
              });
        } else if (error.request) {
          console.log("network error");

        } else {
          console.log(error);
        }
      });

  };

  const test_agent = () => {
    setIndex(ind + 1);
    console.log(agentId);
    setTestAgentBtn(true);
  };
  const handleBackStep = () => {
    if (ind > 1) {
      setIndex(ind - 1);
    }
    setSaveBtn(false);
    setSaveProceedBtn(false);
    setTestAgentBtn(false);
    setInstructionsFormValues([
      {
        label: "instruction",
        type: "text",
        value: "",
      },
    ])
    setAgentId(0)
    setAgentResponse()

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgentForm((prev) => {
      console.log({ ...prev, [name]: value })
      return { ...prev, [name]: value };
    });
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const [instructionsFormValues, setInstructionsFormValues] = useState([
    {
      label: "instruction",
      type: "text",
      value: "",
    },
  ]);



  const handleInstructionsChange = (e, index) => {
    const values = [...instructionsFormValues];
    values[index].value = e.target.value;
    console.log("chain instruction from handleInstructionsChange", values)
    setInstructionsFormValues(values);

  };
  const handleInstChange = (e, index) => {
    let new_instruction = agentForm.instruction;
    new_instruction[index] = e.target.value;
    console.log("chain instruction", new_instruction)
    setAgentForm({ ...agentForm, instruction: new_instruction });
  };

  const handleInstructionsDeleteField = (e, index) => {
    const values = [...instructionsFormValues];
    values.splice(index, 1);
    setInstructionsFormValues(values);
    if (agentForm.instruction.length > 0) {
      const valuesInstr = [...agentForm.instruction];
      valuesInstr.splice(index, 1);
      setAgentForm((prevState) => {
        let agentform = { ...prevState.agentForm };
        agentform.instruction = valuesInstr;
        return agentform;
      });
    }
  };

  const handleInstructionsAddField = (e) => {
    e.preventDefault();
    const values = [...instructionsFormValues];
    values.push({
      label: "instruction",
      type: "text",
      value: "",
    });
    setInstructionsFormValues(values);
  };

  // -----------------------------field update------------------------------------------------


  useEffect(() => {

    console.log("state convertion", {
      "passdata": passdata,
      "agent_id": agent_id,
      "chain_data": chains_data
    })

  }, [passdata, agent_id, chains_data])


  useEffect(() => {

    setchains_data(JSON.parse(localStorage.getItem("chains")));

    setagent_id(chains_data
      ? JSON.parse(chains_data.chain_payload).nodes
      : demoData.chain_payload.nodes)

    setpassdata(chains_data
      ? { ...chains_data, chain_payload: JSON.parse(chains_data.chain_payload) }
      : demoData);


    setJSONData(passdata);

  }, [localStorage.getItem("chains")])

  const updateChainLoaclStorage = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/items/` + id,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data, typeof response.data);
        localStorage.setItem("chains", JSON.stringify(response.data));

        setchains_data(response.data);

        setagent_id(chains_data
          ? JSON.parse(chains_data.chain_payload).nodes
          : demoData.chain_payload.nodes)

        setpassdata(chains_data
          ? { ...chains_data, chain_payload: JSON.parse(chains_data.chain_payload) }
          : demoData);


        setJSONData(passdata);
        alert("chain updated")
      })

      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
              { headers }
            )
            .then(refreshResponse => {
              // Check if the refresh request was successful
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true; // User is authenticated after successful token refresh
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false; // Authentication failed if token refresh fails
              }
            })
            .catch(error => {
              if (error.response && error.response.status === 401)
                window.location.reload()
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false; // Authentication failed if token refresh fails
            });
      });
  }


  const updateChainBackend = () => {
    console.log("api called")


  }



  const handleInstFinish = (isFinish) => {
    console.log("handleInstFinish")
    if (isFinish) {
      console.log("chain instruction handle finish", instructionsFormValues)
      let new_instruction = instructionsFormValues.map((field) => {
        return field.value
      })

      console.log("chain instruction", agentForm)
      console.log(" chain instruction", { ...agentForm, instruction: [...agentForm.instruction, ...new_instruction] })
      setAgentForm({ ...agentForm, instruction: [...agentForm.instruction, ...new_instruction] });
      setInstructionsFormValues([
        {
          label: "instruction",
          type: "text",
          value: "",
        },
      ]
      )
    }
  }

  // ---------------------------------------------------------------------------------

  /** File Handling  */
  const [file, setFile] = useState(blank);
  const [prevIcon, setPrevIcon] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const agentIcons = [AgentAvatar1, AgentAvatar2, AgentAvatar3];
  const do_uploadIcon = () => {
    setPrevIcon(file);
    setShowAgentIcon(true);
  };
  const handleFileChange = (event) => {
    setSelectedFile(URL.createObjectURL(new Blob(event.target.files)));
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setSelectedFile(URL.createObjectURL(new Blob(event.target.files)));
  };
  const updateIcon = (img) => {
    setSelectedFile(img);
  };
  const updateAgentIcon = () => {
    setFile(selectedFile);
    handleClose();
  };
  let title = "Activity Chain Creation";
  let subtitle = `Knowledge Work: ${chains_data.name}`;
  let steps = ["Agent Detail", "Model Configuration", "Test Agent"];
  return (
    <>
      <ToastContainer />

      {/* Screen number 17 from use case wireframe By Mahadev */}
      <ConfigureModal
        showModal={showModal}
        closeModal={closeModal}
        darkMode={darkMode}
      />

      <CustomNavbar
        links={[]}
        profile={true}
        handleToggle={props.handleToggle}
      />

      <AlchemProgressBar
        title={title}
        subtitle={subtitle}
        steps={steps}
        index={ind}
      />

      <div className="container-fluid imgBackground">
        <div className="row mx-0">
          <div className="col-md-12 px-0 pb-3">
            {/** Modal */}
            <Row
              className="px-3"
              style={{ background: darkMode ? "#34385A" : "" }}
            >
              <p
                style={{
                  color: darkMode ? "#BAC9F1" : "#000",
                  position: "relative",
                  top: "5px",
                  marginLeft: "15px",
                  display: "flex",
                }}
                className="mt-2 "
              >
                <button
                  style={{
                    position: "relative",
                    top: "2px",
                    fontSize: "16px",
                    background: "transparent",
                    color: darkMode ? "#BAC9F1" : "",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {" "}
                  Activity {nodeIndex} of {agentKeys.length}: {agentForm.name}{" "}
                </button>{" "}
                <span style={{ marginLeft: "16px", fontSize: "16px" }}>
                  {" "}
                  |{" "}
                </span>{" "}
                <span
                  className="title-agent-details d-flex"
                  style={{
                    color: darkMode ? "#BAC9F1" : "",
                    marginTop: "-13px",
                  }}
                >
                  Intelligence Type:{" "}
                  <div
                    style={{
                      background:
                        colorCode[
                        JSONData.chain_payload.agent[colorkey][
                        "Activity_Classification"
                        ]
                        ],
                      width: "14px",
                      height: "14px",
                      borderRadius: "4px",
                      margin: "5px 8px",
                    }}
                  ></div>{" "}
                  {
                    JSONData.chain_payload.agent[colorkey][
                    "Activity_Classification"
                    ]
                  }
                </span>
              </p>
            </Row>

            {showList && (
              <section
                className={`activityListDropdown `}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  background: darkMode ? "#34385A" : "",
                  color: darkMode ? "#BAC9F1" : "",
                }}
              >
                {agentKeys.map((key) => (
                  <>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="activity"
                      value={key}
                      onChange={(e) => updateSuggestedAgent(e)}
                    />
                    <label className="form-check-label">
                      {" "}
                      {JSONData.chain_payload.agent[key]["Activity Name"]}
                    </label>
                    <br />
                  </>
                ))}
              </section>
            )}
          </div>
        </div>

        {/* agent details */}
        {!saveBtn && !testAgentBtn && (
          <>
            <div className="row mx-0">
              <div className="col-md-10 offset-md-1 px-0 pb-3">
                {/** Modal */}

                <Row
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "0",
                    height: "40px",
                  }}
                >
                  <Col sm={8} className="d-flex">
                    <span
                      className="title-agent-details"
                      style={{
                        color: darkMode ? "#BAC9F1" : "",
                        padding: "12px",
                      }}
                    >
                      Agent Details
                    </span>
                  </Col>

                  <Col sm={4} style={{ padding: 0, paddingRight: "3rem" }}>
                    <Row className="mx-0">
                      <Col sm={8} style={{ paddingLeft: "133px" }}>
                        <Link
                          to={`/achainsaved/${chains_data.id}`}
                          className="btn btn-primary cancelBtnDark me-3 cancel-new-agent"
                          href="/agentcreation"
                          style={{ margin: 0 }}
                        >
                          Cancel
                        </Link>
                      </Col>
                      <Col
                        sm={4}
                        className="d-flex"
                        style={{
                          borderLeft: "1px solid #dee2e6",
                          paddingLeft: "10px",
                        }}
                      >
                        {agentResponse && (
                          <Link
                            to="./knowledgework"
                            className="btn btn-primary submitBtnDark me-3"
                            onClick={test_agent}
                            style={{ width: "118px", marginTop: "2px" }}
                          >
                            Test Activity
                          </Link>
                        )}

                        {saveProceedBtn && (
                          <button
                            className="btn btn-primary submitBtnDark me-3"
                            onClick={createAgent}
                            style={{ width: "118px", marginTop: "2px" }}
                          >
                            Save
                          </button>
                        )}

                        {!saveProceedBtn && (
                          <button
                            className="submitBtnDark btn btn-primary me-3"
                            disabled={save}
                            onClick={do_proceed}
                            style={{ width: "118px", marginTop: "2px" }}
                          >
                            {console.log(save)}
                            Save
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </div>
            </div>

            <div className="row align-items-start mx-0">
              <div className="col-12 col-md-10 pe-3 offset-md-1">
                <div
                  style={{
                    background: darkMode ? "#474F73" : "",
                    padding: "1px 16px",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                >
                  <Container
                    className={darkMode ? "bgwDarkForm" : "bgw"}
                    style={{
                      border: darkMode ? "none" : "1px solid #dee2e6",

                      marginBottom: "20px",
                    }}
                  >
                    <Row
                      style={{
                        background: darkMode ? "#34385A" : "",
                        borderBottom: "1px solid #2F499E",
                        marginTop: "20px",
                        height: "41px",
                      }}
                    >
                      <Col>
                        <span
                          className={
                            darkMode ? "subformTitleDark" : "subformTitle"
                          }
                          style={{
                            width: "110px",
                            marginTop: "11px",
                            marginLeft: "12px",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          Basic Details{" "}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <form className="agentDetailsForm">
                        <Container>
                          <Row>
                            <Col sm={8} className="pt-4 pe-4">
                              <label
                                htmlFor="name"
                                className="label-name pt-2"
                                style={{ color: darkMode ? "#BAC9F1" : "" }}
                              >
                                Activity Name
                              </label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={agentForm.name}
                                onChange={handleChange}
                                className="form-control input-name"
                                style={{
                                  background: darkMode ? "#212542" : "",
                                  color: darkMode ? "#BAC9F1" : "",
                                  border: darkMode ? "1px solid #2F499E" : "",
                                }}
                              />
                              <label
                                htmlFor="description"
                                className="label-purpose pt-3"
                                style={{ color: darkMode ? "#BAC9F1" : "" }}
                              >
                                Purpose
                              </label>
                              <textarea
                                id="description"
                                name="description"
                                value={agentForm.description}
                                onChange={handleChange}
                                className="form-control input-purpose"
                                rows="4"
                                style={{
                                  background: darkMode ? "#212542" : "",
                                  color: darkMode ? "#BAC9F1" : "",
                                  border: darkMode ? "1px solid #2F499E" : "",
                                }}
                              />
                            </Col>
                            <Col
                              sm={4}
                              style={{ borderLeft: "1px solid #2F499E" }}
                            >
                              <Row
                                style={{ position: "relative", left: "10%" }}
                              >
                                <Col>
                                  {file === blank ? (
                                    <FaRegUserCircle
                                      style={{
                                        fontSize: "7rem",
                                        marginTop: "20px",
                                        position: "relative",
                                        left: "25%",
                                        color: darkMode ? "#D7E5FF" : "#012093",
                                      }}
                                    />
                                  ) : (
                                    <img src={file} className="agent-icon" />
                                  )}
                                </Col>
                              </Row>
                              <Row
                                style={{ position: "relative", left: "22%" }}
                              >
                                <Col>
                                  <span
                                    className="creator-text"
                                    style={{ color: darkMode ? "#bac9f1" : "" }}
                                  >
                                    {user.name}
                                  </span>
                                </Col>
                              </Row>
                              <Row
                                style={{ position: "relative", left: "10%" }}
                              >
                                <Col>
                                  <button
                                    className="btn btn-primary cancelBtnDark me-3 select-icon-btn"
                                    onClick={do_uploadIcon}
                                  >
                                    Upload Agent Icon
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </form>
                    </Row>
                  </Container>
                </div>
                <div
                  style={{
                    background: darkMode ? "#474F73" : "",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                    marginBottom: "24px",
                  }}
                  className="mt-4"
                >
                  <Container
                    className={darkMode ? "bgwDarkForm" : "bgw"}
                    style={{
                      border: darkMode ? "none" : "1px solid #dee2e6",
                      background: darkMode ? "#212542" : "",
                      marginBottom: "20px",
                    }}
                  >
                    <Row
                      style={{
                        background: darkMode ? "#212542" : "",
                        borderBottom: "1px solid #2F499E",
                        marginTop: "20px",
                        height: "41px",
                        marginBottom: "40px",
                      }}
                    >
                      <Col>
                        <span
                          className={
                            darkMode ? "subformTitleDark" : "subformTitle"
                          }
                          style={{
                            width: "110px",
                            marginTop: "11px",
                            marginLeft: "12px",
                            color: darkMode ? "#E5EAF1" : "",
                            background: darkMode ? "#34385A" : "",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          Instructions{" "}
                        </span>
                      </Col>

                      <Col sm={2} style={{ padding: 0 }}>
                        <ButtonGroup
                          className="btn btn-primary cancelBtnDark me-4"
                          style={{ marginTop: "14%", marginLeft: "39px" }}
                          onClick={handleInstructionsAddField}
                        >
                          + Add Instructions
                        </ButtonGroup>
                      </Col>
                      <Col sm={1} style={{ padding: 0 }}></Col>
                    </Row>
                    <Row style={{ marginTop: "6px", marginBottom: "20px" }}>
                      <Col style={{ paddingLeft: "26px" }}>
                        {agentForm.instruction && (
                          <form onSubmit={(e) => {
                            e.preventDefault()
                          }}>
                            {agentForm.instruction.map((obj, index) => {
                              let tempObj = {
                                label: "instruction",
                                type: "text",
                                value: obj,
                              };

                              return (
                                <DynamicInput
                                  key={index}
                                  objValue={tempObj}
                                  onChange={handleInstChange}
                                  index={index}
                                  deleteField={handleInstructionsDeleteField}
                                  darkMode={darkMode}
                                  onFinish={handleInstFinish}
                                />
                              );
                            })}
                          </form>
                        )}

                        <form onSubmit={(e) => {
                          e.preventDefault()
                        }}>
                          {instructionsFormValues.map((obj, index) => (
                            <DynamicInput
                              key={index}
                              objValue={obj}
                              onChange={handleInstructionsChange}
                              index={index}
                              deleteField={handleInstructionsDeleteField}
                              darkMode={darkMode}
                              onFinish={handleInstFinish}
                            />
                          ))}
                        </form>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <ChatPopup />
            </div>

          </>
        )
        }

        {/* model configuration */}
        {
          saveBtn && !testAgentBtn && (
            <>
              <div className="row mx-0" style={{ backgroundColor: "inherit" }}>
                <div className="col-md-12 px-0 pb-3">
                  {/** Modal */}
                  <Row
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "0",
                      paddingLeft: "40px",
                      height: "40px",
                    }}
                  >
                    <Col sm={7}>
                      <Row
                        className="SelectModelTitle"
                        style={{ color: darkMode ? "" : "black" }}
                      >
                        Select Model
                      </Row>
                      <Row
                        className="SelectModelTitle"
                        style={{
                          font: "normal normal normal 14px/17px Calibri",
                          color: darkMode ? "#BAC9F1" : "black",
                        }}
                      >
                        The activity type is{" "}
                        {
                          JSONData.chain_payload.agent[colorkey][
                          "Activity_Classification"
                          ]
                        }{" "}
                        in nature and hence Genius Partner has provided the
                        following recommendations
                      </Row>
                    </Col>

                    <Col
                      sm={5}
                      style={{ padding: 0, paddingRight: "2rem" }}
                      className="text-right"
                    >
                      <span className="seperatorBorder">
                        <button
                          to="/agentcreation"
                          className="btn btn-primary cancelBtnDark me-3 cancel-new-agent"
                          style={{ margin: 0 }}
                          onClick={handleBackStep}
                        >
                          Back
                        </button>
                        <button
                          className="btn btn-primary submitBtnDark"
                          onClick={() => openModal()}
                        >
                          <span style={{}}>+</span> Configure Model
                        </button>
                      </span>
                      {agentResponse && (
                        <Link
                          to="./knowledgework"
                          className="btn btn-primary submitBtnDark me-3"
                          onClick={test_agent}
                          style={{ width: "118px", marginTop: "2px" }}
                        >
                          Test Activity
                        </Link>
                      )}

                      {saveProceedBtn && !agentResponse && (
                        <button
                          className="btn btn-primary submitBtnDark ms-3"
                          onClick={createAgent}
                          style={{ marginTop: "2px" }}
                        >
                          Select & Proceed
                        </button>
                      )}

                      {!saveProceedBtn && (
                        <button
                          className="submitBtnDark btn btn-primary me-3"
                          disabled={save}
                          onClick={do_proceed}
                          style={{ width: "118px", marginTop: "2px" }}
                        >
                          Save & Proceed
                        </button>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <SelectModelList darkMode={darkMode} />
            </>
          )
        }

        {
          testAgentBtn && (
            <AgentsListTest
              agentID={agentId}
              darkMode={darkMode}
              activityChainID={chains_data.id}
            />
          )
        }
      </div >

      <Modal
        show={showAgentIcon}
        onHide={handleClose}
        className="uploadIconModal"
      >
        <Modal.Body
          className="modal-content-agent"
          style={{ background: darkMode ? "#34385A" : "" }}
        >
          <Container style={{ height: "100%" }}>
            <Row style={{ marginBottom: "10px", height: "35px" }}>
              <Col sm={11}>
                <span
                  style={{
                    marginTop: "67px",
                    paddingTop: "20%",
                    fontSize: "20px",
                  }}
                >
                  Activity Icon
                </span>
              </Col>
              <Col sm={1}>
                <Link
                  onClick={handleClose}
                  style={{
                    height: "10px",
                    width: "10px",
                    color: "#012060",
                  }}
                >
                  <IoMdClose
                    style={{
                      fontSize: "24px",
                      color: darkMode ? "#BAC9F1" : "",
                    }}
                  />
                </Link>
              </Col>
            </Row>
            <Row style={{ borderTop: "1px solid #212542", height: "321px" }}>
              <Col style={{ borderRight: "1px solid #212542" }} sm={6}>
                <div
                  className="dragDropContainer"
                  onDrop={handleDrop}
                  onDragOver={(event) => event.preventDefault()}
                >
                  <div className="upload-info">
                    <div
                      style={{
                        marginTop: "3px",
                        marginLeft: "34%",
                        height: "67px",
                        width: "67px",
                      }}
                    >
                      {selectedFile ? (
                        <img
                          src={selectedFile}
                          style={{ height: "67px", width: "67px" }}
                          alt="selectedFile"
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    <div style={{ marginTop: "5px", marginLeft: "46%" }}>
                      <img
                        src={uploadIcon}
                        style={{ height: "17px", width: "17px" }}
                        alt="upload icon"
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        marginLeft: "33%",
                        color: "#ADC2FF",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Drag and Drop
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "11px",
                      marginLeft: "44%",
                      color: "#111621",
                      fontSize: "13px",
                    }}
                  >
                    <span style={{ color: darkMode ? "#BAC9F1" : "" }}>
                      {" "}
                      OR
                    </span>
                  </div>
                  <input
                    type="file"
                    hidden
                    id="browse"
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png,.svg"
                  />
                  <label
                    htmlFor="browse"
                    className="browse-btn"
                    style={{ marginLeft: "25%" }}
                  >
                    <Link
                      to="/agentfactory"
                      className="btn btn-primary cancelBtnDark me-3"
                      style={{ marginLeft: "20px", marginTop: "5px" }}
                      onClick={e.preventDefault}
                    >
                      Browse
                    </Link>
                  </label>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span>Select from default Icons</span>
                  </Col>
                  <Col>
                    <NavDropdown
                      className="btn modelSelectDropdown"
                      title="All Domain"
                      id="navbarScrollingDropdown"
                    >
                      <NavDropdown.Item href="#action3">
                        Action
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action4">
                        Another action
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#action5">
                        Something else here
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <col></col>
                  {agentIcons.map((obj, index) => (
                    <Col sm={4} key={index}>
                      <img
                        src={obj}
                        height={67}
                        width={67}
                        onClick={() => updateIcon(obj)}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px", height: "50px", padding: 0 }}>
              <Col sm={8}></Col>
              <Col sm={2} style={{ padding: 0 }}>
                <button
                  className="btn btn-primary cancelBtnDark me-3"
                  style={{
                    height: "33px",
                    width: "76px",
                    marginLeft: "17px",
                    padding: "6px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </Col>
              <Col sm={2} style={{ padding: 0 }}>
                <button
                  className="btn btn-primary submitBtnDark me-3"
                  onClick={updateAgentIcon}
                >
                  Proceed
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

AgentsList.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.object.isRequired,
  }).isRequired,
  prevPath: PropTypes.string.isRequired,
};

export default AgentsList;
