// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-modal {
    width: 70vw;   /* Occupy the 90% of the screen width */
    max-width: 70vw;
} 

.example-padding {
    /* max-height: 300px; */
    max-width: 800px;
    color: white; 
  }
  .example-padding  pre{
    color: white;
  }
  .question-mark-icon {
    cursor: pointer;
    font-size: 18px; 
    padding-left: 5px;
  }
  .modal-header .btn-close{
    color: #BAC9F1;
  }

`, "",{"version":3,"sources":["webpack://./src/components/RecoModule/LoadModal.css"],"names":[],"mappings":"AAAA;IACI,WAAW,IAAI,uCAAuC;IACtD,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,eAAe;IACf,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,cAAc;EAChB","sourcesContent":[".my-modal {\n    width: 70vw;   /* Occupy the 90% of the screen width */\n    max-width: 70vw;\n} \n\n.example-padding {\n    /* max-height: 300px; */\n    max-width: 800px;\n    color: white; \n  }\n  .example-padding  pre{\n    color: white;\n  }\n  .question-mark-icon {\n    cursor: pointer;\n    font-size: 18px; \n    padding-left: 5px;\n  }\n  .modal-header .btn-close{\n    color: #BAC9F1;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
