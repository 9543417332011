// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SmeSummary_max-size-100__c2J85 {
    width: 100%;
    height: 100%;
}

.SmeSummary_text-size-24px__JQ2WS {
    font-size: 24px;
}

.SmeSummary_bg-dark-blue__SUdKP {
    background-color: #212542;
}

.SmeSummary_bg-light-grey__v9BNY {
    background-color: #afaaaa;
}


.SmeSummary_table-bg-light-cyan__mAJCi {
    background-color: #454d7b;
}

.SmeSummary_d-table-head__R5k8R {
    display: table-header-group;
    color: #fff;
    height: 8%;
    background-color: #212529;
    border-color: #32383e;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    padding: .5rem .5rem;
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}


.SmeSummary_table-container__p\\+wb9>table,
tr {
    background: transparent;
}

.SmeSummary_table-container__p\\+wb9>td {
    border: none;
}

.SmeSummary_no-border__2lJoZ {
    border: none !important;
}

.SmeSummary_d-table-row__AngDi {
    display: table-row;
}

.SmeSummary_d-table-cell__wZn2b {
    display: table-cell;
}


.SmeSummary_line__fhKjG {
    background-color: #c7d7f0;
    color: #c7d7f0;
    width: 1px;
    height: 30px;
}


`, "",{"version":3,"sources":["webpack://./src/components/AChain/SmeSummary/SmeSummary.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,UAAU;IACV,yBAAyB;IACzB,qBAAqB;IACrB,sBAAsB;IACtB,gCAAgC;IAChC,oBAAoB;IACpB,2CAA2C;IAC3C,2GAA2G;AAC/G;;;AAGA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,YAAY;AAChB","sourcesContent":[".max-size-100 {\n    width: 100%;\n    height: 100%;\n}\n\n.text-size-24px {\n    font-size: 24px;\n}\n\n.bg-dark-blue {\n    background-color: #212542;\n}\n\n.bg-light-grey {\n    background-color: #afaaaa;\n}\n\n\n.table-bg-light-cyan {\n    background-color: #454d7b;\n}\n\n.d-table-head {\n    display: table-header-group;\n    color: #fff;\n    height: 8%;\n    background-color: #212529;\n    border-color: #32383e;\n    vertical-align: bottom;\n    border-bottom: 2px solid #dee2e6;\n    padding: .5rem .5rem;\n    border-bottom-width: var(--bs-border-width);\n    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));\n}\n\n\n.table-container>table,\ntr {\n    background: transparent;\n}\n\n.table-container>td {\n    border: none;\n}\n\n.no-border {\n    border: none !important;\n}\n\n.d-table-row {\n    display: table-row;\n}\n\n.d-table-cell {\n    display: table-cell;\n}\n\n\n.line {\n    background-color: #c7d7f0;\n    color: #c7d7f0;\n    width: 1px;\n    height: 30px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"max-size-100": `SmeSummary_max-size-100__c2J85`,
	"text-size-24px": `SmeSummary_text-size-24px__JQ2WS`,
	"bg-dark-blue": `SmeSummary_bg-dark-blue__SUdKP`,
	"bg-light-grey": `SmeSummary_bg-light-grey__v9BNY`,
	"table-bg-light-cyan": `SmeSummary_table-bg-light-cyan__mAJCi`,
	"d-table-head": `SmeSummary_d-table-head__R5k8R`,
	"table-container": `SmeSummary_table-container__p+wb9`,
	"no-border": `SmeSummary_no-border__2lJoZ`,
	"d-table-row": `SmeSummary_d-table-row__AngDi`,
	"d-table-cell": `SmeSummary_d-table-cell__wZn2b`,
	"line": `SmeSummary_line__fhKjG`
};
export default ___CSS_LOADER_EXPORT___;
