import React, { useEffect, useRef, useContext, useState } from "react";
import axios from "axios";
import { MarkerType } from "reactflow";
import { DockContext } from "../DockWrapper/DockContext";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from "react-icons/bs";
import { json } from "react-router-dom";
import ChatRobot from "../../assets/img/coachllm-robo.svg";
import sendchat from "../../assets/img/sendchat.svg";
import { AuthContext } from '../Auth/AuthContext';
import { width } from '@mui/system';
import Mind from "../mindmap/Mind";

function ChatLLMcqrs(props) {
  const [selectedDomain, setSelectedDomain] = React.useState('');
  const [selectedSubDomain, setSelectedSubDomain] = React.useState('');
  const [targetClients, setTargetClients] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [history, setHistory] = React.useState([]);
  const [message, setMessage] = React.useState('')
  const [sendDisabled, setSendDisabled] = React.useState(false)
  const {
    isDocked,
    setIsDocked,
    isDraggable,
    setIsDraggable, dockSize,
    setDockSize,
    contentSize,
    setContentSize,
    setmind_map_history,
    isFinish,
    setisFinish,
    setmindmapLoader,
    isFromAchain, setIsFromAchain,
  } = useContext(DockContext);
  const fileRef = React.useRef();
  const [files, setFiles] = useState([]);
  const { subID } = useContext(AuthContext)

  useEffect(() => {
    console.log(history)
  }, [history])
  if (!subID) {
    console.log("sub id not init")
    // window.location.href ="/"
  }
  const [qllm, setQllm] = useState(null);
  const HoverComponent = () => {
    const [isHovered, setIsHovered] = useState(false);
    const style = {
      backgroundColor: 'white',
      color: isHovered ? 'red' : 'black',
      position: 'relative',
      padding: '10px',
    };

    const hoverStyle = {
      position: 'absolute',
      padding: '10px',

      width: "150%",
      right: "100px",
      backgroundColor: 'white',
      color: 'black',
    };
    // console.log(qllm)
    return (
      <div
        style={{ 'color': "#243063" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        sllm
        {isHovered && (

          <pre style={hoverStyle}>{qllm}</pre>
        )}
      </div>
    );
  };

  const fetchTaskConfig = async (userId) => {
    const url = `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot_config/id?user_id=${userId}`;

    try {
      const response = await axios.get(url, {
        headers: {
          'accept': 'application/json'
        }
      });
      setQllm(response.data.data.qllm)
    } catch (error) {
      console.error(`Error fetching task config: ${error}`);

    }
  };
  React.useEffect(() => {
    fetchTaskConfig(subID)
  }, [])

  // Assume this is inside a React Component's function
  const submitFile = async (event) => {
    event.preventDefault();

    let formData = new FormData();

    // Loop over the selected files
    Array.from(event.target.files).forEach((file) => {
      formData.append("files", file);
    });

    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": `multipart/form-data`,
      },
    };


    try {
      // setHistory(history.concat([{ role: 'user', content: message }, { role: 'assistant', content: 'uploading... please wait' }]))
      const response = await axios.post(
        `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/vector_all`,
        formData,
        config
      );
      console.log(response.data.task_id);
      setQllm(response.data.task_id)
      const interval = setInterval(async () => {
        try {
          const responses = await axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/task_status/${response.data.task_id}`, {
            headers: {
              'accept': 'application/json'
            }
          });
          console.log(responses.data)
          setHistory(history.concat([{ role: 'user', content: message }, { role: 'assistant', content: responses.data.status }]))
          // Assuming response.data is the status.
          if (responses.data.status === 'Completed') {

            clearInterval(interval);
            // setHistory(history.concat([{ role: 'user', content: message }, { role: 'assistant', content: 'file uploaded' }]))

            fetchTaskConfig(subID)
            handleSend("START")
          }
        } catch (err) {
          console.error(err);
        }
      }, 20000); // Change this to the desired interval (in milliseconds)





    } catch (error) {
      console.error("An error occured while sending the file", error);
    }
  };

  const handleSubmit = async (e) => {
    setFiles(e.target.files);
    setTimeout(async () => {
      if (fileRef.current.files.length > 0) {
        let formData = new FormData();
        let filesObj = {}; // create an empty object
        let i = 0;
        for (const file of fileRef.current.files) {
          formData.append("file", file);

          // create a new FileReader object
          let reader = new FileReader();
          reader.onload = function (e) {
            // add file and file data into object
            filesObj[`file${i}`] = { file: file, data: e.target.result };
            i++;
          };

          // read the file data
          reader.readAsDataURL(file);
        }
        console.log(filesObj); // after this line, you will see your files and file data    // after this line, you will see your files in one object in console

        console.log("Files: ", formData);
        try {
          // setHistory(
          //   history.concat([
          //     { role: "user", content: message },
          //     { role: "assistant", content: "uploading... please wait" },
          //   ])
          // );
          const response = await fetch(
            `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/vector`,
            {
              method: "POST",
              headers: {
                "content-type": "application/pdf",
              },
              body: formData,
            }
          );

          const result = await response.json();
          console.log(result);
          axios
            .post(
              `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/vector_all`,
              formData
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error)=>{
              console.log(error)
            })
          handleSend("START");
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }, 2000);
  };

  // const [chains, setChains] = React.useState({})
  const chatContainerRef = useRef(null);
  const handleChange = (e) => {
    setMessage(e.target.value)
  }


  var HOST = `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/input/`
  var rag_HOST = `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/rag_input/`

  const toggleDock = () => {
    setIsDocked(!isDocked);
    setisFinish(false)
    setIsDraggable(!isDraggable);
    if (isDocked) {
      setContentSize(100)
    }
    else {
      setContentSize(100 - dockSize * 100)
    }
  };

  const handleReactFlow = (data) => {
    console.log(data)
    let new_agents = {}
    props.setChains(data)
    console.log(JSON.parse(data.chains[0].chain_payload))
    let nodes = JSON.parse(data.chains[0].chain_payload)['nodes'];
    new_agents = JSON.parse(data.chains[0].chain_payload)['agent'];

    console.log(nodes)
    var jsonDataObject = (nodes);
    nodes = jsonDataObject;
    let new_nodes = nodes?.map(node => {
      let colorCode = ""
      if ('Activity_Classification' in (new_agents[node.id])) {
        colorCode = new_agents[node.id]["Activity_Classification"]
        console.log(colorCode)
      }
      if (node.agent !== null) {
        node.data = { acticityName: new_agents[node.id]['Activity Name'], agentName: node.data.label, Activity_Classification: colorCode };
        node.type = 'activityWithAgent'
        node.style = { height: 140, backgroundColor: 'grey', borderWidth: '3px', borderColor: 'red' }
      }
      else {
        node.data = { acticityName: node.data.label, Activity_Classification: colorCode };
        node.type = 'activityNode';
      }
      return node;
    })
    let edges = data.chains[0].chain_payload.edges;
    let new_edges = edges?.map((edge) => {
      edge['type'] = 'smoothstep';
      edge['markerEnd'] = { type: MarkerType.ArrowClosed, };
      return edge;
    })
    props.setEdges(new_edges)
    props.setNodes(new_nodes)
  }
  const [data_test, setData_test] = useState("")
  const handleSend = (start) => {
    console.log(start, "handle send");
    setSendDisabled(true)
    setmindmapLoader(true)
    // setHistory(history.concat([{ role: 'user', content: message + start }, { role: 'assistant', content: 'Thinking...' }]))
    console.log(history)

    axios.post(HOST, {
      "message":
        message + start
    })
      .then(responses => {
        console.log(responses.data)
        setData_test("agent: " + responses.data.message)
        axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/chat_history`)
          .then(async res => {

            await setmind_map_history(res.data)
            // setHistory(res.data);

          }
          )
          .catch((error)=>{
            console.log(error)
          })
        //  setHistory(history.concat([{ role: 'user', content: message }, { role: 'assistant', content: responses.data.message}])); 
        setSendDisabled(false)
      })




      .catch(error => {
        console.log(error)
        setSendDisabled(false);

        // setHistory(history.concat([{ role: 'user', content: message }, { role: 'assistant', content: 'Failed to response please try again' }]))

      })


    setMessage("");


  };
  useEffect(() => {
    setIsFromAchain(false)
    console.log(process.env.REACT_APP_COACH_SERVER)
    axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/chat_history`)
      .then(res => {
        if (Object.keys(res.data).includes("chathistory_chain_payload")) {

          setHistory(res.data.chathistory_chain_payload);
        }
        setmind_map_history(res.data)
      })
      .catch(res => console.log(res))


  }, []);
  const handleClearChat = () => {
    axios.post(HOST, {
      "messages":
        message

    })
      .then(response => setHistory([]))
      .catch(error => console.error(error))
    const newMessage = { domain: selectedDomain, subDomain: selectedSubDomain, targetClients: targetClients, type: 'user' };
    setMessages([...messages, newMessage]);
    setTimeout(() => {
      const apiResponse = { text: `Received your message about ${selectedDomain} - ${selectedSubDomain} for ${targetClients}`, type: 'api' };
      setMessages(messages => [...messages, apiResponse]);
    }, 1000); setSelectedDomain(''); setSelectedSubDomain(''); setTargetClients('');
    setMessage("");
  };
  const handleFinish = () => {
    axios.get(`${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/getAsJSON/${localStorage.getItem("name")}`)
      .then(res => {
        handleReactFlow(res.data)
        setisFinish(true)
      })
      .catch((error)=>{
        console.log(error)
      })

  }
  const handleNewChat = () => {
    // chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    chatContainerRef.current.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!cleareChatMOdal) {
      handleNewChat();
    }
    console.log(history);
  }, [history]);


  // -------------------------------clear chat popup --------------------------

  const [cleareChatMOdal, setcleareChatMOdal] = useState(true)
  function clearChat() {
    setcleareChatMOdal(false);
    axios
      .post(
        `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/chat_history`
      )
      .then((res) => setHistory([]))
      .catch((error)=>{
        console.log(error)
      })
  }


  // -------------------------------clear chat popup ends --------------------------

  return (
    <div
      className={
        isDocked ? "chat-container-docked shadow" : "chat-container shadow"
      }
      style={{ backgroundColor: "red" }}
    >

      {cleareChatMOdal &&
        <div className="clear-chat-modal">
          <div className=" m-3 p-2 rounded">
            <h4>Would you like to continue the chat</h4>
            <div className="d-flex justify-content-evenly ">

              <button className="btn btn-primary m-1" onClick={() => { setcleareChatMOdal(false) }}>continue</button>
              <button className="btn btn-danger m-1" onClick={clearChat}> Clear Chat </button>

            </div>

          </div>
        </div>}

      <div className="chat-header">
        <div className="chat-robot">
          <img
            alt=""
            onClick={handleClearChat}
            width={"15px"}
            className="mx-2"
            style={{ position: "relative", bottom: "3px" }}
          />
          <h1
            onClick={() => {
              axios
                .post(
                  `${process.env.REACT_APP_COACH_SERVER}/api/v1/chatbot/${subID}/chat_history`
                )
                .then((res) => setHistory([]));
            }}
          >
            Coach LLM
          </h1>
        </div>
        <HoverComponent />
        <button className="btn" onClick={toggleDock}>
          {isDocked ? (
            <BsBoxArrowDownLeft style={{ color: "white" }} />
          ) : (
            <BsBoxArrowUpRight style={{ color: "white" }} />
          )}
        </button>
      </div>


      <div className="chat-content bg-e1e7f9" ref={chatContainerRef}>

        {/* {history.map((message, index) => (
          <div
            key={index}
            className={
              `${message.role === "user" ? "user-message-chatLLM" : "api-response"} mb-3`
            }
          >
            {message.role !== "user" && <div className="chat-bubble-icon mr-2 align-items-start" style={{ background: "none" }}>
              <i className={"fas fa-robot"}></i>
            </div>}
            <div className="chat-bubble rounded">
              <pre style={{ color: "#000", marginBottom: ".5rem" }}> {`${message.content}`}</pre>
            </div>
            {message.role === "user" && <div className="chat-bubble-icon ml-2">
              <i className={"fas fa-user"}></i>
            </div>}
          </div>
        ))} */}
      </div>
      <div className="chat-input bg-white " style={{ backgroundColor: "#E1E7F9" }}>
        {/* <div class="chat-input-container pb-2" style={{display:'flex',alignItems:"flex-end"}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
      <input type="file" id="file-upload" ref={fileRef} accept=".pdf" multiple style={{display: 'none'}} onChange={submitFile}/>
      <label htmlFor="file-upload" style={{cursor: 'pointer', color: '#000', fontSize: '2em', marginLeft: '15px'}}>
        <i className="fa fa-upload" aria-hidden="true"></i>
      </label>
    </div>
    <textarea id="chatLLMInput" type="text" placeholder="Type your message..." class="chat-input-box px-2" value={message}
    onChange={e => handleChange(e)} disabled={sendDisabled}   style={{overflow: 'hidden'}}/>
    {!sendDisabled ? 
        <img class="fa fa-paper-plane chat-send-icon " aria-hidden="true"  alt='send' src={sendchat} onClick={()=>{handleSend("")}}></img> 
        :
        <img class="fa fa-paper-plane chat-send-icon" aria-hidden="true" src={sendchat} alt='test'></img>}
</div> */}
        <div class="chat-input-container w-100" style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>

          <textarea id="chatLLMInput" type="text" onInput={(event) => {
            event.target.style.height = "70%"
            event.target.style.height = (event.target.scrollHeight) + "px"
          }} placeholder="Type your message..." class="chat-input-box px-2" value={message}
            onChange={e => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSend("")
              }
            }}
            disabled={sendDisabled} style={{ overflow: 'hidden', flex: 1, marginRight: "6px", height: "70%" }} />
          <div className=" chatbot-text-overlay-pos ">

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: "10px" }} className="w-50">
              <input type="file" id="file-upload" ref={fileRef} accept=".pdf" multiple style={{ display: 'none' }} onChange={submitFile} />
              <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#000', fontSize: '160%', marginLeft: '7px', marginTop: "11px" }}>
                <i className="fa fa-upload" aria-hidden="true"></i>
              </label>
            </div>

            {!sendDisabled ?
              <img className="fa fa-paper-plane chat-send-icon w-50" aria-hidden="true" alt='send' src={sendchat} onClick={() => { handleSend("") }}></img>
              :
              <img className="fa fa-paper-plane chat-send-icon" aria-hidden="true" src={sendchat} alt='test'></img>
            }

          </div>
        </div>
        <div>
          {props.isFinish && (
            <div className="col d-flex align-items-center justify-content-center">
              <button
                onClick={handleFinish}
                className="submitBtnDark btn btn-primary btn-sm m-1"
              >
                <i className="fas fa-check"></i>
                <span>Finish</span>
              </button>
            </div>
          )}
        </div>
      </div>

    </div>
  );
}

export default ChatLLMcqrs;
