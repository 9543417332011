import React, { memo, useContext, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { DockContext } from '../../DockWrapper/DockContext';
import { AuthContext } from '../../Auth/AuthContext';

const CustomNodeInputField = (({ data, isConnectable, id }) => {

  const { nodes, setNodes, nodeIndex, setNodeIndex, edges, setEdges } = useContext(DockContext);
  const { setreactFlowNodeDetailsAChain, reactFlowNodeDetailsAChain } = useContext(AuthContext);

  const [activityDetails, setactivityDetails] = useState({
    activityName: "",
    activityType: ""
  })

  const handleSubmit = (e) => {

    let new_node = {
      "id": id,
      "agent": null,
      "position": {
        "x": 150,
        "y": 105
      },
      "data": {
        "isDark": true,
        "acticityName": activityDetails.activityName,
        "agentName": "",
        "Activity_Classification": activityDetails.activityType,
        "agent_check": false
      },
      "style": {
        "color": "#333",
        "borderColor": "#666",
        "background": "#f5f5f5",
        "backgroundColor": "transparent",
        "border": "none",
        "width": "17%"
      },
      "width": 140,
      "height": 217,
      "selected": false,
      "positionAbsolute": {
        "x": 150,
        "y": 105
      },
      "dragging": false,
      "type": "activityNode"
    }

    let replaced_nodes = nodes.map((node) => {
      if (node.id == id) {

        return new_node
      } else {
        return node
      }
    })
    console.log(replaced_nodes, "265");
    console.log(nodes, "265-og");
    let node_obj = {
      node: null,
      x: 0,
      y: 0,
      clicked: false,
      nodeID: 0,
      data: {}
    }
    setreactFlowNodeDetailsAChain(node_obj)
    setNodes(replaced_nodes)





  }

  return (
    <div style={{ background: "rgb(34, 41, 73)", borderRadius: "0.7rem", border: "2px solid rgb(238, 146, 231)", color: "white", fontWeight: "800", padding: "15px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          backgroundColor: "#ADC2FF",
          border: "none",
          height: "10px",
          width: "10px",
        }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />


      <div class="mb-3" >
        <label for="exampleFormControlInput1" class="form-label">Activity Name</label>
        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" onChange={(e) => setactivityDetails({ ...activityDetails, activityName: e.target.value })} value={activityDetails.activityName} />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Type</label>
        <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search..." onChange={(e) => setactivityDetails({ ...activityDetails, activityType: e.target.value })} value={activityDetails.activityType} />
        <datalist id="datalistOptions"  >
          <option value="Recognition" />
          <option value="Predictive" />
          <option value="Generative" />
        </datalist>
      </div>
      <div className='mb-3 d-flex justify-content-center align-items-center'>
        <button className='btn btn-primary cancelBtnDark' onClick={handleSubmit}>
          Submit
        </button>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{
          backgroundColor: "#ADC2FF",
          border: "none",
          height: "10px",
          width: "10px",
        }}
        isConnectable={isConnectable}
      />
    </div>
  );
});


export default CustomNodeInputField