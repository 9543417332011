import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ProgressBar from "./ProgressBar";
import "./uploadFiles.css";

const FileInput = ({
  competency_data,
  setcompetency_data,
  setisedit,
  isedit,
}) => {
  const [progress, setProgress] = useState(0);
  const [isupload, setisupload] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileslist, setuploadedFileslist] = useState(uploadedFiles);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // setUploadedFiles(acceptedFiles);
      const filename = []
      acceptedFiles.map((file)=>{
        filename.push(file.name)
      })
      console.log(filename);
      setUploadedFiles(filename);
    },
  });

  const handleUpload = async () => {
    for (let progress = 0; progress <= 100; progress += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setProgress(progress);
    }
    var obj = {
      uploaded_files: uploadedFiles,
    };

    var data = {
      ...competency_data,
      ...obj,
    };
    console.log(data,"upload");
    setcompetency_data({
      ...competency_data,
      ...obj,
    });
    var edit = [...isedit];
    edit[edit.length - 1] = true;
    setisedit([...isedit]);
  };
  const removeFile = (filename) => {
    console.log(filename, "filename from remove");
    console.log(uploadedFiles);
    const newList = uploadedFiles.filter((file) => file != filename);
    console.log(newList);
    console.log(newList.length);
    if(newList.length == 0){
      setisupload(false)
      setProgress(0)
      
    }else{

      setUploadedFiles(newList);
    }

  };
  useEffect(() => {
    console.log(uploadedFileslist);
  }, [uploadedFileslist]);

  useEffect(() => {
    console.log(uploadedFiles, "uploadeed files");
  }, [uploadedFiles]);

  return (
    <div className="cardOuter">
      <div className="cardInner">
        <div className="cardHeading">
          <h2 className="headingText">Upload Files</h2>
        </div>
        <label>Upload File</label>

        <div {...getRootProps()} className="upload-area">
          <input {...getInputProps()} />
          {/* <p>Drag and drop files here or click to browse.</p> */}
          <ul>
            {uploadedFileslist.map((file) => {
              return <li>{file.name}</li>;
            })}
          </ul>
        </div>

        <div className="upload-div">
          <button
            onClick={() => {
              handleUpload();
              setisupload(true);
              setuploadedFileslist([]);
            }}
            className="btn-primary upload"
          >
            Upload
          </button>
          {/* <progress value={progress} max="100" /> */}
        </div>

        {isupload &&
          uploadedFiles.map((file, index) => {
            return (
              <>
              
                <ProgressBar
                  file={file}
                  progress={progress}
                  removeFile={removeFile}
                />
                
              </>
            );
          })}
      </div>
    </div>
  );
};

export default FileInput;
