import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  recommendedModelsData,
  newData,
  completedData,
  inProgressData,
} from "./Data/dummyData";
import { fetchChartData } from"./Data/chartData";
import MoHeader from "./MoHeader";
import "./TrainingModel.css";
import { Button, Card, Form, Nav, Table } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import Papa from "papaparse";
import { AuthContext } from "../Auth/AuthContext";
import FineTuningTemplate from "../../assets/templates/Medical_Data-Template.csv";
import RewardTemplate from "../../assets/templates/Reward_Model_data_Template.csv";
import CustomTable from "../Table/CustomTable";
import { TableData, TableColumn, PPOTableData } from "../ModelOrchestrator/FineTunining/DB";
import {  Bar, Line } from "react-chartjs-2";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import CustomTableNew from "./Data/CustomTable";


const TraininRewardPPO = ({ darkMode, handleToggle }) => {
  const { accessToken } = useContext(AuthContext);
  const [TableDataLocal, setTableDataLocal] = useState(TableData);
 
  const [index, setindex] = useState(1);
  const [TableColumnLocal, setTableColumnLocal] = useState(TableColumn);
 

  let templateSelector = {
    "/finetuning": FineTuningTemplate,
    "/rewardModel": RewardTemplate,
    "/rewardppo": RewardTemplate,
  };
  let location = useLocation();
  let path = location.pathname;
  let { id } = useParams();
  const {knowledgeWorkDetails, selectedItems,knowledgeWorkName,hyperparameters,hyperparameterObjectData } = location.state || {};
  console.log(selectedItems, "hypos: ", hyperparameters);
  const knowledgeWorkNameNew = selectedItems.knowledgeWorkName;

  // Provide default values if selectedItems is undefined or does not contain the expected data
  const selectedModelIds = selectedItems.recommendedModels || [];
  const selectedAgent = (selectedItems?.agents || [])[0] || {}; // Extract first agent
  const selectedAgentTitle = selectedAgent.title || "";

  // Ensure `agentsData` and `recommendedModelsData` are defined and have data
  
  let summaryData = newData.find((item) => item.id === Number(id));
  const sections = [
    {
      name: "Supervised Fine-tuning",
      steps: [
        "Upload Data",
        "Basic Hyperparameters",
        "Advanced Hyperparameters",
        "Peft Parameters",
        "Evaluate SFT",
        "Evaluate Model Generative Quality",
        
      ],
    },
    {
      "name": "Reward Model",
      steps: [
        "Upload Data",
        "Hyperparamters",
        "PEFT Parameters",
      ]
    },
    {
      "name": "PPO Model",
      steps: [
        "Upload Data",
        "Model Paramters",
        "Evaluate Model",
      ]
    },
  ];

  const [currentSection, setCurrentSection] = useState(1);
  const [currentStep, setCurrentStep] = useState(sections.map(() => 0));
  const [currentModelId, setCurrentModelId] = useState(1);
  const [temperature, setTemperature] = useState(0);
  const [topP, setTopP] = useState(0.43);
  const [topK, setTopK] = useState(1);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const ws = useRef(null);
  const endOfMessagesRef = useRef(null);
  const [tokensToOutput, setTokensToOutput] = useState(256);
  const handleSliderChange = (sliderName, value) => {
    switch (sliderName) {
      case "temperature":
        setTemperature(value);
        break;
      case "topP":
        setTopP(value);
        break;
      case "topK":
        setTopK(value);
        break;
      case "tokensToOutput":
        setTokensToOutput(value);
        break;
      default:
        break;
    }
  };
  const [selectedModels, setSelectedModels] = useState(recommendedModelsData.filter((item) =>
    selectedModelIds.includes(item.id))); 
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize WebSocket connection
    ws.current = new WebSocket('ws://multimodel.eastus.cloudapp.azure.com:8000/ws/chat');

    // Handle incoming messages
    ws.current.onmessage = (event) => {
      // Check if the message is in JSON format
      try {
        const data = JSON.parse(event.data);
        
        // Handle the received message based on the format
        if (data.message) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: data.message, sender: 'ai' }
          ]);
        } else {
          console.error('Unexpected message format:', data);
        }
      } catch (error) {
        // Handle cases where the message is not valid JSON
        console.error('Error parsing message data:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: event.data, sender: 'ai' } // Fallback to plain text
        ]);
      }
    };

    // Handle WebSocket errors
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);
  useEffect(() => {
    // Scroll to the bottom whenever messages change
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const sendMessage = () => {
    if (message.trim()) {
      // Send message to the WebSocket server
      ws.current.send(JSON.stringify({ message }));
      setMessages((prevMessages) => [...prevMessages, { text: message, sender: 'user' }]);
      setMessage('');
    }
  };
 
  useEffect(() => {
    if (currentStep.length === 0) {
      setCurrentStep(new Array(selectedModelIds.length).fill(0));
    }
  }, [selectedModelIds]);

  const handleNextStep = (modelId) => {
    console.log("handleNextStep triggered with modelId:", modelId);
  
    setCurrentStep((prevSteps) => {
      console.log("prev steps: ", prevSteps);
      const newSteps = [...prevSteps];

        // Close the existing WebSocket connection
        // if (ws.current) {
        //   ws.current.close();
        //   setMessages([])
        // }
        
        // // Reinitialize the WebSocket connection
        // ws.current = new WebSocket('ws://localhost:8000/ws/chat');
        
        // // Handle incoming messages
        // ws.current.onmessage = (event) => {
        //   try {
        //     const data = JSON.parse(event.data);
        //     if (data.message) {
        //       setMessages((prevMessages) => [
        //         ...prevMessages,
        //         { text: data.message, sender: 'ai' }
        //       ]);
        //     } else {
        //       console.error('Unexpected message format:', data);
        //     }
        //   } catch (error) {
        //     console.error('Error parsing message data:', error);
        //     setMessages((prevMessages) => [
        //       ...prevMessages,
        //       { text: event.data, sender: 'ai' }
        //     ]);
        //   }
        // };
        
        // // Handle WebSocket errors
        // ws.current.onerror = (error) => {
        //   console.error('WebSocket error:', error);
        // };
      const currentSectionSteps = sections[currentSection].steps.length;
  
      const getStepName = (stepIndex) => {
        console.log(stepIndex);
        return sections[currentSection].steps[stepIndex] || "Unknown Step";
      };
      // Determine the data to send based on the current step
      const getDataForStep = (step) => {
        const stepName = getStepName(step);
        console.log("Getting data for step:", step);
        switch (step) {
          case 0: // Step 2 (index 1)
            return {
              knowledgeWorkDetails: {
                stepName,
                ...knowledgeWorkDetails,
                hyperparameters: {
                  output_dir: "./results",
                  evaluation_strategy: "steps",
                  eval_steps: 500,
                  do_eval: true,
                  log_level: "debug",
                  optim: "paged_adamw_32bit",
                  save_steps: 25,
                  logging_dir: "./logs",
                  logging_steps: 100,
                  learning_rate: 2.00e-05,
                  per_device_train_batch_size: 4,
                  per_device_eval_batch_size: 4,
                  num_train_epochs: 3,
                  weight_decay: 0.01,
                  save_total_limit: 2,
                  packing: false,
                  max_seq_length: "max_seq_length",
                  load_best_model_at_end: true,
                  metric_for_best_model: "accuracy",
                  bf16: true,
                  tf32: true,
                  gradient_checkpointing: true,
                  gradient_accumulation_steps: 4,
                  max_grad_norm: 0.3,
                  lr_scheduler_type: "reduce_lr_on_plateau",
                  auto_find_batch_size: true,
                  report_to: "none",
                  dataloader_pin_memory: true,
                  distributed_training: true,
                  deepspeed: "./deepspeed_config.json"
                }
              },
            };
          case 1: // Step 3 (index 2)
            return {
              knowledgeWorkDetails: {
                stepName,
                ...knowledgeWorkDetails,
                peft: {
                  lora_r: 8,
                  lora_alpha: 32,
                  lora_dropout: 0.1,
                  target_modules: ["q_proj", "k_proj", "v_proj", "o_proj", "gate_proj", "up_proj", "down_proj"],
                  Bias: "yes",
                  task_type: null,
                  inference_mode: true,
                  rank: 4,
                  alpha: 64,
                  dropout: 0.2,
                  use_bias: "True"
                }
              },
            };
          case 2: // Step 4 (index 3)
            return {
              knowledgeWorkDetails: {
                ...knowledgeWorkDetails,
                additionalInfo: 'Data specific to step 4',
              },
              hyperparameters: { /* Data specific to step 4 */ }
            };
          case 3: // Step 5 (index 4)
            return {
              knowledgeWorkDetails: {
                ...knowledgeWorkDetails,
                additionalInfo: 'Data specific to step 5',
              },
              hyperparameters: { /* Data specific to step 5 */ }
            };
          default:
            return {
              knowledgeWorkDetails,
              hyperparameters
            };
        }
      };
  
      // Function to make the API call
      const updateJson = async (data) => {
        console.log("Making API call with data:", data);
        try {
          const response = await axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log("API call successful:", response.data);
        } catch (error) {
          console.error("Error making API call:", error);
        }
      };
  
      // Check the current step and handle API calls
      if (newSteps[currentSection] === 0) { // User is in Step 2
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      } else if (newSteps[currentSection] === 1) { // User is in Step 3
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      } else if (newSteps[currentSection] === 2) { // User is in Step 4
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      } else if (newSteps[currentSection] === 3) { // User is in Step 5
        const stepData = getDataForStep(newSteps[currentSection]);
        console.log("Step data to be sent:", stepData);
        updateJson(stepData);
      }
  
      if (newSteps[currentSection] < currentSectionSteps - 1) {
        // Move to the next step within the current section
        newSteps[currentSection] += 1;
      } else if (currentSection < sections.length - 1) {
        // Move to the next section if at the end of the current section
        setCurrentSection(currentSection + 1);
        // Move to the first step of the next section
        newSteps[currentSection + 1] = 0;
      }
  
      return newSteps;
    });
  };


  const handlePrevStep = async () => {
    setCurrentStep((prevSteps) => {
      const newSteps = [...prevSteps];
      
      if (newSteps[currentSection] > 0) {
        // Move to the previous step within the current section
        newSteps[currentSection] -= 1;
      } else if (currentSection > 0) {
        // Move to the previous section if at the start of the current section
        setCurrentSection(currentSection - 1);
        // Move to the last step of the previous section
        newSteps[currentSection - 1] = sections[currentSection - 1].steps.length - 1;
      }
  
      // Perform API call with current step details
      const stepName = sections[currentSection].steps[newSteps[currentSection]];
  
      const requestBody = {
        knowledgeWorkDetails: {
          stepName,
          // Include other necessary details here
          hyperparameters: {
            // Include model hyperparameters here
          },
        },
      };
  
      axios.post('http://multimodel.eastus.cloudapp.azure.com:8000/api/update-json', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          console.log('Success:', response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  
      return newSteps;
    });
  };
  
  const isPrevButtonDisabled = () => {
    return currentSection === 1 && currentStep[currentSection] === 0;
  };


  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePPO, setSelectedFilePPO] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]); // State to store CSV data
  const [csvDataPPO, setCsvDataPPO] = useState([]); // State to store CSV data
  const [completed, setCompleted] = useState(false);
  const [logs,setLogs] = useState("");
  const [isFinetuningStarted, setIsFinetuningStarted] = useState(false)
  const [isPPOFinetuningStarted, setIsPPOFinetuningStarted] = useState(false)
  const [isTrainingStarted, setIsTrainingStarted] = useState(false)
  const [isPPOTrainingStarted, setIsPPOTrainingStarted] = useState(false)
  const [isTrainingCompleted, setIsTrainingCompleted] = useState(false)
  const [isTrainingPPOCompleted, setIsTrainingPPOCompleted] = useState(false)
  const [progress, setProgress] = useState(0);
  const [displayedData, setDisplayedData] = useState(newData);
  const [hyperparameterTableData, setHyperparameterTableData] = useState(TableDataLocal[1])
  const [peftTableData, setPeftTableData] = useState(TableDataLocal[3])
  const [ppoTableData, setPPOTableData] = useState(PPOTableData[1])
  const [ppoObjectData,setPpoObjectData] = useState({})
  const [RMhyperparameterObjectData, setRMHyperparameterObjectData] = useState({})
  const [peftObjectData, setPeftObjectData] = useState({})
  const[modelPath, setModelPath] = useState("");
  const [learningRateChartData, setLearningRateChartData] = useState();
  const [metricsError, setMetricsError] = useState(true)
  const [lossChartData, setLossChartData] = useState();
  const [ppoModelUrl, setPPOModelUrl] = useState("");


  const handlehyperparameterTableDataChange = (transformedData) => {
    console.log("Updated Hyperparameters Table Data:", transformedData);
    const result = transformedData.reduce((acc, item) => {
      const parameterName = item["Parameter Name"].value;
      const inputValue = item["Input"].value;

      // Add the parameter name and input value to the result object
      acc[parameterName] = inputValue;
      return acc;
    }, {});
    setHyperparameterTableData(transformedData);
    setRMHyperparameterObjectData(result)
    // Optionally, send updatedData to API here
    // sendToApi(updatedData);
  };
  const handlePPOhyperparameterTableDataChange = (transformedData) => {
    console.log("Updated Hyperparameters Table Data:", transformedData);
    const result = transformedData.reduce((acc, item) => {
      const parameterName = item["Parameter Name"].value;
      const inputValue = item["Input"].value;

      // Add the parameter name and input value to the result object
      acc[parameterName] = inputValue;
      return acc;
    }, {});
    setPPOTableData(transformedData);
    setPpoObjectData(result)
    // Optionally, send updatedData to API here
    // sendToApi(updatedData);
  };
  const handlePeftTableDataChange = (transformedData) => {

    console.log("Updated PEFT Table Data:", transformedData);
    const result = transformedData.reduce((acc, item) => {
      const parameterName = item["Parameter Name"].value;
      const inputValue = item["Input"].value;

      // Add the parameter name and input value to the result object
      acc[parameterName] = inputValue;
      return acc;
    }, {});
    
    // Log the result to check
    setPeftObjectData(result)
  console.log("Formatted Result Object:", result);
    setModelPath(transformedData.output_dir)
    setPeftTableData(transformedData);
    // Optionally, send updatedData to API here
    // sendToApi(updatedData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file.name);
  };

  const handleUpload = () => {
    if (selectedFile) {
        setLoading(true);

        // Parse CSV file
        Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
                console.log("Parsed CSV data: ", results.data);
                setCsvData(results.data.slice(0, 5)); // Update state with CSV data
            },
            error: (error) => {
                console.error("Error parsing CSV: ", error);
            },
            skipEmptyLines: true,
        });

        // Prepare FormData
        const formData = new FormData();
        formData.append("file", selectedFile);

        // API URL with filename and description
        const apiUrl = `http://alchemist.eastus.cloudapp.azure.com:3005/model/dataset?filename=${encodeURIComponent(selectedFile.name)}&description=${encodeURIComponent('File upload description here')}&data_type='rm'`;

        axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                console.log("File uploaded successfully:", response.data);
                localStorage.setItem("dataset_path", response.data.dataset_path);
                localStorage.setItem("datasetid", response.data.id);
                
                setCompleted(true);
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        console.log("Please select a file to upload.");
    }
  }
  const handleFileChangePPO = (event) => {
    const file = event.target.files[0];
    setSelectedFilePPO(file);
    console.log(file.name);
  };

  const handleUploadPPO = () => {
    if (selectedFilePPO) {
        setLoading(true);

        // Parse CSV file
        Papa.parse(selectedFilePPO, {
            header: true,
            complete: (results) => {
                console.log("Parsed CSV data: ", results.data);
                setCsvDataPPO(results.data.slice(0, 5)); // Update state with CSV data
            },
            error: (error) => {
                console.error("Error parsing CSV: ", error);
            },
            skipEmptyLines: true,
        });

        // Prepare FormData
        const formData = new FormData();
        formData.append("file", selectedFilePPO);

        // API URL with filename and description
        const apiUrl = `http://alchemist.eastus.cloudapp.azure.com:3005/model/dataset?filename=${encodeURIComponent(selectedFilePPO.name)}&description=${encodeURIComponent('File upload description here')}&data_type='rm'`;

        axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                console.log("File uploaded successfully:", response.data);
                localStorage.setItem("dataset_path", response.data.dataset_path);
                localStorage.setItem("datasetid", response.data.id);
                
                setCompleted(true);
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        console.log("Please select a file to upload.");
    }
  }
  const [tableData, setTableData] = useState([
    // Initialize with default data or empty objects
  ]);
  const [updateDataJson, setUpdateDataJson] = useState({});
  const updateData = (dataIndex, column, type, value, key) => {
    console.log("Update function called with parameters:", { dataIndex, column, type, value, key });
    console.log("Current TableDataLocal:", TableDataLocal);
    console.log("Current updateDataJson:", updateDataJson);
  
    // Ensure dataIndex is within bounds
    if (dataIndex >= 0 && dataIndex < TableDataLocal.length) {
      const row = TableDataLocal[dataIndex];
  
      console.log("Row data at index:", row);
  
      // Ensure row is an array and not empty
      if (Array.isArray(row) && row.length > 0) {
        // Find the correct cell in the row
        const cellIndex = row.findIndex(cell => cell.hasOwnProperty(column));
  
        if (cellIndex !== -1) {
          const cell = row[cellIndex];
          // Make a copy of the table data and update
          const updatedTableData = [...TableDataLocal];
          const updatedRow = [...row];
  
          updatedRow[cellIndex] = {
            ...cell,
            [column]: { ...cell[column], value }
          };
  
          updatedTableData[dataIndex] = updatedRow;
  
          // Update state
          setTableDataLocal(updatedTableData);
          
          // Update JSON data
          const updatedJson = { ...updateDataJson };
          updatedJson[key] = value;
          setUpdateDataJson(updatedJson);
  
          console.log("Updated TableDataLocal:", updatedTableData);
          console.log("Updated updateDataJson:", updatedJson);
        } else {
          console.error("Column not found in row:", { column, row });
        }
      } else {
        console.error("Invalid row data:", row);
      }
    } else {
      console.error("Invalid dataIndex in TableDataLocal:", dataIndex);
    }
  };
  
  
  useEffect(() => {
    console.log("TableData has changed:", TableData);
  }, [TableData]);
  const [lastId, setLastId] = useState(8);
 
  
  
  

  const [hyperparametersUpdated, setHyperparametersUpdated] = useState({});
  // const handleStartFineTuning = async () => {
  //   try {
  //     // Increment ID
  //     const newId = lastId + 1;
  
  //     // Create configuration with new ID
  //     const hyperparameterConfig = createHyperparameterConfig(TableDataLocal[1], newId);
  
  //     // Log to verify the config
  //     console.log('Hyperparameter Config:', hyperparameterConfig);
  
  //     const response = await axios.post('http://alchemist.eastus.cloudapp.azure.com:3005/model/hyperparameters/', hyperparameterConfig, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  
  //     // Log response for debugging
  //     console.log('API Response:', response.data);
  
  //     // Update state with the new ID
  //     setLastId(newId);
  
  //     // Handle successful response here, e.g., show a success message or navigate to another page
  //   } catch (error) {
  //     // Handle errors here
  //     console.error('API Error:', error.response ? error.response.data : error.message);
  //   }
  // };
  const defaultPPOParameters = 
    {
      model_name_or_path: "huggyllama/llama-7b",
      // model_type: "Generative",
      learning_rate: parseFloat(ppoObjectData.learning_rate),
      // log_with: ppoObjectData.log_with,
      batch_size: parseInt(ppoObjectData.batch_size),
      mini_batch_size: parseInt(ppoObjectData.mini_batch_size),
      gradient_accumulation_steps: parseInt(ppoObjectData.gradient_accumulation_steps),
      optimize_cuda_cache: ppoObjectData.optimize_cuda_cache,
      early_stopping: ppoObjectData.early_stopping,
      target_kl: parseInt(ppoObjectData.target_kl),
      ppo_epochs: parseInt(ppoObjectData.ppo_epochs),
      // Seed: parseInt(ppoObjectData.Seed),
      // rm_adapter:
      query_dataset: localStorage.getItem('dataset_path'),
      max_seq_length:128,
      output_dir:"",
      use_peft:true,
      use_safetensors:true,
      is_peft_model:true
    }
    
    
  
  const defaultHyperparameters = {
    model_name_or_path: "meta-llama/Meta-Llama-3-8B-Instruct",
    // model_type: "Generative",
    // task: " ",
    // domain: " ",
    max_seq_length: 128,
    dtype: "bfloat16",
    output_dir: RMhyperparameterObjectData.output_dir,
    report_to: RMhyperparameterObjectData.report_to,
    learning_rate: parseFloat( RMhyperparameterObjectData.learning_rate),
    lr_scheduler_type: RMhyperparameterObjectData.lr_scheduler_type,
    num_train_epochs: parseInt(RMhyperparameterObjectData.num_train_epochs),
    per_device_train_batch_size: parseInt( RMhyperparameterObjectData.per_device_train_batch_size),
    per_device_eval_batch_size: parseInt( RMhyperparameterObjectData.per_device_eval_batch_size),
    gradient_accumulation_steps: parseInt( RMhyperparameterObjectData.gradient_accumulation_steps),//4
    optim: RMhyperparameterObjectData.optim,
    logging_steps: parseInt(RMhyperparameterObjectData.logging_steps),//1
    save_strategy: "epoch",
    evaluation_strategy: RMhyperparameterObjectData.evaluation_strategy,
    max_grad_norm: parseFloat( RMhyperparameterObjectData.max_grad_norm),//0.3
    warmup_ratio: 0.03,
    bf16: RMhyperparameterObjectData.bf16,
    tf32: RMhyperparameterObjectData.tf32,
    torch_dtype: "bfloat16",
    fp16: false,
    gradient_checkpointing: RMhyperparameterObjectData.gradient_checkpointing,//true
    seed: 1,
    logging_dir: RMhyperparameterObjectData.logging_dir,
    load_in_4bit: false,
    load_in_8bit: false,
    // bnb_4bit_compute_dtype: "torch.bfloat16",
    // bnb_4bit_quant_type: "nf4",
    use_bnb_nested_quant: true,
    // bnb_4bit_quant_storage: "torch.bfloat16",
    use_peft: true,
    lora_r: parseInt(peftObjectData.lora_r, 10),
    lora_alpha: parseInt(peftObjectData.lora_alpha),
    lora_dropout: parseFloat(peftObjectData.lora_dropout),
    lora_target_modules: ["q_proj", "k_proj", "v_proj", "o_proj", "gate_proj", "up_proj", "down_proj"],
    // Bias: peftObjectData.Bias,
    // task_type: peftObjectData.task_type,
    // inference_mode: peftObjectData.inference_mode,
    dataset_path: localStorage.getItem('dataset_path'),
    dataset_text_field: "text",
    dataset_num_proc: 1,
    // packing: false,
    // args: "None",
    // data_collator: "None",
    attn_implementation: null,
    max_steps:10,
    eval_steps:parseInt(RMhyperparameterObjectData.eval_steps,2),
    save_steps:2,
    save_total_limit:4,
    warmup_steps:2

};
  
const handleStartFineTuning = async (hyperparameters = {}) => {
  try {
      // Create hyperparameter configuration

      // Prepare the request payload
      const requestBody = {
          "service_name": "RM",
          param_json: defaultHyperparameters,
          dataset_location: localStorage.getItem('dataset_path')
      };

      // Log to verify the request body
      console.log('Request Body:', requestBody);

      // Send the initial request
      const response = await axios.post('http://alchemistmodel.eastus.cloudapp.azure.com:8002/request_training/', requestBody, {
          headers: {
              'Content-Type': 'application/json'
          }
      }, 
      setIsFinetuningStarted(true)
    );

      // Log response for debugging
      console.log('API Response:', response.data);
      localStorage.setItem('request_id', response.data["request_id"])

      // Polling function to get container logs
      const pollLogs = async () => {
        try {
            const requestBody = {
                service_name: "RM",
                request_id: localStorage.getItem('request_id')
            };
    
            const logResponse = await axios.post('http://alchemistmodel.eastus.cloudapp.azure.com:8002/container-logs/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            // Assuming logs are returned as a plain string
            const logs = logResponse.data.logs; // Directly use the string logs
    
            console.log('Log Response:', logResponse.data);
            setIsTrainingStarted(true);
            setLogs(logs); // Store the logs
            setProgress(logResponse.data.percentage); // Update progress
    
            // Prepare log data for download
            const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); // Replace invalid characters for filename
            const filename = `RM_logs_${timestamp}.log`;
            const blob = new Blob([logs], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
    
            // Check if the training is complete
            if (logResponse.data.percentage === 100) {
                console.log('Training complete');
                
                setIsTrainingStarted(false);
                setIsTrainingCompleted(true);
                setIsFinetuningStarted(false);
    
                // Trigger the download of the log file
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
    
                return; // Exit polling
            }
    
            // Continue polling every 2 seconds
            setTimeout(pollLogs, 2000);
        } catch (error) {
            console.error('Polling Error:', error.response ? error.response.data : error.message);
            // Optionally handle error, e.g., stop polling or notify the user
        }
    };
    

    

      // Start polling
      pollLogs();

      // Update state with the new Id or handle other success logic

  } catch (error) {
      // Handle errors from the initial request
      console.error('API Error:', error.response ? error.response.data : error.message);
  }
};
const handleStartPPOFineTuning = async (hyperparameters = {}) => {
     
  try {
      // Create hyperparameter configuration
      // const hyperparameterConfig = createHyperparameterConfig(hyperparameters);

      // Prepare the request payload
      const requestBody = {
          "service_name": "PPO",
          param_json: defaultPPOParameters,
          dataset_location: localStorage.getItem('dataset_path')
      };

      // Log to verify the request body
      console.log('Request Body:', requestBody);

      // Send the initial request
      const response = await axios.post('http://alchemistmodel.eastus.cloudapp.azure.com:8002/request_training/', requestBody, {
          headers: {
              'Content-Type': 'application/json'
          }
      },
      setIsPPOFinetuningStarted(true)
    );
     

      // Log response for debugging
      console.log('API Response:', response.data);
      localStorage.setItem('request_id', response.data["request_id"]);
      setIsPPOFinetuningStarted(true);

      // Polling function to get container logs
      let trainingCompletedTime = null;

      const pollLogs = async () => {
        try {
            const requestBody = {
                service_name: "PPO",
                request_id: localStorage.getItem('request_id')
            };
    
            // Make API request
            const logResponse = await axios.post('http://alchemistmodel.eastus.cloudapp.azure.com:8002/container-logs/', requestBody, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const logs = logResponse.data.logs;
            const metrics = logResponse.data.metrics;
            const percentage = logResponse.data.percentage;
            const model_url = logResponse.data.model_url;
            setPPOModelUrl(model_url)
    
            // Update state with the logs and progress
            setLogs(logs);
            setProgress(percentage);
            setIsPPOTrainingStarted(true);
    
            const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); // Replace invalid characters for filename
            const filename = `SFT_logs_${timestamp}.log`;
            const blob = new Blob([logs], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
    
            if (model_url != "") {
                if (!trainingCompletedTime) {
                    console.log('Training complete');
    
                    // Store the time when training completed
                    trainingCompletedTime = Date.now();
                    console.log('Training completed at:', trainingCompletedTime);
    
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
    
                    if (metrics && metrics.length > 0) {
                        const steps = metrics.map(log => log.step);
                        const learningRates = metrics.map(log => log.learning_rate);
                        const losses = metrics.map(log => log.loss);
    
                        // Prepare data for charts
                        const learningRateChartData = {
                            labels: steps,
                            datasets: [
                                {
                                    label: 'Learning Rate',
                                    data: learningRates,
                                    borderColor: 'rgba(75,192,192,1)',
                                    backgroundColor: 'rgba(75,192,192,0.2)',
                                    fill: false
                                }
                            ]
                        };
    
                        const lossChartData = {
                            labels: steps,
                            datasets: [
                                {
                                    label: 'Loss',
                                    data: losses,
                                    borderColor: 'rgba(255,99,132,1)',
                                    backgroundColor: 'rgba(255,99,132,0.2)',
                                    fill: false
                                }
                            ]
                        };
    
                        setLearningRateChartData(learningRateChartData);
                        setLossChartData(lossChartData);
                        setMetricsError(false); // Clear any previous errors
                    } else {
                        // If metrics are null or empty
                        setLearningRateChartData(null);
                        setLossChartData(null);
                        setMetricsError(true);
                    }
                }
    
                // Continue polling every 2 seconds for 10 seconds after completion
                const now = Date.now();
                const elapsedTime = now - trainingCompletedTime;
                console.log('Elapsed Time:', elapsedTime);
    
                if (elapsedTime <= 10000) { // 10 seconds
                    console.log('Continuing polling...');
                    setTimeout(pollLogs, 2000);
                    if (metrics && metrics.length > 0) {
                        const steps = metrics.map(log => log.step);
                        const learningRates = metrics.map(log => log.learning_rate);
                        const losses = metrics.map(log => log.loss);
    
                        // Prepare data for charts
                        const learningRateChartData = {
                            labels: steps,
                            datasets: [
                                {
                                    label: 'Learning Rate',
                                    data: learningRates,
                                    borderColor: 'rgba(75,192,192,1)',
                                    backgroundColor: 'rgba(75,192,192,0.2)',
                                    fill: false
                                }
                            ]
                        };
    
                        const lossChartData = {
                            labels: steps,
                            datasets: [
                                {
                                    label: 'Loss',
                                    data: losses,
                                    borderColor: 'rgba(255,99,132,1)',
                                    backgroundColor: 'rgba(255,99,132,0.2)',
                                    fill: false
                                }
                            ]
                        };
    
                        setLearningRateChartData(learningRateChartData);
                        setLossChartData(lossChartData);
                        setMetricsError(false); // Clear any previous errors
                        setIsTrainingPPOCompleted(true);
                        setIsPPOTrainingStarted(false)
                    } else {
                        // If metrics are null or empty
                        setLearningRateChartData(null);
                        setLossChartData(null);
                        setMetricsError(true);
                    }
    
                } else {
                    console.log('Stopping polling after 10 seconds');
                    setIsPPOFinetuningStarted(false);
                    setIsPPOTrainingStarted(false);
                }
    
            } else {
                // Continue polling every 2 seconds
                setTimeout(pollLogs, 2000);
            }
    
        } catch (error) {
            if (error.response && error.response.status === 500) {
                // Handle 500 error by retrying
                console.error('Server Error 500, retrying...', error.message);
                setTimeout(pollLogs, 2000); // Retry after 2 seconds
            } else {
                // Handle other errors
                console.error('Polling Error:', error.response ? error.response.data : error.message);
                setIsPPOTrainingStarted(false); // Stop any ongoing process
                setIsPPOFinetuningStarted(false);
            }
        }
    };
    

      // Start polling
      pollLogs();
    
  } catch (error) {
      // Handle errors from the initial request
      console.error('API Error:', error.response ? error.response.data : error.message);
  }
};
// const handleStartPPOFineTuning = async (hyperparameters = {}) => {
//   try {
//       // Create hyperparameter configuration

//       // Prepare the request payload
//       const requestBody = {
//           "service_name": "PPO",
//           param_json: defaultPPOParameters,
//           dataset_location: localStorage.getItem('dataset_path')
//       };

//       // Log to verify the request body
//       console.log('Request Body:', requestBody);

//       // Send the initial request
//       const response = await axios.post('http://finetune.eastus.cloudapp.azure.com:8002/request_training/', requestBody, {
//           headers: {
//               'Content-Type': 'application/json'
//           }
//       }, 
//       setIsFinetuningStarted(true)
//     );

//       // Log response for debugging
//       console.log('API Response:', response.data);
//       localStorage.setItem('request_id', response.data["request_id"])

//       let trainingCompletedTime = null;
//       const pollLogs = async () => {
//         try {
//             const requestBody = {
//                 service_name: "PPO",
//                 request_id: localStorage.getItem('request_id')
//             };

//             // Make API request
//             const logResponse = await axios.post('http://finetune.eastus.cloudapp.azure.com:8002/container-logs/', requestBody, {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             });

//             const logs = logResponse.data.logs;
//             const metrics = logResponse.data.metrics;
//             const percentage = logResponse.data.percentage;
//             const model_url = logResponse.data.model_url;
          

//             // Update state with the logs and progress
//             setLogs(logs);
//             setProgress(percentage);
//             setIsTrainingStarted(true);

//             const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); // Replace invalid characters for filename
//             const filename = `PPO_logs_${timestamp}.log`;
//             const blob = new Blob([logs], { type: 'text/plain' });
//             const url = URL.createObjectURL(blob);

//             // if (model_url !="") {
//             //     if (!trainingCompletedTime) {
//             //         console.log('Training complete');
                    

//             //         // Store the time when training completed
//             //         trainingCompletedTime = Date.now(); 
//             //         console.log('Training completed at:', trainingCompletedTime);

//             //         const link = document.createElement('a');
//             //         link.href = url;
//             //         link.download = filename;
//             //         document.body.appendChild(link);
//             //         link.click();
//             //         document.body.removeChild(link);
//             //         URL.revokeObjectURL(url);

//             //         if (metrics && metrics.length > 0) {
//             //             const steps = metrics.map(log => log.step);
//             //             const learningRates = metrics.map(log => log.learning_rate);
//             //             const losses = metrics.map(log => log.loss);

//             //             // Prepare data for charts
//             //             const learningRateChartData = {
//             //                 labels: steps,
//             //                 datasets: [
//             //                     {
//             //                         label: 'Learning Rate',
//             //                         data: learningRates,
//             //                         borderColor: 'rgba(75,192,192,1)',
//             //                         backgroundColor: 'rgba(75,192,192,0.2)',
//             //                         fill: false
//             //                     }
//             //                 ]
//             //             };

//             //             const lossChartData = {
//             //                 labels: steps,
//             //                 datasets: [
//             //                     {
//             //                         label: 'Loss',
//             //                         data: losses,
//             //                         borderColor: 'rgba(255,99,132,1)',
//             //                         backgroundColor: 'rgba(255,99,132,0.2)',
//             //                         fill: false
//             //                     }
//             //                 ]
//             //             };

//             //             setLearningRateChartData(learningRateChartData);
//             //             setLossChartData(lossChartData);
//             //             setMetricsError(false); // Clear any previous errors
//             //         } else {
//             //             // If metrics are null or empty
//             //             setLearningRateChartData(null);
//             //             setLossChartData(null);
//             //             setMetricsError(true);
//             //         }
//             //     }

//             //     // Continue polling every 2 seconds for 10 seconds after completion
//             //     const now = Date.now();
//             //     const elapsedTime = now - trainingCompletedTime;
//             //     console.log('Elapsed Time:', elapsedTime);

//             //     if (elapsedTime <= 5000) { // 10 seconds
//             //         console.log('Continuing polling...');
//             //         setTimeout(pollLogs, 2000);
//             //         if (metrics && metrics.length > 0) {
//             //             const steps = metrics.map(log => log.step);
//             //             const learningRates = metrics.map(log => log.learning_rate);
//             //             const losses = metrics.map(log => log.loss);

//             //             // Prepare data for charts
//             //             const learningRateChartData = {
//             //                 labels: steps,
//             //                 datasets: [
//             //                     {
//             //                         label: 'Learning Rate',
//             //                         data: learningRates,
//             //                         borderColor: 'rgba(75,192,192,1)',
//             //                         backgroundColor: 'rgba(75,192,192,0.2)',
//             //                         fill: false
//             //                     }
//             //                 ]
//             //             };

//             //             const lossChartData = {
//             //                 labels: steps,
//             //                 datasets: [
//             //                     {
//             //                         label: 'Loss',
//             //                         data: losses,
//             //                         borderColor: 'rgba(255,99,132,1)',
//             //                         backgroundColor: 'rgba(255,99,132,0.2)',
//             //                         fill: false
//             //                     }
//             //                 ]
//             //             };

//             //             setLearningRateChartData(learningRateChartData);
//             //             setLossChartData(lossChartData);
//             //             setMetricsError(false); // Clear any previous errors
//             //             setIsTrainingCompleted(true);
//             //             setIsTrainingStarted(false)
//             //         } else {
//             //             // If metrics are null or empty
//             //             setLearningRateChartData(null);
//             //             setLossChartData(null);
//             //             setMetricsError(true);
//             //         }

//             //     } else {
//             //         console.log('Stopping polling after 10 seconds');
//             //         setIsFinetuningStarted(false);
//             //         setIsTrainingStarted(false);
//             //     }
//             if (model_url != "") {
//               if (!trainingCompletedTime) {
//                   console.log('Training complete');
                  

//                   // Store the time when training completed
//                   trainingCompletedTime = Date.now(); 
//                   console.log('Training completed at:', trainingCompletedTime);

//                   const link = document.createElement('a');
//                   link.href = url;
//                   link.download = filename;
//                   document.body.appendChild(link);
//                   link.click();
//                   document.body.removeChild(link);
//                   URL.revokeObjectURL(url);

//                   // if (metrics && metrics.length > 0) {
//                   //     const steps = metrics.map(log => log.step);
//                   //     const learningRates = metrics.map(log => log.learning_rate);
//                   //     const losses = metrics.map(log => log.loss);

//                   //     // Prepare data for charts
//                   //     const learningRateChartData = {
//                   //         labels: steps,
//                   //         datasets: [
//                   //             {
//                   //                 label: 'Learning Rate',
//                   //                 data: learningRates,
//                   //                 borderColor: 'rgba(75,192,192,1)',
//                   //                 backgroundColor: 'rgba(75,192,192,0.2)',
//                   //                 fill: false
//                   //             }
//                   //         ]
//                   //     };

//                   //     const lossChartData = {
//                   //         labels: steps,
//                   //         datasets: [
//                   //             {
//                   //                 label: 'Loss',
//                   //                 data: losses,
//                   //                 borderColor: 'rgba(255,99,132,1)',
//                   //                 backgroundColor: 'rgba(255,99,132,0.2)',
//                   //                 fill: false
//                   //             }
//                   //         ]
//                   //     };

//                   //     setLearningRateChartData(learningRateChartData);
//                   //     setLossChartData(lossChartData);
//                   //     setMetricsError(false); // Clear any previous errors
//                   // } else {
//                   //     // If metrics are null or empty
//                   //     setLearningRateChartData(null);
//                   //     setLossChartData(null);
//                   //     setMetricsError(true);
//                   // }
//               }

//               // Continue polling every 2 seconds for 10 seconds after completion
//               const now = Date.now();
//               const elapsedTime = now - trainingCompletedTime;
//               console.log('Elapsed Time:', elapsedTime);

//               if (elapsedTime <= 5000) { // 10 seconds
//                   console.log('Continuing polling...');
//                   setTimeout(pollLogs, 2000);
//                   if (metrics && metrics.length > 0) {
//                       const steps = metrics.map(log => log.step);
//                       const learningRates = metrics.map(log => log.learning_rate);
//                       const losses = metrics.map(log => log.loss);

//                       // Prepare data for charts
//                       const learningRateChartData = {
//                           labels: steps,
//                           datasets: [
//                               {
//                                   label: 'Learning Rate',
//                                   data: learningRates,
//                                   borderColor: 'rgba(75,192,192,1)',
//                                   backgroundColor: 'rgba(75,192,192,0.2)',
//                                   fill: false
//                               }
//                           ]
//                       };

//                       const lossChartData = {
//                           labels: steps,
//                           datasets: [
//                               {
//                                   label: 'Loss',
//                                   data: losses,
//                                   borderColor: 'rgba(255,99,132,1)',
//                                   backgroundColor: 'rgba(255,99,132,0.2)',
//                                   fill: false
//                               }
//                           ]
//                       };

//                       setLearningRateChartData(learningRateChartData);
//                       setLossChartData(lossChartData);
//                       setMetricsError(false); // Clear any previous errors
//                       setIsTrainingCompleted(true);
//                       setIsTrainingStarted(false)
//                   } else {
//                       // If metrics are null or empty
//                       setLearningRateChartData(null);
//                       setLossChartData(null);
//                       setMetricsError(true);
//                   }

//               } else {
//                   console.log('Stopping polling after 10 seconds');
//                   setIsFinetuningStarted(false);
//                   setIsTrainingStarted(false);
//               }

//           } else {
//               // Continue polling every 2 seconds
//               setTimeout(pollLogs, 2000);
//           }

           
        
//         } catch (error) {
//             // Handle network or other unexpected errors
//             console.error('Polling Error:', error.response ? error.response.data : error.message);
//             setIsTrainingStarted(false); // Stop any ongoing process
//             setIsFinetuningStarted(false);
//         }
//     };
    

    

//       // Start polling
//       pollLogs();

//       // Update state with the new Id or handle other success logic

//   } catch (error) {
//       // Handle errors from the initial request
//       console.error('API Error:', error.response ? error.response.data : error.message);
//   }
// };



  // Define a click handler function
  const handleClick = () => {
      const paramsToUse = hyperparameters && Object.keys(hyperparameters).length > 0
          ? hyperparameters
          : defaultPPOParameters;

      handleStartFineTuning(defaultHyperparameters);
  };
  const handlePPOClick = () => {
      const paramsToUse = hyperparameters && Object.keys(hyperparameters).length > 0
          ? hyperparameters
          : defaultPPOParameters;

      handleStartPPOFineTuning(defaultPPOParameters);
  };

  
  
  
  
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const knowledgeWorkLinks = [
    { name: "1. Perplexity", isActive: activeTabIndex === 0 },
    { name: "2. Rouge", isActive: activeTabIndex === 1 },
    { name: "3. BLEU", isActive: activeTabIndex === 2 },
  ];

  const handleKnowledgeWorkClick = (index) => {
    setActiveTabIndex(index);
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    const loadChartData = async () => {
      const chartData = await fetchChartData();
      setData(chartData);
    };

    loadChartData();
  }, []);

  useEffect(() => {
    if (
      knowledgeWorkLinks.find((link) => link.isActive && link.name === "New")
    ) {
      setDisplayedData(newData);
    } else if (
      knowledgeWorkLinks.find(
        (link) => link.isActive && link.name === "In Progress"
      )
    ) {
      setDisplayedData(inProgressData); // replace with actual drafts data
    } else {
      setDisplayedData(completedData);
    }
  }, [completedData, knowledgeWorkLinks]);

  return (
    <div>
        
        <MoHeader darkMode={darkMode}/>
      <div className="container-fluid px-4 pt-4">
        <p className="text-light fw-bold">Model Training</p>
        <p style={{color: '#ADC2FF', marginTop:'-10px'}}>Knowledge Work: {knowledgeWorkDetails.knowledge_work.name}</p>
      </div>
      
      <div className="d-flex justify-content-end align-items-center" style={{ background: 'transparent'}}>
  <Nav className="d-flex" style={{marginBottom:'7px',position:'relative', bottom:'9.8rem'}}>
    <button className='btn btn-sm btn-link mr-2'>
      <Link to={`/mo/summary/new/${id}`} style={{ color: '#ADC2FF', textDecoration: 'underline'}}>Summary</Link>
    </button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Close</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }} onClick={handlePrevStep} disabled={isPrevButtonDisabled()}>Back</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Save</button>
    <button className='btn btn-sm mr-2' style={{ background: '#003BEB', color: '#fff' }} onClick={() => handleNextStep(currentModelId)}>Proceed</button>
  </Nav>
</div>

<div className="container-fluid p-0" style={{ background: '#E8EDFA', height: '100vh', display: 'flex' }}>
        <div className="row flex-grow-1 m-0" style={{ height: '100%', overflow: 'hidden' }}>
        <div className="col-md-2 p-2" style={{ background: "#42466C", overflowY: 'auto', height: '100vh' }}>
            <h5 style={{ fontSize: "16px" }} className="text-light ml-4">
              Models ({selectedModelIds.length})
            </h5>
            <div className="accordion" id="accordionExample">
      {selectedModelIds.map((model, modelIndex) => (
        <div className="accordion-item" key={modelIndex} style={{ border: 'none' }}>
          <h2 className="accordion-header" id={`heading${model.id}`}>
            <button
              className={`accordion-button ${modelIndex === 0 ? 'show' : 'collapsed'}`}
              type="button"
              style={{
                background: '#313557',
                color: '#fff',
                fontSize: '14px',  // Adjusted font size
                padding: '8px 16px'  // Adjusted padding for a smaller button
              }}
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${model.id}`}
              aria-expanded={modelIndex === 1 ? 'true' : 'false'}
              aria-controls={`collapse${model.id}`}
            >
              {model.model_name}
            </button>
          </h2>
          <div
            id={`collapse${model.id}`}
            className={`accordion-collapse collapse ${modelIndex === 0 ? 'show' : ''}`}
            aria-labelledby={`heading${model.id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{ background: '#42466C', padding: '10px' }}>
              <div className="accordion" id={`accordionExample${model.id}`}>
                {sections.map((section, sectionIndex) => (
                  <div className="accordion-item" key={sectionIndex} style={{ border: 'none' }}>
                    <h2 className="accordion-header" id={`heading${model.id}${sectionIndex}`}>
                      <button
                        className={`accordion-button rounded-0 ${sectionIndex === currentSection ? '' : 'collapsed'}`}
                        type="button"
                        style={{
                          background: sectionIndex === currentSection ? '#313557' : '#42466C',
                          color: '#fff',
                          fontSize: '14px',  // Adjusted font size
                          padding: '8px 16px'  // Adjusted padding for a smaller button
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${model.id}${sectionIndex}`}
                        aria-expanded={sectionIndex === currentSection ? 'true' : 'false'}
                        aria-controls={`collapse${model.id}${sectionIndex}`}
                      >
                        {section.name}
                      </button>
                    </h2>
                    <div
                      id={`collapse${model.id}${sectionIndex}`}
                      className={`accordion-collapse collapse ${sectionIndex === currentSection ? 'show' : ''}`}
                      aria-labelledby={`heading${model.id}${sectionIndex}`}
                      data-bs-parent={`#accordionExample${model.id}`}
                    >
                      <div className="accordion-body" style={{ background: '#42466C', padding: '10px' }}>
                        {/* <div
                          className="progress"
                          style={{ height: '8px', marginBottom: '10px' }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${((currentStep[sectionIndex] + 1) / sections[sectionIndex].steps.length) * 100}%`,
                              background: '#0FC948',
                            }}
                            aria-valuenow={(currentStep[sectionIndex] + 1) * (100 / sections[sectionIndex].steps.length)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div> */}
                        <ul className="list-group">
  {section.steps.map((step, stepIndex) => {
    const isCompleted = stepIndex < currentStep[sectionIndex];
    const isCurrent = stepIndex === currentStep[sectionIndex];

    return (
      <li
        key={stepIndex}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '4px',
          fontSize: '14px', // Adjusted font size for better readability
          padding: '8px',
          borderRadius: '4px',
          border: 'none',
          backgroundColor: isCurrent ? '#313557' : 'transparent',
          color: isCurrent || isCompleted ? '#fff' : '#ccc',
        }}
        className={`list-group-item ${isCurrent ? 'active' : ''}`}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: isCompleted ? '#0FC948' : '#4D5284',
            color: '#fff',
            marginRight: '12px',
            fontSize: '14px',
          }}
        >
          {isCompleted ? '✓' : stepIndex + 1}
        </div>
        <div
          style={{
            flex: 1,
            padding: '4px 8px',
            borderRadius: '4px',
          }}
        >
          {step}
        </div>
      </li>
    );
  })}
</ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>



          </div>
          <div className="col-md-6 chatBackgroundImage" style={{
      // Ensure the container has a minimum height
      backgroundSize: 'cover', // Ensures the image covers the container
      backgroundPosition: 'center', // Centers the image in the container
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      backgroundAttachment: 'fixed', // Keeps the background fixed during scrolling
      position: '', // Keeps the center column fixed
      top: '0',
      height: '100vh',
      overflowY:'auto'
          }}>
          <div className="d-flex flex-column" style={{ height: "90vh" }}>
      <div
        className="flex-grow-1 d-flex flex-column overflow-auto"
       // Optional: Set background for visibility

      >

              {sections[currentSection].name === "Reward Model" && currentStep[currentSection] === 0 && (
                 <div className="d-flex flex-column justify-content-center p-4">
                 <div  
                  className="p-2 m-2 d-flex rounded"  
                  style={{ background: "#fff", overflow: 'auto',width:'auto' }}  
                  >  
                  <p className="text-dark">  
                    Hello from CoachLLM!  
                  </p>  
                  </div>  

                 <div
                   className="p-2 m-2 d-flex rounded"
                   style={{ background: "#fff" }}
                 >
                   <p className="text-dark">
                   📂 Ready to get started? Upload your data by clicking the button on the right to begin RM training.<br/>
                   🛠️ Need assistance? Simply type your questions below and I’m here to assist.

                  </p>
                 </div>
               </div>
               
                )}
              {sections[currentSection].name === "PPO Model" && currentStep[currentSection] === 0 && (
                 <div className="d-flex flex-column justify-content-center p-4">
                 <div  
                  className="p-2 m-2 d-flex rounded"  
                  style={{ background: "#fff", overflow: 'auto',width:'auto' }}  
                  >  
                  <p className="text-dark">  
                    Hello from CoachLLM!  
                  </p>  
                  </div>  

                 <div
                   className="p-2 m-2 d-flex rounded"
                   style={{ background: "#fff" }}
                 >
                   <p className="text-dark">
                   📂 Ready to get started? Upload your data by clicking the button on the right to begin PPO training.<br/>
                   🛠️ Need assistance? Simply type your questions below and I’m here to assist.

                  </p>
                 </div>
               </div>
                )}
                 {sections[currentSection].name === "Reward Model" && currentStep[currentSection] === 1 && (
                  <>
                    {messages.length === 0 && (
                      <p className="text-center text-dark mt-4">Ask Queries about hyperparameters</p>
                    )}
                   <div className="d-flex flex-column-reverse flex-grow-1 overflow-scroll p-4" style={{ minHeight: '75%' }}>
                  <div className="d-flex flex-column w-100">
                    {messages.map((msg, index) => (
                      <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                        <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                          <p className="m-0 text-dark">{msg.text}</p>
                        </div>
                      </div>
                    ))}
                    {/* <div ref={endOfMessagesRef} /> */}
                  </div>
                </div>
                </>
                 )}
              {sections[currentSection].name === "Reward Model" && currentStep[currentSection] === 2 && (
               <div className="d-flex flex-column justify-content-center p-4">
               <button className={`m-2 btn btn-sm d-flex text-light align-items-center justify-content-center ${isFinetuningStarted ? 'animated-ellipsis' : ''}`}   style={{background: '#003BEB'}} onClick={handleClick} disabled={isTrainingCompleted || isTrainingStarted || isFinetuningStarted}>
                <span className="px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-bot"
                  >
                    <path d="M12 8V4H8" />
                    <rect width="16" height="12" x="4" y="8" rx="2" />
                    <path d="M2 14h2" />
                    <path d="M20 14h2" />
                    <path d="M15 13v2" />
                    <path d="M9 13v2" />
                  </svg>
                </span>{" "}
                {isFinetuningStarted ? "Reward Training" : !isTrainingCompleted ? "Start Reward":'Reward Completed'}
              </button>
            
            {isTrainingStarted && (
        <div
            className="p-4 m-2 d-flex flex-column rounded"
            style={{ background: "#FFFFFF" }}
        >
            <p className="text-dark">
            Reward Completion Status
            </p>
            <div className="d-flex align-items-center justify-content-center">
                <div className="progress" style={{ width: "100%" }}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progress}%`, background: "#42466C" }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        {progress}%
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    style={{ marginLeft: "10px" }}
                >
                    Cancel
                </button>
            </div>
        </div>
    )}
              {isTrainingCompleted && (
              <div
                className="p-4 m-2 d-flex flex-column rounded"
                style={{ background: "#FFFFFF" }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#08C948"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-circle-check"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path d="m9 12 2 2 4-4" />
                    </svg>
 
                    <h3 className="fs-4 ml-2" style={{ color: "#08C948" }}>
                    Reward Completed
                    </h3>
                  </div>
                  {/* <p className="text-dark" style={{ marginLeft: "10px" }}>
                    Time Taken: 12 Hours
                  </p> */}
                </div>
              </div>
 
              )}
              <div className="container">
                  <>
                    {messages.length === 0 && (
                      <p className="text-center text-dark mt-4">Ask Queries about hyperparameters</p>
                    )}
                   <div className="d-flex flex-column-reverse flex-grow-1 overflow-scroll p-4" style={{ minHeight: '75%' }}>
                  <div className="d-flex flex-column w-100">
                    {messages.map((msg, index) => (
                      <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                        <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                          <p className="m-0 text-dark">{msg.text}</p>
                        </div>
                      </div>
                    ))}
                    {/* <div ref={endOfMessagesRef} /> */}
                  </div>
                </div>
                </>
                </div>
            </div>

              
              )}
              {sections[currentSection].name === "PPO Model" && currentStep[currentSection] === 1 && (
              <div className="d-flex flex-column justify-content-center p-4">
              <button className={`m-2 btn btn-sm d-flex text-light align-items-center justify-content-center ${isPPOFinetuningStarted ? 'animated-ellipsis' : ''}`} style={{background: '#003BEB'}} onClick={handlePPOClick} disabled={isTrainingPPOCompleted || isPPOTrainingStarted || isPPOFinetuningStarted}>
               <span className="px-2">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width="24"
                   height="24"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="currentColor"
                   stroke-width="2"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   class="lucide lucide-bot"
                 >
                   <path d="M12 8V4H8" />
                   <rect width="16" height="12" x="4" y="8" rx="2" />
                   <path d="M2 14h2" />
                   <path d="M20 14h2" />
                   <path d="M15 13v2" />
                   <path d="M9 13v2" />
                 </svg>
               </span>{" "}
               {isPPOFinetuningStarted ? "PPO Training" : isTrainingPPOCompleted ? "PPO Completed":'Start PPO'}
               {/* {isFinetuningStarted ? "PPO Training" : !isTrainingCompleted ? "Start PPO":'PPO Completed'} */}
             </button>
         
           {isPPOTrainingStarted && (
       <div
           className="p-4 m-2 d-flex flex-column rounded"
           style={{ background: "#FFFFFF" }}
       >
           <p className="text-dark">
           PPO Completion Status
           </p>
           <div className="d-flex align-items-center justify-content-center">
               <div className="progress" style={{ width: "100%" }}>
                   <div
                       className="progress-bar"
                       role="progressbar"
                       style={{ width: `${progress}%`, background: "#42466C" }}
                       aria-valuenow={progress}
                       aria-valuemin="0"
                       aria-valuemax="100"
                   >
                       {progress}%
                   </div>
               </div>
               <button
                   type="button"
                   className="btn btn-sm btn-outline-primary"
                   style={{ marginLeft: "10px" }}
               >
                   Cancel
               </button>
           </div>
       </div>
   )}
             {isTrainingPPOCompleted && (
             <div
               className="p-4 m-2 d-flex flex-column rounded"
               style={{ background: "#FFFFFF" }}
             >
               <div className="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="32"
                     height="32"
                     viewBox="0 0 24 24"
                     fill="none"
                     stroke="#08C948"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     class="lucide lucide-circle-check"
                   >
                     <circle cx="12" cy="12" r="10" />
                     <path d="m9 12 2 2 4-4" />
                   </svg>

                   <h3 className="fs-4 ml-2" style={{ color: "#08C948" }}>
                   PPO Completed
                   </h3>
                 </div>
                 {/* <p className="text-dark" style={{ marginLeft: "10px" }}>
                   Time Taken: 12 Hours
                 </p> */}
               </div>
             </div>

             )}
             <div className="container">
                 <>
                   {messages.length === 0 && (
                     <p className="text-center text-dark mt-4">Ask Queries about hyperparameters</p>
                   )}
                  <div className="d-flex flex-column-reverse flex-grow-1 overflow-scroll p-4" style={{ minHeight: '75%' }}>
                 <div className="d-flex flex-column w-100">
                   {messages.map((msg, index) => (
                     <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                       <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                         <p className="m-0 text-dark">{msg.text}</p>
                       </div>
                     </div>
                   ))}
                   {/* <div ref={endOfMessagesRef} /> */}
                 </div>
               </div>
               </>
               </div>
           </div>
              
              )}
              {sections[currentSection].name === "PPO Model" && currentStep[currentSection] === 2 && (
              <div className="row p-2">
              <div className="col-md-5 mt-4 p-0 m-0 rounded" style={{ background: "#243063" }}>
                <div
                  className="pt-2 rounded"
                  style={{ background: "#243063", borderBottom: '1px solid #003beb' }}
                >
                  <p className="text-light fw-bold pl-4">
                   EDA (Pre PPO Training)
                  </p>
                </div>
                <div className="p-2" style={{ background: "#DDE3F6" }}>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sequi quas repellendus nobis veniam sapiente amet at iusto eveniet quisquam?
                  </p>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste accusamus veritatis totam labore. Quia pariatur illum consectetur necessitatibus! Tenetur, deserunt sed tempora aliquid unde et quibusdam debitis magni molestiae nemo.
                  </p>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, architecto.
                  </p>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio tenetur officia accusantium alias, id ut consequatur iure ratione at eos deleniti dolorum. Libero labore voluptatum earum consequatur aperiam nobis sint.
                  </p>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5 mt-4 p-0 m-0 rounded" style={{ background: "#243063" }}>
                <div
                  className="pt-2 rounded"
                  style={{ background: "#243063", borderBottom: '1px solid #003beb' }}
                >
                  <p className="text-light fw-bold pl-4">
                   EDA (Post PPO Training)
                  </p>
                </div>
                <div className="p-2" style={{ background: "#DDE3F6" }}>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sequi quas repellendus nobis veniam sapiente amet at iusto eveniet quisquam?
                  </p>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste accusamus veritatis totam labore. Quia pariatur illum consectetur necessitatibus! Tenetur, deserunt sed tempora aliquid unde et quibusdam debitis magni molestiae nemo.
                  </p>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, architecto.
                  </p>
                  <p className="p-2 rounded text-dark bg-light">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio tenetur officia accusantium alias, id ut consequatur iure ratione at eos deleniti dolorum. Libero labore voluptatum earum consequatur aperiam nobis sint.
                  </p>
                </div>
              </div>
            </div>
            
              )}
             
              
              </div>
              {sections[currentSection].name === "PPO Model" && currentStep[currentSection] != 2 && (
             <div className="d-flex align-items-end justify-content-center">
             <div
               className="p-2 m-2 d-flex align-items-center rounded"
               style={{ background: "#ffffff" }}
             >
               <input
                 type="text"
                 className="form-control "
                 style={{
                   background: "transparent",
                   color: "#003BEB",
                   width: "30rem",
                   height:'2rem',
                   border: 'none',
                   outline: 'none'
                 }}
                 placeholder="Type a message..."
                 value={message}
       onChange={(e) => setMessage(e.target.value)}
       onKeyDown={(e) => {
         if (e.key === 'Enter') {
           sendMessage();
         }
       }}
               />
               <span className="px-2">
               <svg xmlns="http://www.w3.org/2000/svg" width="17.661" height="15.877" viewBox="0 0 17.661 15.877">
  <path id="Path_5908" data-name="Path 5908" d="M20.339,20.58a.6.6,0,0,1,0-.842l3.57-3.57a.6.6,0,0,1,.842,0l3.57,3.57a.6.6,0,1,1-.842.842l-2.554-2.555v6.3a.6.6,0,0,1-1.19,0v-6.3L21.181,20.58a.6.6,0,0,1-.842,0ZM32.661,24.92v4.76a1.19,1.19,0,0,1-1.19,1.19H17.19A1.19,1.19,0,0,1,16,29.68V24.92a1.19,1.19,0,0,1,1.19-1.19h5.058a.3.3,0,0,1,.3.3v.257a1.818,1.818,0,0,0,1.819,1.825,1.785,1.785,0,0,0,1.751-1.785v-.3a.3.3,0,0,1,.3-.3h5.058A1.19,1.19,0,0,1,32.661,24.92ZM29.686,27.3a.893.893,0,1,0-.893.893.893.893,0,0,0,.893-.893Z" transform="translate(-15.5 -15.494)" fill="#003beb" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</svg>
               </span>
               <span className="px-2" onClick={sendMessage}>
               <svg id="Group_1737" data-name="Group 1737" xmlns="http://www.w3.org/2000/svg" width="15.822" height="13.573" viewBox="0 0 15.822 13.573">
  <g id="Group_1383" data-name="Group 1383">
    <path id="Path_53" data-name="Path 53" d="M0,.088A.089.089,0,0,1,.123.006C.46.145,10.89,4.607,15.768,6.7a.089.089,0,0,1,0,.163l-.015.006L.16,13.55l-.041.016A.089.089,0,0,1,0,13.482V7.639a.088.088,0,0,1,.078-.088c.9-.1,6.4-.5,8.88-.673a.088.088,0,0,0,0-.176C6.464,6.515.9,6.093.079,6.008A.088.088,0,0,1,0,5.92V.088" transform="translate(0 0.001)" fill="#003beb"/>
  </g>
</svg>
               </span>
             </div>
           </div>
              )}
              {sections[currentSection].name === "Reward Model"  && (
             <div className="d-flex align-items-end justify-content-center">
             <div
               className="p-2 m-2 d-flex align-items-center rounded"
               style={{ background: "#ffffff" }}
             >
               <input
                 type="text"
                 className="form-control "
                 style={{
                   background: "transparent",
                   color: "#003BEB",
                   width: "30rem",
                   height:'2rem',
                   border: 'none',
                   outline: 'none'
                 }}
                 placeholder="Type a message..."
                 value={message}
       onChange={(e) => setMessage(e.target.value)}
       onKeyDown={(e) => {
         if (e.key === 'Enter') {
           sendMessage();
         }
       }}
               />
               <span className="px-2">
               <svg xmlns="http://www.w3.org/2000/svg" width="17.661" height="15.877" viewBox="0 0 17.661 15.877">
  <path id="Path_5908" data-name="Path 5908" d="M20.339,20.58a.6.6,0,0,1,0-.842l3.57-3.57a.6.6,0,0,1,.842,0l3.57,3.57a.6.6,0,1,1-.842.842l-2.554-2.555v6.3a.6.6,0,0,1-1.19,0v-6.3L21.181,20.58a.6.6,0,0,1-.842,0ZM32.661,24.92v4.76a1.19,1.19,0,0,1-1.19,1.19H17.19A1.19,1.19,0,0,1,16,29.68V24.92a1.19,1.19,0,0,1,1.19-1.19h5.058a.3.3,0,0,1,.3.3v.257a1.818,1.818,0,0,0,1.819,1.825,1.785,1.785,0,0,0,1.751-1.785v-.3a.3.3,0,0,1,.3-.3h5.058A1.19,1.19,0,0,1,32.661,24.92ZM29.686,27.3a.893.893,0,1,0-.893.893.893.893,0,0,0,.893-.893Z" transform="translate(-15.5 -15.494)" fill="#003beb" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</svg>
               </span>
               <span className="px-2" onClick={sendMessage}>
                  
               <svg id="Group_1737" data-name="Group 1737" xmlns="http://www.w3.org/2000/svg" width="15.822" height="13.573" viewBox="0 0 15.822 13.573">
  <g id="Group_1383" data-name="Group 1383">
    <path id="Path_53" data-name="Path 53" d="M0,.088A.089.089,0,0,1,.123.006C.46.145,10.89,4.607,15.768,6.7a.089.089,0,0,1,0,.163l-.015.006L.16,13.55l-.041.016A.089.089,0,0,1,0,13.482V7.639a.088.088,0,0,1,.078-.088c.9-.1,6.4-.5,8.88-.673a.088.088,0,0,0,0-.176C6.464,6.515.9,6.093.079,6.008A.088.088,0,0,1,0,5.92V.088" transform="translate(0 0.001)" fill="#003beb"/>
  </g>
</svg>
               </span>
             </div>
           </div>
              )}
            </div>
          </div>

          
          
        
          {sections[currentSection].name === "Reward Model" && currentStep[currentSection] === 0 && (
             <div className="col-md-4 p-2" style={{ background: "#1f2340", overflowY:'auto',height:'100vh',padding:'0' }}>
        
             <div className="d-flex align-items-center ">
               <p className="text-light pr-2 border-right">Training Data</p>
               <p className="text-light" style={{ cursor: "pointer", display: "flex",alignContent:'center',justifyContent:'center',alignItems: 'center' }}>
      
                 <span className="px-2">
                 <svg id="Group_676" data-name="Group 676" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
                    <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_790" data-name="Rectangle 790" width="12" height="12" fill="#e5eaf1"/>
                    </clipPath>
                  </defs>
                  <g id="Group_675" data-name="Group 675" clip-path="url(#clip-path)">
                    <path id="Path_198" data-name="Path 198" d="M12,125.257l-.043.1a.528.528,0,0,1-.446.353,1.806,1.806,0,0,1-.2.009H.686a1.247,1.247,0,0,1-.268-.019A.563.563,0,0,1,0,125.2a.828.828,0,0,1,0-.087q0-2.487,0-4.975a.564.564,0,0,1,.483-.6.544.544,0,0,1,.595.471,1.77,1.77,0,0,1,.012.236q0,2.07,0,4.14v.169h9.817v-.157q0-2.082,0-4.165a1.206,1.206,0,0,1,.026-.284.531.531,0,0,1,1.019-.063l.045.113Z" transform="translate(0 -113.719)" fill="#e5eaf1"/>
                    <path id="Path_199" data-name="Path 199" d="M66.179,7.7a.464.464,0,0,0-.058-.121Q65.381,6.785,64.638,6a.526.526,0,0,0-.894.134.579.579,0,0,0,.048.589,1.048,1.048,0,0,0,.116.14l2.428,2.584a.535.535,0,0,0,.879,0l2.444-2.6a.616.616,0,0,0,.143-.733.524.524,0,0,0-.821-.178,1.524,1.524,0,0,0-.111.111q-.717.762-1.433,1.526a.832.832,0,0,0-.075.11l-.041-.025V7.516q0-3.429,0-6.858a.615.615,0,0,0-.285-.585.539.539,0,0,0-.8.467c0,.058,0,.116,0,.174q0,3.4,0,6.8v.144l-.055.043" transform="translate(-60.777 0)" fill="#e5eaf1"/>
                  </g>
                  </svg>
                </span>
                 <a
                   href={templateSelector[path]}
                   download="Template"
                   target="_blank"
                   style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                   className={
                     (darkMode ? "darg-n-drop-dark " : "darg-n-drop ") +
                     "p-0 justify-content-center fw-medium"
                   }
                 >
                   Download Template
                 </a>
               </p>
             </div>
             <Card
               className={darkMode ? "UploadCompDark p-3" : "UploadComp p-3"}style={{overflow:'scroll',height:'auto'}}
             >
               <Card.Body
                 className={
                   darkMode
                     ? "uploadCompOuter d-flex align-items-center justify-content-center height:100%"
                     : "UploadComp d-flex align-items-center justify-content-center height:100%"
                 }
                 style={{ color: darkMode ? "" : "white" }}
               >
                 {csvData.length <= 0 && (
                   <div className="text-center">
                     <div
                       className={
                         (darkMode ? "image-upload-dark" : "image-upload") +
                         " text-center d-flex flex-column align-items-center "
                       }
                     >
                       <input
                         type="file"
                         // id="fileUpload"
                         onChange={handleFileChange}
                         id="file-input"
                       />
                       <FiUpload
                         style={{
                           fontSize: "30px",
                           color: darkMode ? "#E5EAF1" : "#0056b3",
                         }}
                       />
                       {/* </label> */}
                       <div
                         style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                         className={
                           darkMode
                             ? "darg-n-drop-dark pb-4 pt-2"
                             : "darg-n-drop pb-5 fw-medium"
                         }
                       >
                         Drag and Drop
                       </div>
                       <div
                         className={
                           darkMode
                             ? "uploadCompOrDark mb-4"
                             : "uploadCompOr mb-5"
                         }
                       >
                         OR
                       </div>
                       <div
                         className="taskList"
                         style={{
                           fontSize: "14px",
                           color: darkMode ? "" : "rgb(0, 86, 179)",
                         }}
                       >
                         Choose from Repository
                       </div>
                       <label htmlFor="file-input" className="my-2">
                         <a className="cancelBtnDark  mx-0">
                           Choose Training Data
                         </a>
                       </label>
                       {selectedFile && (
                         <div
                           style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                         >
                           {" "}
                           {selectedFile.name}{" "}
                         </div>
                       )}
                     </div>

                     <Button
                       onClick={handleUpload}
                       disabled={loading}
                       // className="submitBtn mt-2"
                       className={`mt-2 mr-0 ${
                         selectedFile ? "" : "disabled"
                       } ${darkMode ? "submitBtnDark" : "submitBtnDark"}`}
                     >
                       {loading ? "Uploading..." : "Upload"}
                     </Button>
                     {loading && <div className="loader">Loading...</div>}
                   </div>
                 )}
                 {/* Display CSV Data */}
                 {csvData.length > 0 && (
                   <div className="csv-data ">
                     <Table
                       className={`${
                         darkMode ? "custom-tableDark" : "custom-table"
                       } mt-3`}
                     >
                       <thead className="table-secondary ">
                         <tr>
                           {Object.keys(csvData[0]).map((header, index) => (
                             <th key={index} style={{ fontWeight: "bold" }}>
                               {header}
                             </th>
                           ))}
                         </tr>
                       </thead>
                       <tbody>
                         {csvData.map((row, index) => (
                           <tr key={index}>
                             {Object.values(row).map((cell, cellIndex) => (
                               <td key={cellIndex}>{cell}</td>
                             ))}
                           </tr>
                         ))}
                       </tbody>
                     </Table>
                   </div>
                 )}
               </Card.Body>
             </Card>
           </div>
          )}
          {sections[currentSection].name === "PPO Model" && currentStep[currentSection] === 0 && (
             <div className="col-md-4 p-2" style={{ background: "#1f2340", overflowY:'auto',height:'100vh',padding:'0' }}>
        
             <div className="d-flex align-items-center ">
               <p className="text-light pr-2 border-right">Training Data</p>
               <p className="text-light" style={{ cursor: "pointer", display: "flex",alignContent:'center',justifyContent:'center',alignItems: 'center' }}>
      
                 <span className="px-2">
                 <svg id="Group_676" data-name="Group 676" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
                    <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_790" data-name="Rectangle 790" width="12" height="12" fill="#e5eaf1"/>
                    </clipPath>
                  </defs>
                  <g id="Group_675" data-name="Group 675" clip-path="url(#clip-path)">
                    <path id="Path_198" data-name="Path 198" d="M12,125.257l-.043.1a.528.528,0,0,1-.446.353,1.806,1.806,0,0,1-.2.009H.686a1.247,1.247,0,0,1-.268-.019A.563.563,0,0,1,0,125.2a.828.828,0,0,1,0-.087q0-2.487,0-4.975a.564.564,0,0,1,.483-.6.544.544,0,0,1,.595.471,1.77,1.77,0,0,1,.012.236q0,2.07,0,4.14v.169h9.817v-.157q0-2.082,0-4.165a1.206,1.206,0,0,1,.026-.284.531.531,0,0,1,1.019-.063l.045.113Z" transform="translate(0 -113.719)" fill="#e5eaf1"/>
                    <path id="Path_199" data-name="Path 199" d="M66.179,7.7a.464.464,0,0,0-.058-.121Q65.381,6.785,64.638,6a.526.526,0,0,0-.894.134.579.579,0,0,0,.048.589,1.048,1.048,0,0,0,.116.14l2.428,2.584a.535.535,0,0,0,.879,0l2.444-2.6a.616.616,0,0,0,.143-.733.524.524,0,0,0-.821-.178,1.524,1.524,0,0,0-.111.111q-.717.762-1.433,1.526a.832.832,0,0,0-.075.11l-.041-.025V7.516q0-3.429,0-6.858a.615.615,0,0,0-.285-.585.539.539,0,0,0-.8.467c0,.058,0,.116,0,.174q0,3.4,0,6.8v.144l-.055.043" transform="translate(-60.777 0)" fill="#e5eaf1"/>
                  </g>
                  </svg>
                </span>
                 <a
                   href={templateSelector[path]}
                   download="Template"
                   target="_blank"
                   style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                   className={
                     (darkMode ? "darg-n-drop-dark " : "darg-n-drop ") +
                     "p-0 justify-content-center fw-medium"
                   }
                 >
                   Download Template
                 </a>
               </p>
             </div>
             <Card
               className={darkMode ? "UploadCompDark p-3" : "UploadComp p-3"}style={{overflow:'scroll',height:'auto'}}
             >
               <Card.Body
                 className={
                   darkMode
                     ? "uploadCompOuter d-flex align-items-center justify-content-center height:100%"
                     : "UploadComp d-flex align-items-center justify-content-center height:100%"
                 }
                 style={{ color: darkMode ? "" : "white" }}
               >
                 {csvDataPPO.length <= 0 && (
                   <div className="text-center">
                     <div
                       className={
                         (darkMode ? "image-upload-dark" : "image-upload") +
                         " text-center d-flex flex-column align-items-center "
                       }
                     >
                       <input
                         type="file"
                         // id="fileUpload"
                         onChange={handleFileChangePPO}
                         id="file-input"
                       />
                       <FiUpload
                         style={{
                           fontSize: "30px",
                           color: darkMode ? "#E5EAF1" : "#0056b3",
                         }}
                       />
                       {/* </label> */}
                       <div
                         style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                         className={
                           darkMode
                             ? "darg-n-drop-dark pb-4 pt-2"
                             : "darg-n-drop pb-5 fw-medium"
                         }
                       >
                         Drag and Drop
                       </div>
                       <div
                         className={
                           darkMode
                             ? "uploadCompOrDark mb-4"
                             : "uploadCompOr mb-5"
                         }
                       >
                         OR
                       </div>
                       <div
                         className="taskList"
                         style={{
                           fontSize: "14px",
                           color: darkMode ? "" : "rgb(0, 86, 179)",
                         }}
                       >
                         Choose from Repository
                       </div>
                       <label htmlFor="file-input" className="my-2">
                         <a className="cancelBtnDark  mx-0">
                           Choose Training Data
                         </a>
                       </label>
                       {selectedFilePPO && (
                         <div
                           style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                         >
                           {" "}
                           {selectedFilePPO.name}{" "}
                         </div>
                       )}
                     </div>

                     <Button
                       onClick={handleUploadPPO}
                       disabled={loading}
                       // className="submitBtn mt-2"
                       className={`mt-2 mr-0 ${
                         selectedFilePPO ? "" : "disabled"
                       } ${darkMode ? "submitBtnDark" : "submitBtnDark"}`}
                     >
                       {loading ? "Uploading..." : "Upload"}
                     </Button>
                     {loading && <div className="loader">Loading...</div>}
                   </div>
                 )}
                 {/* Display CSV Data */}
                 {csvDataPPO.length > 0 && (
                   <div className="csv-data ">
                     <Table
                       className={`${
                         darkMode ? "custom-tableDark" : "custom-table"
                       } mt-3`}
                     >
                       <thead className="table-secondary ">
                         <tr>
                           {Object.keys(csvDataPPO[0]).map((header, index) => (
                             <th key={index} style={{ fontWeight: "bold" }}>
                               {header}
                             </th>
                           ))}
                         </tr>
                       </thead>
                       <tbody>
                         {csvDataPPO.map((row, index) => (
                           <tr key={index}>
                             {Object.values(row).map((cell, cellIndex) => (
                               <td key={cellIndex}>{cell}</td>
                             ))}
                           </tr>
                         ))}
                       </tbody>
                     </Table>
                   </div>
                 )}
               </Card.Body>
             </Card>
           </div>
          )}
        
          {sections[currentSection].name === "Reward Model" && currentStep[currentSection] === 1 && (
            <div className="col-md-4 " style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light">Hyperparameters</p>
              </div>
              <div className="mt-2 p-2 d-flex flex-column">
              <CustomTableNew
                  // updateData={updateData}
                  data={hyperparameterTableData}
                  TableColumn={TableColumnLocal[index - 1]}
                  darkMode={darkMode}
                  onDataChange={handlehyperparameterTableDataChange}
                ></CustomTableNew>
              </div>
            </div>
          )}
          {sections[currentSection].name === "Reward Model" && currentStep[currentSection] === 2 && (
            <div className="col-md-4 p-2" style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light">PEFT Hyperparameters</p>
              </div>
              <div className="mt-2 p-2  d-flex flex-column">
               <CustomTableNew
                  data={peftTableData}
                  TableColumn={TableColumnLocal[index - 1]}
                  darkMode={darkMode}
                  onDataChange={handlePeftTableDataChange}
                ></CustomTableNew>
              </div>
            </div>
          )}
          {sections[currentSection].name === "PPO Model" && currentStep[currentSection] === 1 && (
            <div className="col-md-4 p-2"style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
              <div className="d-flex align-items-center border-bottom">
                <p className="text-light">Model Hyperparameters</p>
              </div>
              <div className="mt-2 p-2  d-flex flex-column">
              <CustomTableNew
                  data={ppoTableData}
                  TableColumn={TableColumnLocal[index - 1]}
                  darkMode={darkMode}
                  onDataChange={handlePPOhyperparameterTableDataChange}
                ></CustomTableNew>
              </div>
            </div>
          )}
         {sections[currentSection].name === "PPO Model" && currentStep[currentSection] === 2 && (
               <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'scroll',height:'100%',border:'1rem solid #42466C' }}>
  
               <div className="d-flex align-items-center border-bottom">
                 <p className="text-light">Evaluate SFT</p>
               </div>
               
               <div className=" mt-2 p-2 d-flex flex-column">
                
               <div className="mt-2">
     {progress === 100 && metricsError && (
         <p className="text-align-center text-light">Fetching Metrics...</p>
     )}
     {progress === 100 && !metricsError && (
         <div className="p-2 mt-2">
             {learningRateChartData ? (
                 <Line data={learningRateChartData} options={data.options1} />
             ) : (
                 <p className="text-align-center text-light">No learning rate data available.</p>
             )}
             {lossChartData ? (
                 <Line data={lossChartData} options={data.options2} />
             ) : (
                 <p className="text-align-center text-light">No loss data available.</p>
             )}
         </div>
     )}
    
 </div>
 
                 
               </div>
             </div>
          )}
        
        </div>
      </div>
    </div>
  );

  
};

export default TraininRewardPPO;
