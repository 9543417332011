// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepperheading {
  font-weight: bolder;
}
.reward-navbar-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 5rem;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}

.horizontalline {
  width: 95%;
  border: 2px solid #212542;
  margin-bottom: 5px;
  margin-left: 8px;
}
.horizontallineParameters {
  width: 100%;
 border: 2px solid #2F499E;
  background: transparent;
}

.rewardModal .modal-dialog {
  max-width: 450px;
}
.rewardModal .modal-body {
  padding: 16px 32px;
}
.rewardModal .modal-footer {
  border-top: 0px;
  padding-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModelOrchestrator/FineTunining/RewardAndPFO.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;CACZ,yBAAyB;EACxB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".stepperheading {\n  font-weight: bolder;\n}\n.reward-navbar-header {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  height: 5rem;\n  width: 100%;\n  padding: 30px;\n  box-sizing: border-box;\n}\n\n.horizontalline {\n  width: 95%;\n  border: 2px solid #212542;\n  margin-bottom: 5px;\n  margin-left: 8px;\n}\n.horizontallineParameters {\n  width: 100%;\n border: 2px solid #2F499E;\n  background: transparent;\n}\n\n.rewardModal .modal-dialog {\n  max-width: 450px;\n}\n.rewardModal .modal-body {\n  padding: 16px 32px;\n}\n.rewardModal .modal-footer {\n  border-top: 0px;\n  padding-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
