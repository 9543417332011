import "./ModelHomeScreen.css";
import Button from "react-bootstrap/Button";
import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import Card from "react-bootstrap/Card";
import CompareModels from "./compareModels";
import JsonData from "./tableData.json";
import DotIcon from "./dotIcon.png";
// import StarIcon from "./starIcon.svg";
import axios from "axios";
import LoadModal from "./LoadModal";
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import ChatPopup from "../../AChain/ChatPopup/ChatPopup";
import plusicon from "../../../assets/img/plusIcon.svg"
import plusIconDark from "../../../assets/img/plusIconDark.png"
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import AlchemTableNav from "../../SharedComponents/AlchemTableNav/AlchemTableNav";
import AlchemPagination from "../../SharedComponents/AlchemPagination/AlchemPagination";
import { AuthContext } from "../../Auth/AuthContext";
import { DockContext } from "../../DockWrapper/DockContext";
const RewardModelHome = ({ darkMode, handleToggle }) => {
  const {
    isDocked
  } = useContext(DockContext);
  const {accessToken} = useContext(AuthContext)

  const filters = [
    {
      label: "Base Model",
      options: ["All", "Mistral-7b", "Falcon"],
      selectedOption: "All",
    },
  ];
  const links = [
    { name: 'Model Orchestrator', route: '/rewardmodelhome',isActive: true },
    { name: 'Agent Factory', route: '/agentFactory',isActive: false },
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [dummyData, setdummyData] = useState([]);
  const [createdby, setcreatedby] = useState({
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman"
  });

  const [baseModelMapping, setbaseModelMapping] = useState({
    1: "falcon",
    2: "bloom",
    3: "flan t5",
    4: "Llama 2",
    7: "GLM",
    8: "mistral-7b"
  });

  const [taskList, settaskList] = useState({
    1: "Summarization",
    2: "QA",
    3: "Text Generation"
  });

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(()=> {
    axios.get("http://74.235.164.4:8300/GetModels")
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.error(error);
    })
  }, [])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/finetuned_models`,{
        headers:{
          'Content-Type':'application/json',
          Authorization: `${accessToken}`
        }
      })
      .then(response => {
        setdummyData(response.data.data);
        console.log("Fetch agents success : ");
      })
      .catch(error => {
        console.error(error);
      });

    // openModal();
  }, []);

  const handleRowSelection = index => {
    if (selectedRow === index) {
      // setSelectedRow(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow(index); // Select the clicked row
      localStorage.setItem("finebasemodelid", JSON.stringify(index + 1));
      console.log(index + 1);
    }
  };
  const sortedData = dummyData.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateA - dateB;
  });

   //pagination code
   const [currentPage, setCurrentPage] = useState(1);
   const recordsPerPage = 5;
   const totalPages = Math.ceil(dummyData.length / recordsPerPage);
 
   const handlePageChange = (pageNumber) => {
     setCurrentPage(pageNumber);
   };
 
   const startIndex = (currentPage - 1) * recordsPerPage;
   const endIndex = startIndex + recordsPerPage;
   const paginatedModels = dummyData.slice(startIndex, endIndex);

  // console.log(dummyData);
  console.log(sortedData, "adasdasdasdasdasd");
  const DisplayData = paginatedModels.reverse().map((info, i) => {
    const isSelected = selectedRow === i;
    const created_at = info.created_at.split(" ");
    const domain = info.usecase.replace(/"/g, "");


   

    return (
      <>
        <tr
          key={i}
          className="ModelTableRow"
          // style={{ opacity: isSelected ? 1 : 0.5 }}
        >
          <td style={{border: "none"}}></td>
          <td
            data-toggle="collapse"
            data-target={"#rowId" + i}
            className="accordion-toggle"
            aria-expanded="false"
            aria-controls={"#rowId" + i}
            style={{ cursor: "pointer" }}
          >{darkMode ? 
            <img src={plusIconDark} alt="plus" /> :  <img src={plusicon} alt="plus" />
          }</td>
          <td className="custom-radio">
            <input
              type="radio"
              className="checkbox "
              id="myRadio"
              checked={isSelected}
              style={{ border: '1px solid #BAC9F1' }}
              onClick={() => handleRowSelection(i)}
            />
          </td>
          <td>{info.name}</td>
          <td>{info.description}</td>
          <td className="text-capitalize">
            {baseModelMapping[info.base_model_id]}
          </td>
          <td>{info.model_type}</td>
          <td>{taskList[info.task_id]}</td>
          <td>{domain}</td>
          <td>
            <div className="statusContainer">
              <div
                className="circle"
                style={{
                  background:
                    info.status == "Just created"
                      ? "#1FEB00"
                      : info.status == "Finetuned"
                      ? "#1FEB00"
                      : "#3366FF"
                }}
              ></div>{" "}
              {info.status}
            </div>
          </td>
          <td style={{border: "none"}}></td>
          {/* {info.rating == "" || info.rating == null ? (
          <td>-</td>
        ) : (
          <td>
            <img className="img-fluid icon" src={StarIcon} alt="" />{" "} {info.rating}
          </td>
        )} */}

          {/* <td>
            <button>
              <img
              className="img-fluid icon"
              loading="lazy"
              src={DotIcon}
              alt=""
            />
            </button>
          </td> */}
        </tr>
        <tr>
          <td style={{border: "none"}} className="hiddenRow"></td>
          <td colspan="9" className="hiddenRow border-bottom">
            <div
              class="collapse"
              id={"rowId" + i}
              style={{overflow: "hidden"}}
            >
              <div className=" container-fluid px-5 subTable_outer" style={{background: darkMode ? '#34385A' : '', borderRadius: '4px'}}>
                <table className={`table alchemTable ${darkMode ? 'alchemSubTableDark' : 'alchemSubTable'} subTable mb-0`}>

                <thead>
                  <tr className="info">
                    <th>Purpose</th>
                    <th>Status</th>
                    <th>Updated by</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{info.purpose}</td>
                    <td>{info.status}</td>
                    <td>{createdby[info.updated_by]}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  });


  return (
    <div>
      <ChatPopup type="MODEL" />
      {/* <LoadModal showModal={showModal} closeModal={closeModal}/> */}
      <LoadModal
        showModal={showModal}
        closeModal={closeModal}
        darkMode={darkMode}
      />
      {/* <AlchemNav
        darkMode={darkMode}
        handleToggle={handleToggle}
        module={{ name: "agentFactory", state: "nowizard" }}
      /> */}
      <CustomNavbar links={links} profile={true} handleToggle={handleToggle}/>
      <div className=" py-0" style={{ minHeight: "82vh", padding: "40px" }}>
        {/* <div className="box_container">
          {dummyData.map((data, i) => (
            <div className="box" key={i}>
              <p className="value">{data.value}</p>
              <p className="title">{data.title}</p>
            </div>
          ))}
        </div> */}
        <nav className="navbar navbar-expand-lg pb-3">
          <div className="container-fluid px-0 pt-3">
          <span className={darkMode ? 'text-white page-title-dark' : 'text-dark page-title-dark'}>All Models</span>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav ms-auto px-0">
                <NavLink to={""}>
                  <button
                    class={
                      darkMode
                        ? "btn btn-primary submitBtnDark me-3"
                        : "btn btn-primary submitBtnDark me-3"
                    }
                    role="button"
                    disabled
                  >
                    Compare Models
                  </button>
                </NavLink>
                <NavLink to={"http://74.235.164.4:8888/"}>
                  <button
                    class={
                      darkMode
                        ? "btn btn-primary submitBtnDark me-3"
                        : "btn btn-primary submitBtnDark me-3"
                    }
                    role="button"
                  >
                    H2O Portal
                  </button>
                </NavLink>
                {selectedRow == null ? (
                  <NavLink to={""}>
                    <button
                      class={
                        darkMode
                          ? "btn btn-primary submitBtnDark me-0"
                          : "btn btn-primary submitBtnDark me-0"
                      }
                      role="button"
                      onClick={() => openModal()}
                    >
                      + Configure Model
                    </button>
                  </NavLink>
                ) : (
                  <NavLink>
                    {/* to={"/modelOrchestrator"} */}
                    <button
                      class={
                        darkMode
                          ? "btn btn-primary submitBtnDark me-3"
                          : "btn btn-primary submitBtn me-3"
                      }
                      role="button"
                      onClick={() => openModal()}
                    >
                      + Configure Model
                    </button>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </nav>
        <AlchemTableNav filters={filters} showFilters={true} showSearchbar={true} darkMode={true} navbarBrand=""/>
        <Card className={darkMode ? "tableCardDark" : "tableCard"}>
          <Card.Body className={isDocked?"p-4 disable-scrollbars":"p-4"}>
            <table 
              class={
                darkMode
                  ? "table alchemTableDark allModelTable mb-0"
                  : "table alchemTable allModelTable mb-0"
              }
            >
              <thead className={"custom-table-head-dark " + darkMode?"dark-text":""}>
                <tr className="ModelTableRow">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Model Name</th>
                  <th>Model Description</th>
                  <th>Base Model</th>
                  {/* <th>Data Created</th> */}
                  <th>Model Type</th>
                  <th>Task</th>
                  <th>Domain</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          </Card.Body>
        </Card>
        <AlchemPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
      <footer className="copyright copyright-footer">
        Copyright © TCS 2024. All Rights Reserved.
      </footer>
    </div>
  );
};

export default RewardModelHome;
