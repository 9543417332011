import React from "react";
import { Outlet } from "react-router-dom";
import CustomNavbar from "../CustomNavbar/CustomNavbar";

export default function Agentfactory({ handleToggle }) {

  return (
    <>
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
      <Outlet />
      <footer className="copyright copyright-footer mt-6">
        Copyright © TCS 2024. All Rights Reserved.
      </footer>
    </>
  );
}
