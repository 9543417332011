import React, { useContext, useEffect, useState } from 'react'
import styles from "./SmeSummary.module.css"
import PropTypes from 'prop-types'
import NavBar from '../navBar/NavBar'
import { Nav } from 'react-bootstrap'
import { DockContext } from '../../DockWrapper/DockContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import "./sidebarModal.css"
import SmeChainModelView from './SmeChainModelView'
import dottedPatternBG from '../../../assets/img/doted-patternbg.png'
import CustomNavbar from '../../CustomNavbar/CustomNavbar'



export default function SmeSummaryModelView() {

    const [view, setView] = useState('models')

    const {
        activity_master_json,
        setActivity_master_json,
        selectedKnowledgeWork
    } = useContext(DockContext)

    const navigate = useNavigate()
    const [paramsData, setParamsData] = useState([])

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${selectedKnowledgeWork.id}`
            )
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data)
                    if (res.data.is_master_json) {
                        setActivity_master_json(res.data)

                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (!selectedKnowledgeWork) {
            navigate("/activityList")
        }
    }, [selectedKnowledgeWork])


    const HandleParamsPopulation = (data) => {
        console.log(data)
        if (data) {
            console.log(data)
            setParamsData(data)
            return ""
        }
        setParamsData([])

    }


    const handleRedirectToAgentEdit = () => {

        navigate("/smeAgentCreation")
    }

    const handleRedirectToModelSelection = () => {
        navigate("/agentMapping")
    }

    const populateTask = async (item) => {
        console.log(item)
        let output = {}

        try {
            const res = await axios.post(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/tasks/`,
                {
                    "description": item.description,
                    "expected_output": item.expected_output
                })
            console.log(res.data)
            output = res.data

        } catch (error) {
            console.log(error)
        }


        console.log(output)
        return output

    }


    const populateActivity = async (activity, tasksFromDB) => {

        console.log(activity, tasksFromDB)
        let output = {}
        try {
            const res = await axios.post(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/activities/`,
                {
                    "name": activity.name,
                    "description": activity.description,
                    "role": activity.role,
                    "goal": activity.goal,
                    "human_input_required": activity["human_input_required"],
                    "status": activity.status,
                    "finetuned_model_type": activity["finetuned_model_type"],
                    "predictive_model_id": 0,
                    "generative_model_id": 0,
                    "kpi": activity.kpi,
                    "tasks": tasksFromDB
                })

            console.log(res.data)
            output = res.data
        } catch (error) {
            console.log(error)
        }

        console.log(output)
        return output
    }


    const populateKnowledgeWork = async (item) => {
        console.log(item)
        let output = false
        try {

            const updatedActivities = activity_master_json.activities.concat(item)
            console.log(updatedActivities)

            const res = await axios.patch(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${selectedKnowledgeWork.id}`,
                {
                    "activities": updatedActivities
                })
            console.log(res.status)
            if (res.status == 200) {
                output = true
                setActivity_master_json(res.data)
            } else {

                output = false
            }

        } catch (error) {
            console.log(error)
        }


        console.log(output)
        return output
    }


    const handlePopulateDB = async (activity) => {
        try {
            const activitiesFromDB = []
            // Loop through each activity  
            const tasksFromDB = []
            // Populate tasks and wait for all tasks to be populated before continuing  
            await Promise.all(activity.tasks.map(async (item) => {
                const task = await populateTask(item)
                tasksFromDB.push(task)
            })).then(async () => {
                console.log(tasksFromDB)

                // Populate activity with populated tasks  
                let updatedActivity = await populateActivity(activity, tasksFromDB)
                activitiesFromDB.push(updatedActivity)
            })

            // Populate knowledge work with populated activities
            console.log(activitiesFromDB)
            if (activitiesFromDB.length > 0) {
                const response = await populateKnowledgeWork(activitiesFromDB)
                return { status: response, message: response ? "successfully populated" : "Something went wrong" }
            }
        } catch (error) {
            return { status: false, message: `${error}` }
        }
    }

    const [agentCreationLoading, setAgentCreationLoading] = useState(false)

    const HandleAgentCreation = async (activity) => {

        console.log("agent creation started ")
        console.log(activity)

        setAgentCreationLoading(true)

        const isAgentCreated = await handlePopulateDB(activity)

        console.log(isAgentCreated)


        if (isAgentCreated.status === true) {
            setAgentCreationLoading(false)
        }

        if (isAgentCreated.status === false) {
            alert(`creation failed please retry, error:${isAgentCreated.messgae}`)
        }

    }


    const cellDetails = (props) => {

        console.log(props)
        return (
            <div className={`p-1 d-flex justify-content-start flex-row  align-items-start`} style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 0px 30px #647B9229', borderRadius: '6px' }}>
                <div className='flex flex-row'>
                    <div className='mt-2 ms-2 rounded-circle text-center' style={{ background: '#83A0F7 0% 0% no-repeat padding-box', width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{props?.idx}</div>
                </div>
                <div className='d-flex flex-column w-100'>
                    <div className='w-100 d-flex flex-row pt-2 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                        {props?.name}
                    </div>
                    <div className='w-100 d-flex flex-row ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                        Intelligence:&nbsp;<span style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>{props?.finetuned_model_type}</span>
                    </div>
                    <div className='w-100 d-flex flex-row ps-4 pe-4' style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>
                        {props?.description}
                    </div>
                </div>
            </div>
        )


    }

    const modelDetails = (item) => {
        const originalItem = item
        console.log(item)
        const activity = item
        const name = activity.finetuned_model_type + "_model"
        console.log(name)
        console.log(item[name], activity[name])
        item = activity[name]
        if (item) {

            return (
                <>
                    <div className={`p-1 d-flex justify-content-start flex-row  align-items-start`} style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0px 0px 30px #647B9229', borderRadius: '6px' }}>
                        <div className='flex flex-row'>
                            <div className='mt-2 ms-2 rounded-circle text-center' style={{ background: '#83A0F7 0% 0% no-repeat padding-box', width: '24px', height: '24px', font: 'normal normal bold 14px/17px Calibri', letterSpacing: '0px', color: '#012060', lineHeight: '150%', flexShrink: '0' }}>{item?.idx}</div>
                        </div>
                        <div className='d-flex flex-column w-100'>
                            <div className='w-100 d-flex flex-row pt-2 ps-4 pe-4 pb-2' style={{ font: 'normal normal bold 14px/14px Calibri', letterSpacing: '0px', color: '#080505' }}>
                                {item?.base_model}
                            </div>
                            <div className='w-100 d-flex flex-row ps-4 pe-4' style={{ font: 'normal normal 14px/14px Calibri', letterSpacing: '0px', color: '#636363' }}>
                                {item?.name}
                            </div>
                        </div>
                    </div>
                    <div className={`p-1 d-flex justify-content-start flex-row  align-items-start h-100`}>
                        <i className="fa-solid fa-note-sticky m-1  fs-5" style={{ color: "#8f8fb9" }} ></i>
                        <div className={`d-flex flex-column w-100 justify-content-around h-100 gap-2`} >

                            <div className={`d-flex align-items-center justify-content-between`}>
                                <div className='d-flex align-items-center gap-1'>
                                    <h4 className={`m-0 text-white font-weight-medium text-capitalize fs-5`}>{item["base_model"] ? item["base_model"] : "No Model To Display"}</h4>
                                </div>
                            </div>

                            <p className='text-primary align-self-end m-0 p-1 rounded' type="button" data-bs-toggle="modal" data-bs-target="#side-bar-modal" onClick={() => HandleParamsPopulation(item["hyper_parameters"])} >Hyper Parameters</p>

                        </div >
                    </div>
                </>
            )
        }

        return <div className='d-flex justify-content-center aligin'>
            No Recommended Model
        </div>

    }

    const agentDetails = (item, index, activityData) => {

        try {
            const data = item.find((activity) => activity.name === activityData.name)
            if (data) {

                return (
                    <div className={`p-1 d-flex justify-content-start flex-row  align-items-start`} >
                        <i className="fa-solid fa-note-sticky m-1  fs-5" style={{ color: "#8f8fb9" }} ></i>

                        <div className='d-flex flex-column w-100'>

                            <div className={`d-flex align-items-center justify-content-between`}>
                                <div className='d-flex align-items-center gap-1'>
                                    <h4 className={`m-0 text-white font-weight-medium text-capitalize fs-6`} >{data["name"]}</h4>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <a className='fs-6 text-primary' onClick={handleRedirectToAgentEdit} >Edit</a>
                                    <i className="fa-solid fa-pencil text-white "></i>
                                </div>
                            </div>

                            {data["tool_type"] &&
                                <p style={{ color: "cyan" }}>
                                    Intelligence: {data["tool_type"]}
                                </p>
                            }

                            <p className="m-0" style={{ color: "#c5dfc4" }}>
                                {data["description"]}
                            </p>

                        </div>


                    </div>
                )
            }

        }
        catch (error) {
            console.log(error)
        }


        const isHasObject = item[index - 1] ? Object.keys(item[index - 1]).length : 0

        if (index == 0 || isHasObject > 0) {
            return (
                <div className='d-flex justify-content-center align-items-center h-100'>
                    {
                        agentCreationLoading ?
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            :
                            <button className='btn submitBtnDark' onClick={() => { HandleAgentCreation(activityData) }}>
                                <i className="fa-solid fa-plus m-1"></i>
                                Create Agent
                            </button>
                    }

                </div>
            )
        }

        return ""
    }


    const populateTable = () => {
        console.log(activity_master_json?.master_json?.knowledge_work?.activities)
        if (!activity_master_json || activity_master_json?.master_json?.knowledge_work?.activities?.length < 1) {
            console.log("there is a spinner")
            return (
                <div className='d-flex w-100 justify-content-center align-items-center p-4'>
                    <p className="placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </p>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>

                </div>
            )
        } else {
            return activity_master_json?.master_json?.knowledge_work?.activities.map((data, i) => {
                console.log(data)
                console.log(data[`${data["finetuned_model_type"]}"_model"`])
                data['idx'] = i + 1

                return <tr key={i} className=' '>
                    <td className='' style={{ width: "20%", minWidth: "30%", maxWidth: "33.3%" }} >
                        {cellDetails(data)}
                    </td>
                    <td className='' style={{ width: "20%", minWidth: "30%", maxWidth: "33.3%" }} >

                        {/* model population logic changed */}
                        {
                            modelDetails(data)
                        }

                    </td>

                    <td className='' style={{ width: "20%", minWidth: "30%", maxWidth: "33.3%" }} >

                        {
                            agentDetails(activity_master_json?.activities, i, data)
                        }

                    </td>
                </tr>

            })

        }



    }

    useEffect(() => {
        console.log(selectedKnowledgeWork)
    }, [selectedKnowledgeWork])

    let subtitle = `Knowledge Work: ${selectedKnowledgeWork.name}`;
    let title = "Create Knowledge Work";
    let steps = ["Context Gathering", "Context Formalizing into Activities", "Knowledge Activity Validation", "Agent Chain Mapping", "Agent Chain Test"];
    const aChainToCahtPopFun = (props) => {

        console.log("aChainToCahtPopFun- 123", props);
    }


    const navSummary = []


    return (<>
        <div style={{ minHeight: '100vh' }}>
            <CustomNavbar links={navSummary} isSummary={false} profile={true} />
            <SmeChainModelView agentData={activity_master_json} setView={setView}></SmeChainModelView>
        </div>
        <></>
    </>
    )
}

