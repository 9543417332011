
import React, { useContext } from 'react';
import { Navbar, Nav, Form, FormControl, Button,NavDropdown,InputGroup } from 'react-bootstrap';
import { AuthContext } from '../../Auth/AuthContext';
import { CiSearch } from "react-icons/ci";

import searchIcon from '../../../assets/img/searchIcon.png'

const AlchemTableNav = ({ filters, showFilters, showSearchbar,navbarBrand }) => {
    const {darkMode} = useContext(AuthContext)
  return (
    <Navbar expand="lg" className={darkMode ? "px-4  ModelNavbarContainer d-flex" : " px-4 ModelNavbarContainer whiteBg"}>
      {showFilters && (
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      )}
      <Navbar.Collapse id="basic-navbar-nav">
      {navbarBrand && (
        <Navbar.Brand  style={{
            color: darkMode ? "#E5EAF1" : "",
            textTransform: "inherit",
            fontWeight: "bold"
          }}>{navbarBrand}</Navbar.Brand>
      )}
      <Nav className=" my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
      {showFilters && (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {filters.map((filter, index) => (
            <div key={index}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '1rem' }}>
                <div className="my-auto text-capitalize" style={{ color: darkMode ? "#E5EAF1" : "" }}>
                    {filter.label}
                </div>
                <NavDropdown
                    className={
                        darkMode
                            ? "btn text-capitalize form-select modelSelectDropdownDark dropdown-placeholder-dark dropdown-box-dark"
                            : "btn text-capitalize form-select modelSelectDropdown dropdown-placeholder-dark dropdown-box-dark"
                    }
                    title={<p style={{color: darkMode ? '#BAC9F1' : '#000'}}>{filter.selectedOption}</p>}
                    id={`navbarScrollingDropdown-${index}`}
                    style={{ color: filter.selectedOption === "All" ? "white" : "" }}
                >
                    {filter.options.map((option, optionIndex) => (
                        <NavDropdown.Item key={optionIndex} href={`#action-${optionIndex}`}>
                            {option}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            </div>
        ))}
    </div>
) }
        </Nav>
        {showSearchbar && (
          <InputGroup
          className={
            darkMode
              ? "ms-auto searchWidth alchemSearchGroupDark"
              : "ms-auto searchWidth alchemSearchGroup"
          }
        >
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon2"
            className="searchBar"
          />
          <Button
            variant="outline-secondary"
            id="searchButton"
            className="searchGroupBtn"
          >
            <CiSearch style={{fontSize: "18px", color: "#E5EAF1"}}/>

          </Button>
        </InputGroup>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AlchemTableNav;