// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b-line:active {
  border-bottom: 3px solid #fff !important;
  height: 100% !important;

}

.competencydetails {
  min-height: 10%;
  height: 10%;
}

.competencydetails .alchemNavLinks .nav-link.active {
  border-bottom: 3px solid #3366FF;
  font-family: "calibri-bold";
  height: 3.875rem;
  padding: 0px !important;
}


.competencydetails .navbar-nav {
  gap: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/AgentChainModule/ModelCompetancy/competencyDetailsNav.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,uBAAuB;;AAEzB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,gBAAgB;EAChB,uBAAuB;AACzB;;;AAGA;EACE,oBAAoB;AACtB","sourcesContent":[".b-line:active {\n  border-bottom: 3px solid #fff !important;\n  height: 100% !important;\n\n}\n\n.competencydetails {\n  min-height: 10%;\n  height: 10%;\n}\n\n.competencydetails .alchemNavLinks .nav-link.active {\n  border-bottom: 3px solid #3366FF;\n  font-family: \"calibri-bold\";\n  height: 3.875rem;\n  padding: 0px !important;\n}\n\n\n.competencydetails .navbar-nav {\n  gap: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
