import React from "react";
import { Col, Container, Modal, NavDropdown } from "react-bootstrap";
import styles from "./UploadIconModalCompetency.module.css";
import x_mark from "../../../../src/assets/img/x_mark.svg";
import UploadDragZone from "./UploadDragZone";
import downArrow from "./asset/down_arrow.svg";

const UploadIconModalCompetency = ({ showModal, closeModal, content }) => {
  const handleSubmit = () => {
    closeModal();
  };
  return (
    <div className={styles.mod}>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="configure-dragzone-modal-wrapper"
      >
        <Modal.Body className="p-0 border-0">
          <div>
            <div className={styles.cross}>
              <img src={x_mark} onClick={closeModal}></img>
            </div>
            <div className={styles.modalTitle}>Knowledge Work Icon</div>
            <div className={styles.outer}>
              <div className={styles.dragNdrop + " col-4"}>
                <UploadDragZone />
              </div>
              <div className={styles.OR + " col-1"}>
                <div className={styles.bor}></div>
                <button className={styles.orButton}>OR</button>
                <div className={styles.bor}></div>
              </div>
              <div className={"h-100 p-2 col-7"}>
                <div className=" pl-4 pr-1 pt-1 pb-1 mb-1 text-center d-flex justify-content-start align-items-center gap-3 ">
                  <h6 className="mt-1" style={{ fontSize: "14px" }}>
                    Select from Default Icons
                  </h6>

                  <NavDropdown
                    className={"btn  my-auto pe-2 " + styles.modalDropDown}
                    title={
                      <div
                        className={
                          "d-flex justify-content-between align-items-center" +
                          styles.drop
                        }
                      >
                        All Domain
                        <img src={downArrow} className={styles.downArrow} />
                      </div>
                    }
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
                <div className="d-flex flex-wrap gap-3 ms-3  align-items-center p-1 ">
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                  <Col xs={3} className={styles.rightCol}></Col>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer id="footer">
          <a
            class="btn btn-primary cancelBtn me-3"
            onClick={closeModal}
            role="button"
          >
            CANCEL
          </a>
          <a
            class="btn btn-primary submitBtn me-3"
            onClick={handleSubmit}
            role="button"
          >
            PROCEED
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadIconModalCompetency;
