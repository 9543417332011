import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './StepsNavbar.css'; // Make sure to create this CSS file
 
const StepsNavbar = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Step 1', 'Step 2', 'Step 3'];
 
  const goToStep = stepIndex => {
    setActiveStep(stepIndex);
  };
 
  return (
    <Container>
      <Row>
        <Col md={3}>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`step ${index <= activeStep ? 'active' : ''}`}
              onClick={() => goToStep(index)}
            >
              {step}
            </div>
          ))}
        </Col>
        <Col md={9}>
          {/* Content of the current step */}
          <div>
            {`Content for ${steps[activeStep]}`}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
 
export default StepsNavbar;