import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse'; // CSV parser library
import { useNavigate } from 'react-router-dom';
import './DataSynthesizerSeed.css'
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const DataSynthesizerSeed = ({ stepIndex, messages, knowledgeWorkDetails,selectedItems,id }) => {
  const navigate = useNavigate();
  const [stepMessages, setStepMessages] = useState(messages[stepIndex] || []);
  const [hasDataset, setHasDataset] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [numericColumns, setNumericColumns] = useState([]);
  const [catColumns, setCatColumns] = useState([]);
  const [selectedNumericCols, setSelectedNumericCols] = useState([]);
  const [selectedCatCols, setSelectedCatCols] = useState([]);
  const [numberOfRows, setNumberOfRows] = useState('');
  const [showRowInput, setShowRowInput] = useState(false);
  const [conditions, setConditions] = useState(['']);
  const [showConditionsInput, setShowConditionsInput] = useState(false);
  const [modelPath, setModelPath] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: 'tech', label: 'Technology' },
    { value: 'medical', label: 'Medical' },
    { value: 'finance', label: 'Finance' },
    { value: 'education', label: 'Education' },
    // Add more options as needed
  ]);
  const [subDomainOptions, setSubDomainOptions] = useState([
    { value: 'cardio', label: 'Cardio' },
    // { value: 'medical', label: 'Medical' },
    // { value: 'finance', label: 'Finance' },
    // { value: 'education', label: 'Education' },
    // Add more options as needed
  ]);
  const [attributes,SetAttributes] = useState("")
  const [relationships,setRelationships] = useState("")
  const [constraints,setConstraints] = useState("")
  const [numberOfDatapoints,setNumberOfDatapoints] = useState("")
  const [dataDownloadFormat, setDataDownloadFormat] = useState([
    { value: 'csv', label: 'csv' },
    { value: 'xlsx', label: 'xlsx' },

    // Add more options as needed
  ])

  const fileInputRef = useRef(null);

  const getMarkdownHTML = (markdown) => {
    const rawHTML = marked(markdown);
    return { __html: DOMPurify.sanitize(rawHTML) };
  };


const handleButtonClick = async (response) => {
  if (response === "Yes") {
    if (stepMessages.length === 0) {
      setHasDataset(true);
      setShowUpload(true);
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: 'I have Dataset' },
        { sender: 'ai', text: "Please upload your seed data." }
      ];
      setStepMessages(updatedMessages);
    } else if (stepMessages[stepMessages.length - 1].text === "Do you have seed data to start with?") {
      setHasDataset(true);
      setShowUpload(true);
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: response },
        { sender: 'ai', text: "Please upload your seed data." }
      ];
      setStepMessages(updatedMessages);
    }
  } else if (response === "No") {
    if (stepMessages.length === 0) {
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: "I don't have Dataset" },
      ];
      setStepMessages(updatedMessages);

      try {
        // Call the API using axios
        const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
          messages: {
            role: 'user',
            content: "i don't have dataset"
          },
          new_chat: false
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const { data } = response;
        const assistantMessage = data.find(item => item.role === 'assistant');

        if (assistantMessage) {
          // Add the AI message to the stepMessages state
          const updatedMessages = [
            ...stepMessages,
            { sender: 'user', text: "I don't have Dataset" },
            { sender: 'ai', text: assistantMessage.content, dropdown: assistantMessage.dropdown || false } 
          ];
          setStepMessages(updatedMessages);
        }
        // Handle the API response if needed
      } catch (error) {
        console.error('API call error:', error);
        // Handle the error if needed
      }
    }
  }
};

const handleDropdownChange = async (selectedValue) => {
  console.log('Selected value:', selectedValue);

  // Update messages with the selected value
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: selectedValue },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
      messages: {
        role: 'user',
        content: selectedValue
      },
      new_chat: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const { data } = response;
    const assistantMessage = data.find(item => item.step === 2 && item.role === 'assistant');
    console.log(assistantMessage);

    if (assistantMessage.step === 2) {
      // Add the AI message to the stepMessages state
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: selectedValue },
      { sender: 'ai', text: assistantMessage.content, subdomain: assistantMessage.subdomain || false }
      ];
      setStepMessages(updatedMessages);
    }
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
};
const handleDataDownloadFormatChange = async (selectedValue) => {
  console.log('Selected value:', selectedValue);

  // Update messages with the selected value
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: selectedValue },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/download/', {
      "id": localStorage.getItem('synthesizer_dataset_id'),
      "file_type": selectedValue
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob' // Ensure that the response type is blob
    });

    // Determine the file extension based on selectedValue
    const fileExtension = selectedValue === 'xlsx' ? 'xlsx' : 'csv';

    // Create a URL for the file blob and download it
    const fileURL = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileURL;
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); 
    link.download = `${knowledgeWorkDetails.knowledge_work.name}_${timestamp}.${fileExtension}`; // Specify the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
   
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
};

const handleSubdomainChange = async (selectedValue) => {
  console.log('Selected value:', selectedValue);

  // Update messages with the selected value
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: selectedValue },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
      messages: {
        role: 'user',
        content: selectedValue
      },
      new_chat: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const { data } = response;
    const assistantMessage = data.find(item => item.step === 3 && item.role === 'assistant');

    if (assistantMessage.step === 3) {
      // Add the AI message to the stepMessages state
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: selectedValue },
      { sender: 'ai', text: assistantMessage.content,step:assistantMessage.step, subdomain: assistantMessage.subdomain || false }
      ];
      setStepMessages(updatedMessages);
    }
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
};
const handleAttributesChange = (event) => {
  SetAttributes(event.target.value);
};
const handleSendAttributes = async() => {
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: attributes },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
      messages: {
        role: 'user',
        content: attributes
      },
      new_chat: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const { data } = response;
    const assistantMessage = data.find(item => item.step === 4 && item.role === 'assistant');

    if (assistantMessage.step === 4) {
      // Add the AI message to the stepMessages state
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: attributes },
      { sender: 'ai', text: assistantMessage.content,step:assistantMessage.step, subdomain: assistantMessage.subdomain || false }
      ];
      setStepMessages(updatedMessages);
    }
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
}
const handleRelationshipsChange = (event) => {
  setRelationships(event.target.value);
};
const handleRelationships = async() => {
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: relationships },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
      messages: {
        role: 'user',
        content: relationships
      },
      new_chat: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const { data } = response;
    const assistantMessage = data.find(item => item.step === 5 && item.role === 'assistant');

    if (assistantMessage.step === 5) {
      // Add the AI message to the stepMessages state
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: relationships },
      { sender: 'ai', text: assistantMessage.content,step:assistantMessage.step, subdomain: assistantMessage.subdomain || false }
      ];
      setStepMessages(updatedMessages);
    }
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
}
const handleConstraintsChange = (event) => {
  setConstraints(event.target.value);
};
const handleConstraints = async() => {
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: constraints },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
      messages: {
        role: 'user',
        content: constraints
      },
      new_chat: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const { data } = response;
    const assistantMessage = data.find(item => item.step === 6 && item.role === 'assistant');

    if (assistantMessage.step === 6) {
      // Add the AI message to the stepMessages state
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: constraints },
      { sender: 'ai', text: assistantMessage.content,step:assistantMessage.step, subdomain: assistantMessage.subdomain || false }
      ];
      setStepMessages(updatedMessages);
    }
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
}
const handleNumberOfDatapointsChange = (event) => {
  setNumberOfDatapoints(event.target.value);
};
const handleNumberOfDatapoints = async() => {
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: numberOfDatapoints },
  ];
  setStepMessages(updatedMessages);

  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/chatbot/1?user_id=1', {
      messages: {
        role: 'user',
        content: numberOfDatapoints
      },
      new_chat: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const { data } = response;
    const assistantMessage = data.find(item => item.step === 7 && item.role === 'assistant');

    if (assistantMessage.step === 7) {
      // Add the AI message to the stepMessages state
      const updatedMessages = [
        ...stepMessages,
        { sender: 'user', text: numberOfDatapoints },
      { sender: 'ai', text:'',step:assistantMessage.step, subdomain: assistantMessage.subdomain || false }
      ];
      setStepMessages(updatedMessages);
    }
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
}
const handleGenerateData = async() => {
  const updatedMessages = [
    ...stepMessages,
    { sender: 'user', text: 'Generate' },
    { sender: 'ai', text: 'Generating' },
  ];
  setStepMessages(updatedMessages);
  
  try {
    // Call the API using axios
    const response = await axios.post('http://127.0.0.1:8006/api/syntheticdata/generate/all', {
      "number": numberOfDatapoints,
      "user_id": "1",
      "data_name": "test data 3",
      "domain": "medical",
      "subdomain": "cardio",
      "description": "some desc"
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const dataset_id = response.data.data_id;
    console.log(dataset_id);
    localStorage.setItem('synthesizer_dataset_id', dataset_id)
    setStepMessages(prevMessages => [
      ...prevMessages,
      { sender: 'ai', text: 'Data Generation Completed, Please Select in which format you want to download.',step:8 }
    ]);
    
  } catch (error) {
    console.error('API call error:', error);
    // Handle the error if needed
  }
}

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const userId = 1; // Replace with the actual user ID
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(`http://localhost:8003/api/syntheticdata/available/upload_data?user_id=${userId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        Papa.parse(selectedFile, {
          complete: (result) => {
            const headers = result.data[0];
            const rows = result.data.slice(1);
            setHeaders(headers);
            setTableData(rows);

            const numericCols = headers.map(header => ({
              name: header,
              selected: false
            }));
            const catCols = headers.map(header => ({
              name: header,
              selected: false
            }));
            
            setNumericColumns(numericCols);
            setCatColumns(catCols);

            setStepMessages(prevMessages => [
              ...prevMessages,
              { sender: 'ai', text: 'File uploaded successfully.' },
              { sender: 'ai', text: 'Here is the uploaded data:' },
              { sender: 'ai', text: 'Select numeric columns and categorical columns from the table.' }
            ]);
          },
          header: false,
          skipEmptyLines: true,
        });
      } catch (error) {
        const errorMessage = "File upload failed. Please try again.";
        setStepMessages(prevMessages => [
          ...prevMessages,
          { sender: 'ai', text: errorMessage }
        ]);
      }
    }
  };

  const handleColumnSelect = (type, column, isChecked) => {
    if (type === 'numeric') {
      setNumericColumns(numericColumns.map(col =>
        col.name === column ? { ...col, selected: isChecked } : col
      ));
      setSelectedNumericCols(
        isChecked ? [...selectedNumericCols, column] : selectedNumericCols.filter(col => col !== column)
      );
    } else {
      setCatColumns(catColumns.map(col =>
        col.name === column ? { ...col, selected: isChecked } : col
      ));
      setSelectedCatCols(
        isChecked ? [...selectedCatCols, column] : selectedCatCols.filter(col => col !== column)
      );
    }
  };

  const handleSendSelection = async () => {
    const numericSelections = selectedNumericCols.join(', ');
    const catSelections = selectedCatCols.join(', ');

    const userMessage = `<b>Selections:</b></br><i>Numeric Columns:</i> ${numericSelections}</br><i>Categorical Columns:</i> ${catSelections}`;

    setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'user', text: userMessage }
  ]);
 // Prepare the request body for the API call
 const userId = 1; // Replace with the actual user ID
 const fileName = file ? file.name : ''; // Extract file name
 const requestBody = {
   user_id: userId,
   synthesizer: 'ctgan',
   file_name: fileName,
   beta_1: 0.4,
   beta_2: 0.9,
   epochs: 5,
   batch_size: 500,
   learning_rate: 0.0005,
   cat_cols: selectedCatCols,
   num_cols: selectedNumericCols
 };
// Send a message indicating that model training has started
setStepMessages(prevMessages => [
  ...prevMessages,
  { sender: 'ai', text: 'Model training started for your dataset.' }
]);

try {
  // Call the API with the request body
  const response = await axios.post('http://localhost:8003/api/syntheticdata/available/train_model', requestBody, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const model_path = response.data.model_path;
  console.log(model_path);
    setModelPath(model_path);
  // Add a confirmation message after successful API call
  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'ai', text: 'Model training completed. Let\'s move to further steps.' }
  ]);
  setShowRowInput(true);
  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'ai', text: 'Please enter how many rows you want to generate:' }
  ]);
} catch (error) {
  // Handle API call error
  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'ai', text: 'Failed to start model training. Please try again.' }
  ]);
}

};

const handleNumberOfRowsChange = (event) => {
  setNumberOfRows(event.target.value);
};




const handleSendNumberOfRows = () => {
  if (numberOfRows.trim() === '') {
    // Optionally, show an error message if the input is empty
    return;
  }

  // Send the user message with the number of rows
  const userMessage = `<b>Selected Number Of Rows</b>: ${numberOfRows}`;

  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'user', text: userMessage }
  ]);

  // Hide the input box
  setShowRowInput(false);

  // Optionally, you can add additional steps or API calls here
  // Show conditions input
  setShowConditionsInput(true)
  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'ai', text: 'Enter conditions you want to give to the model while generating data:' }
  ]);

};

const handleAddCondition = () => {
  setConditions([...conditions, '']);
};

const handleConditionChange = (index, value) => {
  const updatedConditions = [...conditions];
  updatedConditions[index] = value;
  setConditions(updatedConditions);
};
const handleSendConditions = async () => {
  const validConditions = conditions.filter(condition => condition.trim() !== '');
  if (validConditions.length === 0) {
    // Optionally, show an error message if no valid conditions are entered
    return;
  }

  const formattedConditions = validConditions.map((condition, index) => `- ${condition}`).join('<br>');
  const userMessage = `<b>Your entered conditions</b>:<br>${formattedConditions}`;
  console.log(conditions);

  // Send the user message with conditions
  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'user', text: userMessage, isHtml: true } // Added isHtml property to indicate HTML content
  ]);

  // Hide conditions input
  setShowConditionsInput(false);

  // Send a message indicating that data generation is in progress
  setStepMessages(prevMessages => [
    ...prevMessages,
    { sender: 'ai', text: 'Data is generating...'}
  ]);

  // Prepare the request body for the API call
  const requestBody = {
    user_id: 1, // Replace with the actual user ID
    model_path: modelPath, // Ensure modelPath is correctly set and available
    num_rows_wanted: numberOfRows, // Ensure numberOfRows is correctly set and available
    conditions: validConditions // Include the valid conditions
  };

  try {
    // Call the API to generate data
    const response = await axios.post('http://localhost:8003/api/syntheticdata/available/generate', requestBody, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob' // Ensure that the response type is blob
    });

    // Create a URL for the file blob and download it
    const fileURL = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileURL;
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '_'); 
    link.download = `${knowledgeWorkDetails.knowledge_work.name}_${timestamp}.csv`; // Specify the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Notify the user that the file has been downloaded
    setStepMessages(prevMessages => [
      ...prevMessages,
      { sender: 'ai', text: 'Generated file downloaded to your system!' },
      { sender: 'ai', text: "",step:20 }
      
    ]);
  } catch (error) {
    // Handle API call error
    // setStepMessages(prevMessages => [
    //   ...prevMessages,
    //   { sender: 'ai', text: 'Failed to generate data. Please try again.' }
    // ]);
  }
};

const handleSendEvaluate = ()=> {
  navigate(`/mo/summary/training/evaluate/${id}`, {
    state: { knowledgeWorkDetails, selectedItems, stepMessages,numericColumns },
  })
}



  useEffect(() => {
    setStepMessages(messages[stepIndex] || []);
  }, [stepIndex, messages]);

  return (
    <div className="d-flex position-relative" style={{ minHeight: '75vh' }}>
      {/* Chat Box */}
      <div className="flex-grow-1 d-flex flex-column">
        <div className="d-flex flex-column-reverse flex-grow-1 overflow-auto p-4">
          <div className="d-flex flex-column w-100">
            {stepMessages.map((msg, index) => (
              <div key={index} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                <div className={`p-2 rounded`} style={{ maxWidth: '60%', background: msg.sender === 'user' ? '#CBD4EE' : '#fff' }}>
                  <p className="m-0 text-dark" dangerouslySetInnerHTML={getMarkdownHTML(msg.text)}></p>
                  {msg.sender === 'ai' && msg.dropdown && (
            <div className="mt-3">
              <label htmlFor="domain-select" className="form-label">Select Domain:</label>
              <select 
                id="domain-select" 
                className="form-select" 
                onChange={(e) => handleDropdownChange(e.target.value)}
              >
                <option value="">Select...</option>
                {dropdownOptions.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          )}
                  {msg.sender === 'ai' && msg.subdomain && (
            <div className="mt-3">
              <label htmlFor="domain-select" className="form-label">Select Subdomain:</label>
              <select 
                id="domain-select" 
                className="form-select" 
                onChange={(e) => handleSubdomainChange(e.target.value)}
              >
                <option value="">Select...</option>
                {subDomainOptions.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          )}
          {msg.sender === 'ai' && msg.step === 3 && (
             <div className="mt-3">
             <input 
               type="text" 
               className="form-control" 
               value={attributes} 
               onChange={handleAttributesChange} 
               placeholder="Enter Attributes"
             />
             <button 
               className='btn btn-sm mt-2' 
               style={{ background: '#003BEB', color: '#fff' }} 
               onClick={handleSendAttributes}
             >
               Send
             </button>
           </div>
          )}
          {msg.sender === 'ai' && msg.step === 4 && (
             <div className="mt-3">
             <input 
               type="text" 
               className="form-control" 
               value={relationships} 
               onChange={handleRelationshipsChange} 
               placeholder="Enter Relationships"
             />
             <button 
               className='btn btn-sm mt-2' 
               style={{ background: '#003BEB', color: '#fff' }} 
               onClick={handleRelationships}
             >
               Send
             </button>
           </div>
          )}
          {msg.sender === 'ai' && msg.step === 5 && (
             <div className="mt-3">
             <input 
               type="text" 
               className="form-control" 
               value={constraints} 
               onChange={handleConstraintsChange} 
               placeholder="Enter Constraints"
             />
             <button 
               className='btn btn-sm mt-2' 
               style={{ background: '#003BEB', color: '#fff' }} 
               onClick={handleConstraints}
             >
               Send
             </button>
           </div>
          )}
          {msg.sender === 'ai' && msg.step === 6 && (
             <div className="mt-3">
             <input 
               type="text" 
               className="form-control" 
               value={numberOfDatapoints} 
               onChange={handleNumberOfDatapointsChange} 
               placeholder="Enter Number Of Datapoints"
             />
             <button 
               className='btn btn-sm mt-2' 
               style={{ background: '#003BEB', color: '#fff' }} 
               onClick={handleNumberOfDatapoints}
             >
               Send
             </button>
           </div>
          )}
          {msg.sender === 'ai' && msg.step === 7 && (
             <div className="mt-3">
             
             <button 
               className='btn btn-sm mt-2' 
               style={{ background: '#003BEB', color: '#fff' }} 
               onClick={handleGenerateData}
             >
               Generate
             </button>
           </div>
          )}
          {msg.sender === 'ai' && msg.step === 8 && (
            <div className="mt-3">
            <label htmlFor="domain-select" className="form-label">Select Download Format:</label>
            <select 
              id="domain-select" 
              className="form-select" 
              onChange={(e) => handleDataDownloadFormatChange(e.target.value)}
            >
              <option value="">Select...</option>
              {dataDownloadFormat.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          )}
                  {showUpload && msg.sender === 'ai' && msg.text === 'Please upload your seed data.' && (
                    <>
                      <input 
                        type="file" 
                        onChange={handleUpload} 
                        className="d-none" 
                        ref={fileInputRef}
                      />
                      <button 
                        className='btn btn-sm mt-2' 
                        style={{ background: '#003BEB', color: '#fff' }} 
                        onClick={handleUploadClick}
                      >
                        Upload
                      </button>
                    </>
                  )}
                  {msg.sender === 'ai' && msg.text === 'Here is the uploaded data:' && (
                    <div className="mt-3">
                      <div className="table-container">
                        <table className="custom-tableDark">
                          <thead>
                            <tr>
                              {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                  <td key={cellIndex}>{cell}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {msg.sender === 'ai' && msg.text === 'Select numeric columns and categorical columns from the table.' && (
                    <div className="mt-3">
                      <div className="row">
                        <div className="col">
                          <p className='text-dark fw-bold'>Numeric Columns</p>
                          <div>
                            {numericColumns.map((col, index) => (
                              <div key={index} className="form-check">
                                <input 
                                  type="checkbox" 
                                  className="form-check-input" 
                                  id={`numeric-${col.name}`} 
                                  checked={col.selected}
                                  onChange={(e) => handleColumnSelect('numeric', col.name, e.target.checked)}
                                />
                                <label className="form-check-label text-dark" htmlFor={`numeric-${col.name}`}>
                                  {col.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col">
                          <p className='text-dark fw-bold'>Categorical Columns</p>
                          <div>
                            {catColumns.map((col, index) => (
                              <div key={index} className="form-check">
                                <input 
                                  type="checkbox" 
                                  className="form-check-input" 
                                  id={`cat-${col.name}`} 
                                  checked={col.selected}
                                  onChange={(e) => handleColumnSelect('categorical', col.name, e.target.checked)}
                                />
                                <label className="form-check-label text-dark" htmlFor={`cat-${col.name}`}>
                                  {col.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <button 
                        className='btn btn-sm mt-3' 
                        style={{ background: '#003BEB', color: '#fff' }} 
                        onClick={handleSendSelection}
                      >
                        Send Selections
                      </button>
                    </div>
                  )}

{showRowInput && msg.sender === 'ai' && msg.text === 'Please enter how many rows you want to generate:' && (
                    <div className="mt-3">
                      <input 
                        type="number" 
                        className="form-control" 
                        value={numberOfRows} 
                        onChange={handleNumberOfRowsChange} 
                        placeholder="Enter number of rows"
                      />
                      <button 
                        className='btn btn-sm mt-2' 
                        style={{ background: '#003BEB', color: '#fff' }} 
                        onClick={handleSendNumberOfRows}
                      >
                        Send
                      </button>
                    </div>
                  )}

{showConditionsInput && msg.sender === 'ai' && msg.text === 'Enter conditions you want to give to the model while generating data:' && (
                    <div className="mt-3">
                      {conditions.map((condition, index) => (
                        <div key={index} className="mb-2">
                          <input 
                            type="text" 
                            className="form-control" 
                            value={condition}
                            onChange={(e) => handleConditionChange(index, e.target.value)}
                            placeholder={`Condition ${index + 1}`}
                          />
                        </div>
                      ))}
                      <button 
                        className='btn btn-sm mt-2' 
                        style={{ background: '#003BEB', color: '#fff' }} 
                        onClick={handleAddCondition}
                      >
                        + Add More
                      </button>
                      <button 
                        className='btn btn-sm mt-2 ms-2' 
                        style={{ background: '#003BEB', color: '#fff' }} 
                        onClick={handleSendConditions}
                      >
                        Send Conditions
                      </button>
                    </div>
                  )}
                  {msg.sender === 'ai' && msg.step === 20 && (
                     <button 
                     className='btn btn-sm mt-2 ms-2' 
                     style={{ background: '#003BEB', color: '#fff' }} 
                     onClick={handleSendEvaluate}
                   >
                     Evaluate
                   </button>
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Suggestions Container */}
      {stepMessages.length === 0 && !hasDataset && (
        <div className="position-absolute bottom-0 end-0 bg-light rounded shadow" style={{ width: '300px', maxHeight: '50vh', overflowY: 'auto', padding: '10px' }}>
          <p className="text-dark">"Don't have data? You can use a data synthesizer. Would you like to proceed?"</p>
          <button className="btn btn-sm mr-2" style={{ background: '#003BEB', color: '#fff' }} onClick={() => handleButtonClick("Yes")}>I've Dataset</button>
          <button className="btn btn-sm" style={{ background: '#003BEB', color: '#fff' }} onClick={() => handleButtonClick("No")}>I don't have Dataset</button>
        </div>
      )}
    </div>
  );
};

export default DataSynthesizerSeed;
