import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// import AlchemTable from "./AlchemTable";
import AlchemTable from "./AlchemTable";
import { AuthContext } from "../../Auth/AuthContext";
import plusDark from "../../../assets/img/plusIconDark.png"

const AlchemTableShow = () => {
  const { darkMode, accessToken } = useContext(AuthContext)
  const [TableData, setTableData] = useState([]);

  // const TableData = 
  // [
  //   {
  //     Input: { value: "None", type: "text" },

  //     "Model Name": {
  //       type: "label",

  //       value: "output_dir"
  //     },
  //     Help: {
  //       type: "label",
  //       value:
  //         "The directory where the model checkpoints and predictions will be saved."
  //     }
  //   },
  // ]


  const [baseModelMapping, setbaseModelMapping] = useState({
    1: "falcon",
    2: "bloom",
    3: "flan t5",
    4: "Llama 2",
    7: "GLM",
    8: "mistral-7b"
  });
  const [createdby, setcreatedby] = useState({
    1: "Aakash",
    2: "Leo",
    3: "Aravind",
    4: "Priya",
    5: "Naman"
  });

  const columns = ["", "  ", "Model Name", "Model Description", "Base Model", "Model Type", "Task", "Domain", "Status"];
  const columnValueMap = {
    "": "",
    "Model Name": "name",
    "Model Description": "description",
    "Base Model": 'task_id',
    "Model Type": "model_type",
    "Task": "taskKey",
    "Domain": "usecase",
    "Status": "status"
  };

  const subTableColumn = ["Purpose", "Status", "Updated By"]
  const subcolumnValueMap = {
    "Purpose": "purpose",
    "Status": "status",
    "Updated By": "updated_by"
  }
  const [data, setData] = useState([]);
  const [index, setindex] = useState(1);
  const [TableDataLocal, setTableDataLocal] = useState(TableData);
  const [TableColumnLocal, setTableColumnLocal] = useState(columns);
  const [updateDataJson, setupdateDataJson] = useState({});

  const [value, setValue] = useState("False");
  const [rowIndex, setRowIndex] = useState(0);

  const handleRowClick = (index) => {
    setRowIndex(index);
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    // Handle the change event here
    console.log("Selected Value:", selectedValue);
    // You can also access other data like row index if needed
    console.log("Row Index:", rowIndex+1);
  };
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowSelection = index => {
    if (selectedRow === index) {
      // setSelectedRow(null); // Unselect the row if it's already selected
    } else {
      setSelectedRow(index); // Select the clicked row
      localStorage.setItem("finebasemodelid", JSON.stringify(index + 1));
      console.log(index + 1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/finetuned_models`, {
        headers: {
          Authorization: accessToken
        }
      })
      .then(response => {
        const jsonData = response.data.data
        const mappedData = jsonData.map((item, i) => {
          const isSelected = selectedRow === i;
          const row = {};

          row["!@#$%"] = {
            type: "icon",
            value: <img src={plusDark} />,
            onChange: (e) => handleRowSelection(e.target.value),
          };

          row[""] = {
            type: "icon",
            value: <img src={plusDark} />,
            onChange: (e) => handleRowSelection(e.target.value),
          };
          row["  "] = {
            value:"False", type:"radio", options: [
              { value: "True" },
              
            ],
            checked: isSelected,
            onClick: () => handleRowSelection(i)
            
          };
          columns.slice(2).forEach((column) => {
            if (column === "Status") {
              const value = item[columnValueMap[column]] || "";
              row[column] = {
                type: "label",
                value: (
                  <div className="statusContainer">
                    <div
                      className="circle"
                      style={{
                        background:
                          value == "Just created"
                            ? "#1FEB00"
                            : value == "Finetuned"
                              ? "#1FEB00"
                              : "#3366FF"
                      }}
                    ></div>
                    {value}
                  </div>
                ),
              };
            } else if (column === "Task") {
              row[column] = { type: "label", value: "QA" };
            } else if (column === "Base Model") {
              const taskId = item[columnValueMap[column]];
              const baseModelName = baseModelMapping[taskId];
              row[column] = { type: "label", value: baseModelName };
            } else {
              row[column] = { type: "label", value: item[columnValueMap[column]] || "" };
            }
          });
          subTableColumn.forEach((subcolumn) => {

            if (subcolumn === "Updated By") {
             

              const taskId = item[subcolumnValueMap[subcolumn]];
              const baseModelName = createdby[taskId];
              row[subcolumn] = { type: "label", value: baseModelName };
            }
            else if (subcolumn === "Status") {
              const value = item[columnValueMap[subcolumn]] || "";
              row[subcolumn] = {
                type: "label",
                value: (
                  <div className="statusContainer">
                    <div
                      className="circle"
                      style={{
                        background:
                          value == "Just created"
                            ? "#1FEB00"
                            : value == "Finetuned"
                              ? "#1FEB00"
                              : "#3366FF"
                      }}
                    ></div>
                    {value}
                  </div>
                ),
              }
            }
            else if (subcolumn !== "Status") {
              row[subcolumn] = { type: "label", value: item[subcolumnValueMap[subcolumn]] || "" };
            }

          })
          return row;
        });
        setTableData(mappedData);
      })
      .catch(error => {
        console.error(error);
      });

  };

  console.log(TableData);


  const updateData = (data_index, column, type, value, key) => {
    if (
      type === "text" ||
      type === "number" ||
      type === "select" ||
      type === "radio"
    ) {
      let data = TableDataLocal;
      let tempupdateDate = updateDataJson;
      tempupdateDate[key] = value;
      setupdateDataJson(tempupdateDate);
      data[index - 1][data_index][column]["value"] = value;
      setTableDataLocal([...data]);
    }
  };
  return (
    <div>
      <h1>My Component</h1>
      {/* <AlchemTable
        columns={columns}
       data={TableData}
      /> */}
      <AlchemTable
        updateData={updateData}
        TableData={TableData}
        TableColumn={columns}
        darkMode={darkMode}
        handleRowSelection={handleRowSelection}
        subTableColumn={subTableColumn}
      ></AlchemTable>
    </div>
  );
};

export default AlchemTableShow;