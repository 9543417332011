import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlchemLogo from "../../../assets/img/AlchemLogo.png";
import LogoutLogo from "../../../assets/img/onBtn.svg";
import LogoWhite from "../../../assets/img/logoWhite.svg";

import UserThumbnail from "../../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../../assets/img/dropdown-arrow.svg";
import "./competencyDetailsNav.css";

function CompetencyDetailsNav({ module }) {
  const navigate = useNavigate();
  const handleHome = () => {
    return navigate("/");
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="navWithBg competencydetails p-0"
    >
      <Container fluid className="px-4">
        <Navbar.Brand href="" onClick={handleHome}>
          <img
            className="img-fluid logoSize"
            loading="lazy"
            src={LogoWhite}
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto"> */}
          {/* <Nav.Link href="#features">Agents</Nav.Link>
            <Nav.Link href="#pricing">Agent Chains</Nav.Link>
            <Nav.Link href="#pricing">Playground</Nav.Link> */}
          {/* </Nav> */}
          <Nav
            className="me-auto alchemNavLinks"
            defaultActiveKey={"competencies"}
          >
            <Nav.Link
              eventKey={"dashboard"}
              to="#"
              className="justify-content-center align-items-center d-flex"
              activeClassName="active"
            >
              Dashboard
            </Nav.Link>
            <Nav.Link
              eventKey={"competencies"}
              className="justify-content-center align-items-center d-flex"
              to="/competency"
              activeClassName=""
            >
              Knowledge Work
            </Nav.Link>
            <Nav.Link
              eventKey={"playground"}
              to="#"
              className="justify-content-center align-items-center d-flex"
              activeClassName="active "
            >
              Playground
            </Nav.Link>
          </Nav>
          <Nav>
            {/* <Nav.Link href="#deets" className="navWhite">
              Mayur Jadhav
            </Nav.Link> */}
            <NavDropdown
              title={
                <>
                  <img src={UserThumbnail} />
                  <span className="user-name">AI.Cloud</span>
                  <img src={DropDownArrow} />
                </>
              }
              id="navbarScrollingDropdown"
              className="btn user-profile m-0"
            >
              <NavDropdown.Item href="#action3">User Profile</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
              <NavDropdown.Item href="#action5">Logout</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link eventKey={2} >
              <img
                className="img-fluid"
                loading="lazy"
                src={LogoutLogo}
                alt=""
              />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CompetencyDetailsNav;
