import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";
import avtar2 from "../../../assets/img/agentAvatar.svg";
import { AuthContext } from "../../Auth/AuthContext";
import AlchemPagination from "../../SharedComponents/AlchemPagination/AlchemPagination";

const AgentCard = () => {
  const [agents, setAgents] = useState([]);
  const { darkMode, accessToken } = useContext(AuthContext);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AGENT_SERVER}/v1/agent/`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(response => {
        setAgents(response.data);
        console.log("Fetch agents success : ");
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  //pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const totalPages = Math.ceil(agents.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const paginatedAgnents = agents.slice(startIndex, endIndex);
  return (

    <div className="container-fluid px-0" style={{}}>
      <div className="row mx-0">
        {paginatedAgnents.toReversed().map(variant => (
          <div key = {variant.agent.id} className="col-md-3 mb-4">
            <Card
              className={
                darkMode
                  ? "mb-2 agentCardBodyTopPadDark agentCardDark "
                  : "mb-2 whiteBg agentCard"
              }
            >
              <Card.Header
                className={
                  darkMode
                    ? "agentDate agentCardHeadDark"
                    : "agentDate agentCardHead"
                }
              >
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <img
                      className="img-fluid avtarImg"
                      loading="lazy"
                      src={avtar2}
                      alt=""
                    />{" "}
                  </div>
                  <div className="col-8 mt-2">
                    <p className="fw-medium text-white">{variant.agent.agent_name}</p>
                    <p
                      className="mb-0"
                      style={{ color: darkMode ? "#ADC2FF" : "" }}
                    >
                      Your Friendly Assistant
                    </p>
                  </div>
                  <div className="col-1 px-0">
                    <HiDotsVertical
                      style={{
                        color: darkMode ? "white" : "inherit",
                        fontSize: "16px"
                      }}
                    />
                  </div>
                </div>
              </Card.Header>
              <Link
                to={"./createAgent/testAgent/" + variant.agent.id}
                className=""
              >
                <Card.Body className="">
                  <Card.Title
                    className="competencyCardTitle mb-0"
                    // style={{ paddingTop: "2rem" }}
                    style={{
                      color: darkMode ? "#ADC2FF" : "",
                      marginLeft: "24px"
                    }}
                  >
                    Created By
                  </Card.Title>
                  <Card.Title
                    className="cardName"
                    style={{
                      color: darkMode ? "#fff" : "",
                      marginLeft: "24px",
                      marginBottom: "16px"
                    }}
                  >
                    {variant.owner.owner_name}
                  </Card.Title>
                  <Card.Title
                    className="competencyCardTitle mb-0"
                    style={{
                      color: darkMode ? "#ADC2FF" : "",
                      marginLeft: "24px"
                    }}
                  >
                    Purpose
                  </Card.Title>
                  <Card.Text
                    style={{
                      color: darkMode ? "#fff" : "",
                      marginLeft: "24px"
                    }}
                  >
                    {variant.agent.agent_description}
                  </Card.Text>

                  <Row>
                    <Col xs={6}>
                      <Card.Title
                        className="competencyCardTitle"
                        style={{
                          color: darkMode ? "#ADC2FF" : "",
                          marginLeft: "24px",
                          marginBottom: "8px"
                        }}
                      >
                        Created On
                      </Card.Title>
                      <Card.Text
                        style={{
                          color: darkMode ? "#fff" : "",
                          marginLeft: "24px"
                        }}
                      >
                        {new Date(
                          variant.agent.timestamp_created
                        ).toLocaleDateString("en-GB")}
                      </Card.Text>
                      <Card.Title
                        className="competencyCardTitle"
                        style={{
                          color: darkMode ? "#ADC2FF" : "",
                          marginLeft: "24px"
                        }}
                      >
                        Category
                      </Card.Title>
                      <Card.Text
                        style={{
                          color: darkMode ? "#fff" : "",
                          marginLeft: "24px"
                        }}
                      >
                        {variant.agent.policy}
                      </Card.Text>
                    </Col>
                    <Col xs={6}>
                      <Card.Title
                        className="competencyCardTitle"
                        style={{ color: darkMode ? "#ADC2FF" : "" }}
                      >
                        Updated On
                      </Card.Title>
                      <Card.Text style={{ color: darkMode ? "#fff" : "" }}>
                        {new Date(
                          variant.agent.timestamp_created
                        ).toLocaleDateString("en-GB")}
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Link>
            </Card>
          </div>
        ))}
      </div>
      <AlchemPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </div>

  );
};

export default AgentCard;
