import Card from "react-bootstrap/Card";
import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import plusicon from "../../../assets/img/plusIcon.svg";
import plusIconDark from "../../../assets/img/plusIconDark.png";
import ActivityModal from "./ActivityModal";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import PropTypes from "prop-types";
import verticalThreeDots from "../../../assets/img/vertical_three_dots_dark.svg";
import knowledgeWorkBot from '../../../assets/img/knowledge_work_bot.svg'
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import AlchemTableNav from "../../SharedComponents/AlchemTableNav/AlchemTableNav";
import { AuthContext } from "../../Auth/AuthContext";
import AlchemPagination from "../../SharedComponents/AlchemPagination/AlchemPagination";
import { Button, Form, InputGroup } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import { DockContext } from "../../DockWrapper/DockContext";
import SMEFirstTime from "./SMEFirstTime";
import { sassTrue } from "sass";
import Styles from './ActivityList.module.css'
import moment from "moment/moment";
const ActivityList = ({ darkMode, handleToggle, firstTimeUser }) => {

  const links = [
    { name: "Knowledge Work", route: "/activityList", isActive: true },
    { name: "Model Orchestrator", route: "/mo/knowledge-work", isActive: false },
  ];
  const TextToggle = ({ text = "", }) => {
    const [isExpand, setIsExpand] = useState(false);
    const toggleText = () => {
      if (!isExpand) {
        setIsPause(true)
      } else {
        setIsPause(false)
      }
      setIsExpand(prevState => !prevState);
    };

    const inlineStyles = {
      cursor: "pointer",
      overflowY: isExpand ? "scroll" : "hidden",
      width: "100%",
      height: "100%"
    }

    return (
      <div
        onClick={toggleText}
        className={Styles["cell-div"]}
        style={inlineStyles}>

        {isExpand || text.length <= 70
          ? text.length == 0 ?
            "NOT SPECIFIED"
            :
            text
          : text.slice(0, 70)}

        {text.length > 70 && !isExpand && '.....'}

      </div>
    );
  };



  const [knowledgeWorkLinks, setKnowledgeWorkLinks] = useState([
    { name: "Deployed", route: "", isActive: false },
    { name: "Draft", route: "", isActive: true },
  ])

  const { accessToken, setAccessToken, setRefreshToken } = useContext(AuthContext);

  const {
    setisFinish,
    setmind_map_history,
    setactivityReactFlowHis,
    setActivity_master_json,
    selectedKnowledgeWork, setSelectedKnowledgeWork,

  } = useContext(DockContext);

  const clearState = () => {
    setActivity_master_json({})
    setSelectedKnowledgeWork()
    setisFinish(false)
    setmind_map_history({ nodes: [], edges: [] })
    setactivityReactFlowHis({ nodes: [], edges: [] })
  }

  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [draftData, setDraftData] = useState([])
  const [deployedData, setDeployedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6;
  const startIndex = useRef((currentPage - 1) * recordsPerPage)
  const endIndex = useRef(startIndex.current + recordsPerPage)
  const [displayedData, setDisplayedData] = useState([]);
  const [totalPages, setTotalPages] = useState(1)
  const [searchKey, setSearchKey] = useState("")
  const [firstTime, setFirstTime] = useState(firstTimeUser)
  let navigate = useNavigate();
  let page = 1;
  let end_page = 1000;

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  const knowledgeworkSegregation = (data) => {
    let deploy = []
    let draft = []
    data.map((item) => {
      if (item.status.toLowerCase() === "done".toLowerCase()) {
        deploy.push(item)
      } else {
        draft.push(item)
      }
    })
    setDeployedData(deploy)
    setDraftData(draft)
    return true
  }

  const knowledgeworkSegregationMemo = useMemo(() => knowledgeworkSegregation(activityData), [activityData])

  const [fetchedDataPrevResult, setFetchedDataPrevResult] = useState(true)

  const fetchData = () => {
    if (window.location.pathname == "/activityList" && fetchedDataPrevResult) {
      setFetchedDataPrevResult(false)
      axios
        .get(
          `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/?skip=0&limit=${end_page}`,
          { headers }
        )
        .then((response) => {
          setActivityData(response.data);
          setLoading(false);
          knowledgeworkSegregation(response.data)
          setFetchedDataPrevResult(true)
        })
        .catch((error) => {
          setFetchedDataPrevResult(true)

          console.error("Error Fetching Data", error);
          if (error.response && error.response.status === 401)
            axios
              .post(
                `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
                { headers }
              )
              .then(refreshResponse => {
                // Check if the refresh request was successful
                if (
                  refreshResponse.status === 200 &&
                  refreshResponse.data.access_token &&
                  refreshResponse.data.refresh_token
                ) {
                  setAccessToken(refreshResponse.data.access_token);
                  setRefreshToken(refreshResponse.data.refresh_token);
                  document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                  document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                  console.log("Token refreshed");
                  return true; // User is authenticated after successful token refresh
                } else {
                  console.log("Error refreshing token:", refreshResponse);
                  return false; // Authentication failed if token refresh fails
                }
              })
              .catch(error => {
                if (error.response && error.response.status === 401)
                  window.location.reload()
                if (
                  error.response &&
                  error.response.status === 400 &&
                  error.response.data.error === "invalid_grant"
                ) {
                  console.error("Refresh token expired or invalid");
                }

                return false; // Authentication failed if token refresh fails
              });
          else {
            setError(true);
          }
          setLoading(false);
        });

    }


  };




  const retry = () => {
    setLoading(true);
    setError(false);
    fetchData();
    console.log(activityData);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    fetchData()
  };



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };


  const handleKnowledgeWorkClick = (index) => {
    setKnowledgeWorkLinks(prevLinks =>
      prevLinks.map((link, i) =>
        ({ ...link, isActive: i === index })
      )
    )
    setSearchKey("")


  }

  const isDeployed = () => {

    let link = knowledgeWorkLinks.find(link => link.isActive && link.name === 'Deployed')

    return link ? true : false

  }


  const filterData = () => {


    if (isDeployed()) {

      let data = deployedData.slice(startIndex.current, endIndex.current)
      let customPageLength = Math.ceil(deployedData.length / recordsPerPage)
      setTotalPages(customPageLength > 0 ? customPageLength : 1)
      setDisplayedData(data)

    } else {

      let data = draftData.slice(startIndex.current, endIndex.current)
      let customPageLength = Math.ceil(draftData.length / recordsPerPage)
      setTotalPages(customPageLength > 0 ? customPageLength : 1)
      setDisplayedData(data)
    }

  }





  useEffect(() => {
    filterData()
  }, [deployedData, draftData])

  useEffect(() => {
    startIndex.current = (0) * recordsPerPage
    endIndex.current = startIndex.current + recordsPerPage

    filterData()
  }, [knowledgeWorkLinks]);

  useEffect(() => {
    startIndex.current = (currentPage - 1) * recordsPerPage
    endIndex.current = startIndex.current + recordsPerPage
    filterData()
  }, [currentPage])

  useEffect(() => {
    fetchData();
    clearState()
  }, []);

  const [isPause, setIsPause] = useState(false)
  const pollingFetch = () => {
    console.log("polling condition", isPause, firstTime, !isPause && firstTime !== "first-time")
    if (!isPause && firstTime !== "first-time") {
      console.log("polling", isPause)
      fetchData()
    }
  }

  // const [pollingIntervalFun, setPollingIntervalFun] = useState()
  const pollingIntervalFun = useRef(null)

  useEffect(() => {
    if (pollingIntervalFun?.current) {
      clearInterval(pollingIntervalFun.current)
    }

    pollingIntervalFun.current = setInterval(pollingFetch, 5500);
    return () => {
      if (pollingIntervalFun?.current) {
        clearInterval(pollingIntervalFun.current)
      }
    }
  }, [isPause, firstTime])



  useEffect(() => {
    if (!selectedKnowledgeWork) {
      navigate("/activityList")
    }

  }, [selectedKnowledgeWork])


  const isProcessingEntity = (item) => {
    return item.status.toLowerCase() === "processing".toLowerCase();
  }


  const handleActivityNavigate = (item) => {

    if (isProcessingEntity(item)) {
      return ""
    }

    setSelectedKnowledgeWork(item)

    if (item.status.toLowerCase() === "done".toLowerCase()) {
      return ""
    }

    if (item.status.toLowerCase() === "validate".toLowerCase()) {
      setActivity_master_json({})
      navigate("/summary")
      return ""
    }

    navigate("/Achain")

  }

  useEffect(() => {
    if (searchKey == "") {
      console.log("filter resetted ")
      fetchData()
    }
  }, [searchKey])


  const SearchForKey = () => {
    setIsPause(true)
    if (searchKey == "") {
      setIsPause(false)
      fetchData()
      return ""
    }

    if (isDeployed()) {

      const updatedData = deployedData.filter((item) => {
        if (item.name?.toLowerCase().includes(searchKey.toLowerCase()) || item.description?.toLowerCase().includes(searchKey.toLowerCase()) || item.domain?.toLowerCase().includes(searchKey.toLowerCase()) || item.sub_domain?.toLowerCase().includes(searchKey.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
      console.log(updatedData)
      setDeployedData(updatedData)
    } else {

      const updatedData = draftData.filter((item) => {
        if (item.name?.toLowerCase().includes(searchKey.toLowerCase()) || item.description?.toLowerCase().includes(searchKey.toLowerCase()) || item.domain?.toLowerCase().includes(searchKey.toLowerCase()) || item.sub_domain?.toLowerCase().includes(searchKey.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
      console.log(updatedData)
      setDraftData(updatedData)
    }

  }

  const handleKnowledgeWorkSoftDelete = (knowledgeWork) => {

    setDisplayedData(displayedData.filter((item) => {

      return item.id !== knowledgeWork.id
    }))

    axios
      .delete(`${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/${knowledgeWork.id}`)
      .then((res) => {
        console.log(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const UTCToLocalTimeConverstion = (utcTime) => {

    const currentDate = new Date(utcTime).toLocaleString()
    const gmtDateTime = moment.utc(currentDate).local().format('DD-MMM-YYYY hh:mm A');

    return (<span key={utcTime} className="text-center align-self-center p-2">
      {gmtDateTime}
    </span>)

  }


  return (
    firstTime === 'first-time' ? <><ActivityModal
      showModal={showModal}
      closeModal={closeModal}
      darkMode={darkMode}
      handleToggle={handleToggle}
      setFirstTime={setFirstTime}
    /><SMEFirstTime setFirstTime={setFirstTime} openModal={openModal} /></> :
      <div className={`activityList`}>
        <ActivityModal
          showModal={showModal}
          closeModal={closeModal}
          darkMode={darkMode}
          handleToggle={handleToggle}
        />
        <CustomNavbar links={links} profile={true} handleToggle={handleToggle} />
        <div style={{ padding: "40px" }} className="pt-4">
          <nav className="navbar navbar-expand-lg pb-3 pt-0">
            <div className="container-fluid px-0 ">
              <span
                className={
                  darkMode
                    ? "text-white page-title-dark"
                    : "text-dark page-title-dark"
                }
              >
                Knowledge Work ({activityData.length})
              </span>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav ms-auto px-0">
                  <InputGroup
                    className={`mr-2 ${darkMode ? "ms-auto searchWidth alchemSearchGroupDark" : "ms-auto searchWidth alchemSearchGroup"}`}
                  >
                    <input
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      className="form-control border-0"
                      value={searchKey}
                      onKeyDown={(input) => {
                        input.key == "Enter" && SearchForKey()
                      }}
                      onChange={(event) => { setIsPause(true); setSearchKey(event.target.value); }}
                    />
                    <Button
                      variant="outline-secondary"
                      id="searchButton"
                      className="searchGroupBtn"
                      onClick={SearchForKey}
                    >
                      <CiSearch style={{ fontSize: "18px", color: "#E5EAF1" }} />

                    </Button>
                  </InputGroup>
                  <button
                    className={
                      darkMode
                        ? "btn btn-primary submitBtnDark button-text-dark"
                        : "btn btn-primary submitBtnDark"
                    }
                    style={{
                      height: "33px",
                      width: "198px",
                      marginRight: "0px",
                    }}
                    onClick={() => openModal()}
                  >
                    <img src={knowledgeWorkBot} alt={''} className="mr-2"></img>Create Knowledge Work
                  </button>
                </div>
              </div>
            </div>
          </nav>

          <div className="d-flex justify-content-between mb-2">
            <Nav
              variant="tabs"
              className="me-auto mt-0"
            >
              {knowledgeWorkLinks.map((link, index) => (
                <Nav.Item key={`${index}_${link}`}>
                  <Nav.Link
                    onClick={() => handleKnowledgeWorkClick(index)}
                    className={
                      `justify-content-center align-items-center d-flex mt-0`
                    }
                    style={{
                      display: "inline-block",
                      marginBottom: "-5px",
                      marginRight: '6px',
                      fontSize: "16ppx",
                      color: darkMode ? "#fff" : "#000",
                      backgroundColor: link.isActive ? '#454d7b' : 'transparent'

                    }}
                    as={Link}
                  >
                    {link.name === 'Deployed' ? link.name + ` (${deployedData.length})` : link.name + ` (${draftData.length})`}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <Card className={darkMode ? "tableCardDark" : "tableCard"}>
            {!loading && <div className="pl-4 pt-4">


            </div>}
            <Card.Body className="p-4 pt-0">
              {loading && (
                <>
                  <div className="dataLoader"></div>
                  <p className="text-center mt-2 text-white"> Loading...</p>
                </>
              )
              }

              {error && !loading ? (
                <div className="retry-container">
                  <p className="text-white">
                    An error occurred while fetching the data. Please try again.
                  </p>
                  <button onClick={retry} className="submitBtnDark">
                    Retry
                  </button>
                </div>
              ) : (
                <table
                  className={
                    darkMode
                      ? "table alchemTableDark allModelTable mb-0 "
                      : "table alchemTable allModelTable mb-0"
                  }
                >
                  <thead
                    className={
                      "custom-table-head-dark " + darkMode ? "dark-text" : ""
                    }
                    style={{ top: '0', position: 'sticky' }}
                  >
                    <tr>
                      <th></th>
                      <th></th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Date Created
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Domain
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Sub Domain
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Uploaded Document
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Activities
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Status
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className={darkMode ? "dark-text" : ""}>
                    {
                      displayedData.length > 0 ?
                        displayedData?.map((item, index) => {
                          return (
                            <>
                              <tr key={index} className={`non-hidden ${isProcessingEntity(item) ? "bg-secoundary" : ""}`}>
                                <td style={{ borderBottom: "none" }}></td>
                                {item.status.toLowerCase() === "done".toLowerCase() ?
                                  <td
                                    data-toggle="collapse"
                                    data-target={"#rowId" + index}
                                    className="accordion-toggle p-3 pt-4"
                                    aria-expanded="false"
                                    aria-controls={"#rowId" + index}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.status.toLowerCase() === "done".toLowerCase() ?

                                      darkMode ? (
                                        <img src={plusIconDark} alt="plus" />
                                      ) : (
                                        <img src={plusicon} alt="plus" />
                                      )

                                      :

                                      ""}
                                  </td>
                                  :
                                  <td></td>}

                                <td
                                  onClick={() => { handleActivityNavigate(item); }}
                                  style={{ borderRight: "#2F499E22 solid 1px" }}
                                >
                                  <div style={{ minWidth: "120px" }}>{item.name ? item.name : item?.master_json?.knowledge_work.name}</div>
                                </td>
                                <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                  <div className={Styles["cell-div"]} >
                                    <TextToggle text={item.description || item?.master_json?.knowledge_work?.description} />
                                  </div>

                                </td>
                                <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                  <div className="m-0 d-flex flex-column justify-content-center align-items-center" style={{ maxHeight: "100px" }}>{
                                    UTCToLocalTimeConverstion(item.created_at)
                                  }</div>
                                </td>
                                <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                  <div className={`${Styles["cell-div"]} d-flex justify-content-center align-items-center`}>
                                    <TextToggle text={item?.domain || item?.master_json?.knowledge_work?.sub_domain} />
                                  </div>
                                </td>
                                <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                  <div className={`${Styles["cell-div"]} d-flex justify-content-center align-items-center`}>
                                    <TextToggle text={item?.sub_domain || item?.master_json?.knowledge_work?.sub_domain} />
                                  </div>
                                </td>
                                <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                  <div className={`${Styles["cell-div"]} text-center d-flex align-items-center justify-content-center`}>
                                    {item?.path === 'nodoc' ? <>No Document Available</> : <a href={item.path} target="_blank" style={{ textDecoration: "underline" }} >View</a>}
                                  </div>
                                </td>
                                <td
                                  style={{ borderRight: "#2F499E22 solid 1px" }}
                                  className="text-center"
                                >
                                  <div>{item.activities.length}</div>
                                </td>
                                <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                  <div className="statusContainer text-capitalize mt-2">
                                    <div
                                      className="circle"
                                      style={{
                                        background: "Just created" == "Just created"
                                          ? "#1FEB00"
                                          : "Finetuned" == "Finetuned"
                                            ? "#1FEB00"
                                            : "#3366FF",
                                      }}
                                    ></div>
                                    {item.status}
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="d-inline-flex gap-3">

                                    {item.status.toLowerCase() !== "done".toLowerCase() &&
                                      <button className={`${darkMode
                                        ? "btn btn-primary submitBtnDark button-text-dark"
                                        : "btn btn-primary submitBtnDark"} text-captalized text-wrap fw-light`}
                                        style={{ minWidth: "100px" }} disabled={item.status.toLowerCase() === "processing".toLowerCase()} onClick={() => { handleActivityNavigate(item); }}>
                                        {item.status.toLowerCase() === "validate".toLowerCase() ?
                                          "Validate"
                                          :
                                          "Start"}
                                      </button>}

                                    {item.status.toLowerCase() === "done".toLowerCase() ?
                                      ""
                                      :

                                      item.status.toLowerCase() === "processing".toLowerCase() ?

                                        <i className="fa-regular fa-trash-can m-auto text-secondary" ></i>
                                        :
                                        <i className="fa-regular fa-trash-can  m-auto" style={{ color: '#9CB1E8' }} onClick={() => { handleKnowledgeWorkSoftDelete(item); }}></i>}

                                  </div>
                                </td>
                                <td>
                                </td>
                                <td style={{ borderBottom: "none" }}></td>
                              </tr >
                              <tr>
                                <td colspan="12" className="hiddenRow border-bottom">
                                  <div
                                    className="collapse subTable_outer multi-collapse"
                                    id={"rowId" + index}
                                    style={{ background: darkMode ? "#212542" : "" }}
                                  >
                                    <table
                                      className={`table ${darkMode ? "subTableDark" : "subTable"} mb-0`}
                                    >
                                      <tbody>
                                        <tr style={{ backgroundColor: "#212542" }}>
                                          <td
                                            colSpan="1"
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          >
                                            <button className="btn btn-primary submitBtnDark">
                                              API
                                            </button>
                                          </td>
                                          <td
                                            colSpan="1"
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          >
                                            <button
                                              onClick={() => navigate(
                                                `/testagent/${item.id}/0`
                                              )}
                                              className="btn btn-primary submitBtnDark"
                                            >
                                              TEST
                                            </button>
                                          </td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                          <td
                                            style={{
                                              borderBottom: darkMode ? "none" : "",
                                            }}
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })


                        :

                        <tr >
                          <td colSpan={11}>
                            <div className="d-flex w-100 text-center d-flex align-items-center justify-content-center text-white p-4">
                              There are no Knowledge work deployed to show !!
                            </div>
                          </td>
                        </tr>
                    }
                  </tbody>
                </table>
              )}
            </Card.Body>
          </Card>

          <AlchemPagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
        <footer
          className="copyright copyright-home-screen"
          style={{ height: "10%" }}
        >
          Copyright © TCS 2024. All Rights Reserved.
        </footer>
      </div >

  );
};
ActivityList.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default ActivityList;
