import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import { Link, Outlet } from "react-router-dom";

export default function Competancy() {
  return (
    <>
      {/* <Agents /> */}
      {/* <AlchemNav /> */}
      {/* <CreateAgentNav /> */}
      <Outlet />
    </>
  );
}
