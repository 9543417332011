import { useState, useEffect, createContext, useContext } from "react";
import { AuthContext } from "../Auth/AuthContext";
import { MarkerType } from "reactflow";
export const DockContext = createContext()

const initialEdges = [
  { id: '12', source: '1', target: '2', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
  { id: '23', source: '2', target: '3', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
  { id: '34', source: '3', target: '4', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
  { id: '45', source: '4', target: '5', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
  { id: '56', source: '5', target: '6', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
  { id: '67', source: '6', target: '7', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
  { id: '78', source: '7', target: '8', 'type': 'smoothstep', 'markerEnd': { type: MarkerType.ArrowClosed, } },
];

const initialChat_history = {
  stages: 4,
  currentStage: 2,
  stageConfig: {
    1: "ContextGathering",
    2: "ContextFormalizingIntoActivities",
    3: "ActivityValidation",
    4: "AgentChainMapping",
    5: "AgentChainTest"
  },
  ContextGathering: {
    currentStep: 2,
    steps: {
      1: {
        botResponseType: "input",
        userResponseType: "input",
        history: [],
        isCompleted: true
      }
    },

    isCompleted: true
  },
  ContextFormalizingIntoActivities: {
    currentStep: 1,
    steps: {
      1: {
        botResponseType: "input",
        userResponseType: "input",
        history: [],
        isCompleted: false
      }
    },

    isCompleted: false
  },
  ActivityValidation: {
    isCompleted: false
  },
  AgentChainMapping: {
    isCompleted: false
  },
  AgentChainTest: {
    isCompleted: false
  }
}

const sample_activity_master_json = {
  "name": "final_test_4",
  "description": null,
  "path": "{'fid': '1,2d39fc9724', 'file_url': 'http://alchemist.eastus.cloudapp.azure.com:5100/1,2d39fc9724'}",
  "user_id": "55c989e1-78f5-4e41-be3d-891e5a86d718",
  "domain": null,
  "sub_domain": null,
  "business_user": null,
  "activity_chain_payload": null,
  "mindmap_chain_payload": null,
  "chathistory_chain_payload": null,
  "master_json": {
    "knowledge_work": {
      "name": "Optimizing the allocation of space within a store",
      "description": "The solution aims to maximize sales per square foot and enhance the customer experience by optimizing the allocation of space within the store.",
      "domain": "Retail",
      "sub_domain": "",
      "business_user": "Supplier Management, Macro Space Decision, and Store Operations",
      "activities": [
        {
          "name": "Building and Deploying Applications",
          "description": "This activity involves the creation and deployment of applications with an emphasis on speed and efficiency.",
          "role": "Supplier Management",
          "goal": "To enable faster and more efficient application development and deployment while maintaining control and security.",
          "tasks": [
            {
              "description": "Develop an application following the requirements and guidelines.",
              "expected_output": "A fully functional application ready for deployment."
            }
          ],
          "human_input_required": true,
          "status": true,
          "finetuned_model_type": "generative",
          "kpi": "Application Development Speed - The time taken from the start of development to the deployment of the application; Application Efficiency - The performance of the application in terms of speed, error rates, and resource usage.",
          "generative_model": {
            "is_finetuned_model": false,
            "is_aligned": false,
            "base_model": "Qwen/Qwen2-72B-Instruct",
            "finetuned_model": {
              "sft": {
                "name": "SFT Model",
                "path": "path/to/sft_model"
              },
              "ppo": {
                "rm": {
                  "name": "RM Model",
                  "path": "path/to/rm_model"
                },
                "ppo": {
                  "name": "PPO Model",
                  "path": "path/to/ppo_model"
                }
              },
              "dpo": {
                "name": "DPO Model",
                "path": "path/to/dpo_model"
              }
            },
            "hyper_parameters": {
              "Quantisation": {
                "load_in_4bit": true,
                "bnb_4bit_use_double_quant": true,
                "bnb_4bit_quant_type": "nf4",
                "bnb_4bit_compute_dtype": "compute_dtype"
              },
              "Peft-Lora Config": {
                "r": 64,
                "lora_alpha": 16,
                "lora_dropout": 0.05,
                "target_modules": [
                  "q_proj",
                  "k_proj",
                  "v_proj",
                  "o_proj",
                  "up_proj",
                  "gate_proj",
                  "down_proj"
                ]
              },
              "Training Arguments": {
                "max_steps": 100,
                "per_device_train_batch_size": 2,
                "gradient_accumulation_steps": 4,
                "gradient_checkpointing": true,
                "bf16": true,
                "tf32": true,
                "learning_rate": 0.00002,
                "lr_scheduler_type": "cosine",
                "warmup_ratio": 0.1,
                "num_train_epochs": 3,
                "logging_steps": 50,
                "output_dir": "temp",
                "save_steps": 500
              },
              "SFT - Trainer": {
                "model": "model",
                "tokenizer": "tokenizer",
                "peft_config": "peft_config",
                "train_dataset": "dataset[train]",
                "args": "training_args",
                "dataset_text_field": "text"
              }
            }
          }
        },
        {
          "name": "Maintaining Control and Security",
          "description": "This activity involves ensuring that the deployed applications are secure and under control.",
          "role": "Store Operations",
          "goal": "To maintain control over the applications and ensure their security.",
          "tasks": [
            {
              "description": "Regularly monitor the applications for any security threats or breaches.",
              "expected_output": "A secure and controlled application environment."
            }
          ],
          "human_input_required": true,
          "status": true,
          "finetuned_model_type": "predictive",
          "kpi": "Security Breach Incidents - The number of security breaches that occur; Control Measures Effectiveness - The effectiveness of the control measures in maintaining the security and control of the applications.",
          "predictive_model": {
            "base_model": "tiiuae/falcon-7b",
            "hyper_parameters": {},
            "name": "",
            "model_weight": ""
          }
        },
        {
          "name": "Optimizing Space Allocation",
          "description": "This activity involves the strategic allocation of space within the store to maximize sales per square foot.",
          "role": "Macro Space Decision",
          "goal": "To optimize the allocation of space within the store to maximize sales per square foot.",
          "tasks": [
            {
              "description": "Analyze sales data and customer behavior to determine the optimal allocation of space.",
              "expected_output": "A strategic plan for space allocation within the store."
            }
          ],
          "human_input_required": true,
          "status": true,
          "finetuned_model_type": "predictive",
          "kpi": "Sales per Square Foot - The total sales divided by the retail square footage; Customer Experience Enhancement - The improvement in customer experience as a result of the space allocation.",
          "predictive_model": {
            "base_model": "Deci/DeciLM-7B-instruct",
            "hyper_parameters": {},
            "name": "",
            "model_weight": ""
          }
        },
        {
          "name": "Macro Space Decision",
          "description": "This activity involves making strategic decisions about the allocation of space within the store to maximize sales and enhance the customer experience.",
          "role": "Macro Space Decision",
          "goal": "To make strategic decisions about space allocation to maximize sales and enhance customer experience.",
          "tasks": [
            {
              "description": "Evaluate different space allocation strategies and their impact on sales and customer experience.",
              "expected_output": "A set of strategic recommendations for space allocation."
            }
          ],
          "human_input_required": true,
          "status": true,
          "finetuned_model_type": "predictive",
          "kpi": "Sales Increase - The percentage increase in sales due to space allocation changes; Customer Satisfaction - The improvement in customer satisfaction scores.",
          "predictive_model": {
            "base_model": "mistralai/Mistral-7B-Instruct-v0.2",
            "hyper_parameters": {},
            "name": "",
            "model_weight": ""
          }
        }
      ]
    }
  },
  "is_master_json": true,
  "created_at": "2024-08-06T11:58:58.606296",
  "updated_at": null,
  "id": 1,
  "activities": [
    {
      "name": "Building and Deploying Applications",
      "description": "test description",
      "role": "test",
      "goal": "string",
      "human_input_required": true,
      "status": true,
      "finetuned_model_type": "string",
      "predictive_model_id": 0,
      "generative_model_id": 0,
      "kpi": "string",
      "id": 0,
      "tasks": [
        {
          "description": "this is a test task description which was modified",
          "expected_output": "this is a test expected output",
          "id": 1
        }
      ]
    }
  ],
  "status": "ready"
}

const master_json_spoof = {
  "knowledge_work": {
    "name": "Optimization of product assortments in retail using TCS Optumera",
    "description": "This use case involves using TCS Optumera's Competitive Assortment feature and 3-way item matching to compare retailer's master product data with supplier's product data and consumer demand data. This enables retailers to make data-driven decisions about which products to stock and promote. The AI Core of TCS Optumera, which includes data wrangling and visualization, sub-vertical domain-specific features, configured hybrid models, and pre-learnt AI models for deep learning, NLP, OCR, and computer vision, provides powerful insights and automated decision-making capabilities.",
    "domain": "Retail",
    "sub_domain": "",
    "business_user": "Retailers",
    "activities": [
      {
        "name": "Measuring Planogram Compliance",
        "description": "This activity involves ensuring that the products are placed on the shelves according to the planogram.",
        "role": "Retailer",
        "goal": "To ensure compliance with the planogram for optimal product placement.",
        "tasks": [
          {
            "description": "Check the placement of products on the shelves against the planogram.",
            "expected_output": "A report detailing compliance with the planogram."
          }
        ],
        "human_input_required": true,
        "finetuned_model_type": "predictive",
        "model_name": "",
        "kpi": "Planogram Compliance Rate: 95%"
      },
      {
        "name": "On-Shelf Availability Measurement",
        "description": "This activity involves using the system to measure the availability of products on the shelf to ensure that there are no stockouts.",
        "role": "Retailer",
        "goal": "To ensure that all products are available on the shelf for customers.",
        "tasks": [
          {
            "description": "Use the system to check the availability of products on the shelf.",
            "expected_output": "A report detailing the availability of products on the shelf."
          }
        ],
        "human_input_required": true,
        "finetuned_model_type": "predictive",
        "model_name": "",
        "kpi": "On-Shelf Availability Rate: 98%"
      },
      {
        "name": "Merchandise Analytics",
        "description": "This activity involves using the insights hub to perform deep features analysis, including clustering, demand transfer, consumer decision tree, affinity, and item elasticity.",
        "role": "Retailer",
        "goal": "To gain insights into merchandise performance and customer behavior.",
        "tasks": [
          {
            "description": "Use the insights hub to perform deep features analysis.",
            "expected_output": "A report detailing the results of the deep features analysis."
          }
        ],
        "human_input_required": true,
        "finetuned_model_type": "generative",
        "model_name": "",
        "kpi": "Insight Accuracy: 95%"
      },
      {
        "name": "Automated Decision Pipeline",
        "description": "This activity involves using the AI Core to perform data wrangling and visualization, apply sub-vertical domain-specific features, and use configured/pre-learned AI models for deep learning, NLP, OCR, and computer vision.",
        "role": "Retailer",
        "goal": "To automate decision-making processes and gain insights.",
        "tasks": [
          {
            "description": "Use the AI Core to perform data wrangling and visualization, apply sub-vertical domain-specific features, and use configured/pre-learned AI models.",
            "expected_output": "Automated decisions and insights based on the analysis."
          }
        ],
        "human_input_required": true,
        "finetuned_model_type": "generative",
        "model_name": "",
        "kpi": "Decision Accuracy: 95%"
      },
      {
        "name": "Competitive Assortment Analysis",
        "description": "This involves using TCS Optumera's Competitive Assortment feature to analyze and compare the retailer's product assortment with the competition.",
        "role": "Retailer",
        "goal": "To understand the competitive landscape and optimize product assortment.",
        "tasks": [
          {
            "description": "Use the Competitive Assortment feature to analyze and compare product assortments.",
            "expected_output": "A report detailing the competitive analysis of product assortments."
          }
        ],
        "human_input_required": true,
        "finetuned_model_type": "predictive",
        "model_name": "",
        "kpi": "Competitive Analysis Accuracy: 90%"
      },
      {
        "name": "3-Way Item Matching",
        "description": "This involves using TCS Optumera's 3-way item matching feature to compare the retailer's master product data with the supplier's product data and consumer demand data.",
        "role": "Retailer",
        "goal": "To ensure accurate and comprehensive product data matching.",
        "tasks": [
          {
            "description": "Use the 3-way item matching feature to compare product data.",
            "expected_output": "A report detailing the results of the 3-way item matching."
          }
        ],
        "human_input_required": true,
        "finetuned_model_type": "predictive",
        "model_name": "",
        "kpi": "Item Matching Accuracy: 92%"
      }
    ]
  }
}


export const DockProvider = (props) => {

  const [isDocked, setIsDocked] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);
  const [dockSize, setDockSize] = useState(0.4);
  const [contentSize, setContentSize] = useState(100);
  const { darkMode } = useContext(AuthContext)
  const [chains, setChains] = useState({})
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState(initialEdges);
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState(false)
  const [history, setHistory] = useState([]);
  const [nodeIndex, setNodeIndex] = useState(2)
  const [dataName, setDataName] = useState("")
  const [isFinish, setisFinish] = useState(false)
  const [mindmapLoader, setmindmapLoader] = useState(false)
  const [enableCQRS, setEnableCQRS] = useState(true) // true for cqrs false for just c


  // ---------------context for knowledgework--------------------
  const [selectedKnowledgeWork, setSelectedKnowledgeWork] = useState({})


  // ---------------context for activityDetails--------------------
  const [isActivityDeatilsDock, setIsActivityDeatilsDock] = useState(false)
  const [activityDetailsData, setActivityDetailsData] = useState({})

  // ---------------context for chatInterface--------------------
  const [chat_history, setchat_history] = useState(initialChat_history)  // chat history - the structured json format for the chat interation 
  const [isFromAchain, setIsFromAchain] = useState(false) // to swift the dock compoent true -> achain dock false -> dock wrapper

  // ---------------context for MindMapReactFlow---------------
  const [mind_map_history, setmind_map_history] = useState({}) // for mindmap react flow formulation


  // ---------------context for ReactFlowContainer---------------
  const [activityReactFlowHis, setactivityReactFlowHis] = useState({})


  //-------------context for agent dock------------------------- 
  const [agentdockSize, setAgentDockSize] = useState(0.35);
  const [isAgentDocked, setIsAgentDocked] = useState(true)
  const [agentcontentSize, setAgentContentSize] = useState(100);

  // ----------------------context for activities and model 
  const [activity_master_json, setActivity_master_json] = useState({})


  //-----------------------context for agent Details----------------------------
  const [isAgentDetailsChanged, setIsAgentDetailsChanged] = useState(false)



  //-------------context for achain dock-------------------------
  const [isAchainDocked, setisAchainDocked] = useState(true)
  const [achaindockSize, setAchainDockSize] = useState(0.4);
  const [achaincontentSize, setAchainContentSize] = useState(100);
  //--------------------------------------------------------------



  useEffect(() => {
    let new_nodes = nodes.map(node => {
      node['data'] = { ...node['data'], isDark: darkMode };
      return node;
    })
    setNodes(new_nodes);

  }, [darkMode])

  return (
    <DockContext.Provider value={{
      isDocked, setIsDocked,
      isDraggable, setIsDraggable,
      dockSize, setDockSize,
      contentSize, setContentSize,
      chains, setChains,
      nodes, setNodes,
      edges, setEdges,
      isLoading, setIsLoading,
      path, setPath,
      history, setHistory,
      nodeIndex, setNodeIndex,
      dataName, setDataName,
      enableCQRS, setEnableCQRS,
      mind_map_history, setmind_map_history,
      isFinish, setisFinish,
      mindmapLoader, setmindmapLoader,
      chat_history, setchat_history,
      isFromAchain, setIsFromAchain,
      isAchainDocked, setisAchainDocked,
      achaindockSize, setAchainDockSize,
      achaincontentSize, setAchainContentSize,
      activityReactFlowHis, setactivityReactFlowHis,
      agentdockSize, setAgentDockSize,
      activity_master_json, setActivity_master_json,
      isAgentDocked, setIsAgentDocked,
      agentcontentSize, setAgentContentSize,
      selectedKnowledgeWork, setSelectedKnowledgeWork,
      isActivityDeatilsDock, setIsActivityDeatilsDock,
      isAgentDetailsChanged, setIsAgentDetailsChanged,
      activityDetailsData, setActivityDetailsData
    }}>
      {props.children}
    </DockContext.Provider>
  )
}