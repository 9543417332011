// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subTable_outer {
  padding-left: 80px;
  padding-right: 80px;
  background-color: #f6f7f9;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
table.subTable {
  margin-left: 0px !important;
}
table.subTable thead tr th {
  background-color: #edeff3;
  padding: 8px !important;
  font-family: "calibri-bold";
  font-size: 14px;
  color: #111621;
}
table.subTable thead tr th:first-child {
  padding-left: 15px !important;
}
table.subTable tbody tr td,
table.subTable tbody tr {
  background-color: #f6f7f9;
  border-width: 0px;
}
.cardBottomBorder {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModelOrchestrator/modelOrchestrator.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,2BAA2B;EAC3B,eAAe;EACf,cAAc;AAChB;AACA;EACE,6BAA6B;AAC/B;AACA;;EAEE,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC","sourcesContent":[".subTable_outer {\n  padding-left: 80px;\n  padding-right: 80px;\n  background-color: #f6f7f9;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\ntable.subTable {\n  margin-left: 0px !important;\n}\ntable.subTable thead tr th {\n  background-color: #edeff3;\n  padding: 8px !important;\n  font-family: \"calibri-bold\";\n  font-size: 14px;\n  color: #111621;\n}\ntable.subTable thead tr th:first-child {\n  padding-left: 15px !important;\n}\ntable.subTable tbody tr td,\ntable.subTable tbody tr {\n  background-color: #f6f7f9;\n  border-width: 0px;\n}\n.cardBottomBorder {\n  border-bottom-left-radius: 0px;\n  border-bottom-right-radius: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
