import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlchemLogo from "../../assets/img/AlchemLogo.png";
import LogoutLogo from "../../assets/img/logout.svg";
import { Link, useNavigate } from "react-router-dom";

function CompetencyNav() {
  const navigate = useNavigate();
  const handleHome = () => {
    return navigate("/");
  };
  return (
    <Navbar collapseOnSelect expand="lg" className=" pt-0 navWithBg">
      <Container fluid className="px-4">
        <Navbar.Brand onClick={handleHome} style={{ cursor: "pointer" }}>
          <img className="img-fluid" loading="lazy" src={AlchemLogo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto alchemNavLinks">
            <Nav.Link className="nav-pt" to="#" activeClassName="active">
              Dashboard
            </Nav.Link>
            <Nav.Link
              className="nav-pt"
              to="/competency"
              activeClassName="active"
            >
              Knowledge Work
            </Nav.Link>
            <Nav.Link className="nav-pt" to="#" activeClassName="active">
              Playground
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#deets" className="nav-pt">
              Mayur Jadhav
            </Nav.Link>
            <Nav.Link eventKey={2} href="#" className="nav-pt">
              <img
                className="img-fluid"
                loading="lazy"
                src={LogoutLogo}
                alt=""
              />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CompetencyNav;
