import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { FaToggleOn } from 'react-icons/fa';
import { FaToggleOff } from "react-icons/fa6";
const LoadModalCompetancy = ({ showModal, closeModal , content}) => {
   
  
   
    const handleSubmit = () => {
       
        closeModal();
    }
    return (
        <div>
           <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static"
                keyboard={false}>

                <Modal.Body>
                <h3>Coach LLM Recommendation</h3>

                    
                  <pre> {content}</pre>

                


                </Modal.Body>

                <Modal.Footer id="footer">
                    <a class="btn btn-primary cancelBtn me-3" onClick={closeModal} role="button">
                        CANCEL
                    </a>
                    <a class="btn btn-primary submitBtn me-3" onClick={handleSubmit} role="button">
                        PROCEED
                    </a>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LoadModalCompetancy
