import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const RougeScoresChart = ({ rougeScores }) => {
  // Data for the chart
  const data = {
    labels: ['1', '2', '3'], // Assuming you have three data points per ROUGE metric
    datasets: [
      {
        label: 'ROUGE-1',
        data: rougeScores.rouge1,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 2,
        tension: 0.1,
      },
      {
        label: 'ROUGE-2',
        data: rougeScores.rouge2,
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderWidth: 2,
        tension: 0.1,
      },
      {
        label: 'ROUGE-L',
        data: rougeScores.rougeL,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        tension: 0.1,
      },
      {
        label: 'ROUGE-Lsum',
        data: rougeScores.rougeLsum,
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderWidth: 2,
        tension: 0.1,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
              color: '#BAC9F1', // Set the color of the legend text
            },
          },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Score: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Data Points',
          color: '#BAC9F1',
        },
        ticks: {
            color: '#BAC9F1', // Set the color of the x-axis ticks
          },
        
      },
      y: {
        title: {
          display: true,
          text: 'Score',
          color: '#BAC9F1',
        },
        ticks: {
            color: '#BAC9F1', // Set the color of the x-axis ticks
          },
        
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default RougeScoresChart;
