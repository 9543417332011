import React, { useContext, useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import styles from "./NavBar.module.css"
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoutLogo from "../../../../assets/img/onBtn.svg";
import LogoWhite from "../../../../assets/img/logoWhite.svg";
import logodark from "../../../../assets/img/logoWhite.svg";
import { Link, useNavigate } from "react-router-dom";
import UserThumbnail from "../../../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../../../assets/img/dropdown-arrow.svg";
import { FaGear } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import PropTypes from 'prop-types'
import { AuthContext } from "../../../Auth/AuthContext";
import { DockContext } from "../../../DockWrapper/DockContext";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




export default function NavBar({ links, profile, isAgentDetailsChanged, isSummary = true, isTest = false, dynamicFuncForSave = () => console.log("this is a dynamic func for save") }) {

    const [username, setUsername] = useState();
    const [IDtoken, setIDtoken] = useState();
    const [access_token, setAccess_token] = useState();
    const [step, setStep] = useState("proceed")
    const {
        accessToken,
        darkMode,
        IDToken,
    } = useContext(AuthContext);

    const { chat_history, setNodeIndex, selectedKnowledgeWork } = useContext(DockContext);

    useEffect(() => {
        setIDtoken(IDToken);
        setAccess_token(accessToken);
        if (IDtoken) {
            const decoded = jwtDecode(IDtoken);
            setUsername(decoded.name);
        }
    }, [IDtoken, setIDtoken]);



    const navigate = useNavigate();
    const handleHome = () => {
        return navigate("/");
    };

    const handleSummaryNavigation = () => {
        console.log(isAgentDetailsChanged, "agent details changed")
        if (isAgentDetailsChanged) {
            if (window.confirm("Modification are not saved, redirection might cause loss of unsaved data")) {

                navigate("/summary")
            }
        }

    }
    const [alertMessage, setAlertMessage] = useState("")

    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className={
                darkMode
                    ? `navWithBgDark competencydetails p-0`
                    : `navWithBg competencydetails p-0 ${styles["fixed-height-15"]}`
            }
        >

            <Container fluid style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                <Navbar.Brand href="" onClick={handleHome}>
                    <img
                        className="img-fluid logoSize"
                        loading="lazy"
                        src={darkMode ? logodark : LogoWhite}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {links && (
                        <Nav
                            className="me-auto alchemNavLinks"
                            defaultActiveKey={"competencies"}
                        >
                            {links.map((link, index) => (
                                <Nav.Link
                                    to={link.route}
                                    className={`justify-content-center align-items-center d-flex  ${darkMode ? "text-dark" : "text-white"
                                        }`}
                                    style={{
                                        display: "inline-block",
                                        borderBottom: link.isActive ? '3px solid #3366FF' : 'none',

                                    }}
                                    as={Link}
                                    key={index}
                                >
                                    <div className={styles.line} style={{ height: "100%" }}></div>
                                    {link.name}
                                </Nav.Link>
                            ))}
                        </Nav>
                    )}

                    <div
                        className="collapse navbar-collapse"
                        id="navbarNavAltMarkup"
                    >
                        <div
                            className="navbar-nav ms-auto w-100 d-flex justify-content-end align-items-center"
                            style={{ paddingRight: "30px" }}
                        >

                            {isTest ?
                                <Link className={"btn btn-primary cancelBtnDark test-uppercase"}>
                                    Exit TEST
                                </Link>
                                :
                                <>
                                    <div className="d-flex align-items-center">
                                        {
                                            isSummary && <>
                                                <p
                                                    className={`${styles["link-text"]} mr-2 m-auto pr-2`}
                                                    onClick={handleSummaryNavigation}
                                                >
                                                    Summary
                                                </p>
                                                <div className="line me-3" />
                                            </>
                                        }
                                        {/* <Link
                                            className={`${styles["link-text"]} text-danger`}
                                            to={"/activityList"}
                                        >
                                            close
                                        </Link> */}
                                    </div>

                                    {/* <Link className={"btn btn-primary cancelBtnDark"} onClick={dynamicFuncForSave} >
                                        Save
                                    </Link> */}
                                </>
                            }



                            {/* <button
                                className="btn btn-primary submitBtnDark"
                                onClick={() => setNodeIndex(1)}
                            >
                                {step}
                            </button> */}

                            <NavComp profile={profile} />
                        </div>
                    </div>

                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}


// -------------------------------------------------------------
// ---------------------user nav comp


export function AlertModal(handleClose, message = "", show = false) {
    const navigate = useNavigate()
    return (
        <Modal
            size="sm"
            show={show} onHide={handleClose} key={false}
            aria-labelledby="example-modal-sizes-title-sm"
            style={{
                zIndex: 9999999999999999
            }}
            className={"alchemModalDark"}
            dialogClassName={"modal-sm"}
        >
            <Modal.Header className="d-inline-flex" closeButton>
                <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-inline-flex">{message}</Modal.Body>
            <Modal.Footer className="d-inline-flex">
                <Button variant="primary" onClick={handleClose}>
                    cancel
                </Button>
                <Button variant="danger" onClick={() => navigate("/summary")}>
                    ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}




export function NavComp({ profile }) {
    const {
        setAuthenticated,
        accessToken,
        darkMode,
        IDToken,
        setIDToken
    } = useContext(AuthContext);


    const [username, setUsername] = useState();
    const [idtoken, setIdtoken] = useState();
    const [access_token, setAccess_token] = useState();


    useEffect(() => {
        // Retrieve the JWT token from local storage
        setIdtoken(IDToken);
        setAccess_token(accessToken);
        if (idtoken) {
            // Decode the JWT token
            const decoded = jwtDecode(idtoken);
            setUsername(decoded.name);
            // setUsername(decoded.)
        }
    }, [idtoken, setIdtoken]);

    function removeCookie(name) {
        document.cookie =
            name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const handleLogout = () => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");
        // Construct logout URL with headers
        removeCookie("accessToken");
        removeCookie("refreshToken");
        removeCookie("IDToken");
        setAuthenticated(false);
    };



    return (
        <>
            {profile ? (
                <>
                    <div className="line me-3"></div>
                    <Nav>
                        <NavDropdown
                            title={
                                <>
                                    <img src={UserThumbnail} />
                                    <span
                                        className="user-name"
                                        style={darkMode ? { color: "#E5EAF1" } : {}}
                                    >
                                        {username}
                                    </span>
                                    <img src={DropDownArrow} />
                                </>
                            }
                            id="navbarScrollingDropdown "
                            className={
                                "btn user-profile m-0 " + darkMode ? " user-drop-down" : ""
                            }
                            style={
                                darkMode
                                    ? { backgroundColor: "#212542", border: "none" }
                                    : {}
                            }
                        >
                            <NavDropdown.Item href="#action3">
                                User Profile
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleLogout()}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </>
            ) : (
                ""
                // <div className="d-flex justify-center align-items-center">
                //     <FaGear className="text-white" style={{ fontSize: "18px" }} />
                // </div>
            )}
        </>
    )
}

NavComp.propTypes = {
    profile: PropTypes.bool.isRequired
}


