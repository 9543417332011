import React, { useEffect, useState, useContext } from "react";
import { DockContext } from "../DockWrapper/DockContext";
import "./AChain.css";
import "reactflow/dist/style.css";
import PropTypes from "prop-types";
import ActivityPopup, { ChainPopup } from "./popup/ActivityPopup";
import ChatInterface from "./FunctionalChatInterface/ChatInterface";
import AchainDock from "./AchainDock/AchainDock";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import PinkBtnGif from '../../../src/assets/img/alchemist-ai.gif'
import { AuthContext } from "../Auth/AuthContext";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const AChain = ({ darkMode, handleToggle }) => {
  const { isDocked} = useContext(DockContext);

  const navigate = useNavigate();

  const {
    setChains,
    nodes, setNodes,
    setEdges,
    isLoading, setIsLoading,
    isFinish,
    chat_history,
    isAchainDocked, setisAchainDocked,
    selectedKnowledgeWork, setSelectedKnowledgeWork

  } = useContext(DockContext);
  const [proceedDisable, setProceedDisable] = useState(true);

  // for swapping activity pop with chain pop
  const [ActivityComplete, setActivityComplete] = useState(false)

  useEffect(() => {
    setisAchainDocked(true)
  }, [])

  useEffect(() => {
    if (nodes.length > 0) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
  }, [nodes]);
  // ----------------------------creation modal attri's

  const [showCreationModal, setShowCreationModal] = useState(false);


  const closeCreationModal = () => {
    setShowCreationModal(false);
  };



  // agent chain creation attris
  const [createChain, setCreateChain] = useState(false)


  // --------------------------validate modal attri's

  const [isChainValidated, setIsChainValidated] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);

  const openValidateModal = () => {
    setShowValidateModal(true);
  };

  const closeValidateModal = () => {
    setShowValidateModal(false);
  };

  useEffect(() => {
    setShowCreationModal(isChainValidated)

  }, [isChainValidated])
  const links = [
    { name: "Knowledge Work", route: "/activityList", isActive: false },
  ];

  return (
    <>
      <CreationModal
        showModal={showCreationModal}
        closeModal={closeCreationModal}
        setCreateChain={setCreateChain}
      />

      {/* activity chain validation confirmation modal */}
      <ValidateModal
        showModal={showValidateModal}
        closeModal={closeValidateModal}
        isChainValidated={isChainValidated}
        setIsChainValidated={setIsChainValidated}
      />
      <CustomNavbar links={links} profile={false} handleToggle={handleToggle} />
      <div className='d-flex flex-row justify-content-between' style={{ background: 'transparent linear-gradient(90deg, #212542 0%, #222C53 49%, #212542 100%) 0% 0% no-repeat padding-box' }}>
        <div className='d-flex flex-column' style={{ marginLeft: '2.5%' }}>
          <span className='mt-4 mb-1' style={{ font: 'normal normal bold 17px/21px Calibri', letterSpacing: '0.55px' }}>Create Knowledge Work</span>
          <span className='mb-4 mt-1' style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px' }}>Knowledge Work: {selectedKnowledgeWork?.name}</span>
        </div>
        <div className='d-flex flex-row mt-auto mb-2' style={{ marginRight: '2.5%' }}>
          <button className='btn me-2' style={{ font: 'normal normal bold 14px/24px Calibri', letterSpacing: '0px', color: '#012060', background: '#D7E5FF 0% 0% no-repeat padding-box', borderRadius: '4px' }} onClick={() => { navigate('/activityList') }}>Close</button>
        </div>
      </div >


      {


        <div className="d-flex position-relative flex-row activity-chat-container mx-4 my-4" style={{ backgroundColor: "#E1E7F9", minHeight: '70vh', maxHeight: '70vh' }}>

          <img src={PinkBtnGif} className="position-absolute" style={{ height: '77.01px', width: '77.01px', overflow: 'visible', top: 'calc(50% - 38px)', left: '-49px', zIndex:"999999" }}></img>

          <ChatInterface
            setChains={setChains}
            setEdges={setEdges}
            setNodes={setNodes}
            setIsLoading={setIsLoading}
            isFinish={isFinish}
            mainJson={chat_history}
            setActivityComplete={setActivityComplete}
            openValidateModal={openValidateModal}
          />
          <AchainDock achain={true} ActivityComplete={ActivityComplete} ></AchainDock>
        </div >

      }

      {
        !isDocked && !isAchainDocked && (

          <div className="">
            {
              ActivityComplete ?
                <ChainPopup />
                :
                <ActivityPopup />
            }
          </div>

        )
      }


      {
        isLoading && (
          <div className="loading-overlay">
            <div className="">
              <div className="spinner-border text-light"></div>
              <p className=" text-white" style={{ marginLeft: "-40px" }}>
                Generating Your Flow...
              </p>
            </div>
          </div>
        )
      }

    </>
  );
};
AChain.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default AChain;






//-------------------------------components used on the above achain


export function CreationModal({ showModal, closeModal, setCreateChain }) {
  const { darkMode } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/summary')
    closeModal();
  };

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        handleSubmit()
      }, 2000);
    }
  })

  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "9999999999999999999999999999999" }}
        className={darkMode ? "alchemModalDark" : "alchemModal"}
      >
        <Modal.Header
          closeButton
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginLeft: "16px",
            marginRight: "16px",
            borderBottom: "1px solid #101221",
          }}
        >
          <Modal.Title className="modalHead text-uppercase">Agent Chain Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <label htmlFor="exampleInputPassword1" className="form-label">
              The Agents will be created from { } activity onwards
            </label>
            <div className="spinner-border m-5" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer
          id="footer"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
            justifyContent: "center"
          }}
        >
          <button
            className="btn btn-primary submitBtnDark me-0 text-uppercase"
            onClick={closeModal}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

CreationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
}




export function ValidateModal({ showModal, closeModal, isChainValidated, setIsChainValidated }) {
  const { darkMode } = useContext(AuthContext);


  const handleSubmit = () => {
    setIsChainValidated(true)
    closeModal();
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "9999999999999999999999999999999" }}
        className={darkMode ? "alchemModalDark" : "alchemModal"}
      >
        <Modal.Header
          closeButton
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginLeft: "16px",
            marginRight: "16px",
            borderBottom: "1px solid #101221",
          }}
        >
          <Modal.Title className="modalHead text-uppercase">Confirm Activity Chain Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Please note that you will not be able to edit activity chain after validation
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer
          id="footer"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <button
            className="btn btn-primary submitBtnDark me-0 text-uppercase"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary submitBtnDark me-0 text-uppercase"
            onClick={handleSubmit}
          >
            Validate
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

ValidateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isChainValidated: PropTypes.bool.isRequired,
  setIsChainValidated: PropTypes.func.isRequired
}

