// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.blue .MuiLinearProgress-root{
    height: 8px !important;
    border-radius: 50px;
    /* width: 90% !important; */
}

.blue .MuiLinearProgress-colorPrimary{
    background-color: #DFE1E5 !important;
    
}
.blue .MuiLinearProgress-bar1Determinate{
    border-radius: 50px;
    background: transparent linear-gradient(90deg, #88B2FF 0%, #3366FF 100%) !important;
}
.blue{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/graph/ProgressBarBlue/ProgressBarBlue.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,oCAAoC;;AAExC;AACA;IACI,mBAAmB;IACnB,mFAAmF;AACvF;AACA;IACI,WAAW;AACf","sourcesContent":["\n.blue .MuiLinearProgress-root{\n    height: 8px !important;\n    border-radius: 50px;\n    /* width: 90% !important; */\n}\n\n.blue .MuiLinearProgress-colorPrimary{\n    background-color: #DFE1E5 !important;\n    \n}\n.blue .MuiLinearProgress-bar1Determinate{\n    border-radius: 50px;\n    background: transparent linear-gradient(90deg, #88B2FF 0%, #3366FF 100%) !important;\n}\n.blue{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
