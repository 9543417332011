import React from 'react'
import PropTypes from 'prop-types'

export default function Collapse({ title, description, id }) {
    return (
        <>
            <div className='d-flex justify-content-between align-items-center' data-bs-toggle="collapse" href={`#collapse-${id}`} role='button' aria-expanded="false" aria-controls={`collapse-${id}`}>
                <div className='d-flex w-100 justify-content-start align-items-center'>
                    {title}
                </div>
            </div>
            <div className="collapse" id={`collapse-${id}`}>
                <div className="card card-body d-flex disable-scrollbars" style={{ borderRadius: "unset", overflowY: "scroll" }}>
                    {description}
                </div>
            </div>
        </>
    )
}

Collapse.propTypes = {
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    id: PropTypes.number.isRequired
}