import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BleuScoreChart = ({ bleuScore, brevityPenalty }) => {
  // Data for the chart
  const data = {
    labels: ['BLEU Score', 'Brevity Penalty'],
    datasets: [
      {
        label: 'BLEU Metrics',
        data: [bleuScore, brevityPenalty],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        tension: 0.1,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
              color: '#BAC9F1', // Set the color of the legend text
            },
          },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Value: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Metrics',
          color: '#BAC9F1',
        },
        ticks: {
            color: '#BAC9F1', // Set the color of the x-axis ticks
          },
        
      },
      y: {
        title: {
          display: true,
          text: 'Value',
          color: '#BAC9F1',
        },
        ticks: {
            color: '#BAC9F1', // Set the color of the x-axis ticks
          },
        
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default BleuScoreChart;
