import React, { useContext, useEffect, useState } from "react";
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import { Col, Container,Row } from "react-bootstrap";
import { AuthContext } from "../../Auth/AuthContext";
import { MdInfo } from "react-icons/md";
import ChatPopup from "../../AChain/ChatPopup/ChatPopup";
import Table from "react-bootstrap/Table";
import { DockContext } from "../../DockWrapper/DockContext";
import Papa from 'papaparse';

const CSVTable = ({ csvText }) => {
  const [tableData, setTableData] = useState([]);
  const {darkMode} = useContext(AuthContext)
  // Parse the CSV text and convert it into table data
  const parseCSV = (text) => {
    Papa.parse(text, {
      complete: (results) => {
        const data = results.data;
        console.log(data)
        setTableData(data);
      },
      header: true
    });
  };

  // Trigger the parseCSV function when the csvText prop changes
  useEffect(() => {
    parseCSV(csvText);
  }, [csvText]);

  return (
    <div className="csv-data ">
      <Table className={ `${darkMode ? 'custom-tableDark' : 'custom-table'} mt-3`}>
        <thead>
          <tr>
          <th style={{ fontWeight: "bold" }}>S.No.</th>
            {tableData[0] && Object.keys(tableData[0]).map((header, index) => (
              <th key={index} style={{ fontWeight: "bold" }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td key={index} style={{borderBottom: "none"}}>{index}</td>
              {Object.values(row).map((cell, index) => (
                <td key={index} style={{borderBottom: "none"}}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};


const SyntheticDataGenerator = () => {
    const [tableData, setTableData] = useState(`Patient ID,Age,Gender,Disease,Blood Pressure,Heart Rate,BMI,Medication,Laboratory Test,Treatment Duration,Adverse Reactions,Follow-Up Visits,Clinical Trial,Research Facility\n12312,67,"Male","Heart Disease","140/90 mmHg",80,27.5,"Beta Blockers","Cholesterol Test",6,"Fatigue",2,true,"ABC Medical Center"`)
    const {darkMode} = useContext(AuthContext)
    // const [dataName, setDataName] = useState("")
    const {dataName, setDataName} = useContext(DockContext)
    return(
        <>
        <CustomNavbar links={[]}/>
        <ChatPopup isSynthe setTableData= {setTableData} dataName = {dataName} setDataName = {setDataName}/>
        <Container className="mx-0 px-40 w-100 " style={{maxWidth: "unset", maxHeight: "unset", height: "90vh"}}>
        <Row className="text-white mx-0 pt-40 pb-3">
              <Col className="col-md-9">
            <h5 className={`${darkMode ? 'createAgentTitleDark px-0' : 'createAgentTitle px-0'} text-left`}>
            Synthesize Data
          </h5>
          <p className={`${darkMode ? 'currentNameDark px-0 m-0' : 'currentName px-0 m-0'}`}>Data: {dataName} <MdInfo style={{paddingBottom: "4px"}}></MdInfo> </p>
          </Col>
          <Col className="align-items-end">
          <button
                    className={darkMode ? 'btn btn-primary cancelBtnDark button-text-dark' : 'btn btn-primary submitBtnDark'}
                    style={{ height: "33px", width: "80px", marginRight: "0px" }}
                    role="button"
                  >
                    Cancel
                  </button>
          </Col>
          <Col className="gx-0">
          <button
                    className={darkMode ? 'btn btn-primary submitBtnDark button-text-dark' : 'btn btn-primary submitBtnDark'}
                    style={{ height: "33px", width: "120px", marginRight: "0px" }}
                    role="button"
                  >
                    Save and Close
                  </button>
          </Col>
            </Row>
            <Row className="text-white mx-0 " style={{backgroundColor: "#454d7b", minHeight: "70vh" , marginBottom: "4rem"}}>
            {tableData.length > 0 && (
          // <div className="csv-data ">
          //   <Table className={ `${darkMode ? 'custom-tableDark' : 'custom-table'} mt-3`}>
          //     <thead className="table-secondary ">
          //       <tr>
          //         {Object.keys(tableData[0]).map((header, index) => (
          //           <th key={index} style={{ fontWeight: "bold" }}>
          //             {header}
          //           </th>
          //         ))}
          //       </tr>
          //     </thead>
          //     <tbody>
          //       {tableData.map((row, index) => (
          //         <tr key={index}>
          //           {Object.values(row).map((cell, cellIndex) => (
          //             <td key={cellIndex} style={{borderBottom: "none"}}>{cell}</td>
          //           ))}
          //         </tr>
          //       ))}
          //     </tbody>
          //   </Table>
          // </div>
          <>
            <CSVTable csvText={tableData}/>
          </>
        )}
            </Row>
        </Container>
        </>
    )
}

export default SyntheticDataGenerator;