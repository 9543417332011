import Card from "react-bootstrap/Card";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import ActivityListFilters from "./ActivityListFilters";
import plusicon from '../../../assets/img/plusIcon.png'
import plusIconDark from '../../../assets/img/plusIconDark.png'
import star from '../../../assets/img/star-solid.svg'
import ActivityModal from "./ActivityModal";
import ChatPopup from "../../AChain/ChatPopup/ChatPopup";
import DotIcon from "../../ModelOrchestrator/ModelHomeScreen/dotIcon.png"
import { FaStar } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import Robotckw from "../../../assets/img/robot-ckw.svg"
import axios from "axios";
import { borderBottom } from "@mui/system";
import verticalThreeDots from "../../../assets/img/vertical_three_dots_dark.svg"
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import { AuthContext } from "../../Auth/AuthContext";


const SyntheticTable = ({ handleToggle}) => {
    const {darkMode} = useContext(AuthContext)

    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(false)
    const [activityData, setActivityData] = useState([]);
    const [jsonData, setJsonData] = useState({
      name: '',
      description: '',
      dateCreated: '',
      dataPoints: '',
      domain: '',
      subDomain: '',
      source: '',
      dataType: ''
  });

//   const tableData = Array.from({ length: 10 }).map((_, index) => ({
//     ...jsonData,
//     name: `Name of the data`,
//     description: `Lorem ipsum dolor, sit amet consectetur ad`,
//     dateCreated: `12-oct-2024`,
//     dataPoints: `100`,
//     domain: `Retail`,
//     subDomain: `Subdomain`,
//     source: `Synthesized`,
//     dataType: `JSON`
// }));

const tableData = [
  {
    name: "Transaction Details",
    description: "Provides details of all financial transactions.",
    dateCreated: "06-Jan-2021",
    dataPoints: "250",
    domain: "Banking",
    subDomain: "Transactions",
    source: "Internal",
    dataType: "CSV"
  },
  {
    name: "User Behaviour Data",
    description: "Collects data on user habits and preferences.",
    dateCreated: "18-Mar-2021",
    dataPoints: "1000",
    domain: "IT",
    subDomain: "Analytics",
    source: "Synthesized",
    dataType: "JSON"
  },
  {
    name: "Inventory List",
    description: "Keeps track of warehouse stock and products.",
    dateCreated: "25-Apr-2021",
    dataPoints: "300",
    domain: "Retail",
    subDomain: "Inventory",
    source: "Internal",
    dataType: "XML"
  },
  {
    name: "Employee Records",
    description: "Maintains all employee-related data and records.",
    dateCreated: "10-Feb-2021",
    dataPoints: "500",
    domain: "HR",
    subDomain: "Employee Management",
    source: "Internal",
    dataType: "JSON"
  },
  {
    name: "Weather Data",
    description: "Keeps record of hourly/daily weather data.",
    dateCreated: "01-May-2021",
    dataPoints: "1500",
    domain: "Meteorology",
    subDomain: "Weather Analytics",
    source: "External",
    dataType: "CSV"
  },
  {
    name: "Sales Data",
    description: "Contains detailed sales figures across various regions.",
    dateCreated: "16-Jun-2021",
    dataPoints: "800",
    domain: "Sales",
    subDomain: "Regional Sales",
    source: "Internal",
    dataType: "XLXS"
  },
  {
    name: "Voting Records",
    description: "Keeps records of all citizen votes.",
    dateCreated: "08-Oct-2021",
    dataPoints: "2000",
    domain: "Government",
    subDomain: "Voting",
    source: "Public",
    dataType: "JSON"
  },
  {
    name: "E-commerce Data",
    description: "Keeps track of all online sales and customer interaction data.",
    dateCreated: "20-Nov-2021",
    dataPoints: "1000",
    domain: "E-commerce",
    subDomain: "Sales and Marketing",
    source: "Internal",
    dataType: "CSV"
  },
  {
    name: "Healthcare Records",
    description: "Contains patient records and healthcare analytics.",
    dateCreated: "15-Mar-2021",
    dataPoints: "1200",
    domain: "Healthcare",
    subDomain: "Patient Management",
    source: "Internal",
    dataType: "JSON"
  },
  {
    name: "Real Estate Data",
    description: "Keeps data related to properties, prices and trends.",
    dateCreated: "22-Jan-2021",
    dataPoints: "700",
    domain: "Real Estate",
    subDomain: "Market Trends",
    source: "Synthesized",
    dataType: "XML"
  }
];
   
    let navigate = useNavigate();
    
    const openModal = () => {
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const links = [
      // { name: 'Dashboard', route: '/', isActive: false },
      { name: 'Knowledge Work', route: '/activityList',isActive: false },
      { name: 'Agent Factory', route: '/agentFactory', isActive: false },
      { name: 'Model Orchestrator', route: '/rewardmodelhome',isActive: false },
      { name: 'Data Repository', route: './',isActive: true },
    ];
    const noLinks =[]
    return (
        <div className={`activityList`}>
            <ActivityModal showModal={showModal} closeModal={closeModal} darkMode={darkMode} handleToggle={handleToggle}/>
            {/* <ActivityNav darkMode={darkMode} handleToggle={handleToggle} ></ActivityNav> */}
            <CustomNavbar links={links} profile={true} handleToggle={handleToggle}/>
            <ChatPopup/>
            <div style={{padding: "40px"}} className="pt-4">
                <nav className="navbar navbar-expand-lg pb-3 pt-0">
                    <div className="container-fluid px-0 ">
                        <span className={darkMode ? 'text-white page-title-dark' : 'text-dark page-title-dark'}>Training Data (20)</span>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav ms-auto px-0">

                                <NavLink>
                                    <button
                                        className={darkMode ? 'btn btn-primary submitBtnDark button-text-dark' : 'btn btn-primary submitBtnDark'}
                                        // style={{height: "33px", width: "198px", marginRight: "0px"}}
                                        role="button"
                                        onClick={() => openModal()}
                                    >
                                        <span style={{marginRight: '5px', position: 'relative', bottom: '3px'}}>
                                            {/* <img src={Robotckw} alt="" /> */}
                                            </span>
                                        Synthesize Data
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </nav>
                <ActivityListFilters darkMode = {darkMode}/>

                <Card className={darkMode ? 'tableCardDark' : 'tableCard'}>
                    <Card.Body className="p-4">
                 

    
                        <table class={darkMode ? 'table alchemTableDark allModelTable mb-0' : 'table alchemTable allModelTable mb-0'}>
                            <thead className={"custom-table-head-dark " + darkMode?"dark-text":""}>
                                <tr>
                                    <th ></th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Name</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Description</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Date Created</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Data Points</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Domain</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Sub Domain</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Source</th>
                                    <th style={{borderRight: "#2F499E22 solid 1px", paddingLeft: "16px"}}>Data Type</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                    <tbody className={  darkMode?"dark-text":""}>
  {tableData.map((tableRow, index)=> (
    <>
      <tr
        key={index}
        className="non-hidden"
      >
     
     <td style={{borderBottom: "none"}}></td>
   
        <td style={{borderRight: "#2F499E22 solid 1px"}}><div className="activityLinkCell">{tableRow.name}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}}><div>{tableRow.description}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}}><div>{tableRow.dateCreated}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}}><div>{tableRow.dataPoints}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}}><div>{tableRow.domain}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}}><div>{tableRow.subDomain}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}} className="text-center"><div>{tableRow.source}</div></td>
        <td style={{borderRight: "#2F499E22 solid 1px"}} className="text-center"><div>{tableRow.dataType}</div></td>
       
        <td >
            <div className="w-100 h-100 d-flex justify-content-end align-items-center pr-4"><img src={verticalThreeDots}></img></div>
        </td>
        <td style={{borderBottom: "none"}}></td>
      </tr>
      <tr>
        <td colspan="12" className="hiddenRow border-bottom">
          <div
            class="collapse subTable_outer multi-collapse"
            id={"rowId" + index}
            style={{background: darkMode ? '#212542' : ''}}
          >
            <table className={`table ${darkMode ? 'subTableDark' : 'subTable'} mb-0`}  >
              
              <tbody>
                <tr style={{backgroundColor : "#212542"}}>
                  <td colSpan="1"  style={{borderBottom: darkMode ? 'none' : ''}}><button    class="btn btn-primary submitBtnDark"
                                        role="button">API</button></td>
                  <td colSpan="1"  style={{borderBottom: darkMode ? 'none' : ''}}><button   class="btn btn-primary submitBtnDark"
                                        role="button">TEST</button></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td><td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td><td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
                <td  style={{borderBottom: darkMode ? 'none' : ''}}></td>
               
                
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  ))}
</tbody>
                        </table>
      {/* )} */}
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default SyntheticTable;