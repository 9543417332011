import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import downarrow from "../../../assets/img/down_arrow.svg";
import Graphshow from "../../ModelOrchestrator/Graphshow";
import LoadRewardModal from "./LoadRewardModal";
import PropTypes from "prop-types";

const TrainingProgressGenAI = ({ proceedToRewardModel, darkMode }) => {
  const [showModal, setShowModal] = useState(false);
  

  const formatTime = (totalSeconds) => {
    console.log("rfsf", totalSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Padding each value with a leading zero if less than 10
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const [rewardprogress, setRewardProgress] = useState([]);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [counter, setCounter] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [disp, setdisp] = useState("");
  const [startTime, setStartTime] = useState(Date.now()); // Start time
  const [elapsedTime, setElapsedTime] = useState(0); // Elapsed time in seconds
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const proceedRewardModel = () => {
    proceedToRewardModel();
  };

  useEffect(() => {
    setStartTime(Date.now());
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://172.208.104.191:5002/get_logs"
        );
        const logs = response.data.logs;

        if (rewardprogress === logs) {
          setCounter((prevCounter) => prevCounter + 1);
        } else {
          setCounter(0);
        }
        setRewardProgress(logs);

        logs.forEach((log) => {
          let percentage = 0;
          if (log.includes("COMPLETED")) {
            percentage = 99;
            setLoadingPercentage(percentage);
            setCompleted(true);
          }
          // Custom regex patterns for different log messages
          else if (log.includes("Loading checkpoint shards")) {
            setdisp("LORA ADAPTER");
            const match = log.match(/(\d+)/);
            if (match) {
              percentage = parseInt(match[1]);
            }
          } else if (log.includes("Map:") && log.includes("%")) {
            setdisp("LOAD DATASET");
            const match = log.match(/(\d+)%/);
            if (match) {
              percentage = parseInt(match[1]);
            }
          } else if (log.includes("%|") && log.includes("|")) {
            setdisp("TRAIN MODEL");
            const match = log.match(/(\d+)%\|/);
            if (match) {
              percentage = parseInt(match[1]);
            }
            if (percentage == 100) {
              setCompleted(true);
            }
          }
          // Add more conditions for other log patterns if needed

          // Set the loading percentage based on the matched regex pattern
          setLoadingPercentage(percentage);
        });

        console.log("Fetch SFT progress success");
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401)
          window.location.reload();
      }
    };
    const interval = setInterval(() => {
      const newElapsedTime = Math.floor((Date.now() - startTime) / 1000);
      setElapsedTime(newElapsedTime);

      if (!completed && loadingPercentage < 100) {
        fetchData();
      } else {
        clearInterval(interval);
      }
    }, 600);

    return () => clearInterval(interval);
  }, [completed, loadingPercentage]);
  const [displayText, setDisplayText] = useState(true);
  const toggleText = () => {
    setDisplayText(displayText !== true);
  };

  return (
    <div className="">
      <LoadRewardModal
        showModal={showModal}
        closeModal={closeModal}
        proceedRewardModel={proceedRewardModel}
        darkMode={darkMode}
      />

      <div className="pageHeadBg">
        <div className="CustomTable-Header mt-0 pt-3">
          <div
            className="CustomTable-Header-Left px-2 w-100"
            style={{ lineHeight: "31px" }}
          >
            <span
              className={`taskList ${
                darkMode ? "text-white" : "text-dark"
              } border-end px-3`}
            >
              Training Progress
            </span>
            <span
              className={`taskList ${
                darkMode ? "text-white" : "text-dark"
              } border-end px-3`}
            >
              Model Name : {localStorage.getItem("name")}
            </span>
            <span
              className={`taskList ${
                darkMode ? "text-white" : "text-dark"
              } px-3`}
            >
              SELECTED MODEL : {localStorage.getItem("basemodelname")}
            </span>
            <Link
              class="btn btn-primary submitBtnDark ms-3 float-right me-3"
              onClick={openModal}
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="container-fluid pt-0 px-4">
          <div className="row mx-0 mb-3">
            <div className="col-12 px-0">
              {completed ? (
                <div className="card mb-4" style={{ height: "70px" }}>
                  <div className="row mt-2 px-4">
                    <div className="col-md-10 px-1 py-2">
                      <span
                        className="tabSub-head"
                        style={{ color: darkMode ? "#BAC9F1" : "" }}
                      >
                        SFT Training Completed{" "}
                      </span>
                    </div>

                    <div
                      className="col-md-2  px-1 py-2"
                      style={{ color: darkMode ? "#BAC9F1" : "" }}
                    >
                      Time Taken: {formatTime(elapsedTime)}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="card mb-4 p-3"
                  style={{ background: darkMode ? "#34385A" : "" }}
                >
                  <div className="mt-2 px-3">
                    <span
                      className="tabSub-head"
                      style={{ color: darkMode ? "#BAC9F1" : "" }}
                    >
                      SFT Model: {disp}{" "}
                    </span>
                  </div>
                  <div className="row mx-0 mt-2 px-3">
                    <div className="col-md-11 px-0 py-2">
                      <ProgressBar
                        style={{ width: "100%" }}
                        now={loadingPercentage}
                        min="0"
                        max="100"
                      />
                    </div>

                    <div className="col-md-1 mb-2">
                      <button className="cancelBtnDark"> Cancel</button>
                    </div>
                  </div>

                  <div className="row mb-3 mx-0 px-3">
                    <div className="col-md-10 px-0">
                      <span
                        className="baseFont "
                        style={{ color: darkMode ? "#BAC9F1" : "" }}
                      >
                        {loadingPercentage}%
                      </span>
                    </div>
                    <div className="col-md-2 d-flex flex-start pe-0 ps-5">
                      <span
                        className="baseFont float-right"
                        style={{ color: darkMode ? "#BAC9F1" : "" }}
                      >
                        {formatTime(elapsedTime)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div
            className="row mx-0 d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: darkMode ? "#474E71" : "#D9E3F8",
              border: "none",
            }}
          >
            <button type="button" className="btn p-0">
              <button
                className="btn consoleBtn align-items-center"
                onClick={toggleText}
              >
                Console
                <img
                  className="img-fluid mb-1 ms-2"
                  loading="lazy"
                  src={downarrow}
                  alt=""
                  style={{ height: "10px", width: "10px", marginTop: "5px" }}
                />
              </button>
            </button>
          </div>

          {!displayText ? (
            <Graphshow
              loadingPercentage={loadingPercentage}
              darkMode={darkMode}
            />
          ) : (
            <div className="row mx-0">
              <div className="col-12 px-0">
                <div
                  className="cards"
                  style={{
                    backgroundColor: "#031039",
                    overflow: "scroll",
                  }}
                >
                  <div className="card-header border-white">
                    <ul className="nav">
                      <li className="nav-item">
                        <button className="nav-link active text-white">
                          Console
                        </button>
                      </li>
                      <li className="nav-item disabled"></li>
                    </ul>
                  </div>
                  <div className="card-body text-white px-4">
                    {rewardprogress.map((log, index) => (
                      <pre className="text-white" key={index}>
                        {log}
                      </pre>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TrainingProgressGenAI.propTypes = {
  proceedToRewardModel: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default TrainingProgressGenAI;
