import Nav from "react-bootstrap/Nav";
import "./CreateAgent.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../Auth/AuthContext";

function CreateAgentNav() {
  const {darkMode} = useContext(AuthContext)
  return (
    <div className="container-fluid pt-3 ps-4 ">
      <div className="row mx-0 pb-3">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-4 ps-0" style={{color : !darkMode?'black' : 'white'}}> Add New Agent </h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav
            variant="pills"
            defaultActiveKey="/agentfactory/createAgent"
            className="agentWizard"
          >
            <Nav.Item className="" id="agentDetailsWizDiv">
              <Nav.Link
                // as={Link}
                // to="/agentfactory/createAgent"
                disabled={true}
                eventKey="/agentfactory/createAgent"
                className="linkPill mx-auto mb-1"
              >
                1
              </Nav.Link>
              <span className="pilltext"> Activity Details</span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <Nav.Item className="negMarg" id="selectModelWizDiv">
              <Nav.Link
                // as={Link}
                // to="/agentfactory/createAgent/selectModel"
                disabled={true}
                eventKey="/agentfactory/createAgent/selectModel"
                // href="/agentfactory/agentDetails"
                className="linkPill mx-auto mb-1"
              >
                2
              </Nav.Link>
              <span className="pilltext">Select Model </span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg ml-n18" id="testAgentWizDiv">
              <Nav.Link
                // as={Link}
                // to="/agentfactory/createAgent/testAgent"
                disabled={true}
                eventKey="/agentfactory/createAgent/testAgent"
                // href="/agentfactory/createAgent/testAgent"
                className="linkPill mx-auto mb-1"
              >
                3
              </Nav.Link>
              <span className="pilltext">Test Activity </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default CreateAgentNav;
