import React from "react";
import Nav from "react-bootstrap/Nav";
import "../AgentModule/CreateAgent/CreateAgent.css";
import { MdInfo } from "react-icons/md";
import checkimg from "../../assets/img/darkCheckImg.svg";


function CreateAgentNav({ darkMode, kwName, index }) {
  console.log(index, "index");
  console.log(darkMode, "index");
  return (
    <div
      className={`container-fluid pt-4 ps-1 ${darkMode ? "bgwDark" : "bgw"}`}
    >
      <div className="row mx-0 pb-3 ps-3 pe-4">
        <div className="col-md-3 px-0 pb-3 mt-2">
          <h5
            className={`${
              darkMode ? "createAgentTitleDark" : "createAgentTitle"
            } pt-2 ps-4 text-left`}
          >
            {" "}
            Activity Chain Creation{" "}
          </h5>
          <p className={`${darkMode ? "currentNameDark" : "currentName"} ps-2`}>
            Knowledge Work: {kwName} <MdInfo />
          </p>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav variant="pills" defaultActiveKey="#" className="agentWizard">
            <Nav.Item className="modelNegMarg">
              <Nav.Link
                to="#"
                eventKey="#"
                className={
                  index >= 1
                    ? "linkPilla mx-auto mb-1"
                    : darkMode
                    ? "linkPillDark mx-auto mb-1"
                    : "linkPill mx-auto mb-1"
                }
                style={
                  darkMode
                    ? {
                        backgroundColor:
                          index > 1
                            ? "#50FC95"
                            : index == 1
                            ? "#83A0F7"
                            : "#C7D7F0",
                      }
                    : {}
                }
              >
                {index > 1 ? (
                  <img className="checkmarkimage" src={checkimg}></img>
                ) : (
                  <div
                    className={darkMode ? "nav-create-activityDark pt-1" : ""}
                  >
                    1
                  </div>
                )}
              </Nav.Link>
              <span
                className={
                  index >= 1
                    ? darkMode
                      ? "pilltextDark"
                      : "modelNavText"
                    : darkMode
                    ? "pilltextDark"
                    : "pilltext"
                }
                style={{ color: darkMode ? "#ADC2FF !important" : "" }}
              >
                Agent Detail
              </span>
            </Nav.Item>
            <hr
              className={
                index >= 1
                  ? "horizontal modelhrnegMargActive"
                  : darkMode
                  ? "horizontal modelhrnegMargActive"
                  : "modelhrnegMarg"
              }
              style={darkMode ? { backgroundColor: "#C7D7F0" } : {}}
            />
            <hr
              className={
                index > 1
                  ? "horizontal modelhrnegMargActive"
                  : darkMode
                  ? "horizontal modelhrnegMargActive"
                  : "modelhrnegMarg"
              }
              style={darkMode ? { backgroundColor: "#C7D7F0" } : {}}
            />
            <Nav.Item className="modelNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className={
                  index >= 2
                    ? "linkPilla mx-auto mb-1"
                    : "linkPill mx-auto mb-1"
                }
                style={
                  darkMode
                    ? {
                        backgroundColor:
                          index > 2
                            ? "#50FC95"
                            : index == 2
                            ? "#83A0F7"
                            : "#C7D7F0",
                      }
                    : {}
                }
              >
                {index > 2 ? (
                  <img className="checkmarkimage" src={checkimg}></img>
                ) : (
                  <div
                    className={darkMode ? "nav-create-activityDark pt-1" : ""}
                  >
                    2
                  </div>
                )}
              </Nav.Link>
              <span
                className={
                  index >= 2
                    ? darkMode
                      ? "pilltextDark"
                      : "modelNavText "
                    : darkMode
                    ? "pilltextDark"
                    : "pilltext"
                }
                style={{ color: darkMode ? "#ADC2FF !important" : "" }}
              >
                Model Configuration
              </span>
            </Nav.Item>
            <hr
              className={
                index >= 2
                  ? "horizontal modelhrnegMargActive"
                  : darkMode
                  ? "horizontal modelhrnegMargActive"
                  : "modelhrnegMarg"
              }
              style={darkMode ? { backgroundColor: "#C7D7F0" } : {}}
            />
            <hr
              className={
                index > 2
                  ? "horizontal modelhrnegMargActive"
                  : darkMode
                  ? "horizontal modelhrnegMargActive"
                  : "modelhrnegMarg"
              }
              style={darkMode ? { backgroundColor: "#C7D7F0" } : {}}
            />
            <Nav.Item className="modelNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/"
                className={
                  index >= 3
                    ? "linkPilla mx-auto mb-1"
                    : "linkPill mx-auto mb-1"
                }
                style={
                  darkMode
                    ? {
                        backgroundColor:
                          index > 3
                            ? "#50FC95"
                            : index == 3
                            ? "#83A0F7"
                            : "#C7D7F0",
                      }
                    : {}
                }
              >
                <div className={darkMode ? "nav-create-activityDark pt-1" : ""}>
                  3
                </div>
              </Nav.Link>
              <span
                className={
                  index >= 3
                    ? darkMode
                      ? "pilltextDark"
                      : "modelNavText "
                    : darkMode
                    ? "pilltextDark"
                    : "pilltext"
                }
                style={{ color: darkMode ? "#ADC2FF !important" : "" }}
              >
                Test Agent
              </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default CreateAgentNav;
