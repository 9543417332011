import React, { useState } from "react";
import "./EvaluateAndGraph.css";
import EvaluateAndGraph from "./EvaluateAndGraph";
import Nav from "react-bootstrap/Nav";
import "./CreateAgent.css";
import { Link } from "react-router-dom";
import LoadModal from "../../RecoModule/LoadModal";

import ModelsNav from "./ModelsNav";
function PreviewModel() {
  const [showModal, setShowModal] = useState(true);
  const openModal = () => {
    setShowModal(true)
  }
  // useEffect(()=>{ openModal();},[])
  const closeModal = () => {
    setShowModal(false)
  
  }
  const content = "I will try it on a vanilla LLM"
  return (
    <>
    {/* <LoadModal showModal={showModal} closeModal={closeModal} content={content} submitModal={closeModal} /> */}
      <ModelsNav />
         <div className="container-fluid pt-4 ps-4">
      <div className="row mx-0 mb-3">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-2">Model Orchestrator</h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav
            variant="pills"
            defaultActiveKey="/agentfactory/createAgent"
            className="agentWizard"
          >
            <Nav.Item className="">
              <Nav.Link
                as={Link}
                to="/comparemodels"
                eventKey="/comparemodels"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                1
              </Nav.Link>
              <span className="pilltext">Select Model</span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <Nav.Item className="negMarg">
              <Nav.Link
                as={Link}
                to="/finetuning"
                eventKey="/finetuning"
                // href="/agentfactory/agentDetails"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                2
              </Nav.Link>
              <span className="pilltext">&nbsp;Fine - Tuning&nbsp;</span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <Nav.Item className="negMarg">
              <Nav.Link
                as={Link}
                to="/rewardmodel"
                eventKey="/rewardmodel"
                // href="/agentfactory/agentDetails"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                3
              </Nav.Link>
              <span className="pilltext">Reward & PPO </span>
            </Nav.Item>
            <hr className="horizontal negMarg" />
            <Nav.Item className="lastPillNegMarg">
              <Nav.Link
                as={Link}
                to="/agentfactory/createAgent/testAgent"
                eventKey="/agentfactory/createAgent/testAgent"
                // href="/agentfactory/createAgent/testAgent"
                className="linkPill mx-auto mb-1 virticle-active"
              >
                4
              </Nav.Link>
              <span className="pilltext">Preview Model </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
      <EvaluateAndGraph isPPO={false} />
    </>
  );
}

export default PreviewModel;
