import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BleuDetailsChart = ({ precisions, lengthRatio, translationLength, referenceLength }) => {
  // Data for the chart
  const data = {
    labels: ['Precision (1-gram)', 'Precision (4-gram)', 'Length Ratio', 'Translation Length', 'Reference Length'],
    datasets: [
      {
        label: 'BLEU Details',
        data: [
          precisions[0],
          precisions[1],
          lengthRatio,
          translationLength,
          referenceLength,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
              color: '#BAC9F1', // Set the color of the legend text
            },
          },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Value: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Metrics',
          color: '#BAC9F1',
        },
        ticks: {
            color: '#BAC9F1', // Set the color of the x-axis ticks
          },
        
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: 'Value',
          color: '#BAC9F1',
        },
        ticks: {
            color: '#BAC9F1', // Set the color of the x-axis ticks
          },
        
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BleuDetailsChart;
