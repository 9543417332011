import React, { useEffect, useState, useContext, useCallback } from "react";
import { DockContext } from "../DockWrapper/DockContext";
import "./AChain.css";
import { Link, useParams, useNavigate, useFetcher } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  Panel,
  Controls,
  MarkerType,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from "reactflow";
import Dagre from '@dagrejs/dagre';
import ActivityNode from "./ActivityNode";
import CreateAgentActivityNode from "./CreateAgentActivityNode";
import "reactflow/dist/style.css";
import ActivityWithAgent from "./ActivityWithAgentNode";
import ChatPopup from "./ChatPopup/ChatPopup";
import axios from "axios";
import colorCode from "../../data/activityNodeDB";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import AlchemProgressBar from "../SharedComponents/AlchemProgressBar/AlchemProgressBar";
import { AuthContext } from "../Auth/AuthContext";
import PropTypes from "prop-types";
import CustomNodeInputField from "./reactFlowCustomNode/CustomNodeAChain";
let title = "Create Knowledge Work";
let steps = ["Knowledge Work", "Activity Chain", "Agent Creation", "Testing"];
const rfStyle = {
  backgroundColor: "#f2f2f2",
};
const rfStyleDark = {
  backgroundColor: "#34385A",
  border: "none",
};
const setheight = { 1: 69, 2: 69, 3: 130 };

const initialNodes = {
  0: [
    {
      id: "1",
      type: "activityWithAgent",
      position: { x: 0, y: 50 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: {
        height: setheight[3],
        backgroundColor: "grey",
        borderWidth: "3px",
        borderColor: "red",
      },
    },
    {
      id: "2",
      type: "activityWithAgent",
      position: { x: 300, y: 0 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[3], backgroundColor: "grey" },
    },
    {
      id: "3",
      type: "activityWithAgent",
      position: { x: 300, y: 200 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[3], backgroundColor: "grey" },
    },
    {
      id: "4",
      type: "activityWithAgent",
      position: { x: 600, y: 0 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[3], backgroundColor: "grey" },
    },
    {
      id: "5",
      type: "activityWithAgent",
      position: { x: 600, y: 400 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[3], backgroundColor: "grey" },
    },
    {
      id: "6",
      type: "activityWithAgent",
      position: { x: 600, y: 200 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[3], backgroundColor: "grey" },
    },
  ],
  2: [
    {
      id: "1",
      type: "createAgentNode",
      position: { x: 0, y: 50 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[2], backgroundColor: "grey" },
    },
    {
      id: "2",
      type: "createAgentNode",
      position: { x: 300, y: 0 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[2], backgroundColor: "grey" },
    },
    {
      id: "3",
      type: "createAgentNode",
      position: { x: 300, y: 200 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[2], backgroundColor: "grey" },
    },
    {
      id: "4",
      type: "createAgentNode",
      position: { x: 600, y: 0 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[2], backgroundColor: "grey" },
    },
    {
      id: "5",
      type: "createAgentNode",
      position: { x: 600, y: 400 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[2], backgroundColor: "grey" },
    },
    {
      id: "6",
      type: "createAgentNode",
      position: { x: 600, y: 200 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[2], backgroundColor: "grey" },
    },
  ],
  1: [
    {
      id: "1",
      type: "activityNode",
      position: { x: 0, y: 50 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[1], backgroundColor: "grey" },
    },
    {
      id: "2",
      type: "activityNode",
      position: { x: 300, y: 0 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[1], backgroundColor: "grey" },
    },
    {
      id: "3",
      type: "activityNode",
      position: { x: 300, y: 200 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[1], backgroundColor: "grey" },
    },
    {
      id: "4",
      type: "activityNode",
      position: { x: 600, y: 0 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[1], backgroundColor: "grey" },
    },
    {
      id: "5",
      type: "activityNode",
      position: { x: 600, y: 400 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[1], backgroundColor: "grey" },
    },
    {
      id: "6",
      type: "activityNode",
      position: { x: 600, y: 200 },
      data: { acticityName: "Wealth Analysis", agentName: "Agent Name" },
      style: { height: setheight[1], backgroundColor: "grey" },
    },
  ],
};


const initialEdges = [
  {
    id: "12",
    source: "1",
    target: "2",
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "23",
    source: "2",
    target: "3",
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "34",
    source: "3",
    target: "4",
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "45",
    source: "4",
    target: "6",
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "56",
    source: "6",
    target: "5",
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
  },
];

const nodeTypes = {
  activityNode: ActivityNode,
  createAgentNode: CreateAgentActivityNode,
  activityWithAgent: ActivityWithAgent,
  customNode: CustomNodeInputField
};


// ---------------------right click feature ---------------------


const getLayoutedElements = (nodes, edges) => {
  var y = 125
  var x = 0
  let sortQ = [];

  var source = 1
  while (sortQ.length != edges.length) {
    console.log("while start")

    edges.map((edge) => {
      if (edge.source == source) {
        if (sortQ.includes(source) == false) {
          sortQ.push(edge.source)
        }

        source = edge.target
        console.log("position if", source, sortQ, edges)
      }

    })

    if (sortQ.includes(source) == false && source != "") {
      sortQ.push(source)
      console.log("position else if", source, sortQ, edges)
    }



    console.log(sortQ.includes("position if", source) == false && source != "", sortQ, edges)
    if (sortQ.length >= edges.length + 1) {
      console.log("breaked")
      break
    }

  }

  console.log("while end")
  console.log("position unsorted edge", edges)

  console.log(" position sorted q", sortQ)


  let updatedNode = []
  sortQ.forEach((i) => {
    nodes.map((node) => {
      if (node.id == i) {
        x = x + 230
        let position = { y: y, x: x }
        console.log("position", x, y, position, node)
        updatedNode.push({ ...node, position: position })
      }

    })
  })

  console.log(updatedNode)
  return { nodes: updatedNode, edges: edges }
}



// ---------------------right click feature ends ---------------------


const AChainSaved = ({ darkMode, handleToggle }) => {

  const {
    isDocked,
    nodes,
    setNodes,
    nodeIndex,
    setNodeIndex,
    chat_history,
    chains,
    setChains
  } = useContext(DockContext);
  const {
    accessToken,
    setAccessToken,
    setRefreshToken,
    reactFlowNodeDetailsAChain
  } = useContext(AuthContext);

  const [progressIndex, setProgressIndex] = useState(nodeIndex < 1 ? 2 : 3);
  const { id } = useParams();
  const navigate = useNavigate();
  // const [chains, setChains] = useState({}); // commented localstorage convertion to usecontext

  const [edges, setEdges] = useState(initialEdges);

  const [isLoading, setIsLoading] = useState(false);

  const [currentData, setCurrentData] = useState({});

  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }
  useEffect(() => {
    setProgressIndex(nodeIndex < 1 ? 2 : 3);
  }, [nodeIndex]);
  useEffect(() => {
    if (nodeIndex < 1) {
      setNodeIndex(1);
    }
  }, []);

  const getActivityChain = () => {

    axios
      .get(
        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/items/` + id,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data, typeof response.data);
        setCurrentData(response.data);
      })

      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
              { headers }
            )
            .then(refreshResponse => {
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true; // User is authenticated after successful token refresh
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false; // Authentication failed if token refresh fails
              }
            })
            .catch(error => {
              if (error.response && error.response.status === 401)
                window.location.reload()
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false; // Authentication failed if token refresh fails
            });
      });


  }


  useEffect(() => {
    getActivityChain()
  }, []);





  const handleProceed = () => {
    navigate("/agentcreation");
  };
  //  for local storga
  // useEffect(() => {
  //   // localStorage.setItem("chains", JSON.stringify(chains));

  //   console.log(chains);
  // }, [chains]);

  useEffect(() => console.log(isLoading), [isLoading]);
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );




  // -------------------------right click feature ------------------------------------

  // Addition of node on right click of any node in the chain 

  const [nodesState, setNodesState, onNodesChangeState] = useNodesState(nodes);
  const [edgesState, setEdgesState, onEdgesChangeState] = useEdgesState(edges);
  const { fitView } = useReactFlow();

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, 0);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));


          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const [customNodeId, setcustomNodeId] = useState(String(nodes.length + 1))
  const [agents, setagents] = useState({})
  const [isNodeSaved, setisNodeSaved] = useState(true)
  useEffect(() => {
    setcustomNodeId(String(nodes.length + 1))
    console.log("custom id", String(nodes.length + 1), nodes)
  }, [nodes])

  useEffect(() => {
    console.log("effect", { nodes: nodes, edges: edges })
    if (reactFlowNodeDetailsAChain.clicked) {
      // var customNodeId = String(nodes.length + 1)
      setisNodeSaved(false)

      let customNode = {
        "id": customNodeId,
        "agent": "",
        "position": {
          "x": 217,
          "y": 105
        },
        "data": {
          "isDark": true,
          "acticityName": "testing",
          "agentName": "",
          "Activity_Classification": "",
          "agent_check": false
        },
        "style": {
          "color": "#333",
          "borderColor": "#666",
          "background": "#f5f5f5",
          "backgroundColor": "transparent",
          "border": "none",
          "width": "17%"
        },
        "width": 100,
        "height": 50,
        "selected": false,
        "positionAbsolute": {
          "x": 150,
          "y": 105
        },
        "dragging": false,
        "type": "customNode"
      }

      let new_nodes = [customNode, ...nodes]

      let updatedNode = []
      var y = 125
      var x = -500

      new_nodes.map((node) => {
        x = x + 300
        let position = { y: y, x: x }
        console.log("position", x, y, position)
        updatedNode.push({ ...node, position: position })

      })
      console.log("new nodes", new_nodes)
      setNodes(updatedNode)

      let next_target = ""
      let new_edges
      let updated_edges
      if (edges.length > 2) {
        console.log("if");
        new_edges = edges.filter((edge) => {
          if (edge.source == reactFlowNodeDetailsAChain.nodeID) {
            next_target = edge.target
            return false
          } else {
            return true
          }
        })
        updated_edges = [...new_edges, {
          id: edges.length + 1,
          source: reactFlowNodeDetailsAChain.nodeID,
          target: customNodeId,
          type: "smoothstep",
          markerEnd: { type: MarkerType.ArrowClosed },
        }, {
          id: edges.length + 2,
          source: customNodeId,
          target: next_target,
          type: "smoothstep",
          markerEnd: { type: MarkerType.ArrowClosed },
        }]
        console.log("new edge", new_edges)


      } else {
        console.log("else")

        new_edges = edges.filter((edge) => {
          if (edge.source == reactFlowNodeDetailsAChain.nodeID) {
            next_target = edge.target
            return false
          } else {
            return true
          }
        })

        updated_edges = [...new_edges, {
          id: `reactflow__edge-${reactFlowNodeDetailsAChain.nodeID}-${customNodeId}`,
          source: reactFlowNodeDetailsAChain.nodeID,
          target: customNodeId,
          type: "smoothstep",
          markerEnd: { type: MarkerType.ArrowClosed },
        }, {
          id: `reactflow__edge-${customNodeId}-${edges[0].target}`,
          source: customNodeId,
          target: next_target,
          type: "smoothstep",
          markerEnd: { type: MarkerType.ArrowClosed },
        }]

        console.log("else", updated_edges)

      }

      console.log("updated edge", updated_edges)






      setEdges(updated_edges.map((edge, i) => {
        return { ...edge, id: `reactflow__edge-${edge.source}-${edge.target}` }
      }))


    } else {
      onLayout("LR")
    }

    try {
      var zoomout = document.querySelector('.react-flow__controls-zoomout')
      zoomout.click();
      console.log("zoom out", zoomout);

    } catch (error) {
      console.error(error)
    }



  }, [reactFlowNodeDetailsAChain.clicked])




  const onLayout = useCallback(
    (direction) => {
      const layouted = getLayoutedElements(nodes, edges, { direction });
      console.log("inside onLayout layouted", layouted);
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);
    },
    [nodes, edges],
  );


  // preventing right click if node is not selected 
  const handleRightClick = (e) => {
    e.preventDefault()
    if (initialNodes[0].length <= 0) {
      alert("add nodes to use right click")
      e.preventDefault()
    }
  }

  const handleChainUpdate = (e) => {
    e.preventDefault()
    let payload = {}
    var updated_agents = { ...agents }
    let chain_payload = JSON.parse(currentData.chain_payload)

    let new_nodes = nodes.map((node) => {
      var format = {
        "id": "1",
        "agent": 250,
        "position": { "x": 150, "y": 105 },
        "data": { "label": "Protocol Synopsis" },
        "style": { "color": "#333", "borderColor": "#666", "background": "#f5f5f5" },
        "width": 100,
        "height": 50,
        "selected": false,
        "positionAbsolute": { "x": 150, "y": 105 },
        "dragging": false
      }
      for (const obj of Object.keys(format)) {
        console.log(obj)

        format[obj] = node[obj]
        if (obj === "data") {
          format[obj] = { "label": node[obj].acticityName }
        }
      }

      return format

    })



    console.log("update nodes\n", "old agents", agents)
    nodes.map((node) => {
      if (Object.keys(agents).includes(node.id) == false) {
        let format = {
          "Activity Name": node["data"]["acticityName"],
          "Description": "", "Role": "", "Instructions": [],
          "Sample_Input": "", "Sample_Output": "",
          "KPIs": { "Data Accuracy": "", "Insight Generation": "", "Impact on Decision-Making": "" },
          "goal": ["INPUT:"], "sequencePosition": node.id,
          "Activity_Classification": node["data"]["Activity_Classification"],
          "model": "AutoML"
        }

        updated_agents[node.id] = format

        console.log("update nodes", "node:", node, "updated agents", updated_agents[node.id])
        setagents({ ...updated_agents })
      }

    })

    console.log("update nodes, agents", updated_agents)
    console.log("update nodes", new_nodes, nodes)
    console.log("update nodes", currentData)

    console.log("update nodes", JSON.parse(currentData.chain_payload))

    chain_payload["nodes"] = new_nodes
    chain_payload["edges"] = edges
    chain_payload["agent"] = updated_agents
    console.log("update nodes", chain_payload)
    payload = { "chain_payload": JSON.stringify(chain_payload) }
    console.log("update nodes", payload)
    axios
      .patch(
        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/items/` + id, payload,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {

        console.log(response.data, typeof response.data);
        // setCurrentData(response.data);

      })
      .then(() => {
        getActivityChain()
        alert("updated")
        setisNodeSaved(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }


  // -------------------------right click feature ends ------------------------------------


  useEffect(() => {
    console.log("chain", currentData);
    // localStorage.setItem("chains", JSON.stringify(currentData));
    setChains(JSON.stringify(currentData))
    // console.log("chain from localStorage", localStorage.getItem("chains"))
    if (currentData) {
      let new_nodes = [];
      let new_edges = [];
      let new_agents = {};

      try {
        new_nodes = JSON.parse(currentData.chain_payload).nodes;
        new_edges = JSON.parse(currentData.chain_payload).edges;
        new_agents = JSON.parse(currentData.chain_payload)["agent"];
        console.log(new_agents);
        console.log(new_nodes);
        console.log(new_edges)
        setagents(new_agents)
      } catch {
        new_nodes = [];
      }

      let s = new_nodes?.map((node) => {
        console.log(node.agent);
        let colorCode = "";
        colorCode = new_agents[node.id]
          ? new_agents[node.id]["Activity_Classification"]
          : "";

        node.style = {
          ...node.style,
          backgroundColor: "transparent",
          border: "none",
          width: "17%",
        };
        if (node.agent !== null) {
          node.data = {
            isDark: darkMode,
            acticityName: new_agents[node.id]["Activity Name"],
            agentName: node.data.label,
            Activity_Classification: colorCode,
            agent_check: true,
          };
          node.type = "activityNode";
        } else {
          node.data = {
            isDark: darkMode,
            acticityName: node.data.label,
            Activity_Classification: colorCode,
          };
          node.type = "activityNode";
        }
        return node;
      });
      setNodes(s);
      let t = new_edges?.map((edge) => {
        edge["type"] = "smoothstep";
        edge["markerEnd"] = { type: MarkerType.ArrowClosed };
        return edge;
      });
      setEdges(t);
    }
  }, [currentData]);

  const onConnect = useCallback(
    (params) => {
      const existingEdge = edges.filter((edge) => edge.source == params.source);
      if (existingEdge.length == 0) {
        setEdges((eds) => {
          let tempEdges = addEdge(params, eds).map((e) => {
            e["type"] = "smoothstep";
            e["markerEnd"] = {
              type: MarkerType.ArrowClosed,
            };
            return e;
          });
          return tempEdges;
        });
      } else {
        alert("Node already connected to another node");
      }
    },

    []
  );
  useEffect(() => {
    let new_nodes = nodes.map((node) => {
      node["data"] = { ...node["data"], isDark: darkMode };
      return node;
    });
    setNodes(new_nodes);
  }, [darkMode]);
  let subtitle = `Knowledge Work: ${currentData["name"]}`;



  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        {/* <ModelsNav darkMode={darkMode} handleToggle={handleToggle}/> */}
        <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
        <div className="row mx-0 h-100">
          <div
            className={
              !isDocked ? "col col-11 pl-0 pt-0 h-100" : " col col-11 pr-0 pl-0 pt-0"
            }
          >
            <div className="row g-0 mx-0 p-0 h-100 " style={{ width: "110%" }}>
              <AlchemProgressBar
                title={title}
                subtitle={subtitle}
                steps={steps}
                index={progressIndex}
              ></AlchemProgressBar>
              <nav className={"navbar navbar-expand-lg p-0 pl-3 ml-3 mt-4"}>
                <div className={"container-fluid "}>
                  {/* <span className={`me-4 ms-2 ${darkMode ? 'text-white' : 'text-dark'} `} onClick={()=>console.log('k')}>
                                    Knowledge Work
                                </span>
                                <div class="line me-3"></div>
                                <span className={"navbar-brand " + styles.screenTitle}>
                                    {currentData['name']}
                                </span>
                                <div className={styles.i}>i</div>
                                {} */}
                  <span
                    className={`me-4 ms-2 ${darkMode ? "text-white" : "text-dark"
                      } `}
                    onClick={() => console.log("k")}
                    style={
                      darkMode
                        ? {
                          textAlign: "left",
                          font: "normal normal normal 17px/21px Calibri",
                          letterSpacing: "0.55px",
                          color: "#E5EAF1",
                          textTransform: "capitalize",
                          opacity: 1,
                        }
                        : {}
                    }
                  >
                    Activity Chain
                  </span>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarNavAltMarkup"
                  >
                    <div
                      className="navbar-nav ms-auto w-100 d-flex justify-content-end"
                      style={{ paddingRight: "30px" }}
                    >
                      <Link
                        className="btn btn-primary cancelBtnDark"
                        to={"/activityList"}
                      >
                        Close
                      </Link>
                      <Link
                        className={
                          darkMode
                            ? "btn btn-primary cancelBtnDark"
                            : "btn btn-primary submitBtnDark"
                        }
                        onClick={handleChainUpdate}
                      >
                        Save
                      </Link>
                      <div className="line me-3"></div>

                      {nodeIndex < 1 ? (
                        <buton
                          className="btn btn-primary submitBtnDark"
                          onClick={() => setNodeIndex(1)}
                        >
                          Confirm & Proceed
                        </buton>
                      ) : (
                        <button
                          className="btn btn-primary submitBtnDark"
                          onClick={handleProceed}
                          disabled={!isNodeSaved}
                        >
                          Proceed
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </nav>

              <ReactFlowProvider>
                <div
                  className="pl-6 pt-2 "
                  onContextMenu={handleRightClick}
                  style={
                    darkMode
                      ? {
                        height: "75%",
                        "padding-left": "4%",
                        background:
                          "transparent linear-gradient(116deg, #222E59 0%, #212542 100%) 0% 0% no-repeat padding-box",
                        marginLeft: "-1rem",
                        maxWidth: "110%",
                        width: "98.5%",
                      }
                      : { height: "180%", "padding-left": "4%" }
                  }
                >
                  <ReactFlow
                    nodes={initialNodes[0]}
                    edges={initialEdges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    fitView
                    style={darkMode ? rfStyleDark : rfStyle}
                    className="react-flow__edge"
                  >
                    {!nodes ? (
                      <Panel>
                        <div className="pannelText">
                          <i>
                            Activity list will be generated from Genius Partner{" "}
                          </i>
                        </div>
                      </Panel>
                    ) : (
                      <></>
                    )}
                    <Panel position="top-right">
                      <div className="pannelText ">
                        <div className="d-flex align-items-center mr-6">
                          {Object.keys(colorCode).map(
                            (classification, index) => {
                              return (
                                <>
                                  <div
                                    className="mx-2 pannel-legend-color "
                                    style={{
                                      backgroundColor:
                                        colorCode[classification],
                                    }}
                                  ></div>
                                  {classification}
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div style={{ width: "32vw" }}></div>
                    </Panel>

                    <Controls
                      id="flow-cont"
                      position="top-right"
                      showZoom={true}
                      showFitView={true}
                      showInteractive={false}
                      className={`${darkMode ? "zoomTransformDark mt-3" : "zoomTransform"
                        }`}
                      style={{
                        height: "fit-content",
                        width: "fit-content",
                        display: "flex",
                        backgroundColor: darkMode ? "#212542" : "white",
                        borderRadius: "0.4rem",
                      }}
                    ></Controls>
                  </ReactFlow>
                </div>
              </ReactFlowProvider>
            </div>
          </div>
          {!isDocked && (
            <div className="">
              <ChatPopup
                setChains={setChains}
                setEdges={setEdges}
                setNodes={setNodes}
                setIsLoading={setIsLoading}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="">
            <div className="spinner-border text-light"></div>
            <p className=" text-white" style={{ marginLeft: "-40px" }}>
              Generating Your Flow...
            </p>
          </div>
        </div>
      )}
    </>
  );
};
AChainSaved.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default AChainSaved;
