import React from "react";
import { useDropzone } from "react-dropzone";
import uploadImg from "../../../assets/img/draganddrop.svg"

export default function UploadDragZone() {

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  });

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <section
      className="container dropzone-wrapper d-flex justify-content-center align-items-center flex-column"
      style={{  width: "200px", height: "200px" }}
    >
       

      <div {...getRootProps({className: 'dropzone'})} className="d-flex justify-content-center align-items-center flex-column">
        <input {...getInputProps()} />
        <img  src={uploadImg} alt="icon" srcset="" />

        <p className="text-primary mt-2">Drag and drop</p>
        <p >or</p>
        <button type="button" className="btn  clnEditBtn" onClick={open}>
          Browse
        </button>
      </div>
    </section>
  );
}
