import React from 'react';
import '../widget.css'; // Import your CSS file

const agentWidget = ({ agent, isVisible, onClose }) => {
  const widgetClass = `agent-widget ${isVisible ? 'visible' : ''}`;

  function changeDate(dd){
    var inputDate = new Date(dd);

    // Extract day, month, and year components
    var day = inputDate.getUTCDate();
    var month = inputDate.getUTCMonth() + 1; // Months are zero-based
    var year = inputDate.getUTCFullYear();

    // Format the date components as dd/mm/yyyy
    var formattedDate = padWithZero(day) + '/' + padWithZero(month) + '/' + year;

    // Function to pad a number with zero if it's a single digit
    function padWithZero(number) {
        return number < 10 ? '0' + number : number;
    }

    return formattedDate
  }
  return (
    <>
    <div className="overlay"> </div>
    <div className={widgetClass} id="agent-widget">
     
      <div className="widget-header">
        <h5>
         {agent.chain_name}
          <span id="close-widget" className="material-symbols-outlined" onClick={onClose}>
            close
          </span>
        </h5>
        {/* <p>
          <small>Purpose</small>{agent.agent.agent_description}
        </p>
        <div className="card-date">
          <div className="date-created">
            <p>
              <small>Created On</small> {changeDate(agent.agent.timestamp_created)}
            </p>
          </div>
          <div className="date-updated">
            <p>
              <small>Updated On</small> {changeDate(agent.agent.timestamp_created)}
            </p>
          </div>
        </div> */}
      </div>
         {/*
      <div className="widget-body">
        <div className="widget-tab">
          <ul className="nav nav-pills agent-tab" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="policy-tab"
                data-toggle="pill"
                href="#policy"
                role="tab"
                aria-controls="policy"
                aria-selected="true"
              >
                Policy
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="constraints-tab"
                data-toggle="pill"
                href="#constraints"
                role="tab"
                aria-controls="constraints"
                aria-selected="false"
              >
                Constraints
              </a>
            </li>
          </ul>
          <div className="tab-content widget-tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="policy" role="tabpanel" aria-labelledby="policy-tab">
              <div className="model-container create-model-container">
                <div className="model-table create-model-table table-responsive">
                  <table className="table table-hover m-0">
                    <thead>
                      <tr>
                        <th>Clause Id</th>
                        <th>Clause Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Policy 1</td>
                        <td>
                         Policy of Toxicity Agent
                        </td>
                      </tr>
                    
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="constraints" role="tabpanel" aria-labelledby="constraints-tab">
              <div className="model-container create-model-container">
                <div className="model-table create-model-table table-responsive">
                  <table className="table table-hover m-0">
                    <thead>
                      <tr>
                        <th>Clause Id</th>
                        <th>Clause Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Policy Clause 2.1</td>
                        <td>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at urna lectus.
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>*/}
    </div>
    </>
  );
};

export default agentWidget;
