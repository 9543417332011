import React, { useEffect, useState } from "react";
import "../card.css";
import axios from "axios";
import avatarImg from "../../../../../../assets/img/avtar.png";
import { Link } from "react-router-dom";

function BasicDetails({
  competency_data,
  setcompetency_data,
  setisedit,
  isedit,
  setModal
}) {
  const [competencyDetails, setCompetencyDetails] = useState({
    competencyName: competency_data.competency_name,
    tagLine: competency_data.tag_line,
    competency: competency_data.competency_type,
    description1: competency_data.description
  });

  const [competencyList, setcompetencyList] = useState();
  const handleInputChange = e => {
    const { name, value } = e.target;
    setCompetencyDetails({ ...competencyDetails, [name]: value });
    console.log(competencyDetails);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/competencies/`)
      .then(res => {
        setcompetencyList(res.data.competencies);
        console.log(res.data.competencies);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    var obj = {
      competency_name: competencyDetails.competencyName,
      tag_line: competencyDetails.tagLine,
      competency_type: competencyDetails.competency,
      description: competencyDetails.description1
    };

    var data = {
      ...competency_data,
      ...obj
    };
    console.log(data, "basic");

    setcompetency_data({
      ...competency_data,
      ...obj
    });
    var edit = [...isedit];
    edit[0] = true;
    setisedit([...isedit]);
  }, [competencyDetails]);

  return (
    <div className="cardOuter">
      <div className="cardInner">
        <div className="cardHeading">
          <h2 className="headingText">Basic Details</h2>
        </div>
        <div className="d-flex justify-content-between w-100">
          <img id="compId" src={avatarImg} alt="Icon" className="icon" />
          <button className="btn-uploadIcon" onClick={() => setModal(true)}>
            Upload Knowledge Work Icon
          </button>
        </div>

        <label htmlFor="competencyName">Knowledge Work Name</label>
        <input
          type="text"
          id="competencyName"
          name="competencyName"
          value={competencyDetails.competencyName || ""}
          onChange={e => {
            setCompetencyDetails({
              ...competencyDetails,
              competencyName: e.target.value
            });
          }}
        />

        <label htmlFor="tagLine">Tag Line</label>
        <input
          type="text"
          id="tagLine"
          name="tagLine"
          value={competencyDetails.tagLine || ""}
          onChange={e => {
            setCompetencyDetails({
              ...competencyDetails,
              tagLine: e.target.value
            });
          }}
        />

        <label htmlFor="competency">Knowledge Work</label>
        <select
          id="competency"
          name="competency"
          // value={competencyDetails.competency || ""}
          onClick={e => {
            setCompetencyDetails({
              ...competencyDetails,
              competency: e.target.value
            });
          }}
        >
          <option value="Digital Stylist">Digital Stylist</option>
          {competencyList?.map(competency => {
            return (
              <option value={competency.competency_name}>
                {competency.competency_name}
              </option>
            );
          })}
        </select>

        <label htmlFor="description1">Description</label>
        <textarea
          id="description1"
          name="description1"
          value={competencyDetails.description1 || ""}
          onChange={handleInputChange}
        ></textarea>
      </div>
    </div>
  );
}

export default BasicDetails;
