import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import "./ModelHomeScreen.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import searchIcon from "../../../assets/img/searchIcon.png";
import { IoIosArrowDown } from "react-icons/io";

const ActivityListFilters = ({ darkMode }) => {
  return (
    <Navbar expand="lg" className={darkMode ? "ModelNavbarContainer darkBackground" : "ModelNavbarContainer whiteBg"}>
      <Container
        fluid
        style={{
          border: darkMode ? "none" : "1px solid #DFE1E5",
          padding: "24px 40px",
          borderTopLeftRadius: "4px",
          borderTopRadiusRadius: "4px"
        }}
      >
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-start">
          <Nav
            className=" my-2 my-lg-0 px-1"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <span
              className="my-auto pe-2 text-capitalize dropdown-label-dark "
              style={{ color: darkMode ? "#E5EAF1" : "" }}
            >
              Domain
            </span>
            <NavDropdown 
              className={
                darkMode
                  ? "btn text-capitalize form-select modelSelectDropdownDark dropdown-placeholder-dark dropdown-box-dark"
                  : " btn text-capitalize form-select modelSelectDropdown dropdown-placeholder-dark dropdown-box-dark"
              }
              style={{ width: "auto;" }}
              title="All"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="#action3">Banking</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Life Science
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
              Insurance
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href=""></Nav.Link>
              <Nav.Link href="#" disabled>
                Link
              </Nav.Link> */}
          </Nav>
          <Nav
            className=" my-2 px-1 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <span
              className="my-auto pe-2 text-capitalize dropdown-label-dark "
              style={{ textWrap: "nowrap", color: darkMode ? "#E5EAF1" : "" }}
            >
              Sub-Domain
            </span>
            <NavDropdown
              className={
                darkMode
                  ? "btn text-capitalize form-select modelSelectDropdownDark dropdown-placeholder-dark dropdown-box-dark"
                  : " btn text-capitalize form-select modelSelectDropdown dropdown-placeholder-dark dropdown-box-dark"
              }
              style={{ width: "auto;", color: darkMode ? "#fff" : "" }}
              title="All"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="#action3">Wealth</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Drug</NavDropdown.Item>
              <NavDropdown.Item href="#action5">
              Policy
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href=""></Nav.Link>
              <Nav.Link href="#" disabled>
                Link
              </Nav.Link> */}
          </Nav>
       

          {/* <InputGroup className="searchWidth alchemSearchGroup">
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <Button
              variant="outline-secondary"
              id="searchButton"
              className="searchGroupBtn"
            >
              <img
                className="img-fluid"
                loading="lazy"
                src={searchIcon}
                alt=""
              />
            </Button>
          </InputGroup> */}
          {/* <Link to="/agentFactory/createAgent" className="submitBtn">
              {" "}
              + Create
            </Link> */}
        </Navbar.Collapse>
        <div class="navbar-nav ms-auto px-0">
          <InputGroup
            className={
              darkMode
                ? "searchWidth alchemSearchGroupDark"
                : "searchWidth alchemSearchGroup"
            }
          >
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <Button
              variant="outline-secondary"
              id="searchButton"
              className="searchGroupBtn"
            >
              <img
                className="img-fluid"
                loading="lazy"
                src={searchIcon}
                alt=""
              />
            </Button>
          </InputGroup>
          {/* <a class="btn btn-primary submitBtn" href="#" role="button">
            Compare Models
          </a> */}
        </div>
      </Container>
    </Navbar>
  );
};

export default ActivityListFilters;
