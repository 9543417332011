import React, {useState, useEffect, useRef} from "react";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Card, Nav } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import axios from "axios";

const TrainingModelPredictive = ({ darkMode, handleToggle }) => {
    let location = useLocation();
    let path = location.pathname;
    let { id } = useParams();
    const {knowledgeWorkDetails, selectedItems,knowledgeWorkName,hyperparameters } = location.state || {};
    const selectedModelIds = selectedItems.recommendedModels || [];

    const [currentSection, setCurrentSection] = useState(0);
    const [currentStep, setCurrentStep] = useState([]);
    const [currentModelId, setCurrentModelId] = useState(1);
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);

    
      

  const sections = [
    {
      name: "Predictive model Training",
      steps: [
        "Upload Model",
        
      ],
    },
    
  ];
  useEffect(() => {
    if (currentStep.length === 0) {
      setCurrentStep(new Array(selectedModelIds.length).fill(0));
    }
  }, [selectedModelIds]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file.name);
    setFileName(file.name)
  };

  const handleSave = async () => {
    const currentModel = selectedModelIds[0]; // Replace with your logic to select the correct model
    const modelName = currentModel ? currentModel.name : 'defaultModelName';
    console.log(currentModel.model_name);
    try {
        // Call the API using axios
        const response = await axios.post('http://alchemist.eastus.cloudapp.azure.com:3005/model/base_model', {
            "model_name": currentModel.model_name,
            "model_type": "predictive",
            "model_path": fileName,
            "model_license": "string",
            "model_description": "string"
          },
          
        {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        alert('Model Saved Successfully!')
      } catch (error) {
        console.error('API call error:', error);
        // Handle the error if needed
      }
  }

const handleUpload = () => {
    if (selectedFile) {
        setLoading(true);

        // Parse CSV file
       

        // Prepare FormData
        const formData = new FormData();
        formData.append("file", selectedFile);

        // API URL with filename and description
        const apiUrl = `http://alchemist.eastus.cloudapp.azure.com:8300/uploadMojo`;

        axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    
                    
                },
            })
            .then((response) => {
                console.log("File uploaded successfully:", response.data);
                setUploadComplete(true)
               
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    } else {
        console.log("Please select a file to upload.");
    }
  }

  const handleGoToH2o = () => {
    const url = "http://alchemist.eastus.cloudapp.azure.com:54321/flow/index.html";
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer'; // For security
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <div>
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
      <div className="container-fluid py-4 px-4">
        <p className="text-light fw-bold">Model Training</p>
        <p style={{ color: "#ADC2FF", marginTop: "-10px" }}>
          Knowledge Work: {knowledgeWorkDetails.knowledge_work.name}
        </p>
      </div>

      <div
        className="d-flex justify-content-end align-items-center"
        style={{ background: "#34385A" }}
      >
        <Nav className="d-flex" style={{ marginBottom: "7px" }}>
          <button className="btn btn-sm btn-link mr-2">
            <Link
              to={`/mo/summary/new/${id}`}
              style={{ color: "#ADC2FF", textDecoration: "underline" }}
            >
              Summary
            </Link>
          </button>
          <button
            className="btn btn-sm mr-2"
            style={{ background: "#D7E5FF", color: "#012060" }}
          >
            Close
          </button>
          <button
            className="btn btn-sm mr-2"
            style={{ background: "#D7E5FF", color: "#012060" }}
            
          >
            Back
          </button>
          <button
            className="btn btn-sm mr-2"
            style={{ background: "#D7E5FF", color: "#012060" }}
            onClick={handleSave}
          >
            Save
          </button>
        
        </Nav>
      </div>

      <div
        className="container-fluid "
        style={{
          background: "#222E59",
          height: "100vh",
          display: "flex",
          padding: "30px",
        }}
      >
        <div
          className="row flex-grow-1 m-0"
          style={{ height: "100%", overflow: "hidden" }}
        >
          <div
            className="col-md-2 p-2"
            style={{
              background: "#42466C",
              overflowY: "auto",
              height: "93vh",
              borderRadius: "4px 0px 0px 4px",
            }}
          >
            <h5 style={{ fontSize: "16px" }} className="text-light ml-4">
              Models ({selectedModelIds.length})
            </h5>
            <div className="accordion" id="accordionExample">
              {selectedModelIds.map((model, modelIndex) => (
                <div
                  className="accordion-item"
                  key={modelIndex}
                  style={{ border: "none" }}
                >
                  <h2 className="accordion-header" id={`heading${model.id}`}>
                    <button
                      className={`accordion-button ${
                        modelIndex === 0 ? "show" : "collapsed"
                      }`}
                      type="button"
                      style={{
                        background: "#313557",
                        color: "#fff",
                        fontSize: "14px", // Adjusted font size
                        padding: "8px 16px",

                        // Adjusted padding for a smaller button
                      }}
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${model.id}`}
                      aria-expanded={modelIndex === 0 ? "true" : "false"}
                      aria-controls={`collapse${model.id}`}
                    >
                      <span style={{width:'80%'}}> {model.model_name}</span>
                     
                    </button>
                  </h2>
                  <div
                    id={`collapse${model.id}`}
                    className={`accordion-collapse collapse ${
                      modelIndex === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`heading${model.id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="accordion-body"
                      style={{ background: "#42466C", padding: "10px" }}
                    >
                      <div
                        className="accordion"
                        id={`accordionExample${model.id}`}
                      >
                        {sections.map((section, sectionIndex) => (
                          <div
                            className="accordion-item"
                            content=""
                            key={sectionIndex}
                            style={{ border: "none" }}
                          >
                            <h2
                              className="accordion-header"
                              id={`heading${model.id}${sectionIndex}`}
                            >
                              <button
                                className={`accordion-button rounded-0 ${
                                  sectionIndex === currentSection
                                    ? ""
                                    : "collapsed"
                                }`}
                                type="button"
                                style={{
                                  background:
                                    sectionIndex === currentSection
                                      ? "#313557"
                                      : "#42466C",
                                  color: "#fff",
                                  fontSize: "14px", // Adjusted font size
                                  padding: "8px 16px", // Adjusted padding for a smaller button
                                }}
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${model.id}${sectionIndex}`}
                                aria-expanded={
                                  sectionIndex === currentSection
                                    ? "true"
                                    : "false"
                                }
                                aria-controls={`collapse${model.id}${sectionIndex}`}
                              >
                                {section.name}
                              </button>
                            </h2>
                            <div
                              id={`collapse${model.id}${sectionIndex}`}
                              className={`accordion-collapse collapse ${
                                sectionIndex === currentSection ? "show" : ""
                              }`}
                              aria-labelledby={`heading${model.id}${sectionIndex}`}
                              data-bs-parent={`#accordionExample${model.id}`}
                            >
                              <div
                                className="accordion-body"
                                style={{
                                  background: "#42466C",
                                  padding: "10px",
                                }}
                              >
                                <div
                                  className="progress"
                                  style={{
                                    height: "8px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width: `${
                                        ((currentStep[sectionIndex] + 1) /
                                          sections[sectionIndex].steps.length) *
                                        100
                                      }%`,
                                      background: "#0FC948",
                                    }}
                                    aria-valuenow={
                                      (currentStep[sectionIndex] + 1) *
                                      (100 /
                                        sections[sectionIndex].steps.length)
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <ul className="list-group">
                                  {section.steps.map((step, stepIndex) => {
                                    const isCompleted =
                                      stepIndex < currentStep[sectionIndex];
                                    const isCurrent =
                                      stepIndex === currentStep[sectionIndex];

                                    return (
                                      <li
                                        key={stepIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: "4px",
                                          fontSize: "14px", // Adjusted font size for better readability
                                          padding: "8px",
                                          borderRadius: "4px",
                                          border: "none",
                                          backgroundColor: isCurrent
                                            ? "#313557"
                                            : "transparent",
                                          color:
                                            isCurrent || isCompleted
                                              ? "#fff"
                                              : "#ccc",
                                        }}
                                        className={`list-group-item ${
                                          isCurrent ? "active" : ""
                                        }`}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                            backgroundColor: isCompleted
                                              ? "#0FC948"
                                              : "#4D5284",
                                            color: "#fff",
                                            marginRight: "12px",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            flexShrink: 0,
                                          }}
                                        >
                                          {isCompleted ? "✓" : stepIndex + 1}
                                        </div>
                                        <div
                                          style={{
                                            flex: 1,
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          {step}
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-md-6 chatBackgroundImage"
            style={{
              // Ensure the container has a minimum height
              background: "#E8EDFA", // Centers the image in the container

              height: "100vh",
              opacity: "100",
              overflowY: "auto",
            }}
          >
            <div
              className="d-flex flex-column overflow-hidden"
              style={{ minHeight: "90vh" }}
            >
              <div
                className="flex-grow-1 d-flex flex-column overflow-auto chatBackgroundImage"
                style={{
                  backgroundSize: "cover", // Ensures the image covers the container
                  backgroundPosition: "center", // Centers the image in the container
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                  backgroundAttachment: "fixed", // Keeps the background fixed during scrolling
                  position: "", // Keeps the center column fixed
                  top: "0",
                }}

                // Optional: Set background for visibility
              >
                {currentStep[0] === 0 && (
                  <div className="d-flex flex-column justify-content-center p-4">
                    <div
                      className="p-2 m-2 d-flex rounded"
                      style={{ background: "#FFFFFF" }}
                    >
                      <p className="text-dark">
                        No training data at hand? No problem! CoachLLM can
                        generate the data you need according to your
                        specifications
                        <br />
                        If you have training data ready for upload, use the
                        option on the right side to get started.{" "}
                      </p>
                    </div>
                    <button className=" mt-2 btn btn-sm d-flex text-light align-items-center justify-content-center" style={{background: '#003BEB'}} onClick={handleGoToH2o}>
                    <span className="px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-bot"
                      >
                        <path d="M12 8V4H8" />
                        <rect width="16" height="12" x="4" y="8" rx="2" />
                        <path d="M2 14h2" />
                        <path d="M20 14h2" />
                        <path d="M15 13v2" />
                        <path d="M9 13v2" />
                      </svg>
                    </span>{" "}
                    Open H2O
                  </button>
                  </div>
                )}
               
              </div>
           
            </div>
          </div>

          {currentStep[0] === 0 && (
           <div className="col-md-4 p-2"  style={{ background: "#1f2340", overflowY:'auto' }}>
           
           <Card
             className={darkMode ? "UploadCompDark p-3" : "UploadComp p-3"}style={{height:'85%'}}
           >
             <Card.Body
               className={
                 darkMode
                   ? "uploadCompOuter d-flex align-items-center justify-content-center"
                   : "UploadComp d-flex align-items-center justify-content-center"
               }
               style={{ color: darkMode ? "" : "white" }}
             >
              
                 <div className="text-center">
                   <div
                     className={
                       (darkMode ? "image-upload-dark" : "image-upload") +
                       " text-center d-flex flex-column align-items-center "
                     }
                   >
                     <input
                       type="file"
                       // id="fileUpload"
                       onChange={handleFileChange}
                       id="file-input"
                     />
                     <FiUpload
                       style={{
                         fontSize: "30px",
                         color: darkMode ? "#E5EAF1" : "#0056b3",
                       }}
                     />
                     {/* </label> */}
                     <div
                       style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                       className={
                         darkMode
                           ? "darg-n-drop-dark pb-4 pt-2"
                           : "darg-n-drop pb-5 fw-medium"
                       }
                     >
                       Drag and Drop
                     </div>
                     <div
                       className={
                         darkMode
                           ? "uploadCompOrDark mb-4"
                           : "uploadCompOr mb-5"
                       }
                     >
                       OR
                     </div>
                     <div
                       className="taskList"
                       style={{
                         fontSize: "14px",
                         color: darkMode ? "" : "rgb(0, 86, 179)",
                       }}
                     >
                       Choose from Repository
                     </div>
                     <label htmlFor="file-input" className="my-2">
                       <a className="cancelBtnDark  mx-0">
                         Choose Model
                       </a>
                     </label>
                     {selectedFile && (
                       <div
                         style={{ color: darkMode ? "#E5EAF1" : "#0056b3" }}
                       >
                         {" "}
                         {selectedFile.name}{" "}
                       </div>
                     )}
                   </div>

                   <Button
                     onClick={handleUpload}
                     disabled={loading || uploadComplete}
                     // className="submitBtn mt-2"
                     className={`mt-2 mr-0 ${
                       selectedFile ? "" : "disabled"
                     } ${darkMode ? "submitBtnDark" : "submitBtnDark"}`}
                   >
                     {loading ? "Uploading..." : uploadComplete ? "Uploaded!": "Upload"}
                   </Button>
                   {loading && <div className="loader">Loading...</div>}
                 </div>
           
               {/* Display CSV Data */}
              
             </Card.Body>
           </Card>
         </div>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default TrainingModelPredictive;
