import React from "react";
import AlchemTableNav from "./AlchemTableNav";

const AlchemTableNavShow = () => {
  const filters = [
    {
      label: "Base Model",
      options: ["All", "Mistral-7b", "Falcon"],
      selectedOption: "All",
    },
    {
      label: "Base Model 2",
      options: ["All", "Mistral-7b", "Falcon"],
      selectedOption: "All Models",
    },
  ];

  return (
    <div>
      <AlchemTableNav filters={filters} showFilters={true} showSearchbar={true} darkMode={true} navbarBrand="Compare Models"/>
    </div>
  );
};

export default AlchemTableNavShow;