import React, { useContext, useEffect, useState } from 'react'
import chevronSmeDown from '../../../assets/img/chevron_sme_down.svg'

export default function Accordion({ data }) {
    const [active, setActive] = useState(false)

    return (
        <>
            <div className='d-flex flex-column' style={{ background: `${active ? '#313557 0% 0% no-repeat padding-box' : '#212542 0% 0% no-repeat padding-box'}`, border: '1px solid #313557' }}>
                {active ?
                    <div className='d-flex flex-row justify-content-between px-2 py-2' >
                        <div className='d-flex flex-column'>
                            <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>Model Name</div>
                            <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data?.name || 'NOT SPECIFIED'}</div>
                        </div>
                        <div className=' d-flex my-auto flex-row'><i className='fa fa-trash-can pe-2 my-auto'></i><div className='ps-2 my-auto' onClick={() => { setActive(false) }}><img src={chevronSmeDown} style={{ transform: 'rotate(180deg)' }}></img></div></div>
                    </div>
                    :
                    <div className='d-flex flex-row justify-content-between px-2 py-2' >
                        <div className='d-flex flex-column'>
                            <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>{data?.description || 'NOT SPECIFIED'}</div>
                            <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data?.name || 'NOT SPECIFIED'}</div>
                        </div>
                        <div className='d-flex my-auto flex-row'><i className='fa fa-trash-can pe-2 my-auto'></i><div className='ps-2 my-auto' onClick={() => { setActive(true) }}><img src={chevronSmeDown}></img></div></div>
                    </div>
                }
                {
                    active ?
                        <div className='d-flex flex-column ps-2'>
                            <div className='d-flex flex-row pt-2'>
                                <div className='d-flex flex-column w-50'>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>Base Model</div>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data?.base_model || 'NOT SPECIFIED'}</div>
                                </div>
                                <div className='d-flex flex-column w-50'>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>Date Updated</div>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data?.date_updated || 'NOT SPECIFIED'}</div>
                                </div>
                            </div>
                            <div className='d-flex flex-row pt-2'>
                                <div className='d-flex flex-column w-50'>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>Domain</div>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data?.domain || 'NOT SPECIFIED'}</div>
                                </div>
                                <div className='d-flex flex-column w-50'>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>Task</div>
                                    <div className='d-flex flex-column w-100'>
                                    {data?.task? data.task.map((task,idx)=>(<div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }} className='my-1'>{`${idx+1}. ${task.description}` || 'NOT SPECIFIED'}</div>)):<>No Tasks</>}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-row pt-2 pb-2'>
                                <div className='d-flex flex-column w-100'>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#E5EAF1' }}>Model Description</div>
                                    <div style={{ font: 'normal normal normal 14px/17px Calibri', letterSpacing: '0px', color: '#BAC9F1' }}>{data?.description || 'NOT SPECIFIED'}</div>
                                </div>
                            </div>
                        </div> :
                        <></>
                }
            </div>
        </>
    )
}