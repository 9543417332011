import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DockContext } from '../DockWrapper/DockContext';
import { AuthContext } from '../Auth/AuthContext';
import "./reactflow.css"
import ReactFlow, {
    Panel,
    Controls,
    MarkerType,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    MiniMap,
} from "reactflow";
import "reactflow/dist/style.css";
import colorCode from '../../data/activityNodeDB';
import ActivityNode from '../AChain/ActivityNode';
import CreateAgentActivityNode from '../AChain/CreateAgentActivityNode';
import ActivityWithAgent from '../AChain/ActivityWithAgentNode';
import SubTitleNode from './SubTitleNode';
import TitleNode from './TitleNode';

const rfStyle = {
    backgroundColor: "#f2f2f2",
};
const rfStyleDark = {
    backgroundColor: "#34385A",
    border: "none",
};

const nodeTypes = {
    titleNode: TitleNode,
    subtitleNode: SubTitleNode,
};

// color selection for node in mindmap projection 
function nodeColor(node) {
    switch (node.type) {
        case 'titleNode':
            return '#2a6f97';
        case 'subtitleNode':
            return '#2c7da0';
        default:
            return '#ff0072';
    }
}


export default function MindMapReactFlow() {
    const { darkMode } = useContext(AuthContext)
    const {
        chains,
        isAchainDock,
        chat_history,
        mind_map_history,
        setmind_map_history,

    } = useContext(DockContext);

    const [nodes, setNodes, onNodesChange] = useNodesState(mind_map_history?.nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(mind_map_history?.edges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );


    useEffect(() => {
        setNodes(mind_map_history?.nodes)
        setEdges(mind_map_history?.edges)
        console.log("changed", mind_map_history)
    }, [mind_map_history?.nodes, mind_map_history?.edges, mind_map_history])

    return (
        <ReactFlowProvider>
            <div
                className={`${isAchainDock ? "70%" : "100%"}`}
                style={
                    darkMode
                        ? {
                            height: `${isAchainDock ? "70%" : "100%"}`,
                            background: '#212542 0% 0% no-repeat padding-box',
                            maxWidth: "100%",
                            width: "100%",
                        }
                        : { height: "50%",background: '#212542 0% 0% no-repeat padding-box' }
                }
            >
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    fitView
                    style={darkMode ? rfStyleDark : rfStyle}
                    className="react-flow__edge"
                    edgeTypes={"SmoothStepEdge"}
                    defaultViewport={{ x: 0, y: 0, zoom: 1 }}

                >
                    {!nodes ? (
                        <Panel>
                            <div className="pannelText">
                                <i>
                                    Activity list will be generated from Genius Partner{" "}
                                </i>
                            </div>
                        </Panel>
                    ) : (
                        <></>
                    )}

                    <Controls
                        id="flow-cont"
                        position="top-right"
                        showZoom={true}
                        showFitView={true}
                        showInteractive={false}
                        className={`${darkMode ? "zoomTransformDark mt-3" : "zoomTransform"
                            }`}
                        style={{
                            height: "fit-content",
                            width: "fit-content",
                            display: "flex",
                            backgroundColor: darkMode ? "#34385A" : "white",
                            borderRadius: "0.4rem",
                        }}
                    ></Controls>
                    <MiniMap className='mindMapReactFlow-miniMap' nodeStrokeWidth={3} nodeColor={nodeColor} pannable={true}  style={{ backgroundColor: "#34385a" }} offsetScale={10} nodeStrokeColor={"yellow"} />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    )
}

