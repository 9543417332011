// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_line__H7SlA {
  background-color: #c7d7f0;
  color: #c7d7f0;
  height: 2rem;
  width: 1.5px;
  margin: 1.05rem;
}

.NavBar_link-text__mAl8u {
  font-size: .8rem;
  color: #e5eaf1;
}

.NavBar_fixed-height-15__RnamW {
  min-height: 15%;
  max-height: 15%;
  height: 15%;
}`, "",{"version":3,"sources":["webpack://./src/components/AgentModule/CreateAgent/navBar/NavBar.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb","sourcesContent":[".line {\n  background-color: #c7d7f0;\n  color: #c7d7f0;\n  height: 2rem;\n  width: 1.5px;\n  margin: 1.05rem;\n}\n\n.link-text {\n  font-size: .8rem;\n  color: #e5eaf1;\n}\n\n.fixed-height-15 {\n  min-height: 15%;\n  max-height: 15%;\n  height: 15%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `NavBar_line__H7SlA`,
	"link-text": `NavBar_link-text__mAl8u`,
	"fixed-height-15": `NavBar_fixed-height-15__RnamW`
};
export default ___CSS_LOADER_EXPORT___;
