import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Tabs,
  Tab,
  Nav,
  InputGroup,
  Form
} from "react-bootstrap";
import { AuthContext } from "../Auth/AuthContext";

export default function EvaluateModel(finetunedURL,{isPPO}) {
  const {darkMode} = useContext(AuthContext)
  
  finetunedURL = "http://172.208.104.191:3000/v1/chat/completions";
  let baseModel = "http://172.208.104.191:8000/openai/chat/completions";

  if (isPPO) {
    finetunedURL = "http://172.208.104.191:3000/v1/chat/completions";
    baseModel = "http://172.208.104.191:8000/openai/chat/completions";
  }

  const [newMessage, setNewMessage] = useState("");
  const [messageIdCounter, setMessageIdCounter] = useState(1);
  const [response1, setResponse1] = useState([]);
  const [response2, setResponse2] = useState([]);

  const [temperature, setTemperature] = useState(0);
  const [topP, setTopP] = useState(0.43);
  const [topK, setTopK] = useState(1);
  const [tokensToOutput, setTokensToOutput] = useState(256);

  const handleSendMessage = e => {
    e.preventDefault();

    if (newMessage.trim() === "") {
      return; // Do not send empty messages
    }
    const payload = {
      model: "devGPT35",
      messages: [
        {
          role: "system",
          content: "Assistant is a large language model trained by OpenAI."
        },
        {
          role: "user",
          content: newMessage
        }
      ],
      max_tokens: tokensToOutput,
      temperature: temperature,
      top_p: topP,
      n: 1,
      stream: false
    };

    const fine_payload = {
      messages: [
        {
          role: "system",
          content: "You are a helpful assistant."
        },
        {
          role: "user",
          content: "Question: " + newMessage + " Answer:"
        }
      ],
      model: "mistralai--Mistral-7B-v0.1",
      max_tokens: tokensToOutput,
      temperature: parseInt(temperature) + 0.1,
      top_p: topP,
      n: 1,
      stream: false
    };

    sendMessageToResponse(setResponse1, finetunedURL, fine_payload);
    sendMessageToResponse(setResponse2, baseModel, payload); //Running GPT 3.5
  };

  const sendMessageToResponse = async (setResponse, apiUrl, payload) => {
    setMessageIdCounter(prevCounter => prevCounter + 1);

    // Create the user message
    const userMessage = {
      id: messageIdCounter,
      text: `<strong>User:</strong> ${newMessage}`,
      isBot: false
    };

    setResponse(prevMessages => [...prevMessages, userMessage]);
    setMessageIdCounter(prevCounter => prevCounter + 1);

    // Simulate a bot response after a delay
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
          // Add any additional headers if needed
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const responseBody = await response.json();

      let content = responseBody.choices[0].message.content; // Extract the "response" property

      console.log(content);

      if (content.includes("im_start")) {
        console.log("adasdasd");
        var parts = content.split("<|im_end|>");
        if (parts.length > 1) {
          // Extract the text after the first occurrence of "system"
          var extractedText = parts[0].trim();
          console.log(extractedText);
          content = extractedText.replace("<|im_start|>system", "");
        } else {
          console.log("No 'system' found in the content.");
        }
      }
      const botMessage = {
        id: messageIdCounter + 1,
        text: `<strong>Model:</strong> ${content}`, // Assuming the API returns a plain text response
        isBot: true
      };

      setResponse(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error fetching bot response:", error);

      // If the API call fails, add an error message to the response
      const errorMessage = {
        id: messageIdCounter + 1,
        text: `<strong>Model:</strong> Error fetching response from the API`,
        isBot: true
      };

      setResponse(prevMessages => [...prevMessages, errorMessage]);
    }

    setNewMessage("");
  };

  const handleSliderChange = (sliderName, value) => {
    switch (sliderName) {
      case "temperature":
        setTemperature(value);
        break;
      case "topP":
        setTopP(value);
        break;
      case "topK":
        setTopK(value);
        break;
      case "tokensToOutput":
        setTokensToOutput(value);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = e => {
    // Check if the pressed key is Enter (key code 13)
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of the Enter key
      handleSendMessage();
    }
  };

  const ChatCard = ({ messages, title}) => {
    return (
      <Col xs={4} className="ps-0">
        <Card className="whiteBg agentCard"style={{border: 'none'}} id="Card1">
          <Card.Header className="agentDate agentCardHead"  style={{ background: darkMode ? '#243063' : '', color: darkMode ? '#BAC9F1' : '', border: 'none'}}> <span className="ms-4 fw-medium"> {title} </span></Card.Header>
          <Card.Body style={{background: darkMode ? '#E1E7F9' : '', overflowY: "auto" }}>
            {messages ? (
              messages.map((message, index) => (
                <div
                  key={index}
                  className={message.isBot ? "botBubble" : "userBubble"}
                >
                  <Card.Text
                    className="cardText"
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  />
                </div>
              ))
            ) : (
              <Card.Text className="cardText">Loading...</Card.Text>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <div id="content">
      <Row className="mt-3 mb-4">
        {/* <Col xs={3}>
                <Card>

                </Card>
            </Col> */}
        <Col xs={20} className="px-0">
          <Row></Row>

          <Row className="mt-2">
            <ChatCard
              messages={response1}
              darkMode = {darkMode}
              title={
                isPPO
                  // ? localStorage.getItem("basemodelname")
                  ? "Mistral-Wealth-Advisor"
                  : "FineTuned Model Name"
              }
            />
            <ChatCard
              className="pe-0 text-start"
              messages={response2}
              // title={isPPO ? localStorage.getItem("name") : "BenchMark Model"}
              title="GPT-4"
            />
            <Col xs={4} className="pe-0">
              <Card className="whiteBg agentCard" style={{ border: 'none'}}  id="Card3">
                <Card.Header className=" agentDate agentCardHead fw-medium" style={{background: darkMode ? '#212542' : '', color: darkMode ? '#BAC9F1' : '',}}>
                  Parameters
                </Card.Header>
                <div className="horizontallineParameters"></div>
                <Card.Body style={{background: darkMode ? '#212542' : '', color: darkMode ? '#BAC9F1' : '', borderRadius: '0'}}>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="temperature" className="form-label">
                        Temperature: {temperature}
                      </Form.Label>
                      <div className="parametersRange">
                      <Form.Range
                        id="temperature"
                        name="temperature"
                        value={temperature}
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={e =>
                          handleSliderChange("temperature", e.target.value)
                        }
                      />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="topP" className="form-label">
                        Top P: {topP}
                      </Form.Label>
                      <Form.Range
                        id="topP"
                        name="topP"
                        value={topP}
                        onChange={e =>
                          handleSliderChange("topP", e.target.value)
                        }
                        min={0}
                        max={1}
                        step={0.01}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="topK" className="form-label">
                        Top K: {topK}
                      </Form.Label>
                      <Form.Range
                        id="topK"
                        name="topK"
                        value={topK}
                        onChange={e =>
                          handleSliderChange("topK", e.target.value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="tokensToOutput"
                        className="form-label"
                      >
                        Tokens to Output: {tokensToOutput}
                      </Form.Label>
                      <Form.Range
                        id="tokensToOutput"
                        name="tokensToOutput"
                        value={tokensToOutput}
                        onChange={e =>
                          handleSliderChange("tokensToOutput", e.target.value)
                        }
                        min={10}
                        max={2000}
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            {/* <Col xs={8} className="ps-0 input-form"> */}
            <Col xs={8} className="px-0" style={{background: darkMode ? '#34385A' : ''}}>
              <InputGroup className="mt-2 evalModelTextArea" style={{background: darkMode ? '#34385A' : ''}}>
                <Form.Control
                  as="textarea"
                  style={{
                    
                    borderRadius: "4px",
                    border: 'none',
                    marginRight: "10px",
                    background: darkMode ? '#34385A' : '', color: darkMode ? '#BAC9F1' : ''
                  }}
                  placeholder="Enter your prompt and press Enter"
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                />
                <Button
                  type="submit"
                  className="submitBtnDark mt-3"
                  id="button-addon2"
                  onClick={handleSendMessage}
                >
                  Submit
                </Button>
              </InputGroup>
            </Col>
            {/* </Col> */}
          </Row>
          <Row className="">
            <Col xs="6" className="ps-0 mt-3">
              <Button className="cancelBtnDark me-3">Regenerate</Button>
              <Button className="cancelBtnDark me-3">Clear History</Button>
              <Button className="cancelBtnDark">Download</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
