// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AchainDock_generative__wR-5Q{
    background-color: blueviolet;
}

`, "",{"version":3,"sources":["webpack://./src/components/AChain/AchainDock/AchainDock.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC","sourcesContent":[".generative{\n    background-color: blueviolet;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generative": `AchainDock_generative__wR-5Q`
};
export default ___CSS_LOADER_EXPORT___;
