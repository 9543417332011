// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/Mask Group 52@2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Summary_max-size-100__R8OQ1 {
    width: 100%;
    height: 100%;
}

.Summary_text-size-24px__0Y9\\+F {
    font-size: 24px;
}

.Summary_bg-dark-blue__Za1w- {
    background-color: #212542;
}

.Summary_bg-light-grey__gBrOB {
    background-color: #afaaaa;
}


.Summary_table-bg-light-cyan__WtAhg {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
 
}


.Summary_d-table-head__wtZgC {
    display: table-header-group;
    /* color: #fff; */
    height: 8%;
    background-color: #212529;
    border-color: #32383e;
    vertical-align: bottom;
    /* border-bottom: 2px solid #dee2e6; */
    padding: .5rem .5rem;
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.Summary_table-container__-nksi {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: stretch;
}


.Summary_d-table-row__R9IJe {
    display: table-row;
}

.Summary_d-table-cell__ubenk {
    display: table-cell;
}


.Summary_line__cSNGP {
    background-color: #c7d7f0;
    color: #c7d7f0;
    width: 1px;
    height: 30px;
  }
  .Summary_selected__mVy2R {
    background: #2e3b5a !important; /* Change to your desired selected background color */
    color: #fff !important; /* Change to your desired text color */
  }`, "",{"version":3,"sources":["webpack://./src/components/NewModelOrchestrator/summary/Summary.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,yDAAmE;;AAEvE;;;AAGA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,UAAU;IACV,yBAAyB;IACzB,qBAAqB;IACrB,sBAAsB;IACtB,sCAAsC;IACtC,oBAAoB;IACpB,2CAA2C;IAC3C,2GAA2G;AAC/G;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;IACvB,oBAAoB;AACxB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,YAAY;EACd;EACA;IACE,8BAA8B,EAAE,qDAAqD;IACrF,sBAAsB,EAAE,sCAAsC;EAChE","sourcesContent":[".max-size-100 {\n    width: 100%;\n    height: 100%;\n}\n\n.text-size-24px {\n    font-size: 24px;\n}\n\n.bg-dark-blue {\n    background-color: #212542;\n}\n\n.bg-light-grey {\n    background-color: #afaaaa;\n}\n\n\n.table-bg-light-cyan {\n    background-image: url('../../../assets/img/Mask\\ Group\\ 52@2x.png');\n \n}\n\n\n.d-table-head {\n    display: table-header-group;\n    /* color: #fff; */\n    height: 8%;\n    background-color: #212529;\n    border-color: #32383e;\n    vertical-align: bottom;\n    /* border-bottom: 2px solid #dee2e6; */\n    padding: .5rem .5rem;\n    border-bottom-width: var(--bs-border-width);\n    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));\n}\n\n.table-container {\n    display: flex;\n    flex-direction: row;\n    align-content: center;\n    justify-content: center;\n    align-items: stretch;\n}\n\n\n.d-table-row {\n    display: table-row;\n}\n\n.d-table-cell {\n    display: table-cell;\n}\n\n\n.line {\n    background-color: #c7d7f0;\n    color: #c7d7f0;\n    width: 1px;\n    height: 30px;\n  }\n  .selected {\n    background: #2e3b5a !important; /* Change to your desired selected background color */\n    color: #fff !important; /* Change to your desired text color */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"max-size-100": `Summary_max-size-100__R8OQ1`,
	"text-size-24px": `Summary_text-size-24px__0Y9+F`,
	"bg-dark-blue": `Summary_bg-dark-blue__Za1w-`,
	"bg-light-grey": `Summary_bg-light-grey__gBrOB`,
	"table-bg-light-cyan": `Summary_table-bg-light-cyan__WtAhg`,
	"d-table-head": `Summary_d-table-head__wtZgC`,
	"table-container": `Summary_table-container__-nksi`,
	"d-table-row": `Summary_d-table-row__R9IJe`,
	"d-table-cell": `Summary_d-table-cell__ubenk`,
	"line": `Summary_line__cSNGP`,
	"selected": `Summary_selected__mVy2R`
};
export default ___CSS_LOADER_EXPORT___;
