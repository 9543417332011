import { Handle, Position } from "reactflow";
import "./AChain.css";
import colorCode from "../../data/activityNodeDB";
import { Col, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { DockContext } from "../DockWrapper/DockContext";
import union from "../../assets/img/Union.svg";
import checkDark from "../../assets/img/darkCheckImg.svg";
import PropTypes from "prop-types";
import { AuthContext } from "../Auth/AuthContext";

const ActivityNode = ({ data, isConnectable, id }) => {
  const { nodeIndex, nodes, setNodeIndex, setIsActivityDeatilsDock, setActivityDetailsData } = useContext(DockContext);
  const { setreactFlowNodeDetailsAChain } = useContext(AuthContext);
  console.log("dataisHere: ", data);
  console.log("id: ", id, data.acticityName);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (nodeIndex > nodes.length || nodeIndex < 1) {
      setSelected(true);
    } else if (`${nodeIndex}` === id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [nodeIndex, nodes]);

  const handleContextMenu = (e) => {
    console.log("clicker", e.target)
    let obj = {
      node: e.target,
      x: e.pageX,
      y: e.pageY,
      clicked: true,
      nodeID: id,
      data: data
    }
    console.log("clicked", obj);
    setreactFlowNodeDetailsAChain(obj)
  }
  const handleNodeClick = () => {
    setNodeIndex(parseInt(id));
    setIsActivityDeatilsDock(true)
    setActivityDetailsData(data)

  }

  return (
    <button
      className={` h-100 w-100 bg-transparent`}
      onClick={handleNodeClick}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{
          backgroundColor: "#ADC2FF",
          border: "none",
          height: "10px",
          width: "10px",
        }}
      />
      <div
        className="h-100 w-100 "
        style={{
          background: data.isDark
            ? selected
              ? "#222949"
              : "#4C5075"
            : "#FFFFFF",
          borderRadius: "5px",
          border: `1px solid ${colorCode[data.Activity_Classification] ? colorCode[data.Activity_Classification] : "#2a6f97"}`,
        }}
        onContextMenu={handleContextMenu}
      >
        <Row className="py-2 px-4">
          <Col className="g-0  col-md-3 p-2">
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: data.isDark ? "white" : "#ECECEC",
                borderRadius: "0.3rem",
              }}
            ></div>
          </Col>
          <Col className="g-0 col-md-9">
            <Row className="g-0 d-flex justify-content-between">
              <Col
                className="g-0 col-md-8 activity-node-title "
                style={data.isDark ? {} : { color: "#AEAEBB" }}
              >
                Activity
              </Col>
              <Col className="g-0 col-md-1  ">

                <div
                  className="activityIButton "
                  style={{
                    color: "#012060",
                    background: "#adc2ff 0% 0% no-repeat padding-box",
                    height: "14px",
                    width: "14px",
                    fontSize: "12px",
                  }}
                >
                  i
                </div>
              </Col>
            </Row>
            <Row
              className="g-0 "
              style={
                data.isDark
                  ? { color: "#ffffff", wordBreak: "break-word" }
                  : { color: "#000000", wordBreak: "break-word" }
              }
            >
              {data.acticityName}
            </Row>
            <Row className="g-0 " style={{ height: "10px" }}>

            </Row>
            {data.Activity_Classification && (
              <>
                <Row
                  className="g-0 activity-node-title "
                  style={data.isDark ? {} : { color: "#AEAEBB" }}
                >
                  {" "}
                  Type{" "}
                </Row>
                <Row
                  className="g-0 "
                  style={
                    data.isDark ? { color: "#ffffff" } : { color: "#000000" }
                  }
                >
                  {" "}
                  {data.Activity_Classification}
                </Row>
              </>
            )}
            <Row className="g-0 " style={{ height: "10px" }}>
              {" "}
            </Row>
            {nodeIndex > 0 && !data.agentName && id !== `${nodeIndex}` && (
              <Row
                className="g-0 activity-node-title "
                style={
                  data.isDark
                    ? { whiteSpace: "nowrap", fontSize: "12px" }
                    : {
                      color: "#AEAEBB",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }
                }
              >
                {" "}
                Agent Creation Pending{" "}
              </Row>
            )}
            {id === `${nodeIndex}` && !data.agentName && (
              <Row
                className="g-0 activity-node-title "
                style={
                  data.isDark
                    ? { whiteSpace: "nowrap", fontSize: "12px" }
                    : {
                      color: "#AEAEBB",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                    }
                }
              >
                {" "}
                Agent Creation In Progress{" "}
              </Row>
            )}
          </Col>
        </Row>
      </div>
      {data.agent_check && (
        <>
          <div className="activity-node-link-chain mx-auto">
            {" "}
            <img src={union} alt="" />
          </div>
          <div
            className="h-100 w-100 "
            style={{
              background: data.isDark
                ? selected
                  ? "#222949"
                  : "#4C5075"
                : "#FFFFFF",
              borderRadius: "0.7rem",
              border: "2px solid " + colorCode[data.Activity_Classification],
            }}
          >
            <Row className="py-2 px-4">
              <Col className="g-0  col-md-3 p-2">
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: data.isDark ? "white" : "#ECECEC",
                    borderRadius: "0.3rem",
                  }}
                ></div>
              </Col>
              <Col className="g-0 col-md-9">
                <Row className="g-0 d-flex justify-content-between">
                  <Col
                    className="g-0 col-md-3 activity-node-title "
                    style={data.isDark ? {} : { color: "#AEAEBB" }}
                  >
                    Agent
                  </Col>
                  <Col
                    className="g-0 col-md-6 activity-node-title "
                    style={data.isDark ? {} : { color: "#AEAEBB" }}
                  >
                    <span className="activity-node-check-green">
                      <img src={checkDark} alt="" />
                    </span>
                  </Col>
                  <Col className="g-0 col-md-1  ">
                    {" "}
                    <div
                      className="activityIButton "
                      style={{
                        color: "#012060",
                        background: "#adc2ff 0% 0% no-repeat padding-box",
                        height: "14px",
                        width: "14px",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      i
                    </div>{" "}
                  </Col>
                </Row>
                <Row
                  className="g-0 "
                  style={
                    data.isDark
                      ? { color: "#ffffff", wordBreak: "break-word" }
                      : { color: "#000000", wordBreak: "break-word" }
                  }
                >
                  {" "}
                  {data.agentName}
                </Row>
                <Row className="g-0 " style={{ height: "10px" }}>
                  {" "}
                </Row>
                <Row>
                  <Col className="col-md-8">
                    <button
                      className="btn btn-primary cancelBtnDark"
                      onClick={(e) => {
                        e.stopPropagation();
                        setNodeIndex(0);
                      }}
                    >
                      Edit
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      )}
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={{
          backgroundColor: "#ADC2FF",
          border: "none",
          height: "10px",
          width: "10px",
        }}
      />
    </button>
  );
};
ActivityNode.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default ActivityNode;