import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import "../../Table/CustomTable.css";
function AlchemTable({ TableColumn, TableData, updateData, darkMode, handleRowSelection, subTableColumn,value, type, options }) {
  const handleChange = (e) => {
    // Your logic to handle the radio button change event
    console.log(e.target.value);
  };
  return (

    <div className="custum-table-wrapper" style={{ background: darkMode ? '#42466C' : '', border: darkMode ? 'none' : '' }}>
      <Table className={darkMode ? 'custom-tableDark' : 'custom-table'}>
        <thead className="">
          <tr>
            <th></th>
            {TableColumn?.map((e, index) => (
              <th key={index} scope="col">
                {e}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {console.log(TableData)}
          {TableData?.map((e, index) => {
            return (
              <>
                <tr key={index}>
                  <td style={{ borderBottom: 'none' }}></td>
                  {TableColumn?.map((cloumn, innerIndex) => {
                    if (e[cloumn] === undefined) {
                      return <td key={innerIndex} style={{ borderBottom: '1px solid #2F499E' }}>{""}</td>;
                    } else if (e[cloumn]?.type === "label")
                      return <td key={innerIndex} style={{ borderBottom: '1px solid #2F499E' }}>{e[cloumn]?.value || ""}</td>;
                    else if (e[cloumn]?.type === "icon")
                      return <td
                        key={innerIndex}
                        data-toggle="collapse"
                        data-target={"#rowId" + index}
                        className="accordion-toggle p-3 pt-4"
                        aria-expanded="false"
                        aria-controls={"#rowId" + index}
                        style={{ borderBottom: '1px solid #2F499E' }}
                      >
                        {e[cloumn]?.value || ""}
                      </td>;
                    else if (e[cloumn]?.type === "radio")
                      return (
                        <td key={innerIndex} style={{ borderBottom: '1px solid #2F499E' }}>
                           <div>
      {e[cloumn]?.options.map((option, index) => (
        <div key={index}>
          <input
            type="radio"
            onChange={handleRowSelection}
            checked={console.log( option.value === value)}
            id={option.label + "_" + index}
            name={"radio_" + index}
            value={option.value}
          />
          <label htmlFor={option.label + "_" + index}>{option.label}</label>
        </div>
      ))}
    </div>
                        </td>
                      );
                    else if (e[cloumn]?.type === "select")
                      return (
                        <td key={innerIndex} style={{ borderBottom: '1px solid #2F499E' }}>
                          <Form.Select
                            className="form-select custom-table-select-text"
                            style={{ background: darkMode ? '#34385A' : '', color: darkMode ? '#BAC9F1' : '', border: '1px solid #2F499E' }}
                            value={e[cloumn]?.value}
                            onChange={SelectResponse =>
                              updateData(
                                index,
                                cloumn,
                                "text",
                                SelectResponse.target.value,
                                e[TableColumn[0]]?.value
                              )
                            }
                            aria-label="Default select example"
                            size="sm"
                          >
                            <option>Open this select menu</option>
                            {e[cloumn]?.options?.map(option => (
                              <option value={option?.value}>
                                {option?.label}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      );
                    else if (e[cloumn]?.type === "text")
                      return (
                        <td key={innerIndex} style={{ borderBottom: '1px solid #2F499E' }}>
                          <input
                            className="form-control"
                            style={{ background: darkMode ? '#34385A' : '', color: darkMode ? '#BAC9F1' : '', border: '1px solid #2F499E' }}
                            onChange={textResponse =>
                              updateData(
                                index,
                                cloumn,
                                "text",
                                textResponse.target.value,
                                e[TableColumn[0]]?.value
                              )
                            }
                            type="text"
                            value={e[cloumn]?.value || ""}
                          ></input>
                        </td>
                      );
                    else if (e[cloumn]?.type === "number")
                      return (
                        <td key={innerIndex} style={{ borderBottom: '1px solid #2F499E' }}>
                          <input
                            className="form-control"
                            style={{ background: darkMode ? '#34385A' : '', color: darkMode ? '#BAC9F1' : '', border: '1px solid #2F499E' }}
                            type="number"
                            min={e[cloumn]?.min || undefined}
                            onChange={numberResponse =>
                              updateData(
                                index,
                                cloumn,
                                "text",
                                numberResponse.target.value,
                                e[TableColumn[0]]?.value
                              )
                            }
                            max={e[cloumn]?.max || undefined}
                            value={e[cloumn]?.value || 0}
                          ></input>
                        </td>
                      );
                    else
                      return <td key={innerIndex}>{e[cloumn]?.value || ""}</td>;
                  })}
                  <td style={{ borderBottom: 'none' }}></td>
                </tr>
                <tr>
                  <td colspan="10" className="hiddenRow border-bottom" >
                    <div
                      id={"rowId" + index}
                      // className={i === selectedIndex ? "" : "collapse"}
                      className="collapse"
                      aria-labelledby={"rowId" + index}
                      data-bs-parent="#accordion"

                    >
                      <div className=" container-fluid px-5 subTable_outer" style={{ background: darkMode ? '#34385A' : '', borderRadius: '4px' }}>
                        <table className={`alchemTable ${darkMode ? 'alchemSubTableDark' : 'alchemSubTable'} subTable mb-0`}>
                          <thead>
                            <tr>

                              {subTableColumn?.map((e, index) => (
                                <th key={index} scope="col">
                                  {e}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>

                            {subTableColumn.map((element, index) => (
                              <td key={index} style={{ borderBottom: '1px solid #2F499E' }}>{e[element]?.value || ""}</td>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default AlchemTable;
