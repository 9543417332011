import React, { useContext, useEffect, useState } from 'react'
import { DockContext } from '../../../DockWrapper/DockContext';
import styles from './AgentModelMapping.module.css'
import AgentDock from '../dock/AgentDock';
import AgentSideBar from '../sideBar/AgentSideBar';
import ChatInterface from '../../../AChain/FunctionalChatInterface/ChatInterface';
import NavBar from '../navBar/NavBar';
import NormalChat from '../chat/NormalChat';


const dummyData = [
    {
        model_id: 1,
        name: "model name",
        complete: true
    },
    {
        model_id: 2,
        name: "model name 2",
        complete: true
    },
    {
        model_id: 3,
        name: "model name 3",
        complete: true
    },
    {
        model_id: 4,
        name: "model name 4",
        complete: true
    }
]


export default function AgentModelMapping() {

    const {
        isAgentDocked, setIsAgentDocked,
        selectedKnowledgeWork,
        activity_master_json
    } = useContext(DockContext);


    const [data, setData] = useState([])

    const [title, setTitle] = useState("ModelSelection")

    const populateDataForSideBar = () => {
        let items = []
        activity_master_json?.knowledge_work?.activities?.map((item, i) => {
            items.push({
                name: item.name,
                isCompleted: false,
                isActive: i === 0
            })
        })
        console.log(items)
        setData(items)
    }

    useEffect(() => {

        populateDataForSideBar()

    }, [activity_master_json])

    const ModelMappingNav = [
        { name: "CREATE KNOWLEDGE WORK" },
        { name: `Knowledge Work: ${selectedKnowledgeWork.name}` }
    ]

    return (
        <div className='d-flex flex-column h-100'>
            <NavBar links={ModelMappingNav} isSummary={true} profile={true} />

            <div className='d-flex flex-colomn w-100' style={{ height: "85%" }}>

                <AgentDock title={title} setTitle={setTitle} data={data} setData={setData}>

                    <div className={`order-1 w-25`} style={{ backgroundColor: "#212542" }}  >
                        <AgentSideBar data={data} title={"Activity Chain"} />
                    </div>
                    <div className='flex-grow-1 order-2 w-75' style={{ backgroundColor: "#E1E7F9" }}>
                        <NormalChat />
                    </div>


                </AgentDock>

                {/* <i
                    className="fa-solid fa-circle-chevron-left fs-4 text-primary"
                    style={{ position: "absolute", right: "0", top: "50%" }}
                    onClick={() => { setIsAgentDocked(true) }}
                ></i> */}

            </div>

        </div>

    )
}
