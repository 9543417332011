import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const LoadModal = ({ showModal, closeModal, proceedRewardModel, darkMode }) => {
  const arr = [];

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    radio: "",
  });
  const [radio, setRadio] = useState("");
  const [formData, setFormData] = useState({
    rewardModel: "",
    version1: "",
    ppoModel: "",
    version2: "",
  });

  if (selectedOption === "no") {
    arr.push(radio);
  } else {
    arr.push(radio, formData);
  }

  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setSelectedOption(value);
    setShowDropdown(value === "yes");
    setRadio({
      ...radio,
      [name]: value,
    });
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    console.log("form data: ", arr);
    if (selectedOption === "no") {
      window.location.href = "/comparemodels";
      return;
    }
    if (
      formData.rewardModel.trim() === "" ||
      formData.version1.trim() === "" ||
      formData.version2.trim() === "" ||
      formData.ppoModel === ""
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    console.log("form data: ", arr);
    localStorage.setItem(
      "rewardModelstatus",
      JSON.stringify(arr[0].radioButton)
    );
    localStorage.setItem("rewardModel", JSON.stringify(arr[1].rewardModel));
    localStorage.setItem("version1", JSON.stringify(arr[1].version1));
    localStorage.setItem("ppoModel", JSON.stringify(arr[1].ppoModel));
    localStorage.setItem("version2", JSON.stringify(arr[1].version2));
    closeModal();
    proceedRewardModel();
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={darkMode ? "alchemModalDark" : "alchemModal"}
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className="border-bottom-0"
          style={{ paddingLeft: "32px", paddingRight: "32px" }}
        >
          <Modal.Title className="modalHead " style={{ lineHeight: "25px" }}>
            Do you want to Train the model with Reward and PPO Model ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="switch">
            <input
              type="radio"
              value="yes"
              name="radioButton"
              checked={selectedOption === "yes"}
              onChange={handleDropdownChange}
            />
            <span className="slider round">
              {" "}
              Yes, Select Reward & PPO Model
            </span>
          </label>
          <label className="switch ms-4">
            <input
              type="radio"
              value="no"
              name="radioButton"
              checked={selectedOption === "no"}
              onChange={handleDropdownChange}
            />
            <span className="slider round"> No, Go to Preview Model</span>
          </label>

          {showDropdown && (
            <div className="mt-4">
              <label className="form-label ">
                <span> Reward Model </span>

                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  name="rewardModel"
                  onChange={handleOptionChange}
                >
                  <option selected>Open this select menu</option>
                  <option value="5">opt-350m</option>
                  <option value="6">decapoda-research-llama-7b-hf</option>
                </select>
              </label>

              <label className="form-label mt-3">
                <span> Version </span>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  name="version1"
                  onChange={handleOptionChange}
                >
                  <option selected>Open this select menu</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </label>
              <label className="form-label mt-3">
                <span> PPO Model </span>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  name="ppoModel"
                  onChange={handleOptionChange}
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </label>
              <label className="form-label mt-3">
                <span> Version </span>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  name="version2"
                  onChange={handleOptionChange}
                >
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </label>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer id="footer">
          <button
            className="btn btn-primary cancelBtnDark me-3"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary submitBtnDark me-3"
            onClick={handleSubmit}
          >
            Proceed
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
LoadModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  proceedRewardModel: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};
export default LoadModal;
