// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagBubbles {
  display: flex;
  flex-wrap: wrap;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  min-height: 100px;
  padding: 5% 2%;
  gap: 10px;
}
.bubble {
  background-color: #d5d5d5;
  border-radius: 8px;
  padding:0px 3%;
  text-align: center;
  align-items: center;
  margin: 0px !important;
  width: 112px;
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #111621;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif  ;
  letter-spacing: 0px;
}
.removBubble {
  margin-left: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/AgentChainModule/ModelCompetancy/components/cards/IndustryDetails/IndustryDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,+CAA+C;EAC/C,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,SAAS;AACX;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,+EAA+E;EAC/E,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".tagBubbles {\n  display: flex;\n  flex-wrap: wrap;\n  background: #f8f8f8 0% 0% no-repeat padding-box;\n  border: 1px solid #dfe1e5;\n  border-radius: 4px;\n  min-height: 100px;\n  padding: 5% 2%;\n  gap: 10px;\n}\n.bubble {\n  background-color: #d5d5d5;\n  border-radius: 8px;\n  padding:0px 3%;\n  text-align: center;\n  align-items: center;\n  margin: 0px !important;\n  width: 112px;\n  height: 33px;\n  display: flex;\n  justify-content: space-between;\n  align-content: center;\n  color: #111621;\n  font-size: 14px;\n  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif  ;\n  letter-spacing: 0px;\n}\n.removBubble {\n  margin-left: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
