// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardOuter .upload {
  width: 78px;
  height: 33px;
}

.cardOuter .btn-primary {
  background: transparent linear-gradient(180deg, #3366ff 0%, #012060 100%) 0%
    0% no-repeat padding-box;
  border-radius: 27px;
  opacity: 1;
  color: #fff;
  font-size: 14px/24px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border: none;
}

.cardOuter .upload-div {
  display: flex;
  justify-content: flex-end;
}
.cardOuter .upload-area {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  opacity: 1;
  height: 30px;
}

.uploadFiles tbody tr:first-child td {
  border-top: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/AgentChainModule/ModelCompetancy/components/cards/uploadfiles/uploadFiles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;4BAC0B;EAC1B,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,oBAAoB;EACpB,6EAA6E;EAC7E,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,+CAA+C;EAC/C,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".cardOuter .upload {\n  width: 78px;\n  height: 33px;\n}\n\n.cardOuter .btn-primary {\n  background: transparent linear-gradient(180deg, #3366ff 0%, #012060 100%) 0%\n    0% no-repeat padding-box;\n  border-radius: 27px;\n  opacity: 1;\n  color: #fff;\n  font-size: 14px/24px;\n  font-family: \"Gill Sans\", \"Gill Sans MT\", Calibri, \"Trebuchet MS\", sans-serif;\n  border: none;\n}\n\n.cardOuter .upload-div {\n  display: flex;\n  justify-content: flex-end;\n}\n.cardOuter .upload-area {\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 1px solid #d5d5d5;\n  border-radius: 4px;\n  opacity: 1;\n  height: 30px;\n}\n\n.uploadFiles tbody tr:first-child td {\n  border-top: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
