import "./ModelCompetancy.css";
import BasicDetails from "./components/cards/BasicDetails/BasicDetails";
import IndustryDetails from "./components/cards/IndustryDetails/IndustryDetails";
import UploadFiles from "./components/cards/uploadfiles/UploadFiles";
import { createContext, useEffect, useState } from "react";
import AlchemNav from "../../AgentModule/AlchemNav/AlchemNav";
import ModelCompetancyNav from "./ModelCompetancyNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoadModal from "../../RecoModule/LoadModal";
import LoadModalCompetancy from "./LoadModalCompetancy";
import ModelsNav from "../../ModelOrchestrator/FineTunining/ModelsNav";
import UploadIconModalCompetency from "./UploadIconModalCompetency";
function ModelCompetancy() {
  const [isedit, setisedit] = useState([false, false, false]);

  const location = useLocation();

  const [competency_data, setcompetency_data] = useState({
    ...location.state.competency,
    agent_chain_mapping: "2e249e32-17c6-410f-a64c-766225d05b65"
  });
  const [isUpdate, setIsUpdate] = useState(location.state.isUpdate);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  // useEffect(()=>{ openModal();},[])
  const closeModal = () => {
    setShowModal(false);
  };

  const [bshowModal, bsetShowModal] = useState(false);

  const [fshowModal, fsetShowModal] = useState(false);
  const fopenModal = () => {
    fsetShowModal(true);
  };
  const extra = {
    state: { competency: competency_data, isUpdate: true }
  };
  // useEffect(()=>{ openModal();},[])
  const fcloseModal = () => {
    console.log(competency_data);
    localStorage.setItem("competency", JSON.stringify(competency_data));
    fsetShowModal(false);
    console.log(extra);
    navigate("/competency/createCompetencyChain", extra);
  };
  const handleSave = e => {
    e.preventDefault();
    fopenModal();
  };

  const handleUpdate = e => {
    e.preventDefault();
    navigate("/competency/createCompetencyChain", {
      state: { competency: competency_data, isUpdate: true }
    });
  };
  const bcloseModal = () => {
    bsetShowModal(false);
  };
  useEffect(() => {
    console.log(competency_data);
  }, [competency_data]);
  const content = `Task provided is determined to be highly complex, and hetrogenous in nature.
  Needs not just decomposition of high level tasks. It needs human-like reflection and reasoning for effective task completion. Alchemist recommends
  'Reasoning and Acting' algorithm for actioning the Tasks`;
  return (
    <div className="App model-competancy inheritBg">
      {/* <AlchemNav/> */}
      <ModelsNav />
      <ModelCompetancyNav index={1} />
      <LoadModalCompetancy
        showModal={fshowModal}
        closeModal={fcloseModal}
        content={content}
      />
      <UploadIconModalCompetency
        showModal={bshowModal}
        closeModal={bcloseModal}
        content="conetet"
      />
      <div className="content">
        <div className="content-header">
          <h4>KNOWLEDGE WORK DETAILS</h4>
          <div className="content-btns">
            <Link to="/competency">
              <button className="btn-secoundary close">Close</button>
            </Link>
            {isUpdate ? (
              <button className="btn-primary save" onClick={handleUpdate}>
                Update & Proceed
              </button>
            ) : (
              <button className="btn-primary save" onClick={handleSave}>
                Save & Proceed
              </button>
            )}
          </div>
        </div>
        <div className="cards">
          <BasicDetails
            competency_data={competency_data}
            setcompetency_data={setcompetency_data}
            setisedit={setisedit}
            isedit={isedit}
            setModal={bsetShowModal}
          />
          <IndustryDetails
            competency_data={competency_data}
            setcompetency_data={setcompetency_data}
            setisedit={setisedit}
            isedit={isedit}
          />
          <UploadFiles
            competency_data={competency_data}
            setcompetency_data={setcompetency_data}
            setisedit={setisedit}
            isedit={isedit}
          />
        </div>
      </div>
    </div>
  );
}

export default ModelCompetancy;
