import React, { useContext, useEffect, useState } from "react";
import "../ModelOrchestrator/FineTunining/FineTuning.css";
import CreateAgentNav from "./CreateAgentNav";
import CustomTable from "../Table/CustomTable";
import { Row } from "react-bootstrap";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  TableData,
  TableColumn,
  PPOTableData,
  RewardTableData,
} from "../ModelOrchestrator/FineTunining/DB";
import UploadComp from "../ModelOrchestrator/FineTunining/UploadComp/UploadComp";
import { useNavigate } from "react-router-dom";
import TrainingProgressGenAI from "./ModelCreation/TrainingProgressGenAI";
import checkimg from "../../assets/img/check.png";
import darkCheckImg from "../../assets/img/darkCheckImg.svg";
import axios from "axios";
import RewardModelProgress from "../ModelOrchestrator/RewardModelProgress";
import SelectModelCompetancy from "./ModelCreation/SelectModelCompetency";
import RewardModelPPOProgress from "../ModelOrchestrator/RewardModelPPOProgress";
import RewardTemplate from "../../assets/templates/Reward_Model_data_Template.csv";
import EvaluateAndGraph from "../ModelOrchestrator/FineTunining/EvaluateAndGraph";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import { AuthContext } from "../Auth/AuthContext";
function ModelConfiguration({ darkMode, handleToggle }) {
  const {accessToken} = useContext(AuthContext)
  const [index, setindex] = useState(0);
  const [TableDataLocal, setTableDataLocal] = useState(TableData);
  const [TableColumnLocal, setTableColumnLocal] = useState(TableColumn);
  const [RewardTableDataLocal, setRewardTableDataLocal] =
    useState(RewardTableData);
  let chains_data = localStorage.getItem("chains")
    ? JSON.parse(localStorage.getItem("chains"))
    : { name: "" };
  const [updateDataJson, setupdateDataJson] = useState({});
  const [selectModelFlag, setselectModelFlag] = useState(true);

  const navigate = useNavigate();
  const proceed = () => {
    if (index <= 13) setindex(index + 1);
    console.log(index);
    setShowModal(false);
    setselectModelFlag(false);
  };
  const uploaddone = () => {
    proceed();
  };
  const proceedToRewardModel = () => {
    proceed();
  };
  useEffect(() => {
    console.log("FirsT Load");
    if (index === 5) {
      console.log(TableDataLocal);
      console.log(updateDataJson);
      console.log("I'm fins9hed training");
    }
  }, [index]);
  const FineTuningstart = () => {
    const headers = { "Content-Type": "application/json" };
    console.log(updateDataJson);
    axios
      .post(
        "http://172.208.104.191:5002/start_training",
        JSON.stringify({
          basemodelid: localStorage.getItem("basemodelid"),
          name: localStorage.getItem("name"),
          description: localStorage.getItem("description"),
          usecase: localStorage.getItem("usecase"),
          task: localStorage.getItem("task"),
          userid: 1,
          purpose: localStorage.getItem("purpose"),
          finetuned_model_src: localStorage.getItem("datasetsrc"),
          hyperparamsid: 4,
          dataset_id: localStorage.getItem("datasetid"),
        }),
        { headers }
      )
      .then((res) => {
        console.log(res.data);

        proceed();
      });

    proceed();
  };

  const updateData = (data_index, column, type, value, key) => {
    if (
      type === "text" ||
      type === "number" ||
      type === "select" ||
      type === "radio"
    ) {
      let data = TableDataLocal;
      let tempupdateDate = updateDataJson;
      tempupdateDate[key] = value;
      setupdateDataJson(tempupdateDate);
      data[index - 1][data_index][column]["value"] = value;
      setTableDataLocal([...data]);
    }
  };

  const updateRewardData = (data_index, column, type, value, key) => {
    if (
      type === "text" ||
      type === "number" ||
      type === "select" ||
      type === "radio"
    ) {
      let data = RewardTableDataLocal;
      let tempupdateDate = updateDataJson;
      tempupdateDate[key] = value;
      setupdateDataJson(tempupdateDate);
      data[index - 1][data_index][column]["value"] = value;
      setRewardTableDataLocal([...data]);
    }
  };

  const back = () => {
    if (index >= 1) setindex(index - 1);
    if (index == 1) setselectModelFlag(true);
    console.log(index);
  };

  const savehyper = () => {
    const headers = { 
      "Content-Type": "application/json",
      Authorization: accessToken
    };
    console.log(updateDataJson);
    axios
      .post(
        `${process.env.REACT_APP_MODEL_ORCHESTRATOR_SERVER}/hyperparameters`,
        JSON.stringify({
          hyperparameter: {
            updateDataJson,
          },
        }),
        { headers }
      )
      .then((res) => {
        localStorage.setItem("hyperparameter_id", JSON.stringify(res.data.id));
        console.log(res.data.id);
      });
  };
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const [content, setContent] = useState(
    "Given the specificity of ADRE detection, we recommend hyperparameters that aims for lower training loss and higher accuracy"
  );

  const [recoContent, setRecContent] = useState();
  const newContent = `<strong>Resource Efficiency of PEFT</strong>
  Parameter-Efficient Fine-Tuning (PEFT) delivers resource efficiency in healthcare, particularly in ADRE prediction. PEFT allows for the fine-tuning of large-scale models on specific ADRE datasets without the need for comprehensive model overhauls. This approach significantly reduces computational demands, enabling the deployment of advanced ADRE prediction models in a broader range of healthcare settings. The potential increase in the deployment of these models in hospitals could be as high as 20%, substantially enhancing patient safety.

  <strong>Rapid Adaptability with LoRA</strong>
  Low-Rank Adaptation (LoRA) contributes significantly to model adaptability. By altering only a small segment of the model, such as neural network weights, LoRA enables rapid adaptation to specific ADRE tasks. This feature is vital for swiftly responding to new drugs or emerging ADRE patterns, potentially reducing the time required for model updates and deployment by 15%.

  <strong>Handling Large Datasets through Data and Model Parallelism</strong>
  Data and Model Parallelism addresses the challenge of processing large and complex datasets in ADRE studies. These techniques enable the efficient handling of extensive data on drug interactions and patient records, improving the accuracy of ADRE predictions. A 25% improvement in processing capabilities could translate to a 10% increase in prediction accuracy, significantly aiding in the prevention of adverse drug events.

  <strong>Combined Impact on ADRE Prediction</strong>
  When combined, these advanced techniques offer a comprehensive approach to ADRE prediction. They ensure models are resource-efficient, rapidly adaptable, and capable of effectively handling large datasets. This synergy could lead to a 20-30% reduction in ADRE-related hospitalizations, markedly improving patient outcomes and reducing healthcare costs associated with treating these reactions. The adoption of these advanced compute and learning optimization techniques is a critical step forward in enhancing patient care and safety in the pharmaceutical and healthcare industries.
</pre>`;

  const [isPeft, setisPeft] = useState(false);
  const doPeftReco = () => {
    setContent(
      `Advanced techniques like PEFT, LoRA, and Data/Model Parallelism enhance ADRE prediction in healthcare, reducing hospitalisations by 20-30% through improved efficiency, adaptability, and accuracy in processing large datasets.`
    );
    setShowModal(true);
    setisPeft(true);
    setRecContent(newContent);
  };

  return (
    <>
      <div className="">
        <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
        <div className="finetuning-outer">
          <div className="finetuning-head mb-4">
            <CreateAgentNav
              index={2}
              darkMode={darkMode}
              kwName={chains_data.name}
            />
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 px-0">
                <Row
                  className="mx-0"
                  style={{
                    background: darkMode ? "#34385A" : "",
                    padding: "0px 24px",
                  }}
                >
                  <p
                    style={{
                      color: darkMode ? "#BAC9F1" : "#000",
                      position: "relative",
                      top: "5px",
                      paddingLeft: "0px",
                      display: "flex",
                      width: "auto",
                    }}
                    className="mt-2 "
                  >
                    <span style={{ position: "relative", top: "2px" }}>
                      {" "}
                      Activity 1 of 06: Wealth Analysis
                    </span>{" "}
                    <span style={{ marginLeft: "16px", fontSize: "16px" }}>
                      {" "}
                      |{" "}
                    </span>{" "}
                    <span
                      className="title-agent-details d-flex"
                      style={{
                        color: darkMode ? "#BAC9F1" : "",
                        marginTop: "-13px",
                        fontSize: "14px",
                      }}
                    >
                      Intelligence Type:{" "}
                      <div
                        style={{
                          background: "#ED5CF8",
                          width: "14px",
                          height: "14px",
                          borderRadius: "4px",
                          margin: "5px 8px",
                        }}
                      ></div>{" "}
                      Generative
                    </span>
                  </p>
                </Row>
              </div>
            </div>
          </div>
          <div className="container-fluid pageHeadBg mt-3 px-0">
            <div className="finetuning-body ">
              {index === 5 || index === 9 || index === 13 ? (
                <> </>
              ) : (
                <div
                  className="finetuning-body-left accordion"
                  id="accordionExample"
                >
                  <div className="p-4"></div>
                  <div
                    className={
                      darkMode
                        ? "finetuning-navbar FineTuningDarKNav"
                        : "finetuning-navbar"
                    }
                    style={{
                      background: darkMode ? "#42466C" : "",
                      border: "none",
                    }}
                  >
                    <div
                      className={
                        darkMode && index == 0
                          ? "finetuning-navbar-header text-start calibriBold active"
                          : "finetuning-navbar-header text-start"
                      }
                      style={{ fontSize: "16px", paddingTop: "16px" }}
                    >
                      Select Model{" "}
                      <div
                        className={
                          index > 0 && darkMode
                            ? "rounded-circle circleWidth greenCircle virticle-green"
                            : "d-none"
                        }
                      >
                        <img
                          className="stepCompletion"
                          src={darkCheckImg}
                          style={{ width: "11px" }}
                        ></img>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      style={{ backgroundColor: "inherit", border: "0px" }}
                    >
                      <div
                        className="finetuning-navbar-header text-start accordion-button"
                        style={{
                          fontSize: "16px",
                          fontFamily: "calibri-bold",
                          paddingTop: "10px",
                          color: darkMode ? "#FFFFFF" : "",
                          backgroundColor: darkMode ? "#42466C" : "inherit",
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Supervised Fine-tuning
                        <div
                          className={
                            index > 5 && darkMode
                              ? "rounded-circle circleWidth greenCircle virticle-green"
                              : "d-none"
                          }
                        >
                          <img
                            className="stepCompletion"
                            src={darkCheckImg}
                          ></img>
                        </div>
                      </div>
                      <div
                        className="finetuning-navbar-body accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        id="collapseOne"
                      >
                        <div className="stepper d-flex flex-column mt-1 ml-2 pb-2 accordion-body">
                          <div
                            className={
                              index == 1 ? "d-flex active pt-2" : "d-flex"
                            }
                          >
                            <div className="d-flex flex-column pr-2 align-items-center">
                              <div
                                className={
                                  index >= 1
                                    ? index >= 2
                                      ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                      : "rounded-circle circleWidth virticle-active text-white"
                                    : "rounded-circle circleWidth virticle-not-active text-white"
                                }
                              >
                                {index >= 2 ? (
                                  <img
                                    className="checkmarkimage"
                                    src={darkMode ? darkCheckImg : checkimg}
                                    style={{
                                      width:
                                        index >= 2 && darkMode ? "8px" : "9px",
                                    }}
                                  ></img>
                                ) : (
                                  <>1</>
                                )}
                              </div>
                              <div
                                className={
                                  index >= 2
                                    ? "line virticle-active h-100"
                                    : "line h-100 bg-primary"
                                }
                              ></div>
                            </div>
                            <div>
                              <p
                                className={
                                  index == 1
                                    ? darkMode
                                      ? "leadDark  pb-2 active"
                                      : "lead  pb-3 active"
                                    : darkMode
                                    ? "leadDark  pb-2 "
                                    : "lead  pb-3 "
                                }
                              >
                                Upload Data
                              </p>
                            </div>
                          </div>
                          <div
                            className={index == 2 ? "d-flex active" : "d-flex"}
                          >
                            <div className="d-flex flex-column pr-2 align-items-center">
                              <div
                                className={
                                  darkMode
                                    ? "line virticle-active h-100"
                                    : "d-none"
                                }
                              ></div>
                              <div
                                className={
                                  index >= 2
                                    ? index >= 3
                                      ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                      : "rounded-circle circleWidth virticle-active text-white"
                                    : "rounded-circle circleWidth virticle-not-active text-white"
                                }
                              >
                                {index >= 3 ? (
                                  <img
                                    className="checkmarkimage"
                                    src={darkMode ? darkCheckImg : checkimg}
                                    style={{
                                      width:
                                        index >= 3 && darkMode ? "8px" : "9px",
                                    }}
                                  ></img>
                                ) : (
                                  <>2</>
                                )}
                              </div>
                              <div
                                className={
                                  index >= 3
                                    ? "line virticle-active h-100"
                                    : "line h-100"
                                }
                              ></div>
                            </div>
                            <div>
                              <p
                                className={
                                  index == 2
                                    ? darkMode
                                      ? "leadDark  pb-2 pt-2 active"
                                      : "lead  pb-3 active"
                                    : darkMode
                                    ? "leadDark  pb-2 pt-2 "
                                    : "lead  pb-3 "
                                }
                              >
                                Basic Hyperparameters
                              </p>
                            </div>
                          </div>
                          <div
                            className={index == 3 ? "d-flex active" : "d-flex"}
                          >
                            <div className="d-flex flex-column pr-2 align-items-center">
                              <div
                                className={
                                  darkMode
                                    ? "line virticle-active h-100"
                                    : "d-none"
                                }
                              ></div>
                              <div
                                className={
                                  index >= 3
                                    ? index >= 4
                                      ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                      : "rounded-circle circleWidth virticle-active text-white"
                                    : "rounded-circle circleWidth virticle-not-active text-white"
                                }
                              >
                                {index >= 4 ? (
                                  <img
                                    className="checkmarkimage"
                                    src={darkMode ? darkCheckImg : checkimg}
                                    style={{
                                      width:
                                        index >= 4 && darkMode ? "8px" : "9px",
                                    }}
                                  ></img>
                                ) : (
                                  <>3</>
                                )}
                              </div>
                              <div
                                className={
                                  index >= 4
                                    ? "line virticle-active h-100"
                                    : "line h-100"
                                }
                              ></div>
                            </div>
                            <div>
                              <p
                                className={
                                  index == 3
                                    ? darkMode
                                      ? "leadDark  pb-2 pt-2 active"
                                      : "lead  pb-3 active"
                                    : darkMode
                                    ? "leadDark  pb-2 pt-2"
                                    : "lead  pb-3 "
                                }
                              >
                                Advanced Hyperparameters
                              </p>
                            </div>
                          </div>
                          <div
                            className={index == 4 ? "d-flex active" : "d-flex"}
                          >
                            <div className="d-flex flex-column pr-2 align-items-center">
                              <div
                                className={
                                  darkMode
                                    ? "line virticle-active h-100"
                                    : "d-none"
                                }
                              ></div>
                              <div
                                className={
                                  index >= 4
                                    ? index >= 5
                                      ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                      : "rounded-circle circleWidth virticle-active text-white"
                                    : "rounded-circle circleWidth virticle-not-active text-white"
                                }
                              >
                                {" "}
                                {index >= 5 ? (
                                  <img
                                    className="checkmarkimage"
                                    src={darkMode ? darkCheckImg : checkimg}
                                    style={{
                                      width:
                                        index >= 5 && darkMode ? "8px" : "9px",
                                    }}
                                  ></img>
                                ) : (
                                  <>4</>
                                )}
                              </div>
                              <div
                                className={
                                  index >= 5
                                    ? "line virticle-active h-100"
                                    : "line h-100"
                                }
                              ></div>
                            </div>
                            <div>
                              <p
                                className={
                                  index == 4
                                    ? darkMode
                                      ? "leadDark  pb-2 pt-2 active"
                                      : "lead  pb-3 active"
                                    : darkMode
                                    ? "leadDark  pb-2 pt-2 pt-3 "
                                    : "lead  pb-3"
                                }
                              >
                                PEFT Hyperparameters
                              </p>
                            </div>
                          </div>
                          <div
                            className={index == 5 ? "d-flex active" : "d-flex"}
                          >
                            <div className="d-flex flex-column pr-2 align-items-center">
                              <div
                                className={
                                  darkMode
                                    ? "line virticle-active h-100"
                                    : "d-none"
                                }
                              ></div>
                              <div
                                className={
                                  index >= 5
                                    ? index >= 6
                                      ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                      : "rounded-circle circleWidth virticle-active text-white"
                                    : "rounded-circle circleWidth virticle-not-active text-white"
                                }
                              >
                                {index >= 5 ? (
                                  <img
                                    className="checkmarkimage"
                                    src={darkMode ? darkCheckImg : checkimg}
                                    style={{
                                      width:
                                        index >= 4 && darkMode ? "8px" : "9px",
                                    }}
                                  ></img>
                                ) : (
                                  <>5</>
                                )}
                              </div>
                              {/* <div className="line h-100 d-noneselect"></div> */}
                            </div>
                            <div>
                              <p
                                className={
                                  index == 5
                                    ? darkMode
                                      ? "leadDark  pb-2 pt-2 active"
                                      : "lead  pb-3 active"
                                    : darkMode
                                    ? "leadDark  pb-2 pt-2 "
                                    : "lead  pb-3 "
                                }
                              >
                                Evaluate Model
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index > 5 ? (
                    <>
                      <div
                        className={
                          darkMode
                            ? "finetuning-navbar FineTuningDarKNav"
                            : "finetuning-navbar"
                        }
                        style={{
                          background: darkMode ? "#42466C" : "",
                          border: "none",
                        }}
                      >
                        <div
                          className="accordion-item"
                          style={{ backgroundColor: "inherit", border: "0px" }}
                        >
                          <div
                            className="reward-navbar-header text-start accordion-button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                            style={{
                              flexDirection: "inherit",
                              height: "auto",
                              color: darkMode ? "#FFFFFF" : "",
                              backgroundColor: darkMode ? "#42466C" : "inherit",
                              border: "0px",
                              padding: "16px 20px 16px 24px",
                            }}
                          >
                            Reward Model
                            <div
                              className={
                                index > 8 && darkMode
                                  ? "rounded-circle circleWidth greenCircle virticle-green"
                                  : "d-none"
                              }
                              style={{ marginLeft: "4.3rem" }}
                            >
                              <img
                                className="stepCompletion"
                                src={darkCheckImg}
                                style={{ width: "11px" }}
                              ></img>
                            </div>
                            {/* <span>
                                Reward Model RLHF &nbsp;
                               <span style={{ paddingLeft: "5rem" }}>
                              <FaEdit
                                className="text-primary"
                                size={"1.1rem"}
                              />
                            </span>
                              </span> */}
                          </div>
                          {/* <div className="horizontalline"></div> */}

                          <div
                            className="finetuning-navbar-body accordion-collapse collapse show"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                            id="collapseTwo"
                          >
                            <div class="stepper d-flex flex-column">
                              <div
                                class={
                                  index == 6 ? "d-flex active pt-2" : "d-flex"
                                }
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 6
                                        ? index >= 7
                                          ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                          : "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    {index >= 7 ? (
                                      <img
                                        className="checkmarkimage"
                                        src={darkMode ? darkCheckImg : checkimg}
                                        style={{
                                          width:
                                            index >= 7 && darkMode
                                              ? "8px"
                                              : "9px",
                                        }}
                                      ></img>
                                    ) : (
                                      <>6</>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      index >= 7
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 7
                                        ? darkMode
                                          ? "leadDark  pb-3 active"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-2 pt-0 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    Upload Data
                                    {/* {index} */}
                                  </p>
                                </div>
                              </div>
                              <div
                                class={index == 7 ? "d-flex active" : "d-flex"}
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 7
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                  <div
                                    className={
                                      index >= 7
                                        ? index >= 8
                                          ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                          : "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    {index >= 8 ? (
                                      <img
                                        className="checkmarkimage"
                                        src={darkMode ? darkCheckImg : checkimg}
                                        style={{
                                          width:
                                            index >= 8 && darkMode
                                              ? "8px"
                                              : "9px",
                                        }}
                                      ></img>
                                    ) : (
                                      <>7</>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      index >= 8
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 7
                                        ? darkMode
                                          ? "leadDark  pb-2 active pt-2"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-2 pt-2 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    Model Parameters
                                  </p>
                                </div>
                              </div>
                              <div
                                class={index == 8 ? "d-flex active" : "d-flex"}
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 9
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                  <div
                                    className={
                                      index >= 8
                                        ? index >= 9
                                          ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                          : "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    {index >= 9 ? (
                                      <img
                                        className="checkmarkimage"
                                        src={darkMode ? darkCheckImg : checkimg}
                                        style={{
                                          width:
                                            index >= 9 && darkMode
                                              ? "8px"
                                              : "9px",
                                        }}
                                      ></img>
                                    ) : (
                                      <>8</>
                                    )}
                                  </div>
                                  <div class="line h-100 virticle-active d-none"></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 8
                                        ? darkMode
                                          ? "leadDark  pb-1 pt-2 active"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-1 pt-2 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    PEFT
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* <div className="horizontalline"></div> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          darkMode
                            ? "finetuning-navbar FineTuningDarKNav"
                            : "finetuning-navbar"
                        }
                        style={{
                          background: darkMode ? "#42466C" : "",
                          border: "none",
                        }}
                      >
                        <div
                          className="accordion-item"
                          style={{
                            backgroundColor: darkMode ? "#42466C" : "#EEF4FF",
                            border: "0px",
                          }}
                        >
                          <div
                            className="reward-navbar-header text-start accordion-button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            style={{
                              flexDirection: "inherit",
                              height: "auto",
                              color: darkMode ? "#FFFFFF" : "",
                              backgroundColor: darkMode ? "#42466C" : "inherit",
                              border: "0px",
                              padding: "16px 20px 16px 24px",
                            }}
                          >
                            PPO Model{" "}
                            <div
                              className={
                                index > 14 && darkMode
                                  ? "rounded-circle circleWidth greenCircle virticle-green"
                                  : "d-none"
                              }
                            >
                              <img
                                className="stepCompletion"
                                src={darkCheckImg}
                                style={{ width: "11px" }}
                              ></img>
                            </div>
                            {/* <span>
                            PPO Model: PPO &nbsp;
                            <span style={{ paddingLeft: "5rem" }}>
                              <FaEdit
                                className="text-primary"
                                size={"1.1rem"}
                              />{" "}
                            </span> */}
                          </div>
                          {/* <div className="horizontalline"></div> */}

                          <div
                            className="finetuning-navbar-body accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                            id="collapseThree"
                          >
                            <div class="stepper d-flex flex-column pt-0">
                              <div
                                class={
                                  index == 10 ? "d-flex active pt-2" : "d-flex"
                                }
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 10
                                        ? index >= 11
                                          ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                          : "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    {index >= 11 ? (
                                      <img
                                        className="checkmarkimage"
                                        src={darkMode ? darkCheckImg : checkimg}
                                        style={{
                                          width:
                                            index >= 8 && darkMode
                                              ? "8px"
                                              : "9px",
                                        }}
                                      ></img>
                                    ) : (
                                      <>1</>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      index >= 11
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 10
                                        ? darkMode
                                          ? "leadDark  pb-2 pt-0 active"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-2 pt-0 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    Upload Data
                                  </p>
                                </div>
                              </div>
                              <div
                                class={index == 11 ? "d-flex active" : "d-flex"}
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 11
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                  <div
                                    className={
                                      index >= 11
                                        ? index >= 12
                                          ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                          : "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    {index >= 12 ? (
                                      <img
                                        className="checkmarkimage"
                                        src={darkMode ? darkCheckImg : checkimg}
                                        style={{
                                          width:
                                            index >= 8 && darkMode
                                              ? "8px"
                                              : "9px",
                                        }}
                                      ></img>
                                    ) : (
                                      <>2</>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      index >= 13
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 11
                                        ? darkMode
                                          ? "leadDark  pb-2 pt-1 active"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-2 pt-2 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    Model Parameters
                                  </p>
                                </div>
                              </div>

                              <div
                                class={index == 12 ? "d-flex active" : "d-flex"}
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 12
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                  <div
                                    className={
                                      index >= 12
                                        ? index >= 13
                                          ? "rounded-circle circleWidth virticle-active virticle-green text-white"
                                          : "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    {index >= 13 ? (
                                      <img
                                        className="checkmarkimage"
                                        src={darkMode ? darkCheckImg : checkimg}
                                        style={{
                                          width:
                                            index >= 8 && darkMode
                                              ? "8px"
                                              : "9px",
                                        }}
                                      ></img>
                                    ) : (
                                      <>3</>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      index >= 14
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 12
                                        ? darkMode
                                          ? "leadDark  pb-2 pt-2 active"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-2 pt-2 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    PEFT Parameters
                                  </p>
                                </div>
                              </div>
                              <div
                                class={index == 14 ? "d-flex active" : "d-flex"}
                              >
                                <div class="d-flex flex-column pr-2 align-items-center">
                                  <div
                                    className={
                                      index >= 14
                                        ? "line virticle-active h-100"
                                        : "line h-100"
                                    }
                                  ></div>
                                  <div
                                    className={
                                      index >= 14
                                        ? "rounded-circle circleWidth virticle-active text-white"
                                        : "rounded-circle circleWidth virticle-not-active text-white"
                                    }
                                  >
                                    4
                                  </div>
                                  <div class="line h-100 virticle-active d-none"></div>
                                </div>
                                <div>
                                  <p
                                    className={
                                      index == 14
                                        ? darkMode
                                          ? "leadDark  pb-1 pt-2 active"
                                          : "lead  pb-3 active"
                                        : darkMode
                                        ? "leadDark  pb-1 pt-2 "
                                        : "lead  pb-3 "
                                    }
                                  >
                                    Evaluate Model
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              )}

              {index === 5 ? (
                <div className="w-100">
                  <TrainingProgressGenAI
                    proceedToRewardModel={proceedToRewardModel}
                    darkMode={darkMode}
                  />
                </div>
              ) : selectModelFlag == true ? (
                <SelectModelCompetancy
                  parentFunction={proceed}
                  darkMode={darkMode}
                />
              ) : index >= 1 && index <= 5 ? (
                <div className="finetuning-body-right">
                  <div className="CustomTable-Header">
                    <div
                      className="CustomTable-Header-Left"
                      style={{ lineHeight: "31px" }}
                    >
                      {/* <span className="CustomTable-Header-Left-span text-uppercase border-end pe-3">
                      {SideBarNames[index - 1]}
                    </span> */}
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Model Name : {localStorage.getItem("name")}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } ps-3`}
                      >
                        Selected Model : {localStorage.getItem("basemodelname")}
                      </span>
                    </div>
                    <div className="CustomTable-Header-Right">
                      {index == 4 ? (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={FineTuningstart}
                        >
                          Start Finetuning
                        </button>
                      ) : (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={(e) => {
                            // index == 3 ? doPeftReco() : proceed();
                            proceed();
                          }}
                        >
                          Proceed
                        </button>
                      )}
                      {index === 1 ? (
                        <>
                          <button className="cancelBtnDark" onClick={back}>
                            Back
                          </button>
                        </>
                      ) : (
                        <button className="cancelBtnDark" onClick={back}>
                          Back
                        </button>
                      )}
                    </div>
                  </div>
                  {index === 1 ? (
                    <UploadComp
                      uploaddone={uploaddone}
                      darkMode={darkMode}
                    ></UploadComp>
                  ) : index == 5 ? (
                    <>
                      <TrainingProgressGenAI darkMode={darkMode} />
                    </>
                  ) : (
                    <CustomTable
                      updateData={updateData}
                      TableData={TableDataLocal[index - 1]}
                      TableColumn={TableColumnLocal[index - 1]}
                      darkMode={darkMode}
                    ></CustomTable>
                  )}
                </div>
              ) : index === 6 ? (
                <div className="finetuning-body-right">
                  <div className="CustomTable-Header">
                    <div className="CustomTable-Header-Left">
                      {/* <span
                        className="CustomTable-Header-Left-span text-uppercase border-end pe-3"
                        style={{ paddingTop: "8px" }}
                      >
                        {RewardSideBarNames[index - 1]}
                      </span> */}
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Model Name : {localStorage.getItem("name")}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } ps-3`}
                      >
                        Selected Model : {localStorage.getItem("basemodelname")}
                      </span>
                    </div>
                    <div className="CustomTable-Header-Right">
                      {index === 8 ? (
                        <button
                          className="submitBtnDark bs-3 ms-3"
                          onClick={FineTuningstart}
                        >
                          Train Model
                        </button>
                      ) : (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={proceed}
                        >
                          Proceed
                        </button>
                      )}
                      {index === 6 ? (
                        <>
                          <button
                            className="cancelBtnDark"
                            onClick={back}
                            // onClick={() => navigate("/rewardmodelhome")}
                          >
                            Back
                          </button>
                        </>
                      ) : (
                        <button className="cancelBtnDark" onClick={back}>
                          Back
                        </button>
                      )}
                    </div>
                  </div>
                  <UploadComp
                    uploaddone={uploaddone}
                    darkMode={darkMode}
                  ></UploadComp>
                </div>
              ) : index === 9 ? (
                <div className="finetuning-body-right w-100 ps-0 pe-0">
                  <div className="CustomTable-Header px-4">
                    <div className="CustomTable-Header-Left">
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Training Progress
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Model Name : {localStorage.getItem("name")}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } ps-3`}
                      >
                        Selected Model : {localStorage.getItem("basemodelname")}
                      </span>
                    </div>
                    <div className="CustomTable-Header-Right pe-4">
                      <button className="submitBtnDark ms-3" onClick={proceed}>
                        Proceed
                      </button>
                    </div>
                  </div>
                  <>
                    <RewardModelProgress darkMode={darkMode} />{" "}
                  </>
                </div>
              ) : index > 6 && index <= 8 ? (
                <div className="finetuning-body-right">
                  <div className="CustomTable-Header">
                    <div className="CustomTable-Header-Left">
                      <span
                        className="CustomTable-Header-Left-span text-uppercase"
                        style={{ paddingTop: "8px" }}
                      >
                        {/* {RewardSideBarNames[index - 1]} */}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Model Name : {localStorage.getItem("name")}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } ps-3`}
                      >
                        Selected Model : {localStorage.getItem("basemodelname")}{" "}
                        {/* {selectedModelName}{" "} */}
                      </span>
                    </div>
                    <div className="CustomTable-Header-Right">
                      {index === 3 ? (
                        <button
                          className="submitBtnDark bs-3 ms-3"
                          onClick={FineTuningstart}
                        >
                          Train Model
                        </button>
                      ) : (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={proceed}
                        >
                          Proceed
                        </button>
                      )}
                      {index === 1 ? (
                        <button
                          className="cancelBtnDark"
                          onClick={() => navigate("/rewardmodelhome")}
                        >
                          Back
                        </button>
                      ) : (
                        <button className="cancelBtnDark" onClick={back}>
                          Back
                        </button>
                      )}
                    </div>
                  </div>
                  <CustomTable
                    updateData={updateRewardData}
                    TableData={RewardTableData[index - 6]}
                    TableColumn={TableColumn[index - 1]}
                    darkMode={darkMode}
                  ></CustomTable>
                </div>
              ) : index === 13 ? (
                <div className="container-fluid px-0">
                  <div className="CustomTable-Header px-4">
                    <div className="CustomTable-Header-Left">
                      {/* <span className="CustomTable-Header-Left-span text-uppercase border-end pe-3">
                      {PPOSideBarNames[index - 1]}
                    </span> */}
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Model Name : {localStorage.getItem("name")}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } ps-3`}
                      >
                        Selected Model : {localStorage.getItem("basemodelname")}
                      </span>
                    </div>
                    <div className="CustomTable-Header-Right">
                      {index === 3 ? (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={FineTuningstart}
                        >
                          Train Model
                        </button>
                      ) : (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={proceed}
                        >
                          Proceed
                        </button>
                      )}
                    </div>
                  </div>
                  <RewardModelPPOProgress darkMode={darkMode} />
                </div>
              ) : (
                <div className="finetuning-body-right pageHeadBg">
                  <div className="CustomTable-Header">
                    <div className="CustomTable-Header-Left">
                      {/* <span className="CustomTable-Header-Left-span text-uppercase border-end pe-3">
                      {PPOSideBarNames[index - 1]}
                    </span> */}
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } border-end pe-3 ps-0`}
                      >
                        Model Name : {localStorage.getItem("name")}
                      </span>
                      <span
                        className={`taskList ${
                          darkMode ? "text-white" : "text-dark"
                        } ps-3`}
                      >
                        Selected Model : {localStorage.getItem("basemodelname")}
                      </span>
                    </div>
                    <div className="CustomTable-Header-Right">
                      {index === 12 ? (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={FineTuningstart}
                        >
                          Train Model
                        </button>
                      ) : (
                        <button
                          className="submitBtnDark ms-3"
                          onClick={proceed}
                        >
                          Proceed
                        </button>
                      )}
                      {index === 10 ? (
                        <>
                          <a
                            href={RewardTemplate}
                            download="RewardTemplate"
                            target="_blank"
                            style={{
                              paddingTop: "6px",
                              paddingRight: "15px",
                              fontFamily: "calibri-bold",
                              fontSize: "16px",
                              color: darkMode ? "#BAC9F1" : "",
                            }}
                          >
                            <MdOutlineFileDownload
                              style={{ fontSize: "18px" }}
                            />{" "}
                            Download Template{" "}
                          </a>
                        </>
                      ) : (
                        <button className="cancelBtnDark" onClick={back}>
                          Back
                        </button>
                      )}
                    </div>
                  </div>
                  {index === 10 ? (
                    <UploadComp
                      uploaddone={uploaddone}
                      darkMode={darkMode}
                    ></UploadComp>
                  ) : // : index == 4 ? (
                  //   <div className="w-100">
                  //     <RewardModelPPOProgress />
                  //   </div>
                  // )
                  index == 14 ? (
                    <EvaluateAndGraph isPPO={true} darkMode={darkMode} />
                  ) : (
                    <CustomTable
                      updateData={updateData}
                      TableData={PPOTableData[index - 10]}
                      TableColumn={TableColumn[index - 1]}
                      darkMode={darkMode}
                    ></CustomTable>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <footer className="copyright copyright-footer">
          Copyright © TCS 2024. All Rights Reserved.
        </footer>
      </div>
    </>
  );
}

export default ModelConfiguration;
