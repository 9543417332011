import axios from "axios";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState
} from "reactflow";
import "reactflow/dist/style.css";
import mask from "../../assets/img/Mask Group 3@2x.png";
import { AuthContext } from "../Auth/AuthContext";
import Cnode from "../rflow/Cnode";
import Sidebar from "./AgentList";
import CreateAgentChainNav from "./CreateAgentChainNav";
import "./index.css";
const initialNodes = [

];
const rfStyle = {
  backgroundColor: '#f2f2f2',
};
const rfStyleDark = {
  backgroundColor: '#34385A',
  border: 'none'
};
let id = 0;
const getId = () => `dndnode_${id++}`;

const FlowView = () => {
  const navigate = useNavigate();
  const { darkMode, accessToken } = useContext(AuthContext)
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const onConnect = useCallback(
    params => {
      const existingEdge = edges.filter(edge => edge.source == params.source);
      if (existingEdge.length == 0) {
        setEdges(eds => addEdge(params, eds));
      } else {
        alert("Node already connected to another node");
      }
    },
    console.log("edges:", edges),

    []
  );
  const nodeTypes = useMemo(() => ({ textUpdater: Cnode }), []);
  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      const agent_id = event.dataTransfer.getData("id");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });
      const newNode = {
        id: agent_id,
        type: "textUpdater",
        position,
        data: { label: `${type}`, name: `${type}` },
        style: {
          color: "#333", // Dark gray for text
          borderColor: "#666", // A slightly darker gray for borders
          background: "#f5f5f5", // Light gray for background
          width: 250
        }
      };
      setNodes(nds => nds.concat(newNode));
      console.log(reactFlowInstance);
    },
    [reactFlowInstance]
  );

  var flow = {
    nodes: nodes,
    edges: edges,
    name: name,
    purpose: purpose
  };
  const upload = () => {
    flow = JSON.parse(localStorage.getItem("test"));

    if (flow) {
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setName(flow.name || "");
      setPurpose(flow.purpose || "");
    }
  };
  const isDeleting = "Yes" ? "Delete" : "";

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handlePurposeChange = event => {
    setPurpose(event.target.value);
  };

  const saveChain = async () => {
    const connectedNodes = nodes.filter(node =>
      edges.some(
        edge =>
          edge.source === node.id.toString() ||
          edge.target === node.id.toString()
      )
    );
    if (connectedNodes.length < nodes.length) {
      alert("please connect node");
    } else {
      // const flow = { name, purpose };
      const edges = flow.edges;
      const edgesList = [];

      // Iterate through edges and store source and target in the list
      edges.forEach((edge, index) => {
        const sourceNode = edge.source;
        const targetNode = edge.target;
        if (index === 0) {
          edgesList.push(sourceNode);
          // edgesList.push(targetNode)
        }
        edgesList.push(targetNode);
      });
      console.log(edgesList);
      flow["agent"] = edgesList;
      localStorage.setItem("test", JSON.stringify(flow));

      axios({
        url: `${process.env.REACT_APP_ACTIVITY_SERVER}/api/agentchains/chains/?user_id=1`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${accessToken}`
        },
        data: flow // No need to stringify data when using axios
      })
        .then(response => {
          // Handle the response data
          console.log("Response:", response.data);
          navigate("/agentFactory/playground/" + response.data.id);
        })
        .catch(error => {
          // Handle errors
          console.error("Error:", error);
          alert("try again")
        });
    }
  };

  

  return (
    <>
      <CreateAgentChainNav />
      <div className=" pt-3 createAgentChainBg  mx-0 w-100 agentPad">

        <div className="row mx-0 agentChainForm ">
          <div className="col-lg-3 pl-0 pt-2">
            <div className="form-group">
              <div className="label-input-container">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className={"form-control " + (darkMode ? "alchemLH border border-primary " : "")}
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 pt-2">
            <div className="form-group">
              <div className="label-input-container">
                <label htmlFor="purpose" className="form-label">
                  Purpose
                </label>
                <input
                  type="text"
                  className={"form-control  " + (darkMode ? "alchemLH border border-primary" : "")}
                  id="purpose"
                  value={purpose}
                  onChange={handlePurposeChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 px-0 pb-3">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div
                  className="collapse navbar-collapse"
                  id="navbarNavAltMarkup"
                >
                  <div class="navbar-nav ms-auto">
                    <img
                      src={mask}
                      alt="agent icon"
                      className="agent-chain-icon me-3"
                    />
                    <Link
                      class={"btn btn-primary  me-3 cancelBtn" + (darkMode ? "Dark" : "")}
                      role="button"
                      to={""}
                    >
                      Upload Agent Chain Icon
                    </Link>
                    <div className="agent-chain-partition me-3"></div>
                    <Link
                      class={"btn btn-primary  me-3 cancelBtn" + (darkMode ? "Dark" : "")}
                      role="button"
                      to={"/agentFactoryHome"}
                    >
                      Cancel
                    </Link>


                    <a
                      class={"btn btn-primary me-3 submitBtn" + (darkMode ? "Dark" : "")}
                      // onClick={() => {
                      //   navigate("/playground");
                      // }}
                      onClick={() => saveChain()}
                      role="button"
                    >
                      Save
                    </a>
                    <div className="agent-chain-partition me-3"></div>
                    <a
                      class={"btn btn-primary submitBtn" + (darkMode ? "Dark" : "")}
                      // onClick={() => {
                      //   navigate("/playground");
                      // }}
                      onClick={() => saveChain()}
                      role="button"
                    >
                      Proceed
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="dndflow" >
            <ReactFlowProvider>
              <div className="reactflow-wrapper pt-2">
                
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  proOptions={{ hideAttribution: true }}
                  fitView
                  deleteKeyCode={isDeleting}
                  style={darkMode ? rfStyleDark : rfStyle}
                  className="react-flow__edge animated"

                >
                  <Controls />
                </ReactFlow>
              </div>
              <Sidebar />
            </ReactFlowProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlowView;


