import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import CustomNavbar from "../../CustomNavbar/CustomNavbar";
import loginLeftImage from '../../../assets/img/loginLeftImageDark.png'
import { AuthContext } from "../../Auth/AuthContext";
import { jwtDecode } from "jwt-decode";

const SMEFirstTime = ({ setFirstTime, openModal }) => {
    const [activeSection, setActiveSection] = useState('getting-started')
    const createKnowledgeWork = () => {
        openModal()
    }
    const returningUser = () => {
        setFirstTime('returning-user')
    }
    const {
        token,
        setToken,
        userRole,
        setUserRole,
        authenticated,
        setAuthenticated,
        accessToken,
        setAccessToken,
        refreshToken,
        darkMode,
        setRefreshToken,
        IDToken,
        setIDToken
    } = useContext(AuthContext);
    const [username, setUsername] = useState();
    const [IDtoken, setIDtoken] = useState();
    useEffect(() => {
        // Retrieve the JWT token from local storage
        setIDtoken(IDToken);
        if (IDtoken) {
            // Decode the JWT token
            const decoded = jwtDecode(IDtoken);
            setUsername(decoded.name);
            // setUsername(decoded.)
        }
    }, [IDtoken]);
    const renderSection = (section) => {
        if (section === 'getting-started') {
            return <div className="d-flex flex-column justify-content-center">
                <div className="text-center mt-2" style={{ fontSize: '16px', fontWeight: 'bold' }}>Getting Started With Alchemist</div>
                <div className="text-center mt-2" style={{ fontSize: '14px' }}>To get started, ensure you have the following documents and materials ready</div>
                <div className="d-flex flex-row mt-2 justify-content-between w-75 mx-auto">
                    <div className="d-flex flex-column text-center p-2" style={{ background: '#34385A', borderRadius: '4px', width: '30%' }}>
                        <div className="p-2" style={{ fontSize: '16px', fontWeight: 'bold' }}>Relevant Source Documents</div>
                        <div className="p-2">These may include reference materials, guidelines, protocols, or any other documents critical to your specific knowledge work.</div>
                    </div>
                    <div className="d-flex flex-column text-center" style={{ width: '30%', background: '#34385A', borderRadius: '4px' }}>
                        <div className="d-flex flex-column text-center p-2">
                            <div className="p-2" style={{ fontSize: '16px', fontWeight: 'bold' }}>Data and Analytics</div>
                            <div className="p-2">Gather any datasets or analytical reports that are essential for decision-making in your work.</div>
                        </div>
                    </div>
                    <div className="d-flex flex-column text-center p-2" style={{ background: '#34385A', borderRadius: '4px', width: '30%' }}>
                        <div className="p-2" style={{ fontSize: '16px', fontWeight: 'bold' }}>Expected Outcomes</div>
                        <div className="p-2">Clearly define what successful knowledge work looks like. This includes identifying the objectives, quality standards, and key characteristics of a well-executed task, ensuring that the final deliverables meet the desired expectations.</div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center"><button className="btn mt-2" style={{ backgroundColor: '#102fa5', color: 'white' }} onClick={createKnowledgeWork}>Create Knowledge Work</button></div>
            </div>
        } else if (section === 'expectation') {
            return <div className="d-flex flex-column justify-content-center">
                <div className="text-center mt-2" style={{ fontSize: '16px', fontWeight: 'bold' }}>What We Expect From You</div>
                <div className="d-flex flex-column mt-2 w-75 mx-auto">
                    <div className="d-flex flex-row p-2 my-2" style={{ background: '#34385A', borderRadius: '4px' }}>
                        <div className="d-flex flex-column">
                            <div className="p-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>Collaboration</div>
                            <div className="p-1">Be prepared to collaborate with Alchemist's purposive agents to fine-tune your work and make data-driven decisions.</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row p-2 my-2" style={{ background: '#34385A', borderRadius: '4px' }}>
                        <div className="d-flex flex-column">
                            <div className="p-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>Engagement</div>
                            <div className="p-1">Actively engage in configuring the knowledge work settings to ensure Alchemist aligns perfectly with your specific needs.</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row p-2 my-2" style={{ background: '#34385A', borderRadius: '4px' }}>
                        <div className="d-flex flex-column">
                            <div className="p-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>Continuous Feedback</div>
                            <div className="p-1">Provide ongoing feedback to refine the agents’ outputs and improve the accuracy of future tasks.</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center"><button className="btn mt-2 mx-auto" style={{ backgroundColor: '#102fa5', color: 'white' }} onClick={createKnowledgeWork}>Create Knowledge Work</button></div>
            </div>
        } else if (section === 'start-configuring') {
            return <div className="d-flex flex-row justify-content-between w-75 mx-auto my-4" style={{ backgroundColor: '#34385a', borderRadius: '4px' }}>
                <div className="d-flex flex-column my-2 w-75">
                    <div className="d-flex flex-column">
                        <div style={{ fontWeight: 'bold', paddingLeft: '10%', paddingTop: '2.5%', paddingBottom: '2.5%', fontSize: '16px' }}>Start Configuring Your Knowledge Work</div>
                        <div style={{ paddingLeft: '10%', paddingTop: '2.5%', paddingBottom: '2.5%' }}>You're now ready to start configuring your knowledge work with Alchemist. Begin by defining your work parameters, setting goals, and allowing CoachLLM to assist you in optimizing your workflow.</div>
                        <div className="d-flex flex-row justify-content-start my-1" style={{ marginLeft: '10%' }}><button className="btn my-2" style={{ backgroundColor: '#102fa5', color: 'white' }} onClick={createKnowledgeWork}>Create Knowledge Work</button></div>
                    </div>
                </div>
            </div>
        } else {
            return <></>
        }
    }
    return (
        <>
            <CustomNavbar links={[]} profile={true} />
            <div className="container-fluid text-wrap">
                <div className="d-flex flex-row mt-2" style={{ background: '#34385A', borderRadius: '4px' }}>
                    <div className="d-flex flex-column w-75 ps-2 my-4">
                        <div className="ps-2" style={{ fontSize: '14px', fontWeight: 'bold' }}>Hi {username ? username : ''},</div>
                        <div className="ps-2 mt-2" style={{ fontSize: '16px' }}>Welcome to Alchemist</div>
                        <div className="ps-2 mt-2" style={{ fontSize: '14px' }}>Alchemist is your ultimate partner in transforming complex knowledge work with AI-led assistance and augmentations. Whether you're involved in underwriting, medical protocol writing, or any other knowledge-intensive activity, Alchemist leverages advanced purposive agents to enhance the consistency, quality, and efficiency of your tasks.</div>
                        <div className="d-flex flex-row justify-content-start ps-2 mt-2"><button className="btn mt-2" style={{ backgroundColor: '#102fa5', color: 'white' }} onClick={returningUser}>I know how to use Alchemist</button></div>
                    </div>
                    {/* <div className="w-25"><img style={{ width: '25%' }} src={loginLeftImage}></img></div> */}
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center mt-2 w-50 mx-auto">
                    <div className="rounded-pill" style={{ backgroundColor: activeSection === 'getting-started' ? '#34385A' : '#475075', fontSize: '14px', cursor: 'pointer', width: '20%', textAlign: 'center' }} onClick={() => { setActiveSection('getting-started') }}><div className="p-2">Getting Started</div></div>
                    <div style={{ height: '1px', border: '1px solid white', width: '20%' }}></div>
                    <div className="rounded-pill" style={{ backgroundColor: activeSection === 'expectation' ? '#34385A' : '#475075', fontSize: '14px', cursor: 'pointer', width: '20%', textAlign: 'center' }} onClick={() => { setActiveSection('expectation') }}><div className="p-2">Expectation</div></div>
                    <div style={{ height: '1px', border: '1px solid white', width: '20%' }}></div>
                    <div className="rounded-pill" style={{ backgroundColor: activeSection === 'start-configuring' ? '#34385A' : '#475075', fontSize: '14px', cursor: 'pointer', width: '20%', textAlign: 'center' }} onClick={() => { setActiveSection('start-configuring') }}><div className="p-2">Start Configuring</div></div>
                </div>
                {renderSection(activeSection)}
            </div >
            <div className={`mt-auto`} >
                <div className={`d-flex flex-column justify-content-center text-center mt-2`} style={{ backgroundColor: '#34385A' }}>
                    <div className="p-2" style={{ fontSize: '16px', fontWeight: 'bold' }}>Need Assistance?</div>
                    <div className="p-2" style={{ fontSize: '14px' }}>If you have any questions or need help configuring your tasks, our support team is here to assist you.</div>
                </div>
            </div>
        </>
    )
}

export default SMEFirstTime