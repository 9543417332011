import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import checkimg from "../../../assets/img/check.png";
import { useState } from "react";

function CreateAgentChainNav(prop) {
  const [index, setIndex] = useState(prop.index);
  console.log(index);
  return (
    <div className="container-fluid pt-4 ps-4 bgw">
      <div className="row mx-0 mb-3">
        <div className="col-md-3 px-0 pb-3">
          <h5 className="createAgentTitle pt-2 ps-3 text-left">
            {" "}
            Configure Knowledge Work{" "}
          </h5>
        </div>
        <div className="col-md-6 px-0 pb-3 mx-auto">
          <Nav variant="pills" defaultActiveKey="#" className="agentWizard">
            <Nav.Item className="modelNegMarg">
              <Nav.Link
                to="#"
                eventKey="#"
                className={
                  index >= 1
                    ? "linkPilla mx-auto mb-1"
                    : "linkPill mx-auto mb-1"
                }
              >
                {index > 1 ? (
                  <img className="checkmarkimage" src={checkimg}></img>
                ) : (
                  <>1</>
                )}
              </Nav.Link>
              <span className={index >= 1 ? "modelNavText" : "pilltext"}>
                Knowledge Work Details
              </span>
            </Nav.Item>
            {/* <hr className="horizontal negMarg" /> */}
            <hr
              className={
                index >= 1
                  ? "horizontal modelhrnegMargActive"
                  : "modelhrnegMarg"
              }
            />
            <hr
              className={
                index > 1 ? "horizontal modelhrnegMargActive" : "modelhrnegMarg"
              }
            />
            <Nav.Item className="modelNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/createAgentChain"
                className={
                  index >= 2
                    ? "linkPilla mx-auto mb-1"
                    : "linkPill mx-auto mb-1"
                }
              >
                {index > 2 ? (
                  <img className="checkmarkimage" src={checkimg}></img>
                ) : (
                  <>2</>
                )}
              </Nav.Link>
              <span className={index >= 2 ? "modelNavText" : "pilltext"}>
                Select Agent Chain{" "}
              </span>
            </Nav.Item>
            {/* <hr className="horizontal negMarg" /> */}
            <hr
              className={
                index >= 2
                  ? "horizontal modelhrnegMargActive"
                  : "modelhrnegMarg"
              }
            />
            <hr
              className={
                index > 2 ? "horizontal modelhrnegMargActive" : "modelhrnegMarg"
              }
            />
            <Nav.Item className="modelNegMarg">
              <Nav.Link
                to="/agentfactory/createAgentChain"
                eventKey="/agentfactory/"
                className={
                  index >= 3
                    ? "linkPilla mx-auto mb-1"
                    : "linkPill mx-auto mb-1"
                }
              >
                3
              </Nav.Link>
              <span className={index >= 3 ? "modelNavText" : "pilltext"}>
                Test Knowledge Work
              </span>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default CreateAgentChainNav;
