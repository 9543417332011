import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MoHeader from "./MoHeader";
import { recommendedModelsData } from "./Data/dummyData";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import { Nav } from "react-bootstrap";
import './TrainingModel.css'
import RoboImg from '../../assets/img/Group 1713 (1).svg'
import axios from "axios";

const SelectRewardPPO = ({ darkMode, handleToggle }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const {knowledgeWorkDetails, selectedAgentTitle, selectedModelIds, selectedItems, knowledgeWorkName,hyperparameterObjectData,responseText,sftModelUrl } = location.state || {};
  console.log(selectedItems);
  const currentModelId = localStorage.getItem("modelID");
  const selectedModel = selectedModelIds.find((item) => item.id === Number(currentModelId)) || {};

  // State to handle modal visibility
  const [showModal, setShowModal] = useState(false);
  const [rewardModel, setRewardModel] = useState('');
  const [rewardVersion, setRewardVersion] = useState('');
  const [ppoModel, setPpoModel] = useState('');
  const [ppoVersion, setPpoVersion] = useState('');

  const handleGoBack = () => {
    navigate("/");
  };

  const handleDPOClick = () => {
    navigate(`/mo/summary/training/dpo/${id}`, { state: {
      knowledgeWorkDetails, selectedAgentTitle, selectedModelIds, selectedItems, knowledgeWorkName,hyperparameterObjectData,responseText,sftModelUrl
    } });
  };

  const handlePPOClick = () => {
    navigate(`/mo/summary/training/reward-ppo/${id}`, { state: {
      knowledgeWorkDetails, selectedAgentTitle, selectedModelIds, selectedItems, knowledgeWorkName,hyperparameterObjectData,responseText,sftModelUrl
    } });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleProceed = () => {
    // Gather the details and then call handlePPOClick
    // Optionally validate the form data here
    handlePPOClick();
    handleCloseModal();
  };
  const handleCreateTool = async () => {
    try {
      const requestBody = {
        "model_url": "http://multimodel.eastus.cloudapp.azure.com:8004/sft-inf/predict",
        "base_model": "None",
        "user_id": `${knowledgeWorkDetails.selectedActivity.id}`,
        "model_name": "meta-llama/Meta-Llama-3-8B-Instruct",
        "prefix": "",
        "api_config": {
          "method": null,
          "params": null,
          "query": null,
          "payload": null,
          "is_api_key": null
        },
        "model_org": "local",
        "api_key": "Empty",
        "tool_name": knowledgeWorkDetails.knowledge_work.name+'_tool',
        "payload": null,
        "suffix": "",
        "model_params": {
          "max_tokens": 5000
        },
        "adapter_source": "local"
      };

      const response = await axios.post('http://alchemist.centralindia.cloudapp.azure.com:8004/api/v1/alchemist_tools/tool', requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Tool created successfully:', response.data);
      // Optionally handle the response, update UI, or notify the user
    } catch (error) {
      console.error('Error creating tool:', error.response ? error.response.data : error.message);
      // Optionally handle the error, e.g., show an error message to the user
    }
  };

  return (
    <div>
      <CustomNavbar links={[]} profile={true} handleToggle={handleToggle} />
      <div className="container-fluid py-4 px-4">
        <p className="text-light fw-bold">Model Training</p>
        <p style={{color: '#ADC2FF',marginTop:'-10px'}}>Knowledge Work: {knowledgeWorkDetails.knowledge_work.name}</p>
      </div>
      <div className="d-flex justify-content-end align-items-center" style={{ background: '#34385A', padding:'7px'}}>
  <Nav className="d-flex">
    <button className='btn btn-sm btn-link mr-2'>
      <Link to={`/mo/summary/new/${id}`} style={{ color: '#ADC2FF', textDecoration: 'underline' }}>Summary</Link>
    </button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Close</button>
    <button className='btn btn-sm mr-2' style={{ background: '#D7E5FF', color: '#012060' }}>Save</button>
  </Nav>
</div>

      <div className="container-fluid pt-5">
        <div className="d-flex align-items-center">
          <p className="text-light px-2 border-right">
            Model: {selectedItems.recommendedModels[0].model_name}
          </p>
          <p className="text-light pl-2">
            Selected Model: {selectedItems.recommendedModels[0].model_name}
          </p>
        </div>

        <div className="p-3" style={{background:'#474C6B'}}>
          <div className="" style={{background:'#313557'}}>
        <div className="chatBackgroundImage"style={{ padding:'40px', backgroundSize: 'cover', // Ensures the image covers the container
           backgroundPosition: 'center', // Centers the image in the container
           backgroundRepeat: 'no-repeat', // Prevents the image from repeating
           backgroundAttachment: 'fixed', // Keeps the background fixed during scrolling
           position: 'relative'}}>

        <p className="mt-4 text-light" style={{fontSize:'16px'}}>
          Do you want to Train the model with Reward & PPO Model or DPO mode?
        </p>


        <div className="container-fluid" >
          <div className="row">
            <div className="col-md-4 mt-4">
              <div className="card text-light" style={{ width: "20rem",height:'157px', background: "#212542", borderRadius:'0' }}>
                <div className="card-body pb-2">
                  <div className=" justify-content-between align-items-center">
                    <p className="card-title fw-bold">Reward & PPO</p>
                    
                <div className="p-2">
                  <p className="card-text text-light">
                  Reward modeling aligns a fine-tuned LLM with human preferences, while PPO (Proximal Policy Optimization) improves decision-making during the model's reinforcement learning process
                  </p>
                 
                </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2 justify-content-end">
                    <button onClick={handlePPOClick} className='btn btn-sm mr-5' style={{ background: '#003BEB', color: '#fff' }}>
                      Select & Proceed
                    </button>
                  </div>
            </div>
            <div className="col-md-4 mt-4">
            <div className="card text-light" style={{ width: "20rem", height: '157px', background: "#212542", borderRadius: '0' }}>
    <div className="card-body pb-2">
        <div className="d-flex justify-content-between align-items-start">
            <div>
                <p className="card-title fw-bold">DPO</p>
            </div>
            <div className="d-flex align-items-center" style={{position:'relative', fontWeight:'500', left:'17px',bottom:'4px'}}>
                <span className="" style={{ background: '#003BEB',padding:'4px' }}>  <span className="mx-1">
                                            <img src={RoboImg} alt="" />
                                        </span> <span style={{position:'relative', top:'2px', right:'2px'}}> Recommended </span></span>
            </div>
        </div>
        <div className="p-2">
            <p className="card-text text-light">
                DPO (Direct Preference Optimization) fine-tunes an LLM directly based on human preference data, enhancing its alignment and response quality.
            </p>
        </div>
    </div>
</div>

              <div className="d-flex mt-2 justify-content-end">
                    <button onClick={handleDPOClick} className='btn btn-sm mr-5' style={{ background: '#003BEB', color: '#fff' }}>
                      Select & Proceed
                    </button>
                  </div>
            </div>
            <div className="col-md-4 mt-4">
              <div className="card text-light" style={{ width: "20rem",height:'157px', background: "#212542", borderRadius:'0' }}>
                <div className="card-body pb-2 d-flex justify-content-center align-items-center ">
                  <p className="card-text text-light">
                    Skip Training and go to model preview.
                  </p>
                 
                </div>
                <div className="p-2">
                 
                </div>
              </div>
              <div className="d-flex mt-2 justify-content-end">
                    <button onClick={handleCreateTool} className='btn btn-sm mr-5' style={{ background: '#003BEB', color: '#fff' }}>
                      Finish Training
                    </button>
                  </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </div>

      {/* Modal for entering details */}
      {/* <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ background: '#212542' }}>
            <div className="modal-header">
              <h5 className="modal-title text-light">Enter Details for Reward & PPO</h5>
              <button type="button" className="close" onClick={handleCloseModal}>
                <span className="text-light">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="rewardModel">Reward Model</label>
                  <select id="rewardModel" className="form-control" style={{ background: '#42466C', color: '#fff', borderColor: '#003BEB' }} value={rewardModel} onChange={(e) => setRewardModel(e.target.value)}>
                    <option value="">Select Reward Model</option>
                    {/* Add your options here */}
                   
    </div>
  );
};

export default SelectRewardPPO;
