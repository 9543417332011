import { Handle, Position } from "reactflow";
import "./AChain.css";
import avatar from "../../assets/img/agentAvatar.svg";
import colorCode from "../../data/activityNodeDB";
import PropTypes from "prop-types";

const ActivityWithAgent = ({ data, isConnectable }) => {
  return (
    <>
      <div
        className="activityChainNode "
        style={{
          borderRadius: "0.25rem 0.25rem 0.25rem 0.25rem",
          height: "41%",
          border: "1px solid " + colorCode[data.Activity_Classification],
        }}
      >
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          style={{ backgroundColor: "white" }}
        />
        <div className="h-100 w-100 row g-0">
          <div className="col pt-3 pl-3 col-3" style={{ height: "100%" }}>
            <img
              src={avatar}
              alt="avatar"
              style={{ height: "20px", width: "20px" }}
            ></img>
          </div>
          <div className="col col-9 pt-3 px-0">
            <p
              style={{ fontSize: "10px", marginBottom: "0px", color: "white" }}
            >
              Activity
            </p>
            <div className="row d-flex g-0 justify-content-between pr-4">
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "0px",
                  color: "white",
                  width: "80%",
                  overflow: "hidden",
                  height: "14px",
                }}
              >
                {data.acticityName}
              </p>
              <div className="activityIButton">i</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center "
        style={{ height: "10%", backgroundColor: "#f2f2f2", margin: "0% -2%" }}
      >
        <div
          className="h-100 "
          style={{ width: "10%", backgroundColor: "#857070" }}
        ></div>
      </div>
      <div
        className="activityChainNode w-100"
        style={{
          height: "44%",
          borderRadius: "0.25rem 0.25rem 0rem 0rem",
          borderTop: "1px solid " + colorCode[data.Activity_Classification],
          borderRight: "1px solid " + colorCode[data.Activity_Classification],
          borderLeft: "1px solid " + colorCode[data.Activity_Classification],
        }}
      >
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          style={{ backgroundColor: "white" }}
        />
        <div className="h-100 w-100 row g-0" style={{ overflow: "hidden" }}>
          <div className="col pt-3 pl-3 col-3">
            <img
              src={avatar}
              alt="avatar"
              style={{ height: "20px", width: "20px" }}
            ></img>
          </div>
          <div className="col col-9 pt-3 px-0">
            <p
              style={{ fontSize: "10px", marginBottom: "0px", color: "white" }}
            >
              Agent
            </p>
            <div
              className="row d-flex g-0 justify-content-between pr-4 h-100"
              style={{ overflow: "hidden" }}
            >
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "0px",
                  color: "white",
                  width: "85%",
                }}
              >
                {data.agentName}
              </p>
              <div
                className="activityIButton "
                style={{ marginBottom: "-30%" }}
              >
                i
              </div>
            </div>
          </div>
        </div>

        <div
          className="  pb-6"
          style={{
            height: "16%",
            margin: data.Activity_Classification
              ? "-0.7%"
              : "-0.7% 0px 0px 0px",
            backgroundColor: "#9CA3Af",
            borderRadius: " 0rem 0rem 0.25rem 0.25rem",
            borderBottom:
              "1px solid " + colorCode[data.Activity_Classification],
            borderRight: "1px solid " + colorCode[data.Activity_Classification],
            borderLeft: "1px solid " + colorCode[data.Activity_Classification],
          }}
        ></div>
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
          style={{ backgroundColor: "white" }}
        />
      </div>
    </>
  );
};

ActivityWithAgent.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};

export default ActivityWithAgent;
