import React from "react";
import "./header.css";

export default function Header() {
  return (
    <div className="header">

      <div className="header-left">
        <button className="btn-header">Model Playground</button>
        <button className="btn-header btn-header-select">
          Evaluation Metrics
        </button>
      </div>

      <div className="header-right">
        <div className="wrapper">
          <div className="color-area"></div>
          <p>Model (Pre Reward Training)</p>
        </div>
        <div className="wrapper">
          <div className="color-area color-area-selected"></div>
          <p>Model (Post Reward Training)</p>
        </div>
      </div>

    </div>
  );
}
