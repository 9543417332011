import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "../agentchain.css";
import AgentWidget from "./chainWidget"; // Import the AgentWidget component
import star_regular from "../../../assets/img/star-regular.svg"
import star_solid from "../../../assets/img/star-solid.svg"
import up_arrow from "../../../assets/img/angle-up-solid.svg"
import avatar from "../../../assets/img/avtar.png"
import searchIcon from "../../../assets/img/searchIcon.png";
import { FaInfoCircle } from "react-icons/fa";
const AgentList = () => {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null); // New state to track the selected agent
  const [widgetVisible, setWidgetVisible] = useState(false);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_AGENT_CHAINING_SERVER}/api/chains/`);
        if (response.ok) {
          const data = await response.json();
          setAgents(data.chains); // Assuming data is an array of agents
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };

    fetchAgents();
  }, []); // Fetch data on initial component mount

  const onDragStart = (event, nodeType, id) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("id", id);
    event.dataTransfer.effectAllowed = "move";
    const data = agents.filter(a => a.id == id);
    console.log(data);
    // localStorage.setItem('test', JSON.stringify(data[0].chain_payload));
    localStorage.setItem("test", JSON.stringify(data));
  };

  const handleAgentClick = agent => {
    setSelectedAgent(agent);
    setWidgetVisible(true);
  };
  const handleWidgetClose = () => {
    setSelectedAgent(null);
    setWidgetVisible(false);
  };
  return (
    <div className="h-auto mt-2 w-auto bg-light" >
      <aside className=" p-0 d-flex justify-content-start flex-column bg-light h-100" style={{width: '100rem'}}>
        <div className="" style={{ height: '88.9%' }}>
          <div className=" d-flex justify-content-around flex-column border-bottom">
            <div className="cat-rat-outer">
              <div className="cat-rat-inner">
                Agent Chains
              </div>
              <div className="cat-rat-inner">
                <InputGroup className="searchWidth alchemSearchGroup" style={{ paddingRight: "20px" }}>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    className="searchBar"
                  />
                  <Button
                    variant="outline-secondary"
                    id="searchButton"
                    className="searchGroupBtn"
                  >
                    <img
                      className="img-fluid"
                      loading="lazy"
                      src={searchIcon}
                      alt=""
                    />
                  </Button>
                </InputGroup>
              </div>
            </div>


          </div>
          <div className="container-fluid h-100 d-inline-block agent-chain-list-n-rating">
            <div className="row h-100">
              <div className="col-md-6 border-right">
                <div className="d-flex agent-chain-list">
                  {/* {[1,1,1,1,1,1,1,,1,1,1,1,,1 ].map(a => (<div
                    key={1}
                    className="dndnode "
                    onDragStart={event =>
                      onDragStart(event, 'Abcd', 1)
                    }
                    draggable
                  // onClick={() => handleAgentClick(agent)} // Add click handler
                  >
                    <img width="24px" src={avatar} alt="star regular" />
                    <p style={{ fontSize: "12px" }}>{'ABCD'}</p>
                    <div style={{ borderRadius: "50%", background: "transparent linear-gradient(180deg, #FFFFFF 0%, #AAC7FF 100%) 0% 0% no-repeat padding-box", width: "20px", height: "20px", marginLeft: "3px", fontWeight: "bold" }}>i</div>
                  </div>))} */}
                  {agents.map(agent => (
                    <div
                      key={agent.id}
                      className="dndnode "
                      onDragStart={event =>
                        onDragStart(event, agent.chain_name, agent.id)
                      }
                      draggable
                      onClick={() => handleAgentClick(agent)} // Add click handler
                    >
                      <img width="24px" src={avatar} alt="star regular" />
                      <p className="dndnodeText">{agent.chain_name}</p>
                      <div style={{ borderRadius: "50%", background: "transparent linear-gradient(180deg, #FFFFFF 0%, #AAC7FF 100%) 0% 0% no-repeat padding-box", width: "20px", height: "20px", marginLeft: "3px", fontWeight: "bold" }}>i</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-6 bg-white p-0 m-0">
                <div className="cat-rat-inner-three border-bottom mt-2">
                  <a className="cat-rat-text  w-100 d-flex justify-content-between" data-toggle="collapse" href="#hel" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <p className="pl-3">Categories</p>
                    <img className="cat-rat-star mr-4" src={up_arrow} alt="star regular" />
                  </a>
                  <br />
                  <div id='hel'>
                    <input className="form-check-input " type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    <label for="vehicle1">Banking</label><br />
                    <input className="form-check-input " type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
                    <label for="vehicle2">Finance</label><br />
                    <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
                    <label for="vehicle2">Health</label><br />
                    <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
                    <label for="vehicle2">Retail</label><br />
                    <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
                    <label for="vehicle2">Privacy</label><br />

                  </div>
                </div>
                <div className="cat-rat-inner-three mt-2 p-0">
                  <div className="cat-rat-text w-100 d-flex justify-content-between" data-toggle="collapse" href="#hel2" role="button" aria-expanded="false" aria-controls="collapseExample">
                    <p className="pl-3">Rating</p>
                    <img className="cat-rat-star mr-4" src={up_arrow} alt="star regular" />
                  </div>
                  <br />
                  <div id='hel2'>
                    <div className="feedback-wrappern  justify-content-center aline-item-center ">

                      <input className="form-check-input " type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="feedback">

                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />


                      </div>
                    </div>
                    <div className="feedback-wrappern justify-content-center aline-item-center ">
                      <input className="form-check-input " type="checkbox" id="vehicle2" name="vehicle2" value="Car" />

                      <div className="feedback">
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                      </div>
                    </div>
                    <div className="feedback-wrappern  justify-content-center aline-item-center ">
                      <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />

                      <div className="feedback">
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                      </div>
                    </div>
                    <div className="feedback-wrappern  justify-content-center aline-item-center ">
                      <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />


                      <div className="feedback">
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                      </div>
                    </div>
                    <div className="feedback-wrappern justify-content-center aline-item-center ">
                      <input className="form-check-input " type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />

                      <div className="feedback">
                        <img className="cat-rat-star" src={star_solid} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                        <img className="cat-rat-star" src={star_regular} alt="star regular" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </aside>

    </div>
  );
};

export default AgentList;
