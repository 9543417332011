import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import { Card, Nav, NavLink } from "react-bootstrap";
import AlchemTableNav from "../SharedComponents/AlchemTableNav/AlchemTableNav";
import { Link, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import plusicon from "../../assets/img/plusIcon.svg";
import plusIconDark from "../../assets/img/plusIconDark.png";
import verticalThreeDots from "../../assets/img/vertical_three_dots_dark.svg";
import AlchemPagination from "../SharedComponents/AlchemPagination/AlchemPagination";
import { completedData, newData, inProgressData } from "./Data/dummyData";
import axios from "axios";
import { AuthContext } from "../Auth/AuthContext";
import { CiSearch } from "react-icons/ci";
import { Button, Form, InputGroup } from 'react-bootstrap';
import Styles from '../AChain/ActivityList/ActivityList.module.css'
import moment from "moment/moment";

const KnowledgeWork = ({ darkMode, handleToggle }) => {
  let navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("")
  const [isPause, setIsPause] = useState(false)

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [draftData, setDraftData] = useState([])
  const [deployedData, setDeployedData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const startIndex = useRef((currentPage - 1) * recordsPerPage)
  const endIndex = useRef(startIndex.current + recordsPerPage)
  const [displayedData, setDisplayedData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [totalPages, setTotalPages] = useState(1)
  // const [displayedData, setDisplayedData] = useState(newData);
  let page = 1;
  let end_page = 1000;
  const UTCToLocalTimeConverstion = (utcTime) => {



    const currentDate = new Date(utcTime).toLocaleString()
    var gmtDateTime = moment.utc(currentDate).local().format('DD-MMM-YYYY hh:mm A');
    return (<span className="text-center align-self-center p-2">
      {gmtDateTime}
    </span>)

  }


  const { accessToken, setAccessToken, setRefreshToken } = useContext(AuthContext);
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };
  const TextToggle = ({ text = "" }) => {
    const [isExpand, setIsExpand] = useState(false);
    const toggleText = () => {
      if (!isExpand) {
        setIsPause(true)
      } else {
        setIsPause(false)
      }
      setIsExpand(prevState => !prevState);
    };
    const inlineStyles = {
      cursor: "pointer",
      overflowY: isExpand ? "scroll" : "hidden",
      width: "100%",
      height: "100%"
    }



    return (
      <div
        onClick={toggleText}
        className={Styles["cell-div"]}
        style={inlineStyles}>
        {isExpand || text.length <= 70
          ? text
          : text.slice(0, 70)}
        {text.length > 70 && !isExpand && '...'}
      </div>
    );
  };


  const links = [
    { name: "Knowledge Work", route: "/activityList", isActive: false },
    { name: "Model Orchestrator", route: "/mo/knowledge-work", isActive: true },
    { name: "Models", route: "/mo/models", isActive: false },
  ];


  const filters = [
    {
      label: "Domain",
      options: ["Banking", "Life Science", "Insurance"],
      selectedOption: "All",
    },
    {
      label: "Sub-Domain",
      options: ["Wealth", "Drug", "Policy"],
      selectedOption: "All",
    },
    {
      label: "Task",
      options: ["Wealth", "Drug", "Policy"],
      selectedOption: "All",
    },
    {
      label: "Base Model",
      options: ["EY", "Astreagenica", "Cigna"],
      selectedOption: "All",
    },
  ];

  const [knowledgeWorkLinks, setKnowledgeWorkLinks] = useState([
    { name: "Deployed", route: "", isActive: false },
    { name: "Draft", route: "", isActive: true },
  ])


  const handleKnowledgeWorkClick = (index) => {
    setKnowledgeWorkLinks(prevLinks =>
      prevLinks.map((link, i) =>
        ({ ...link, isActive: i === index })
      )
    )


  }

  const knowledgeworkSegregation = (data) => {
    let deploy = []
    let draft = []
    data.map((item) => {
      if (item.status.toLowerCase() === "done".toLowerCase()) {
        deploy.push(item)
      } else {
        draft.push(item)
      }
    })
    // deploy.reverse()
    setDeployedData(deploy)
    // draft.reverse()
    setDraftData(draft)

    return true
  }
  const SearchForKey = () => {
    setIsPause(true)
    if (searchKey == "") {
      setIsPause(false)
      fetchData()
      return ""
    }

    if (isDeployed()) {

      const updatedData = deployedData.filter((item) => {
        if (item.name?.toLowerCase().includes(searchKey.toLowerCase()) || item.description?.toLowerCase().includes(searchKey.toLowerCase()) || item.domain?.toLowerCase().includes(searchKey.toLowerCase()) || item.sub_domain?.toLowerCase().includes(searchKey.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
      console.log(updatedData)
      setDeployedData(updatedData)
    } else {

      const updatedData = draftData.filter((item) => {
        if (item.name?.toLowerCase().includes(searchKey.toLowerCase()) || item.description?.toLowerCase().includes(searchKey.toLowerCase()) || item.domain?.toLowerCase().includes(searchKey.toLowerCase()) || item.sub_domain?.toLowerCase().includes(searchKey.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
      console.log(updatedData)
      setDraftData(updatedData)
    }
  }

  const knowledgeworkSegregationMemo = useMemo(() => knowledgeworkSegregation(activityData), [activityData])

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  const fetchData = () => {

    axios
      .get(
        `${process.env.REACT_APP_ACTIVITY_SERVER}/api/activity/knowledge_works/?skip=0&limit=${end_page}`,
        { headers }
      )
      .then((response) => {
        const filteredData = response.data.filter(item => item.status === 'validate');
        setActivityData(filteredData);
        setLoading(false);
        knowledgeworkSegregation(filteredData);
      })
      .catch((error) => {
        console.error("Error Fetching Data", error);
        if (error.response && error.response.status === 401)
          axios
            .post(
              `${process.env.REACT_APP_AUTHENTICATE_SERVER}/refresh_token`, { "refresh_token": getCookie("refreshToken") },
              { headers }
            )
            .then(refreshResponse => {
              // Check if the refresh request was successful
              if (
                refreshResponse.status === 200 &&
                refreshResponse.data.access_token &&
                refreshResponse.data.refresh_token
              ) {
                setAccessToken(refreshResponse.data.access_token);
                setRefreshToken(refreshResponse.data.refresh_token);
                document.cookie = `accessToken=${refreshResponse.data.access_token}`;
                document.cookie = `refreshToken=${refreshResponse.data.refresh_token}`;
                console.log("Token refreshed");
                return true; // User is authenticated after successful token refresh
              } else {
                console.log("Error refreshing token:", refreshResponse);
                return false; // Authentication failed if token refresh fails
              }
            })
            .catch(error => {
              if (error.response && error.response.status === 401)
                window.location.reload()
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.error === "invalid_grant"
              ) {
                console.error("Refresh token expired or invalid");
              }

              return false; // Authentication failed if token refresh fails
            });
        else {
          setError(true);
        }
        setLoading(false);
      });

  };
  useEffect(() => {
    fetchData();
  }, [])

  const retry = () => {
    setLoading(true);
    setError(false);
    fetchData();
    console.log(activityData);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const isDeployed = () => {

    let link = knowledgeWorkLinks.find(link => link.isActive && link.name === 'Deployed')

    if (link) {
      return true
    }

    return false

  }
  const filterData = () => {


    if (isDeployed()) {

      let data = deployedData.slice(startIndex.current, endIndex.current)

      setTotalPages(Math.ceil(deployedData.length / recordsPerPage))
      setDisplayedData(data);

    } else {

      let data = draftData.slice(startIndex.current, endIndex.current)
      setTotalPages(Math.ceil(draftData.length / recordsPerPage))
      setDisplayedData(data);

    }

  }

  useEffect(() => {
    filterData()
  }, [deployedData, draftData])

  useEffect(() => {
    startIndex.current = (0) * recordsPerPage
    endIndex.current = startIndex.current + recordsPerPage

    filterData()
  }, [knowledgeWorkLinks]);

  useEffect(() => {
    startIndex.current = (currentPage - 1) * recordsPerPage
    endIndex.current = startIndex.current + recordsPerPage
    filterData()
  }, [currentPage])

  const isProcessingEntity = (item) => {
    return item.status.toLowerCase() === "processing".toLowerCase();
  }


  const handleActivityNavigate = (item) => {
    if (isProcessingEntity(item)) {
      return ""
    }

    localStorage.setItem("name", item.name)

    if (item.status.toLowerCase() === "done".toLowerCase()) {
      navigate("/summary/" + item.id)
    } else {
      navigate("/mo/summary/new/" + item.id)
    }
  }


  return (
    <div>
      <CustomNavbar links={links} profile={true} />

      <div style={{ padding: "40px" }} className="pt-4">
        <nav className="navbar navbar-expand-lg pb-3 pt-0">
          <div className="container-fluid px-0 ">
            <span
              className={
                darkMode
                  ? "text-white page-title-dark"
                  : "text-dark page-title-dark"
              }
            >
              Knowledge Work
            </span>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ms-auto px-0">
                <InputGroup
                  className={`mr-2 ${darkMode ? "ms-auto searchWidth alchemSearchGroupDark" : "ms-auto searchWidth alchemSearchGroup"}`}
                >
                  <input
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    className="form-control border-0"
                    value={searchKey}
                    onKeyDown={(input) => {
                      input.key == "Enter" && SearchForKey()
                    }}
                    onChange={(event) => { setIsPause(true); setSearchKey(event.target.value); }}
                  />
                  <Button
                    variant="outline-secondary"
                    id="searchButton"
                    className="searchGroupBtn"
                    onClick={SearchForKey}
                  >
                    <CiSearch style={{ fontSize: "18px", color: "#E5EAF1" }} />

                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
        </nav>



        <div className="mt-2">

          <Nav variant="tabs" className="me-auto mt-0" defaultActiveKey={"competencies"}>
            {knowledgeWorkLinks.map((link, index) => (
              <Nav.Item key={`${index}_${link}`}>
                <Nav.Link
                  onClick={() => handleKnowledgeWorkClick(index)}
                  className={
                    `justify-content-center align-items-center d-flex mt-0`
                  }
                  style={{
                    display: "inline-block",
                    marginBottom: "-5px",
                    marginRight: '6px',
                    fontSize: "16ppx",
                    color: darkMode ? "#fff" : "#000",
                    backgroundColor: link.isActive ? '#454d7b' : 'transparent'

                  }}
                  as={Link}
                >
                  {link.name === 'Deployed' ? link.name + ` (${deployedData.length})` : link.name + ` (${draftData.length})`}
                </Nav.Link>
              </Nav.Item>
            ))}


          </Nav>


          <Card className={darkMode ? "tableCardDark mt-2" : "tableCard"}>
            {!loading && <div className="pl-4 pt-4">


            </div>}
            <Card.Body className="p-4 pt-0 ">
              {loading && (
                <>
                  <div className="dataLoader"></div>
                  <p className="text-center mt-2 text-white"> Loading...</p>
                </>
              )
              }

              {error && !loading ? (
                <div className="retry-container">
                  <p className="text-white">
                    An error occurred while fetching the data. Please try again.
                  </p>
                  <button onClick={retry} className="submitBtnDark">
                    Retry
                  </button>
                </div>
              ) : (
                <table
                  className={
                    darkMode
                      ? "table alchemTableDark allModelTable mb-0"
                      : "table alchemTable allModelTable mb-0"
                  }
                >
                  <thead
                    className={
                      "custom-table-head-dark " + darkMode ? "dark-text" : ""
                    }
                  >
                    <tr>
                      <th></th>
                      <th></th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Date Created
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Domain
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Sub Domain
                      </th>

                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Activities
                      </th>
                      <th
                        style={{
                          borderRight: "#2F499E22 solid 1px",
                          paddingLeft: "16px",
                        }}
                      >
                        Status
                      </th>


                      <th></th>
                    </tr>
                  </thead>

                  <tbody className={darkMode ? "dark-text" : ""}>

                    {
                      displayedData.length == 0 ?
                        <>
                          <tr>
                            <td colSpan={12}>
                              <div className="d-flex justify-content-center align-items-center">
                                No knowledge work is validated to show
                              </div>
                            </td>
                          </tr>
                        </>

                        :


                        displayedData?.map((item, index) => (
                          <>
                            <tr key={index} className={`non-hidden ${isProcessingEntity(item) ? "bg-secoundary" : ""}`}>
                              <td style={{ borderBottom: "none" }}></td>
                              <td
                                data-toggle="collapse"
                                data-target={"#rowId" + index}
                                className="accordion-toggle p-3 pt-4"
                                aria-expanded="false"
                                aria-controls={"#rowId" + index}
                                style={{ cursor: "pointer" }}
                              >
                                {item.status.toLowerCase() === "done".toLowerCase() ?

                                  darkMode ? (
                                    <img src={plusIconDark} alt="plus" />
                                  ) : (
                                    <img src={plusicon} alt="plus" />
                                  )

                                  :

                                  ""}

                              </td>
                              <td
                                onClick={() => { handleActivityNavigate(item) }}
                                style={{ borderRight: "#2F499E22 solid 1px" }}
                              >
                                <div className="activityLinkCell" style={{ minWidth: "120px" }}>{item.name}</div>
                              </td>
                              <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                <div className={Styles["cell-div"]} >
                                  <TextToggle text={item.description || item?.master_json?.knowledge_work?.description} />
                                </div>

                              </td>
                              <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                <div className="m-0 d-flex flex-column justify-content-center align-items-center" style={{ maxHeight: "100px" }}>{
                                  UTCToLocalTimeConverstion(item.created_at)
                                }</div>                      </td>
                              <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                <div className={`${Styles["cell-div"]} d-flex justify-content-center align-items-center`}>
                                  <TextToggle text={item?.domain || item?.master_json?.knowledge_work?.sub_domain} />
                                </div>
                              </td>
                              <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                <div className={`${Styles["cell-div"]} d-flex justify-content-center align-items-center`}>
                                  <TextToggle text={item?.sub_domain || item?.master_json?.knowledge_work?.sub_domain} />
                                </div>                      </td>

                              <td
                                style={{ borderRight: "#2F499E22 solid 1px" }}
                                className="text-center"
                              >
                                <div>{item.activities.length}</div>
                              </td>
                              <td style={{ borderRight: "#2F499E22 solid 1px" }}>
                                <div className="statusContainer mt-2">
                                  <div
                                    className="circle"
                                    style={{
                                      background:
                                        "Just created" == "Just created"
                                          ? "#1FEB00"
                                          : "Finetuned" == "Finetuned"
                                            ? "#1FEB00"
                                            : "#3366FF",
                                    }}
                                  ></div>{" "}
                                  {item.status.toLowerCase() === "validate".toLowerCase() ?
                                    "Validate" : "Start"
                                  }
                                </div>
                              </td>
                              <td>
                              </td>
                              <td style={{ borderBottom: "none" }}></td>


                            </tr>
                            <tr>
                              <td colspan="12" className="hiddenRow border-bottom">
                                <div
                                  className="collapse subTable_outer multi-collapse"
                                  id={"rowId" + index}
                                  style={{ background: darkMode ? "#212542" : "" }}
                                >
                                  <table
                                    className={`table ${darkMode ? "subTableDark" : "subTable"
                                      } mb-0`}
                                  >
                                    <tbody>
                                      <tr style={{ backgroundColor: "#212542" }}>
                                        <td
                                          colSpan="1"
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        >
                                          <button className="btn btn-primary submitBtnDark">
                                            API
                                          </button>
                                        </td>
                                        <td
                                          colSpan="1"
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        >
                                          <button
                                            onClick={() =>
                                              navigate(
                                                "/knowledgework/playground/" + item.id
                                              )
                                            }
                                            className="btn btn-primary submitBtnDark"
                                          >
                                            TEST
                                          </button>
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: darkMode ? "none" : "",
                                          }}
                                        ></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                  </tbody>
                </table>
              )}
            </Card.Body>
          </Card>

          <AlchemPagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default KnowledgeWork;
