import React, { useState, useEffect, useContext } from "react";
import { Dock } from "react-dock";
import Draggable from "react-draggable";
import styles from "./AgentDock.module.css"
import PropTypes from "prop-types";
import { BsBoxArrowDownLeft, BsBoxArrowUpRight } from "react-icons/bs";
import { Nav } from "react-bootstrap";
import { DockContext } from "../../../DockWrapper/DockContext";
import AgentDetails from "../dockComps/AgentDetails";
import ModelSelection from "../dockComps/ModelSelection";
import ListModel from "../agentModelMapping/ListModel";



const AgentDock = (props) => {


    console.log(props)
    const {
        setIsDraggable,
        isDraggable,
        agentdockSize,
        setAgentDockSize,
        isAgentDocked, setIsAgentDocked,
        agentcontentSize, setAgentContentSize
    } = useContext(DockContext);


    const toggleDock = () => {
        setIsAgentDocked(!isAgentDocked);
        setIsDraggable(!isDraggable);
        if (!isAgentDocked) {
            setAgentContentSize(100)
        }
        else {
            setAgentContentSize(100 - agentdockSize * 100)
        }
    };
    // useEffect(() => setPath(window.location.pathname === '/AChain'))

    const handleSizeChange = (size) => {
        console.log(size)
        if (size < 0.46 && size > 0.30) {
            setAgentDockSize(size);
        }

    };






    useEffect(() => {
        if (isAgentDocked) {
            setAgentContentSize(100 - agentdockSize * 100)
        }
    },

        [agentdockSize, isAgentDocked])

    const dockStyle = { top: "8.5%", overflow: "scroll", height: "90%", backgroundColor: "" };

    const ComponentConfig = {
        AgentDetails: <AgentDetails setTitle={props.setTitle} data={props.data} setData={props.setData} isSave={props.dynamicFuncForSave} setIsSave={props.setSaveAgentDetails} setSelectedPos={props?.setSelectedPos} setFinished={props?.setFinished} finished={props?.finished}/>,
        ModelSelection: <ModelSelection setTitle={props.setTitle} data={props.data} setData={props.setData} />,
        ListModel: <ListModel setTitle={props.setTitle} />
    }

    useEffect(() => {

        console.log(props.title)


    }, [props.title])

    return (
        <>
            <div className="d-flex flex-column " style={{ width: agentcontentSize + "%", float: "left", height: "100%" }}>
                {props.children}
            </div>
            {
                ComponentConfig[props.title]
            }
        </>
    );
};

export default AgentDock;


