// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
}
.progress-file-txt {
  text-align: left;
  font: normal normal normal 14px Calibri;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
}
.progress-txt {
  text-align: left;
  font: italic normal normal 14px/17px Calibri;
  letter-spacing: 0px;
  color: #1feb00;
  opacity: 1;
}

/* .d-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
} */
.fileUploadMsg {
  font: italic normal normal 14px/17px Calibri;
  color: #1feb00;
}

.uploadedFileName {
  font: normal normal normal 14px/17px Calibri;
  color: #888888;
}
`, "",{"version":3,"sources":["webpack://./src/components/AgentChainModule/ModelCompetancy/components/cards/uploadfiles/progressBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,gBAAgB;EAChB,uCAAuC;EACvC,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,4CAA4C;EAC5C,mBAAmB;EACnB,cAAc;EACd,UAAU;AACZ;;AAEA;;;;;;GAMG;AACH;EACE,4CAA4C;EAC5C,cAAc;AAChB;;AAEA;EACE,4CAA4C;EAC5C,cAAc;AAChB","sourcesContent":[".progress-div {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: flex-start;\n  align-content: center;\n}\n.progress-file-txt {\n  text-align: left;\n  font: normal normal normal 14px Calibri;\n  letter-spacing: 0px;\n  color: #888888;\n  opacity: 1;\n}\n.progress-txt {\n  text-align: left;\n  font: italic normal normal 14px/17px Calibri;\n  letter-spacing: 0px;\n  color: #1feb00;\n  opacity: 1;\n}\n\n/* .d-flex{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 10px;\n    width: 100%;\n} */\n.fileUploadMsg {\n  font: italic normal normal 14px/17px Calibri;\n  color: #1feb00;\n}\n\n.uploadedFileName {\n  font: normal normal normal 14px/17px Calibri;\n  color: #888888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
