// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Models_modelTableMaxWidth__t5Q5g td {
    min-width: 100px;
    max-width: 120px;
    padding: 15px !important;
    border-right: 1px solid rgba(47, 73, 158, 0.133);
}`, "",{"version":3,"sources":["webpack://./src/components/NewModelOrchestrator/Models.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,wBAAwB;IACxB,gDAAgD;AACpD","sourcesContent":[".modelTableMaxWidth td {\n    min-width: 100px;\n    max-width: 120px;\n    padding: 15px !important;\n    border-right: 1px solid rgba(47, 73, 158, 0.133);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelTableMaxWidth": `Models_modelTableMaxWidth__t5Q5g`
};
export default ___CSS_LOADER_EXPORT___;
