import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlchemLogo from "../../../assets/img/AlchemLogo.png";
import LogoutLogo from "../../../assets/img/logout.svg";
import LogoWhite from "../../../assets/img/logoWhite.svg";
import Logout from "../../../assets/img/onBtn.svg";
import UserThumbnail from "../../../assets/img/user-profile-icon.svg";
import DropDownArrow from "../../../assets/img/dropdown-arrow.svg";
import { AuthContext } from "../../Auth/AuthContext";
import { useContext } from "react";
import { jwtDecode } from "jwt-decode"; import Toggle from "../../Toggle/Toggle";
import { Background } from "reactflow";

function ModelsNav({ darkMode, handleToggle }) {
  const { IDToken } = useContext(AuthContext)
  const navigate = useNavigate();

  const handleHome = () => {
    return navigate("/");
  };
  var user = jwtDecode(IDToken);
  // console.log(user.name)
  return (
    <Navbar collapseOnSelect expand="lg" className={darkMode ? "navWithBgDark " : "navWithBg "}>
      <Container fluid className="px-4">
        <Navbar.Brand
          href=""
          onClick={handleHome}
          style={{
            cursor: "pointer"
          }}
        >
          <img
            className="img-fluid logoSize ps-3"
            loading="lazy"
            src={LogoWhite}
            alt=""
            href="/"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className="me-auto">
            {/*  <Nav.Link href="#pricing">Agent Chains</Nav.Link>
            <Nav.Link href="#pricing">Playground</Nav.Link> */}
          </Nav>
          <Nav.Link className="text-primary m-4" onClick={() => navigate(-1)}>Back</Nav.Link>
          <Nav className='ml-3 d-flex align-items-center'
            style={{
              background: darkMode ? 'inherit' : 'white', border: 'none',
              width: '124px',
              height: '34px',
            }}
          >
            <NavDropdown
              title={
                <div  >
                  <img src={UserThumbnail} />
                  <span
                    className="user-name"
                    style={darkMode ? { color: "#E5EAF1" } : { color: "white" }}
                  >
                    {user.name}
                  </span>
                  <img src={DropDownArrow} />
                </div>
              }
              id="navbarScrollingDropdown "
              className={"btn user-profile m-0 " + darkMode ? " user-drop-down" : ""}
              style={
                darkMode ? { backgroundColor: "#212542", border: "none" } : { backgroundColor: "white" }
              }
            >
              <NavDropdown.Item href="#action3">User Profile</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Settings</NavDropdown.Item>
              <NavDropdown.Item>
                Logout
              </NavDropdown.Item>
            </NavDropdown>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ModelsNav;
