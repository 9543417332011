export const SideBarNames = [
  "Upload Data",
  "Basic Hyperparameters",
  "Advanced Hyperparameters",
  "PEFT Hyperparameters",
  "Evaluate Model"
];
export const RewardSideBarNames = [
  "Upload Data",
  "Model Parameters",
  "PEFT",
  "Training Model",
  "Evaluate Model"
];
export const PPOSideBarNames = [
  "Upload Data",
  "Model Parameters",
  "PEFT Parameters",
  "Training Model",
  "Model Parameter | Model Name"
];
export const TableColumn = [
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["id5", "Data", "amount"],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ],
  ["Parameter Name", "Input", ]
];

export const TableData = [
  [],
  [
    {
      Input: { value: "None", type: "text" },

      "Parameter Name": {
        type: "label",

        value: "output_dir"
      },
      Help: {
        type: "label",
        value:
          "The directory where the model checkpoints and predictions will be saved."
      }
    },

    {
      Input: {
        options: [
          {
            label: "no",

            value: "no"
          },

          {
            label: "steps",

            value: "steps"
          },

          {
            label: "epoch",

            value: "epoch"
          }
        ],

        type: "select",

        value: "epoch"
      },

      "Parameter Name": {
        type: "label",

        value: "evaluation_strategy"
      },
      Help: {
        type: "label",
        value:
          "The strategy to use for evaluation. Possible values are `no`, `steps`, `epoch`."
      }
    },
    {
      Input: { value: 2, type: "number" },

      "Parameter Name": { value: "eval_steps", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each evaluation. Will default to the same value as `logging_steps` if not set"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "do_steps", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each evaluation. Will default to the same value as `logging_steps` if not set"
      }
    },

    {
      Input: { value: "debug", type: "text" },

      "Parameter Name": { value: "log_level", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each checkpoint save. Will default to the same value as `logging_steps` if not set"
      }
    },
    {
      Input: { value: "paged_adamw_32bit", type: "text" },

      "Parameter Name": { value: "optim", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each checkpoint save. Will default to the same value as `logging_steps` if not set"
      }
    },
    {
      Input: { value:2, type: "number" },

      "Parameter Name": { value: "save_steps", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each checkpoint save. Will default to the same value as `logging_steps` if not set"
      }
    },
    {
      Input: { value: "./logs", type: "text" },

      "Parameter Name": { value: "logging_dir", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each logging. If set to 0, no logging is done"
      }
    },
    {
      Input: { value: 2, type: "number" },

      "Parameter Name": { value: "logging_steps", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each logging. If set to 0, no logging is done"
      }
    },
    {
      Input: { value: 5, type: "number" },

      "Parameter Name": { value: "num_train_epochs", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each logging. If set to 0, no logging is done"
      }
    },
    {
      Input: { value: 0.01, type: "number" },

      "Parameter Name": { value: "weight_decay", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each logging. If set to 0, no logging is done"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "packing", type: "label" },
      Help: {
        type: "label",
        value:
          "The number of steps between each logging. If set to 0, no logging is done"
      }
    },
    {
      Input: { value: 5, type: "number" },

      "Parameter Name": { value: "save_total_limit", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "load_best_model_at_end", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "bf16", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "tf32", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "gradient_checkpointing", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "True", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "auto_find_batch_size", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "False", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "dataloader_pin_memory", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      Input: { value: "False", type: "radio", options:[
        { value: "True", label: "True" },
        { value: "False", label: "False" }
      ] },

      "Parameter Name": { value: "distributed_training", type: "label" },
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },
    {
      "Parameter Name": { value: "metric_for_best_model", type: "label" },

      Input: { value: "accuracy", type: "text" },

      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for training"
      }
    },
    {
      "Parameter Name": { value: "per_device_train_batch_size", type: "label" },

      Input: { value: 8, type: "number", min: 1, max: 10000 },

      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for training"
      }
    },
    {
      "Parameter Name": { value: "per_device_eval_batch_size", type: "label" },

      Input: { value: 8, type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for evaluation"
      }
    },
    {
      "Parameter Name": { value: "gradient_accumulation_steps", type: "label" },

      Input: { value: 4, type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for evaluation"
      }
    },
    {
      "Parameter Name": { value: "max_grad_norm", type: "label" },

      Input: { value: 0.3, type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for evaluation"
      }
    },
    {
      Input: { value:"reduce_lr_on_plateau", type: "text" },

      "Parameter Name": { value: "lr_scheduler_type", type: "label" },
      Help: {
        type: "label",
        value: "The initial learning rate for the optimizer"
      }
    },
    {
      Input: { value:"none", type: "text" },

      "Parameter Name": { value: "report_to", type: "label" },
      Help: {
        type: "label",
        value: "The initial learning rate for the optimizer"
      }
    },
    {
      Input: { value: 5e-05, type: "number" },

      "Parameter Name": { value: "learning_rate", type: "label" },
      Help: {
        type: "label",
        value: "The initial learning rate for the optimizer"
      }
    },
  ],
  [
    {
      "Parameter Name": { value: "gradient_checkpointing", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables gradient checkpointing, which saves memory by trading off some speed. It allows the model to handle longer sequences and larger batch sizes"
      }
    },
    {
      "Parameter Name": { value: "fp16", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables mixed precision training with 16-bit floating point numbers. It can reduce memory usage and improve speed, but may also introduce some numerical instability"
      }
    },
    {
      "Parameter Name": { value: "bf16", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables mixed precision training with bfloat16 numbers. It is similar to fp16, but uses fewer bits for the exponent and more bits for the mantissa. It is supported by some hardware accelerators, such as TPUs"
      }
    },
    {
      "Parameter Name": { value: "weight_decay", type: "label" },
      Input: { value: 0, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "A regularization parameter that applies L2 penalty to the model weights. It can prevent overfitting and improve generalization"
      }
    },
    {
      "Parameter Name": { value: "warmup_ratio", type: "label" },
      Input: { value: 0, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "A fraction of the total number of training steps that are used for a linear warmup from 0 to the initial learning rate. It can help the model converge faster and avoid local minima"
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "run_name", type: "label" },
      Help: {
        type: "label",
        value:
          "A string that identifies the run and is used for logging and saving checkpoints"
      }
    },
    {
      "Parameter Name": { value: "report_to", type: "label" },
      Input: {
        value: "tensorboard",
        type: "select",
        options: [
          { value: "tensorboard", label: "linear" },
          { value: "tensorboard", label: "tensorboard" },
          { value: "wandb", label: "wandb" },
          { value: "mlflow", label: "mlflow" },
          { value: "None", label: "None" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A list of integrations to report the results and logs to. Possible values are `none`, `tensorboard`, `wandb`, `azure_ml`, `mlflow`, `ui`, `optuna`, `ray` or a custom `Integration`"
      }
    },
    {
      "Parameter Name": { value: "adam_beta1", type: "label" },
      Input: { value: 0.9, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The first beta parameter for the Adam optimizer. It controls the exponential decay rate of the first moment (mean) of the gradients"
      }
    },
    {
      "Parameter Name": { value: "adam_beta2", type: "label" },
      Input: { value: 0.999, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The second beta parameter for the Adam optimizer. It controls the exponential decay rate of the second moment (variance) of the gradients"
      }
    },
    {
      "Parameter Name": { value: "adam_epsilon", type: "label" },
      Input: { value: 0, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "A small constant that is added to the denominator of the Adam optimizer to prevent division by zero and numerical instability"
      }
    },
    {
      "Parameter Name": { value: "max_grad_norm", type: "label" },
      Input: { value: 1.0, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The maximum norm for the gradients. It can be used to clip the gradients and prevent exploding gradients"
      }
    }
  ],
  [
    {
      "Parameter Name": { value: "lora_r", type: "label" },
      Input: { value: 8, type: "number" },
      Help: {
        type: "label",
        value:
          "The rank of the low-rank approximation matrices. It controls the trade-off between memory efficiency and accuracy. A lower rank means fewer parameters to update, but also less expressive power"
      }
    },
    {
      "Parameter Name": { value: "lora_alpha", type: "label" },
      Input: { value: 8, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The scaling factor for the weight updates. It is calculated as alpha divided by rank. A higher alpha means larger updates, but also more risk of overfitting"
      }
    },
    {
      "Parameter Name": { value: "lora_dropout", type: "label" },
      Input: { value: 0.05, type: "number" },
      Help: {
        type: "label",
        value:
          "The dropout probability for the low-rank approximation matrices. It can help regularize the model and prevent overfitting"
      }
    },
    {
      Input: { value: "['q_proj', 'k_proj', 'v_proj', 'o_proj', 'gate_proj', 'up_proj', 'down_proj']", type: "text" },
      "Parameter Name": { value: "target_modules", type: "label" },
      Help: {
        type: "label",
        value:
          "The list of modules or layers that are targeted by LoRA. It can be used to fine-tune specific parts of the model, such as the query, key, value, output, or head projections"
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "Bias", type: "label" },
      Help: {
        type: "label",
        value:
          "A boolean flag that indicates whether to use bias terms in the low-rank approximation matrices. It can improve the model performance, but also increase the number of parameters"
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "task_type", type: "label" },
      Help: {
        type: "label",
        value:
          "The type of the downstream task that the model is fine-tuned for. It can be one of the following: `classification`, `regression`, `generation`, `question_answering`, `summarization`, `translation`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "inference_mode", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
   
  ],
  [
    {
      "Parameter Name": { value: "load_in_4bit", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "The rank of the low-rank approximation matrices. It controls the trade-off between memory efficiency and accuracy. A lower rank means fewer parameters to update, but also less expressive power"
      }
    },
    {
      "Parameter Name": { value: "torch.bfloat16", type: "label" },
      Input: { value: "", type: "text"},
      Help: {
        type: "label",
        value:
          "The scaling factor for the weight updates. It is calculated as alpha divided by rank. A higher alpha means larger updates, but also more risk of overfitting"
      }
    },
    {
      "Parameter Name": { value: "bnb_4bit_use_double_quant", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "The dropout probability for the low-rank approximation matrices. It can help regularize the model and prevent overfitting"
      }
    },
    {
      Input: { value: "nf4", type: "text" },
      "Parameter Name": { value: "bnb_4bit_quant_type", type: "label" },
      Help: {
        type: "label",
        value:
          "The list of modules or layers that are targeted by LoRA. It can be used to fine-tune specific parts of the model, such as the query, key, value, output, or head projections"
      }
    },
    {
      Input: { value: 1, type: "number" },
      "Parameter Name": { value: "per_device_train_batch_size", type: "label" },
      Help: {
        type: "label",
        value:
          "A boolean flag that indicates whether to use bias terms in the low-rank approximation matrices. It can improve the model performance, but also increase the number of parameters"
      }
    },
    {
      Input: { value: 1, type: "number" },
      "Parameter Name": { value: "gradient_accumulation_steps", type: "label" },
      Help: {
        type: "label",
        value:
          "The type of the downstream task that the model is fine-tuned for. It can be one of the following: `classification`, `regression`, `generation`, `question_answering`, `summarization`, `translation`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "learning_rate", type: "label" },
      Input: {
        value: 5e-6,
        type: "number",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "lr_scheduler_type", type: "label" },
      Input: {
        value: "cosine",
        type: "text",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "max_steps", type: "label" },
      Input: {
        value: 100,
        type: "number",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "logging_steps", type: "label" },
      Input: {
        value: 10,
        type: "number",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "output_dir", type: "label" },
      Input: {
        value: "./dpo",
        type: "text",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "optim", type: "label" },
      Input: {
        value: "paged_adamw_8bit",
        type: "text",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "warmup_steps", type: "label" },
      Input: {
        value: 100,
        type: "number",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "fp16", type: "label" },
      Input: {
        value: "True",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "tf32", type: "label" },
      Input: {
        value: "True",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "report_to", type: "label" },
      Input: {
        value: "None",
        type: "text",
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "remove_unused_columns", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options:[
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "max_length", type: "label" },
      Input: {
        value: 1024,
        type: "number",
       
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "max_prompt_length", type: "label" },
      Input: {
        value: 512,
        type: "number",
       
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "beta", type: "label" },
      Input: {
        value: 0.1,
        type: "number",
       
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "model_adapter_name", type: "label" },
      Input: {
        value: "default",
        type: "text",
       
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "ref_adapter_name", type: "label" },
      Input: {
        value: "reference",
        type: "text",
      
        
      },
      Help: {
        type: "label",
        value:
          "The mode of inference that the model uses after fine-tuning. It can be one of the following: `greedy`, `beam_search`, `top_k`, `top_p`, or `custom`"
      }
    },
  ],
  [
    {
      id: { value: 1, type: "label" },
      Data: { value: "dummy Data Evaluate Model", type: "label" }
    },
    {
      id: { value: 2, type: "label" },
      Data: { value: "dummy Data", type: "label" }
    },
    {
      id: { value: 3, type: "label" },
      Data: { value: "dummy Data", type: "text" }
    },
    {
      id: { value: 4, type: "label" },
      Data: { value: 101, type: "number", min: 1, max: 10000 }
    },
    {
      id: { value: 5, type: "label" },
      Data: {
        value: "A",
        type: "select",
        options: [
          { value: "A", label: "A Label" },
          { value: "D", label: "D Label" },
          { value: "C", label: "C Label" },
          { value: "B", label: "B Label" }
        ]
      }
    },
    {
      id: { value: 6, type: "label" },
      Data: {
        value: "A",
        type: "radio",
        options: [
          { value: "A", label: "A Label" },
          { value: "D", label: "D Label" },
          { value: "C", label: "C Label" },
          { value: "B", label: "B Label" }
        ]
      }
    }
  ]
];
export const RewardTableData = [
  [],
  [
    // ......................

    {
      "Parameter Name": { value: "output_dir", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "The directory where the model checkpoints and predictions will be saved."
      }
    },
    {
      "Parameter Name": { value: "learning_rate: floa", type: "label" },

      Input: { value: "5e-05", type: "text" },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables mixed precision training with 16-bit floating point numbers. It can reduce memory usage and improve speed, but may also introduce some numerical instability"
      }
    },
    {
      "Parameter Name": { value: "per_device_train_batch_size", type: "label" },

      Input: { value: "8", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for training"
      }
    },
    {
      "Parameter Name": { value: "per_device_eval_batch_size", type: "label" },

      Input: { value: "8", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value: "The batch size per device (GPU or CPU) for evaluation"
      }
    },
    {
      "Parameter Name": { value: "num_train_epochs", type: "label" },

      Input: { value: "3.0", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The number of epochs to train the model. An epoch is a full pass over the training data"
      }
    },
    {
      "Parameter Name": { value: "weight_decay", type: "label" },

      Input: { value: "0.0", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "A regularization parameter that applies L2 penalty to the model weights. It can prevent overfitting and improve generalization"
      }
    },

    {
      "Parameter Name": { value: "evaluation_strategy", type: "label" },

      Input: { value: "No", type: "text" },
      Help: {
        type: "label",
        value:
          "The strategy to use for evaluation. Possible values are `no`, `steps`, `epoch`, or a custom `IntervalStrategy`"
      }
    },

    {
      "Parameter Name": { value: "eval_steps", type: "label" },

      Input: { value: "0.0", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The number of steps between each evaluation. Will default to the same value as `logging_steps` if not set"
      }
    },

    {
      "Parameter Name": { value: "save_strategy", type: "label" },

      Input: { value: "Steps", type: "text" },
      Help: {
        type: "label",
        value:
          "The strategy to use for saving checkpoints. Possible values are `no`, `steps`, `epoch`, or a custom `IntervalStrategy`"
      }
    },

    {
      "Parameter Name": { value: "save_steps", type: "label" },

      Input: { value: 2, type: "number" },
      Help: {
        type: "label",
        value:
          "The number of steps between each checkpoint save. Will default to the same value as `logging_steps` if not set"
      }
    },

    {
      "Parameter Name": { value: "save_total_limit", type: "label" },

      Input: { value: 5, type: "number"},
      Help: {
        type: "label",
        value:
          "The maximum number of checkpoints to keep. If set to None, no limit is applied"
      }
    },

    {
      "Parameter Name": { value: "gradient_accumulation_steps", type: "label" },

      Input: { value: "1", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The number of steps to accumulate gradients before performing a backward/update pass"
      }
    },

    {
      "Parameter Name": { value: "gradient_checkpointing", type: "label" },

      Input: {
        value: "True",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables gradient checkpointing, which saves memory by trading off some speed. It allows the model to handle longer sequences and larger batch sizes¹."
      }
    },

    {
      "Parameter Name": { value: "Deepspeed", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables the use of DeepSpeed, a library for distributed training and inference"
      }
    },

    {
      "Parameter Name": { value: "local_rank", type: "label" },

      Input: { value: -1, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The rank of the current process on the local node. It is used for distributed training with multiple GPUs per node"
      }
    },

    {
      "Parameter Name": { value: "remove_unused_columns", type: "label" },

      Input: {
        value: "True",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A boolean flag that indicates whether to remove columns from the dataset that are not used by the model. It can reduce memory usage and speed up data loading"
      }
    },
    {
      Input: { value: "None", type: "text" },

      "Parameter Name": { value: "label_names", type: "label" },
      Help: {
        type: "label",
        value:
          "A list of names for the labels in the dataset. It is used to map the label IDs to human-readable names"
      }
    },

    {
      "Parameter Name": { value: "Bf16", type: "label" },

      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value:
          "A boolean flag that enables mixed precision training with bfloat16 numbers. It is similar to fp16, but uses fewer bits for the exponent and more bits for the mantissa. It is supported by some hardware accelerators, such as TPUs"
      }
    },

    {
      Input: { value: "steps", type: "text" },

      "Parameter Name": { value: "logging_strategy", type: "label" },
      Help: {
        type: "label",
        value: "It is used to control when the logging happens during training"
      }
    },

    {
      "Parameter Name": { value: "logging_steps", type: "label" },

      Input: { value: 500, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The number of steps between each logging. If set to 0, no logging is done"
      }
    },

    {
      Input: { value: "adamw_torch", type: "text" },

      "Parameter Name": { value: "Optim", type: "label" },
      Help: {
        type: "label",
        value:
          "The name of the optimizer to use for training. Possible values are `adam`, `adamw`, `sgd`, `rmsprop`, or a custom `Optimizer`"
      }
    },
    {
      Input: { value: "linear", type: "text" },

      "Parameter Name": { value: "lr_scheduler_type", type: "label" },
      Help: {
        type: "label",
        value:
          "The type of learning rate scheduler to use. Possible values are `linear`, `cosine`, `cosine_with_restarts`, `polynomial`, `constant`, `constant_with_warmup`, or a custom `SchedulerType`"
      }
    }

    // ..........................
  ],
  [
    // ......................

    {
      "Parameter Name": { value: "lora_r", type: "label" },

      Input: { value: "8", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The rank of the low-rank approximation matrices. It controls the trade-off between memory efficiency and accuracy. A lower rank means fewer parameters to update, but also less expressive power"
      }
    },
    {
      "Parameter Name": { value: "lora_alpha", type: "label" },

      Input: { value: "8", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The scaling factor for the weight updates. It is calculated as alpha divided by rank. A higher alpha means larger updates, but also more risk of overfitting"
      }
    },
    {
      "Parameter Name": { value: "lora_dropout", type: "label" },

      Input: { value: "0.0", type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The dropout probability for the low-rank approximation matrices. It can help regularize the model and prevent overfitting"
      }
    },
    {
      "Parameter Name": { value: "lora_target_modules", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "The list of modules or layers that are targeted by LoRA. It can be used to fine-tune specific parts of the model, such as the query, key, value, output, or head projections"
      }
    },
    {
      "Parameter Name": { value: "Bias", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "A boolean flag that indicates whether to use bias terms in the low-rank approximation matrices. It can improve the model performance, but also increase the number of parameters"
      }
    },
    {
      "Parameter Name": { value: "task_type", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "The type of the downstream task that the model is fine-tuned for. It can be one of the following: `classification`, `regression`, `generation`, `question_answering`, `summarization`, `translation`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "inference_mode", type: "label" },

      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value: "The mode of inference that the model uses after fine-tuning."
      }
    }

    // ..........................
  ],
  [
    {
      "Parameter Name": { value: "lora_r", type: "label" },
      Input: { value: 8, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The rank of the low-rank approximation matrices. It controls the trade-off between memory efficiency and accuracy. A lower rank means fewer parameters to update, but also less expressive power"
      }
    },
    {
      "Parameter Name": { value: "lora_alpha", type: "label" },
      Input: { value: 8, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The scaling factor for the weight updates. It is calculated as alpha divided by rank. A higher alpha means larger updates, but also more risk of overfitting"
      }
    },
    {
      "Parameter Name": { value: "lora_dropout", type: "label" },
      Input: { value: 0.0, type: "number", min: -10000, max: 10000 },
      Help: {
        type: "label",
        value:
          "The dropout probability for the low-rank approximation matrices. It can help regularize the model and prevent overfitting"
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "lora_target_modules", type: "label" },
      Help: {
        type: "label",
        value:
          "The list of modules or layers that are targeted by LoRA. It can be used to fine-tune specific parts of the model, such as the query, key, value, output, or head projections"
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "Bias", type: "label" },
      Help: {
        type: "label",
        value:
          "A boolean flag that indicates whether to use bias terms in the low-rank approximation matrices. It can improve the model performance, but also increase the number of parameters"
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "task_type", type: "label" },
      Help: {
        type: "label",
        value:
          "The type of the downstream task that the model is fine-tuned for. It can be one of the following: `classification`, `regression`, `generation`, `question_answering`, `summarization`, `translation`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "inference_mode", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value: "The mode of inference that the model uses after fine-tuning."
      }
    }
  ],
  [
    {
      id: { value: 1, type: "label" },
      Data: { value: "dummy Data Evaluate Model", type: "label" }
    },
    {
      id: { value: 2, type: "label" },
      Data: { value: "dummy Data", type: "label" }
    },
    {
      id: { value: 3, type: "label" },
      Data: { value: "dummy Data", type: "text" }
    },
    {
      id: { value: 4, type: "label" },
      Data: { value: 101, type: "number", min: 1, max: 10000 }
    },
    {
      id: { value: 5, type: "label" },
      Data: {
        value: "A",
        type: "select",
        options: [
          { value: "A", label: "A Label" },
          { value: "D", label: "D Label" },
          { value: "C", label: "C Label" },
          { value: "B", label: "B Label" }
        ]
      }
    },
    {
      id: { value: 6, type: "label" },
      Data: {
        value: "A",
        type: "radio",
        options: [
          { value: "A", label: "A Label" },
          { value: "D", label: "D Label" },
          { value: "C", label: "C Label" },
          { value: "B", label: "B Label" }
        ]
      }
    }
  ]
];
export const PPOTableData = [
  [],
  [
    // ......................

    {
      "Parameter Name": { value: "learning_rate", type: "label" },

      Input: { value: 1e-05, type: "number" },
      Help: {
        type: "label",
        value: "The initial learning rate for the optimizer"
      }
    },
    {
      "Parameter Name": { value: "log_with", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        value:
          "The name of the integration to report the results and logs to. Possible values are wandb, tensorboard, or None",
        type: "label"
      }
    },
    {
      "Parameter Name": { value: "batch_size", type: "label" },

      Input: { value: 256, type: "number", min: 1, max: 10000 },
      Help: {
        value:
          "The number of samples in a batch of data. It affects the memory usage and the speed of training",
        type: "label"
      }
    },
    {
      "Parameter Name": { value: "mini_batch_size", type: "label" },

      Input: { value: 1, type: "number", min: 1, max: 10000 },
      Help: {
        value:
          "The number of samples in a mini-batch of data. It is used for gradient accumulation when the batch size is too large to fit into GPU memory",
        type: "label"
      }
    },
    {
      "Parameter Name": { value: "gradient_accumulation_steps", type: "label" },

      Input: { value: 1, type: "number", min: 1, max: 10000 },
      Help: {
        type: "label",
        value:
          "The number of steps to accumulate gradients before performing a backward/update pass"
      }
    },
    {
      "Parameter Name": { value: "optimize_cuda_cache", type: "label" },

      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        value:
          "A boolean flag that indicates whether to optimize the CUDA cache for faster training. It can reduce the overhead of memory allocation and deallocation",
        type: "label"
      }
    },
    {
      "Parameter Name": { value: "early_stopping", type: "label" },

      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        value:
          "A boolean flag that indicates whether to stop the training when the validation performance stops improving. It can prevent overfitting and save time",
        type: "label"
      }
    },
    {
      "Parameter Name": { value: "target_kl", type: "label" },

      Input: { value: 1, type: "number", min: 1, max: 10000 },
      Help: {
        value:
          "The target value for the Kullback-Leibler divergence between the old and new policies. It is used to control the step size of the policy update.",
        type: "label"
      }
    },

    {
      "Parameter Name": { value: "ppo_epochs", type: "label" },

      Input: { value: 4, type: "number", min: 1, max: 10000 },
      Help: {
        value:
          "The number of epochs to train the model using the Proximal Policy Optimization algorithm. An epoch is a full pass over the collected trajectories",
        type: "label"
      }
    },

    {
      "Parameter Name": { value: "Seed", type: "label" },

      Input: { value: 0, type: "number", min: 1, max: 10000 },
      Help: {
        value:
          "The random seed for the initialization and the randomness of the training. It affects the reproducibility of the results",
        type: "label"
      }
    }

    // ..........................
  ],
  [
    // ......................

    {
      "Parameter Name": { value: "lora_r", type: "label" },

      Input: { value: 8, type: "number", min: 1, max: 10000 },

      Help: {
        type: "label",
        value:
          "The rank of the low-rank approximation matrices. It controls the trade-off between memory efficiency and accuracy. A lower rank means fewer parameters to update, but also less expressive power"
      }
    },
    {
      "Parameter Name": { value: "lora_alpha", type: "label" },

      Input: { value: 8, type: "number", min: 1, max: 10000 },

      Help: {
        type: "label",
        value:
          "The scaling factor for the weight updates. It is calculated as alpha divided by rank. A higher alpha means larger updates, but also more risk of overfitting"
      }
    },
    {
      "Parameter Name": { value: "lora_dropout", type: "label" },

      Input: { value: "0.0", type: "number", min: 1, max: 10000 },

      Help: {
        type: "label",
        value:
          "The dropout probability for the low-rank approximation matrices. It can help regularize the model and prevent overfitting"
      }
    },
    {
      "Parameter Name": { value: "lora_target_modules", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "The list of modules or layers that are targeted by LoRA. It can be used to fine-tune specific parts of the model, such as the query, key, value, output, or head projections"
      }
    },
    {
      "Parameter Name": { value: "Bias", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "A boolean flag that indicates whether to use bias terms in the low-rank approximation matrices. It can improve the model performance, but also increase the number of parameters"
      }
    },
    {
      "Parameter Name": { value: "task_type", type: "label" },

      Input: { value: "None", type: "text" },
      Help: {
        type: "label",
        value:
          "The type of the downstream task that the model is fine-tuned for. It can be one of the following: `classification`, `regression`, `generation`, `question_answering`, `summarization`, `translation`, or `custom`"
      }
    },
    {
      "Parameter Name": { value: "inference_mode", type: "label" },

      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      },
      Help: {
        type: "label",
        value: "The mode of inference that the model uses after fine-tuning."
      }
    }

    // ..........................
  ],
  [
    {
      "Parameter Name": { value: "lora_r", type: "label" },
      Input: { value: 8, type: "number", min: -10000, max: 10000 }
    },
    {
      "Parameter Name": { value: "lora_alpha", type: "label" },
      Input: { value: 8, type: "number", min: -10000, max: 10000 }
    },
    {
      "Parameter Name": { value: "lora_dropout", type: "label" },
      Input: { value: 0.0, type: "number", min: -10000, max: 10000 }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "lora_target_modules", type: "label" }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "Bias", type: "label" }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "task_type", type: "label" }
    },
    {
      "Parameter Name": { value: "inference_mode", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      }
    }
  ],
  [
    {
      id: { value: 1, type: "label" },
      Data: { value: "dummy Data Evaluate Model", type: "label" }
    },
    {
      id: { value: 2, type: "label" },
      Data: { value: "dummy Data", type: "label" }
    },
    {
      id: { value: 3, type: "label" },
      Data: { value: "dummy Data", type: "text" }
    },
    {
      id: { value: 4, type: "label" },
      Data: { value: 101, type: "number", min: 1, max: 10000 }
    },
    {
      id: { value: 5, type: "label" },
      Data: {
        value: "A",
        type: "select",
        options: [
          { value: "A", label: "A Label" },
          { value: "D", label: "D Label" },
          { value: "C", label: "C Label" },
          { value: "B", label: "B Label" }
        ]
      }
    },
    {
      id: { value: 6, type: "label" },
      Data: {
        value: "A",
        type: "radio",
        options: [
          { value: "A", label: "A Label" },
          { value: "D", label: "D Label" },
          { value: "C", label: "C Label" },
          { value: "B", label: "B Label" }
        ]
      }
    }
  ]
];

export const MainDB = {
  TableColumn: ["Parameter Name", "Input"],
  TableData: [
    {
      "Parameter Name": { value: "local_rank", type: "label" },
      Input: { value: 1, type: "number", min: -10000, max: 10000 }
    },
    {
      "Parameter Name": { value: "remove_unused_columns", type: "label" },
      Input: {
        value: "True",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      }
    },
    {
      Input: { value: "None", type: "text" },
      "Parameter Name": { value: "label_names", type: "label" }
    },
    {
      "Parameter Name": { value: "Bf16", type: "label" },
      Input: {
        value: "False",
        type: "radio",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" }
        ]
      }
    },
    {
      Input: { value: "steps", type: "text" },
      "Parameter Name": { value: "logging_strategy", type: "label" }
    },
    {
      "Parameter Name": { value: "logging_steps", type: "label" },
      Input: { value: 500, type: "number", min: -10000, max: 10000 }
    },
    {
      Input: { value: "adamw_torch", type: "text" },
      "Parameter Name": { value: "Optim", type: "label" }
    },
    {
      Input: { value: "lr_scheduler_type", type: "text" },
      "Parameter Name": { value: "linear", type: "label" }
    }
  ]
};
