import React from 'react'
import CustomNavbar from './CustomNavbar'

const MenuShow = () => {
    const links1 = [
        { name: 'Home', route: '/' },
        { name: 'About', route: '/about' },
        { name: 'Contact', route: '/contact' },
      ];
    const links2 = [];
  
    return (
      <div>
        <h1>Menu 1</h1>
        <CustomNavbar links={links1} profile={true} />
  
        <h1>Menu 2</h1>
        <CustomNavbar links={links2} profile={false} />
      </div>
    );
  };

export default MenuShow
